import { Box, styled } from '@mui/material';

export const AvailabilitiesCalendarBody = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const AvailabilitiesCalendarContainer = styled(Box)(() => ({
  width: '100%',
  height: '92%',
  display: 'flex',
  flexDirection: 'column',
}));
