/* eslint-disable react/jsx-props-no-spreading */
import { Alert, Box, Breadcrumbs, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  profileSelector,
  updateLanguageErrorSelector,
  updateLanguageSuccessSelector,
} from 'store/selectors/profile-selectors';
import {
  GENERAL__EMAIL,
  GENERAL__FIRST_NAME,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__PHONE_NUMBER,
  GENERAL__SAVE_BUTTON,
  MENU__MY_PROFILE,
  GENERAL__ERROR_MESSAGE,
  PROFILE__UPDATE_LANGUAGE_SUCCESSFUL,
} from 'translations/constants';
import UPInput from 'components/input';
import UPLoadingSpinner from 'components/loading-spinner';
import { CloseAlertIcon, SectionContainer } from 'modules/companies/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import Lang from 'types/Lang';
import IProfileUpdateLanguage from 'types/profile/IProfileUpdateLanguage';
import {
  updateLanguageAsync,
  updateLanguageErrorAction,
  updateLanguageSuccessAction,
} from 'store/actions/profile-actions';
import { getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import UPButton from 'components/button';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { changeCompaniesAction } from 'store/actions/auth-actions';
import { toNumber } from 'lodash';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { LeftContainer } from './styles';

const Profile = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(profileSelector);
  const updateLanguageSuccessError = useAppSelector(updateLanguageSuccessSelector);
  const updateLanguageError = useAppSelector(updateLanguageErrorSelector);

  const [languageId, setLanguageId] = useState<string>(profile.languageId);
  const [companyId, setCompanyId] = useState<string>(profile.currentCompany.toString());

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const companiesOptions: UPDropdownItem[] = profile.userCompanies;

  const onSaveLanguage = () => {
    const updateLanguage: IProfileUpdateLanguage = { userId: profile.id, languageId };
    dispatch(updateLanguageAsync(updateLanguage));
  };

  const onChangeLanguage = useCallback(event => {
    setLanguageId(event.target.value);
  }, []);

  const onChangeCompany = useCallback(
    event => {
      setCompanyId(event.target.value);
      dispatch(changeCompaniesAction(toNumber(event.target.value)));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(setMenuStatusAction(t(MENU__MY_PROFILE)));
  }, [dispatch, t]);

  useEffect(() => {
    dispatch(updateLanguageSuccessAction(false));
    dispatch(updateLanguageErrorAction(false));
  }, [dispatch]);

  if (!profile) {
    return <UPLoadingSpinner />;
  }

  return (
    <SectionContainer>
      <LeftContainer>
        <Box display="flex" justifyContent="space-between">
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: 8 }}>
            <Typography color="textSecondary">Profile</Typography>
            <Typography color="textPrimary">
              {profile.firstName} {profile.lastName}
            </Typography>
          </Breadcrumbs>
        </Box>
        <Divider />
        <Box mt={2}>
          <UPInput value={profile.firstName} label={t(GENERAL__FIRST_NAME)} disabled placeholder="" onChange={null} />
        </Box>
        <Box mt={2}>
          <UPInput value={profile.lastName} label={t(GENERAL__LAST_NAME)} disabled placeholder="" onChange={null} />
        </Box>
        <Box mt={2}>
          <UPInput value={profile.email} label={t(GENERAL__EMAIL)} disabled placeholder="" onChange={null} />
        </Box>
        <Box mt={2}>
          <UPInput
            value={profile.phoneNumber || ''}
            label={t(GENERAL__PHONE_NUMBER)}
            disabled
            placeholder=""
            onChange={null}
          />
        </Box>
        <Box mt={2} mb={profile.role.includes('manager') ? 0 : 5}>
          <UPDropdown
            items={languageOptions}
            onChange={onChangeLanguage}
            value={languageId}
            label={t(GENERAL__LANGUAGE)}
          />
        </Box>
        {profile.role.includes('manager') && (
          <Box mt={2} mb={5}>
            <UPDropdown items={companiesOptions} onChange={onChangeCompany} value={companyId} label="Environments" />
          </Box>
        )}

        <Box sx={{ display: 'flex' }}>
          <UPButton text={t(GENERAL__SAVE_BUTTON)} onClick={onSaveLanguage} />
        </Box>
        {updateLanguageSuccessError && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '100%', marginTop: '16px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updateLanguageSuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(PROFILE__UPDATE_LANGUAGE_SUCCESSFUL)}
          </Alert>
        )}
        {updateLanguageError && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '100%' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updateLanguageErrorAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(GENERAL__ERROR_MESSAGE)}
          </Alert>
        )}
      </LeftContainer>
    </SectionContainer>
  );
};

export default Profile;
