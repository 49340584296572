import { styled } from '@mui/material';

export const EditableCostCenterStyled = styled('div')(({ theme }) => ({
  borderRadius: '4px',
  background: 'white',
  '& .MuiAutocomplete-inputRoot': {
    fontSize: '14px',
    width: '94%',
    '& input::placeholder': {
      color: theme.palette.secondary.main,
      opacity: '1',
    },
  },
}));
