import { Checkbox, Typography } from '@mui/material';
import { DayOfWeek, HeaderCell } from './styles';
import { UPDayPickerOptionProps } from './types';

const UPDayPickerOption = (props: UPDayPickerOptionProps): JSX.Element => {
  const { dayOfWeek, date, checked } = props;
  return (
    <HeaderCell>
      <DayOfWeek>
        <Checkbox checked={checked} />
        <Typography>
          <b>{dayOfWeek}</b> {date}
        </Typography>
      </DayOfWeek>
    </HeaderCell>
  );
};

UPDayPickerOption.defaultProps = { checkboxLeft: false };
export default UPDayPickerOption;
