import { UPDropdownItem } from 'components/dropdown';
import { RootState } from 'store';
import { IParitairComite } from 'types/paritair-comite';

export const paritairComiteSelectItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.paritairComite.paritairComiteSelectItems;
export const paritairComiteByCompanySelectItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.paritairComite.paritairComiteByCompanySelectItems;
export const getParitairComiteSelectItemsErrorSelector = (state: RootState): boolean =>
  state.paritairComite.getParitairComiteSelectItemsError;
export const getParitairComiteSelectItemsByCompanyErrorSelector = (state: RootState): boolean =>
  state.paritairComite.getParitairComiteSelectItemsByCompanyError;
export const paritairComitesSelector = (state: RootState): IParitairComite[] => state.paritairComite.paritairComites;

export const paritairComiteSelector = (state: RootState): IParitairComite => state.paritairComite.paritairComite;
export const paritairComiteErrorSelector = (state: RootState): boolean => state.paritairComite.paritairComiteError;

export const saveParitairComiteSuccessSelector = (state: RootState): boolean =>
  state.paritairComite.saveParitairComiteSuccess;
export const saveParitairComiteErrorSelector = (state: RootState): boolean =>
  state.paritairComite.saveParitairComiteError;
export const paritairComiteIdSelector = (state: RootState): number => state.paritairComite.paritairComiteId;

export const updateParitairComiteSuccessSelector = (state: RootState): boolean =>
  state.paritairComite.updateParitairComiteSuccess;
export const updateParitairComiteErrorSelector = (state: RootState): boolean =>
  state.paritairComite.updateParitairComiteError;

export const paritairComiteLoadingSelector = (state: RootState): boolean => state.paritairComite.loading;
