import { createReducer } from '@reduxjs/toolkit';
import {
  setManagerAction,
  setManagerErrorAction,
  setManagerRequestsLoadingAction,
} from 'store/actions/company-manager-actions';
import IManagerState from 'types/company/managers/IManagerState';

const initialState: IManagerState = {
  manager: false,
  loading: false,
  managerError: false,
};

const managerReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setManagerAction, (state, action) => ({ ...state, manager: action.payload }))
    .addCase(setManagerRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setManagerErrorAction, (state, action) => ({
      ...state,
      managerError: action.payload,
    }));
});

export default managerReducer;
