/* eslint-disable react/jsx-props-no-spreading */
import { Backdrop, Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import UPInput from 'components/input';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import IPayrollFunction from 'types/company/payroll/IPayrollFunction';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { paritairComiteByCompanySelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import { getParitairComiteSelectItemsByCompanyAction } from 'store/actions/paritair-comite-actions';
import { getFunctionTypesWithTranslation, getInputErrorText } from 'utils/helpers';
import { HexColorPicker } from 'react-colorful';
import { useDispatch } from 'react-redux';
import { addFunctionAction, updateFunctionAction } from 'store/actions/company-function-actions';
import { toNumber } from 'lodash';
import { useParams } from 'react-router-dom';
import { useMemo, useEffect } from 'react';
import CATEGORIES from 'constants/Categories';
import {
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModal,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalTitle,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalActionButton,
} from './styles';
import {
  COMPANY_PAYROLL__PARITAIR_COMITE,
  GENERAL__SELECT,
  GENERAL__CANCEL_BUTTON,
  GENERAL__SAVE_BUTTON,
  COMPANY_PAYROLL__FUNCTION_DESCRIPTION,
  COMPANY_PAYROLL__EDIT_PAYROLL_FUNCTION,
  COMPANY_PAYROLL__FUNCTION_COLOR,
  COMPANY_PAYROLL__FUNCTION_TYPE,
  COMPANY_PAYROLL__CATEGORY,
  COMPANY_PAYROLL__ADD_NEW_FUNCTION,
  VALIDATION__IS_REQUIRED,
  GENERAL__ARB,
  GENERAL__BED,
} from '../../../translations/constants';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { EditPayrollFunctionProps } from './types';

const EditPayrollFunction = (props: EditPayrollFunctionProps): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const { open, onClose, payrollFunction } = props;

  const categories = useMemo((): UPDropdownItem[] => CATEGORIES, []);
  const paritairComites = useAppSelector(paritairComiteByCompanySelectItemsSelector);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { isDirty, errors },
  } = useForm<IPayrollFunction>({
    defaultValues: payrollFunction,
  });

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsByCompanyAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  const onSavePayrollFunction: SubmitHandler<IPayrollFunction> = event => {
    const values = getValues();
    if (isDirty) {
      if (payrollFunction.id === 0) {
        dispatch(addFunctionAction({ companyId: toNumber(companyId), functionToAdd: values }));
      } else {
        dispatch(updateFunctionAction({ companyId: toNumber(companyId), functionToUpdate: values }));
      }
    }
    onClose(event);
  };
  return (
    <Backdrop open={open}>
      <PayrollEditFunctionModal open={open} onClose={onClose} aria-labelledby="modal-edit-payroll-function">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>
            {payrollFunction.id === 0
              ? t(COMPANY_PAYROLL__ADD_NEW_FUNCTION)
              : t(COMPANY_PAYROLL__EDIT_PAYROLL_FUNCTION)}
          </PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn sx={{ marginRight: '16px' }}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="description"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_PAYROLL__FUNCTION_DESCRIPTION)}
                      label={t(COMPANY_PAYROLL__FUNCTION_DESCRIPTION)}
                      required
                      error={!!errors.description}
                      helperText={getInputErrorText(errors, 'description', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="paritairComiteId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={paritairComites}
                      value={field.value.toString()}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__SELECT)}
                      label={t(COMPANY_PAYROLL__PARITAIR_COMITE)}
                      required
                      error={!!errors.paritairComiteId}
                      helperText={getInputErrorText(errors, 'paritairComiteId', undefined)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="class"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <RadioGroup row {...field}>
                        <FormControlLabel value="BED" control={<Radio />} label={t(GENERAL__BED)} />
                        <FormControlLabel value="ARB" control={<Radio />} label={t(GENERAL__ARB)} />
                      </RadioGroup>
                    );
                  }}
                />
                {errors.class && (
                  <Typography color="error" variant="body2">
                    {t(VALIDATION__IS_REQUIRED)}
                  </Typography>
                )}
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="category"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={categories}
                      value={field.value?.toString()}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__SELECT)}
                      label={t(COMPANY_PAYROLL__CATEGORY)}
                      required
                      error={!!errors.category}
                      helperText={getInputErrorText(errors, 'category', undefined)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={getFunctionTypesWithTranslation()}
                      value={field.value ? field.value.toString() : ''}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__SELECT)}
                      label={t(COMPANY_PAYROLL__FUNCTION_TYPE)}
                      required
                      error={!!errors.type}
                      helperText={getInputErrorText(errors, 'type', undefined)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
            <PayrollEditFunctionModalColumn>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="color"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Box sx={{ marginBottom: '42px' }}>
                        <UPInput
                          value={field.value}
                          onChange={field.onChange}
                          placeholder={t(COMPANY_PAYROLL__FUNCTION_COLOR)}
                          label={t(COMPANY_PAYROLL__FUNCTION_COLOR)}
                        />
                      </Box>
                      <HexColorPicker color={field.value?.toString() || ''} onChange={field.onChange} />
                    </>
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            <PayrollEditFunctionModalActionButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={event => handleSubmit(onSavePayrollFunction)(event)}
            />
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

export default EditPayrollFunction;
