import { NavigationDirection } from 'constants/NavigationDirection';
import { CompanyAvailabilityTableHeaderProps } from '../../types';
import CompanyAvailabilityCalendarHeader from './calendar-header';
import CompanyAvailabilityEmployeeSearch from './employee-search';
import { CompanyAvailabilityTableHeaderContainer } from './styles';

const CompanyAvailabilityTableHeader = (props: CompanyAvailabilityTableHeaderProps) => {
  const { week, setWeek, days, searchQuery, onChangeSearchQuery, availabilityCountByDay, onSearch } = props;

  const onChangeWeek = (direction: NavigationDirection) => {
    const newDate = new Date(week);
    switch (direction) {
      case NavigationDirection.PREVIOUS:
        newDate.setDate(newDate.getDate() - 7);
        break;
      case NavigationDirection.NEXT:
        newDate.setDate(newDate.getDate() + 7);
        break;
      default:
    }
    setWeek(newDate);
  };

  return (
    <CompanyAvailabilityTableHeaderContainer>
      <CompanyAvailabilityEmployeeSearch
        searchQuery={searchQuery}
        onChangeSearchQuery={onChangeSearchQuery}
        onSearch={onSearch}
      />
      <CompanyAvailabilityCalendarHeader
        onChangeWeek={onChangeWeek}
        days={days}
        availabilityCountByDay={availabilityCountByDay}
      />
    </CompanyAvailabilityTableHeaderContainer>
  );
};

export default CompanyAvailabilityTableHeader;
