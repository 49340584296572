import { Box, Checkbox, Pagination, Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import { getCurrentTime, getCurrentWeek } from 'modules/contracts/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { archiveErrorsAction, getApiErrorsAction, getErrorTypesAction } from 'store/actions/api-errors-actions';
import { apiErrorTypesSelector, apiErrorsSelector } from 'store/selectors/api-errors-selectors';
import { ApiErrorFilters } from 'types/errors/ApiErrorFilters';
import UPWeekPicker from 'components/week-picker';
import {
  DASHBOARD__BUSINESS_OBJECT,
  DASHBOARD__SHOW_ARCHIVED,
  DASHBOARD__SHOW_WITHOUT_TYPE,
  GENERAL__NO,
  GENERAL__NO_DATA_TO_SHOW,
  GENERAL__YES,
  HEADER__WEEK,
} from 'translations/constants';
import { toNumber } from 'lodash';
import { GetApiErrorBusinessObjectAsDropdownItems } from 'types/errors/ApiErrorBusinessObjects';
import { FilterButton, StyledFilterIcon } from 'components/filters-menu/styles';
import FiltersMenu from 'components/filters-menu';
import { PageFilter } from 'modules/contracts/types';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import { DashboardErrorLogsTitle, ErrorLogsHeader } from '../styles';
import ApiErrorRow from './ApiErrorRow';
import { StyledTableCell, StyledTableRow } from './styles';
import APiErrorBottomBar from './ApiErrorBottomBar';

const AdminDashboard = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const apiErrors = useAppSelector(apiErrorsSelector);
  const errorTypesList = useAppSelector(apiErrorTypesSelector);

  const [week, setWeek] = useState<Date>(getCurrentTime().date);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorType, setErrorType] = useState<string>('-1');
  const [includeArchived, setIncludeArchived] = useState<string>('2');
  const [businessObject, setBusinessObject] = useState<string>('');
  const [errorDate, setErrorDate] = useState<Date>(null);
  const pageSize = 25;
  const currentWeek = getCurrentWeek(week);
  const year = week.getFullYear();

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [clearAll, setClearAll] = useState<boolean>(true);

  const [filtersPopoverAnchorEl, setFiltersPopoverAnchorEl] = useState<null | HTMLElement>(null);

  const filters = useMemo((): PageFilter[] => {
    const includeArchivedFilterOptions: UPDropdownItem[] = [
      {
        value: '1',
        label: t(GENERAL__YES),
      },
      {
        value: '2',
        label: t(GENERAL__NO),
      },
    ];

    const f: PageFilter[] = [
      {
        label: t(DASHBOARD__BUSINESS_OBJECT),
        value: businessObject,
        setValue: setBusinessObject,
        items: GetApiErrorBusinessObjectAsDropdownItems(),
        component: UPDropdown,
        canBeEmpty: true,
      },
      {
        label: t(DASHBOARD__SHOW_WITHOUT_TYPE),
        value: errorType,
        setValue: setErrorType,
        items: errorTypesList,
        component: UPDropdown,
        canBeEmpty: false,
      },
      {
        label: t(DASHBOARD__SHOW_ARCHIVED),
        value: includeArchived,
        setValue: setIncludeArchived,
        items: includeArchivedFilterOptions,
        component: UPDropdown,
        canBeEmpty: false,
      },
    ];

    return f;
  }, [t, includeArchived, errorType, errorTypesList, businessObject]);

  const getData = (pageNumber: number) => {
    const filter: ApiErrorFilters = {
      pageNumber,
      pageSize,
      includeArchived: includeArchived === '1',
      errorType: toNumber(errorType),
      date: errorDate,
      week: currentWeek,
      businessObject: businessObject ? toNumber(businessObject) : null,
      year,
    };
    dispatch(getApiErrorsAction(filter));
  };

  useEffect(() => {
    if (clearAll) {
      setSelectedRows([]);
      setClearAll(false);
    }
  }, [clearAll]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getData(toNumber(page));
  };

  const getDefaultData = useCallback(() => {
    const defaultFilters: ApiErrorFilters = {
      pageNumber: 1,
      pageSize,
      errorType: -1,
      includeArchived: false,
      week: currentWeek,
      year,
    };
    dispatch(getApiErrorsAction(defaultFilters));
  }, [dispatch, currentWeek, year]);

  const resetFilters = () => {
    setCurrentPage(1);
    setBusinessObject('');
    setErrorType('-1');
    setIncludeArchived('2');
    setErrorDate(null);
    getDefaultData();
  };

  useEffect(() => {
    getDefaultData();
  }, [dispatch, getDefaultData, currentWeek, year]);

  useEffect(() => {
    dispatch(getErrorTypesAction());
  }, [dispatch]);

  const onClickFilterButton = event => {
    setFiltersPopoverAnchorEl(event.currentTarget);
  };

  const onCloseFilterPopover = () => {
    setFiltersPopoverAnchorEl(null);
  };

  const onApplyFilters = () => {
    setCurrentPage(1);
    getData(1);
    onCloseFilterPopover();
  };

  const onArchiveErrors = () => {
    dispatch(archiveErrorsAction(selectedRows)).then(() => {
      setSelectedRows([]);
      getData(1);
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = apiErrors.items.map(n => n.id);
      setSelectedRows(newSelected);
      return;
    }
    setSelectedRows([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedRows.slice(0, selectedIndex), selectedRows.slice(selectedIndex + 1));
    }
    setSelectedRows(newSelected);
  };

  const isItemChecked = (id: number) => selectedRows.indexOf(id) !== -1;

  const getRows = () => {
    if (apiErrors?.items) {
      const rows = apiErrors.items.map(r => (
        <ApiErrorRow row={r} handleClick={handleClick} isChecked={isItemChecked(r.id)} />
      ));
      return rows;
    }

    return <p>No data to show</p>;
  };

  return (
    <Box>
      <ErrorLogsHeader justifyContent="space-between" id="header">
        <DashboardErrorLogsTitle>Error Logs</DashboardErrorLogsTitle>
        <UPWeekPicker
          value={week}
          weekValue={currentWeek}
          label={t(HEADER__WEEK)}
          onChange={newValue => {
            setWeek(newValue);
          }}
        />
        <FilterButton
          text={
            <Box display="flex" alignItems="center">
              <StyledFilterIcon />
            </Box>
          }
          onClick={onClickFilterButton}
          ref={filtersPopoverAnchorEl}
        />
        <FiltersMenu
          open={Boolean(filtersPopoverAnchorEl)}
          anchorEl={filtersPopoverAnchorEl}
          onClose={onCloseFilterPopover}
          onApplyFilters={onApplyFilters}
          onClearFilters={resetFilters}
          filters={filters}
        />
      </ErrorLogsHeader>
      {apiErrors?.items?.length > 0 && (
        <>
          <Box sx={{ margin: '20px 5px' }} id="hasErrors">
            <TableContainer component={Paper} id="tableContainer">
              <Table aria-label="collapsible table" id="table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell padding="checkbox" align="left">
                      {includeArchived === '2' && (
                        <Checkbox
                          color="primary"
                          onChange={handleSelectAllClick}
                          checked={selectedRows.length > 0 && selectedRows.length === apiErrors?.items?.length}
                          indeterminate={selectedRows.length > 0 && selectedRows.length < apiErrors?.items?.length}
                          inputProps={{
                            'aria-labelledby': `errors-select-all`,
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell width="10%">Business Object</StyledTableCell>
                    <StyledTableCell>Title</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>{getRows()}</TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Pagination count={apiErrors?.totalPages} page={currentPage} onChange={handlePageChange} />
        </>
      )}
      {!apiErrors?.items?.length && (
        <TableContainer component={Paper} id="no_data_tableContainer">
          <Table aria-label="collapsible table" id="table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell colSpan={5} align="center">
                  {t(GENERAL__NO_DATA_TO_SHOW)}
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
          </Table>
        </TableContainer>
      )}
      {selectedRows.length > 0 && (
        <APiErrorBottomBar
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setClearAll={setClearAll}
          onArchiveErrors={onArchiveErrors}
        />
      )}
    </Box>
  );
};

export default AdminDashboard;
