import { createReducer } from '@reduxjs/toolkit';
import {
  addOneLocationAction,
  removeAllLocationsAction,
  removeManyLocationAction,
  setLocationsAction,
  setLocationsErrorAction,
  setLocationsRequestsLoadingAction,
  setLocationsNamesAction,
  setLocationsNamesErrorAction,
  updateOneLocationAction,
  setLocationsDropdownItemsAction,
  setLocationsDropdownItemsErrorAction,
} from 'store/actions/company-locations-actions';
import locationsAdapter from 'store/adapters/company-locations-adapter';
import ILocationsState from 'types/company/locations/ILocationsState';

const initialState: ILocationsState = {
  locations: [],
  loading: false,
  locationsError: false,
  locationNames: [],
  locationsDropdownItems: [],
  locationsDropdownItemsError: false,
};

const locationsReducer = createReducer(locationsAdapter.getInitialState<ILocationsState>(initialState), builder => {
  return builder
    .addCase(setLocationsAction, locationsAdapter.setAll)
    .addCase(updateOneLocationAction, locationsAdapter.updateOne)
    .addCase(addOneLocationAction, locationsAdapter.addOne)
    .addCase(removeManyLocationAction, locationsAdapter.removeMany)
    .addCase(removeAllLocationsAction, locationsAdapter.removeAll)
    .addCase(setLocationsRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setLocationsErrorAction, (state, action) => ({
      ...state,
      locationsError: action.payload,
    }))
    .addCase(setLocationsNamesAction, (state, action) => ({ ...state, locationNames: action.payload }))
    .addCase(setLocationsNamesErrorAction, (state, action) => ({
      ...state,
      locationsError: action.payload,
    }))
    .addCase(setLocationsDropdownItemsAction, (state, action) => ({
      ...state,
      locationsDropdownItems: action.payload,
    }))
    .addCase(setLocationsDropdownItemsErrorAction, (state, action) => ({
      ...state,
      locationsDropdownItemsError: action.payload,
    }));
});

export default locationsReducer;
