import { Alert, Box, FormControlLabel } from '@mui/material';
import UPButton from 'components/button';
import UPSwitch from 'components/switch';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { CloseAlertIcon } from 'modules/companies/styles';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import {
  getWageExemptionsAction,
  updateWageExemptionsAction,
  updateWageExemptionsSuccessAction,
} from 'store/actions/company-wage-exemptions-actions';
import { getAllEmployeesAction } from 'store/actions/employee-actions';
import {
  updateWageExemptionsSuccessSelector,
  wageExemptionsSelector,
} from 'store/selectors/company-wage-exemptions-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY,
  COMPANY_PAYROLL__ENABLE_EMPLOYEE,
  COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE,
  COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE_FUNCTION,
  COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE_WORKERCLASS,
  GENERAL__CANCEL_BUTTON,
  GENERAL__SAVE_BUTTON,
} from 'translations/constants';
import IEmployeeExemption from 'types/company/payroll/IEmployeeExemption';
import IFunctionExemption from 'types/company/payroll/IFunctionExemption';
import IPayrollWageExemptions from 'types/company/payroll/IPayrollWageExemptions';
import IWorkerclassExemption from 'types/company/payroll/IWorkerclassExemption';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg';
import EmployeeExemptionsGrid from './EmployeeExemptionsGrid';
import FunctionExemptionsGrid from './FunctionExemptionsGrid';
import WorkerclassExemptionsGrid from './WorkerclassExemptionsGrid';
import { WageExemptionsContainer } from './styles';

const PayrollWageExemptionsInitialState: IPayrollWageExemptions = {
  id: 0,
  wageExemptions: false,
  workerclass: false,
  function: false,
  employee: false,
  workerclassExemptions: [],
  functionExemptions: [],
  employeeExemptions: [],
};

const PayrollWageExemptions = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const userIsCompanyManager = useAppSelector(profileSelector).role === CompanyRole.COMPANY_MANAGER;
  const wageExemptions = useAppSelector(wageExemptionsSelector);
  const updateWageExemptionSuccess = useAppSelector(updateWageExemptionsSuccessSelector);

  const [displayWorkerclassSwitch, setDisplayWorkerclassSwitch] = useState<boolean>(false);
  const [displayWorkerclassGrid, setDisplayWorkerclassGrid] = useState<boolean>(false);
  const [displayFunctionSwitch, setDisplayFunctionSwitch] = useState<boolean>(false);
  const [displayFunctionGrid, setDisplayFunctionGrid] = useState<boolean>(false);
  const [displayEmployeeSwitch, setDisplayEmployeeSwitch] = useState<boolean>(false);
  const [displayEmployeeGrid, setDisplayEmployeeGrid] = useState<boolean>(false);
  const [workerclassExemptions, setWorkerclassExemptions] = useState<IWorkerclassExemption[]>([]);
  const [functionExemptions, setFunctionExemptions] = useState<IFunctionExemption[]>([]);
  const [employeeExemptions, setEmployeeExemptions] = useState<IEmployeeExemption[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { handleSubmit, control, setValue, getValues, reset } = useForm<IPayrollWageExemptions>({
    defaultValues: PayrollWageExemptionsInitialState,
  });

  useEffect(() => {
    if (companyId) {
      dispatch(getWageExemptionsAction(toNumber(companyId)));
      dispatch(getAllEmployeesAction({ companyId }));
    }
  }, [dispatch, companyId]);

  const onChangeWageExemptionsSwitch = useCallback((event, field) => {
    field.onChange(event);
    setDisplayWorkerclassSwitch(!field.value);
    setDisplayFunctionSwitch(!field.value);
    setDisplayEmployeeSwitch(!field.value);
  }, []);

  const onChangeWorkerclassSwitch = useCallback((event, field) => {
    field.onChange(event);
    setDisplayWorkerclassGrid(!field.value);
  }, []);

  const onChangeFunctionSwitch = useCallback((event, field) => {
    field.onChange(event);
    setDisplayFunctionGrid(!field.value);
  }, []);

  const onChangeEmployeeSwitch = useCallback((event, field) => {
    field.onChange(event);
    setDisplayEmployeeGrid(!field.value);
  }, []);

  const onSaveWageExemptions: SubmitHandler<any> = useCallback(() => {
    const values = getValues();
    setValue('id', toNumber(companyId));
    if (!values.workerclass) {
      setValue('workerclassExemptions', []);
    } else {
      setValue(
        'workerclassExemptions',
        workerclassExemptions.map(c => {
          if (c.id < 1) {
            return {
              ...c,
              id: 0,
              functionId: null,
              wageExemption: toNumber(c.wageExemption),
              paritairComiteId: c.paritairComiteId,
            };
          }
          return {
            ...c,
            functionId: null,
            wageExemption: toNumber(c.wageExemption),
            paritairComiteId: c.paritairComiteId,
          };
        }),
      );
    }
    if (!values.function) {
      setValue('functionExemptions', []);
    } else {
      setValue(
        'functionExemptions',
        functionExemptions.map(c => {
          if (c.id < 1) {
            return {
              ...c,
              id: 0,
              workerclass: null,
              wageExemption: toNumber(c.wageExemption),
              paritairComiteId: c.paritairComiteId,
            };
          }
          return {
            ...c,
            workerclass: null,
            wageExemption: toNumber(c.wageExemption),
            paritairComiteId: c.paritairComiteId,
          };
        }),
      );
    }
    if (!values.employee) {
      setValue('employeeExemptions', []);
    } else {
      setValue(
        'employeeExemptions',
        employeeExemptions.map(c => {
          if (c.id < 1) {
            return {
              ...c,
              employeeId: toNumber(c.employeeId),
              id: 0,
              workerclass: null,
              functionId: null,
              wageExemption: toNumber(c.wageExemption),
              paritairComiteId: c.paritairComiteId,
            };
          }
          return {
            ...c,
            employeeId: toNumber(c.employeeId),
            workerclass: null,
            functionId: null,
            wageExemption: toNumber(c.wageExemption),
            paritairComiteId: c.paritairComiteId,
          };
        }),
      );
    }
    dispatch(updateWageExemptionsAction(getValues()));
  }, [companyId, dispatch, functionExemptions, employeeExemptions, getValues, setValue, workerclassExemptions]);

  const onCancel = useCallback(() => {
    reset(wageExemptions);
    navigate(`/company/${companyId}/general`);
  }, [companyId, navigate, reset, wageExemptions]);

  useEffect(() => {
    if (wageExemptions) {
      reset(wageExemptions);
      setDisplayWorkerclassSwitch(wageExemptions.wageExemptions);
      setDisplayFunctionSwitch(wageExemptions.wageExemptions);
      setDisplayEmployeeSwitch(wageExemptions.wageExemptions);
      setDisplayWorkerclassGrid(wageExemptions.workerclass);
      setDisplayFunctionGrid(wageExemptions.function);
      setDisplayEmployeeGrid(wageExemptions.employee);
      if (wageExemptions.workerclassExemptions != null) {
        setWorkerclassExemptions(wageExemptions.workerclassExemptions);
      }
      if (wageExemptions.functionExemptions != null) {
        setFunctionExemptions(wageExemptions.functionExemptions);
      }
      if (wageExemptions.employeeExemptions != null) {
        setEmployeeExemptions(wageExemptions.employeeExemptions);
      }
    }
  }, [reset, wageExemptions]);

  return (
    <WageExemptionsContainer>
      <Box sx={{ display: 'flex' }}>
        <Controller
          name="wageExemptions"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={
                <UPSwitch checked={field.value} onChange={event => onChangeWageExemptionsSwitch(event, field)} />
              }
              label={<span>{t(COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE)}</span>}
              sx={{
                '.MuiTypography-root': {
                  fontSize: '14px',
                  marginLeft: '10px',
                  marginTop: '2px',
                },
                '&.MuiFormControlLabel-root': {
                  marginBottom: '8px',
                  marginLeft: '0px',
                },
              }}
            />
          )}
        />
        {displayWorkerclassSwitch && (
          <Controller
            name="workerclass"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<UPSwitch checked={field.value} onChange={event => onChangeWorkerclassSwitch(event, field)} />}
                label={<span>{t(COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE_WORKERCLASS)}</span>}
                sx={{
                  '.MuiTypography-root': {
                    fontSize: '14px',
                    marginLeft: '10px',
                    marginTop: '2px',
                  },
                  '&.MuiFormControlLabel-root': {
                    marginBottom: '8px',
                    marginLeft: '0px',
                  },
                }}
              />
            )}
          />
        )}
        {displayFunctionSwitch && (
          <Controller
            name="function"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<UPSwitch checked={field.value} onChange={event => onChangeFunctionSwitch(event, field)} />}
                label={<span>{t(COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE_FUNCTION)}</span>}
                sx={{
                  '.MuiTypography-root': {
                    fontSize: '14px',
                    marginLeft: '10px',
                    marginTop: '2px',
                  },
                  '&.MuiFormControlLabel-root': {
                    marginBottom: '8px',
                    marginLeft: '0px',
                  },
                }}
              />
            )}
          />
        )}
        {displayEmployeeSwitch && (
          <Controller
            name="employee"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<UPSwitch checked={field.value} onChange={event => onChangeEmployeeSwitch(event, field)} />}
                label={<span>{t(COMPANY_PAYROLL__ENABLE_EMPLOYEE)}</span>}
                sx={{
                  '.MuiTypography-root': {
                    fontSize: '14px',
                    marginLeft: '10px',
                    marginTop: '2px',
                  },
                  '&.MuiFormControlLabel-root': {
                    marginBottom: '8px',
                    marginLeft: '0px',
                  },
                }}
              />
            )}
          />
        )}
      </Box>
      {displayWorkerclassGrid && displayWorkerclassSwitch && (
        <WorkerclassExemptionsGrid
          workerclassExemptions={workerclassExemptions}
          setWorkerclassExemptions={setWorkerclassExemptions}
          setErrorMessage={setErrorMessage}
        />
      )}
      {displayFunctionSwitch && displayFunctionGrid && (
        <FunctionExemptionsGrid
          functionExemptions={functionExemptions}
          setFunctionExemptions={setFunctionExemptions}
          setErrorMessage={setErrorMessage}
        />
      )}
      {displayEmployeeSwitch && displayEmployeeGrid && (
        <EmployeeExemptionsGrid
          employeesExemptions={employeeExemptions}
          setEmployeesExemptions={setEmployeeExemptions}
          setErrorMessage={setErrorMessage}
        />
      )}
      <Box sx={{ marginTop: '32px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <Box sx={{ marginRight: '16px' }}>
          <UPButton
            text={t(GENERAL__SAVE_BUTTON)}
            disabled={userIsCompanyManager || !!errorMessage}
            onClick={() => handleSubmit(onSaveWageExemptions)()}
          />
        </Box>
        <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} disabled={userIsCompanyManager} outlined />
      </Box>
      <Box sx={{ marginTop: '32px' }}>
        {updateWageExemptionSuccess && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updateWageExemptionsSuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY)}
          </Alert>
        )}
        {errorMessage && (
          <Alert severity="error" icon={false} variant="filled" sx={{ width: '800px' }}>
            {errorMessage}
          </Alert>
        )}
      </Box>
    </WageExemptionsContainer>
  );
};

export default PayrollWageExemptions;
