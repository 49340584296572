import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { IMinimumWage, IMinimumWageFilters } from 'types/minimum-wage';

export const getMinimumWagesByParitairComiteRequest = async (filters: IMinimumWageFilters): Promise<IMinimumWage[]> => {
  const response: AxiosResponse<IMinimumWage[]> = await getApi().post(`MinimumWage`, filters);
  return response.data;
};

export const saveMinimumWageRequest = async (minimumWage: IMinimumWage): Promise<number> => {
  const response: AxiosResponse<number> = await getApi().post(`MinimumWage/Add`, minimumWage);
  return response.data;
};

export const updateMinimumWageRequest = async (minimumWage: IMinimumWage): Promise<void> => {
  await getApi().put(`MinimumWage`, minimumWage);
};
