import { createReducer } from '@reduxjs/toolkit';
import {
  addOneApiUserAction,
  removeAllApiUserssAction,
  removeManyApiUsersAction,
  setApiUsersAction,
  setApiUsersRequestsLoadingAction,
  updateOneApiUserAction,
} from 'store/actions/superadmins-actions';
import apiUsersAdapter from 'store/adapters/api-users-adapter';
import ISuperAdminsState from 'types/superadmin/ISuperAdminsState';

const initialState: ISuperAdminsState = {
  superAdmins: [],
  loading: false,
  superAdminsError: false,
  apiUsers: [],
};

const apiUsersReducer = createReducer(apiUsersAdapter.getInitialState<ISuperAdminsState>(initialState), builder => {
  return builder
    .addCase(setApiUsersAction, apiUsersAdapter.setAll)
    .addCase(updateOneApiUserAction, apiUsersAdapter.updateOne)
    .addCase(addOneApiUserAction, apiUsersAdapter.addOne)
    .addCase(removeManyApiUsersAction, apiUsersAdapter.removeMany)
    .addCase(removeAllApiUserssAction, apiUsersAdapter.removeAll)
    .addCase(setApiUsersRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }));
});

export default apiUsersReducer;
