import { SectionContainer } from 'modules/companies/styles';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { toNumber } from 'lodash';
import {
  COMPANY__LOCATIONS,
  EMPLOYEES__IS_FOR_ALL_LOCATIONS,
  GENERAL__CANCEL_BUTTON,
  GENERAL__COPIED_TO_CLIPBOARD,
} from 'translations/constants';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { UPDataGrid } from 'components/data-grid';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { employeeCompanyEmployeeInvitationLinksSelector } from 'store/selectors/employee-selector';
import {
  deleteCompanyEmployeeInvitationLinks,
  getCompanyEmployeeInvitationLinks,
} from 'store/actions/employee-actions';
import { UPButtonStyledWithNoPadding } from 'components/button/styles';
import { IGeneralInvitationProps } from './types';
import { ReactComponent as DeleteIconGreen } from '../../../assets/icons/DeleteGreen.svg';
import { ReactComponent as CopyToClipboard } from '../../../assets/icons/CopyToClipboard.svg';
import { ModalFooter } from './styles';

const GenralEmployeeInvitation = (props: IGeneralInvitationProps) => {
  const { companyId, onClose } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [copied, setCopied] = useState(false);
  const [copiedRow, setCopiedRow] = useState<number>(0);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const inivtations = useAppSelector(employeeCompanyEmployeeInvitationLinksSelector);

  useEffect(() => {
    if (companyId) dispatch(getCompanyEmployeeInvitationLinks(toNumber(companyId)));
  }, [companyId, dispatch]);

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setCopiedRow(rowOnHover);
  };

  const onDeleteInvitationLink = (id: number) => {
    dispatch(deleteCompanyEmployeeInvitationLinks({ id, companyId: toNumber(companyId) }));
    dispatch(getCompanyEmployeeInvitationLinks(toNumber(companyId)));
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    // {
    //   field: 'language',
    //   align: 'left',
    //   width: 150,
    //   headerName: t(GENERAL__LANGUAGE),
    // },
    {
      field: 'locations',
      align: 'left',
      width: 450,
      headerName: t(COMPANY__LOCATIONS),
      renderCell: params => {
        return params.row.isForAllLocations ? t(EMPLOYEES__IS_FOR_ALL_LOCATIONS) : params.row.locations.join(', ');
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <Box display="flex">
              <UPButtonStyledWithNoPadding
                onClick={() => {
                  onDeleteInvitationLink(toNumber(params.id));
                }}
              >
                <DeleteIconGreen style={{ cursor: 'pointer' }} />
              </UPButtonStyledWithNoPadding>
              <UPButtonStyledWithNoPadding>
                <CopyToClipboard
                  onClick={() => handleCopyClick(params.row.uniqueIdentifier)}
                  style={{ cursor: 'pointer' }}
                />
                {copied && rowOnHover === copiedRow && (
                  <span style={{ paddingLeft: '0.5rem', fontSize: '75%' }}>{t(GENERAL__COPIED_TO_CLIPBOARD)}</span>
                )}
              </UPButtonStyledWithNoPadding>
            </Box>
          );
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer>
      <Box sx={{ height: '50vh', width: '100%' }}>
        <UPDataGrid
          rows={inivtations}
          columns={columns}
          setRowOnHover={setRowOnHover}
          withCheckboxes={false}
          getRowId={r => r.id}
          disableSelectionOnClick
        />
      </Box>
      <ModalFooter>
        <Box sx={{ marginLeft: '12px' }}>
          <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onClose} outlined />
        </Box>
      </ModalFooter>
    </SectionContainer>
  );
};

export default GenralEmployeeInvitation;
