import { createReducer } from '@reduxjs/toolkit';
import {
  setLocationAction,
  setLocationErrorAction,
  setLocationRequestsLoadingAction,
} from 'store/actions/company-location-actions';
import ILocationState from 'types/company/locations/ILocationState';

const initialState: ILocationState = {
  location: false,
  loading: false,
  locationError: false,
};

const locationReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setLocationAction, (state, action) => ({ ...state, location: action.payload }))
    .addCase(setLocationRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setLocationErrorAction, (state, action) => ({
      ...state,
      locationError: action.payload,
    }));
});

export default locationReducer;
