import { useTranslation } from 'react-i18next';
import {
  WEEKDAY__FRIDAY_ABBREVIATION,
  WEEKDAY__MONDAY_ABBREVIATION,
  WEEKDAY__SATURDAY_ABBREVIATION,
  WEEKDAY__SUNDAY_ABBREVIATION,
  WEEKDAY__THURSDAY_ABBREVIATION,
  WEEKDAY__TUESDAY_ABBREVIATION,
  WEEKDAY__WEDNESDAY_ABBREVIATION,
} from 'translations/constants';
import { AvailabilitiesCalendarHeaderContainer, CalendarHeaderDay } from './styles';

const AvailabilitiesCalendarHeader = (): JSX.Element => {
  const [t] = useTranslation();

  const weekDaysNames = [
    t(WEEKDAY__MONDAY_ABBREVIATION),
    t(WEEKDAY__TUESDAY_ABBREVIATION),
    t(WEEKDAY__WEDNESDAY_ABBREVIATION),
    t(WEEKDAY__THURSDAY_ABBREVIATION),
    t(WEEKDAY__FRIDAY_ABBREVIATION),
    t(WEEKDAY__SATURDAY_ABBREVIATION),
    t(WEEKDAY__SUNDAY_ABBREVIATION),
  ];

  const findRightTranslation = (dayAbr: string) => {
    switch (dayAbr) {
      case 'Mon':
        return weekDaysNames[0];
      case 'Tue':
        return weekDaysNames[1];
      case 'Wed':
        return weekDaysNames[2];
      case 'Thu':
        return weekDaysNames[3];
      case 'Fri':
        return weekDaysNames[4];
      case 'Sat':
        return weekDaysNames[5];
      default:
        return weekDaysNames[6];
    }
  };

  return (
    <AvailabilitiesCalendarHeaderContainer>
      <CalendarHeaderDay>{findRightTranslation('Mon')}</CalendarHeaderDay>
      <CalendarHeaderDay>{findRightTranslation('Tue')}</CalendarHeaderDay>
      <CalendarHeaderDay>{findRightTranslation('Wed')}</CalendarHeaderDay>
      <CalendarHeaderDay>{findRightTranslation('Thu')}</CalendarHeaderDay>
      <CalendarHeaderDay>{findRightTranslation('Fri')}</CalendarHeaderDay>
      <CalendarHeaderDay>{findRightTranslation('Sat')}</CalendarHeaderDay>
      <CalendarHeaderDay>{findRightTranslation('Sun')}</CalendarHeaderDay>
    </AvailabilitiesCalendarHeaderContainer>
  );
};

export default AvailabilitiesCalendarHeader;
