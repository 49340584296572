import { createReducer } from '@reduxjs/toolkit';
import {
  setCompanyFinancialAction,
  setCompanyFinancialErrorAction,
  setCompanyFinancialRequestsLoadingAction,
  setCompanyFinancialUpdateSuccessfulAction,
  clearCompanyFinancialAction,
  setCompanyFinancialErrorMessageAction,
} from 'store/actions/company-financial-actions';
import IFinancialState from 'types/company/financial/IFinancialState';

const initialState: IFinancialState = {
  companyFinancial: null,
  loading: false,
  companyFinancialError: false,
  companyFinancialUpdateSuccessful: false,
  companyFinancialErrorMessage: '',
};

const companyFinancialReducer = createReducer(initialState, builder =>
  builder
    .addCase(setCompanyFinancialAction, (state, action) => ({ ...state, companyFinancial: action.payload }))
    .addCase(setCompanyFinancialRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setCompanyFinancialErrorAction, (state, action) => ({
      ...state,
      companyFinancialError: action.payload,
    }))
    .addCase(setCompanyFinancialUpdateSuccessfulAction, (state, action) => ({
      ...state,
      companyFinancialUpdateSuccessful: action.payload,
    }))
    .addCase(clearCompanyFinancialAction, state => ({
      ...state,
      companyFinancial: null,
      loading: false,
      companyFinancialError: false,
      companyFinancialUpdateSuccessful: false,
    }))
    .addCase(setCompanyFinancialErrorMessageAction, (state, action) => ({
      ...state,
      companyFinancialErrorMessage: action.payload,
    })),
);

export default companyFinancialReducer;
