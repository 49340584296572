import axios, { Axios } from 'axios';
import { isEmpty } from 'lodash';
import { getConfig } from 'config';
import store from 'store';
import { authStateSelector, authTokenSelector } from 'store/selectors/auth-selectors';
import logError from 'utils/log-error';
import { logoutAction } from 'store/actions/auth-actions';

let instance: Axios = null;

export const initApi = (): void => {
  instance = axios.create({
    baseURL: getConfig('API_ENDPOINT'),
  });

  instance.interceptors.request.use(config => {
    const newConfig = { ...config };
    if (authStateSelector(store.getState())) {
      const token = authTokenSelector(store.getState());
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
    return newConfig;
  });

  instance.interceptors.response.use(
    res => {
      return res;
    },
    error => {
      if (error?.response?.status === 401) {
        store.dispatch(logoutAction(true));
      }

      if (error?.response?.status === 500) {
        logError(error);
      }
      return Promise.reject(error);
    },
  );
};

export const getApi = (): Axios => {
  if (isEmpty(instance)) {
    initApi();
  }
  return instance;
};
