/* eslint-disable react/jsx-props-no-spreading */
import { Card, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TAB_ROUTE__HOME_NAME } from 'translations/constants';

const Register = (): JSX.Element => {
  const [t] = useTranslation();

  return (
    <div>
      <Card>
        <CardHeader title={t(TAB_ROUTE__HOME_NAME)} />
      </Card>
    </div>
  );
};

export default Register;
