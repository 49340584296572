import { Box, Tooltip } from '@mui/material';
import UPButton from 'components/button';
import { UPDropdown } from 'components/dropdown';
import { UPTable } from 'components/table';
import UPWeekPicker from 'components/week-picker';
import { CompanyRole } from 'constants/CompanyRole';
import { ReportType } from 'constants/ReportType';
import useReportingFilters from 'hooks/reporting/useReportingFilters';
import { t } from 'i18next';
import { toNumber } from 'lodash';
import moment from 'moment';
import { SectionContainer } from 'modules/companies/styles';
import { getCurrentTime, getCurrentWeek } from 'modules/contracts/helpers';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getCompaniesAsDropdownItemsAction } from 'store/actions/company-actions';
import { getCostCentersDropdownItemsAction } from 'store/actions/company-cost-centers-actions';
import { getCountriesNamesAction } from 'store/actions/countries-actions';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { getParitairComiteSelectItemsAction } from 'store/actions/paritair-comite-actions';
import { clearReportsAction, getReportsAction } from 'store/actions/reporting-actions';
import { getSuperAdminsAction } from 'store/actions/superadmins-actions';
import { profileSelector } from 'store/selectors/profile-selectors';
import { reportDataSelector } from 'store/selectors/reporting-selector';
import {
  COST_ESTIMATION__TOOLTIP,
  GENERAL__FROM,
  GENERAL__SHOW,
  GENERAL__UNTIL,
  HEADER__WEEK,
} from 'translations/constants';
import ReportingFilter from 'types/reporting/ReportingFilter';
import { AllowedCompaniesForWizard } from 'constants/CustomFeatureEnabledForCompanies';
import { ReactComponent as InfoIcon } from '../../assets/icons/InfoIcon.svg';
import BaseReportingFilters from './filters/BaseReportingFilters';
import ClientPerformanceFilters from './filters/ClientPerformanceFilters';
import FTECheckFilters from './filters/FTECheckFilters';
import GeneralStatisticsFilters from './filters/GeneralStatisticsFilters';
import StatisticsPerCostCenterFilters from './filters/StatisticsPerCostCenterFilters';
import {
  getReportTypesDropdownValues,
  isReportWithInterval,
  mapDataToClientPerformanceReport,
  mapDataToFTECheckReport,
  mapDataToRequiredActionsReportData,
  mapDataToStatisticsPerCostCenterReport,
  mapDataToWeeklyReportData,
} from './helpers';
import ClientPerformanceReport from './reports/ClientPerformance/ClientPerformanceReport';
import FTECheckReport from './reports/FTECheckReport';
import RequiredActionsReport from './reports/RequiredActionsReport';
import StatisticsPerCostCenterReport from './reports/StatisticsPerCostCenterReport';
import WeeklyContractCountReport from './reports/WeeklyContractCountReport';

const Reporting = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const reportData = useAppSelector(reportDataSelector);
  const [year, setYear] = useState<number | null>(() => new Date(Date.now()).getFullYear());
  const [week, setWeek] = useState<Date | null>(getCurrentTime().date);
  const currentWeek = getCurrentWeek(week);
  const [weekRange, setWeekRange] = useState<[Date, Date]>([week, week]);
  const [yearRange, setYearRange] = useState<[number, number]>([year, year]);
  const startWeek = getCurrentWeek(weekRange[0]);
  const endWeek = getCurrentWeek(weekRange[1]);
  const profile = useAppSelector(profileSelector);
  const isAdmin = profile.role === CompanyRole.ADMIN;
  const userCompany = profile.companyId;
  const allowedRoles = [CompanyRole.ADMIN, CompanyRole.HR_MANAGER, CompanyRole.COMPANY_MANAGER];
  const [reportType, setReportType] = useState<ReportType>(
    isAdmin ? ReportType.WEEKLY_CONTRACT_COUNT : ReportType.STATISTICS_PER_COST_CENTER,
  );

  const allowedCompaniesForWizard = AllowedCompaniesForWizard;

  if (
    !isAdmin &&
    !(allowedCompaniesForWizard.includes(toNumber(profile?.currentCompany)) || toNumber(profile?.currentCompany) > 100)
  ) {
    window.location.href = '/';
  }

  const { dropdownData, reportingFilters, handleFilterChange } = useReportingFilters();

  const {
    selectedCompany,
    selectedCompanies,
    selectedParitairComites,
    selectedSuperAdmins,
    selectedEmployees,
    selectedWorkerClasses,
    selectedStatus,
    companyName,
    selectedNationalities,
    selectedBirthCountries,
    selectedLanguages,
    employeeName,
    ageRange,
    experienceRange,
    confirmedOnly,
    noShowOnly,
    selectedYear,
    selectedMonth,
    selectedHourType,
    selectedCostCenters,
  } = reportingFilters;

  const generateFiltersObject = (): ReportingFilter => {
    let companyIds: number[] = [];

    if (!isAdmin && userCompany) {
      companyIds = [+userCompany];
    } else if (isAdmin && selectedCompany !== '') {
      companyIds = [+selectedCompany];
    } else {
      companyIds = selectedCompanies.map(Number);
    }

    return {
      ReportType: toNumber(reportType),
      Year: selectedYear ?? year, // selected year from type 4 reporting
      Week: currentWeek,
      FromWeek: isReportWithInterval(reportType) ? getCurrentWeek(weekRange[0]) : undefined,
      ToWeek: isReportWithInterval(reportType) ? getCurrentWeek(weekRange[1]) : undefined,
      FromYear: isReportWithInterval(reportType) ? yearRange[0] : undefined,
      ToYear: isReportWithInterval(reportType) ? yearRange[1] : undefined,
      CompanyName: companyName,
      CompanyIds: companyIds,
      EmployeeName: employeeName,
      ContractStatus: '',
      WorkerClass: selectedWorkerClasses,
      NoShow: noShowOnly,
      Confirmed: confirmedOnly,
      EmployeeNationality: '',
      EmployeeNationalityId: selectedNationalities.map(Number),
      EmployeeLanguage: '',
      EmployeeLanguageId: selectedLanguages.map(Number),
      EmployeeBirthCountryId: selectedBirthCountries.map(Number),
      Location: '',
      CostCenterIds: selectedCostCenters.map(Number),
      CostCenter: '',
      Function: '',
      ParitaireComite: '',
      EmployeeYearsOfExperience: experienceRange,
      EmployeeAge: ageRange,
      EmployeeIds: selectedEmployees.map(Number),
      ParitaireComiteId: selectedParitairComites.map(Number),
      PayrollOfficer: selectedSuperAdmins.map(Number),
      Status: selectedStatus,
      Month: selectedMonth,
      HoursType: selectedHourType,
    };
  };

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsAction());
    dispatch(getCompaniesAsDropdownItemsAction());
    dispatch(getCountriesNamesAction('en')); // defult language
    dispatch(getSuperAdminsAction());
    if (selectedCompany !== '') {
      dispatch(getCostCentersDropdownItemsAction({ companyId: +selectedCompany }));
      dispatch(getEmployeesWithWorkerclass(+selectedCompany));
    }
  }, [dispatch, reportType, selectedCompany]);

  const validFilters = (filters: ReportingFilter): boolean => {
    // mock the validations here!
    if (reportType === ReportType.CLIENT_PERFORMANCE && (!filters.Year || !filters.Month)) {
      return false;
    }
    return true;
  };

  const renderData = () => {
    const filters = generateFiltersObject();
    if (validFilters(filters)) dispatch(getReportsAction(filters));
  };

  useEffect(() => {
    dispatch(clearReportsAction());
  }, [dispatch]);

  const onWeekPickerChange = (value: Date) => {
    setWeek(value);
    setYear(new Date(value).getFullYear());
  };

  const onStartWeekPickerChange = (value: Date) => {
    if (weekRange[1] < value) {
      setWeekRange([value, value]);
      setYearRange([new Date(value).getFullYear(), new Date(value).getFullYear()]);
      return;
    }
    setWeekRange([value, weekRange[1]]);
    setYearRange([new Date(value).getFullYear(), new Date(weekRange[1]).getFullYear()]);
  };

  const onEndWeekPickerChange = (value: Date) => {
    if (weekRange[0] > value) {
      setWeekRange([value, value]);
      setYearRange([new Date(value).getFullYear(), new Date(value).getFullYear()]);
      return;
    }
    setWeekRange([weekRange[0], value]);
    setYearRange([new Date(weekRange[0]).getFullYear(), new Date(value).getFullYear()]);
  };

  const options = {
    search: false,
    download: false,
    print: false,
    viewColumns: false,
    filter: false,
    filterType: 'dropdown',
    selectableRows: 'none',
  };

  const transformResponseData = () => {
    if (reportData?.items) {
      return reportData.items.map(item => Object.values(item));
    }

    return [];
  };

  if (!allowedRoles.includes(profile.role) && !isAdmin) {
    window.location.href = '/';
  }

  const regularWeekPicker = () => {
    return (
      <UPWeekPicker
        value={week}
        weekValue={currentWeek}
        onChange={onWeekPickerChange}
        label={t(HEADER__WEEK)}
        className="datepicker--white-background"
      />
    );
  };

  const rangeWeekPicker = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ marginRight: '16px' }}>
          <UPWeekPicker
            value={weekRange[0]}
            weekValue={startWeek}
            onChange={onStartWeekPickerChange}
            label={t(GENERAL__FROM)}
            className="datepicker--white-background"
            minDate={
              reportType === ReportType.STATISTICS_PER_COST_CENTER ? moment().subtract(1, 'years').toDate() : undefined
            }
          />
        </Box>
        <Box>
          <UPWeekPicker
            value={weekRange[1]}
            weekValue={endWeek}
            onChange={onEndWeekPickerChange}
            label={t(GENERAL__UNTIL)}
            className="datepicker--white-background"
            minDate={
              reportType === ReportType.STATISTICS_PER_COST_CENTER ? moment().subtract(1, 'years').toDate() : undefined
            }
          />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <SectionContainer sx={{ paddingTop: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ alignSelf: 'flex-start', marginRight: 'auto' }}>
            {isReportWithInterval(reportType) ? rangeWeekPicker() : regularWeekPicker()}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box sx={{ alignSelf: 'center', marginRight: 'auto', marginLeft: 'auto' }}>
              <UPDropdown
                items={getReportTypesDropdownValues(profile.role)}
                onChange={e => {
                  setReportType(e.target.value);
                  dispatch(clearReportsAction());
                }}
                value={reportType}
                label=""
                disableUnderline
              />
            </Box>
            {reportType === ReportType.STATISTICS_PER_COST_CENTER && (
              <Tooltip title={t(COST_ESTIMATION__TOOLTIP)} placement="left" arrow>
                <InfoIcon />
              </Tooltip>
            )}
          </Box>
          <Box sx={{ alignSelf: 'flex-end', marginLeft: 'auto', marginBottom: '18px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <UPButton text={t(GENERAL__SHOW)} onClick={() => renderData()} />
            </Box>
          </Box>
        </Box>
        {(reportType === ReportType.WEEKLY_CONTRACT_COUNT || reportType === ReportType.GENERAL_STATISTICS) && (
          <BaseReportingFilters
            filters={reportingFilters}
            dropdownData={dropdownData}
            handleFilterChange={handleFilterChange}
          />
        )}
        {reportType === ReportType.GENERAL_STATISTICS && (
          <GeneralStatisticsFilters
            filters={reportingFilters}
            dropdownData={dropdownData}
            handleFilterChange={handleFilterChange}
          />
        )}
        {reportType === ReportType.CLIENT_PERFORMANCE && (
          <ClientPerformanceFilters
            filters={reportingFilters}
            dropdownData={dropdownData}
            handleFilterChange={handleFilterChange}
          />
        )}
        {reportType === ReportType.STATISTICS_PER_COST_CENTER && (
          <StatisticsPerCostCenterFilters
            filters={reportingFilters}
            dropdownData={dropdownData}
            handleFilterChange={handleFilterChange}
          />
        )}
        {reportType === ReportType.FTE_CHECK && (
          <FTECheckFilters
            filters={reportingFilters}
            dropdownData={dropdownData}
            handleFilterChange={handleFilterChange}
          />
        )}
      </SectionContainer>
      <SectionContainer sx={{ paddingTop: '16px' }}>
        <Box sx={{ width: '100%' }}>
          {reportType === ReportType.WEEKLY_CONTRACT_COUNT && (
            <WeeklyContractCountReport
              items={mapDataToWeeklyReportData(reportData)}
              year={year}
              week={currentWeek}
              renderData={renderData}
            />
          )}
          {reportType === ReportType.GENERAL_STATISTICS && (
            <UPTable columns={reportData?.headerColumns} data={transformResponseData()} options={options} />
          )}
          {reportType === ReportType.REQUIRED_ACTIONS && (
            <RequiredActionsReport items={mapDataToRequiredActionsReportData(reportData)} />
          )}
          {reportType === ReportType.CLIENT_PERFORMANCE && (
            <ClientPerformanceReport items={mapDataToClientPerformanceReport(reportData)} />
          )}
          {reportType === ReportType.STATISTICS_PER_COST_CENTER && (
            <StatisticsPerCostCenterReport items={mapDataToStatisticsPerCostCenterReport(reportData)} />
          )}
          {reportType === ReportType.FTE_CHECK && (
            <FTECheckReport
              items={mapDataToFTECheckReport(reportData)}
              showCostCenterColumn={selectedCostCenters.length > 0}
            />
          )}
        </Box>
      </SectionContainer>
    </>
  );
};

export default Reporting;
