import { Dispatch, SetStateAction } from 'react';
import { INightShift, IServiceCode, IWageCalculationComponent, IOvertimeLimits } from 'types/wage-calculation';
import IWorkhourSettings from 'types/wage-calculation/IWorkhourSettings';

interface ServiceCodesProps {
  companyId?: number;
}

interface ServiceCodeSchemasProps {
  setShouldUpdate: Dispatch<SetStateAction<boolean>>;
  setValidValues: Dispatch<SetStateAction<boolean>>;
}

interface ServiceCodesTableProps extends ServiceCodeSchemasProps {
  wageCalculationComponent: IWageCalculationComponent;
  setWageCalculationComponent: Dispatch<SetStateAction<IWageCalculationComponent>>;
}

interface ServiceCodesTableRowProps extends ServiceCodeSchemasProps {
  serviceCode: IServiceCode;
  setServiceCode: Dispatch<SetStateAction<IServiceCode>>;
  outcome: string;
}

interface NightShiftSchemaProps extends ServiceCodeSchemasProps {
  nightShiftRule: INightShift;
  setNightShiftRule: Dispatch<SetStateAction<INightShift>>;
}

interface OvertimeLimitsProps {
  overtimeLimits: IOvertimeLimits;
  setOvertimeLimits: Dispatch<SetStateAction<IOvertimeLimits>>;
  setShouldUpdate: Dispatch<SetStateAction<boolean>>;
  setValidValues: Dispatch<SetStateAction<boolean>>;
}

interface WorkhourSettingsProps {
  workhourSettings: IWorkhourSettings;
  setWorkhourSettings: Dispatch<SetStateAction<IWorkhourSettings>>;
  setShouldUpdate: Dispatch<SetStateAction<boolean>>;
  setValidValues: Dispatch<SetStateAction<boolean>>;
}

export enum ServiceCodesAction {
  UPDATE,
  RESET,
}

export enum OvertimeLimitsFields {
  DAILY = 'dailyLimit',
  WEEKLY = 'weeklyLimit',
}

export enum WorkhourSettingsFields {
  MIN_HOURS = 'minimumWorkhours',
  MAX_HOURS = 'maximumWorkhours',
  SCHEDULE = 'schedule',
}

export type {
  ServiceCodesProps,
  ServiceCodesTableProps,
  ServiceCodesTableRowProps,
  NightShiftSchemaProps,
  OvertimeLimitsProps,
  WorkhourSettingsProps,
};
