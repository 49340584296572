import { Box, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { AlertStyled } from 'components/bottom-green-bar/styles';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { CompanyRole } from 'constants/CompanyRole';
import { SectionContainer } from 'modules/companies/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteDocumentAction,
  downloadDocumentAction,
  downloadMultipleDocumentAction,
} from 'store/actions/documents-action';
import { documentsSelector } from 'store/selectors/documents-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  CONTRACTS__ARE_YOU_SURE,
  DOCUMENTS__BANKING_MANDATE,
  DOCUMENTS__BANK_CARD,
  DOCUMENTS__CONFIRMATION_SHEET,
  DOCUMENTS__CONTRACT,
  DOCUMENTS__DATE,
  DOCUMENTS__DOCUMENT_CATEGORY,
  DOCUMENTS__DOCUMENT_NAME,
  DOCUMENTS__DRIVING_LICENSE,
  DOCUMENTS__EMPLOYEE,
  DOCUMENTS__ID,
  DOCUMENTS__INVOICE,
  DOCUMENTS__MEDICAL_CHECK,
  DOCUMENTS__PARTNERSHIP_AGREEMENT,
  DOCUMENTS__PERIOD_END,
  DOCUMENTS__PERIOD_START,
  DOCUMENTS__SAFETY_PASSPORT,
  DOCUMENTS__SELECTED,
  DOCUMENTS__SHARED_FILES,
  DOCUMENTS__STUDENT_PROOF_OF_INSCRIPTION,
  DOCUMENTS__VCA,
  DOCUMENTS__WORKPOSTFILE,
  DOCUMENTS__WORK_PERMIT,
  GENERAL__CLEAR_ALL,
} from 'translations/constants';
import { IDocument } from 'types/documents';
import { IViewDocument } from 'types/documents/IDocument';
import { ReactComponent as DeleteIcon } from '../../assets/icons/DeleteGrey.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/DownloadGrey.svg';
import DocumentsMobileView from './DocumentsMobileView';
import './styles.scss';
import { DocumentCategories, DocumentGridViewProps } from './types';

const DocumentsGridView = (props: DocumentGridViewProps): JSX.Element => {
  const { category, startDate, endDate } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const documents = useAppSelector(documentsSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [idToDelete, setIdToDelete] = useState<number>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const userRole = useAppSelector(profileSelector).role;
  const canDeleteSharedFile = userRole === CompanyRole.ADMIN || userRole === CompanyRole.COMPANY_MANAGER;

  const documentCategoriesArray = useMemo(
    () => [
      t(DOCUMENTS__CONTRACT),
      t(DOCUMENTS__INVOICE),
      t(DOCUMENTS__ID),
      t(DOCUMENTS__WORKPOSTFILE),
      t(DOCUMENTS__BANKING_MANDATE),
      t(DOCUMENTS__STUDENT_PROOF_OF_INSCRIPTION),
      t(DOCUMENTS__VCA),
      t(DOCUMENTS__MEDICAL_CHECK),
      t(DOCUMENTS__SAFETY_PASSPORT),
      t(DOCUMENTS__BANK_CARD),
      t(DOCUMENTS__DRIVING_LICENSE),
      t(DOCUMENTS__WORK_PERMIT),
      t(DOCUMENTS__CONFIRMATION_SHEET),
      t(DOCUMENTS__PARTNERSHIP_AGREEMENT),
      t(DOCUMENTS__SHARED_FILES),
    ],

    [t],
  );
  const padTo2Digits = num => {
    return num.toString().padStart(2, '0');
  };

  const formatDateToString = (date: Date): string => {
    if (!date) return '';
    const auxDate = new Date(date);
    return [auxDate.getFullYear(), padTo2Digits(auxDate.getMonth() + 1), padTo2Digits(auxDate.getDate())].join('-');
  };

  const generateDocumentsWithCategories = (docs: IDocument[]): IViewDocument[] => {
    const auxDocs = docs.map(d => {
      return {
        ...d,
        name: d.name.length > 39 ? `${d.name.substring(0, 39)}...` : d.name,
        categoryName: documentCategoriesArray[d.category - 1],
        documentTime: `${d.documentDate}`,
        periodStart: formatDateToString(d.periodStart),
        periodEnd: formatDateToString(d.periodEnd),
      };
    });
    return auxDocs;
  };

  const findDocumentName = (id: number) => {
    const found = documents.find(doc => doc.id === id);
    return found.name;
  };

  const userCanDeleteCheck = (docId: number) => {
    if (isEmployee) return false;

    const documentType = documents.find(doc => doc.id === docId).category;
    const isSharedFile = documentType === Number(DocumentCategories.SHARED_FILES);
    return !isSharedFile || (isSharedFile && canDeleteSharedFile);
  };

  const onDeleteDocument = (id: number) => {
    dispatch(
      deleteDocumentAction({
        companyId: documents[0].companyId,
        id,
        category,
        startDate,
        endDate,
      }),
    );
  };

  const onDownloadDocument = (id: number) => {
    dispatch(
      downloadDocumentAction({
        companyId: documents[0].companyId,
        id,
        name: findDocumentName(id),
      }),
    );
  };

  const onDeleteButtonPress = (id: number) => {
    setIdToDelete(id);
    setOpenDeleteModal(true);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'noName', headerName: '', width: 50, sortable: false },
    {
      field: 'name',
      headerName: t(DOCUMENTS__DOCUMENT_NAME),
      width: 500,
      align: 'left',
      headerAlign: 'center',
    },
    { field: 'documentTime', headerName: t(DOCUMENTS__DATE), width: 150, align: 'center', headerAlign: 'center' },
    {
      field: 'categoryName',
      headerName: t(DOCUMENTS__DOCUMENT_CATEGORY),
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'periodStart',
      headerName: t(DOCUMENTS__PERIOD_START),
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },
    { field: 'periodEnd', headerName: t(DOCUMENTS__PERIOD_END), width: 140, align: 'center', headerAlign: 'center' },
    {
      field: 'employeeName',
      headerName: t(DOCUMENTS__EMPLOYEE),
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <div>
              <DownloadIcon className="documentsIcon" onClick={() => onDownloadDocument(rowOnHover)} />
              {userCanDeleteCheck(params.id) && (
                <DeleteIcon onClick={() => onDeleteButtonPress(rowOnHover)} className="documentsIcon" />
              )}
            </div>
          );
        }
        return null;
      },
    },
  ];

  const onDownloadDocuments = useCallback(() => {
    dispatch(
      downloadMultipleDocumentAction({
        companyId: documents[0].companyId,
        id: selectedRows,
        zipName: 'documents.zip',
      }),
    );
  }, [documents, selectedRows, dispatch]);

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down(768));

  // behold fellow developer
  // this page requires a lot of refactoring!

  if (isSmScreen) {
    return (
      <Box>
        <DocumentsMobileView
          documents={generateDocumentsWithCategories(documents)}
          isEmployee={isEmployee}
          onDownloadDocument={onDownloadDocument}
          onDeleteButtonPress={onDeleteButtonPress}
        />
        {openDeleteModal && (
          <DeleteModal
            content={t(CONTRACTS__ARE_YOU_SURE)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => {
              onDeleteDocument(idToDelete);
            }}
          />
        )}
      </Box>
    );
  }

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ height: '100vh', width: '100%' }}>
        <UPDataGrid
          rows={generateDocumentsWithCategories(documents)}
          columns={columns}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          withCheckboxes
          selectionModel={clearAll ? [] : selectedRows}
          disableSelectionOnClick
          onSelectionModelChange={(selectedItem: any) => {
            setSelectedRows(selectedItem);
            setClearAll(false);
          }}
        />
      </Box>

      {selectedRows?.length > 0 && (
        <Box sx={{ width: '88%' }}>
          <AlertStyled severity="success" icon={false} sx={{ width: '88%' }}>
            <div className="contracts-number">
              {selectedRows.length} {t(DOCUMENTS__SELECTED).toLocaleLowerCase()} |
            </div>
            {documents.length > 0 && <div className="contracs-number">{documents.length} |</div>}
            <button
              type="button"
              className="clear-all"
              onClick={() => {
                setSelectedRows([]);
                setClearAll(true);
              }}
            >
              {t(GENERAL__CLEAR_ALL)}
            </button>
            <div className="icons-container">
              <button
                className="icon-container"
                type="button"
                onClick={() => {
                  onDownloadDocuments();
                }}
              >
                <DownloadIcon />
              </button>
            </div>
          </AlertStyled>
        </Box>
      )}
      {openDeleteModal && (
        <DeleteModal
          content={t(CONTRACTS__ARE_YOU_SURE)}
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onDelete={() => {
            onDeleteDocument(idToDelete);
          }}
        />
      )}
    </SectionContainer>
  );
};

export default DocumentsGridView;
