import { Box } from '@mui/material';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  DASHBOARD__GO_TO_EMPLOYEES,
  EMPLOYEES__ADD_NEW_EMPLOYEE,
  EMPLOYEE_STATUS__DEPLOYED,
  EMPLOYEE_STATUS__INVITED,
  EMPLOYEE_STATUS__READY,
  GENERAL__ADD,
  GENERAL__INVITE,
  MENU__EMPLOYEES,
} from 'translations/constants';
import UPButton from 'components/button';
import { useNavigate } from 'react-router-dom';
import { AddEditEmployeeModal } from 'modules/employees/add-edit-employee-modal';
import { toNumber } from 'lodash';
import InviteEmployeeModal from 'modules/employees/invite-employee-modal';
import { ReactComponent as DashboardAvatarIcon } from '../../assets/icons/DashboardAvatar.svg';
import { ReactComponent as EmptyLineIcon } from '../../assets/icons/EmptyLine.svg';
import { ReactComponent as LoadingLineIcon } from '../../assets/icons/LoadingLine.svg';
import {
  DashboardEmployeeStyled,
  EmployeeDownSideContainer,
  EmployeeLeftSideContainer,
  EmployeeRightSideContainer,
} from './styles';
import { OldDashboardEmployeeProps } from './types';

const DashboardEmployee = (props: OldDashboardEmployeeProps): JSX.Element => {
  const { employeesInvited, employeesReady, employeesDeployed, companyId } = props;

  const [t] = useTranslation();
  const navigate = useNavigate();

  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState<boolean>(false);

  return (
    <DashboardEmployeeStyled>
      <EmployeeLeftSideContainer>
        <div style={{ color: '#71B790' }}>{t(MENU__EMPLOYEES)}</div>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }} height="65%">
          <div className="dashboard-employee-numbers-container">
            <div>{t(EMPLOYEE_STATUS__INVITED)}</div>
            <div className="dashboard-employee-number">{employeesInvited}</div>
          </div>
          <hr style={{ borderColor: 'rgb(184, 219, 199, 0.3)', margin: '0px 15px' }} />
          <div className="dashboard-employee-numbers-container">
            <div>{t(EMPLOYEE_STATUS__READY)}</div>
            <div className="dashboard-employee-number">{employeesReady}</div>
          </div>
          <hr style={{ borderColor: 'rgb(184, 219, 199, 0.3)', margin: '0px 15px' }} />
          <div className="dashboard-employee-numbers-container">
            <div>{t(EMPLOYEE_STATUS__DEPLOYED)}</div>
            <div className="dashboard-employee-number">{employeesDeployed}</div>
          </div>
        </Box>
      </EmployeeLeftSideContainer>
      <EmployeeRightSideContainer>
        <div className="employee-left">
          <UPButton
            text={t(EMPLOYEES__ADD_NEW_EMPLOYEE)}
            onClick={() => setOpenAddEditModal(true)}
            outlined
            className="dashboard-button-employee"
          />

          <button
            type="button"
            onClick={() => {
              navigate('/employees');
            }}
            className="dashboard-link-employee"
          >
            {t(DASHBOARD__GO_TO_EMPLOYEES)}
          </button>
        </div>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}
          width="40%"
        >
          <DashboardAvatarIcon />
          <EmptyLineIcon />
          <LoadingLineIcon />
        </Box>
      </EmployeeRightSideContainer>
      <EmployeeDownSideContainer>
        <div className="container-for-mobile-employee-button">
          <UPButton
            text={t(GENERAL__ADD)}
            onClick={() => setOpenAddEditModal(true)}
            outlined
            className="mobile-button-employee"
          />
        </div>
        <div className="container-for-mobile-employee-button">
          <UPButton
            text={t(GENERAL__INVITE)}
            onClick={() => setOpenInviteModal(true)}
            outlined
            className="mobile-button-employee"
          />
        </div>
      </EmployeeDownSideContainer>
      {openAddEditModal && (
        <AddEditEmployeeModal
          open={openAddEditModal}
          onClose={() => {
            setOpenAddEditModal(false);
          }}
          companyId={toNumber(companyId)}
        />
      )}
      {openInviteModal && (
        <InviteEmployeeModal open={openInviteModal} onClose={() => setOpenInviteModal(false)} companyId={companyId} />
      )}
    </DashboardEmployeeStyled>
  );
};

export default DashboardEmployee;
