import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteFunctionsRequest,
  getAllFunctionsAsSelectItems,
  getFunctionsRequest,
} from 'api/requests/company-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  COMPANY_FUNCTIONS__ADD_ONE,
  COMPANY_FUNCTIONS__CLEAR,
  COMPANY_FUNCTIONS__DELETE,
  COMPANY_FUNCTIONS__DELETE_ERROR,
  COMPANY_FUNCTIONS__DELETE_SUCCESS,
  COMPANY_FUNCTIONS__GENERAL_LOADING,
  COMPANY_FUNCTIONS__GET,
  COMPANY_FUNCTIONS__GET_DROPDOWN_ITEMS,
  COMPANY_FUNCTIONS__REMOVE_MANY,
  COMPANY_FUNCTIONS__SET,
  COMPANY_FUNCTIONS__SET_DROPDOWN_ITEMS,
  COMPANY_FUNCTIONS__SET_DROPDOWN_ITEMS_ERROR,
  COMPANY_FUNCTIONS__SET_ERROR,
  COMPANY_FUNCTIONS__UPDATE_ONE,
} from 'store/constants';
import IPayrollFunction from 'types/company/payroll/IPayrollFunction';

export const setFunctionsRequestsLoadingAction = createAction<boolean>(COMPANY_FUNCTIONS__GENERAL_LOADING);

export const updateOneFunctionAction =
  createAction<{ id: number; changes: IPayrollFunction }>(COMPANY_FUNCTIONS__UPDATE_ONE);
export const addOneFunctionAction = createAction<IPayrollFunction>(COMPANY_FUNCTIONS__ADD_ONE);
export const removeManyFunctionsAction = createAction<number[]>(COMPANY_FUNCTIONS__REMOVE_MANY);
export const removeAllFunctionsAction = createAction<void>(COMPANY_FUNCTIONS__CLEAR);

export const setFunctionsAction = createAction<IPayrollFunction[]>(COMPANY_FUNCTIONS__SET);
export const setFunctionsErrorAction = createAction<boolean>(COMPANY_FUNCTIONS__SET_ERROR);

export const getFunctionsAction = createAsyncThunk(COMPANY_FUNCTIONS__GET, async (companyId: number, thunkApi) => {
  thunkApi.dispatch(setFunctionsRequestsLoadingAction(true));
  thunkApi.dispatch(setFunctionsErrorAction(false));
  try {
    const response = await getFunctionsRequest(companyId);

    thunkApi.dispatch(setFunctionsAction(response));
  } catch (error) {
    thunkApi.dispatch(setFunctionsErrorAction(true));
  }
  thunkApi.dispatch(setFunctionsRequestsLoadingAction(false));
});

export const deleteFunctionsSuccessAction = createAction<boolean>(COMPANY_FUNCTIONS__DELETE_SUCCESS);
export const deleteFunctionsErrorAction = createAction<boolean>(COMPANY_FUNCTIONS__DELETE_ERROR);

export const deleteFunctionsAction = createAsyncThunk(
  COMPANY_FUNCTIONS__DELETE,
  async (props: { companyId: string; functionIds: number[] }, thunkApi) => {
    thunkApi.dispatch(setFunctionsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteFunctionsErrorAction(false));
    try {
      await deleteFunctionsRequest(props);
      thunkApi.dispatch(deleteFunctionsSuccessAction(true));
      thunkApi.dispatch(removeManyFunctionsAction(props.functionIds));
    } catch (error) {
      thunkApi.dispatch(deleteFunctionsErrorAction(true));
    }
    thunkApi.dispatch(setFunctionsRequestsLoadingAction(false));
  },
);

export const setFunctionsDropdownItemsAction = createAction<UPDropdownItem[]>(COMPANY_FUNCTIONS__SET_DROPDOWN_ITEMS);
export const setFunctionsDropdownItemsErrorAction = createAction<boolean>(COMPANY_FUNCTIONS__SET_DROPDOWN_ITEMS_ERROR);

export const getFunctionsAsDropdownItemsAction = createAsyncThunk(
  COMPANY_FUNCTIONS__GET_DROPDOWN_ITEMS,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setFunctionsRequestsLoadingAction(true));
    thunkApi.dispatch(setFunctionsDropdownItemsErrorAction(false));
    try {
      const response = await getAllFunctionsAsSelectItems(companyId);
      thunkApi.dispatch(setFunctionsDropdownItemsAction(response));
    } catch (error) {
      thunkApi.dispatch(setFunctionsDropdownItemsErrorAction(true));
    }
    thunkApi.dispatch(setFunctionsRequestsLoadingAction(false));
  },
);
