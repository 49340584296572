import { useTranslation } from 'react-i18next';
import { CONTRACTS__CLEAR_ALL, CONTRACTS__SELECTED } from 'translations/constants';
import { AlertStyled } from 'components/bottom-green-bar/styles';
import { ReactComponent as ArchiveIconGreen } from '../../../assets/icons/ArchiveContractsGreen.svg';

const APiErrorBottomBar = (props: {
  selectedRows: number[];
  setSelectedRows: (rows: number[]) => void;
  setClearAll: (clear: boolean) => void;
  onArchiveErrors: () => void;
}): JSX.Element => {
  const { selectedRows, setSelectedRows, setClearAll, onArchiveErrors } = props;

  const [t] = useTranslation();

  return (
    <AlertStyled severity="success" icon={false} sx={{ width: '92%' }}>
      <div className="contracts-number">
        {selectedRows.length} {t(CONTRACTS__SELECTED).toLocaleLowerCase()} |
      </div>
      <button
        type="button"
        className="clear-all"
        onClick={() => {
          setSelectedRows([]);
          setClearAll(true);
        }}
      >
        {t(CONTRACTS__CLEAR_ALL)}
      </button>
      <div className="icons-container">
        <button
          className="icon-container"
          type="button"
          onClick={() => {
            onArchiveErrors();
          }}
        >
          <ArchiveIconGreen />
        </button>
      </div>
    </AlertStyled>
  );
};

export default APiErrorBottomBar;
