/* eslint-disable no-param-reassign */
import UPDropdownWithInput from 'components/dropdown-with-input';
import { filterByHour } from '../helpers';

const DataEditCellHours = (props: any): JSX.Element => {
  const { id, value, api, field, dropdownOptions, type, errorMessage } = props;

  const handleChange = async (newValue, aux, event: { target: { value: any }; clientX: number; clientY: number }) => {
    api.setEditCellValue({ id, field, value: newValue }, event);
    if (event.clientX !== 0 && event.clientY !== 0) {
      const isValid = await api.commitCellChange({ id, field });
      if (isValid) {
        api.setCellMode(id, field, 'view');
      }
    }
  };

  return (
    <UPDropdownWithInput
      withSearchIcon={false}
      value={value}
      handleChangeWithEvent={handleChange}
      category="dataGrid"
      ref={null}
      placeholder=""
      items={dropdownOptions}
      filterOptions={filterByHour}
      label=""
      required
      type={type}
      error={errorMessage}
    />
  );
};

export default DataEditCellHours;
