import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { addFunctionRequest, updateFunctionRequest } from 'api/requests/company-requests';
import {
  COMPANY_FUNCTION__GENERAL_LOADING,
  COMPANY_FUNCTION__POST,
  COMPANY_FUNCTION__PUT,
  COMPANY_FUNCTION__SET_FUNCTION,
  COMPANY_FUNCTION__SET_FUNCTION_ERROR,
} from 'store/constants';
import IPayrollFunction from 'types/company/payroll/IPayrollFunction';
import { addOneFunctionAction, updateOneFunctionAction } from './company-functions-actions';

export const setFunctionRequestsLoadingAction = createAction<boolean>(COMPANY_FUNCTION__GENERAL_LOADING);

export const setFunctionAction = createAction<boolean>(COMPANY_FUNCTION__SET_FUNCTION);
export const setFunctionErrorAction = createAction<boolean>(COMPANY_FUNCTION__SET_FUNCTION_ERROR);

export const updateFunctionAction = createAsyncThunk(
  COMPANY_FUNCTION__PUT,
  async (props: { companyId: number; functionToUpdate: IPayrollFunction }, thunkApi) => {
    thunkApi.dispatch(setFunctionRequestsLoadingAction(true));
    thunkApi.dispatch(setFunctionErrorAction(false));
    try {
      await updateFunctionRequest(props);
      thunkApi.dispatch(setFunctionAction(true));
      thunkApi.dispatch(updateOneFunctionAction({ id: props.functionToUpdate.id, changes: props.functionToUpdate }));
    } catch (error) {
      thunkApi.dispatch(setFunctionErrorAction(true));
    }
    thunkApi.dispatch(setFunctionRequestsLoadingAction(false));
  },
);

export const addFunctionAction = createAsyncThunk(
  COMPANY_FUNCTION__POST,
  async (props: { companyId: number; functionToAdd: IPayrollFunction }, thunkApi) => {
    thunkApi.dispatch(setFunctionRequestsLoadingAction(true));
    thunkApi.dispatch(setFunctionErrorAction(false));
    try {
      const functionId = await addFunctionRequest(props);
      const newFunction: IPayrollFunction = { ...props.functionToAdd, id: functionId };
      if (newFunction.type === '') {
        newFunction.type = null;
      }
      thunkApi.dispatch(setFunctionAction(true));
      thunkApi.dispatch(addOneFunctionAction(newFunction));
    } catch (error) {
      thunkApi.dispatch(setFunctionErrorAction(true));
    }
    thunkApi.dispatch(setFunctionRequestsLoadingAction(false));
  },
);
