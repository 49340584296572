import { createReducer } from '@reduxjs/toolkit';
import {
  setCostCenterAction,
  setCostCenterErrorAction,
  setCostCenterRequestsLoadingAction,
} from 'store/actions/company-cost-center-actions';
import IPayrollCostCenterState from 'types/company/payroll/IPayrollCostCenterState';

const initialState: IPayrollCostCenterState = {
  costCenter: false,
  loading: false,
  costCenterError: false,
};

const costCenterReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setCostCenterAction, (state, action) => ({ ...state, costCenter: action.payload }))
    .addCase(setCostCenterRequestsLoadingAction, (state, action) => ({
      ...state,
      costCenterLoading: action.payload,
    }))
    .addCase(setCostCenterErrorAction, (state, action) => ({
      ...state,
      costCenterError: action.payload,
    }));
});

export default costCenterReducer;
