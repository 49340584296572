import UPTab from 'components/tab';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MENU__PAYROLL_SETTINGS,
  WAGE_COMPONENTS__COST_DECLARATION_HEADER,
  WAGE_COMPONENTS__SERVICE_CODES,
} from 'translations/constants';
import { SectionContainer } from 'modules/companies/styles';
import { useAppDispatch, useAppSelector } from 'store';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import ServiceCodes from './service-codes/ServiceCodes';
import CostDeclaration from './cost-declarations/CostDeclaration';

const WageComponents = (): JSX.Element => {
  const [t] = useTranslation();
  const activeItem = useAppSelector(activeMenuItemSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeItem !== t(MENU__PAYROLL_SETTINGS)) {
      dispatch(setMenuStatusAction(t(MENU__PAYROLL_SETTINGS)));
    }
  }, [activeItem, dispatch, t]);

  const WageComponentsTabs = [t(WAGE_COMPONENTS__SERVICE_CODES), t(WAGE_COMPONENTS__COST_DECLARATION_HEADER)];

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <UPTab sections={WageComponentsTabs}>
        <ServiceCodes />
        <CostDeclaration />
      </UPTab>
    </SectionContainer>
  );
};

export default WageComponents;
