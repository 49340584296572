import { IContractData } from 'modules/contracts/types';

export const filterContractsByDate = (contracts: IContractData[], currentDate: Date) => {
  const dateYear = currentDate.getFullYear();
  const dateMonth = currentDate.getMonth();
  const dateDay = currentDate.getDate();
  const filterdContracts = contracts.filter((c: IContractData) => {
    const contractDate = new Date(c.date);
    return (
      dateYear === contractDate.getFullYear() &&
      dateMonth === contractDate.getMonth() &&
      dateDay === contractDate.getDate()
    );
  });
  return filterdContracts;
};

export const generateDate = (date: string, type: string) => {
  const auxArr = date.split(' ');
  if (type === 'start') return `${auxArr[0]}`;
  return `${auxArr[2]} ${auxArr[1]}`;
};

export const generateDummyElements = (data: any, maxValue: number) => {
  const firstElement = {
    name: '',
    value: 0,
    place: maxValue,
  };
  const lastElement = {
    name: '',
    value: 0,
    place: maxValue / 2,
  };
  data.unshift(firstElement);
  data.push(lastElement);
  return data;
};

export const getAdjacentDay = (date: Date, day: number) => {
  const auxDate = new Date(date);
  auxDate.setDate(auxDate.getDate() + day);
  return auxDate;
};
