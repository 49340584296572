import { API_USERS } from 'translations/constants';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteApiUsersRequest,
  deleteSuperAdminsRequest,
  getAllApiUsersRequest,
  getAllSuperAdminsRequest,
} from 'api/requests/superadmin-requests';
import {
  SUPERADMINS__ADD_ONE,
  SUPERADMINS__CLEAR,
  SUPERADMINS__DELETE,
  SUPERADMINS__DELETE_ERROR,
  SUPERADMINS__DELETE_SUCCESS,
  SUPERADMINS__GET,
  SUPERADMINS__REMOVE_MANY,
  SUPERADMINS__SET,
  SUPERADMINS__SET_ERROR,
  SUPERADMINS__GENERAL_LOADING,
  SUPERADMINS__UPDATE_ONE,
  API_USERS__SET,
  API_USERS__SET_ERROR,
  API_USERS__UPDATE_ONE,
  API_USERS__ADD_ONE,
  API_USERS__REMOVE_MANY,
  API_USERS__CLEAR,
  API_USERS__GET,
  API_USERS__DELETE_ERROR,
  API_USERS__DELETE_SUCCESS,
  APIUSERS__GENERAL_LOADING,
  API_USERS__DELETE,
} from 'store/constants';
import IUser from 'types/superadmin/ISuperAdmin';

export const setSuperAdminsRequestsLoadingAction = createAction<boolean>(SUPERADMINS__GENERAL_LOADING);

// admin section
export const setSuperAdminsAction = createAction<IUser[]>(SUPERADMINS__SET);
export const setSuperAdminsErrorAction = createAction<boolean>(SUPERADMINS__SET_ERROR);

export const updateOneSuperAdminAction = createAction<{ id: number; changes: IUser }>(SUPERADMINS__UPDATE_ONE);
export const addOneSuperAdminAction = createAction<IUser>(SUPERADMINS__ADD_ONE);
export const removeManySuperAdminsAction = createAction<number[]>(SUPERADMINS__REMOVE_MANY);
export const removeAllSuperAdminsAction = createAction<void>(SUPERADMINS__CLEAR);

export const getSuperAdminsAction = createAsyncThunk(SUPERADMINS__GET, async (_, thunkApi) => {
  thunkApi.dispatch(setSuperAdminsRequestsLoadingAction(true));
  thunkApi.dispatch(setSuperAdminsErrorAction(false));
  try {
    const response = await getAllSuperAdminsRequest();
    thunkApi.dispatch(setSuperAdminsAction(response));
  } catch (error) {
    thunkApi.dispatch(setSuperAdminsErrorAction(true));
  }
  thunkApi.dispatch(setSuperAdminsRequestsLoadingAction(false));
});

export const deleteSuperAdminsSuccessAction = createAction<boolean>(SUPERADMINS__DELETE_SUCCESS);
export const deleteSuperAdminsErrorAction = createAction<boolean>(SUPERADMINS__DELETE_ERROR);

export const deleteSuperAdminsAction = createAsyncThunk(
  SUPERADMINS__DELETE,
  async (superAdminsIds: number[], thunkApi) => {
    thunkApi.dispatch(setSuperAdminsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteSuperAdminsErrorAction(false));
    try {
      await deleteSuperAdminsRequest(superAdminsIds);
      thunkApi.dispatch(deleteSuperAdminsSuccessAction(true));
      thunkApi.dispatch(removeManySuperAdminsAction(superAdminsIds));
    } catch (error) {
      thunkApi.dispatch(deleteSuperAdminsErrorAction(true));
    }
    thunkApi.dispatch(setSuperAdminsRequestsLoadingAction(false));
  },
);

// api users
export const setApiUsersRequestsLoadingAction = createAction<boolean>(APIUSERS__GENERAL_LOADING);

export const setApiUsersAction = createAction<IUser[]>(API_USERS__SET);
export const setApiUsersErrorAction = createAction<boolean>(API_USERS__SET_ERROR);

export const updateOneApiUserAction = createAction<{ id: number; changes: IUser }>(API_USERS__UPDATE_ONE);
export const addOneApiUserAction = createAction<IUser>(API_USERS__ADD_ONE);
export const removeManyApiUsersAction = createAction<number[]>(API_USERS__REMOVE_MANY);
export const removeAllApiUserssAction = createAction<void>(API_USERS__CLEAR);

export const getApiUsersAction = createAsyncThunk(API_USERS__GET, async (_, thunkApi) => {
  thunkApi.dispatch(setApiUsersRequestsLoadingAction(true));
  thunkApi.dispatch(setApiUsersErrorAction(false));
  try {
    const response = await getAllApiUsersRequest();
    thunkApi.dispatch(setApiUsersAction(response));
  } catch (error) {
    thunkApi.dispatch(setApiUsersErrorAction(true));
  }
  thunkApi.dispatch(setApiUsersRequestsLoadingAction(false));
});

export const deleteApiUsersSuccessAction = createAction<boolean>(API_USERS__DELETE_SUCCESS);
export const deleteApiUsersErrorAction = createAction<boolean>(API_USERS__DELETE_ERROR);

export const deleteApiUsersAction = createAsyncThunk(API_USERS__DELETE, async (userIds: number[], thunkApi) => {
  thunkApi.dispatch(setApiUsersRequestsLoadingAction(true));
  thunkApi.dispatch(deleteApiUsersErrorAction(false));
  try {
    await deleteApiUsersRequest(userIds);
    thunkApi.dispatch(deleteApiUsersSuccessAction(true));
    thunkApi.dispatch(removeManyApiUsersAction(userIds));
  } catch (error) {
    thunkApi.dispatch(deleteApiUsersErrorAction(true));
  }
  thunkApi.dispatch(setApiUsersRequestsLoadingAction(false));
});
