import { Backdrop } from '@mui/material';
import { Box } from '@mui/system';
import { validateContractRequest } from 'api/requests/contract-requests';
import UPButton from 'components/button';
import { ModalContainer } from 'components/delete-modal/styles';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPMultipleSelectAutocomplete from 'components/dropdown-with-search/UPMultipleSelectAutocomplete';
import ErrorBar from 'components/error-bar';
import UPInput from 'components/input';
import WarningBar from 'components/warning-bar';
import { CompanyRole } from 'constants/CompanyRole';
import { ContractTypes } from 'constants/ContractTypes';
import { useContractModalDropdownData } from 'hooks/contracts';
import { toNumber } from 'lodash';
import { fromContractModelToContractRequest } from 'mappers/contracts-mapper';
import { ModalFooter, ModalTitle, RowContainer } from 'modules/employees/add-edit-employee-modal/styles';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  clearContractAction,
  getWageAction,
  saveContractAction,
  validateAndSaveContractAction,
  validateAndSaveEditContractAction,
  validateContractErrorMessageRowAction,
  validateContractSuccessAction,
} from 'store/actions/contract-actions';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import {
  validateContractErrorMessageRowSelector,
  validateContractSuccessSelector,
  wageSelector,
} from 'store/selectors/contract-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  CONTRACTS__ADD_ANOTHER,
  CONTRACTS__CANCEL,
  CONTRACTS__COMPANY_LOCATION,
  CONTRACTS__COST_CENTER,
  CONTRACTS__EDIT_CONTRACT,
  CONTRACTS__FILL_CONTRACT_SLOT,
  CONTRACTS__FUNCTION,
  CONTRACTS__VIEW_CONTRACT,
  CONTRACTS__WAGE,
  CONTRACTS__WORKING_LOCATION,
  GENERAL__CREATE,
  GENERAL__NAME,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  HEADER__WEEK,
  VALIDATION__IS_REQUIRED,
} from 'translations/constants';
import IAddEditContract from 'types/contract/IAddEditContract';
import IHours from 'types/contract/IHours';
import ISaveContract from 'types/contract/ISaveContract';
import IValidateContract from 'types/contract/IValidateContract';
import IWageData from 'types/contract/IWageData';
import IWeekDays from 'types/contract/IWeekDays';
import IWeekDaysRow from 'types/contract/IWeekDaysRow';
import { getInputErrorText, getWeekDaysAbbreviations } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import AddEditDayPickerRow from '../AddEditDayPickerRow';
import EditableCostcenter from '../EditableCostCenter/EditableCostcenter';
import {
  generateCompanyEmployeesList,
  generateRowsWithDays,
  getCurrentTime,
  getWeekDaysByWeekNumber,
  hoursToMinutes,
} from '../helpers';
import {
  ContractDateContainer,
  ContractXIcon,
  DayPickerError,
  ModalCloseIconStyled,
  ModalTitleContainer,
} from '../styles';
import { AddEditContractModalProps } from '../types';
import {
  CompanyBranchInputStyled,
  WorkerclassInputStyled,
  CostCenterStyled,
  FunctionStyled,
  ModalContentContainer,
  ModalHeaderContract,
  ModalWrapperContract,
  NameInputStyled,
  RowContainerForNameAndContractType,
  WageStyled,
  WorkingLocationStyled,
} from './styles';

const ContractsInitialState: IAddEditContract = {
  id: 0,
  companyId: '',
  employeeId: '',
  contractType: '',
  functionId: '',
  costCenterId: '',
  locationId: '',
};

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const AddEditContractModal = (props: AddEditContractModalProps) => {
  const {
    title,
    open,
    onClose,
    onSuccess,
    companyId,
    week,
    cardData,
    weekForYear,
    weekNumber,
    prefilledDates,
    prefilledEmployeeId,
    setSavedContract,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const contractIsValid = useAppSelector(validateContractSuccessSelector);
  const profile = useAppSelector(profileSelector);
  const isEmployee = profile.role === CompanyRole.EMPLOYEE;
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const errorRow = useAppSelector(validateContractErrorMessageRowSelector);
  const wage = useAppSelector(wageSelector) || '';

  const { functions, companyEmployees, costCentersWithDetails } = useContractModalDropdownData(
    toNumber(companyId),
    isEmployee,
  );

  const weekDaysNames = getWeekDaysAbbreviations();

  const weekDays = getWeekDaysByWeekNumber(week, weekDaysNames, weekForYear, prefilledDates);
  const generateAllRows = () => {
    let data = [
      {
        id: 0,
        days: weekDays,
        hours: {
          startWork: '',
          startBreak: '',
          endBreak: '',
          endWork: '',
        },
      },
    ];
    if (cardData) data = generateRowsWithDays(cardData);
    return data;
  };

  const [refreshCostCentre, setRefreshCostCentre] = useState<boolean>(false);
  const [allContractValid, setAllContractValid] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [locations, setLocations] = useState<UPDropdownItem[]>([]);
  const [employees, setEmployees] = useState<UPDropdownItem[]>([]);
  const [allRows, setAllRows] = useState<IWeekDaysRow[]>(generateAllRows());
  const [weekRowRequiredError, setWeekRowRequiredError] = useState<boolean>(false);
  const [currentStartWorkError, setCurrentStartWorkError] = useState<boolean>(false);
  const [currentEndWorkError, setCurrentEndWorkError] = useState<boolean>(false);
  const [formatError, setFormatError] = useState<number>(-1);
  const [selectedEmployees, setSelectedEmployees] = useState<UPDropdownItem[]>([]);
  const [contractType, setContractType] = useState<string>('');
  const [workingLocation, setWorkingLocation] = useState<string>(cardData ? cardData.workingLocation : '');
  const [permanent, setPermanent] = useState<boolean>(false);
  const [costCenters, setCostCenters] = useState<UPDropdownItem[]>([]);

  const maxId = Math.max(...costCenters.map(cc => cc.value));

  const datesErrorsObject: any = useMemo(() => {
    return {
      0: {
        found: false,
        foundHourInPresent: false,
      },
    };
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<any>(
    cardData
      ? {
          defaultValues: {
            ...ContractsInitialState,
            employeeId: cardData?.employee ? cardData.employee.id : 0,
            functionId: cardData.functionId,
            wage: `€ ${cardData.wage}`,
            locationId: cardData.locationId,
            costCenterId: cardData.costCenterId,
          },
        }
      : {
          defaultValues: {
            ...ContractsInitialState,
            costCenterId: costCenters.length === 1 ? costCenters[0].value : undefined,
            functionId: functions.length === 1 ? functions[0].value : undefined,
          },
        },
  );

  const watchEmployee: UPDropdownItem = watch<string>('employeeId');
  const watchFunction: UPDropdownItem = watch<string>('functionId');
  const watchLocationId: string = watch<string>('locationId');
  const watchCostCenter: UPDropdownItem = watch<string>('costCenterId');

  useEffect(() => {
    dispatch(clearContractAction());
  }, [dispatch]);

  useEffect(() => {
    setLocations(locationsDropdownItems);
  }, [locationsDropdownItems]);

  useEffect(() => {
    if (cardData && cardData?.employee) {
      const employee = companyEmployees.find(e => e.id === cardData.employee.id);

      // if the employee is inactive, it won't be fetched and the employee object will be undefined
      // fallback to use the default set of locations in that case
      const filteredLocations = employee
        ? locationsDropdownItems.filter(location => employee.locations.includes(location.value))
        : locationsDropdownItems;

      setLocations(filteredLocations);
    }
  }, [cardData, companyEmployees, companyId, dispatch, locationsDropdownItems]);

  useEffect(() => {
    if (costCentersWithDetails && costCentersWithDetails.length > 0) {
      const costCentersDetails = costCentersWithDetails.map(cc => ({
        label: cc.name,
        value: cc.id,
      }));
      setCostCenters(costCentersDetails);
    }
  }, [costCentersWithDetails]);

  useEffect(() => {
    if (!isEmployee) dispatch(getEmployeesWithWorkerclass(toNumber(companyId)));
  }, [companyId, dispatch, isEmployee]);

  const onNameChange = useCallback(
    value => {
      setSelectedEmployees(value);
      clearErrors();
      const selectedIds = value.map(e => e.value);

      if (!selectedIds || selectedIds.length === 0) {
        return;
      }
      const filteredEmployees = companyEmployees.filter(e => selectedIds.includes(e.id.toString()));
      const locationArray = [];
      filteredEmployees.forEach(e => locationArray.push(e.locations));

      const locationIds = locationArray.reduce((a, b) => a.filter(c => b.includes(c)));
      const newLocations = locationsDropdownItems.filter(e => locationIds.includes(e.value));

      if (locationIds.length > 0) {
        setLocations(newLocations);
      } else {
        setLocations([]);
        setError('locationId', { type: 'noCompanyLocation' });
      }
    },
    [clearErrors, companyEmployees, locationsDropdownItems, setError],
  );

  useEffect(() => {
    if (companyEmployees) {
      const employeesNames = generateCompanyEmployeesList(companyEmployees);
      setEmployees(employeesNames);
      if (prefilledEmployeeId && employeesNames.length > 0) {
        const employee = employeesNames.find(e => e.value === prefilledEmployeeId.toString());
        if (employee) {
          onNameChange([employee]);
        }
      }
    }
  }, [companyEmployees, onNameChange, prefilledEmployeeId, setValue]);

  useEffect(() => {
    if (functions && functions.length === 1) {
      setValue('functionId', functions[0].value);
      clearErrors('functionId');
    }
  }, [clearErrors, functions, setValue]);

  useEffect(() => {
    if (costCenters && costCenters.length === 1) {
      setValue('costCenterId', costCenters[0].value);
      clearErrors('costCenterId');
    }
  }, [clearErrors, costCenters, setValue, watchCostCenter]);

  useEffect(() => {
    if (locations.length === 1 && !isEmployee) {
      setValue('locationId', locations[0].value);
      clearErrors('locationId');
    }
  }, [cardData, clearErrors, isEmployee, locations, setValue]);

  useEffect(() => {
    if (watchEmployee) {
      const theEmployee = companyEmployees.find(e => e.id === toNumber(watchEmployee));
      if (theEmployee) {
        if (theEmployee.permanent) {
          setPermanent(true);
          setContractType('');
        } else {
          setContractType(theEmployee.workerclass);
          setPermanent(false);
        }
      }
    }
    if (selectedEmployees.length === 1) {
      const theEmployee = companyEmployees.find(e => e.id === toNumber(selectedEmployees[0].value));
      if (theEmployee) {
        if (theEmployee.permanent) {
          setPermanent(true);
          setContractType('');
        } else {
          setContractType(theEmployee.workerclass);
          setPermanent(false);
        }
      }
    }
  }, [selectedEmployees, companyEmployees, watchEmployee]);

  useEffect(() => {
    if (isEmployee || cardData?.vendor) setContractType(cardData?.contractType);
  }, [cardData, isEmployee]);

  const getEmployeeId = useCallback(() => {
    if (toNumber(watchEmployee) > 0) {
      return toNumber(watchEmployee);
    }

    if (watchEmployee && watchEmployee[0]?.value) {
      return toNumber(watchEmployee[0].value);
    }

    return toNumber(selectedEmployees[0]?.value);
  }, [watchEmployee, selectedEmployees]);

  useEffect(() => {
    if (profile.showWages && watchFunction && (selectedEmployees.length === 1 || watchEmployee)) {
      const wageData: IWageData = {
        employeeId: getEmployeeId(),
        companyId: toNumber(companyId),
        functionId: toNumber(watchFunction),
      };
      dispatch(getWageAction({ wageData, companyId }));
    }
  }, [selectedEmployees, companyId, dispatch, getEmployeeId, watchEmployee, watchFunction, cardData, profile]);

  useEffect(() => {
    if (selectedEmployees.length === 1) {
      const theEmployee = companyEmployees.find(e => e.id === toNumber(selectedEmployees[0].value));

      const employeeNames = generateCompanyEmployeesList(
        companyEmployees.filter(e => e.permanent === theEmployee.permanent),
      );

      const selectedEmployeeIds = employeeNames.map(x => x.value);

      setEmployees(items => items.filter(x => selectedEmployeeIds.includes(x.value)));
    }
    if (selectedEmployees.length === 0) {
      const employeesNames = generateCompanyEmployeesList(companyEmployees);
      setEmployees(employeesNames);
      if (!cardData) {
        setContractType('');
        setPermanent(false);
      }
    }
  }, [cardData, companyEmployees, selectedEmployees]);

  useEffect(() => {
    if (watchCostCenter) {
      const costCenterWithAddress = costCentersWithDetails.find(c => c.id === toNumber(watchCostCenter));
      if (costCenterWithAddress)
        setWorkingLocation(
          `${costCenterWithAddress.streetName} ${costCenterWithAddress.streetNumber}, ${costCenterWithAddress.postalCode}, ${costCenterWithAddress.city}`,
        );
    } else {
      setWorkingLocation('');
    }
  }, [costCentersWithDetails, watchCostCenter]);

  useEffect(() => {
    if (watchLocationId) {
      const displayedCostCenters = costCentersWithDetails.filter(
        cc => cc.locationId === watchLocationId || cc.locationId === null,
      );
      const costCentersItems = displayedCostCenters.map(cc => ({
        label: cc.name,
        value: cc.id,
      }));
      setCostCenters(costCentersItems);
    }
  }, [watchLocationId, costCentersWithDetails]);

  useEffect(() => {
    if (allContractValid) {
      const values = getValues();
      if (selectedEmployees.length > 0) {
        selectedEmployees.forEach(employee => {
          values.contractType = companyEmployees.find(e => e.id.toString() === employee.value).workerclass;
          values.employeeId = employee.value;
          const contractActionArgs = {
            contract: {
              ...values,
              permanent,
            },
            companyId,
            weekDaysRows: allRows,
            week,
            year: toNumber(moment(weekForYear).format('Y')),
            wage,
            workingLocation,
          };
          if (weekNumber) dispatch(saveContractAction({ ...contractActionArgs, weekNumber }));
          else dispatch(saveContractAction(contractActionArgs));
          dispatch(validateContractSuccessAction(false));
          onClose();

          const newEmployee = companyEmployees.find(e => e.id.toString() === employee.value);
          const { hours } = allRows[0];
          const selectedDay = allRows[0].days.find(d => d.checked);

          onSuccess({
            employee: {
              ...newEmployee,
              functionName: newEmployee.workerclass,
            },
            costCenterId: values.costCenterId,
            contractType,
            date: new Date(selectedDay?.nativeDate),
            startHour: hours.startWork,
            startBreak: hours.startBreak,
            endBreak: hours.endBreak,
            endHour: hours.endWork,
            functionId: values.functionId,
            locationId: values.locationId,
            workingLocation,
            wage: toNumber(contractActionArgs.wage),
            permanent,
          });
        });
      }
    }
  }, [
    selectedEmployees,
    allRows,
    weekDays,
    companyId,
    contractIsValid,
    contractType,
    dispatch,
    getValues,
    onClose,
    onSuccess,
    wage,
    week,
    workingLocation,
    cardData,
    companyEmployees,
    watchFunction,
    watchCostCenter,
    watchLocationId,
    weekForYear,
    weekNumber,
    permanent,
    allContractValid,
  ]);

  // useEffect(() => {
  //   if (refreshCostCentre) {
  //     dispatch(getCostCentersDropdownItemsAction({ companyId: toNumber(companyId) }));
  //     setRefreshCostCentre(false);
  //     setValue('costCenterId', maxId);
  //   }
  // }, [companyId, dispatch, setValue, refreshCostCentre, maxId]);

  // useEffect(() => {
  //   const workDays: IWorkingDaysInterval[] = [];

  //   for (let i = 0; i < 7; i++) {
  //     if (allRows[0].days?.length > 0) {
  //       // edit contract has no concept of days
  //       const day = allRows[0].days[i];
  //       workDays.push({
  //         dayNumber: day.nativeDate.getDate(),
  //         day: day.day,
  //         startWork: '',
  //         endWork: '',
  //       });
  //     }
  //   }

  //   allRows.forEach(row => {
  //     if (row.days?.length > 0) {
  //       row.days.forEach(day => {
  //         if (day.checked) {
  //           const dayIndex = workDays.findIndex(w => w.dayNumber === day.nativeDate.getDate());
  //           if (dayIndex !== -1) {
  //             workDays[dayIndex].startWork = row.hours.startWork;
  //             workDays[dayIndex].endWork = row.hours.endWork;
  //           }
  //         }
  //       });
  //     }
  //   });

  //   setWorkingDays(workDays);
  // }, [allRows]);

  const checkIfThereIsARowDateInPast = useCallback((date: IWeekDays) => {
    const currentDate = new Date(Date.now());
    const auxErr = {
      found: false,
      foundDateInPresent: false,
    };
    let itemDate;
    if (date.checked) {
      itemDate = new Date(date.nativeDate);
      if (itemDate.getMonth() < currentDate.getMonth() && itemDate.getFullYear() === currentDate.getFullYear()) {
        auxErr.found = true;
      }
      if (itemDate.getMonth() === currentDate.getMonth() && itemDate.getDate() < currentDate.getDate()) {
        auxErr.found = true;
      }
      if (itemDate.getMonth() === currentDate.getMonth() && itemDate.getDate() === currentDate.getDate()) {
        auxErr.foundDateInPresent = true;
      }
    } else {
      auxErr.found = false;
    }
    return auxErr;
  }, []);

  const checkIfARowHasAnyErrors = useCallback(
    (modifItem: IWeekDays, modifItemIndex: number, indexRow: number, row: IWeekDaysRow) => {
      const errObjForRow = { found: false, foundDateInPresent: false };
      const errorsForRow = row.days.map((day: IWeekDays) => {
        const errorForDay = checkIfThereIsARowDateInPast(day);
        const ignoreDayErrorsForContractTypes: string[] = [ContractTypes.BED, ContractTypes.ARB];

        return errorForDay && !ignoreDayErrorsForContractTypes.includes(contractType);
      });
      const foundErr = errorsForRow.find((err: any) => err.found);
      const foundDateInPresentErr = errorsForRow.find((err: any) => err.foundDateInPresent);
      if (foundErr) errObjForRow.found = true;
      if (foundDateInPresentErr) errObjForRow.foundDateInPresent = true;

      datesErrorsObject[indexRow] = errObjForRow;
    },
    [checkIfThereIsARowDateInPast, datesErrorsObject, contractType],
  );

  const onCheckDay = useCallback(
    (item: IWeekDays, rowIndex: number) => {
      const row = allRows[rowIndex];
      const itemIndex = row.days.findIndex(e => e.date === item.date);
      const modifiedItem = { ...row.days[itemIndex] };
      modifiedItem.checked = !item.checked;
      row.days[itemIndex] = modifiedItem;
      setAllRows([...allRows]);
      checkIfARowHasAnyErrors(modifiedItem, itemIndex, rowIndex, row);
    },
    [allRows, checkIfARowHasAnyErrors],
  );

  const onChangeHours = useCallback(
    (value: string, type: string, index: number) => {
      const row = allRows[index];
      row.hours[type as keyof IHours] = value;
      setAllRows([...allRows]);
      setCurrentStartWorkError(false);
      setCurrentEndWorkError(false);
      setFormatError(-1);
      const currentTime = getCurrentTime();
      const currentMinutes = currentTime.minutes + 30 + currentTime.hours * 60;
      const itemMinutes = hoursToMinutes(value);
      if (
        (type === 'startWork' && !value) ||
        (datesErrorsObject[index].foundDateInPresent && type === 'startWork' && itemMinutes < currentMinutes)
      ) {
        setCurrentStartWorkError(true);
      }

      if (type === 'startWork' && !value) {
        setCurrentStartWorkError(true);
      }
      if (type === 'endWork' && !value) {
        setCurrentEndWorkError(true);
      }
    },
    [allRows, datesErrorsObject],
  );

  const onChangeHoursEdit = useCallback(
    (value: string, type: string, id: number) => {
      const newAllRows = allRows.map((r: IWeekDaysRow, index: number) => {
        if (index === id)
          return {
            ...r,
            [type]: value,
          };

        return r;
      });
      setAllRows(newAllRows);
    },
    [allRows],
  );

  const onDeleteRow = useCallback(
    (index: number) => {
      if (allRows.length > 1) {
        allRows.splice(index, 1);
        setAllRows([...allRows]);
        if (errorRow === index) {
          dispatch(validateContractErrorMessageRowAction(null));
        }
      }
    },
    [allRows, dispatch, errorRow],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function validateBulk(contracts: IValidateContract[]) {
    const promises = contracts.map(async item => {
      const contractRequest = fromContractModelToContractRequest(
        item.contract,
        item.weekDaysRows,
        item.week,
        item.year,
        item.wage,
        item.workingLocation,
      );

      return validateContractRequest({
        contract: contractRequest,
        companyId,
      });
    });

    const results = await Promise.all(promises);
    const allValid = results.every(i => i.success === true);
    setAllContractValid(allValid);
  }

  const handleValidateBulk = useCallback(
    (contracts: IValidateContract[]) => {
      validateBulk(contracts);
    },
    [validateBulk],
  );

  const onValidateAndSaveContract: SubmitHandler<IAddEditContract> = useCallback(
    (contract: IAddEditContract) => {
      const values = getValues();
      values.contractType = contractType;
      if (selectedEmployees.length > 1) {
        const requests = selectedEmployees.map(employee => {
          values.contractType = companyEmployees.find(e => e.id.toString() === employee.value).workerclass;
          values.employeeId = employee.value;
          return {
            contract: values,
            companyId,
            weekDaysRows: allRows,
            week,
            year: toNumber(moment(weekForYear).format('Y')),
            wage,
            workingLocation,
          };
        });

        handleValidateBulk(requests);
      } else {
        // validate single contract region
        const id = getEmployeeId();
        values.contractType = values.contractType ?? companyEmployees.find(e => e.id === id).workerclass;
        values.employeeId = id;
        contract.employeeId = id.toString(); // eslint-disable-line no-param-reassign
        const validateArgs: IValidateContract = {
          contract,
          companyId,
          weekDaysRows: allRows,
          week,
          year: toNumber(moment(weekForYear).format('Y')),
          wage,
          workingLocation,
        };

        const saveArgs: ISaveContract = {
          contract: {
            ...values,
            permanent,
          },
          companyId,
          weekDaysRows: allRows,
          week,
          year: toNumber(moment(weekForYear).format('Y')),
          wage,
          workingLocation,
        };

        if (weekNumber) {
          saveArgs.weekNumber = weekNumber;
        }

        if (!cardData) {
          (async () => {
            const result = await dispatch(
              validateAndSaveContractAction({ validateContract: validateArgs, saveContract: saveArgs }),
            );

            if (result.payload) {
              onClose();
              setSavedContract(true);

              const newEmployee = companyEmployees.find(e => e.id === id);
              const selectedDays = [];

              allRows.forEach(row => {
                const checkedDays = row.days.filter(d => d.checked);
                selectedDays.push(...checkedDays);
              });

              const selectedDaysNumbers = selectedDays.map(d => d.nativeDate.getDate());

              if (setSavedContract) {
                onSuccess({
                  dates: selectedDaysNumbers,
                  employee: {
                    ...newEmployee,
                    functionName: newEmployee.workerclass,
                  },
                });
              }
            } else {
              setIsDisabled(false);
            }
          })();
        } else {
          dispatch(
            validateAndSaveEditContractAction({
              contract: {
                contractId: cardData.contractId,
                employeeId: cardData?.employee ? cardData.employee.id : getEmployeeId(),
                companyId: cardData.companyId,
                contractType: cardData.contractType,
                functionId: toNumber(watchFunction),
                costCenterId: toNumber(watchCostCenter),
                locationId: toNumber(watchLocationId),
                workingLocation,
                dates: allRows,
                wage: toNumber(wage),
              },
              companyId: cardData.companyId,
            }),
          ).then(result => {
            if (result.payload) {
              onClose();

              onSuccess({
                contractId: cardData.contractId,
                employeeId: cardData?.employee ? cardData.employee.id : getEmployeeId(),
                companyId: cardData.companyId,
                contractType: cardData.contractType,
                functionId: toNumber(watchFunction),
                costCenterId: toNumber(watchCostCenter),
                locationId: toNumber(watchLocationId),
                workingLocation,
                dates: allRows,
                wage: toNumber(wage),
                permanent,
              });
            } else {
              setIsDisabled(false);
            }
          });
        }
      }
    },
    [
      getValues,
      contractType,
      selectedEmployees,
      handleValidateBulk,
      companyEmployees,
      companyId,
      allRows,
      week,
      weekForYear,
      wage,
      workingLocation,
      getEmployeeId,
      permanent,
      weekNumber,
      cardData,
      dispatch,
      onClose,
      setSavedContract,
      onSuccess,
      watchFunction,
      watchCostCenter,
      watchLocationId,
    ],
  );

  const onCostCentreClose = useCallback(() => {
    setRefreshCostCentre(true);
  }, []);

  const onError = useCallback((err, e) => {
    console.log(err, e);
  }, []);

  const onSubmit = useCallback(() => {
    const validTimeSpanRegex = /^(?:\d{2}:\d{2})?$/;
    let weekRowRequiredErrorAux = false;
    let canSave = true;
    if (!cardData) {
      const dates = allRows;

      if (dates.find(r => !r.hours.startWork)) {
        setCurrentStartWorkError(true);
        canSave = false;
      }
      if (dates.find(r => !r.hours.endWork)) {
        setCurrentEndWorkError(true);
        canSave = false;
      }

      if (dates.find(r => r.days.find(d => d.checked)) === undefined) {
        weekRowRequiredErrorAux = true;
      }

      const startWorkFormatIndex = dates.findIndex(r => !validTimeSpanRegex.test(r.hours.startWork));
      const endWorkFormatIndex = dates.findIndex(r => !validTimeSpanRegex.test(r.hours.endWork));
      const startBreakFormatIndex = dates.findIndex(r => !validTimeSpanRegex.test(r.hours.startBreak));
      const endBreakFormatIndex = dates.findIndex(r => !validTimeSpanRegex.test(r.hours.endBreak));

      if (startWorkFormatIndex > -1) {
        setFormatError(startWorkFormatIndex);
        canSave = false;
      }

      if (endWorkFormatIndex > -1) {
        setFormatError(endWorkFormatIndex);
        canSave = false;
      }

      if (startBreakFormatIndex > -1) {
        setFormatError(startBreakFormatIndex);
        canSave = false;
      }

      if (endBreakFormatIndex > -1) {
        setFormatError(endBreakFormatIndex);
        canSave = false;
      }
    } else {
      // for some reason the strucutre of allRows is changing when we are editing
      const editRows = JSON.parse(JSON.stringify(allRows));
      const startWorkFormatIndex = editRows.findIndex(r => !validTimeSpanRegex.test(r.startHour));
      const endWorkFormatIndex = editRows.findIndex(r => !validTimeSpanRegex.test(r.endHour));
      const startBreakFormatIndex = editRows.findIndex(r => !validTimeSpanRegex.test(r.startBreak));
      const endBreakFormatIndex = editRows.findIndex(r => !validTimeSpanRegex.test(r.endBreak));

      if (startWorkFormatIndex > -1) {
        setFormatError(startWorkFormatIndex);
        canSave = false;
      }

      if (endWorkFormatIndex > -1) {
        setFormatError(endWorkFormatIndex);
        canSave = false;
      }

      if (startBreakFormatIndex > -1) {
        setFormatError(startBreakFormatIndex);
        canSave = false;
      }

      if (endBreakFormatIndex > -1) {
        setFormatError(endBreakFormatIndex);
        canSave = false;
      }
    }

    setWeekRowRequiredError(weekRowRequiredErrorAux);
    const dateErrKeys = Object.keys(datesErrorsObject);
    let found = false;
    dateErrKeys.forEach((k: any) => {
      if (datesErrorsObject[k].found) found = true;
    });

    if (!weekRowRequiredErrorAux && !found && canSave) {
      // setIsDisabled(true);
      handleSubmit(onValidateAndSaveContract, onError)();
    }
  }, [cardData, allRows, datesErrorsObject, handleSubmit, onValidateAndSaveContract, onError]);

  const isSaveDisabled = useCallback((): boolean => {
    const keys = Object.keys(datesErrorsObject).map(Number);
    const hasDateError = keys.some(key => datesErrorsObject[key].found || datesErrorsObject[key].foundHourInPresent);
    return currentStartWorkError || (datesErrorsObject && hasDateError) || isDisabled;
  }, [currentStartWorkError, datesErrorsObject, isDisabled]);

  const getModalTitle = (): string => {
    if (!isEmployee) {
      if (cardData?.vendor) {
        return t(CONTRACTS__FILL_CONTRACT_SLOT);
      }

      if (cardData?.dimonaId) {
        return `${t(CONTRACTS__EDIT_CONTRACT)}|  ${cardData.dimonaId}`;
      }
      return t(CONTRACTS__EDIT_CONTRACT);
    }
    return t(CONTRACTS__VIEW_CONTRACT);
  };

  const getDefaultValue = () => {
    if (prefilledEmployeeId) {
      return [employees.find(e => e.value === prefilledEmployeeId.toString())];
    }
    return [];
  };

  return (
    <Backdrop open sx={{ zIndex: '1' }}>
      <ModalWrapperContract open={open} onClose={onClose}>
        <ModalContainer sx={{ padding: '5px' }}>
          <ModalHeaderContract>
            <ModalTitleContainer>
              <ModalTitle>{cardData ? getModalTitle() : `${title} | ${t(HEADER__WEEK)} ${week}`}</ModalTitle>
              <ModalCloseIconStyled onClick={onClose}>
                <CloseIcon fill={theme.palette.secondary.contrastText} />
              </ModalCloseIconStyled>
            </ModalTitleContainer>
            {cardData && cardData.errorMessage && (
              <Box>
                <ErrorBar message={cardData.errorMessage} />
              </Box>
            )}

            {cardData && cardData.warningMessage && (
              <Box>
                <WarningBar message={cardData.warningMessage} />
              </Box>
            )}

            {!cardData ? (
              <RowContainerForNameAndContractType>
                <NameInputStyled expanded={selectedEmployees.length > 1}>
                  <Controller
                    name="employeeId"
                    control={control}
                    render={({ field }) => (
                      <UPMultipleSelectAutocomplete
                        handleChange={(event, newValue) => {
                          onNameChange(newValue);
                          field.onChange(event);
                        }}
                        defaultValue={getDefaultValue()}
                        items={employees}
                        label={t(GENERAL__NAME)}
                        placeholder={t(GENERAL__SELECT)}
                        required
                      />
                    )}
                  />
                </NameInputStyled>
                {selectedEmployees.length <= 1 && (
                  <WorkerclassInputStyled>
                    <UPInput
                      value={contractType}
                      onChange={null}
                      label={t(GENERAL__WORKERCLASS)}
                      placeholder=""
                      disabled
                    />
                  </WorkerclassInputStyled>
                )}
              </RowContainerForNameAndContractType>
            ) : (
              <RowContainerForNameAndContractType>
                <NameInputStyled>
                  <Controller
                    name="employeeId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => {
                      return (
                        <UPDropdownWithSearch
                          value={isEmployee ? cardData.employee.id : field.value}
                          onChange={field.onChange}
                          placeholder={t(GENERAL__SELECT)}
                          items={
                            isEmployee
                              ? [
                                  {
                                    value: `${cardData.employee.id}`,
                                    label: `${cardData.employee.firstName} ${cardData.employee.lastName} `,
                                  },
                                ]
                              : employees
                          }
                          label={t(GENERAL__NAME)}
                          required
                          error={!!errors.employeeId}
                          helperText={getInputErrorText(errors, 'employeeId')}
                          disabled={isEmployee || (Boolean(cardData) && !cardData?.vendor)}
                        />
                      );
                    }}
                  />
                </NameInputStyled>
                <WorkerclassInputStyled>
                  <UPInput
                    value={contractType}
                    onChange={null}
                    label={t(GENERAL__WORKERCLASS)}
                    placeholder=""
                    disabled
                  />
                </WorkerclassInputStyled>
              </RowContainerForNameAndContractType>
            )}
          </ModalHeaderContract>
          <ModalContentContainer>
            {!cardData ? (
              allRows.map((row, index) => (
                <ContractDateContainer key={`row ${row.id}`}>
                  <AddEditDayPickerRow
                    week={week}
                    weekForYear={weekForYear}
                    selectedDates={row.days}
                    onCheckDay={(selectedDate: IWeekDays) => onCheckDay(selectedDate, index)}
                    hours={row.hours}
                    onChangeHours={(value: string, type: string) => onChangeHours(value, type, index)}
                    error={weekRowRequiredError}
                    currentDateError={datesErrorsObject}
                    currentStartWorkError={currentStartWorkError}
                    currentEndWorkError={currentEndWorkError}
                    formatError={formatError}
                    row={index}
                    isEdit={!!cardData}
                  />
                  <ContractXIcon onClick={() => onDeleteRow(index)}>
                    {!cardData && <CloseIcon fill={theme.palette.secondary.contrastText} />}
                  </ContractXIcon>
                </ContractDateContainer>
              ))
            ) : (
              <ContractDateContainer key={`${cardData.contractId} editContracts`}>
                <AddEditDayPickerRow
                  week={week}
                  weekForYear={weekForYear}
                  selectedDates={allRows}
                  onCheckDay={null}
                  onChangeHours={onChangeHoursEdit}
                  error={weekRowRequiredError}
                  currentDateError={datesErrorsObject}
                  currentStartWorkError={currentStartWorkError}
                  currentEndWorkError={currentEndWorkError}
                  formatError={formatError}
                  isEdit={!!cardData}
                  isSlot={!!cardData.vendor}
                />
              </ContractDateContainer>
            )}
            {weekRowRequiredError && <DayPickerError>{t(VALIDATION__IS_REQUIRED)}</DayPickerError>}
            {!cardData && (
              <RowContainer>
                <UPButton
                  text={t(CONTRACTS__ADD_ANOTHER)}
                  onClick={() => {
                    const newArray = [...allRows];
                    const newRow = {
                      id: allRows[allRows.length - 1].id + 1,
                      days: weekDays.map(day => ({
                        ...day,
                        checked: false,
                      })),
                      hours: {
                        startWork: '',
                        startBreak: '',
                        endBreak: '',
                        endWork: '',
                      },
                    };
                    newArray.push(newRow);
                    datesErrorsObject[newArray.length - 1] = {
                      found: false,
                      foundHourInPresent: false,
                    };

                    setAllRows(newArray);
                  }}
                  outlined
                />
              </RowContainer>
            )}
            <RowContainerForNameAndContractType>
              <CompanyBranchInputStyled>
                <Controller
                  name="locationId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__SELECT)}
                      items={locations}
                      label={t(CONTRACTS__COMPANY_LOCATION)}
                      required
                      error={!!errors.locationId}
                      helperText={getInputErrorText(errors, 'locationId')}
                      disabled={isEmployee}
                    />
                  )}
                />
              </CompanyBranchInputStyled>
              <CostCenterStyled>
                <Controller
                  name="costCenterId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <EditableCostcenter
                      items={costCenters}
                      selectedValue={field.value}
                      handleChange={field.onChange}
                      companyId={companyId}
                      label={t(CONTRACTS__COST_CENTER)}
                      locationId={watchLocationId}
                      required
                      error={!!errors.costCenterId}
                      helperText={getInputErrorText(errors, 'costCenterId')}
                      onClose={onCostCentreClose}
                      disabled={isEmployee}
                      isEdit={!!cardData}
                    />
                  )}
                />
              </CostCenterStyled>
            </RowContainerForNameAndContractType>

            <RowContainerForNameAndContractType>
              {!permanent && (
                <>
                  <FunctionStyled>
                    <Controller
                      name="functionId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <UPDropdownWithSearch
                          value={field.value}
                          onChange={field.onChange}
                          placeholder={t(GENERAL__SELECT)}
                          items={functions}
                          label={t(CONTRACTS__FUNCTION)}
                          required
                          error={!!errors.functionId}
                          helperText={getInputErrorText(errors, 'functionId')}
                          disabled={isEmployee}
                          hasDefaultValue
                        />
                      )}
                    />
                  </FunctionStyled>
                  {selectedEmployees.length <= 1 && profile.showWages && (
                    <WageStyled>
                      <UPInput value={`€ ${wage}`} label={t(CONTRACTS__WAGE)} placeholder="" onChange={null} disabled />
                    </WageStyled>
                  )}
                </>
              )}
              <WorkingLocationStyled
                sx={{ minWidth: permanent ? 'unset' : '280px', width: permanent ? '100%' : '33%' }}
              >
                <UPInput
                  value={workingLocation}
                  onChange={event => {
                    setWorkingLocation(event.target.value);
                  }}
                  placeholder={t(CONTRACTS__WORKING_LOCATION)}
                  label={t(CONTRACTS__WORKING_LOCATION)}
                  inputClassName="white-background"
                  disabled={isEmployee}
                />
              </WorkingLocationStyled>
            </RowContainerForNameAndContractType>
          </ModalContentContainer>
          <ModalFooter>
            {!isEmployee && (
              <UPButton
                text={cardData ? t(GENERAL__SAVE_BUTTON) : t(GENERAL__CREATE)}
                onClick={onSubmit}
                disabled={isSaveDisabled() || (selectedEmployees.length === 0 && !cardData)}
              />
            )}
            <Box sx={{ marginLeft: '12px' }}>
              <UPButton text={t(CONTRACTS__CANCEL)} onClick={onClose} outlined />
            </Box>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapperContract>
    </Backdrop>
  );
};

export default AddEditContractModal;
