/* eslint-disable */
import { Box } from '@mui/system';
import UPTab from 'components/tab';
import { toNumber } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { checkIsParentCompanyAction, clearIsParentCompanyAction } from 'store/actions/company-actions';
import { isParentCompanySelector } from 'store/selectors/company-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  COMPANY__COMPANY,
  COMPANY__CONTACT,
  COMPANY__LOCATIONS,
  COMPANY__PAYROLL,
  COMPANY__MANAGERS,
  COMPANY__FINANCIAL,
  COMPANY__INTEGRATION,
  COMPANY__STRUCTURE,
  COMPANY__VENDORS,
} from 'translations/constants';
import EditCompanyInformation from './company-information/EditCompanyInformation';
import EditContact from './contact/EditContact';
import EditIntegration from './integration/EditIntegration';
import Locations from './locations/Locations';
import Managers from './managers/Managers';
import EditPaymentInformation from './payment-information/EditPaymentInformation';
import { EditPayroll } from './payroll';
import { ChildCompaniesStructure } from './structure';
import Vendors from './vendors/Vendors';
import { CompanyRole } from 'constants/CompanyRole';

const Company = (): JSX.Element => {
  const [t] = useTranslation();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(profileSelector);
  const isParentCompany = useAppSelector(isParentCompanySelector);

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(checkIsParentCompanyAction(toNumber(companyId)));
    }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearIsParentCompanyAction());
    };
  }, [dispatch, clearIsParentCompanyAction]);

  const CompanyTabs = [
    { title: t(COMPANY__COMPANY), route: 'general' },
    { title: t(COMPANY__CONTACT), route: 'contact' },
    { title: t(COMPANY__LOCATIONS), route: 'locations' },
    { title: t(COMPANY__PAYROLL), route: 'payroll' },
    { title: t(COMPANY__MANAGERS), route: 'managers' },
    { title: t(COMPANY__VENDORS), route: 'vendors' },
    { title: t(COMPANY__FINANCIAL), route: 'financial' },
    { title: t(COMPANY__INTEGRATION), route: 'integration' },
    { title: t(COMPANY__STRUCTURE), route: 'structure', isVisible: isParentCompany },
  ];

  const CompanyManagerUserTabs = [
    { title: t(COMPANY__COMPANY), route: 'general' },
    { title: t(COMPANY__CONTACT), route: 'contact' },
    { title: t(COMPANY__PAYROLL), route: 'payroll' },
    { title: t(COMPANY__MANAGERS), route: 'managers' },
    { title: t(COMPANY__VENDORS), route: 'vendors' },
    { title: t(COMPANY__STRUCTURE), route: 'structure', isVisible: isParentCompany },
  ];

  const CompanyUserTabs = [
    { title: t(COMPANY__COMPANY), route: 'general' },
    { title: t(COMPANY__CONTACT), route: 'contact' },
  ];

  switch (user.role) {
    case CompanyRole.ADMIN:
      return (
        <UPTab sections={CompanyTabs} disabled={companyId === undefined}>
          <EditCompanyInformation />
          <EditContact />
          <Locations />
          <EditPayroll />
          <Managers />
          <Vendors />
          <EditPaymentInformation />
          <EditIntegration />
          {isParentCompany && <ChildCompaniesStructure />}
        </UPTab>
      );
    case CompanyRole.COMPANY_MANAGER:
      return (
        <UPTab sections={CompanyManagerUserTabs} disabled={companyId === undefined}>
          <EditCompanyInformation />
          <EditContact />
          <EditPayroll />
          <Managers />
          <Vendors />
          {isParentCompany && <ChildCompaniesStructure />}
        </UPTab>
      );
    case CompanyRole.LOCATION_MANAGER:
    case CompanyRole.HR_MANAGER:
      return (
        <UPTab sections={CompanyUserTabs} disabled={companyId === undefined}>
          <EditCompanyInformation />
          <EditContact />
        </UPTab>
      );
    default:
  }
  return <Box>You do not have acces to this page</Box>;
};

export default Company;
