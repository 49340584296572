import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllCountriesNamesRequest } from 'api/requests/country-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  COUNTRIES__GENERAL_LOADING,
  COUNTRIES__GET_ALL_COUNTRIES_NAMES,
  COUNTRIES__SET_COUNTRIES_NAMES,
  COUNTRIES__SET_COUNTRIES_NAMES_ERROR,
} from 'store/constants';

export const setCountriesRequestsLoadingAction = createAction<boolean>(COUNTRIES__GENERAL_LOADING);

export const setCountriesNamesAction = createAction<UPDropdownItem[]>(COUNTRIES__SET_COUNTRIES_NAMES);
export const setCountriesNamesErrorAction = createAction<boolean>(COUNTRIES__SET_COUNTRIES_NAMES_ERROR);

export const getCountriesNamesAction = createAsyncThunk(
  COUNTRIES__GET_ALL_COUNTRIES_NAMES,
  async (language: string, thunkApi) => {
    thunkApi.dispatch(setCountriesRequestsLoadingAction(true));
    thunkApi.dispatch(setCountriesNamesErrorAction(false));
    try {
      const response = await getAllCountriesNamesRequest(language);
      thunkApi.dispatch(setCountriesNamesAction(response));
    } catch (error) {
      thunkApi.dispatch(setCountriesNamesErrorAction(true));
    }
    thunkApi.dispatch(setCountriesRequestsLoadingAction(false));
  },
);
