import { Backdrop } from '@mui/material';
import AppContainer from 'components/app-container';
import UPLoadingSpinner from 'components/loading-spinner';
import SetPassword from 'modules/auth/SetPassword';
import Availabilities from 'modules/availabilities/employee-availabilities/Availabilities';
import CompanyPickScreen from 'modules/availabilities/employee-availabilities/company-pick-screen/CompanyPickScreen';
import Blogs from 'modules/blogs';
import Company from 'modules/companies';
import Companies from 'modules/companies/Companies';
import EditCompanyInformation from 'modules/companies/company-information/EditCompanyInformation';
import EditContact from 'modules/companies/contact/EditContact';
import EditIntegration from 'modules/companies/integration/EditIntegration';
import { Locations } from 'modules/companies/locations';
import { Managers } from 'modules/companies/managers';
import EditPaymentInformation from 'modules/companies/payment-information/EditPaymentInformation';
import { EditPayroll } from 'modules/companies/payroll';
import { ChildCompaniesStructure } from 'modules/companies/structure';
import Vendors from 'modules/companies/vendors/Vendors';
import { Contracts } from 'modules/contracts';
import Dashboard from 'modules/dashboard';
import Documents from 'modules/documents';
import Employees from 'modules/employees/Employees';
import ForgotPassword from 'modules/forgotpassword/ForgotPassword';
import Logout from 'modules/logout';
import ParitairComite from 'modules/paritair-comite';
import ParitairComiteDetails from 'modules/paritair-comite/ParitairComiteDetails';
import PayrollSettings from 'modules/payroll-settings/PayrollSettings';
import WageComponents from 'modules/payroll-settings/WageComponents';
import ChangePassword from 'modules/profile/ChangePassword';
import Profile from 'modules/profile/Profile';
import Reporting from 'modules/reporting';
import Users from 'modules/superadmins/Users';
import ApiUsers from 'modules/superadmins/ApiUsers';
import { Navigate, Route, Routes } from 'react-router-dom';
import postAuthStartup from 'services/post-auth-startup';
import { useAppSelector } from 'store';
import { appGeneralLoadingSelector } from 'store/selectors/app-selectors';
import SuperAdmins from 'modules/superadmins/SuperAdmins';
import { useEffect, useState } from 'react';

const AppRouter = (): JSX.Element => {
  const [isReady, setIsReady] = useState(false);
  const requsestsLoading = useAppSelector(appGeneralLoadingSelector);

  useEffect(() => {
    (async () => {
      await postAuthStartup();
      setIsReady(true);
    })();
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <AppContainer>
      <Backdrop open={requsestsLoading} sx={{ zIndex: 9000, color: '#fff' }} transitionDuration={300}>
        <UPLoadingSpinner />
      </Backdrop>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/auth/set-password/:uniqueKey" element={<SetPassword />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/company/:companyId" element={<Company />}>
          <Route path="general" element={<EditCompanyInformation />} />
          <Route path="contact" element={<EditContact />} />
          <Route path="locations" element={<Locations />} />
          <Route path="payroll" element={<EditPayroll />} />
          <Route path="managers" element={<Managers />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="financial" element={<EditPaymentInformation />} />
          <Route path="integration" element={<EditIntegration />} />
          <Route path="structure" element={<ChildCompaniesStructure />} />
        </Route>
        <Route path="/company/new" element={<Company />}>
          <Route path="general" element={<EditCompanyInformation />} />
          <Route path="contact" element={<EditContact />} />
          <Route path="locations" element={<Locations />} />
          <Route path="payroll" element={<EditPayroll />} />
          <Route path="managers" element={<Managers />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="financial" element={<EditPaymentInformation />} />
          <Route path="integration" element={<EditIntegration />} />
        </Route>
        <Route path="/company/new" element={<Company />} />

        <Route path="/payroll-settings">
          <Route index element={<PayrollSettings />} />
          <Route path="paritair-comite">
            <Route index element={<ParitairComite />} />
            <Route path="paritair-comite-details/:paritairComiteId/*" element={<ParitairComiteDetails />} />
            <Route path="paritair-comite-details/new/*" element={<ParitairComiteDetails />} />
          </Route>
          <Route path="wage-components" element={<WageComponents />} />
        </Route>

        <Route path="/employees/*" element={<Employees />} />
        <Route path="/employees/:pCompanyId/:pEmployeeName" element={<Employees />} />
        <Route path="/availabilities" element={<CompanyPickScreen />} />
        <Route path="/availabilities/:companyId" element={<Availabilities />} />
        <Route path="/users" element={<Users />}>
          <Route path="superadmins" element={<SuperAdmins />} />
          <Route path="apiUsers" element={<ApiUsers />} />
        </Route>
        <Route path="/contracts/*" element={<Contracts />} />
        <Route path="/contracts/:pCompanyId/:pYear/:pWeek" element={<Contracts />} />
        <Route path="/docs" element={<Documents />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/reports" element={<Reporting />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </AppContainer>
  );
};

export default AppRouter;
