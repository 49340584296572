import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './reducers/auth-reducer';
import companyContactReducer from './reducers/company-contact-reducer';
import companyFinancialReducer from './reducers/company-financial-reducer';
import locationReducer from './reducers/company-location-reducer';
import locationsReducer from './reducers/company-locations-reducer';
import managerReducer from './reducers/company-manager-reducer';
import managersReducer from './reducers/company-managers-reducer';
import companyReducer from './reducers/company-reducer';
import drawerReducer from './reducers/drawer-reducer';
import profileReducer from './reducers/profile-reducer';
import paritairComiteReducer from './reducers/paritair-comite-reducer';
import payrollReducer from './reducers/payroll-reducer';
import functionsReducer from './reducers/company-functions-reducer';
import functionReducer from './reducers/company-function-reducer';
import costCentersReducer from './reducers/company-cost-centers-reducer';
import costCenterReducer from './reducers/company-cost-center-reducer';
import wageExemptionsReducer from './reducers/company-wage-exemptions-reducer';
import menuReducer from './reducers/menu-reducer';
import superAdminsReducer from './reducers/superadmins-reducer';
import superAdminReducer from './reducers/superadmin-reducer';
import minimumWagesReducer from './reducers/minimum-wages-reducer';
import countriesReducer from './reducers/countries-reducer';
import employeeReducer from './reducers/employee-reducer';
import financialReducer from './reducers/financial-reducer';
import contractReducer from './reducers/contract-reducer';
import contractsReducer from './reducers/contracts-reducer';
import appReducer from './reducers/app-reducer';
import documentsReducer from './reducers/documents-reducer';
import blogReducer from './reducers/blog-reducer';
import costDeclarationReducer from './reducers/cost-declaration-reducer';
import apiErrorsReducer from './reducers/api-errors-reducer';
import availabilitiesReducer from './reducers/availabilities-reducer';
import wageCalculationReducer from './reducers/wage-calculation-reducer';
import vendorsReducer from './reducers/company-vendors-reducer';
import companyAvailabilityFiltersReducer from './reducers/company-availability-filters-reducer';
import apiUserReducer from './reducers/api-user-reducer';
import apiUsersReducer from './reducers/api-users-reducer';
import reportingReducer from './reducers/reporting-reducer';

/**
 * Register application wide reducers here
 * Reducers will be bind to the root state using the namespace given
 * in the object passed to the combineReducers function
 */
const rootReducer = combineReducers({
  app: appReducer,
  availabilities: availabilitiesReducer,
  auth: authReducer,
  menu: menuReducer,
  company: companyReducer,
  contact: companyContactReducer,
  locations: locationsReducer,
  location: locationReducer,
  managers: managersReducer,
  manager: managerReducer,
  vendors: vendorsReducer,
  financial: companyFinancialReducer,
  companyAvailabilityFilters: companyAvailabilityFiltersReducer,
  profile: profileReducer,
  drawer: drawerReducer,
  paritairComite: paritairComiteReducer,
  costDeclaration: costDeclarationReducer,
  payroll: payrollReducer,
  functions: functionsReducer,
  function: functionReducer,
  costCenters: costCentersReducer,
  costCenter: costCenterReducer,
  wageExemptions: wageExemptionsReducer,
  superAdmins: superAdminsReducer,
  superAdmin: superAdminReducer,
  minimumWages: minimumWagesReducer,
  countries: countriesReducer,
  employee: employeeReducer,
  bic: financialReducer,
  contract: contractReducer,
  contracts: contractsReducer,
  documents: documentsReducer,
  blog: blogReducer,
  apiErrors: apiErrorsReducer,
  wageCalculation: wageCalculationReducer,
  apiUsers: apiUsersReducer,
  apiUser: apiUserReducer,
  reporting: reportingReducer,
});

export default rootReducer;
