import { Alert, Box } from '@mui/material';
import UPButton from 'components/button';
import UPDatepicker from 'components/datepicker';
import UPInput from 'components/input';
import { toNumber } from 'lodash';
import { CloseAlertIcon, SectionContainer } from 'modules/companies/styles';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import {
  clearParitairComiteAction,
  getParitairComiteByIdAction,
  saveParitairComiteAction,
  saveParitairComiteErrorAction,
  saveParitairComiteSuccessAction,
  updateParitairComiteAction,
  updateParitairComiteErrorAction,
  updateParitairComiteSuccessAction,
} from 'store/actions/paritair-comite-actions';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import {
  paritairComiteSelector,
  updateParitairComiteErrorSelector,
  updateParitairComiteSuccessSelector,
  saveParitairComiteErrorSelector,
  saveParitairComiteSuccessSelector,
  paritairComiteIdSelector,
} from 'store/selectors/paritair-comite-selectors';
import {
  GENERAL__SAVE_BUTTON,
  PARITAIR_COMITE__DUTCH,
  PARITAIR_COMITE__ENGLISH,
  PARITAIR_COMITE__FRENCH,
  PARITAIR_COMITE__START_DATE,
  GENERAL__ERROR_MESSAGE,
  PARITAIR_COMITE__UPDATED_SUCCESSFULLY,
  PARITAIR_COMITE__ADDED_SUCCESSFULLY,
  MENU__PAYROLL_SETTINGS,
} from 'translations/constants';
import { IParitairComite } from 'types/paritair-comite';
import { getInputErrorText } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';

const ParitairComiteInitialState: IParitairComite = {
  id: 0,
  number: '',
  dutchName: '',
  frenchName: '',
  englishName: '',
  startDate: null,
};

const ParitairComiteGeneral = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { paritairComiteId } = useParams();
  const navigate = useNavigate();

  const paritairComite = useAppSelector(paritairComiteSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);
  const updateParitairComiteError = useAppSelector(updateParitairComiteErrorSelector);
  const updateParitairComiteSuccess = useAppSelector(updateParitairComiteSuccessSelector);
  const saveParitairComiteError = useAppSelector(saveParitairComiteErrorSelector);
  const saveParitairComiteSuccess = useAppSelector(saveParitairComiteSuccessSelector);
  const newParitairComiteId = useAppSelector(paritairComiteIdSelector);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm<IParitairComite>({
    defaultValues: ParitairComiteInitialState,
  });

  const onSaveParitairComite: SubmitHandler<IParitairComite> = useCallback(
    data => {
      if (isDirty) {
        if (paritairComiteId === undefined) {
          dispatch(saveParitairComiteAction(data));
        } else {
          dispatch(updateParitairComiteAction(data));
        }
      }
    },
    [dispatch, isDirty, paritairComiteId],
  );

  useEffect(() => {
    if (activeItem !== t(MENU__PAYROLL_SETTINGS)) {
      dispatch(setMenuStatusAction(t(MENU__PAYROLL_SETTINGS)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    dispatch(clearParitairComiteAction());
    if (paritairComiteId !== undefined) {
      dispatch(getParitairComiteByIdAction(toNumber(paritairComiteId)));
    }
  }, [dispatch, paritairComiteId]);

  useEffect(() => {
    if (paritairComite && paritairComiteId) {
      reset(paritairComite);
    }
  }, [dispatch, paritairComite, paritairComiteId, reset]);

  useEffect(() => {
    if (saveParitairComiteError || updateParitairComiteError) {
      setAlertOpen(true);
      if (saveParitairComiteSuccess) {
        setTimeout(() => {
          setAlertOpen(false);
          if (newParitairComiteId) {
            navigate(`/paritair-comite-details/${newParitairComiteId}/general`);
          }
        }, 1000);
      }
    }
  }, [navigate, newParitairComiteId, saveParitairComiteError, saveParitairComiteSuccess, updateParitairComiteError]);

  return (
    <SectionContainer>
      <Box sx={{ width: '40%' }}>
        <Box sx={{ marginBottom: '24px' }}>
          <Controller
            name="number"
            control={control}
            rules={{ required: true, maxLength: 7 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label="Paritair Comité"
                placeholder="Paritair Comité"
                required
                error={!!errors.number}
                helperText={getInputErrorText(errors, 'number', undefined, 7, undefined, undefined, true)}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Controller
            name="dutchName"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(PARITAIR_COMITE__DUTCH)}
                placeholder={t(PARITAIR_COMITE__DUTCH)}
                required
                error={!!errors.dutchName}
                helperText={getInputErrorText(errors, 'dutchName', undefined, 100)}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Controller
            name="frenchName"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(PARITAIR_COMITE__FRENCH)}
                placeholder={t(PARITAIR_COMITE__FRENCH)}
                required
                error={!!errors.frenchName}
                helperText={getInputErrorText(errors, 'frenchName', undefined, 100)}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Controller
            name="englishName"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(PARITAIR_COMITE__ENGLISH)}
                placeholder={t(PARITAIR_COMITE__ENGLISH)}
                required
                error={!!errors.englishName}
                helperText={getInputErrorText(errors, 'englishName', undefined, 100)}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '32px' }}>
          <Controller
            name="startDate"
            control={control}
            render={({ field }) => (
              <UPDatepicker label={t(PARITAIR_COMITE__START_DATE)} value={field.value} onChange={field.onChange} />
            )}
          />
        </Box>
        <Box>
          <UPButton text={t(GENERAL__SAVE_BUTTON)} onClick={() => handleSubmit(onSaveParitairComite)()} />
        </Box>
        <Box sx={{ marginTop: '32px' }}>
          {alertOpen && updateParitairComiteError && (
            <Alert
              severity="error"
              icon={false}
              variant="filled"
              sx={{ width: '800px' }}
              action={
                <CloseAlertIcon
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(updateParitairComiteErrorAction(false));
                  }}
                >
                  <CloseIcon fill="white" />
                </CloseAlertIcon>
              }
              onClose={() => setAlertOpen(false)}
            >
              There has been an error with updating the paritair comité. Please try again later.
            </Alert>
          )}
          {alertOpen && saveParitairComiteError && (
            <Alert
              severity="error"
              icon={false}
              variant="filled"
              sx={{ width: '800px' }}
              action={
                <CloseAlertIcon
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(saveParitairComiteErrorAction(false));
                  }}
                >
                  <CloseIcon fill="white" />
                </CloseAlertIcon>
              }
              onClose={() => setAlertOpen(false)}
            >
              {t(GENERAL__ERROR_MESSAGE)}
            </Alert>
          )}
          {updateParitairComiteSuccess && (
            <Alert
              severity="success"
              icon={false}
              variant="filled"
              sx={{ width: '800px' }}
              action={
                <CloseAlertIcon
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(updateParitairComiteSuccessAction(false));
                  }}
                >
                  <CloseIcon fill="white" />
                </CloseAlertIcon>
              }
            >
              {t(PARITAIR_COMITE__UPDATED_SUCCESSFULLY)}
            </Alert>
          )}
          {saveParitairComiteSuccess && (
            <Alert
              severity="success"
              icon={false}
              variant="filled"
              sx={{ width: '800px' }}
              action={
                <CloseAlertIcon
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch(saveParitairComiteSuccessAction(false));
                  }}
                >
                  <CloseIcon fill="white" />
                </CloseAlertIcon>
              }
            >
              {t(PARITAIR_COMITE__ADDED_SUCCESSFULLY)}
            </Alert>
          )}
        </Box>
      </Box>
    </SectionContainer>
  );
};

export default ParitairComiteGeneral;
