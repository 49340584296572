import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { UPDataGrid } from 'components/data-grid';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_PAYROLL__COST_CENTERS,
  REPORTING__CONTRACTED_COST,
  REPORTING__NOSHOW_HOURS,
  REPORTING__PLANNED_HOURS,
  REPORTING__WORKED_COST,
  REPORTING__WORKED_HOURS,
} from 'translations/constants';
import { StatisticsPerCostCenterData } from 'types/reporting';

const StatisticsPerCostCenterReport = (props: { items: StatisticsPerCostCenterData[] }) => {
  const { items } = props;
  const [t] = useTranslation();

  const [, setRowOnHover] = useState<number>();

  const columnDef: GridColDef[] = [
    { field: 'costCenterId', headerName: '', hide: true },
    {
      field: 'costCenterName',
      headerName: t(COMPANY_PAYROLL__COST_CENTERS),
      width: 300,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'contractHours',
      headerName: t(REPORTING__PLANNED_HOURS),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'performedHours',
      headerName: t(REPORTING__WORKED_HOURS),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'noShowHours',
      headerName: t(REPORTING__NOSHOW_HOURS),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalContractedCost',
      headerName: t(REPORTING__CONTRACTED_COST),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalPerformedCost',
      headerName: t(REPORTING__WORKED_COST),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <Box>
      <UPDataGridContainer sx={{ height: '70vh' }}>
        <UPDataGrid
          columns={columnDef}
          rows={items}
          setRowOnHover={setRowOnHover}
          getRowId={r => `scc_${r.costCenterId}`}
        />
      </UPDataGridContainer>
    </Box>
  );
};

export default StatisticsPerCostCenterReport;
