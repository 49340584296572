/* eslint-disable react/destructuring-assignment */
import { SectionContainer } from 'modules/companies/styles';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import { useAppDispatch, useAppSelector } from 'store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import UPSwitch from 'components/switch';
import { UPMultipleSelectionDropdown } from 'components/dropdown';
import {
  EMPLOYEES__INVITE_EMPLOYEE_SAVED,
  EMPLOYEES__IS_FOR_ALL_LOCATIONS,
  EMPLOYEE__COMPANY_LOCATION,
  GENERAL__CANCEL_BUTTON,
  GENERAL__CREATE,
} from 'translations/constants';
import { useEffect, useState } from 'react';
import { getInputErrorText } from 'utils/helpers';
import { Box, Typography } from '@mui/material';
import UPButton from 'components/button';
import ErrorBar from 'components/error-bar';
import ICompanyInvitationLink from 'types/employee/ICompanyInvitationLink';
import { addCompanyEmployeeInvitationLink } from 'store/actions/employee-actions';
import {
  employeeCompanyEmployeeInvitationLinkErrorSelector,
  employeeCompanyEmployeeInvitationLinkSuccessSelector,
} from 'store/selectors/employee-selector';
import { IAddGeneralInvitationProps } from './types';
import { ModalFooter, RowContainerForContactInfo } from './styles';
import { AlertStyled } from '../styles';

const AddGenralEmployeeInvitation = (props: IAddGeneralInvitationProps) => {
  const { companyId, locations, onClose } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const [isForAllLocations, setIsForAllLocations] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<any>();

  const invitationError = useAppSelector(employeeCompanyEmployeeInvitationLinkErrorSelector);
  const invitationSuccess = useAppSelector(employeeCompanyEmployeeInvitationLinkSuccessSelector);

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShowMessage(false);
    }, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const onSave: SubmitHandler<any> = () => {
    const values = getValues();
    const locationIdList = values.isForAllLocations ? [] : values.companyLocationIds?.toString().split(',').map(Number);

    const objectToSend: ICompanyInvitationLink = {
      id: 0,
      companyId: toNumber(companyId),
      locationIds: locationIdList,
      isForAllLocations: values.isForAllLocations,
    };
    dispatch(addCompanyEmployeeInvitationLink(objectToSend));
    setShowMessage(true);
  };

  return (
    <SectionContainer>
      {showMessage && invitationError && (
        <Box>
          <ErrorBar message={t(invitationError)} />
        </Box>
      )}

      {showMessage && invitationSuccess && (
        <AlertStyled severity="success">{t(EMPLOYEES__INVITE_EMPLOYEE_SAVED)}</AlertStyled>
      )}
      <RowContainerForContactInfo>
        <Box sx={{ display: 'inline-block', marginRight: '16px', width: '70%' }}>
          <Controller
            name="companyLocationIds"
            control={control}
            rules={{ required: !isForAllLocations }}
            render={({ field: { onChange, value: currentValue } }) => {
              return (
                <UPMultipleSelectionDropdown
                  items={locations}
                  handleChange={event => {
                    const {
                      target: { value },
                    } = event;
                    onChange(value);
                  }}
                  value={!isForAllLocations ? currentValue ?? [] : []}
                  dropdownLabel={t(EMPLOYEE__COMPANY_LOCATION)}
                  placeholder={locations.length === 0 ? 'No locations available' : t(EMPLOYEE__COMPANY_LOCATION)}
                  disabled={isForAllLocations}
                  helperText={getInputErrorText(errors, 'companyLocationIds')}
                  error={errors.companyLocationIds}
                  required
                />
              );
            }}
          />
        </Box>
        <Box>
          <Controller
            name="isForAllLocations"
            control={control}
            render={({ field }) => (
              <Box sx={{ display: 'flex', paddingTop: '1.1rem' }}>
                <UPSwitch
                  checked={field.value}
                  onChange={e => {
                    field.onChange(e);
                    setIsForAllLocations(Boolean(e.target.checked));
                  }}
                />
                <Typography sx={{ ml: '10px' }}>{t(EMPLOYEES__IS_FOR_ALL_LOCATIONS)}</Typography>
              </Box>
            )}
          />
        </Box>
      </RowContainerForContactInfo>
      <ModalFooter>
        <UPButton text={t(GENERAL__CREATE)} onClick={() => handleSubmit(onSave)()} />
        <Box sx={{ marginLeft: '12px' }}>
          <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onClose} outlined />
        </Box>
      </ModalFooter>
    </SectionContainer>
  );
};

export default AddGenralEmployeeInvitation;
