import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  bulkChangeActiveStatusForCompanyRequest,
  bulkChangeActiveStatusRequest,
  deleteCostDeclarationsForCompanyRequest,
  deleteCostDeclarationsRequest,
  getAllCostDeclarationsRequest,
  getCostDeclarationByIdRequest,
  getCostDeclarationSelectItemsForCompanyRequest,
  getCostDeclarationSelectItemsRequest,
  getCostDeclarationsForCompanyRequest,
  saveCostDeclarationRequest,
  saveCostDeclarationsForCompanyRequest,
  updateCostDeclarationRequest,
} from 'api/requests/wage-components-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS,
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_ERROR,
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_FOR_COMPANY,
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_SUCCESS,
  COST_DECLARATION__DELETE,
  COST_DECLARATION__DELETE_ERROR,
  COST_DECLARATION__DELETE_FOR_COMPANY,
  COST_DECLARATION__DELETE_SUCCESS,
  COST_DECLARATION__GET_ALL,
  COST_DECLARATION__GET_BY_ID,
  COST_DECLARATION__GET_FOR_COMPANY,
  COST_DECLARATION__GET_SELECT_ITEMS_FOR_COMPANY,
  COST_DECLARATION__SAVE,
  COST_DECLARATION__SAVE_ERROR,
  COST_DECLARATION__SAVE_FOR_COMPANY,
  COST_DECLARATION__SAVE_SUCCESS,
  COST_DECLARATION__SET_COST_DECLARATION,
  COST_DECLARATION__SET_COST_DECLARATIONS,
  COST_DECLARATION__SET_COST_DECLARATIONS_ERROR,
  COST_DECLARATION__SET_COST_DECLARATIONS_SUCCESS,
  COST_DECLARATION__SET_COST_DECLARATION_ERROR,
  COST_DECLARATION__GENERAL_LOADING,
  COST_DECLARATION__SET_COST_DECLARATION_SUCCESS,
  COST_DECLARATION__SET_FOR_COMPANY,
  COST_DECLARATION__SET_ID,
  COST_DECLARATION__SET_SELECT_ITEMS,
  COST_DECLARATION__SET_SELECT_ITEMS_ERROR,
  COST_DECLARATION__SET_SELECT_ITEMS_FOR_COMPANY,
  COST_DECLARATION__SET_SELECT_ITEMS_SUCCESS,
  COST_DECLARATION__UPDATE,
  COST_DECLARATION__UPDATE_ERROR,
  COST_DECLARATION__UPDATE_SUCCESS,
  COST_DECLARATION__GET_SELECT_ITEMS,
} from 'store/constants';
import { ICostDeclaration, ICostDeclarationForCompany } from 'types/cost-declaration';

export const setCostDeclarationsRequestsLoadingAction = createAction<boolean>(COST_DECLARATION__GENERAL_LOADING);

export const setCostDeclarationsAction = createAction<ICostDeclaration[]>(COST_DECLARATION__SET_COST_DECLARATIONS);
export const setCostDeclarationsErrorAction = createAction<boolean>(COST_DECLARATION__SET_COST_DECLARATIONS_ERROR);
export const setCostDeclarationsSuccessAction = createAction<boolean>(COST_DECLARATION__SET_COST_DECLARATIONS_SUCCESS);

export const getCostDeclarationsAction = createAsyncThunk(COST_DECLARATION__GET_ALL, async (_, thunkApi) => {
  thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
  thunkApi.dispatch(setCostDeclarationsErrorAction(false));
  thunkApi.dispatch(setCostDeclarationsSuccessAction(false));
  try {
    const response = await getAllCostDeclarationsRequest();
    thunkApi.dispatch(setCostDeclarationsAction(response));
    thunkApi.dispatch(setCostDeclarationsSuccessAction(true));
  } catch (error) {
    thunkApi.dispatch(setCostDeclarationsErrorAction(true));
  }
  thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
});

export const setCostDeclarationAction = createAction<ICostDeclaration>(COST_DECLARATION__SET_COST_DECLARATION);
export const setCostDeclarationSuccessAction = createAction<boolean>(COST_DECLARATION__SET_COST_DECLARATION_SUCCESS);
export const setCostDeclarationErrorAction = createAction<boolean>(COST_DECLARATION__SET_COST_DECLARATION_ERROR);

export const getCostDeclarationAction = createAsyncThunk(
  COST_DECLARATION__GET_BY_ID,
  async (costDeclarationId: number, thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(setCostDeclarationErrorAction(false));
    thunkApi.dispatch(setCostDeclarationSuccessAction(false));

    try {
      const response = await getCostDeclarationByIdRequest(costDeclarationId);
      thunkApi.dispatch(setCostDeclarationAction(response));
      thunkApi.dispatch(setCostDeclarationSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(setCostDeclarationErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const saveCostDeclarationSuccessAction = createAction<boolean>(COST_DECLARATION__SAVE_SUCCESS);
export const saveCostDeclarationErrorAction = createAction<boolean>(COST_DECLARATION__SAVE_ERROR);
export const setCostDeclarationIdAction = createAction<number>(COST_DECLARATION__SET_ID);

export const saveCostDeclarationAction = createAsyncThunk(
  COST_DECLARATION__SAVE,
  async (costDeclaration: ICostDeclaration, thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(saveCostDeclarationErrorAction(false));
    thunkApi.dispatch(saveCostDeclarationSuccessAction(false));

    try {
      const response = await saveCostDeclarationRequest(costDeclaration);
      thunkApi.dispatch(saveCostDeclarationSuccessAction(true));
      thunkApi.dispatch(setCostDeclarationIdAction(response));
      thunkApi.dispatch(getCostDeclarationsAction());
    } catch (error) {
      thunkApi.dispatch(saveCostDeclarationErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const updateCostDeclarationSuccessAction = createAction<boolean>(COST_DECLARATION__UPDATE_SUCCESS);
export const updateCostDeclarationErrorAction = createAction<boolean>(COST_DECLARATION__UPDATE_ERROR);

export const updateCostDeclarationAction = createAsyncThunk(
  COST_DECLARATION__UPDATE,
  async (costDeclaration: ICostDeclaration, thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(updateCostDeclarationErrorAction(false));
    thunkApi.dispatch(updateCostDeclarationSuccessAction(false));

    try {
      await updateCostDeclarationRequest(costDeclaration);
      thunkApi.dispatch(updateCostDeclarationSuccessAction(true));
      thunkApi.dispatch(getCostDeclarationsAction());
    } catch (error) {
      thunkApi.dispatch(updateCostDeclarationErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const deleteCostDeclarationsSuccessAction = createAction<boolean>(COST_DECLARATION__DELETE_SUCCESS);
export const deleteCostDeclarationsErrorAction = createAction<boolean>(COST_DECLARATION__DELETE_ERROR);

export const deleteCostDeclarationsAction = createAsyncThunk(
  COST_DECLARATION__DELETE,
  async (costDeclarationIds: number[], thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteCostDeclarationsErrorAction(false));
    thunkApi.dispatch(deleteCostDeclarationsSuccessAction(false));

    try {
      await deleteCostDeclarationsRequest(costDeclarationIds);
      thunkApi.dispatch(deleteCostDeclarationsSuccessAction(true));
      thunkApi.dispatch(getCostDeclarationsAction());
    } catch (error) {
      thunkApi.dispatch(deleteCostDeclarationsErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const setCostDeclarationSelectItemsAction = createAction<UPDropdownItem[]>(COST_DECLARATION__SET_SELECT_ITEMS);
export const setCostDeclarationSelectItemsErrorAction = createAction<boolean>(COST_DECLARATION__SET_SELECT_ITEMS_ERROR);
export const setCostDeclarationSelectItemsSuccessAction = createAction<boolean>(
  COST_DECLARATION__SET_SELECT_ITEMS_SUCCESS,
);

export const getCostDeclarationSelectItemsAction = createAsyncThunk(
  COST_DECLARATION__GET_SELECT_ITEMS,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(setCostDeclarationSelectItemsErrorAction(false));
    thunkApi.dispatch(setCostDeclarationSelectItemsSuccessAction(false));

    try {
      const response = await getCostDeclarationSelectItemsRequest(companyId);
      thunkApi.dispatch(setCostDeclarationSelectItemsAction(response));
      thunkApi.dispatch(setCostDeclarationSelectItemsSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(setCostDeclarationSelectItemsErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const setCostDeclarationSelectItemsForCompanyAction = createAction<UPDropdownItem[]>(
  COST_DECLARATION__SET_SELECT_ITEMS_FOR_COMPANY,
);

export const getCostDeclarationSelectItemsForCompanyAction = createAsyncThunk(
  COST_DECLARATION__GET_SELECT_ITEMS_FOR_COMPANY,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(setCostDeclarationSelectItemsErrorAction(false));
    thunkApi.dispatch(setCostDeclarationSelectItemsSuccessAction(false));

    try {
      const response = await getCostDeclarationSelectItemsForCompanyRequest(companyId);
      thunkApi.dispatch(setCostDeclarationSelectItemsForCompanyAction(response));
      thunkApi.dispatch(setCostDeclarationSelectItemsSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(setCostDeclarationSelectItemsErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const setCostDeclarationsForCompanyAction = createAction<ICostDeclarationForCompany[]>(
  COST_DECLARATION__SET_FOR_COMPANY,
);

export const getCostDeclarationsForCompanyAction = createAsyncThunk(
  COST_DECLARATION__GET_FOR_COMPANY,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(setCostDeclarationsErrorAction(false));
    thunkApi.dispatch(setCostDeclarationsSuccessAction(false));

    try {
      const response = await getCostDeclarationsForCompanyRequest(companyId);
      thunkApi.dispatch(setCostDeclarationsForCompanyAction(response));
      thunkApi.dispatch(setCostDeclarationsSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(setCostDeclarationsErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const saveCostDeclarationsForCompanyAction = createAsyncThunk(
  COST_DECLARATION__SAVE_FOR_COMPANY,
  async (props: { costDeclarationIds: number[]; companyId: number }, thunkApi) => {
    const { costDeclarationIds, companyId } = props;
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(saveCostDeclarationErrorAction(false));
    thunkApi.dispatch(saveCostDeclarationSuccessAction(false));

    try {
      await saveCostDeclarationsForCompanyRequest(costDeclarationIds, companyId);
      thunkApi.dispatch(saveCostDeclarationSuccessAction(true));
      thunkApi.dispatch(getCostDeclarationsForCompanyAction(companyId));
    } catch (error) {
      thunkApi.dispatch(saveCostDeclarationErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const bulkChangeActiveStatusSuccessAction = createAction<boolean>(
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_SUCCESS,
);
export const bulkChangeActiveStatusErrorAction = createAction<boolean>(
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_ERROR,
);

export const bulkChangeActiveStatusAction = createAsyncThunk(
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS,
  async (costDeclarationIds: number[], thunkApi) => {
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(bulkChangeActiveStatusErrorAction(false));
    thunkApi.dispatch(bulkChangeActiveStatusSuccessAction(false));

    try {
      await bulkChangeActiveStatusRequest(costDeclarationIds);
      thunkApi.dispatch(bulkChangeActiveStatusSuccessAction(true));
      thunkApi.dispatch(getCostDeclarationsAction());
    } catch (error) {
      thunkApi.dispatch(bulkChangeActiveStatusErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const bulkChangeActiveStatusForCompanyAction = createAsyncThunk(
  COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_FOR_COMPANY,
  async (props: { costDeclarationIds: number[]; companyId: number }, thunkApi) => {
    const { costDeclarationIds, companyId } = props;
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(bulkChangeActiveStatusErrorAction(false));
    thunkApi.dispatch(bulkChangeActiveStatusSuccessAction(false));

    try {
      await bulkChangeActiveStatusForCompanyRequest(costDeclarationIds, companyId);
      thunkApi.dispatch(bulkChangeActiveStatusSuccessAction(true));
      thunkApi.dispatch(getCostDeclarationsForCompanyAction(companyId));
    } catch (error) {
      thunkApi.dispatch(bulkChangeActiveStatusErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);

export const deleteCostDeclarationsForCompanyAction = createAsyncThunk(
  COST_DECLARATION__DELETE_FOR_COMPANY,
  async (props: { costDeclarationIds: number[]; companyId: number }, thunkApi) => {
    const { costDeclarationIds, companyId } = props;
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteCostDeclarationsErrorAction(false));
    thunkApi.dispatch(deleteCostDeclarationsSuccessAction(false));

    try {
      await deleteCostDeclarationsForCompanyRequest(costDeclarationIds, companyId);
      thunkApi.dispatch(deleteCostDeclarationsSuccessAction(true));
      thunkApi.dispatch(getCostDeclarationsForCompanyAction(companyId));
    } catch (error) {
      thunkApi.dispatch(deleteCostDeclarationsErrorAction(true));
    }
    thunkApi.dispatch(setCostDeclarationsRequestsLoadingAction(false));
  },
);
