import { createReducer } from '@reduxjs/toolkit';
import {
  clearCompaniesAction,
  clearCompanyAction,
  clearSaveCompanyStatusAction,
  clearUpdateCompanyStatusAction,
  deleteCompanyErrorAction,
  setCompanyRequestsLoadingAction,
  deleteCompanySuccessAction,
  getCompanyAction,
  getCompanyErrorAction,
  saveCompanyErrorAction,
  saveCompanySuccessAction,
  setCompaniesAction,
  setCompaniesErrorAction,
  setCompanyIdAction,
  updateCompanyErrorAction,
  updateCompanySuccessAction,
  setParentCompaniesAction,
  setChildCompaniesAction,
  setChildCompaniesErrorAction,
  setIsParentCompanyAction,
  clearIsParentCompanyAction,
  setExternalReferenceAction,
  setCompaniesAsDropdownItemsErrorAction,
  setCompaniesAsDropdownItemsAction,
  companyErrorMessageAction,
  setAllUserCompaniesAsDropdownItemsAction,
} from 'store/actions/company-actions';
import { ICompanyState } from 'types/company';

const initialState: ICompanyState = {
  companies: [],
  companiesError: false,
  company: null,
  companyError: false,
  loading: false,
  externalReferenceLoading: false,
  updateCompanySuccess: false,
  updateCompanyError: false,
  deleteCompanySuccess: false,
  deleteCompanyError: false,
  saveCompanySuccess: false,
  saveCompanyError: false,
  companyId: undefined,
  parentCompanies: [],
  childCompanies: [],
  childCompaniesError: false,
  isParentCompany: false,
  externalReferences: null,
  companiesDropdownItems: [],
  userCompaniesDropdownItems: [],
  companiesDropdownItemsError: false,
  companyErrorMessage: '',
};
const companyReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setCompaniesAction, (state, action) => ({ ...state, companies: action.payload }))
    .addCase(setCompaniesErrorAction, (state, action) => ({
      ...state,
      companiesError: action.payload,
    }))
    .addCase(clearCompaniesAction, state => ({
      ...state,
      companies: [],
      loading: false,
      companiesError: false,
    }))
    .addCase(getCompanyAction, (state, action) => ({ ...state, company: action.payload }))
    .addCase(getCompanyErrorAction, (state, action) => ({ ...state, companyError: action.payload }))
    .addCase(updateCompanySuccessAction, (state, action) => ({ ...state, updateCompanySuccess: action.payload }))
    .addCase(updateCompanyErrorAction, (state, action) => ({ ...state, updateCompanyError: action.payload }))
    .addCase(clearUpdateCompanyStatusAction, state => ({
      ...state,
      updateCompanyError: false,
      updateCompanySuccess: false,
      loading: false,
    }))
    .addCase(deleteCompanySuccessAction, (state, action) => ({ ...state, deleteCompanySuccess: action.payload }))
    .addCase(deleteCompanyErrorAction, (state, action) => ({ ...state, deleteCompanyError: action.payload }))
    .addCase(saveCompanySuccessAction, (state, action) => ({ ...state, saveCompanySuccess: action.payload }))
    .addCase(saveCompanyErrorAction, (state, action) => ({ ...state, saveCompanyError: action.payload }))
    .addCase(clearSaveCompanyStatusAction, state => ({
      ...state,
      saveCompanyError: false,
      saveCompanySuccess: false,
      loading: false,
      companyId: undefined,
    }))
    .addCase(setCompanyIdAction, (state, action) => ({ ...state, companyId: action.payload }))
    .addCase(clearCompanyAction, state => ({
      ...state,
      company: null,
      companyError: false,
      updateCompanySuccess: false,
      updateCompanyError: false,
      deleteCompanySuccess: false,
      deleteCompanyError: false,
      saveCompanySuccess: false,
      saveCompanyError: false,
      companyId: undefined,
      externalReferences: null,
    }))
    .addCase(setParentCompaniesAction, (state, action) => ({ ...state, parentCompanies: action.payload }))
    .addCase(setChildCompaniesAction, (state, action) => ({ ...state, childCompanies: action.payload }))
    .addCase(setChildCompaniesErrorAction, (state, action) => ({
      ...state,
      childCompaniesError: action.payload,
    }))
    .addCase(setIsParentCompanyAction, (state, action) => ({ ...state, isParentCompany: action.payload }))
    .addCase(clearIsParentCompanyAction, state => ({
      ...state,
      isParentCompany: false,
    }))
    .addCase(setExternalReferenceAction, (state, action) => ({
      ...state,
      externalReferences: action.payload,
    }))
    .addCase(setCompaniesAsDropdownItemsAction, (state, action) => ({
      ...state,
      companiesDropdownItems: action.payload,
    }))
    .addCase(setAllUserCompaniesAsDropdownItemsAction, (state, action) => ({
      ...state,
      userCompaniesDropdownItems: action.payload,
    }))
    .addCase(setCompaniesAsDropdownItemsErrorAction, (state, action) => ({
      ...state,
      companiesDropdownItemsError: action.payload,
    }))
    .addCase(companyErrorMessageAction, (state, action) => ({ ...state, companyErrorMessage: action.payload }))
    .addCase(setCompanyRequestsLoadingAction, (state, action) => ({ ...state, loading: action.payload }));
});

export default companyReducer;
