/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Backdrop, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import UPButton from 'components/button';

import UPMultiselectDropdown from 'components/app-container/multiselect-dropdown';
import UPDatepicker from 'components/datepicker';
import DeleteModal from 'components/delete-modal';
import { UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPInput from 'components/input';
import { DayPickerError } from 'modules/contracts/styles';
import { isDateValid } from 'modules/documents/helpers';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { addBlogAction, editBlogAction } from 'store/actions/blog-actions';
import theme from 'theme';
import {
  BLOGS__ADD_NEW_BLOG,
  BLOGS__DESCRIPTION,
  BLOGS__EDIT_BLOG_TITLE,
  BLOGS__TARGET_GROUP,
  BLOGS__TARGET_GROUP_ERROR,
  BLOGS__TITLE,
  BLOGS__URL,
  CONTRACTS__ARE_YOU_SURE,
  DOCUMENTS__DATE,
  DOCUMENTS__DOCUMENT_USER,
  GENERAL__ALL,
  GENERAL__CANCEL_BUTTON,
  GENERAL__CREATE,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  MENU__PARITAIR_COMITE,
} from 'translations/constants';
import { getInputErrorText } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { RowContainer, SectionTitle } from '../employees/add-edit-employee-modal/styles';
import { generateRightFormatForDate, isValidUrl } from './helpers';
import {
  BlogTextareaStyled,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from './styles';
import { AddNewBlogModalProps } from './types';

const AddNewBlogModal = (props: AddNewBlogModalProps): JSX.Element => {
  const { onClose, workerclassList, userDropdownItems, PCDropdownItems, blogToEdit } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [workerclassSelection, setWorkerclassSelection] = useState<UPDropdownItem[]>([]);
  const [PCSelection, setPCSelection] = useState<UPDropdownItem[]>([]);
  const [targetGroupError, setTargetGroupError] = useState<boolean>(false);
  const [invalidDateError, setInvalidDateError] = useState<boolean>(false);
  const [invalidURLError, setInvalidURLError] = useState<boolean>(false);

  const generateEditValues = useCallback(
    (list: number[], items: UPDropdownItem[]) => {
      const auxValues = items.map((l: UPDropdownItem) => {
        const found = list === null ? undefined : list.find((value: number) => value === l.value);
        if (found !== undefined) return l;
        return null;
      });
      const filteredValues = auxValues.filter((value: any) => value !== null);
      return filteredValues;
    },

    [],
  );

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setError,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<any>(
    blogToEdit
      ? {
          defaultValues: {
            blogDate: new Date(generateRightFormatForDate(blogToEdit.date)),
            blogTitle: blogToEdit.title,
            blogDescription: blogToEdit.description,
            blogURL: blogToEdit.url,
            blogUser: blogToEdit.user,
            workerclass: blogToEdit.workerClass
              ? generateEditValues(blogToEdit.workerClass, workerclassList)
              : undefined,
            pc: blogToEdit.pc ? generateEditValues(blogToEdit.pc, PCDropdownItems) : undefined,
          },
        }
      : {
          defaultValues: {
            blogDate: null,
            blogTitle: '',
            blogDescription: '',
            blogURL: '',
            blogUser: 3,
            workerclass: [
              {
                value: t(GENERAL__ALL),
                label: t(GENERAL__ALL),
              },
            ],
            pc: [
              {
                value: 0,
                label: t(GENERAL__ALL),
              },
            ],
          },
        },
  );

  const watchDate = watch('blogDate');
  const watchURL = watch('blogURL');

  useEffect(() => {
    if (blogToEdit) {
      if (blogToEdit.pc) setPCSelection(generateEditValues(blogToEdit.pc, PCDropdownItems));
      if (blogToEdit.workerClass) setWorkerclassSelection(generateEditValues(blogToEdit.workerClass, workerclassList));
    }
  }, [PCDropdownItems, blogToEdit, generateEditValues, setValue, workerclassList]);

  useEffect(() => {
    if (!isDateValid(watchDate) && dirtyFields?.blogDate) {
      setInvalidDateError(true);
    } else {
      setInvalidDateError(false);
    }
  }, [clearErrors, dirtyFields, setError, watchDate]);

  useEffect(() => {
    if (!isValidUrl(watchURL) && dirtyFields?.blogURL) {
      setInvalidURLError(true);
    } else {
      setInvalidURLError(false);
    }
  }, [clearErrors, dirtyFields, setError, watchURL]);

  const generateObjectWithIDs = (selection: UPDropdownItem[], formValue: UPDropdownItem[]) => {
    if (selection?.length === 0 && formValue === undefined) return null;
    if (selection === null) return null;
    if (selection.length === 0 && formValue?.length === 1) return [formValue[0].value];

    const found = selection.find((data: UPDropdownItem) => data.label === t(GENERAL__ALL));
    if (found) return [found.value];

    const auxValues = selection.map((data: UPDropdownItem) => {
      return data.value;
    });
    return auxValues;
  };

  const generateValuesToSubmit = (values: any, workerclass: UPDropdownItem[], PC: UPDropdownItem[]) => {
    const auxValues = {
      date: moment(new Date(values.blogDate)).format('DD/MM/YYYY'),
      id: blogToEdit ? blogToEdit.id : 0,
      title: values.blogTitle,
      description: values.blogDescription,
      url: values.blogURL,
      user: values.blogUser === undefined ? null : values.blogUser,
      workerClass: generateObjectWithIDs(workerclass, values.workerclass),
      pc: generateObjectWithIDs(PC, values.pc),
    };
    return auxValues;
  };

  const onSubmit = () => {
    const values = getValues();
    const valuesToSend = generateValuesToSubmit(values, workerclassSelection, PCSelection);
    if (valuesToSend.user === null && valuesToSend.workerClass === null && valuesToSend.pc === null) {
      setTargetGroupError(true);
    } else {
      setTargetGroupError(false);
      if (!invalidDateError && !invalidURLError && values.blogDate.getFullYear() >= 2000) {
        if (!valuesToSend.id) {
          dispatch(
            addBlogAction({
              blog: valuesToSend,
              type: 'add',
            }),
          );
        } else {
          dispatch(
            editBlogAction({
              blog: valuesToSend,
              type: 'edit',
            }),
          );
        }
        onClose();
      }
    }
  };

  return (
    <Backdrop open sx={{ zIndex: '1' }}>
      <ModalWrapper open>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>{blogToEdit ? t(BLOGS__EDIT_BLOG_TITLE) : t(BLOGS__ADD_NEW_BLOG)}</ModalTitle>
            <ModalCloseIcon onClick={() => setOpenConfirmModal(true)}>
              <CloseIcon fill={theme.palette.secondary.contrastText} />
            </ModalCloseIcon>
          </ModalHeader>
          <RowContainer
            sx={{
              display: 'flex',
              padding: '10px 32px',
              marginTop: '0px',
            }}
          >
            <Box width="30%">
              <Controller
                name="blogDate"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDatepicker
                    value={field.value}
                    onChange={field.onChange}
                    label={t(DOCUMENTS__DATE)}
                    required
                    error={!!errors.blogDate || invalidDateError}
                    helperText={
                      invalidDateError
                        ? getInputErrorText(errors, 'blogDate', undefined, 50, invalidDateError)
                        : getInputErrorText(errors, 'blogDate', undefined, 50)
                    }
                    className="datepicker--white-background"
                  />
                )}
              />
            </Box>
            <Box sx={{ marginLeft: '16px' }} width="70%">
              <Controller
                name="blogTitle"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t(BLOGS__TITLE)}
                    placeholder={t(BLOGS__TITLE)}
                    required
                    error={!!errors.blogTitle}
                    helperText={getInputErrorText(errors, 'blogTitle', undefined, 50)}
                    inputClassName="white-background"
                  />
                )}
              />
            </Box>
          </RowContainer>
          <RowContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 32px',
              marginTop: '0px',
              maxHeight: '300px',
              overflowY: 'auto',
            }}
          >
            <InputLabel sx={{ color: ' #9EA2A2', fontSize: '12px', fontFamily: 'Montserrat,sans-serif' }}>
              {`${t(BLOGS__DESCRIPTION)} *`}
            </InputLabel>
            <Controller
              name="blogDescription"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <BlogTextareaStyled
                  value={field.value}
                  onChange={field.onChange}
                  aria-label={t(BLOGS__DESCRIPTION)}
                  minRows={5}
                  required
                  style={{ borderColor: errors.blogDescription ? 'red' : 'rgba(0, 0 , 0, 0.2)' }}
                />
              )}
            />
            {errors.blogDescription && (
              <DayPickerError>{getInputErrorText(errors, 'blogDescription', undefined, 50)}</DayPickerError>
            )}
          </RowContainer>
          <RowContainer
            sx={{
              display: 'flex',
              padding: '10px 32px',
              marginTop: '0px',
            }}
          >
            <Box width={4 / 4}>
              <Controller
                name="blogURL"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPInput
                    type="url"
                    value={field.value}
                    onChange={field.onChange}
                    label={t(BLOGS__URL)}
                    placeholder={t(BLOGS__URL)}
                    required
                    error={!!errors.blogURL || invalidURLError}
                    helperText={
                      invalidURLError
                        ? getInputErrorText(errors, 'blogURL', undefined, 50, invalidURLError)
                        : getInputErrorText(errors, 'blogURL', undefined, 50)
                    }
                    inputClassName="white-background"
                  />
                )}
              />
            </Box>
          </RowContainer>
          <SectionTitle
            sx={{
              padding: '10px 32px',
              marginTop: '0px',
            }}
          >
            {t(BLOGS__TARGET_GROUP)}
          </SectionTitle>
          <RowContainer
            sx={{
              display: 'flex',
              padding: '10px 32px',
              marginTop: '0px',
              width: '100%',
            }}
          >
            <Box sx={{ marginRight: '16px' }} width={2 / 4}>
              <Controller
                name="pc"
                control={control}
                render={() => (
                  <UPMultiselectDropdown
                    handleChange={setPCSelection}
                    items={PCDropdownItems}
                    label={t(MENU__PARITAIR_COMITE)}
                    defaultValue={
                      !blogToEdit
                        ? [PCDropdownItems[0]]
                        : blogToEdit.pc
                        ? generateEditValues(blogToEdit.pc, PCDropdownItems)
                        : undefined
                    }
                  />
                )}
              />
            </Box>
            <Box width={2 / 4}>
              <Controller
                name="workerclass"
                control={control}
                render={({ field }) => (
                  <UPMultiselectDropdown
                    handleChange={value => {
                      setWorkerclassSelection(value);
                      field.onChange();
                    }}
                    defaultValue={
                      !blogToEdit
                        ? [workerclassList[0]]
                        : blogToEdit.workerClass
                        ? generateEditValues(blogToEdit.workerClass, workerclassList)
                        : undefined
                    }
                    items={workerclassList}
                    label={t(GENERAL__WORKERCLASS)}
                  />
                )}
              />
            </Box>
          </RowContainer>
          <RowContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 32px',
              marginTop: '0px',
            }}
          >
            <Box width={1 / 4}>
              <Controller
                name="blogUser"
                control={control}
                render={({ field }) => (
                  <UPDropdownWithSearch
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t(GENERAL__SELECT)}
                    items={userDropdownItems}
                    label={t(DOCUMENTS__DOCUMENT_USER)}
                    disableClearable={false}
                  />
                )}
              />
            </Box>
            {targetGroupError && <DayPickerError>{t(BLOGS__TARGET_GROUP_ERROR)}</DayPickerError>}
          </RowContainer>

          <ModalFooter>
            <UPButton
              text={blogToEdit ? t(GENERAL__SAVE_BUTTON) : t(GENERAL__CREATE)}
              onClick={handleSubmit(onSubmit)}
            />
            <Box sx={{ marginLeft: '12px' }}>
              <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={() => setOpenConfirmModal(true)} outlined />
            </Box>
          </ModalFooter>
          {openConfirmModal && (
            <DeleteModal
              open={openConfirmModal}
              content={t(CONTRACTS__ARE_YOU_SURE)}
              onClose={() => {
                setOpenConfirmModal(false);
              }}
              onDelete={() => {
                onClose();
              }}
            />
          )}
        </ModalContainer>
      </ModalWrapper>
    </Backdrop>
  );
};

export default AddNewBlogModal;
