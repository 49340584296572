import { LogLevel } from '@azure/msal-browser';
import { getConfig } from 'config';
import logError from 'utils/log-error';

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */

const initMsalConfig = async (): Promise<any> => {
  const msalConfig = {
    auth: {
      clientId: getConfig('AZURE_AD_CLIENT_ID'), // This is the ONLY mandatory field that you need to supply.
      authority: `https://login.microsoftonline.com/${getConfig('AZURE_AD_TENANT_ID')}`, // Defaults to "https://login.microsoftonline.com/"
      redirectUri: getConfig('AZURE_AD_REDIRECT_URI'), // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
      postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              logError(message);
              break;
            case LogLevel.Info:
              console.info(message);
              break;
            case LogLevel.Verbose:
              console.debug(message);
              break;
            case LogLevel.Warning:
              console.warn(message);
              break;
            default:
              console.log(message);
              break;
          }
        },
      },
    },
  };

  return msalConfig;
};

export default initMsalConfig;
