import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { logoutAction } from 'store/actions/auth-actions';

const Logout = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logoutAction(true));
  }, [dispatch]);

  return <div />;
};

export default Logout;
