import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import store from 'store';
import reportWebVitals from 'reportWebVitals';
import { CssBaseline } from '@mui/material';
import Application from './Application';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <Application />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
