import { UPDropdownItem } from 'components/dropdown';
import ModalProps from 'types/common/ModalProps';
import { IViewDocument } from 'types/documents/IDocument';

export interface AddNewDocumentModalProps extends ModalProps {
  onClose: () => void;
  categories: UPDropdownItem[];
  companyId: number;
  category: number | null;
}

export interface Document {
  file?: File;
  id: number;
  name: string;
  date: Date;
  periodStart?: Date;
  periodEnd?: Date;
  type: number;
  employeeId?: number;
  error?: {
    dateError: boolean;
    typeError: boolean;
    invalidDate: boolean;
    periodError?: boolean;
    academicPeriodError?: boolean;
  };
}

export interface DocumentToSend {
  file: File;
  category: number;
  date: string;
  hour: string;
  periodStart?: Date;
  periodEnd?: Date;
  employeeId?: number;
}

export interface AddNewRowProps {
  name: string;
  type: number;
  date: Date;
  periodStart?: Date;
  periodEnd?: Date;
  documentTypes: UPDropdownItem[];
  index: number;
  isEmployee: boolean;
  companyEmployees: UPDropdownItem[];
  onChangeValue: (index: number, documentDate: any | Date, type?: string) => void;
  error: {
    dateError: boolean;
    typeError: boolean;
    periodError?: boolean;
    academicPeriodError?: boolean;
  };
}

export interface DocumentGridViewProps {
  category: number | null;
  startDate: Date | null;
  endDate: Date | null;
}

export interface DocumentsMobileViewProps {
  documents: IViewDocument[];
  isEmployee: boolean;
  onDownloadDocument(id: number): void;
  onDeleteButtonPress(id: number): void;
}

export enum DocumentCategories {
  CONTRACT = '1',
  INVOICE = '2',
  ID = '3',
  WORK_POST_FILE = '4',
  BANKING_MANDATE = '5',
  STUDENT_INSCRIPTION_PROOF = '6',
  VCA = '7',
  MEDICAL_CHECK = '8',
  SAFETY_PASSPORT = '9',
  BANK_CARD = '10',
  DRIVING_LICENCE = '11',
  WORK_PERMIT = '12',
  CONFIRMATION_SHEET = '13',
  PARTNERSHIP_AGREEMENT = '14',
  SHARED_FILES = '15',
}
