import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Box, Stack } from '@mui/material';
import { DateFormat } from 'constants/DateFormat';
import { NavigationDirection } from 'constants/NavigationDirection';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AVAILABILITIES__CHANGES_NOT_SUBMITTED } from 'translations/constants';
import { ReactComponent as AvailabilitySubmittedIcon } from '../../../assets/icons/AvailabilitySubmittedIcon.svg';
import { ReactComponent as AvailabilityWarningIcon } from '../../../assets/icons/AvailabilityWarningIcon.svg';
import { MonthChangeComponentProps } from '../employee-availabilities/types';
import {
  MonthButton,
  MonthButtonChangesText,
  MonthChangeButton,
  MonthChangeContainer,
  MonthDisplayContainer,
} from './styles';

const MonthChangeComponent = (props: MonthChangeComponentProps): JSX.Element => {
  const { currentMonth, onChangeMonth, currentYear, isAvailabilitySubmitted } = props;
  const [t] = useTranslation();

  const currentMonthMoment = moment().month(currentMonth).year(currentYear);
  const previousMonthMoment = currentMonthMoment.clone().subtract(1, 'months');
  const nextMonthMoment = currentMonthMoment.clone().add(1, 'months');

  return (
    <MonthChangeContainer hasMessage={!isAvailabilitySubmitted}>
      <MonthChangeButton>
        <KeyboardArrowLeft onClick={() => onChangeMonth(NavigationDirection.PREVIOUS)} />
      </MonthChangeButton>
      <MonthDisplayContainer>
        <Stack spacing={2} direction="row" width="100%">
          <MonthButton onClick={() => onChangeMonth(NavigationDirection.PREVIOUS)}>
            {previousMonthMoment.format(DateFormat.MONTH_YEAR_FULL)}
          </MonthButton>
          <MonthButton selected>
            <Box sx={{ display: 'flex' }}>
              {isAvailabilitySubmitted ? (
                <AvailabilitySubmittedIcon style={{ marginRight: '4px' }} />
              ) : (
                <AvailabilityWarningIcon style={{ marginRight: '4px' }} />
              )}
              {currentMonthMoment.format(DateFormat.MONTH_YEAR_FULL)}
            </Box>
            {!isAvailabilitySubmitted && (
              <MonthButtonChangesText>{t(AVAILABILITIES__CHANGES_NOT_SUBMITTED)}</MonthButtonChangesText>
            )}
          </MonthButton>
          <MonthButton onClick={() => onChangeMonth(NavigationDirection.NEXT)}>
            {nextMonthMoment.format(DateFormat.MONTH_YEAR_FULL)}
          </MonthButton>
        </Stack>
      </MonthDisplayContainer>
      <MonthChangeButton>
        <KeyboardArrowRight onClick={() => onChangeMonth(NavigationDirection.NEXT)} />
      </MonthChangeButton>
    </MonthChangeContainer>
  );
};

export default MonthChangeComponent;
