import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import Dashboard from 'modules/dashboard';
import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { ssoLoginAction } from 'store/actions/auth-actions';

const AuthRedirect = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  let account;

  if (instance) {
    account = instance.getActiveAccount();
  }

  useEffect(() => {
    if (isAuthenticated && account) {
      console.log('account:', account);
      dispatch(ssoLoginAction({ email: account.username, password: '' }));
    }
  });

  return <Dashboard />;
};

export default AuthRedirect;
