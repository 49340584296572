/* eslint-disable no-nested-ternary */
import { Box, Checkbox, Tooltip } from '@mui/material';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  directlyCardToCopyAction,
  getContractsAction,
  getDimonaNumberAction,
  reopenConfirmationAction,
  selectedContractsAction,
  shouldCopyAction,
} from 'store/actions/contract-actions';
import { CONTRACTS__ACTIONS_DISABLED } from 'store/constants';
import {
  getDimonaNumberErrorSelector,
  getDimonaNumberSuccessSelector,
  getDirectlyCardToCopySelector,
} from 'store/selectors/contracts-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import { ReactComponent as CloseAction } from '../../../../assets/icons/CloseContractAction.svg';
import { ReactComponent as ConfirmIcon } from '../../../../assets/icons/ConfirmContract.svg';
import { ReactComponent as CopyIcon } from '../../../../assets/icons/CopyContract.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/Delete.svg';
import { ReactComponent as DisabledInfoIcon } from '../../../../assets/icons/DisabledInfoIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/EditContract.svg';
import { ReactComponent as FillContractSlotIcon } from '../../../../assets/icons/FillContractSlotIcon.svg';
import { ReactComponent as ReopenServiceIcon } from '../../../../assets/icons/ReopenServiceIcon.svg';
import { ReactComponent as RoundedWarningCircle } from '../../../../assets/icons/RoundedWarningCircle.svg';
import { ReactComponent as RefreshIcon } from '../../../../assets/icons/SmallRefreshIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/WarningIcon.svg';
import {
  CardActions,
  CardOptions,
  ConfirmServiceActionContainer,
  ContractHours,
  DimonaRefreshContainer,
  GridCardStyled,
  IconWithMargin,
} from '../../styles';
import { IContractData, IGridCardProps } from '../../types';
import GridCardEmployee from './GridCardEmployee';
import GridCardVendor from './GridCardVendor';

const GridCard = (props: IGridCardProps): JSX.Element => {
  const {
    data,
    copyEnabled,
    onChangeCardCheck,
    onClickEditCard,
    onClickFillSlot,
    onClickConfirm,
    onDeleteCheckedContracts,
    selectedWeeklyId,
    onlyDailies,
    companyId,
    year,
    weekNumber,
    filters,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const cardToCopyId = useAppSelector(getDirectlyCardToCopySelector);
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;
  const isAdmin = useAppSelector(profileSelector).role === CompanyRole.ADMIN;

  const [auxCardOnHover, setAuxCardOnHover] = useState<number | null>();
  const [dimonaId, setDimonaId] = useState<string>(data.dimonaId);
  const dimonaError = useAppSelector(getDimonaNumberErrorSelector);
  const dimonaSuccess = useAppSelector(getDimonaNumberSuccessSelector);

  const isWeekly = !!data.weeklyId;

  const onClickEdit = (d: IContractData) => {
    let isDaily = true;
    if (d.weeklyId) {
      isDaily = false;
      onClickEditCard(d.id, isDaily, d.weeklyId);
    } else onClickEditCard(d.id, isDaily);
  };

  const isCheckboxDisabled = (): boolean => {
    if (data.employee?.inactive) return true;
    if (data.weeklyId !== selectedWeeklyId && selectedWeeklyId !== '') return true;
    if (onlyDailies && data.weeklyId) return true;
    return false;
  };

  const onClickReopen = (id: number) => {
    dispatch(
      reopenConfirmationAction({
        companyId: toNumber(companyId),
        contractDateId: id,
        year,
        weekNumber,
      }),
    ).then(() => {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: filters,
        }),
      );
    });
  };

  const isCopyDisabled = (): boolean => {
    if (selectedWeeklyId === '' && !onlyDailies) return false;
    if (onlyDailies && data.weeklyId) return true;
    if (selectedWeeklyId !== '' && data.weeklyId !== selectedWeeklyId) return true;

    return false;
  };

  const isDateInFuture = (): boolean => {
    return new Date(`${data.date.toString().split('T')[0]} ${data.startHour}`) > new Date();
  };

  const IsDisabledForModification = (): boolean => {
    return !isAdmin && !isEmployee && isWeekly && !isDateInFuture();
  };

  const fetchDimonaNumber = () => {
    dispatch(getDimonaNumberAction({ companyId: toNumber(companyId), contractId: data.id }));
  };

  useEffect(() => {
    if (dimonaSuccess && data.id === dimonaSuccess.contractId) {
      setDimonaId(dimonaSuccess.dimonaId);
    }

    if (dimonaError && data.id === dimonaError.contractId) {
      setDimonaId('Error...');
    }
  }, [dimonaSuccess, dimonaError, data]);

  return (
    <Box
      sx={{
        width: location.pathname === '/dashboard' ? '100%' : '188px',
        padding: '8px',
        position: 'relative',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
      onMouseEnter={() => {
        if (!copyEnabled) {
          setAuxCardOnHover(data.id);
        }
      }}
      onMouseLeave={() => {
        if (!copyEnabled) {
          setAuxCardOnHover(null);
        }
      }}
    >
      <GridCardStyled
        active={data.editable && ((!isEmployee && !isWeekly) || isAdmin || (!isEmployee && isWeekly))}
        hover={auxCardOnHover === data.id}
        confirmed={data.confirmHours}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ContractHours error={data.errorMessage}>
            {data.startHour} - {data.endHour}{' '}
            <Box sx={{ marginLeft: '4px' }}>{data.errorMessage && <WarningIcon />}</Box>
          </ContractHours>
          {data.checked && !isEmployee && (
            <Checkbox
              checked={data.checked}
              onChange={() => onChangeCardCheck(data.contractId)}
              sx={{ padding: '0px', width: '16px', height: '16px' }}
            />
          )}
        </Box>
        {data.employee ? <GridCardEmployee data={data} isWeekly={isWeekly} /> : <GridCardVendor data={data} />}
        {dimonaId && isAdmin && (
          <Box
            sx={{
              marginLeft: '30px',
              marginRight: '10px',
              position: 'absolute',
              marginTop: '-15px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {data.warningMessage && <RoundedWarningCircle color="warning" fontSize="small" />}
            <span style={{ fontSize: '0.75rem', color: '#9EA2A2' }}>{dimonaId}</span>
          </Box>
        )}
      </GridCardStyled>
      {(auxCardOnHover === data.id || cardToCopyId === data.id) && (
        <CardOptions>
          {!isEmployee && location.pathname !== '/dashboard' && (
            <Checkbox
              checked={data.checked}
              disabled={isCheckboxDisabled()}
              onChange={() => onChangeCardCheck(data.contractId)}
              sx={{ padding: '0px', width: '16px', height: '16px', position: 'absolute', top: '8px', right: '8px' }}
            />
          )}

          {isEmployee ? (
            <CardActions>
              <IconWithMargin>
                <EditIcon onClick={() => onClickEdit(data)} />
              </IconWithMargin>
              {!dimonaId && (
                <DimonaRefreshContainer>
                  <RefreshIcon onClick={() => fetchDimonaNumber()} />
                </DimonaRefreshContainer>
              )}
            </CardActions>
          ) : data.editable && !IsDisabledForModification() ? (
            <CardActions>
              <Box sx={{ cursor: 'pointer' }}>
                <DeleteIcon onClick={() => onDeleteCheckedContracts([data.id])} />
              </Box>
              <IconWithMargin>
                {data.employee ? (
                  <EditIcon onClick={() => onClickEdit(data)} />
                ) : (
                  <FillContractSlotIcon onClick={() => onClickFillSlot(data.id)} />
                )}
              </IconWithMargin>
              {location.pathname !== '/dashboard' && !isCopyDisabled() && (
                <IconWithMargin
                  onClick={() => {
                    if (!copyEnabled) {
                      if (data.weeklyId) {
                        onChangeCardCheck(data.contractId);
                      } else {
                        dispatch(selectedContractsAction({ selectedContracts: [data] }));
                      }
                      dispatch(shouldCopyAction({ copy: true }));
                      dispatch(directlyCardToCopyAction({ id: data.id }));
                    } else {
                      dispatch(selectedContractsAction({ selectedContracts: [] }));
                      dispatch(shouldCopyAction({ copy: false }));
                      dispatch(directlyCardToCopyAction({ id: null }));
                    }
                  }}
                >
                  {copyEnabled ? <CloseAction /> : <CopyIcon />}
                </IconWithMargin>
              )}
              {!dimonaId && (
                <DimonaRefreshContainer>
                  <RefreshIcon onClick={() => fetchDimonaNumber()} />
                </DimonaRefreshContainer>
              )}
            </CardActions>
          ) : data.editable && IsDisabledForModification() ? (
            // part where the contract is disabled for non admins
            <CardActions>
              <Tooltip title={t(CONTRACTS__ACTIONS_DISABLED)}>
                <DisabledInfoIcon />
              </Tooltip>
              {!(location.pathname === '/dashboard') && (
                <IconWithMargin
                  onClick={() => {
                    if (!copyEnabled) {
                      if (data.weeklyId) {
                        onChangeCardCheck(data.contractId);
                      } else {
                        dispatch(selectedContractsAction({ selectedContracts: [data] }));
                      }
                      dispatch(shouldCopyAction({ copy: true }));
                      dispatch(directlyCardToCopyAction({ id: data.id }));
                    } else {
                      dispatch(selectedContractsAction({ selectedContracts: [] }));
                      dispatch(shouldCopyAction({ copy: false }));
                      dispatch(directlyCardToCopyAction({ id: null }));
                    }
                  }}
                >
                  {copyEnabled ? <CloseAction /> : <CopyIcon />}
                </IconWithMargin>
              )}
              {!dimonaId && (
                <DimonaRefreshContainer>
                  <RefreshIcon onClick={() => fetchDimonaNumber()} />
                </DimonaRefreshContainer>
              )}
            </CardActions>
          ) : (
            <CardActions>
              <ConfirmServiceActionContainer hidden={!isAdmin && data.confirmHours}>
                {data.confirmHours ? (
                  <ReopenServiceIcon
                    onClick={() => {
                      onClickReopen(data.id);
                    }}
                  />
                ) : (
                  !data?.vendor && <ConfirmIcon onClick={() => onClickConfirm(data.id)} />
                )}
              </ConfirmServiceActionContainer>
              {location.pathname !== '/dashboard' && !isCopyDisabled() && (
                <IconWithMargin
                  onClick={() => {
                    if (!copyEnabled) {
                      if (data.weeklyId) {
                        onChangeCardCheck(data.contractId);
                      } else {
                        dispatch(selectedContractsAction({ selectedContracts: [data] }));
                      }
                      dispatch(shouldCopyAction({ copy: true }));
                      dispatch(directlyCardToCopyAction({ id: data.id }));
                    } else {
                      dispatch(selectedContractsAction({ selectedContracts: [] }));
                      dispatch(shouldCopyAction({ copy: false }));
                      dispatch(directlyCardToCopyAction({ id: null }));
                    }
                  }}
                >
                  {copyEnabled ? <CloseAction /> : <CopyIcon />}
                </IconWithMargin>
              )}
              {!dimonaId && (
                <DimonaRefreshContainer>
                  <RefreshIcon onClick={() => fetchDimonaNumber()} />
                </DimonaRefreshContainer>
              )}
            </CardActions>
          )}
        </CardOptions>
      )}
    </Box>
  );
};

export default GridCard;
