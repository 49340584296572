import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  checkPasswordRequest,
  forgotPasswordRequest,
  getProfileRequest,
  setPasswordRequest,
  updateLanguageRequest,
  updatePasswordRequest,
} from 'api/requests/profile-requests';
import {
  PROFILE__CHECK_PASSWORD,
  PROFILE__CHECK_PASSWORD_RESULT,
  PROFILE__GET,
  PROFILE__SET,
  PROFILE__SET_PASSWORD,
  PROFILE__SET_PASSWORD_ERROR,
  PROFILE__SET_PASSWORD_SUCCESS,
  PROFILE__UPDATE_LANGUAGE,
  PROFILE__UPDATE_LANGUAGE_ERROR,
  PROFILE__UPDATE_LANGUAGE_SUCCESS,
  PROFILE__UPDATE_PASSWORD,
  PROFILE__UPDATE_PASSWORD_ERROR,
  PROFILE__GENERAL_LOADING,
  PROFILE__UPDATE_PASSWORD_SUCCESS,
  PROFILE__FORGOT_PASSWORD,
  PROFILE__FORGOT_PASSWORD_SUCCESS,
  PROFILE__FORGOT_PASSWORD_ERROR,
} from 'store/constants';
import IProfile from 'types/profile/IProfile';
import IProfileUpdateLanguage from 'types/profile/IProfileUpdateLanguage';
import IProfileUpdatePassword from 'types/profile/IProfileUpdatePassword';
import ISetPasswordRequest from 'types/profile/ISetPasswordRequest';
import IForgotPasswordRequest from 'types/profile/IForgotPasswordRequest';
import { logoutAction } from './auth-actions';

export const setProfileRequestsLoadingAction = createAction<boolean>(PROFILE__GENERAL_LOADING);

export const setProfileAction = createAction<IProfile>(PROFILE__SET);

export const fetchProfileAsync = createAsyncThunk(PROFILE__GET, async (__, thunkApi) => {
  thunkApi.dispatch(setProfileRequestsLoadingAction(true));
  try {
    const profile = await getProfileRequest();
    if (profile) {
      thunkApi.dispatch(setProfileAction(profile));
    }
  } catch (err) {
    // If profile has an error
    // Redirect on login page with errors (maybe?)
    thunkApi.dispatch(logoutAction(true));
  }
  thunkApi.dispatch(setProfileRequestsLoadingAction(false));
});

export const updatePasswordSuccessAction = createAction<boolean>(PROFILE__UPDATE_PASSWORD_SUCCESS);
export const updatePasswordErrorAction = createAction<boolean>(PROFILE__UPDATE_PASSWORD_ERROR);

export const updatePasswordAsync = createAsyncThunk(
  PROFILE__UPDATE_PASSWORD,
  async (data: IProfileUpdatePassword, thunkApi) => {
    thunkApi.dispatch(setProfileRequestsLoadingAction(true));
    thunkApi.dispatch(updatePasswordErrorAction(false));
    thunkApi.dispatch(updatePasswordSuccessAction(false));
    try {
      await updatePasswordRequest(data);
      thunkApi.dispatch(updatePasswordSuccessAction(true));
    } catch (err) {
      thunkApi.dispatch(updatePasswordErrorAction(true));
    }
    thunkApi.dispatch(setProfileRequestsLoadingAction(false));
  },
);

export const setCheckPasswordAction = createAction<boolean>(PROFILE__CHECK_PASSWORD_RESULT);
export const checkPasswordAsync = createAsyncThunk(
  PROFILE__CHECK_PASSWORD,
  async (data: IProfileUpdatePassword, thunkApi) => {
    try {
      const response = await checkPasswordRequest(data);
      thunkApi.dispatch(setCheckPasswordAction(response));
    } catch (err) {
      thunkApi.dispatch(setCheckPasswordAction(null));
    }
  },
);

export const updateLanguageSuccessAction = createAction<boolean>(PROFILE__UPDATE_LANGUAGE_SUCCESS);
export const updateLanguageErrorAction = createAction<boolean>(PROFILE__UPDATE_LANGUAGE_ERROR);
export const updateLanguageAsync = createAsyncThunk(
  PROFILE__UPDATE_LANGUAGE,
  async (data: IProfileUpdateLanguage, thunkApi) => {
    thunkApi.dispatch(setProfileRequestsLoadingAction(true));
    thunkApi.dispatch(updateLanguageErrorAction(false));
    thunkApi.dispatch(updateLanguageSuccessAction(false));
    try {
      await updateLanguageRequest(data);
      thunkApi.dispatch(updateLanguageSuccessAction(true));
      const profile = await getProfileRequest();
      if (profile) {
        thunkApi.dispatch(setProfileAction(profile));
      }
    } catch (err) {
      thunkApi.dispatch(updateLanguageErrorAction(true));
    }
    thunkApi.dispatch(setProfileRequestsLoadingAction(false));
  },
);

export const setPasswordSuccessAction = createAction<boolean>(PROFILE__SET_PASSWORD_SUCCESS);
export const setPasswordErrorAction = createAction<boolean>(PROFILE__SET_PASSWORD_ERROR);

export const setPasswordAsync = createAsyncThunk(PROFILE__SET_PASSWORD, async (data: ISetPasswordRequest, thunkApi) => {
  thunkApi.dispatch(setProfileRequestsLoadingAction(true));
  thunkApi.dispatch(setPasswordErrorAction(false));
  thunkApi.dispatch(setPasswordSuccessAction(false));
  try {
    await setPasswordRequest(data);
    thunkApi.dispatch(setPasswordSuccessAction(true));
  } catch (err) {
    thunkApi.dispatch(setPasswordErrorAction(true));
  }
  thunkApi.dispatch(setProfileRequestsLoadingAction(false));
});

export const forgotPasswordSuccessAction = createAction<boolean>(PROFILE__FORGOT_PASSWORD_SUCCESS);
export const forgotPasswordErrorAction = createAction<boolean>(PROFILE__FORGOT_PASSWORD_ERROR);

export const forgotPasswordRequestAsync = createAsyncThunk(
  PROFILE__FORGOT_PASSWORD,
  async (data: IForgotPasswordRequest, thunkApi) => {
    thunkApi.dispatch(setProfileRequestsLoadingAction(true));
    thunkApi.dispatch(forgotPasswordSuccessAction(false));
    thunkApi.dispatch(forgotPasswordErrorAction(false));
    try {
      await forgotPasswordRequest(data);
      thunkApi.dispatch(forgotPasswordSuccessAction(true));
    } catch (err) {
      thunkApi.dispatch(forgotPasswordErrorAction(true));
    }

    thunkApi.dispatch(setProfileRequestsLoadingAction(false));
  },
);
