import { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import { EditPayrollCostCenter } from 'modules/companies/payroll';
import { UPInputLabel } from 'components/input/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { UPDropdownItem } from 'components/dropdown';
import { COMPANY_PAYROLL__COST_CENTER_ADD_NEW_COST_CENTER, GENERAL__SELECT } from 'translations/constants';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import { EditableCostcenterProps } from './types';
import { EditableCostCenterStyled } from './styles';

/* eslint-disable react/jsx-props-no-spreading */

const filter = createFilterOptions<UPDropdownItem>({
  matchFrom: 'any',
  stringify: (option: UPDropdownItem) => option.label,
});

const EditableCostcenter = (props: EditableCostcenterProps): JSX.Element => {
  const {
    items,
    selectedValue,
    companyId,
    label,
    required,
    disabled,
    handleChange,
    onClose,
    locationId,
    error,
    helperText,
  } = props;

  const [t] = useTranslation();
  const [open, toggleOpen] = useState<boolean>(false);
  const [customDescription, setCustomDescription] = useState<string>('');

  const selectedItem: UPDropdownItem = useMemo(() => {
    return items.length === 1 ? items[0] : items.find(e => e.value === selectedValue);
  }, [items, selectedValue]);

  const ContainsValue = (val: UPDropdownItem): boolean => {
    for (let i = 0; i < items.length; i++) {
      if (val && items[i].label === val.label && [items[i].value === val.value]) {
        return true;
      }
    }

    return false;
  };

  const handleClose = () => {
    toggleOpen(false);
    onClose();
  };

  const getLabel = (item: UPDropdownItem | any): string => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].value === item?.value || items[i].value === item?.toString()) {
        return items[i].label;
      }
    }
    return '';
  };

  return (
    <Box sx={{ width: '100%' }}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <EditableCostCenterStyled>
        <Autocomplete
          disabled={disabled}
          value={selectedItem}
          onChange={(event, newValue) => {
            const item = newValue as UPDropdownItem;
            if (item && !ContainsValue(item)) {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
              });
            } else {
              handleChange(item?.value);
            }
          }}
          filterOptions={(options, params) => {
            const found = items.find(e => e.label === params.inputValue);

            const filtered = filter(options, params);
            let results = filtered;
            if (params.inputValue !== '' && !found) {
              setCustomDescription(params.inputValue);

              const newElement: UPDropdownItem = {
                label: `${params.inputValue}`,
                value: 0,
              };

              results = [newElement].concat(filtered);
            }

            return results;
          }}
          id="free-solo-dialog-demo"
          options={items}
          getOptionLabel={option => {
            // e.g value selected with enter, right from the input
            if (typeof option === 'string' && Number.isNaN(option)) {
              return option;
            }

            if (typeof option === 'string' && !Number.isNaN(option)) {
              return getLabel(option);
            }

            if (typeof option === 'number') {
              return getLabel(option);
            }

            if ((option as UPDropdownItem).label) {
              return (option as UPDropdownItem).label;
            }

            return (option as UPDropdownItem).label;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(o, option) => (
            <li {...o} key={option.value}>
              {option.label}
            </li>
          )}
          sx={{ width: 300 }}
          freeSolo
          renderInput={params => {
            return (
              <TextField
                {...params}
                placeholder={t(GENERAL__SELECT)}
                error={error}
                InputLabelProps={{ shrink: false }}
              />
            );
          }}
        />
        <EditPayrollCostCenter
          open={open}
          onClose={handleClose}
          title={t(COMPANY_PAYROLL__COST_CENTER_ADD_NEW_COST_CENTER)}
          isEdit={false}
          paramCompanyId={companyId}
          customDescription={customDescription}
          locationId={locationId}
        />
      </EditableCostCenterStyled>
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

export default EditableCostcenter;
