import { RootState } from 'store';
import IPaymentInformation from 'types/company/financial/IPaymentInformation';

export const companyFinancialStateSelector = (state: RootState): IPaymentInformation =>
  state.financial.companyFinancial;
export const companyFinancialErrorSelector = (state: RootState): boolean => state.financial.companyFinancialError;
export const companyFinancialUpdateSuccessfulSelector = (state: RootState): boolean =>
  state.financial.companyFinancialUpdateSuccessful;

export const companyFinancialErrorMessageSelector = (state: RootState): string =>
  state.financial.companyFinancialErrorMessage;

export const companyFinancialLoadingSelector = (state: RootState): boolean => state.financial.loading;
