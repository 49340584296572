import { UPMultipleSelectionDropdown } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPInput from 'components/input';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_INFORMATION_WEEKLY_STATUS,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  COMPANY__COMPANY,
  COMPANY__SEARCH_COMPANY_NAME,
  GENERAL__WORKERCLASS,
  SUPERADMINS,
} from 'translations/constants';
import { FilterItem, FiltersContainer } from '../styles';
import { BaseReportingFiltersProps } from '../types';

const BaseReportingFilters = (props: BaseReportingFiltersProps) => {
  const { filters, dropdownData, handleFilterChange } = props;

  const [t] = useTranslation();

  const {
    companiesDropdownItems,
    paritairComitesDropdownItems,
    superAdminsDropdownItems,
    workerClassesDropdownItems,
    reportStatusDropdownItems,
  } = dropdownData;

  const {
    selectedCompanies,
    selectedParitairComites,
    selectedSuperAdmins,
    selectedWorkerClasses,
    selectedStatus,
    companyName,
  } = filters;

  return (
    <FiltersContainer>
      <FilterItem>
        <UPMultipleSelectionDropdown
          items={companiesDropdownItems}
          value={selectedCompanies}
          dropdownLabel={t(COMPANY__COMPANY)}
          handleChange={event => handleFilterChange('selectedCompanies', event.target.value)}
        />
      </FilterItem>
      <FilterItem>
        <UPMultipleSelectionDropdown
          items={paritairComitesDropdownItems}
          value={selectedParitairComites}
          dropdownLabel={t(COMPANY_PAYROLL__PARITAIR_COMITE)}
          handleChange={event => handleFilterChange('selectedParitairComites', event.target.value)}
        />
      </FilterItem>
      <FilterItem>
        <UPMultipleSelectionDropdown
          items={superAdminsDropdownItems}
          value={selectedSuperAdmins}
          dropdownLabel={t(SUPERADMINS)}
          handleChange={event => handleFilterChange('selectedSuperAdmins', event.target.value)}
        />
      </FilterItem>
      <FilterItem>
        <UPMultipleSelectionDropdown
          items={workerClassesDropdownItems}
          value={selectedWorkerClasses}
          dropdownLabel={t(GENERAL__WORKERCLASS)}
          handleChange={event => handleFilterChange('selectedWorkerClasses', event.target.value)}
        />
      </FilterItem>
      <FilterItem>
        <UPDropdownWithSearch
          items={reportStatusDropdownItems}
          value={selectedStatus}
          label={t(COMPANY_INFORMATION_WEEKLY_STATUS)}
          onChange={selectedItem => handleFilterChange('selectedStatus', selectedItem)}
        />
      </FilterItem>
      <FilterItem>
        <UPInput
          type="text"
          onChange={event => handleFilterChange('companyName', event.target.value)}
          label={t(COMPANY__SEARCH_COMPANY_NAME)}
          placeholder={t(COMPANY__SEARCH_COMPANY_NAME)}
          inputClassName="white-background"
          value={companyName}
        />
      </FilterItem>
    </FiltersContainer>
  );
};

export default BaseReportingFilters;
