import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getFunctionDescriptionsRequest,
  getWageExemptionsRequest,
  updateCompanyWageExemptionsRequest,
} from 'api/requests/company-requests';
import {
  COMPANY_WAGE_EXEMPTIONS__CLEAR,
  COMPANY_WAGE_EXEMPTIONS__GENERAL_LOADING,
  COMPANY_WAGE_EXEMPTIONS__GET,
  COMPANY_WAGE_EXEMPTIONS__GET_FUNCTIONS_DESCRIPTION,
  COMPANY_WAGE_EXEMPTIONS__GET_FUNCTIONS_DESCRIPTION_ERROR,
  COMPANY_WAGE_EXEMPTIONS__SET,
  COMPANY_WAGE_EXEMPTIONS__SET_ERROR,
  COMPANY_WAGE_EXEMPTIONS__SET_FUNCTIONS_DESCRIPTION,
  COMPANY_WAGE_EXEMPTIONS__UPDATE,
  COMPANY_WAGE_EXEMPTIONS__UPDATE_ERROR,
  COMPANY_WAGE_EXEMPTIONS__UPDATE_SUCCESS,
} from 'store/constants';
import IFunctionsDescription from 'types/company/payroll/IFunctionsDescription';
import IPayrollWageExemptions from 'types/company/payroll/IPayrollWageExemptions';

export const setWageExemptionsRequestsLoadingAction = createAction<boolean>(COMPANY_WAGE_EXEMPTIONS__GENERAL_LOADING);

export const setWageExemptionsAction = createAction<IPayrollWageExemptions>(COMPANY_WAGE_EXEMPTIONS__SET);
export const getWageExemptionsErrorAction = createAction<boolean>(COMPANY_WAGE_EXEMPTIONS__SET_ERROR);

export const getWageExemptionsAction = createAsyncThunk(
  COMPANY_WAGE_EXEMPTIONS__GET,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(getWageExemptionsErrorAction(false));
    thunkApi.dispatch(setWageExemptionsRequestsLoadingAction(true));
    try {
      const response = await getWageExemptionsRequest(companyId);
      thunkApi.dispatch(setWageExemptionsAction(response));
    } catch (error) {
      thunkApi.dispatch(getWageExemptionsErrorAction(true));
    }
    thunkApi.dispatch(setWageExemptionsRequestsLoadingAction(false));
  },
);

export const updateWageExemptionsErrorAction = createAction<boolean>(COMPANY_WAGE_EXEMPTIONS__UPDATE_ERROR);
export const updateWageExemptionsSuccessAction = createAction<boolean>(COMPANY_WAGE_EXEMPTIONS__UPDATE_SUCCESS);

export const updateWageExemptionsAction = createAsyncThunk(
  COMPANY_WAGE_EXEMPTIONS__UPDATE,
  async (wageExemptions: IPayrollWageExemptions, thunkApi) => {
    thunkApi.dispatch(setWageExemptionsRequestsLoadingAction(true));
    thunkApi.dispatch(updateWageExemptionsErrorAction(false));
    thunkApi.dispatch(updateWageExemptionsSuccessAction(false));
    try {
      await updateCompanyWageExemptionsRequest(wageExemptions);
      thunkApi.dispatch(updateWageExemptionsSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(updateWageExemptionsErrorAction(true));
    }
    thunkApi.dispatch(setWageExemptionsRequestsLoadingAction(false));
  },
);

export const setFunctionDescriptions = createAction<IFunctionsDescription[]>(
  COMPANY_WAGE_EXEMPTIONS__SET_FUNCTIONS_DESCRIPTION,
);
export const getFunctionDescriptionsError = createAction<boolean>(
  COMPANY_WAGE_EXEMPTIONS__GET_FUNCTIONS_DESCRIPTION_ERROR,
);

export const getFunctionDescriptions = createAsyncThunk(
  COMPANY_WAGE_EXEMPTIONS__GET_FUNCTIONS_DESCRIPTION,
  async (id: number, thunkApi) => {
    thunkApi.dispatch(setWageExemptionsRequestsLoadingAction(true));
    thunkApi.dispatch(getFunctionDescriptionsError(false));
    try {
      const response = await getFunctionDescriptionsRequest(id);
      thunkApi.dispatch(setFunctionDescriptions(response));
    } catch (error) {
      thunkApi.dispatch(getFunctionDescriptionsError(true));
    }
    thunkApi.dispatch(setWageExemptionsRequestsLoadingAction(false));
  },
);

export const clearCompanyWageExemptionsAction = createAction<void>(COMPANY_WAGE_EXEMPTIONS__CLEAR);
