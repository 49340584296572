import { useTranslation } from 'react-i18next';
import {
  NO_SHOW__ALLOWED_ABSENCE,
  NO_SHOW__NOT_ALLOWED_ABSENCE,
  NO_SHOW__SICKNESS,
  NO_SHOW__OTHER,
} from 'translations/constants';

const useReasonForNoShowTranslation = (employeeReasonForEmployment: number) => {
  const [t] = useTranslation();
  const getTranslation = () => {
    switch (employeeReasonForEmployment) {
      case 1:
        return t(NO_SHOW__ALLOWED_ABSENCE);
      case 2:
        return t(NO_SHOW__NOT_ALLOWED_ABSENCE);
      case 3:
        return t(NO_SHOW__SICKNESS);
      case 4:
        return t(NO_SHOW__OTHER);
      default:
        return '';
    }
  };
  return getTranslation();
};
export default useReasonForNoShowTranslation;
