import { Box, Grid, Table, Typography, styled } from '@mui/material';

export const ServiceCodesContainer = styled(Grid)(({ theme }) => ({
  borderRadius: '10px',
  position: 'relative',
  display: 'flex',
  gap: '10px',
  border: `1px solid ${theme.palette.grey[300]}`,
  padding: '10px',
}));

export const ContainerTitle = styled(Box)(() => ({
  padding: '0 5px',
  margin: '-20px 0 0 10px',
  background: 'white',
  position: 'absolute',
}));

export const WorkhourSettingsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '5px',
  gap: '25px',
  width: '95%',
  justifyContent: 'space-between',
}));

export const OvertimeLimitsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '5px 20px',
  gap: '20px',
  width: '90%',
  margin: 'auto',
  justifyContent: 'space-between',
}));

export const NightShiftIntervalContainer = styled(Box)(() => ({
  width: '90%',
  display: 'flex',
  alignItems: 'center',
  padding: '5px 20px',
  margin: 'auto',
  gap: '20px',
  justifyContent: 'space-between',
}));

export const IntervalHourBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '30%',
}));

export const IntervalText = styled(Typography)(() => ({
  width: '50%',
  textAlign: 'center',
}));

export const ServiceCodesButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  padding: '0 0 3px 10px',
}));

export const BorderlessTableStyled = styled(Table)(() => ({
  '& .MuiTableCell-root': {
    borderBottom: 'none',
    padding: '5px 10px',
  },
}));
