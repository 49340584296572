import { createReducer } from '@reduxjs/toolkit';
import {
  clearReportsAction,
  getReportsPayloadAction,
  setReportsRequestsLoadingAction,
} from 'store/actions/reporting-actions';
import ReportingResult from 'types/reporting/ReportingResult';

const initialState: ReportingResult = {
  headerColumns: [],
  items: [],
  loading: false,
};

const reportingReducer = createReducer(initialState, builder => {
  return builder
    .addCase(getReportsPayloadAction, (state, action) => {
      return {
        ...state,
        loading: Boolean(action.payload),
        headerColumns: action.payload?.headerColumns || [],
        items: action.payload?.items || [],
      };
    })
    .addCase(setReportsRequestsLoadingAction, (state, action) => ({ ...state, loading: action.payload }))
    .addCase(clearReportsAction, () => initialState);
});

export default reportingReducer;
