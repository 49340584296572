import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { UPDataGrid } from 'components/data-grid';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CONTRACTS__COST_CENTER,
  GENERAL__NAME,
  REPORTING__FTE_CHECK,
  REPORTING__NOSHOW_HOURS,
  REPORTING__WORKED_HOURS,
} from 'translations/constants';
import FTECheckData from 'types/reporting/FTECheckData';

const FTECheckReport = (props: { items: FTECheckData[]; showCostCenterColumn: boolean }) => {
  const { items, showCostCenterColumn } = props;
  const [t] = useTranslation();

  const [, setRowOnHover] = useState<number>();

  const columnDef: GridColDef[] = [
    {
      field: 'employeeId',
      headerName: '',
      hide: true,
    },
    {
      field: 'employeeName',
      headerName: t(GENERAL__NAME),
      width: 300,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'performedHours',
      headerName: t(REPORTING__WORKED_HOURS),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'noShowHours',
      headerName: t(REPORTING__NOSHOW_HOURS),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'fteCheck',
      headerName: t(REPORTING__FTE_CHECK),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  if (showCostCenterColumn && items[0]?.costCenterId) {
    columnDef.unshift(
      { field: 'costCenterId', headerName: '', hide: true },
      {
        field: 'costCenterName',
        headerName: t(CONTRACTS__COST_CENTER),
        width: 300,
        headerAlign: 'center',
        align: 'center',
      },
    );
  }

  return (
    <Box>
      <UPDataGridContainer sx={{ height: '70vh' }}>
        <UPDataGrid
          rows={items}
          columns={columnDef}
          setRowOnHover={setRowOnHover}
          getRowId={r => `fte_${r.costCenterId}_${r.employeeId}`}
        />
      </UPDataGridContainer>
    </Box>
  );
};

export default FTECheckReport;
