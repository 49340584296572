import { TableCell, TableRow } from '@mui/material';
import UPInput from 'components/input';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { WAGE_COMPONENTS__CODE, WAGE_COMPONENTS__DESCRIPTION } from 'translations/constants';
import { onChangeCode } from './helpers';
import { ServiceCodesTableRowProps } from './types';

const ServiceCodesTableRow = (props: ServiceCodesTableRowProps) => {
  const { outcome, serviceCode, setServiceCode, setShouldUpdate, setValidValues } = props;

  const [t] = useTranslation();

  const onChangeDescription = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setServiceCode(prevState => ({
      ...prevState,
      description: value,
    }));

    setShouldUpdate(true);
  };

  return (
    <TableRow>
      <TableCell>{t(outcome)}</TableCell>
      <TableCell>
        <UPInput
          label=""
          placeholder={t(WAGE_COMPONENTS__CODE)}
          value={serviceCode.code.toString()}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChangeCode(event, setServiceCode, setValidValues, setShouldUpdate);
          }}
        />
      </TableCell>
      <TableCell>
        <UPInput
          label=""
          placeholder={t(WAGE_COMPONENTS__DESCRIPTION)}
          value={serviceCode.description}
          onChange={onChangeDescription}
        />
      </TableCell>
    </TableRow>
  );
};

export default ServiceCodesTableRow;
