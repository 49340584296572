import { Checkbox, Typography } from '@mui/material';
import { CompanyRole } from 'constants/CompanyRole';
import { useCallback } from 'react';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { DayOfWeek, HeaderCell, NumberOfItems } from '../styles';
import { IGridHeaderCellProps } from '../types';

const GridHeaderCell = (props: IGridHeaderCellProps): JSX.Element => {
  const { dayOfWeek, date, numberOfItems, onCheckDay, checked, year, isWeeklySelected } = props;
  const isEmployee = useAppSelector(profileSelector).role === CompanyRole.EMPLOYEE;

  const onClickCheckbox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newChecked = e.target.checked;
      onCheckDay(new Date(date), newChecked);
    },
    [date, onCheckDay],
  );

  return (
    <HeaderCell
      highlight={
        new Date(date).getDate() === new Date(Date.now()).getDate() &&
        new Date(date).getMonth() === new Date(Date.now()).getMonth() &&
        year === new Date(Date.now()).getFullYear()
      }
    >
      <DayOfWeek>
        <Typography>
          <b>{dayOfWeek}</b> {date}
        </Typography>
        {!isEmployee && <Checkbox checked={checked || false} onChange={onClickCheckbox} disabled={isWeeklySelected} />}
      </DayOfWeek>
      <NumberOfItems>{numberOfItems}</NumberOfItems>
    </HeaderCell>
  );
};

export default GridHeaderCell;
