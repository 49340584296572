import { createReducer } from '@reduxjs/toolkit';
import {
  addOneManagersAction,
  removeAllManagersAction,
  removeManyManagersAction,
  setManagersAction,
  setManagersErrorAction,
  setManagersRequestsLoadingAction,
  updateOneManagersAction,
} from 'store/actions/company-managers-actions';
import managersAdapter from 'store/adapters/company-managers-adapter';
import IManagersState from 'types/company/managers/IManagersState';

const initialState: IManagersState = {
  managers: [],
  loading: false,
  managersError: false,
};

const managersReducer = createReducer(managersAdapter.getInitialState<IManagersState>(initialState), builder => {
  return builder
    .addCase(setManagersAction, managersAdapter.setAll)
    .addCase(updateOneManagersAction, managersAdapter.updateOne)
    .addCase(addOneManagersAction, managersAdapter.addOne)
    .addCase(removeManyManagersAction, managersAdapter.removeMany)
    .addCase(removeAllManagersAction, managersAdapter.removeAll)
    .addCase(setManagersRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setManagersErrorAction, (state, action) => ({
      ...state,
      managersError: action.payload,
    }));
});

export default managersReducer;
