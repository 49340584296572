import { createReducer } from '@reduxjs/toolkit';
import {
  getDocumentsErrorAction,
  getDocumentsPayloadAction,
  getEmployeeDocumentsPayloadAction,
  setDocumentsRequestsLoadingAction,
  saveDocumentsErrorAction,
} from 'store/actions/documents-action';
import IDocumentsState from 'types/documents/IDocumentsState';

const initialState: IDocumentsState = {
  allDocuments: [],
  employeeDocuments: [],
  getDocumentsError: false,
  setDocumentsError: false,
  loading: false,
};

const documentsReducer = createReducer(initialState, builder => {
  return builder
    .addCase(getDocumentsPayloadAction, (state, action) => {
      return { ...state, allDocuments: action.payload };
    })
    .addCase(getEmployeeDocumentsPayloadAction, (state, action) => {
      return { ...state, employeeDocuments: action.payload };
    })
    .addCase(setDocumentsRequestsLoadingAction, (state, action) => ({ ...state, loading: action.payload }))
    .addCase(getDocumentsErrorAction, (state, action) => ({ ...state, getDocumentsError: action.payload }))
    .addCase(saveDocumentsErrorAction, (state, action) => ({ ...state, setDocumentsError: action.payload }));
});

export default documentsReducer;
