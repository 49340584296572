import Typography from '@mui/material/Typography/Typography';
import { ICostEstimation, IWorkerclassCost } from 'modules/contracts/types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import {
  COST_ESTIMATION__COST_FOR_ALL,
  COST_ESTIMATION__STUDENTS,
  COST_ESTIMATION__FLEXI,
  COST_ESTIMATION__EXTRA,
  COST_ESTIMATION__VIEW_INVOICES,
  HEADER__WEEK,
  COST_ESTIMATION__TOOLTIP,
  COST_ESTIMATION__ARB,
  COST_ESTIMATION__BED,
} from 'translations/constants';
import { Tooltip as MUITooltip } from '@mui/material';
import {
  BigCostTooltipContainer,
  DashboardTileStyled,
  DashboardTileTitle,
  InvoicesButtonContainer,
  SmallCostTooltipContainer,
  StyledCostTooltip,
  ViewInvoicesButton,
} from './styles';
import { ReactComponent as InfoIcon } from '../../assets/icons/InfoIcon.svg';
import { CostEstimationDisplay, DashboardCostEstimationTileProps } from './types';

const DashboardCostEstimationTile = (props: DashboardCostEstimationTileProps): JSX.Element => {
  const { costEstimationData } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [costData, setCostData] = useState<CostEstimationDisplay[]>([]);
  const [minChartValue, setMinChartValue] = useState<number>(0);
  const [maxChartValue, setMaxChartValue] = useState<number>(0);
  const minChartValCoefficient = 0.85;
  const maxChartValCoefficient = 1.15;

  useEffect(() => {
    if (costEstimationData) {
      let minValue = Number.MAX_SAFE_INTEGER;
      let maxValue = Number.MIN_SAFE_INTEGER;
      const costDisplay: CostEstimationDisplay[] = costEstimationData
        .map((cost: ICostEstimation) => {
          const label = `${t(HEADER__WEEK)} ${cost.weekNumber}`;
          let totalCostForWeek = 0;
          let studCost = 0;
          let flexCost = 0;
          let extrCost = 0;
          let arbCost = 0;
          let bedCost = 0;
          // let othrCost = 0;
          cost.costs.forEach((workerCost: IWorkerclassCost) => {
            totalCostForWeek += workerCost.cost;
            switch (workerCost.workerClass) {
              case 'STUD':
                studCost += workerCost.cost;
                break;
              case 'FLEX':
                flexCost += workerCost.cost;
                break;
              case 'EXT':
                extrCost += workerCost.cost;
                break;
              case 'BED':
                bedCost += workerCost.cost;
                break;
              case 'ARB':
                arbCost += workerCost.cost;
                break;
              default:
                break;
            }
          });
          minValue = Math.min(minValue, totalCostForWeek);
          maxValue = Math.max(maxValue, totalCostForWeek);
          return {
            week: label,
            cost: Number(totalCostForWeek.toFixed(2)),
            students: Number(studCost.toFixed(2)),
            flexi: Number(flexCost.toFixed(2)),
            extra: Number(extrCost.toFixed(2)),
            arb: Number(arbCost.toFixed(2)),
            bed: Number(bedCost.toFixed(2)),
          };
        })
        .reverse();
      setMinChartValue(minValue * minChartValCoefficient);
      setMaxChartValue(maxValue * maxChartValCoefficient);
      setCostData(costDisplay);
    }
  }, [costEstimationData, t]);

  const CustomizedTooltip = useCallback(
    ({ payload, label }) => {
      const showCost = payload?.length && payload[0];
      const cost = showCost ? payload[0]?.payload?.cost?.toLocaleString() : '';
      const students = showCost ? payload[0]?.payload?.students?.toLocaleString() : '';
      const flexi = showCost ? payload[0]?.payload?.flexi?.toLocaleString() : '';
      const extra = showCost ? payload[0]?.payload?.extra?.toLocaleString() : '';
      const arb = showCost ? payload[0]?.payload?.arb?.toLocaleString() : '';
      const bed = showCost ? payload[0]?.payload?.bed?.toLocaleString() : '';

      return (
        <StyledCostTooltip>
          <BigCostTooltipContainer>
            <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
            <Typography sx={{ fontSize: '22px', color: '#008c15', fontWeight: 'bold' }}>{cost}</Typography>
          </BigCostTooltipContainer>
          <SmallCostTooltipContainer>
            <Typography>{t(COST_ESTIMATION__STUDENTS)}</Typography>
            <Typography>{students}</Typography>
          </SmallCostTooltipContainer>
          <SmallCostTooltipContainer>
            <Typography>{t(COST_ESTIMATION__FLEXI)}</Typography>
            <Typography>{flexi}</Typography>
          </SmallCostTooltipContainer>
          <SmallCostTooltipContainer>
            <Typography>{t(COST_ESTIMATION__EXTRA)}</Typography>
            <Typography>{extra}</Typography>
          </SmallCostTooltipContainer>
          <SmallCostTooltipContainer>
            <Typography>{t(COST_ESTIMATION__ARB)}</Typography>
            <Typography>{arb}</Typography>
          </SmallCostTooltipContainer>
          <SmallCostTooltipContainer>
            <Typography>{t(COST_ESTIMATION__BED)}</Typography>
            <Typography>{bed}</Typography>
          </SmallCostTooltipContainer>
        </StyledCostTooltip>
      );
    },
    [t],
  );

  return (
    <DashboardTileStyled sx={{ border: 'white 3px solid' }}>
      <DashboardTileTitle>
        <Typography fontWeight="bold">{t(COST_ESTIMATION__COST_FOR_ALL)}</Typography>
        <MUITooltip title={t(COST_ESTIMATION__TOOLTIP)} placement="left" arrow>
          <InfoIcon />
        </MUITooltip>
      </DashboardTileTitle>
      <ResponsiveContainer width="100%" height="60%">
        <BarChart width={150} height={40} data={costData}>
          <XAxis dataKey="week" axisLine={false} tickLine={false} style={{ color: 'red' }} />
          <YAxis type="number" domain={[minChartValue, maxChartValue]} hide />
          <Tooltip content={<CustomizedTooltip />} wrapperStyle={{ zIndex: '1000', outline: 'none' }} />
          <Bar dataKey="cost" style={{ cursor: 'pointer' }} unit="€" radius={[5, 5, 0, 0]} isAnimationActive={false}>
            {costData.map((entry, index) => (
              <Cell fill={index === 4 ? '#008c15' : '#a1d199'} />
            ))}
            <LabelList
              dataKey="cost"
              position="top"
              formatter={value => `€ ${value.toLocaleString()}`}
              style={{
                fontSize: '18px',
                fontFamily: 'Montserrat,sans-serif',
                fill: '#008c15',
                fontWeight: 'bold',
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <InvoicesButtonContainer>
        <ViewInvoicesButton
          text={t(COST_ESTIMATION__VIEW_INVOICES)}
          onClick={() => {
            navigate('/docs', {
              state: {
                category: 2,
              },
            });
          }}
        />
      </InvoicesButtonContainer>
    </DashboardTileStyled>
  );
};

export default DashboardCostEstimationTile;
