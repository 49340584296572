import { Box, MenuItem } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { UPInputLabel } from 'components/input/styles';
import { DropdownPlaceholder, MenuProps, UPDropdownHelperText, UPSelectStyled } from './styles';
import { UPMultipleSelectionDropdownProps } from './types';

const UPMultipleSelectionDropdown = (props: UPMultipleSelectionDropdownProps): JSX.Element => {
  const { items, value, handleChange, placeholder, className, dropdownLabel, required, error, helperText, disabled } =
    props;

  return (
    <Box className={className} width="100%" minWidth="100%">
      <UPInputLabel>
        {dropdownLabel} {required && '*'}
      </UPInputLabel>
      <UPSelectStyled
        autoWidth
        displayEmpty
        value={value}
        onChange={handleChange}
        input={<OutlinedInput />}
        MenuProps={MenuProps}
        multiple
        fullWidth
        required={required}
        error={error}
        renderValue={value?.length === 0 ? () => <DropdownPlaceholder>{placeholder}</DropdownPlaceholder> : undefined}
        disabled={disabled}
      >
        {items.length > 0 ? (
          items.map((item, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={`${item?.value}${i}`} value={item?.value}>
              {item?.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            No items
          </MenuItem>
        )}
      </UPSelectStyled>
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

UPMultipleSelectionDropdown.defaultProps = {
  placeholder: 'Select',
  className: '',
  required: false,
  error: false,
  helperText: '',
  disabled: false,
};
export default UPMultipleSelectionDropdown;
