import { createReducer } from '@reduxjs/toolkit';
import {
  saveContractSuccessAction,
  saveContractErrorAction,
  validateContractSuccessAction,
  validateContractErrorMessageAction,
  validateContractErrorMessageRowAction,
  clearContractAction,
  getWageErrorAction,
  setWageAction,
  getContractForDateError,
  setContract,
  confirmContractError,
  validateContractsSuccessAction,
  validateContractErrorMessageExtraInfoAction,
} from 'store/actions/contract-actions';
import IContractState from 'types/contract/IContractState';

const initialState: IContractState = {
  saveContractSuccess: false,
  saveContractError: false,
  validateContractSuccess: false,
  validateContractsSuccess: false,
  validateContractErrorMessage: '',
  validateContractExtraMessage: '',
  validateContractErrorMessageRow: null,
  wage: '',
  wageError: false,
  contract: null,
  getContractForDateError: false,
  confirmContractError: false,
};

const contractReducer = createReducer(initialState, builder => {
  return builder
    .addCase(saveContractSuccessAction, (state, action) => ({ ...state, saveContractSuccess: action.payload }))
    .addCase(saveContractErrorAction, (state, action) => ({ ...state, saveContractError: action.payload }))
    .addCase(validateContractSuccessAction, (state, action) => ({ ...state, validateContractSuccess: action.payload }))
    .addCase(validateContractsSuccessAction, (state, action) => ({
      ...state,
      validateContractsSuccess: action.payload,
    }))
    .addCase(validateContractErrorMessageAction, (state, action) => ({
      ...state,
      validateContractErrorMessage: action.payload,
    }))
    .addCase(validateContractErrorMessageExtraInfoAction, (state, action) => ({
      ...state,
      validateContractExtraMessage: action.payload,
    }))
    .addCase(validateContractErrorMessageRowAction, (state, action) => ({
      ...state,
      validateContractErrorMessageRow: action.payload,
    }))
    .addCase(clearContractAction, state => ({
      ...state,
      saveContractSuccess: false,
      saveContractError: false,
      contract: null,
      validateContractSuccess: false,
      validateContractErrorMessage: '',
      validateContractExtraMessage: '',
      wage: '',
      wageError: false,
    }))
    .addCase(getWageErrorAction, (state, action) => ({ ...state, wageError: action.payload }))
    .addCase(setWageAction, (state, action) => ({
      ...state,
      wage: action.payload,
    }))
    .addCase(getContractForDateError, (state, action) => ({ ...state, getContractForDateError: action.payload }))
    .addCase(setContract, (state, action) => {
      return {
        ...state,
        contract: action.payload,
      };
    })
    .addCase(confirmContractError, (state, action) => ({
      ...state,
      confirmContractError: action.payload,
    }));
});

export default contractReducer;
