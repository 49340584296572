import { DateFormat } from 'constants/DateFormat';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AvailabilitiesContext } from '../Availabilities';
import AvailabilitiesCalendarHeader from './header';
import AvailabilitiesCalendarRow from './row';
import { AvailabilitiesCalendarBody, AvailabilitiesCalendarContainer } from './styles';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

const AvailabilitiesCalendar = (): JSX.Element => {
  const { currentMonth, currentYear } = useContext(AvailabilitiesContext);

  const [sixRowsCalendar, setSixRowsCalendar] = useState<boolean>(false);

  const currentMonthMoment = moment().month(currentMonth).year(currentYear);
  const firstDayOfMonth = currentMonthMoment.clone().startOf('month').startOf('week');
  const lastDayOfMonth = currentMonthMoment.clone().endOf('month');

  const mondaysOfTheMonth = useMemo(() => {
    const mondays = [firstDayOfMonth];
    for (let i = 1; i < 5; i++) {
      mondays.push(firstDayOfMonth.clone().add(i, 'weeks'));
    }
    if (mondays[4].clone().add(1, 'weeks').isSameOrBefore(lastDayOfMonth)) {
      mondays.push(firstDayOfMonth.clone().add(5, 'weeks'));
    }
    return mondays;
  }, [firstDayOfMonth, lastDayOfMonth]);

  useEffect(() => {
    if (mondaysOfTheMonth.length === 6) {
      setSixRowsCalendar(true);
      return;
    }
    setSixRowsCalendar(false);
  }, [mondaysOfTheMonth]);

  return (
    <AvailabilitiesCalendarContainer>
      <AvailabilitiesCalendarHeader />
      <AvailabilitiesCalendarBody>
        {mondaysOfTheMonth.map(monday => (
          <AvailabilitiesCalendarRow
            firstDayOfWeek={monday}
            key={monday.format(DateFormat.FULL_DATE)}
            sixRowsCalendar={sixRowsCalendar}
          />
        ))}
      </AvailabilitiesCalendarBody>
    </AvailabilitiesCalendarContainer>
  );
};

export default AvailabilitiesCalendar;
