import { useEffect, useState } from 'react';
import { Backdrop, Box } from '@mui/material';
import theme from 'theme';
import UPButton from 'components/button';
import { UPMultipleSelectionDropdown } from 'components/dropdown';
import { useAppDispatch, useAppSelector } from 'store';
import { useParams } from 'react-router-dom';
import {
  getCostDeclarationSelectItemsAction,
  saveCostDeclarationsForCompanyAction,
} from 'store/actions/cost-declaration-actions';
import { toNumber } from 'lodash';
import { costDeclarationSelectItemsSelector } from 'store/selectors/cost-declaration-selectors';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  ModalCloseIcon,
  ModalContainer,
  ModalHeader,
  ModalTitle,
  WageComponentsModalStyled,
  AddWageComponentButtonContainer,
} from './styles';

const AddWageComponentModal = (props: { open: boolean; onClose: () => void }): JSX.Element => {
  const { open, onClose } = props;

  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const [wageComponentsSelection, setWageComponentsSelection] = useState<string[]>([]);

  const wageComponentsOptions = useAppSelector(costDeclarationSelectItemsSelector);

  useEffect(() => {
    dispatch(getCostDeclarationSelectItemsAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  const onSave = () => {
    dispatch(
      saveCostDeclarationsForCompanyAction({
        costDeclarationIds: wageComponentsSelection.map(item => toNumber(item)),
        companyId: toNumber(companyId),
      }),
    );
    onClose();
  };

  return (
    <Backdrop open={open} sx={{ zIndex: '1' }}>
      <WageComponentsModalStyled open={open} onClose={onClose}>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>Add Cost Declaration</ModalTitle>
            <ModalCloseIcon onClick={onClose}>
              <CloseIcon fill={theme.palette.secondary.contrastText} />
            </ModalCloseIcon>
          </ModalHeader>
          <Box>
            <UPMultipleSelectionDropdown
              required
              items={wageComponentsOptions}
              value={wageComponentsSelection}
              dropdownLabel="Wage Components"
              handleChange={event => {
                setWageComponentsSelection(event.target.value);
              }}
            />
          </Box>
          <AddWageComponentButtonContainer>
            <UPButton text="Add" onClick={onSave} />
          </AddWageComponentButtonContainer>
        </ModalContainer>
      </WageComponentsModalStyled>
    </Backdrop>
  );
};

export default AddWageComponentModal;
