import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getParitairComiteSelectItemsByCompanyAction } from 'store/actions/paritair-comite-actions';
import { generateEmployeesArray } from 'modules/employees/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { employeesSelector } from 'store/selectors/employee-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import { paritairComiteByCompanySelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import {
  COMPANY_PAYROLL__ADD_NEW_EMPLOYEE_EXEMPTION,
  COMPANY_PAYROLL__DELETE_EMPLOYEE_EXEMPTION,
  COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION,
  COMPANY_PAYROLL__EMPLOYEE,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  COMPANY_PAYROLL__WAGE_EXEMPTION,
  COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO,
  GENERAL__SELECT,
} from 'translations/constants';
import { EmployeesExemptionsGridProps } from '../types';
import DataGridEditInputCell from './DataGridEditInputCell';
import { UPDataGridContainer } from './styles';
import { arrayToString, findDuplicates } from './helpers';

const EmployeeExemptionsGrid = (props: EmployeesExemptionsGridProps): JSX.Element => {
  const { employeesExemptions, setEmployeesExemptions, setErrorMessage } = props;

  const [t] = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();
  const employees = generateEmployeesArray(useAppSelector(employeesSelector));
  const userIsCompanyManager = useAppSelector(profileSelector).role === CompanyRole.COMPANY_MANAGER;
  const paritairComites = useAppSelector(paritairComiteByCompanySelectItemsSelector);

  const [employeesOptions, setEmployeesOptions] = useState<UPDropdownItem[]>(employees);
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();
  const [rowIsUpdated, setRowIsUpdated] = useState<boolean>(true);

  useEffect(() => {
    if (employeesOptions.length === 0 && employees.length > 0) setEmployeesOptions(employees);
  }, [employees, employeesOptions.length]);

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsByCompanyAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (rowIsUpdated) {
      const items = employeesExemptions.map(w => ({
        keyId: w.employeeId.toString(),
        jointCommissionId: w.paritairComiteId,
      }));

      const duplicates = findDuplicates(items);
      if (duplicates.length > 0) {
        setErrorMessage(
          `${t(COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION)} ${t(COMPANY_PAYROLL__EMPLOYEE)}:  ${arrayToString(
            duplicates.map(w => ({
              keyId: employeesOptions?.find(item => item.value === w.keyId)?.label,
              jointCommissionId: paritairComites?.find(item => item.value === w.jointCommissionId)?.label,
            })),
          )}`,
        );
      } else if (employeesExemptions.some(e => toNumber(e.wageExemption) <= 0)) {
        setErrorMessage(t(COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO));
      } else {
        setErrorMessage('');
      }
      setRowIsUpdated(false);
    }
  }, [employeesExemptions, employeesOptions, rowIsUpdated, paritairComites, setErrorMessage, t]);

  const getEmployee = useCallback(
    employeeId => {
      const employeeData = employeesOptions.find(pc => toNumber(pc.value) === employeeId || pc.value === employeeId);
      return employeeData ? employeeData.label : <span>{t(GENERAL__SELECT)}</span>;
    },
    [employeesOptions, t],
  );

  const renderFunctionEditInputCell = useCallback(
    (params: any) => {
      /* eslint-disable react/jsx-props-no-spreading */
      return <DataGridEditInputCell {...params} dropdownOptions={employeesOptions} />;
    },
    [employeesOptions],
  );

  const updateEmployeeExemption = useCallback(
    (id, field, value) => {
      let employeeExemption = employeesExemptions.find(w => w.id === id);
      const index = employeesExemptions.indexOf(employeeExemption);
      employeeExemption = { ...employeeExemption, [field]: value };
      const updatedList = [
        ...employeesExemptions.slice(0, index),
        employeeExemption,
        ...employeesExemptions.slice(index + 1, employeesExemptions.length),
      ];
      setEmployeesExemptions(updatedList);
      setRowIsUpdated(true);
      return employeeExemption;
    },
    [employeesExemptions, setEmployeesExemptions],
  );

  const onAddNewEmployeeWageExemption = useCallback(() => {
    setEmployeesExemptions([
      ...employeesExemptions,
      { id: Math.random(), employeeId: 0, wageExemption: 0, paritairComiteId: '' },
    ]);
  }, [employeesExemptions, setEmployeesExemptions]);

  const onDeleteWageExemption = useCallback(() => {
    setEmployeesExemptions([...employeesExemptions.filter(c => c.id !== selectedRow)]);
    setSelectedRow(null);
    setRowIsUpdated(true);
  }, [employeesExemptions, selectedRow, setEmployeesExemptions]);

  const functionColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'employeeId',
      headerName: t(COMPANY_PAYROLL__EMPLOYEE),
      width: 200,
      editable: !userIsCompanyManager,
      renderCell: params => {
        return getEmployee(params.row.employeeId);
      },
      renderEditCell: renderFunctionEditInputCell,
      valueSetter: params => {
        const updatedFunctionExemption = updateEmployeeExemption(params.row.id, 'employeeId', params.value);
        return updatedFunctionExemption;
      },
      sortable: false,
    },
    {
      field: 'paritairComiteId',
      headerName: t(COMPANY_PAYROLL__PARITAIR_COMITE),
      width: 200,
      renderCell: params => {
        return paritairComites?.find(item => item.value === params.row.paritairComiteId)?.label;
      },
      renderEditCell: params => {
        const found = paritairComites?.find((item: UPDropdownItem) => item.value === params.row.paritairComiteId);
        return (
          <DataGridEditInputCell
            {...params}
            value={found?.value}
            dropdownOptions={paritairComites}
            category="employeesExemptions"
          />
        );
      },
      valueSetter: params => {
        const updatedFunctionExemption = updateEmployeeExemption(params.row.id, 'paritairComiteId', params.value);
        return updatedFunctionExemption;
      },
      editable: !userIsCompanyManager,
      sortable: false,
    },
    {
      field: 'wageExemption',
      headerName: t(COMPANY_PAYROLL__WAGE_EXEMPTION),
      width: 200,
      type: 'number',
      editable: !userIsCompanyManager,
      valueSetter: params => {
        const updatedFunctionExemption = updateEmployeeExemption(params.row.id, 'wageExemption', params.value);
        return updatedFunctionExemption;
      },
      sortable: false,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          marginTop: '32px',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ marginBottom: '16px', marginRight: '16px' }}>
          <UPButton
            text={`+ ${t(COMPANY_PAYROLL__ADD_NEW_EMPLOYEE_EXEMPTION)}`}
            onClick={onAddNewEmployeeWageExemption}
            disabled={userIsCompanyManager}
          />
        </Box>
        <Box sx={{ marginBottom: '16px' }}>
          <UPButton
            text={t(COMPANY_PAYROLL__DELETE_EMPLOYEE_EXEMPTION)}
            onClick={onDeleteWageExemption}
            disabled={userIsCompanyManager}
            outlined
          />
        </Box>
      </Box>
      <UPDataGridContainer>
        <UPDataGrid
          rows={employeesExemptions}
          columns={functionColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selection: any) => setSelectedRow(selection[0])}
          withCheckboxes={false}
          getRowId={r => r.id}
        />
      </UPDataGridContainer>
    </Box>
  );
};
export default EmployeeExemptionsGrid;
