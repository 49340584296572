import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllParitairComiteRequest,
  getParitairComiteByIdRequest,
  getParitairComiteSelectItemsByCompanyRequest,
  getParitairComiteSelectItemsRequest,
  saveParitairComiteRequest,
  updateParitairComiteRequest,
} from 'api/requests/paritair-comite-requests';
import { UPDropdownItem } from 'components/dropdown';
import { toNumber } from 'lodash';
import {
  PARITAIR_COMITE__SET_SELECT_ITEMS,
  PARITAIR_COMITE__GET_SELECT_ITEMS,
  PARITAIR_COMITE__GET_SELECT_ITEMS_ERROR,
  PARITAIR_COMITE__GET_ALL,
  PARITAIR_COMITE__SET_PARITAIR_COMITES,
  PARITAIR_COMITE__GET_BY_ID,
  PARITAIR_COMITE__GET_PARITAIR_COMITE,
  PARITAIR_COMITE__GET_PARITAIR_COMITE_ERROR,
  PARITAIR_COMITE__SAVE,
  PARITAIR_COMITE__SAVE_ERROR,
  PARITAIR_COMITE__GENERAL_LOADING,
  PARITAIR_COMITE__SAVE_SUCCESS,
  PARITAIR_COMITE__SET_ID,
  PARITAIR_COMITE__CLEAR_UPDATE_PARITAIR_COMITE_STATUS,
  PARITAIR_COMITE__UPDATE,
  PARITAIR_COMITE__UPDATE_ERROR,
  PARITAIR_COMITE__UPDATE_SUCCESS,
  PARITAIR_COMITE__CLEAR,
  PARITAIR_COMITE__GET_SELECT_ITEMS_BY_COMPANY_ERROR,
  PARITAIR_COMITE__SET_SELECT_ITEMS_BY_COMPANY,
  PARITAIR_COMITE__GET_SELECT_ITEMS_BY_COMPANY,
} from 'store/constants';
import { IParitairComite } from 'types/paritair-comite';
import logError from 'utils/log-error';

export const setParitairComiteRequestsLoadingAction = createAction<boolean>(PARITAIR_COMITE__GENERAL_LOADING);

export const setParitairComiteSelectItemsAction = createAction<UPDropdownItem[]>(PARITAIR_COMITE__SET_SELECT_ITEMS);
export const getParitairComiteSelectItemsErrorAction = createAction<boolean>(PARITAIR_COMITE__GET_SELECT_ITEMS_ERROR);

export const getParitairComiteSelectItemsAction = createAsyncThunk(
  PARITAIR_COMITE__GET_SELECT_ITEMS,
  async (_, thunkApi) => {
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(true));
    thunkApi.dispatch(getParitairComiteSelectItemsErrorAction(false));
    try {
      const response = await getParitairComiteSelectItemsRequest();
      thunkApi.dispatch(setParitairComiteSelectItemsAction(response));
    } catch (error) {
      thunkApi.dispatch(getParitairComiteSelectItemsErrorAction(true));
    }
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(false));
  },
);

export const setParitairComiteSelectItemsByCompanyAction = createAction<UPDropdownItem[]>(
  PARITAIR_COMITE__SET_SELECT_ITEMS_BY_COMPANY,
);
export const getParitairComiteSelectItemsErrorByCompanyAction = createAction<boolean>(
  PARITAIR_COMITE__GET_SELECT_ITEMS_BY_COMPANY_ERROR,
);

export const getParitairComiteSelectItemsByCompanyAction = createAsyncThunk(
  PARITAIR_COMITE__GET_SELECT_ITEMS_BY_COMPANY,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(getParitairComiteSelectItemsErrorByCompanyAction(false));
    try {
      const response = await getParitairComiteSelectItemsByCompanyRequest(companyId);
      thunkApi.dispatch(setParitairComiteSelectItemsByCompanyAction(response));
    } catch (error) {
      thunkApi.dispatch(getParitairComiteSelectItemsErrorByCompanyAction(true));
    }
  },
);

export const setParitairComitesAction = createAction<IParitairComite[]>(PARITAIR_COMITE__SET_PARITAIR_COMITES);

export const getParitairComitesAction = createAsyncThunk(PARITAIR_COMITE__GET_ALL, async (_, thunkApi) => {
  thunkApi.dispatch(setParitairComiteRequestsLoadingAction(true));

  try {
    const response = await getAllParitairComiteRequest();
    thunkApi.dispatch(setParitairComitesAction(response));
  } catch (error) {
    logError(error);
  }
  thunkApi.dispatch(setParitairComiteRequestsLoadingAction(false));
});

export const getParitairComiteAction = createAction<IParitairComite>(PARITAIR_COMITE__GET_PARITAIR_COMITE);
export const getParitairComiteErrorAction = createAction<boolean>(PARITAIR_COMITE__GET_PARITAIR_COMITE_ERROR);

export const getParitairComiteByIdAction = createAsyncThunk(
  PARITAIR_COMITE__GET_BY_ID,
  async (paritairComiteId: number, thunkApi) => {
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(true));
    thunkApi.dispatch(getParitairComiteErrorAction(false));

    try {
      const response = await getParitairComiteByIdRequest(paritairComiteId);
      thunkApi.dispatch(getParitairComiteAction(response));
    } catch (error) {
      thunkApi.dispatch(getParitairComiteErrorAction(true));
    }
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(false));
  },
);

export const saveParitairComiteSuccessAction = createAction<boolean>(PARITAIR_COMITE__SAVE_SUCCESS);
export const saveParitairComiteErrorAction = createAction<boolean>(PARITAIR_COMITE__SAVE_ERROR);
export const setParitairComiteIdAction = createAction<number>(PARITAIR_COMITE__SET_ID);

export const saveParitairComiteAction = createAsyncThunk(
  PARITAIR_COMITE__SAVE,
  async (paritairComite: IParitairComite, thunkApi) => {
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(true));
    thunkApi.dispatch(saveParitairComiteErrorAction(false));
    thunkApi.dispatch(saveParitairComiteSuccessAction(false));
    try {
      const paritairComiteId = await saveParitairComiteRequest({
        id: paritairComite.id,
        number: toNumber(paritairComite.number),
        dutchName: paritairComite.dutchName,
        frenchName: paritairComite.frenchName,
        englishName: paritairComite.englishName,
        startDate: paritairComite.startDate,
      });
      thunkApi.dispatch(saveParitairComiteSuccessAction(true));
      thunkApi.dispatch(setParitairComiteIdAction(paritairComiteId));
    } catch (error) {
      thunkApi.dispatch(saveParitairComiteErrorAction(true));
    }
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(false));
  },
);

export const updateParitairComiteSuccessAction = createAction<boolean>(PARITAIR_COMITE__UPDATE_SUCCESS);
export const updateParitairComiteErrorAction = createAction<boolean>(PARITAIR_COMITE__UPDATE_ERROR);
export const clearUpdateParitairComiteStatusAction = createAction<void>(
  PARITAIR_COMITE__CLEAR_UPDATE_PARITAIR_COMITE_STATUS,
);

export const updateParitairComiteAction = createAsyncThunk(
  PARITAIR_COMITE__UPDATE,
  async (paritairComite: IParitairComite, thunkApi) => {
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(true));
    thunkApi.dispatch(updateParitairComiteErrorAction(false));
    thunkApi.dispatch(updateParitairComiteSuccessAction(false));
    try {
      await updateParitairComiteRequest({
        id: paritairComite.id,
        number: toNumber(paritairComite.number),
        dutchName: paritairComite.dutchName,
        frenchName: paritairComite.frenchName,
        englishName: paritairComite.englishName,
        startDate: paritairComite.startDate,
      });
      thunkApi.dispatch(updateParitairComiteSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(updateParitairComiteErrorAction(true));
    }
    thunkApi.dispatch(setParitairComiteRequestsLoadingAction(false));
  },
);

export const clearParitairComiteAction = createAction<void>(PARITAIR_COMITE__CLEAR);
