import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { postLocationRequest, putLocationRequest } from 'api/requests/company-requests';
import {
  COMPANY_LOCATION__PUT,
  COMPANY_LOCATION__SET_LOCATION,
  COMPANY_LOCATION__SET_LOCATION_ERROR,
  COMPANY_LOCATION__POST,
  COMPANY_LOCATION__GENERAL_LOADING,
} from 'store/constants';
import ILocation from 'types/company/locations/ILocation';
import { addOneLocationAction, updateOneLocationAction } from './company-locations-actions';

export const setLocationRequestsLoadingAction = createAction<boolean>(COMPANY_LOCATION__GENERAL_LOADING);

export const setLocationAction = createAction<boolean>(COMPANY_LOCATION__SET_LOCATION);
export const setLocationErrorAction = createAction<boolean>(COMPANY_LOCATION__SET_LOCATION_ERROR);

export const putLocationAction = createAsyncThunk(
  COMPANY_LOCATION__PUT,
  async (props: { companyId: string; location: ILocation }, thunkApi) => {
    thunkApi.dispatch(setLocationRequestsLoadingAction(true));
    thunkApi.dispatch(setLocationErrorAction(false));
    try {
      await putLocationRequest(props);
      thunkApi.dispatch(setLocationAction(true));
      thunkApi.dispatch(updateOneLocationAction({ id: props.location.id, changes: props.location }));
    } catch (error) {
      thunkApi.dispatch(setLocationErrorAction(true));
    }
    thunkApi.dispatch(setLocationRequestsLoadingAction(false));
  },
);

export const postLocationAction = createAsyncThunk(
  COMPANY_LOCATION__POST,
  async (props: { companyId: string; location: ILocation }, thunkApi) => {
    thunkApi.dispatch(setLocationRequestsLoadingAction(true));
    thunkApi.dispatch(setLocationErrorAction(false));
    try {
      const locationId = await postLocationRequest({ companyId: props.companyId, location: props.location });
      const newLocation: ILocation = { ...props.location, id: locationId };
      thunkApi.dispatch(setLocationAction(true));
      thunkApi.dispatch(addOneLocationAction(newLocation));
    } catch (error) {
      thunkApi.dispatch(setLocationErrorAction(true));
    }
    thunkApi.dispatch(setLocationRequestsLoadingAction(false));
  },
);
