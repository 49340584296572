import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteCostCentresRequest,
  getAllCostCentresAsSelectItems,
  getAllCostCentresRequest,
} from 'api/requests/company-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  COMPANY_COST_CENTERS__SET_COST_CENTERS,
  COMPANY_COST_CENTERS__SET_COST_CENTERS_ERROR,
  COMPANY_COST_CENTERS__GET_ALL,
  COMPANY_COST_CENTERS__DELETE_SUCCESS,
  COMPANY_COST_CENTERS__DELETE_ERROR,
  COMPANY_COST_CENTERS__DELETE,
  COMPANY_COST_CENTERS__REMOVE_MANY,
  COMPANY_COST_CENTERS__UPDATE_ONE,
  COMPANY_COST_CENTERS__ADD_ONE,
  COMPANY_COST_CENTERS__CLEAR,
  COMPANY_COST_CENTERS__SET_COST_CENTERS_DROPDOWN_ITEMS,
  COMPANY_COST_CENTERS__SET_COST_CENTERS_DROPDOWN_ITEMS_ERROR,
  COMPANY_COST_CENTERS__GENERAL_LOADING,
} from 'store/constants';
import IPayrollCostCenter from 'types/company/payroll/IPayrollCostCenter';

export const setCostCentersRequestsLoadingAction = createAction<boolean>(COMPANY_COST_CENTERS__GENERAL_LOADING);

export const setCostCentersAction = createAction<IPayrollCostCenter[]>(COMPANY_COST_CENTERS__SET_COST_CENTERS);
export const setCostCentersErrorAction = createAction<boolean>(COMPANY_COST_CENTERS__SET_COST_CENTERS_ERROR);

export const updateOneCostCenterAction = createAction<{ id: number; changes: IPayrollCostCenter }>(
  COMPANY_COST_CENTERS__UPDATE_ONE,
);
export const addOneCostCenterAction = createAction<IPayrollCostCenter>(COMPANY_COST_CENTERS__ADD_ONE);

export const getCostCentersAction = createAsyncThunk(
  COMPANY_COST_CENTERS__GET_ALL,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCostCentersRequestsLoadingAction(true));
    thunkApi.dispatch(setCostCentersErrorAction(false));
    try {
      const response = await getAllCostCentresRequest(companyId);
      thunkApi.dispatch(setCostCentersAction(response));
    } catch (error) {
      thunkApi.dispatch(setCostCentersErrorAction(true));
    }
    thunkApi.dispatch(setCostCentersRequestsLoadingAction(false));
  },
);

export const deleteCostCentersSuccessAction = createAction<boolean>(COMPANY_COST_CENTERS__DELETE_SUCCESS);
export const deleteCostCentersErrorAction = createAction<boolean>(COMPANY_COST_CENTERS__DELETE_ERROR);
export const removeManyCostCentersAction = createAction<number[]>(COMPANY_COST_CENTERS__REMOVE_MANY);
export const removeAllCostCentersAction = createAction<void>(COMPANY_COST_CENTERS__CLEAR);

export const deleteCostCentersAction = createAsyncThunk(
  COMPANY_COST_CENTERS__DELETE,
  async (props: { companyId: string; costCentresIds: number[] }, thunkApi) => {
    thunkApi.dispatch(setCostCentersRequestsLoadingAction(true));
    thunkApi.dispatch(deleteCostCentersErrorAction(false));
    try {
      await deleteCostCentresRequest(props);
      thunkApi.dispatch(deleteCostCentersSuccessAction(true));
      thunkApi.dispatch(removeManyCostCentersAction(props.costCentresIds));
    } catch (error) {
      thunkApi.dispatch(deleteCostCentersErrorAction(true));
    }
    thunkApi.dispatch(setCostCentersRequestsLoadingAction(false));
  },
);

export const setCostCentersDropdownItemsAction = createAction<UPDropdownItem[]>(
  COMPANY_COST_CENTERS__SET_COST_CENTERS_DROPDOWN_ITEMS,
);
export const setCostCentersDropdownItemsErrorAction = createAction<boolean>(
  COMPANY_COST_CENTERS__SET_COST_CENTERS_DROPDOWN_ITEMS_ERROR,
);

export const getCostCentersDropdownItemsAction = createAsyncThunk(
  COMPANY_COST_CENTERS__GET_ALL,
  async (
    props: {
      companyId: number;
      locationId?: number;
    },
    thunkApi,
  ) => {
    const { companyId, locationId } = props;

    thunkApi.dispatch(setCostCentersDropdownItemsErrorAction(false));
    thunkApi.dispatch(setCostCentersRequestsLoadingAction(true));
    try {
      const response = await getAllCostCentresAsSelectItems(companyId, locationId);
      thunkApi.dispatch(setCostCentersDropdownItemsAction(response));
    } catch (error) {
      thunkApi.dispatch(setCostCentersDropdownItemsErrorAction(true));
    }
    thunkApi.dispatch(setCostCentersRequestsLoadingAction(false));
  },
);
