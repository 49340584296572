import { createReducer } from '@reduxjs/toolkit';
import {
  addOneCostCenterAction,
  removeAllCostCentersAction,
  removeManyCostCentersAction,
  setCostCentersAction,
  setCostCentersErrorAction,
  setCostCentersRequestsLoadingAction,
  updateOneCostCenterAction,
  setCostCentersDropdownItemsErrorAction,
  setCostCentersDropdownItemsAction,
} from 'store/actions/company-cost-centers-actions';
import costCentersAdapter from 'store/adapters/company-cost-centers-adapter';
import IPayrollCostCentersState from 'types/company/payroll/IPayrollCostCentersState';

const initialState: IPayrollCostCentersState = {
  costCenters: [],
  loading: false,
  costCentersError: false,
  costCentersDropdownItems: [],
  costCentersDropdownItemsError: false,
};

const costCentersReducer = createReducer(
  costCentersAdapter.getInitialState<IPayrollCostCentersState>(initialState),
  builder => {
    return builder
      .addCase(setCostCentersAction, costCentersAdapter.setAll)
      .addCase(updateOneCostCenterAction, costCentersAdapter.updateOne)
      .addCase(addOneCostCenterAction, costCentersAdapter.addOne)
      .addCase(removeManyCostCentersAction, costCentersAdapter.removeMany)
      .addCase(removeAllCostCentersAction, costCentersAdapter.removeAll)
      .addCase(setCostCentersRequestsLoadingAction, (state, action) => ({
        ...state,
        loading: action.payload,
      }))
      .addCase(setCostCentersErrorAction, (state, action) => ({
        ...state,
        costCentersError: action.payload,
      }))
      .addCase(setCostCentersDropdownItemsAction, (state, action) => ({
        ...state,
        costCentersDropdownItems: action.payload,
      }))
      .addCase(setCostCentersDropdownItemsErrorAction, (state, action) => ({
        ...state,
        costCentersDropdownItemsError: action.payload,
      }));
  },
);

export default costCentersReducer;
