import { toNumber } from 'lodash';
import { AddEditEmployeeModal } from 'modules/employees/add-edit-employee-modal';
import { AddEmployeeButtonDashboard } from 'modules/employees/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DASHBOARD__GO_TO_EMPLOYEES,
  EMPLOYEES__ADD_NEW_EMPLOYEE,
  EMPLOYEE_STATUS__DEPLOYED,
  EMPLOYEE_STATUS__INVITED,
  EMPLOYEE_STATUS__READY,
  MENU__EMPLOYEES,
} from 'translations/constants';
import { ReactComponent as DashboardAvatarIcon } from '../../assets/icons/DashboardAvatar.svg';
import { ReactComponent as EmptyLineIcon } from '../../assets/icons/EmptyLine.svg';
import { ReactComponent as LoadingLineIcon } from '../../assets/icons/LoadingLine.svg';
import {
  DashboardEmployeeActionButtonsContainer,
  DashboardEmployeeActions,
  DashboardEmployeeAvatarContainer,
  DashboardEmployeeNumbers,
  DashboardTileStyled,
  DashboardTileTitle,
  DeployedEmployeesNumberStyled,
  DeployedTitle,
  EmployeeNumberContainerStyled,
  GoToEmployeesButtonStyled,
  InvitedEmployeesNumberStyled,
  InvitedTitle,
  ReadyEmployeesNumberStyled,
  ReadyTitle,
} from './styles';
import { DashboardEmployeeTileProps } from './types';

const DashboardEmployeeTile = (props: DashboardEmployeeTileProps): JSX.Element => {
  const { employeesInvited, employeesReady, employeesDeployed, companyId } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [openAddEditModal, setOpenAddEditModal] = useState(false);

  return (
    <DashboardTileStyled sx={{ backgroundColor: 'white' }}>
      <DashboardTileTitle>{t(MENU__EMPLOYEES)}</DashboardTileTitle>
      <DashboardEmployeeNumbers>
        <EmployeeNumberContainerStyled sx={{ borderRight: '1px solid', borderColor: 'lightgray' }}>
          <InvitedTitle>{t(EMPLOYEE_STATUS__INVITED)}</InvitedTitle>
          <InvitedEmployeesNumberStyled>{employeesInvited}</InvitedEmployeesNumberStyled>
        </EmployeeNumberContainerStyled>
        <EmployeeNumberContainerStyled sx={{ borderRight: '1px solid', borderColor: 'lightgray' }}>
          <ReadyTitle>{t(EMPLOYEE_STATUS__READY)}</ReadyTitle>
          <ReadyEmployeesNumberStyled>{employeesReady}</ReadyEmployeesNumberStyled>
        </EmployeeNumberContainerStyled>
        <EmployeeNumberContainerStyled>
          <DeployedTitle>{t(EMPLOYEE_STATUS__DEPLOYED)}</DeployedTitle>
          <DeployedEmployeesNumberStyled>{employeesDeployed}</DeployedEmployeesNumberStyled>
        </EmployeeNumberContainerStyled>
      </DashboardEmployeeNumbers>
      <DashboardEmployeeActions>
        <DashboardEmployeeActionButtonsContainer>
          <AddEmployeeButtonDashboard text={t(EMPLOYEES__ADD_NEW_EMPLOYEE)} onClick={() => setOpenAddEditModal(true)} />
          <GoToEmployeesButtonStyled
            onClick={() => {
              navigate('/employees');
            }}
          >
            {t(DASHBOARD__GO_TO_EMPLOYEES)}
          </GoToEmployeesButtonStyled>
        </DashboardEmployeeActionButtonsContainer>
        <DashboardEmployeeAvatarContainer>
          <DashboardAvatarIcon />
          <EmptyLineIcon />
          <LoadingLineIcon />
        </DashboardEmployeeAvatarContainer>
      </DashboardEmployeeActions>
      {openAddEditModal && (
        <AddEditEmployeeModal
          open={openAddEditModal}
          onClose={() => {
            setOpenAddEditModal(false);
          }}
          companyId={toNumber(companyId)}
        />
      )}
    </DashboardTileStyled>
  );
};

export default DashboardEmployeeTile;
