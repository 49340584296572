import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import Lang from 'types/Lang';
import { getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import AppHeader from './AppHeader';
import MenuItemsByRole from './MenuItemsByRole';
import { Drawer, DrawerHeader } from './styles';
import { IAppContainerProps } from './types';

const AppContainer = (props: IAppContainerProps): JSX.Element => {
  const { children } = props;

  const [, i18next] = useTranslation();
  const screenIsSmaller = useMediaQuery('(max-height:900px)');
  const user = useAppSelector(profileSelector);

  const isAdmin = user?.role === CompanyRole.ADMIN;

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  useEffect(() => {
    if (user && languageOptions) {
      // we could use Array.at(index) but it's not supported by onlder browsers
      const userLang = languageOptions[toNumber(user.languageId) - 1].label;
      i18next.changeLanguage(userLang);
    }
  }, [i18next, languageOptions, user]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppHeader />
      <Drawer variant="permanent" smallscreen={screenIsSmaller && isAdmin}>
        <DrawerHeader />
        <MenuItemsByRole />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: '100%' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default AppContainer;
