import { ICostDeclaration, ICostDeclarationForCompany } from 'types/cost-declaration';
import { RootState } from 'store';
import { UPDropdownItem } from 'components/dropdown';

export const costDeclarationsSelector = (state: RootState): ICostDeclaration[] =>
  state.costDeclaration.costDeclarations;

export const costDeclarationsLoadingSelector = (state: RootState): boolean => state.costDeclaration.loading;

export const costDeclarationsErrorSelector = (state: RootState): boolean => state.costDeclaration.costDeclarationsError;

export const costDeclarationsSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.costDeclarationsSuccess;

export const costDeclarationErrorSelector = (state: RootState): boolean => state.costDeclaration.costDeclarationError;

export const costDeclarationSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.costDeclarationSuccess;

export const costDeclarationSelector = (state: RootState): ICostDeclaration => state.costDeclaration.costDeclaration;

export const saveCostDeclarationErrorSelector = (state: RootState): boolean =>
  state.costDeclaration.saveCostDeclarationError;

export const saveCostDeclarationSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.saveCostDeclarationSuccess;

export const updateCostDeclarationErrorSelector = (state: RootState): boolean =>
  state.costDeclaration.updateCostDeclarationError;

export const updateCostDeclarationSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.updateCostDeclarationSuccess;

export const deleteCostDeclarationsErrorSelector = (state: RootState): boolean =>
  state.costDeclaration.deleteCostDeclarationsError;

export const deleteCostDeclarationsSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.deleteCostDeclarationsSuccess;

export const costDeclarationSelectItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.costDeclaration.costDeclarationSelectItems;

export const costDeclarationSelectItemsErrorSelector = (state: RootState): boolean =>
  state.costDeclaration.costDeclarationSelectItemsError;

export const costDeclarationSelectItemsSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.costDeclarationSelectItemsSuccess;

export const costDeclarationsForCompanySelector = (state: RootState): ICostDeclarationForCompany[] =>
  state.costDeclaration.costDeclarationsForCompany;

export const costDeclarationSelectItemsForCompanySelector = (state: RootState): UPDropdownItem[] =>
  state.costDeclaration.costDeclarationSelectItemsForCompany;

export const bulkChangeActiveStatusErrorSelector = (state: RootState): boolean =>
  state.costDeclaration.bulkChangeActiveStatusError;

export const bulkChangeActiveStatusSuccessSelector = (state: RootState): boolean =>
  state.costDeclaration.bulkChangeActiveStatusSuccess;
