import {
  styled,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Typography,
  Avatar,
  Box,
  Link,
  Menu,
  IconButton,
  ListItem,
  Alert,
} from '@mui/material';
import { IAppBarProps } from './types';

const drawerWidth = 90;

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export const AppBar = styled(MuiAppBar)<IAppBarProps>(({ theme }) => ({
  backgroundColor: 'white',
  '.MuiTypography-h6': {
    color: theme.palette.secondary.contrastText,
    fontWeight: '600',
    fontSize: '16px',
  },
  '.MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'smallscreen',
})<{ smallscreen?: boolean }>(({ theme, smallscreen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '.MuiList-root': {
    paddingTop: '0px',
  },
  '.MuiPaper-root': {
    borderRight: 'none',
    width: drawerWidth,
    boxShadow: '4px 0px 4px rgba(0, 0, 0, 0.03)',
  },
  '.MuiListItemIcon-root': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  '.MuiButtonBase-root': {
    flexDirection: 'column',
    transition: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    ' svg': { fill: theme.palette.secondary.main },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      '.MuiTypography-root': { color: theme.palette.primary.main },
      '.MuiSvgIcon-root': { fill: theme.palette.primary.main },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      ' svg': { fill: theme.palette.primary.main },
    },
  },
  '.MuiTypography-root': {
    fontSize: '12px',
    fontWeight: '600',
    marginTop: smallscreen ? '5px' : '10px',
    color: theme.palette.secondary.main,
    whiteSpace: 'normal',
  },
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
  [theme.breakpoints.down(1367)]: {
    '.MuiListItemIcon-root': {
      marginTop: '3px',
    },
  },
}));

export const ActiveItemStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  width: 'auto',
  alignSelf: 'center',
  marginRight: '10px',
}));

export const CompanyNameStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  '&:hover': {
    cursor: 'default',
  },
  display: 'inline-block',
  [theme.breakpoints.down(1000)]: {
    width: '120px',
  },
}));

export const CompanyDropdownStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.down(1000)]: {
    width: '120px',
  },
}));

export const NavbarCloseIconStyled = styled(Box)(() => ({
  position: 'absolute',
  right: 24,
  top: 16,
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const WeekStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '16px',
  '&:hover': {
    cursor: 'default',
  },
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const UserName = styled(Avatar)(({ theme }) => ({
  backgroundColor: '#dbede3', // theme.palette.info.light
  color: theme.palette.primary.main,
  fontWeight: '600',
  fontSize: '12px',
}));

export const IconBox = styled(Box)(() => ({
  width: '39px',
  height: '32px',
}));

export const HeaderBox = styled(Box)(() => ({
  display: 'flex',
  flexGrow: '1',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  alignItems: 'center',
  paddingLeft: '36px',
}));

export const AvatarMenu = styled(Menu)(() => ({
  '&.MuiPaper-root': {
    elevation: 0,
    overflow: 'visible',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))',
    mt: 1.5,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 10,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}));

export const MenuLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  display: 'block',
  color: theme.palette.secondary.contrastText,
  fontSize: '14px',
}));

export const GreenMenuItem = styled(ListItem)(({ theme }) => ({
  '.MuiTypography-root': {
    color: theme.palette.primary.main,
  },
}));

export const MenuStyled = styled(Menu)(({ theme }) => ({
  overflow: 'visible',
  filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08))',
  mt: 1.5,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 20,
    width: 10,
    height: 15,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const NavBarButtonStyled = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up(1000)]: {
    display: 'none',
  },
}));

export const AlertStyled = styled(Alert)(({ theme }) => ({
  width: '800px',
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));
