import { createReducer } from '@reduxjs/toolkit';
import {
  bulkChangeActiveStatusSuccessAction,
  bulkChangeActiveStatusErrorAction,
  deleteCostDeclarationsSuccessAction,
  deleteCostDeclarationsErrorAction,
  saveCostDeclarationSuccessAction,
  saveCostDeclarationErrorAction,
  setCostDeclarationAction,
  setCostDeclarationSuccessAction,
  setCostDeclarationSelectItemsAction,
  setCostDeclarationSelectItemsSuccessAction,
  setCostDeclarationSelectItemsErrorAction,
  setCostDeclarationSelectItemsForCompanyAction,
  setCostDeclarationsAction,
  setCostDeclarationsErrorAction,
  setCostDeclarationsForCompanyAction,
  setCostDeclarationsRequestsLoadingAction,
  updateCostDeclarationSuccessAction,
  updateCostDeclarationErrorAction,
} from 'store/actions/cost-declaration-actions';
import { ICostDeclarationState } from 'types/cost-declaration';

const initialState: ICostDeclarationState = {
  costDeclarations: [],
  loading: false,
  costDeclarationsError: false,
  costDeclarationsSuccess: false,
  costDeclaration: null,
  costDeclarationError: false,
  costDeclarationSuccess: false,
  saveCostDeclarationError: false,
  saveCostDeclarationSuccess: false,
  updateCostDeclarationError: false,
  updateCostDeclarationSuccess: false,
  deleteCostDeclarationsError: false,
  deleteCostDeclarationsSuccess: false,
  costDeclarationSelectItems: [],
  costDeclarationSelectItemsError: false,
  costDeclarationSelectItemsSuccess: false,
  costDeclarationsForCompany: [],
  costDeclarationSelectItemsForCompany: [],
  bulkChangeActiveStatusError: false,
  bulkChangeActiveStatusSuccess: false,
};

const costDeclarationReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setCostDeclarationsAction, (state, action) => ({ ...state, costDeclarations: action.payload }))
    .addCase(setCostDeclarationsRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setCostDeclarationsErrorAction, (state, action) => ({
      ...state,
      costDeclarationError: action.payload,
    }))
    .addCase(setCostDeclarationAction, (state, action) => ({ ...state, costDeclaration: action.payload }))
    .addCase(setCostDeclarationSuccessAction, (state, action) => ({
      ...state,
      costDeclarationSuccess: action.payload,
    }))
    .addCase(saveCostDeclarationErrorAction, (state, action) => ({
      ...state,
      saveCostDeclarationError: action.payload,
    }))
    .addCase(saveCostDeclarationSuccessAction, (state, action) => ({
      ...state,
      saveCostDeclarationSuccess: action.payload,
    }))
    .addCase(updateCostDeclarationErrorAction, (state, action) => ({
      ...state,
      updateCostDeclarationError: action.payload,
    }))
    .addCase(updateCostDeclarationSuccessAction, (state, action) => ({
      ...state,
      updateCostDeclarationSuccess: action.payload,
    }))
    .addCase(deleteCostDeclarationsErrorAction, (state, action) => ({
      ...state,
      deleteCostDeclarationsError: action.payload,
    }))
    .addCase(deleteCostDeclarationsSuccessAction, (state, action) => ({
      ...state,
      deleteCostDeclarationsSuccess: action.payload,
    }))
    .addCase(setCostDeclarationSelectItemsAction, (state, action) => ({
      ...state,
      costDeclarationSelectItems: action.payload,
    }))
    .addCase(setCostDeclarationSelectItemsErrorAction, (state, action) => ({
      ...state,
      costDeclarationSelectItemsError: action.payload,
    }))
    .addCase(setCostDeclarationSelectItemsSuccessAction, (state, action) => ({
      ...state,
      costDeclarationSelectItemsSuccess: action.payload,
    }))
    .addCase(setCostDeclarationsForCompanyAction, (state, action) => ({
      ...state,
      costDeclarationsForCompany: action.payload,
    }))
    .addCase(setCostDeclarationSelectItemsForCompanyAction, (state, action) => ({
      ...state,
      costDeclarationSelectItemsForCompany: action.payload,
    }))
    .addCase(bulkChangeActiveStatusErrorAction, (state, action) => ({
      ...state,
      bulkChangeActiveStatusError: action.payload,
    }))
    .addCase(bulkChangeActiveStatusSuccessAction, (state, action) => ({
      ...state,
      bulkChangeActiveStatusSuccess: action.payload,
    }));
});

export default costDeclarationReducer;
