import { Tab, styled, Box, Typography, Stack } from '@mui/material';

export const TabStyled = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: theme.palette.secondary.main,
  fontWeight: '600',
  fontSize: '12px',
  '&.Mui-selected': {
    color: theme.palette.secondary.contrastText,
  },
}));

export const EmptySection = styled(Box)(() => ({
  padding: '32px 32px 16px 0px',
  marginTop: '10px',
}));

export const SwitchLabel = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: '600',
  paddingLeft: '5px',
}));

export const SwitchContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '4px',
}));
