import { Avatar, Box } from '@mui/material';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import theme from 'theme';
import { ReactComponent as VendorTruckIcon } from '../../../../assets/icons/VendorTruckIcon.svg';
import { generateEmployeeFunction } from '../../helpers';
import { EmployeeData, EmployeeName, EmployeeRole } from '../../styles';

const GridCardVendor = (props: { data: any }) => {
  const { data } = props;
  const location = useLocation();

  return (
    <Fragment key={data.vendor.id}>
      <EmployeeData>
        <Avatar sx={{ width: '32px', height: '32px', backgroundColor: theme.palette.primary.main }}>
          <VendorTruckIcon />
        </Avatar>
        <Box sx={{ marginLeft: '30px', marginRight: '10px', position: 'absolute' }}>
          <EmployeeName active={data.editable}>{data.vendor.name}</EmployeeName>
          {location.pathname === '/dashboard' ? (
            <EmployeeRole>{data.vendor.functionName}</EmployeeRole>
          ) : (
            <EmployeeRole>{generateEmployeeFunction(data.vendor.functionName)}</EmployeeRole>
          )}
        </Box>
      </EmployeeData>
    </Fragment>
  );
};

export default GridCardVendor;
