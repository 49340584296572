import { styled, Box, Alert } from '@mui/material';
import UPButton from 'components/button';
import { UPDropdown } from 'components/dropdown';
import { ReactComponent as EmployeeIcon } from '../../assets/icons/EmployeeIcon.svg';
import { ReactComponent as InviteIcon } from '../../assets/icons/InviteIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/DownloadGreen.svg';

export const EmployeeCardStyled = styled(Box)(() => ({
  width: '100%',
  height: 240,
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  cursor: 'pointer',
  // margin: '8px 16px 8px 0px',
  position: 'relative',
}));

export const CardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: theme.palette.secondary.contrastText,
  padding: '20px',
}));

export const MoreContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.primary.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  color: theme.palette.secondary.dark,
  padding: '40px',
}));

export const EmployeeName = styled(Box)(() => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '32px',
}));

export const PersonalDetails = styled(Box)(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '16px',
  textAlign: 'center',
}));

export const ContactDetails = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  color: theme.palette.secondary.dark,
}));

export const InviteActionsContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  width: '50%',
  marginTop: '16px',
}));

export const InviteEmployeesButton = styled('span')(() => ({
  height: '25px',
  display: 'flex',
}));

export const InviteEmployeesButtonText = styled('span')(() => ({
  marginLeft: '8px',
}));

export const UPDropdownStyled = styled(UPDropdown)(() => ({
  maxWidth: '140px',
  display: 'inline-block',
  marginLeft: '8px',
}));

export const UPButtonStyled = styled(UPButton)(() => ({
  marginLeft: '8px',
}));

export const RequiresActionContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  animation: 'breatheAnimation 3s ease-in-out infinite',
  '@keyframes breatheAnimation': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.2,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

export const InactiveContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '16px',
  right: '40px',
}));

export const InvitedContainer = styled(Box)(() => ({
  position: 'absolute',
  top: '16px',
  right: '40px',
}));

export const FiltersEmployeeBox = styled(Box)(({ theme }) => ({
  width: '140px',
  marginRight: '16px',
  [theme.breakpoints.down(690)]: {
    display: 'none',
  },
}));

export const ResponsiveFiltersEmployeeBox = styled(Box)(({ theme }) => ({
  width: '280px',
  marginRight: '8px',
  [theme.breakpoints.up(691)]: {
    width: '140px',
  },
}));

export const NameEmployeeBox = styled(Box)(({ theme }) => ({
  width: '280px',
  marginLeft: theme.spacing(1),
  [theme.breakpoints.down(712)]: {
    marginLeft: '0px',
  },
}));

export const CollapseSearchFiltersContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.up(691)]: {
    display: 'none',
  },
}));

export const AddInviteButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(690)]: {
    width: 'unset',
    alignItems: 'start',
    marginBottom: '16px',
  },
}));

export const InviteEmployeeButton = styled(UPButton)(({ theme }) => ({
  margin: '16px 0px',
  [theme.breakpoints.down(690)]: {
    margin: '6px 0px',
  },
}));

export const AddEmployeeButton = styled(UPButton)(({ theme }) => ({
  margin: '16px 16px 16px 0px',
  height: '48px',
  [theme.breakpoints.down(690)]: {
    margin: '6px 8px 6px 0px',
    fontSize: '14px',
  },
}));

export const AddEmployeeButtonDashboard = styled(UPButton)(({ theme }) => ({
  width: '100%',
  height: '45%',
  fontSize: '15px',
  whiteSpace: 'nowrap',
  [theme.breakpoints.down(690)]: {
    margin: '6px 8px 6px 0px',
    fontSize: '14px',
  },
}));

export const AlertStyled = styled(Alert)(({ theme }) => ({
  width: '380px',
  [theme.breakpoints.down(690)]: {
    width: '100%',
  },
}));

export const RequiresActionButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    marginLeft: 'unset',
    marginTop: '16px',
    width: '75%',
  },
}));

export const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down(690)]: {
    width: 'unset',
    alignItems: 'start',
  },
}));

const defaultButtonColor = ({ theme }) => ({
  fill: 'white',
  'button:hover &': {
    fill: theme.palette.primary.main,
  },
});

export const StyledEmployeeIcon = styled(EmployeeIcon)(defaultButtonColor);

export const StyledInviteIcon = styled(InviteIcon)(defaultButtonColor);

export const StyledDownloadIcon = styled(DownloadIcon)(defaultButtonColor);

export const FiltersLeftContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(999)]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down(690)]: {
    flexDirection: 'column',
  },
}));

export const FiltersRightContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  [theme.breakpoints.down(690)]: {
    flexDirection: 'column',
  },
}));

export const SortIconBox = styled(Box)<{ sortOrder: number }>(({ theme, sortOrder }) => ({
  transform: `rotate(${180 * sortOrder}deg)`,
  transition: 'transform 0.2s ease-in-out',
  cursor: 'pointer',
  alignSelf: 'center',
  [theme.breakpoints.down(691)]: {
    display: 'none',
  },
}));

export const SortIconMobileBox = styled(Box)<{ sortOrder: number }>(({ theme, sortOrder }) => ({
  transform: `rotate(${180 * sortOrder}deg)`,
  transition: 'transform 0.2s ease-in-out',
  cursor: 'pointer',
  alignSelf: 'center',
  marginTop: theme.spacing(4),
  [theme.breakpoints.up(691)]: {
    display: 'none',
  },
}));
