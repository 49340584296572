import { Box, Chip, Menu, MenuItem, Typography } from '@mui/material';
import UPButton from 'components/button';
import { useState } from 'react';
import moment from 'moment';
import { UPInputLabel } from 'components/input/styles';
import { useTranslation } from 'react-i18next';
import { DayPickerError } from 'modules/contracts/styles';
import { CONTRACTS__PRESENT_DATE_ERROR } from 'translations/constants';
import { UPDayPickerProps } from './types';
import UPDayPickerOption from './UPDayPickerOption';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { DatesBoxStyled, DayPickerButtonStyled, DisplayDatesStyled } from './styles';

function getIntervalByWeekNumber(weeknumber: number, weekForYear: number | Date) {
  const date = moment(weekForYear)
    .isoWeek(weeknumber || 1)
    .startOf('week');
  const firstDay = date.format('DD');
  const firstDayMonth = date.format('MMM').toLocaleLowerCase();
  const lastDay = date.add(6, 'day').format('DD');
  const lastDayMonth = date.format('MMM').toLocaleLowerCase();
  if (firstDayMonth === lastDayMonth) {
    return firstDay.concat('-', lastDay, ' ', firstDayMonth);
  }
  return firstDay.concat(' ', firstDayMonth, ' - ', lastDay, ' ', lastDayMonth);
}

const UPDayPicker = (props: UPDayPickerProps): JSX.Element => {
  const { week, required, weekDays, handleDaySelect, error, label, disabled, currentDateError, weekForYear } = props;

  const [t] = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <UPInputLabel>
        {label || 'Dates'} {required && '*'}
      </UPInputLabel>
      <DatesBoxStyled error={error}>
        <DisplayDatesStyled>
          {weekDays
            .filter(e => e.checked)
            .map(item => (
              <Chip
                key={item.date}
                label={
                  <div>
                    <b>{item.day}</b> {item.date2}
                  </div>
                }
              />
            ))}
        </DisplayDatesStyled>
        <Box>
          <DayPickerButtonStyled
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <CalendarIcon />
          </DayPickerButtonStyled>
        </Box>
      </DatesBoxStyled>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          disabled={disabled}
          onClick={null}
          sx={{
            borderBottom: '1px solid #CFD0D0',
            padding: '0px 4px',
            margin: '0px 5px',
            '&.MuiMenuItem-root:hover': {
              backgroundColor: 'white',
            },
          }}
        >
          <Box
            sx={{
              padding: '8px',
              width: '100%',
            }}
          >
            <Typography sx={{ fontSize: '14px', lineHeight: '20px', display: 'flex', justifyContent: 'center' }}>
              <b>Week {week}</b>
            </Typography>
            <Typography sx={{ fontSize: '14px', lineHeight: '20px', display: 'flex', justifyContent: 'center' }}>
              {getIntervalByWeekNumber(week, weekForYear)}
            </Typography>
          </Box>
        </MenuItem>
        {weekDays.map(item => (
          <MenuItem key={item.date} onClick={() => handleDaySelect(item)} sx={{ padding: '0px 4px' }}>
            <UPDayPickerOption dayOfWeek={item.day} date={item.date} checked={item.checked} />
          </MenuItem>
        ))}
        <MenuItem
          onClick={handleClose}
          sx={{
            justifyContent: 'center',
            '&.MuiMenuItem-root:hover': {
              backgroundColor: 'white',
            },
          }}
        >
          <UPButton text="Select" onClick={null} outlined />
        </MenuItem>
      </Menu>

      {currentDateError && <DayPickerError>{t(CONTRACTS__PRESENT_DATE_ERROR)}</DayPickerError>}
    </Box>
  );
};

export default UPDayPicker;
