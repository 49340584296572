import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { archiveErrorsRequest, getApiErrorsRequest, getErrorTypesRequest } from 'api/requests/api-errors-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  API_ERRORS__ARCHIVE_SELECTED,
  API_ERRORS__GET_ALL,
  API_ERRORS__GET_ALL_ERROR,
  API_ERRORS__GET_ALL_LOADING,
  API_ERRORS__GET_ALL_SUCCESS,
  API_ERRORS__GET_ERROR_TYPES,
  API_ERRORS__SET_ALL,
  API_ERRORS__SET_ERROR_TYPES,
} from 'store/constants';
import { IApiErrorGetResponse } from 'types/errors';
import { ApiErrorFilters } from 'types/errors/ApiErrorFilters';

export const getApiErrorsLoadingAction = createAction<boolean>(API_ERRORS__GET_ALL_LOADING);
export const getApiErrorsSuccessAction = createAction<boolean>(API_ERRORS__GET_ALL_SUCCESS);
export const getApiErrorsErrorAction = createAction<boolean>(API_ERRORS__GET_ALL_ERROR);
export const setApiErrorsAction = createAction<IApiErrorGetResponse>(API_ERRORS__SET_ALL);

export const getApiErrorsAction = createAsyncThunk(
  API_ERRORS__GET_ALL,
  async (filters: ApiErrorFilters, { dispatch }) => {
    dispatch(getApiErrorsLoadingAction(true));
    dispatch(getApiErrorsSuccessAction(false));
    dispatch(getApiErrorsErrorAction(false));

    try {
      const response = await getApiErrorsRequest(filters);
      dispatch(setApiErrorsAction(response));
      dispatch(getApiErrorsSuccessAction(true));
    } catch (error) {
      dispatch(getApiErrorsErrorAction(true));
    }

    dispatch(getApiErrorsLoadingAction(false));
  },
);

export const setApiErrorTypesAction = createAction<UPDropdownItem[]>(API_ERRORS__SET_ERROR_TYPES);

export const getErrorTypesAction = createAsyncThunk(API_ERRORS__GET_ERROR_TYPES, async (_, { dispatch }) => {
  dispatch(getApiErrorsLoadingAction(true));
  try {
    const response = await getErrorTypesRequest();
    dispatch(setApiErrorTypesAction(response));
  } catch (error) {
    dispatch(getApiErrorsErrorAction(true));
  }
  dispatch(getApiErrorsLoadingAction(false));
});

export const archiveErrorsAction = createAsyncThunk(
  API_ERRORS__ARCHIVE_SELECTED,
  async (errorIds: number[], { dispatch }) => {
    dispatch(getApiErrorsLoadingAction(true));
    dispatch(getApiErrorsErrorAction(false));

    try {
      await archiveErrorsRequest(errorIds);
    } catch (error) {
      dispatch(getApiErrorsErrorAction(true));
    }

    dispatch(getApiErrorsLoadingAction(false));
  },
);
