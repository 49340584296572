import { Box } from '@mui/material';
import { UPDropdownItem } from 'components/dropdown';
import { CoefficientsPerWorkerClass, CoefficientsPerWorkerClassOptions } from 'constants/CoefficientsPerWorkerclass';
import { isEqual, toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getBlogByIDAction, getBlogByIDPayloadAction, getBlogsAction } from 'store/actions/blog-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { getParitairComitesAction } from 'store/actions/paritair-comite-actions';
import { blogsSelector, blogToEditSelector } from 'store/selectors/blog-selector';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { paritairComitesSelector } from 'store/selectors/paritair-comite-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  BLOGS__ADD_NEW_BLOG,
  BLOGS__BLOGS,
  BLOGS__EMPLOYEE,
  COMPANY__COMPANY,
  GENERAL__ALL,
} from 'translations/constants';
import { getUpDropdownItemsFromEnum } from 'utils/helpers';
import AddNewBlogModal from './AddNewBlogModal';
import BlogsGridView from './BlogsGridView';
import { AddNewBlogButton } from './styles';

const Blogs = (): JSX.Element => {
  const [t] = useTranslation();
  const allBlogs = useAppSelector(blogsSelector);
  const blogToEditRedux = useAppSelector(blogToEditSelector);
  const [openAddNewBlogModal, setOpenNewBlogModal] = useState<boolean>(false);
  const workerclassList = useMemo(() => CoefficientsPerWorkerClassOptions(), []);
  const dispatch = useAppDispatch();
  const activeItem = useAppSelector(activeMenuItemSelector);
  const paritairComites = useAppSelector(paritairComitesSelector);
  const userLanguage = toNumber(useAppSelector(profileSelector).languageId);
  const [PCDropdownItems, setPCDropdownItems] = useState<UPDropdownItem[]>([]);

  const userDropdownItems = useMemo(
    () => [
      {
        value: 1,
        label: t(COMPANY__COMPANY),
      },
      {
        value: 2,
        label: t(BLOGS__EMPLOYEE),
      },
      {
        value: 3,
        label: t(GENERAL__ALL),
      },
    ],

    [t],
  );

  useEffect(() => {
    if (activeItem !== t(BLOGS__BLOGS)) {
      dispatch(setMenuStatusAction(t(BLOGS__BLOGS)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    dispatch(getBlogsAction({ type: 'all' }));
  }, [dispatch]);

  const generatePCItems = useCallback(() => {
    const auxPC = paritairComites.map((pc: any) => {
      let name = '';
      if (userLanguage === 1) name = pc.dutchName;
      else if (userLanguage === 2) name = pc.frenchName;
      else if (userLanguage === 3) name = pc.englishName;

      return {
        value: pc.id,
        label: name,
      };
    });
    const newItems = [
      {
        value: 0,
        label: t(GENERAL__ALL),
      },
      ...auxPC,
    ];
    if (!isEqual(newItems, PCDropdownItems)) {
      setPCDropdownItems(newItems);
    }
  }, [PCDropdownItems, paritairComites, t, userLanguage]);

  useEffect(() => {
    dispatch(getParitairComitesAction());
  }, [dispatch]);

  useEffect(() => {
    if (paritairComites) generatePCItems();
  }, [generatePCItems, paritairComites]);

  const handleEditBlog = (id: number) => {
    dispatch(getBlogByIDAction({ id }));
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <AddNewBlogButton
            text={`+ ${t(BLOGS__ADD_NEW_BLOG)}`}
            onClick={() => setOpenNewBlogModal(true)}
            className="employee-action-button"
          />
        </Box>
        {openAddNewBlogModal && (
          <AddNewBlogModal
            onClose={() => setOpenNewBlogModal(false)}
            workerclassList={[{ value: 'All', label: t(GENERAL__ALL) }, ...workerclassList]}
            userDropdownItems={userDropdownItems}
            PCDropdownItems={PCDropdownItems}
          />
        )}
        {blogToEditRedux && (
          <AddNewBlogModal
            onClose={() => {
              dispatch(getBlogByIDPayloadAction(null));
            }}
            workerclassList={[{ value: 'All', label: t(GENERAL__ALL) }, ...workerclassList]}
            userDropdownItems={userDropdownItems}
            PCDropdownItems={PCDropdownItems}
            blogToEdit={blogToEditRedux}
          />
        )}
      </Box>

      {allBlogs.length !== 0 && <BlogsGridView blogs={allBlogs} onEdit={handleEditBlog} />}
    </>
  );
};

export default Blogs;
