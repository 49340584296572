import { Box, Chip } from '@mui/material';
import { DatesBoxStyled, DisplayDatesStyled } from 'components/day-picker/styles';
import { UPInputLabel } from 'components/input/styles';
import moment from 'moment';
import { getWeekDaysAbbreviations } from 'utils/helpers';
import { UPDayPickerDisplayProps } from './types';

const UPDayPickerDisplay = (props: UPDayPickerDisplayProps): JSX.Element => {
  const { day, label } = props;
  const date = moment(day);

  const weekDaysNames = getWeekDaysAbbreviations();

  const findRightTranslation = (dayAbr: string) => {
    switch (dayAbr) {
      case 'Mon':
        return weekDaysNames[0];
      case 'Tue':
        return weekDaysNames[1];
      case 'Wed':
        return weekDaysNames[2];
      case 'Thu':
        return weekDaysNames[3];
      case 'Fri':
        return weekDaysNames[4];
      case 'Sat':
        return weekDaysNames[5];
      default:
        return weekDaysNames[6];
    }
  };

  const item = { day: findRightTranslation(date.format('ddd')), date: date.format('DD.MM') };
  return (
    <Box sx={{ width: '100%' }}>
      <UPInputLabel>{label || 'Dates'}</UPInputLabel>
      <DatesBoxStyled sx={{ backgroundColor: '#FAFAFA' }}>
        <DisplayDatesStyled>
          <Chip
            label={
              <div>
                <b>{item.day}</b> {item.date}
              </div>
            }
          />
        </DisplayDatesStyled>
      </DatesBoxStyled>
    </Box>
  );
};

export default UPDayPickerDisplay;
