import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { UPDropdownItem } from 'components/dropdown';
import { IApiErrorGetResponse } from 'types/errors';
import { ApiErrorFilters } from 'types/errors/ApiErrorFilters';

export const getApiErrorsRequest = async (filters: ApiErrorFilters): Promise<IApiErrorGetResponse> => {
  const response: AxiosResponse<IApiErrorGetResponse> = await getApi().post(`ApiError/GetErrors`, filters);
  return response.data;
};

export const archiveErrorsRequest = async (errorIds: number[]): Promise<void> => {
  await getApi().post(`ApiError/Archive`, errorIds);
};

export const getErrorTypesRequest = async (): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(`ApiError/GetErrorTypes`);
  return response.data;
};
