import { Box, InputLabel, styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';

export const UPDatepickerContainerStyled = styled(Box)<{ error?: boolean }>(({ theme, error }) => ({
  '.MuiOutlinedInput-root': {
    fontSize: '14px',
    fontWeight: '800',
    width: '150px',
    cursor: 'pointer',
    '.MuiOutlinedInput-input': {
      cursor: 'default',
    },
  },
  ...(error && {
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.error.main,
    },
    '.MuiOutlinedInput-root': {
      color: theme.palette.error.main,
      fontSize: '14px',
      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
        },
      },
    },
  }),
}));

export const UPDatepickerHelperText = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));

export const CustomPickersDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: '10%',
  backgroundColor: theme.palette.info.light,
  '&.Mui-selected': {
    color: theme.palette.common.white,
    transition: 'none',
    backgroundColor: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const CustomWeekDisplay = styled(Box)<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
  ...(isSelected && {
    backgroundColor: theme.palette.primary.main,
  }),
  ...(!isSelected && {
    backgroundColor: '#B8DBC7',
  }),
  color: 'white',
  display: 'inline',
  fontSize: '13px',
  borderRadius: '5px',
  padding: '10px',
  margin: '3px',
}));
