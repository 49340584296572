import { Box, Typography } from '@mui/material';
import { toNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { CONTRACTS__FETCH_MORE } from 'translations/constants';
import UPButton from 'components/button';
import theme from 'theme';
import { AvailabilitiesAlertStyled } from 'modules/availabilities/employee-availabilities/green-bar/styles';
import { directlyCardToCopyAction, selectedContractsAction, shouldCopyAction } from 'store/actions/contract-actions';
import { totalContractCountSelector, getShouldCopyContractsSelector } from 'store/selectors/contracts-selector';
import { GridHeader, GridCard } from '..';
import { sortedContracts } from '../helpers';
import { CardsDivider, GridColumn, GridContainer } from '../styles';
import { IContractData, IContractsGridViewProps, IDayProps } from '../types';
import PasteHereCard from './PasteHereCard';

const ContractsGridView = (props: IContractsGridViewProps): JSX.Element => {
  const {
    contracts,
    onChangeContracts,
    setOpenConfirmModal,
    onPasteContracts,
    selectedContractsMaxDate,
    days,
    onChangeWeek,
    onClickEditCard,
    onClickFillSlot,
    onDeleteCheckedContracts,
    copyWeekly,
    companyId,
    year,
    weekNumber,
    filters,
    getMoreContracts,
  } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const copy = useAppSelector(getShouldCopyContractsSelector);
  const contractPageDetails = useAppSelector(totalContractCountSelector);
  const [selectedWeeklyId, setSelectedWeeklyId] = useState<string>('');
  const [onlyDailies, setOnlyDailies] = useState<boolean>(false);
  const [displayFetchButton, setDisplayFetchButton] = useState<boolean>(false);

  const onChangeCardCheck = useCallback(
    contractId => {
      const changedContracts = contracts.map((card: IContractData) => {
        if (card.contractId === contractId) {
          if (card.weeklyId && !card.checked) {
            setSelectedWeeklyId(card.weeklyId);
          } else {
            setSelectedWeeklyId('');
          }
          return {
            ...card,
            checked: !card.checked,
          };
        }
        return card;
      });
      const checkedContracts = changedContracts.filter((c: IContractData) => c.checked);
      if (checkedContracts.length > 0 && !checkedContracts[0].weeklyId) {
        setOnlyDailies(true);
      }
      dispatch(selectedContractsAction({ selectedContracts: checkedContracts }));
      onChangeContracts(changedContracts);
    },
    [contracts, dispatch, onChangeContracts],
  );

  const checkADayContracts = (date: Date, checked: boolean) => {
    const updatedContracts = contracts.map((c: IContractData) => {
      const cDate = new Date(c.date);
      if (
        cDate.getDate() === date.getDate() &&
        cDate.getMonth() === date.getMonth() &&
        !c.employee?.inactive &&
        !c.weeklyId
      )
        return {
          ...c,
          checked,
        };
      return c;
    });
    const checkedContracts = updatedContracts.filter((c: IContractData) => c.checked);
    if (checkedContracts.length > 0 && !checkedContracts[0].weeklyId) {
      setOnlyDailies(true);
    }
    if (checkedContracts.length === 0 && copy) {
      dispatch(shouldCopyAction({ copy: false }));
    }
    dispatch(selectedContractsAction({ selectedContracts: checkedContracts }));
    onChangeContracts(updatedContracts);
  };

  const onCheckDay = (date: Date, isDayChecked: boolean) => {
    days.forEach((day: IDayProps) => {
      if (day.date.getDate() === date.getDate() && day.date.getMonth() === date.getMonth()) {
        checkADayContracts(date, isDayChecked);
      }
    });
  };

  useEffect(() => {
    const checkedContracts = contracts.filter((c: IContractData) => c.checked);
    if (checkedContracts.length === 0) {
      setSelectedWeeklyId('');
      setOnlyDailies(false);
    }
  }, [contracts]);

  const getContract = useCallback(
    (firstActiveContractId, contract) => {
      return (
        <Fragment key={contract.id}>
          {firstActiveContractId === contract.id && <CardsDivider />}
          <GridCard
            onlyDailies={onlyDailies}
            selectedWeeklyId={selectedWeeklyId}
            data={contract}
            onClickConfirm={setOpenConfirmModal}
            copyEnabled={copy}
            onChangeCardCheck={onChangeCardCheck}
            onClickEditCard={onClickEditCard}
            onClickFillSlot={onClickFillSlot}
            onDeleteCheckedContracts={onDeleteCheckedContracts}
            companyId={companyId}
            year={year}
            weekNumber={weekNumber}
            filters={filters}
          />
        </Fragment>
      );
    },
    [
      setOpenConfirmModal,
      copy,
      onChangeCardCheck,
      onClickEditCard,
      onClickFillSlot,
      onDeleteCheckedContracts,
      selectedWeeklyId,
      onlyDailies,
      companyId,
      year,
      weekNumber,
      filters,
    ],
  );

  useEffect(() => {
    copyWeekly(selectedWeeklyId.includes('weekly'));
  }, [selectedWeeklyId, copyWeekly]);

  const onPasteHereClick = useCallback(
    (newDate: Date) => {
      onPasteContracts(newDate);
      dispatch(directlyCardToCopyAction({ id: null }));
    },
    [dispatch, onPasteContracts],
  );

  const displayPasteHereCardOnColumn = useCallback(
    (contractDate: Date) => {
      const contractDay = contractDate.getDate();
      const contractMonth = contractDate.getMonth();
      if (selectedContractsMaxDate !== null) {
        const lastDate = selectedContractsMaxDate;
        const dayOfContractToCopy = new Date(lastDate).getDate();
        const monthOfContractToCopy = new Date(lastDate).getMonth();
        if (
          copy &&
          (contractMonth > monthOfContractToCopy ||
            (contractMonth === monthOfContractToCopy && dayOfContractToCopy < contractDay && selectedWeeklyId === ''))
        ) {
          return (
            <PasteHereCard
              date={contractDate}
              onClickPaste={newContractDate => {
                onPasteHereClick(newContractDate);
                dispatch(selectedContractsAction({ selectedContracts: [] }));
                dispatch(shouldCopyAction({ copy: false }));
              }}
            />
          );
        }
      }
      return '';
    },
    [selectedContractsMaxDate, copy, onPasteHereClick, dispatch, selectedWeeklyId],
  );

  const getContractsColumn = useCallback(
    (contractDate: Date) => {
      const contractsToDisplayFiltered = contracts.filter(
        (card: IContractData) =>
          new Date(card.date).getDate() === contractDate.getDate() &&
          new Date(card.date).getMonth() === contractDate.getMonth() &&
          new Date(card.date).getFullYear() === contractDate.getFullYear(),
      );
      let firstActiveContractId = 0;
      const currentDate = new Date().getDate();
      const currentHour = new Date().getHours();

      const contractsToDisplay = sortedContracts(contractsToDisplayFiltered);

      if (contractDate.getDate() === currentDate) {
        const firstActiveContract = contracts.find((card: IContractData) => {
          const contractStartHour = toNumber(card.startHour.split(':')[0]);
          if (contractStartHour > currentHour) {
            return true;
          }
          return false;
        });

        if (firstActiveContract) {
          firstActiveContractId = firstActiveContract.id;
        }
      }
      return !copy ? (
        <Box sx={{ minWidth: '188px' }}>
          {contractsToDisplay.map((c: IContractData) => getContract(firstActiveContractId, c))}
        </Box>
      ) : (
        <GridColumn>
          <>
            {contractsToDisplay.map((c: IContractData) => getContract(firstActiveContractId, c))}
            {displayPasteHereCardOnColumn(contractDate)}
          </>
        </GridColumn>
      );
    },
    [contracts, copy, displayPasteHereCardOnColumn, getContract],
  );

  const generateColumns = useCallback(() => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {days.map(d => (
          <Fragment key={Math.random()}>{getContractsColumn(d.date)}</Fragment>
        ))}
      </Box>
    );
  }, [getContractsColumn, days]);

  useEffect(() => {
    setDisplayFetchButton(
      toNumber(companyId) > 0 && contractPageDetails?.numberOfResults >= 35 && contracts.length > 0,
    );
  }, [companyId, contractPageDetails, contracts]);

  return (
    <GridContainer>
      <GridHeader
        onCheckDay={onCheckDay}
        days={days}
        onChangeWeek={onChangeWeek}
        isWeeklySelected={selectedWeeklyId !== ''}
      />
      {generateColumns()}
      {displayFetchButton && (
        <AvailabilitiesAlertStyled severity="success" icon={false}>
          <Typography mr={theme.spacing(2)} fontWeight="bold">
            {`${contractPageDetails.pageSize * contractPageDetails.pageIndex} / ${contractPageDetails.numberOfResults}`}
          </Typography>

          <UPButton text={t(CONTRACTS__FETCH_MORE)} onClick={() => getMoreContracts()} />
        </AvailabilitiesAlertStyled>
      )}
    </GridContainer>
  );
};

export default ContractsGridView;
