import { ErrorsInterface, IContractData, IContractsCountPerWeek, ICostEstimation } from 'modules/contracts/types';
import { RootState } from 'store';
import { ContractPageState } from 'store/reducers/contracts-reducer';
import { DimonaContractKeyPair } from 'types/contract/DimonaDetails';
import IContractTotals from 'types/contract/IContractTotals';

export const getContractsSelector = (state: RootState): IContractData[] => state.contracts.filteredContracts;
export const getContractsListViewSelector = (state: RootState): IContractData[] => state.contracts.listViewContracts;
export const getCostEstimationSelector = (state: RootState): ICostEstimation[] => state.contracts.costEstimation;
export const validateEditContractErrorMessageSelector = (state: RootState): string =>
  state.contracts.validateContractErrorMessage;
export const validateEditContractErrorMessageRowSelector = (state: RootState): number =>
  state.contracts.validateContractErrorMessageRow;
export const getContractsErrors = (state: RootState): ErrorsInterface[] => state.contracts.errors;
export const getSelectedContractsSelector = (state: RootState): IContractData[] => state.contracts.selectedContracts;
export const getShouldCopyContractsSelector = (state: RootState): boolean => state.contracts.shouldCopy;
export const getDirectlyCardToCopySelector = (state: RootState): number | null => state.contracts.directlyCardToCopy;
export const deleteContractsSuccessActionSelector = (state: RootState): boolean =>
  state.contracts.deleteContractsSuccess;
export const getContractCountSelector = (state: RootState): IContractTotals => state.contracts.contractsCount;
export const getContractsCountFromPreviousWeeksSelector = (state: RootState): IContractsCountPerWeek[] =>
  state.contracts.contractsFromPreviousWeeks;
export const contractsMultipleConfirmationResponseSelector = (state: RootState): any =>
  state.contracts.multipleConfirmationResponse;
export const contractsMultipleConfirmationSuccessSelector = (state: RootState): boolean =>
  state.contracts.multipleConfirmationSuccess;
export const getDimonaNumberSuccessSelector = (state: RootState): DimonaContractKeyPair =>
  state.contracts.getDimonaSuccess;
export const getDimonaNumberErrorSelector = (state: RootState): DimonaContractKeyPair => state.contracts.getDimonaError;

export const tooManyContractsSelector = (state: RootState): boolean => state.contracts.tooManyContracts;
export const totalContractCountSelector = (state: RootState): ContractPageState => state.contracts.contractPageDetails;

export const contractsLoadingSelector = (state: RootState): boolean => state.contracts.loading;
