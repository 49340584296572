import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyFinancialRequest, postCompanyFinancialRequest } from 'api/requests/company-requests';
import {
  COMPANY_FINANCIAL__CLEAR,
  COMPANY_FINANCIAL__GENERAL_LOADING,
  COMPANY_FINANCIAL__GET,
  COMPANY_FINANCIAL__PUT,
  COMPANY_FINANCIAL__SET_FINANCIAL,
  COMPANY_FINANCIAL__SET_FINANCIAL_ERROR,
  COMPANY_FINANCIAL__SET_FINANCIAL_UPDATE_SUCCESSFUL,
} from 'store/constants';
import IPaymentInformation from 'types/company/financial/IPaymentInformation';
import { setBicAction } from './financial-actions';

export const setCompanyFinancialRequestsLoadingAction = createAction<boolean>(COMPANY_FINANCIAL__GENERAL_LOADING);

export const setCompanyFinancialAction = createAction<IPaymentInformation>(COMPANY_FINANCIAL__SET_FINANCIAL);
export const setCompanyFinancialErrorAction = createAction<boolean>(COMPANY_FINANCIAL__SET_FINANCIAL_ERROR);
export const setCompanyFinancialUpdateSuccessfulAction = createAction<boolean>(
  COMPANY_FINANCIAL__SET_FINANCIAL_UPDATE_SUCCESSFUL,
);

export const setCompanyFinancialErrorMessageAction = createAction<any>('');

export const getCompanyFinancialAction = createAsyncThunk(COMPANY_FINANCIAL__GET, async (id: number, thunkApi) => {
  thunkApi.dispatch(setCompanyFinancialRequestsLoadingAction(true));
  thunkApi.dispatch(setCompanyFinancialErrorAction(false));

  try {
    const response = await getCompanyFinancialRequest(id);
    thunkApi.dispatch(setCompanyFinancialAction(response));
    thunkApi.dispatch(setBicAction(response.bic));
  } catch (error) {
    thunkApi.dispatch(setCompanyFinancialErrorAction(true));
  }
  thunkApi.dispatch(setCompanyFinancialRequestsLoadingAction(false));
});

export const putCompanyFinancialAction = createAsyncThunk(
  COMPANY_FINANCIAL__PUT,
  async (props: { companyId: string; data: IPaymentInformation }, thunkApi) => {
    thunkApi.dispatch(setCompanyFinancialRequestsLoadingAction(true));
    thunkApi.dispatch(setCompanyFinancialErrorAction(false));
    thunkApi.dispatch(setCompanyFinancialErrorMessageAction(null));
    try {
      await postCompanyFinancialRequest({ companyId: props.companyId, data: props.data });
      thunkApi.dispatch(setCompanyFinancialUpdateSuccessfulAction(true));
    } catch (error: any) {
      thunkApi.dispatch(setCompanyFinancialErrorAction(true));
      thunkApi.dispatch(setCompanyFinancialUpdateSuccessfulAction(false));
      thunkApi.dispatch(setCompanyFinancialErrorMessageAction(error.response.data.detail));
    }

    thunkApi.dispatch(setCompanyFinancialRequestsLoadingAction(false));
  },
);
export const clearCompanyFinancialAction = createAction<void>(COMPANY_FINANCIAL__CLEAR);
