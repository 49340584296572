import { Alert, styled } from '@mui/material';

export const AlertStyled = styled(Alert)(() => ({
  color: '#5F6161',
  background: '#DBEDE3',
  height: '80px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'fixed',
  bottom: '0',
  width: 'calc(100% - 80px - 48px)',
  '.MuiAlert-message': {
    display: 'flex',
    alignItems: 'center',
  },
}));
