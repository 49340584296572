import { Backdrop, Box, Typography } from '@mui/material';
import UPCheckbox from 'components/app-container/checkbox-with-label';
import UPButton from 'components/button';
import UPMultipleCalendarDayPicker from 'components/multiple-calendar-day-picker/UPMultipleCalendarDayPicker';
import UPSwitch from 'components/switch';
import { AvailabilityPeriod } from 'constants/AvailabilityPeriod';
import { DayChangeComponent } from 'modules/availabilities/date-change-components';
import { ModalTitle } from 'modules/documents/styles';
import { ModalActionButtonsContainer } from 'modules/payroll-settings/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import {
  AVAILABILITIES__AFTERNOON,
  AVAILABILITIES__AVAILABILITY,
  AVAILABILITIES__EVENING,
  AVAILABILITIES__FULL_DAY_AVAILABLE,
  AVAILABILITIES__MORNING,
  GENERAL__CANCEL_BUTTON,
  GENERAL__SAVE_BUTTON,
} from 'translations/constants';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/Close.svg';
import { FillAvailabilityModalProps } from '../types';
import {
  AvailabilityDatePickerContainer,
  FillAvailabilityModalContainer,
  FillAvailabilityModalStyled,
  ModalCloseIcon,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitleRow,
} from './styles';

const FillAvailabilityModal = (props: FillAvailabilityModalProps): JSX.Element => {
  const { onClose, day, onChangeDays, calendarDays, changeDayOnMobile, availability, onChangeAvailability } = props;
  const [t] = useTranslation();

  const [modalValues, setModalValues] = useState(() => {
    const firstCheckedDay = calendarDays.find(d => d.checked);
    const dayAvailability = availability?.days.find(d => d.day === firstCheckedDay?.date.date());
    if (!dayAvailability) {
      return {
        fullDay: false,
        morning: false,
        afternoon: false,
        evening: false,
      };
    }
    return {
      fullDay:
        dayAvailability.isAvailableMorning &&
        dayAvailability.isAvailableAfternoon &&
        dayAvailability.isAvailableEvening,
      morning: dayAvailability.isAvailableMorning,
      afternoon: dayAvailability.isAvailableAfternoon,
      evening: dayAvailability.isAvailableEvening,
    };
  });

  const onChangeAvailabilityStatus = (period: AvailabilityPeriod) => (value: boolean) => {
    setModalValues({
      ...modalValues,
      [period]: value,
    });
  };

  const onSave = () => {
    onChangeAvailability({
      ...availability,
      days: availability.days.map(d => {
        const calendarDayNumbers = calendarDays.filter(e => e.checked).map(e => e.dayNumber);
        if (calendarDayNumbers.includes(d.day)) {
          return {
            ...d,
            isAvailableMorning: modalValues.morning,
            isAvailableAfternoon: modalValues.afternoon,
            isAvailableEvening: modalValues.evening,
            isSubmitted: false,
          };
        }
        return d;
      }),
      isSubmitted: false,
    });
    onClose();
  };

  const onToggleSwitch = () => {
    setModalValues({
      fullDay: !modalValues.fullDay,
      morning: !modalValues.fullDay,
      afternoon: !modalValues.fullDay,
      evening: !modalValues.fullDay,
    });
  };

  return (
    <Backdrop open sx={{ zIndex: 2 }}>
      <FillAvailabilityModalContainer>
        <FillAvailabilityModalStyled open onClose={onClose}>
          <ModalContainer>
            <ModalHeader>
              <ModalTitleRow>
                <ModalTitle>{t(AVAILABILITIES__AVAILABILITY)}</ModalTitle>
                <ModalCloseIcon onClick={onClose}>
                  <CloseIcon fill={theme.palette.secondary.contrastText} />
                </ModalCloseIcon>
              </ModalTitleRow>
              <AvailabilityDatePickerContainer>
                <UPMultipleCalendarDayPicker day={day} calendarDays={calendarDays} onChangeDays={onChangeDays} />
              </AvailabilityDatePickerContainer>
              <DayChangeComponent day={day} onChangeDay={changeDayOnMobile} />
            </ModalHeader>
            <ModalContent>
              <Box display="flex">
                <Typography marginRight={theme.spacing(3)}>{t(AVAILABILITIES__FULL_DAY_AVAILABLE)}</Typography>
                <UPSwitch checked={modalValues.fullDay} onChange={onToggleSwitch} />
              </Box>
              <Box>
                <UPCheckbox
                  label={
                    <>
                      <strong>{t(AVAILABILITIES__MORNING)}</strong>{' '}
                      {`(${availability?.availabilityIntervals?.morningInterval || '07:00 - 12:00'})`}
                    </>
                  }
                  value={modalValues.morning}
                  handleChange={() => onChangeAvailabilityStatus(AvailabilityPeriod.MORNING)(!modalValues.morning)}
                />
              </Box>
              <Box>
                <UPCheckbox
                  label={
                    <>
                      <strong>{t(AVAILABILITIES__AFTERNOON)}</strong>{' '}
                      {`(${availability?.availabilityIntervals?.afternoonInterval || '12:00 - 17:00'})`}
                    </>
                  }
                  value={modalValues.afternoon}
                  handleChange={() => onChangeAvailabilityStatus(AvailabilityPeriod.AFTERNOON)(!modalValues.afternoon)}
                />
              </Box>
              <Box>
                <UPCheckbox
                  label={
                    <>
                      <strong>{t(AVAILABILITIES__EVENING)}</strong>{' '}
                      {`(${availability?.availabilityIntervals?.eveningInterval || '17:00 - 22:00'})`}
                    </>
                  }
                  value={modalValues.evening}
                  handleChange={() => onChangeAvailabilityStatus(AvailabilityPeriod.EVENING)(!modalValues.evening)}
                />
              </Box>
            </ModalContent>
            <ModalFooter>
              <ModalActionButtonsContainer>
                <UPButton text={t(GENERAL__SAVE_BUTTON)} onClick={onSave} />
                <UPButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
              </ModalActionButtonsContainer>
            </ModalFooter>
          </ModalContainer>
        </FillAvailabilityModalStyled>
      </FillAvailabilityModalContainer>
    </Backdrop>
  );
};

export default FillAvailabilityModal;
