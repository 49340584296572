import { Alert, Box, styled } from '@mui/material';

export const AvailabilitiesAlertStyled = styled(Alert)(({ theme }) => ({
  color: '#5F6161',
  height: '80px',
  maxHeight: '10vh',
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  bottom: '0',
  width: 'calc(100% - 80px - 48px)',
  overflowY: 'hidden',
  '.MuiAlert-message': {
    display: 'flex',
    alignItems: 'center',
  },
  [theme.breakpoints.down(600)]: {
    width: 'calc(100% - 48px)',
  },
}));

export const AvailabilitiesBarText = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down(600)]: {
    display: 'none',
  },
}));

export const SubmitAvailabilityButtonContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(600)]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
