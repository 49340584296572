import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addCompanyPayrollRequest,
  getCompanyPayrollRequest,
  updateCompanyPayrollRequest,
} from 'api/requests/company-requests';
import {
  COMPANY__ADD_PAYROLL,
  COMPANY__ADD_PAYROLL_ERROR,
  COMPANY__ADD_PAYROLL_SUCCESS,
  COMPANY__CLEAR_PAYROLL,
  COMPANY__GET_PAYROLL,
  COMPANY__SET_PAYROLL,
  COMPANY__SET_PAYROLL_ERROR,
  COMPANY__UPDATE_PAYROLL,
  COMPANY__UPDATE_PAYROLL_ERROR,
  COMPANY__UPDATE_PAYROLL_SUCCESS,
  PAYROLL__GENERAL_LOADING,
} from 'store/constants';
import ICompanyPayrollGeneral from 'types/company/payroll/ICompanyPayrollGeneral';

export const setPayrollRequestsLoadingAction = createAction<boolean>(PAYROLL__GENERAL_LOADING);

export const setPayrollAction = createAction<ICompanyPayrollGeneral>(COMPANY__SET_PAYROLL);
export const getPayrollErrorAction = createAction<boolean>(COMPANY__SET_PAYROLL_ERROR);

export const getPayrollAction = createAsyncThunk(COMPANY__GET_PAYROLL, async (companyId: number, thunkApi) => {
  thunkApi.dispatch(getPayrollErrorAction(false));
  thunkApi.dispatch(setPayrollRequestsLoadingAction(true));
  try {
    const response = await getCompanyPayrollRequest(companyId);
    thunkApi.dispatch(setPayrollAction(response));
  } catch (error) {
    thunkApi.dispatch(getPayrollErrorAction(true));
  }
  thunkApi.dispatch(setPayrollRequestsLoadingAction(false));
});

export const addPayrollSuccessAction = createAction<boolean>(COMPANY__ADD_PAYROLL_SUCCESS);
export const addPayrollErrorAction = createAction<boolean>(COMPANY__ADD_PAYROLL_ERROR);

export const addPayrollAction = createAsyncThunk(
  COMPANY__ADD_PAYROLL,
  async (payroll: ICompanyPayrollGeneral, thunkApi) => {
    thunkApi.dispatch(setPayrollRequestsLoadingAction(true));
    thunkApi.dispatch(addPayrollErrorAction(false));
    thunkApi.dispatch(addPayrollSuccessAction(false));
    try {
      await addCompanyPayrollRequest(payroll);
      thunkApi.dispatch(addPayrollSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(addPayrollErrorAction(true));
    }
    thunkApi.dispatch(setPayrollRequestsLoadingAction(false));
  },
);

export const updatePayrollSuccessAction = createAction<boolean>(COMPANY__UPDATE_PAYROLL_SUCCESS);
export const updatePayrollErrorAction = createAction<boolean>(COMPANY__UPDATE_PAYROLL_ERROR);

export const updatePayrollAction = createAsyncThunk(
  COMPANY__UPDATE_PAYROLL,
  async (payroll: ICompanyPayrollGeneral, thunkApi) => {
    thunkApi.dispatch(setPayrollRequestsLoadingAction(true));
    thunkApi.dispatch(updatePayrollErrorAction(false));
    thunkApi.dispatch(updatePayrollSuccessAction(false));
    try {
      await updateCompanyPayrollRequest(payroll);
      thunkApi.dispatch(updatePayrollSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(updatePayrollErrorAction(true));
    }
    thunkApi.dispatch(setPayrollRequestsLoadingAction(false));
  },
);

export const clearCompanyPayrollAction = createAction<void>(COMPANY__CLEAR_PAYROLL);
