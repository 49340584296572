import { createAction } from '@reduxjs/toolkit';
import { CompanyAvailabilityFilterType } from 'constants/CompanyAvailabilityFilterType';
import {
  COMPANY_AVAILABILITY_FILTERS__CLEAR,
  COMPANY_AVAILABILITY_FILTERS__RESET,
  COMPANY_AVAILABILITY_FILTERS__SET,
} from 'store/constants';

export const setCompanyAvailabilityFiltersAction = createAction(
  COMPANY_AVAILABILITY_FILTERS__SET,
  (props: { filterType: CompanyAvailabilityFilterType; value: string | number }[]) => {
    const payloadArray = props.map(prop => {
      return {
        filterType: prop.filterType,
        value: prop.value,
      };
    });

    return {
      payload: payloadArray,
    };
  },
);

export const clearCompanyAvailabilityFiltersAction = createAction<void>(COMPANY_AVAILABILITY_FILTERS__CLEAR);
export const resetCompanyAvailabilityFiltersAction = createAction<void>(COMPANY_AVAILABILITY_FILTERS__RESET);
