import { useTranslation } from 'react-i18next';
import { FUNCTION_TYPE__PER_HOUR, FUNCTION_TYPE__PER_DAY, FUNCTION_TYPE__PER_MONTH } from 'translations/constants';

const useFunctionTypesTranslation = (functionType: number) => {
  const [t] = useTranslation();
  const getTranslation = () => {
    switch (functionType) {
      case 1:
        return t(FUNCTION_TYPE__PER_HOUR);
      case 2:
        return t(FUNCTION_TYPE__PER_DAY);
      case 3:
        return t(FUNCTION_TYPE__PER_MONTH);
      default:
        return '';
    }
  };
  return getTranslation();
};
export default useFunctionTypesTranslation;
