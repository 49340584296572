import { isNaN, toNumber } from 'lodash';
import moment from 'moment';
import { treatAsUTC } from 'utils/helpers';
import { Document, DocumentCategories } from './types';

const generateExactTime = (docDate: Date) => {
  let hours = new Date(docDate).getHours();
  let minutes = new Date(docDate).getMinutes();
  if (hours === 0 && minutes === 0) {
    hours = new Date(Date.now()).getHours();
    minutes = new Date(Date.now()).getMinutes();
  }
  let auxHours: string;
  let auxMinutes: string;
  if (hours / 10 < 1) auxHours = `0${hours}:`;
  else auxHours = `${hours}:`;
  if (minutes / 10 < 1) auxMinutes = `0${minutes}`;
  else auxMinutes = `${minutes}`;
  return `${auxHours}${auxMinutes}`;
};

export const generateDocumentsToSend = (documents: Document[]) => {
  const auxDocuments = documents.map((doc: Document) => {
    const docDate = doc.date;
    return {
      file: doc.file,
      category: toNumber(doc.type),
      date: moment(new Date(docDate)).format('DD/MM/YYYY'),
      hour: generateExactTime(docDate),
      periodStart: doc.periodStart,
      periodEnd: doc.periodEnd,
      employeeId: doc.employeeId,
    };
  });
  return auxDocuments;
};

export const formatDateToString = (date: Date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const isDateValid = (d: any) => {
  return !isNaN(Date.parse(d));
};

export const needsPeriod = (type: number): boolean => {
  const categoriesWithValabilityDates = [
    DocumentCategories.ID,
    DocumentCategories.STUDENT_INSCRIPTION_PROOF,
    DocumentCategories.VCA,
    DocumentCategories.MEDICAL_CHECK,
    DocumentCategories.SAFETY_PASSPORT,
    DocumentCategories.DRIVING_LICENCE,
    DocumentCategories.WORK_PERMIT,
  ];
  return categoriesWithValabilityDates.map(categoryId => Number(categoryId)).includes(type);
};

export const needsEmployeedropdown = (type: number): boolean => {
  const categoriesWithEmployeeDropdown = [
    DocumentCategories.ID,
    DocumentCategories.WORK_POST_FILE,
    DocumentCategories.STUDENT_INSCRIPTION_PROOF,
    DocumentCategories.VCA,
    DocumentCategories.MEDICAL_CHECK,
    DocumentCategories.SAFETY_PASSPORT,
    DocumentCategories.BANK_CARD,
    DocumentCategories.DRIVING_LICENCE,
    DocumentCategories.WORK_PERMIT,
  ];
  return categoriesWithEmployeeDropdown.map(categoryId => Number(categoryId)).includes(type);
};

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d);
};

export const isPeriodValid = (startDate: Date, endDate: Date, docType: number): boolean => {
  if (needsPeriod(docType) && (!isValidDate(startDate) || !isValidDate(endDate))) {
    return false;
  }

  if (startDate && endDate) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const difference = (treatAsUTC(endDate).getTime() - treatAsUTC(startDate).getTime()) / millisecondsPerDay;

    const numberOfDaysInAYear = 365;
    if (docType !== 6) {
      return difference >= 0;
    }

    const b = difference >= 0 && difference <= numberOfDaysInAYear;
    return b;
  }
  // we don't care if not both dates are filled in
  return true;
};
