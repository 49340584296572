import { Avatar, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { inviteEmployeeRequest } from 'api/requests/employee-requests';
import UPButton from 'components/button';
import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import useReasonForEmploymentTranslation from 'hooks/useReasonForEmploymentTranslation';
import { toNumber } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { impersonateEmployeeAction } from 'store/actions/auth-actions';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  EMPLOYEES__EXTRA,
  EMPLOYEES__FLEXI,
  EMPLOYEES__INVITE_EMPLOYEE_COPY_INVITATION_LINK,
  EMPLOYEES__LABOURER,
  EMPLOYEES__PERMANENT,
  EMPLOYEES__RESEND_INVITATION,
  EMPLOYEES__SERVANT,
  EMPLOYEES__STUDENT,
  GENERAL__EDIT,
  GENERAL__IMPERSONATE,
} from 'translations/constants';
import Lang from 'types/Lang';
import { getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { ReactComponent as CopyToClipboard } from '../../assets/icons/CopyToClipboard.svg';
import { ReactComponent as EditEmployeeAvatar } from '../../assets/icons/EditEmployeeAvatar.svg';
import { ReactComponent as EmployeeRequiresAction } from '../../assets/icons/EmployeeRequiresAction.svg';
import { ReactComponent as Inactive } from '../../assets/icons/Inactive.svg';
import { ReactComponent as Invited } from '../../assets/icons/Invited.svg';
import { ReactComponent as SendInviteEmail } from '../../assets/icons/SendInviteEmail.svg';
import {
  CardContent,
  ContactDetails,
  EmployeeCardStyled,
  EmployeeName,
  InactiveContainer,
  InviteActionsContainer,
  InvitedContainer,
  MoreContent,
  PersonalDetails,
  RequiresActionContainer,
} from './styles';
import { ReactComponent as InfoIcon } from '../../assets/icons/EmployeeInfoIcon.svg';
import { EmployeeCardProps } from './types';

const EmployeeCard = (props: EmployeeCardProps) => {
  const { employee, onClickEdit, companyId, setCopied, setInviteSent } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(profileSelector);
  const isAdmin = useAppSelector(profileSelector).role === CompanyRole.ADMIN;
  const isManager = ['Company manager', 'Store/location manager', 'HR manager'].includes(profile?.role);
  const [displayMore, setDisplayMore] = useState<boolean>(false);

  const isInvited = employee.age === 0;
  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const reasonForEmployment = useReasonForEmploymentTranslation(employee.reasonForEmployment);

  const findRightTranslation = (workerclass: string) => {
    switch (workerclass) {
      case 'Student':
        return t(EMPLOYEES__STUDENT);
      case 'Flexi-job':
        return t(EMPLOYEES__FLEXI);
      case 'Extra':
        return t(EMPLOYEES__EXTRA);
      case 'Labourer':
        return t(EMPLOYEES__LABOURER);
      case 'Permanent':
        return t(EMPLOYEES__PERMANENT);
      default:
        return t(EMPLOYEES__SERVANT);
    }
  };

  const getCodeForWorkerClass = (workerclass: string) => {
    switch (workerclass) {
      case 'Student':
        return 'STUD';
      case 'Flexi-job':
        return 'FLEX';
      case 'Extra':
        return 'EXT';
      case 'Labourer':
        return 'LAB';
      case 'Permanent':
        return 'PERM';
      default:
        return 'BED';
    }
  };

  const ResendInviteEmail = () => {
    const employeeDetails = {
      firstname: employee.firstName,
      lastname: employee.lastName,
      contractType: getCodeForWorkerClass(employee.workerclass),
      emailAddress: employee.emailAddress,
      languageId: languageOptions.find(option => option.label === employee.language)?.value,
    };

    inviteEmployeeRequest({ employee: employeeDetails, companyId });
    setInviteSent(true);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(employee.invitationLink);
    setCopied(true);
  };

  const onClickImpersonate = useCallback(
    (employeeId: number) => {
      dispatch(impersonateEmployeeAction({ employeeId, companyId: toNumber(companyId) }));
    },
    [companyId, dispatch],
  );

  return (
    <EmployeeCardStyled
      onClick={() => {
        if (!isInvited) setDisplayMore(prevState => !prevState);
      }}
    >
      {!employee.canSkipApproval && (
        <RequiresActionContainer>
          <EmployeeRequiresAction />
        </RequiresActionContainer>
      )}
      {employee.inactive && (
        <InactiveContainer>
          <Inactive />
        </InactiveContainer>
      )}
      {isInvited && (
        <InvitedContainer>
          <Invited />
        </InvitedContainer>
      )}
      {displayMore ? (
        <MoreContent>
          <EmployeeName>{employee.companyName}</EmployeeName>
          <PersonalDetails>
            {employee.nationality} | {employee.language.toUpperCase()}
          </PersonalDetails>
          <PersonalDetails>
            {employee.address.streetName} {employee.address.streetNumber}, Bus no. {employee.address.busNumber},{' '}
            {employee.address.postalCode}, {employee.address.city}
          </PersonalDetails>
          <PersonalDetails>{reasonForEmployment}</PersonalDetails>
          {isAdmin && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <UPButton text={t(GENERAL__EDIT)} onClick={() => onClickEdit(employee.id)} />
              <Box sx={{ marginLeft: '8px' }}>
                <UPButton text={t(GENERAL__IMPERSONATE)} onClick={() => onClickImpersonate(employee.id)} />
              </Box>
            </Box>
          )}

          {isManager && (
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <InfoIcon onClick={() => onClickEdit(employee.id)} />
            </Box>
          )}
        </MoreContent>
      ) : (
        <CardContent>
          {!employee.pictureUpload.includes(null) ? (
            <div>
              <Avatar src={employee.pictureUpload} sx={{ width: '80px', height: '80px' }} />
            </div>
          ) : (
            <EditEmployeeAvatar />
          )}
          <EmployeeName>
            {employee.firstName} {employee.lastName}
          </EmployeeName>
          {!isInvited && (
            <PersonalDetails>
              {employee.gender} | {employee.age} | {findRightTranslation(employee.workerclass)}
            </PersonalDetails>
          )}
          <ContactDetails>{employee.emailAddress}</ContactDetails>
          <ContactDetails>{employee.mobileNumber}</ContactDetails>
          {isInvited && (
            <InviteActionsContainer>
              <Box>
                <Tooltip title={t(EMPLOYEES__RESEND_INVITATION)} placement="top">
                  <SendInviteEmail onClick={ResendInviteEmail} />
                </Tooltip>
              </Box>
              <Box sx={{ ml: '5px' }}>
                <Tooltip title={t(EMPLOYEES__INVITE_EMPLOYEE_COPY_INVITATION_LINK)} placement="top">
                  <CopyToClipboard onClick={handleCopyToClipboard} />
                </Tooltip>
              </Box>
            </InviteActionsContainer>
          )}
        </CardContent>
      )}
    </EmployeeCardStyled>
  );
};

export default EmployeeCard;
