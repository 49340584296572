export const findDuplicates = (
  data: { keyId: string; jointCommissionId: string }[],
): { keyId: string; jointCommissionId: string }[] => {
  const seen = new Set<string>();
  const duplicates: { keyId: string; jointCommissionId: string }[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const obj of data) {
    const key = `${obj.keyId}-${obj.jointCommissionId}`;
    if (seen.has(key)) {
      duplicates.push(obj);
    } else {
      seen.add(key);
    }
  }

  return duplicates;
};

export const arrayToString = (data: { keyId: string; jointCommissionId: string }[]): string => {
  return data.map(obj => `${obj.keyId} - ${obj.jointCommissionId}`).join(', ');
};
