import { Box } from '@mui/material';
import UPInput from 'components/input';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getExternalReferenceAction, putCompanyExternalReferences } from 'store/actions/company-actions';
import {
  IEditableCompanyExternalReferences,
  ICompanyExternalReferences,
} from 'types/company/ICompanyExternalReferences';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  companyExternalReferenceLoadingSelector,
  salaryEngineReferenceIdSelector,
} from 'store/selectors/company-selectors';
import UPButton from 'components/button';
import {
  COMPANY_INTEGRATION__SALARY_ENGINE_REFERENCE,
  COMPANY_INTEGRATION__ENVIRONMENT_KEY,
  COMPANY_INTEGRATION__API_KEY,
  GENERAL__SAVE_BUTTON,
  GENERAL__SAVE_AND_NEXT_BUTTON,
  GENERAL__CANCEL_BUTTON,
} from 'translations/constants';
import { ButtonsContainer, SectionContainer } from '../styles';

const EditIntegration = (): JSX.Element => {
  const [t] = useTranslation();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { handleSubmit } = useForm<ICompanyExternalReferences>();

  const references = useAppSelector(salaryEngineReferenceIdSelector);
  const loading = useAppSelector(companyExternalReferenceLoadingSelector);

  const [environmentKey, setEnvironmentKey] = useState<string>('');
  const [externalApiAccessKey, setExternalApiAccessKey] = useState<string>('');
  const [saveAndNextClicked, setSaveAndNextClicked] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    navigate(`/company/${companyId}/managers`);
  }, [companyId, navigate]);

  useEffect(() => {
    dispatch(getExternalReferenceAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (references) {
      setEnvironmentKey(references.environmentKey);
      setExternalApiAccessKey(references.externalApiAccessKey);
    }
  }, [references]);

  const onSaveExternalReferences: SubmitHandler<any> = useCallback(() => {
    const data: IEditableCompanyExternalReferences = {
      environmentKey,
      externalApiAccessKey,
    };
    dispatch(putCompanyExternalReferences({ companyId, data }));
  }, [companyId, dispatch, environmentKey, externalApiAccessKey]);

  useEffect(() => {
    if (saveAndNextClicked) {
      setSaveAndNextClicked(false);
      setTimeout(() => {
        navigate(`/company/${companyId}/structure`);
      }, 1000);
    }
  }, [navigate, saveAndNextClicked, companyId, dispatch]);

  const onSaveAndNext = useCallback(() => {
    handleSubmit(onSaveExternalReferences)();
    setSaveAndNextClicked(true);
  }, [handleSubmit, onSaveExternalReferences]);

  return (
    <SectionContainer>
      <Box sx={{ width: '40%' }}>
        <UPInput
          value={references?.salaryEngineReferenceId}
          label={t(COMPANY_INTEGRATION__SALARY_ENGINE_REFERENCE)}
          placeholder={t(COMPANY_INTEGRATION__SALARY_ENGINE_REFERENCE)}
          helperText=""
          onChange={null}
          required
          disabled
        />
      </Box>
      <Box sx={{ width: '40%' }}>
        <UPInput
          value={environmentKey}
          label={t(COMPANY_INTEGRATION__ENVIRONMENT_KEY)}
          placeholder={t(COMPANY_INTEGRATION__ENVIRONMENT_KEY)}
          helperText=""
          onChange={e => setEnvironmentKey(e.target.value)}
        />
      </Box>
      <Box sx={{ width: '40%' }}>
        <UPInput
          value={externalApiAccessKey}
          label={t(COMPANY_INTEGRATION__API_KEY)}
          placeholder={t(COMPANY_INTEGRATION__API_KEY)}
          helperText=""
          onChange={e => setExternalApiAccessKey(e.target.value)}
        />
      </Box>
      <ButtonsContainer sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex' }}>
          <Box>
            <UPButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={() => handleSubmit(onSaveExternalReferences)()}
              disabled={loading}
            />
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            <UPButton text={t(GENERAL__SAVE_AND_NEXT_BUTTON)} onClick={onSaveAndNext} disabled={loading} />
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} outlined />
          </Box>
        </Box>
      </ButtonsContainer>
    </SectionContainer>
  );
};

export default EditIntegration;
