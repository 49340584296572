import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { COMPANY_INFORMATION__COMPANY_NAME, GENERAL__NAME } from 'translations/constants';
import { RequiredActionsReportData } from 'types/reporting';

const RequiredActionsReport = (props: { items: RequiredActionsReportData[] }) => {
  const { items } = props;
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [, setRowOnHover] = useState<number>();

  const columnDef: GridColDef[] = [
    { field: 'companyId', headerName: '', hide: true },
    {
      field: 'companyName',
      headerName: t(COMPANY_INFORMATION__COMPANY_NAME),
      width: 400,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'employeeName',
      headerName: t(GENERAL__NAME),
      width: 400,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'Actions',
      headerAlign: 'center',
      width: 400,
      align: 'center',
      renderCell: params => {
        return (
          <UPButton
            onClick={() => navigate(`/employees/${params.row.companyId}/${params.row.employeeName}`)}
            text="Go to employee"
          />
        );
      },
    },
  ];
  return (
    <Box>
      <UPDataGridContainer sx={{ height: '70vh' }}>
        <UPDataGrid
          columns={columnDef}
          rows={items}
          setRowOnHover={setRowOnHover}
          getRowId={r => `ra_${r.companyId}_${r.employeeId}`}
        />
      </UPDataGridContainer>
    </Box>
  );
};

export default RequiredActionsReport;
