import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import {
  IAvailability,
  IAvailabilityForWeek,
  ICompanyAvailabilityFilters,
  IGetAvailabilitiesProps,
  IRequestAvailabilityFilters,
} from 'types/availabilities';

export const getAvailabilitiesRequest = async (props: IGetAvailabilitiesProps): Promise<IAvailability> => {
  const { employeeId, companyId, year, month } = props;
  let requestUrl = `/Availability/${employeeId}/${companyId}/${year}/${month}`;
  if (props.userId) {
    requestUrl += `/${props.userId}`;
  }
  const response: AxiosResponse<IAvailability> = await getApi().get(requestUrl);
  return response.data;
};

export const putAvailabilitiesRequest = async (props: { availability: IAvailability }): Promise<boolean> => {
  const { availability } = props;
  const response: AxiosResponse<boolean> = await getApi().put('/Availability', availability);
  return response.data;
};

export const getAvailabilityForWeekRequest = async (
  props: ICompanyAvailabilityFilters,
): Promise<IAvailabilityForWeek> => {
  const { companyId, year, week, locationId, workerclass, name, age, page, pageSize } = props;
  const response: AxiosResponse<IAvailabilityForWeek> = await getApi().post(
    `/Availability/GetForWeek`,
    {
      year,
      week,
      locationId,
      workerclass,
      name,
      age,
      page,
      pageSize,
    },
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const requestAvailabilityRequest = async (props: {
  companyId: number;
  month: number;
  employeeId?: number;
}): Promise<void> => {
  const { companyId, month, employeeId } = props;
  await getApi().post(`/Availability/Request`, {
    companyId,
    month,
    employeeId,
  });
};

export const requestAvailabilityFilteredRequest = async (props: IRequestAvailabilityFilters): Promise<void> => {
  const { companyId, month, locationId, workerclass, age } = props;
  await getApi().post(`/Availability/RequestFiltered`, {
    companyId,
    month,
    locationId,
    workerclass,
    age,
  });
};

export const exportCompanyAvailabilityRequest = async (props: {
  companyId: number;
  locationId: number;
  workerclass: string;
  name: string;
  age: string;
  week: number;
  year: number;
}): Promise<any> => {
  const { companyId, locationId, workerclass, name, age, week, year } = props;
  const response = await getApi().post(
    `/DataExport/ExportCompanyAvailability`,
    {
      companyId,
      locationId,
      workerclass,
      name,
      age,
      week,
      year,
    },
    {
      responseType: 'blob',
    },
  );
  return response.data;
};
