import { Box } from '@mui/system';
import UPTab from 'components/tab';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import WageCalculation from 'modules/payroll-settings/service-codes/ServiceCodes';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { PayrollCostCenters, PayrollFunctions, PayrollGeneral, PayrollWageExemptions } from '.';
import {
  COMPANY_PAYROLL__COST_CENTERS,
  COMPANY_PAYROLL__FUNCTIONS,
  COMPANY_PAYROLL__GENERAL,
  COMPANY_PAYROLL__WAGE_EXEMPTIONS,
  WAGE_COMPONENTS__COST_DECLARATION_HEADER,
  WAGE_COMPONENTS__SERVICE_CODES,
} from '../../../translations/constants';
import { SectionContainer } from '../styles';
import PayrollCostDeclarations from './PayrollCostDeclarations';

const EditPayroll = (): JSX.Element => {
  const [t] = useTranslation();
  const user = useAppSelector(profileSelector);
  const { companyId } = useParams();

  const PayrollTabs = [
    t(COMPANY_PAYROLL__GENERAL),
    t(COMPANY_PAYROLL__FUNCTIONS),
    t(COMPANY_PAYROLL__COST_CENTERS),
    t(COMPANY_PAYROLL__WAGE_EXEMPTIONS),
    t(WAGE_COMPONENTS__COST_DECLARATION_HEADER),
    t(WAGE_COMPONENTS__SERVICE_CODES),
  ];

  const PayrollTabsForCompanyManager = [
    t(COMPANY_PAYROLL__FUNCTIONS),
    t(COMPANY_PAYROLL__COST_CENTERS),
    t(COMPANY_PAYROLL__WAGE_EXEMPTIONS),
  ];

  switch (user.role) {
    case CompanyRole.ADMIN:
      return (
        <SectionContainer sx={{ paddingTop: '0px' }}>
          <UPTab sections={PayrollTabs}>
            <PayrollGeneral />
            <PayrollFunctions />
            <PayrollCostCenters />
            <PayrollWageExemptions />
            <PayrollCostDeclarations />
            <WageCalculation companyId={toNumber(companyId)} />
          </UPTab>
        </SectionContainer>
      );
    case CompanyRole.COMPANY_MANAGER:
      return (
        <SectionContainer sx={{ paddingTop: '0px' }}>
          <UPTab sections={PayrollTabsForCompanyManager}>
            <PayrollFunctions />
            <PayrollCostCenters />
            <PayrollWageExemptions />
          </UPTab>
        </SectionContainer>
      );
    default:
  }
  return <Box>You do not have acces to this page</Box>;
};

export default EditPayroll;
