import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import UPInput from 'components/input';
import { PayrollAddNewItemButton } from 'modules/companies/payroll/styles';
import { SectionContainer } from 'modules/companies/styles';
import { ChangeEvent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getParitairComitesAction } from 'store/actions/paritair-comite-actions';
import { paritairComitesSelector } from 'store/selectors/paritair-comite-selectors';
import {
  GENERAL__EDIT,
  GENERAL__SEARCH,
  PARITAIR_COMITE__ADD_PARITAIR_COMITE,
  PARITAIR_COMITE__DUTCH,
  PARITAIR_COMITE__ENGLISH,
  PARITAIR_COMITE__FRENCH,
} from 'translations/constants';
import { IParitairComite } from 'types/paritair-comite';
import { escapeRegExp } from 'utils/helpers';
import { ParitairComiteGridContainer, SearchContainer, TopSectionContainer } from './styles';

const ParitairComite = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const paritairComites = useAppSelector(paritairComitesSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState<IParitairComite[]>([]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = paritairComites.filter(row => {
      return Object.keys(row).some((field: string) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const renderNumberCell = (params: GridRenderCellParams<any, any, any>) => {
    const convertedValue = params.value.toString();
    const yySubcategory = convertedValue.substring(3, 5);
    const zzSubcategory = convertedValue.substring(5);
    if (!yySubcategory.match('00') && zzSubcategory.match('00')) {
      return <Box sx={{ paddingLeft: '20px' }}>{params.value}</Box>;
    }
    if (
      (yySubcategory.match('00') && !zzSubcategory.match('00')) ||
      (!yySubcategory.match('00') && !zzSubcategory.match('00'))
    ) {
      return <Box sx={{ paddingLeft: '40px' }}>{params.value}</Box>;
    }
    return <Box>{params.value}</Box>;
  };

  useEffect(() => {
    dispatch(getParitairComitesAction());
  }, [dispatch]);

  useEffect(() => {
    if (paritairComites) {
      setRows(paritairComites);
    }
  }, [paritairComites]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'number',
      headerName: 'Number',
      width: 300,
      renderCell: renderNumberCell,
      sortable: false,
    },
    { field: 'dutchName', headerName: t(PARITAIR_COMITE__DUTCH), width: 300 },
    { field: 'frenchName', headerName: t(PARITAIR_COMITE__FRENCH), width: 300 },
    { field: 'englishName', headerName: t(PARITAIR_COMITE__ENGLISH), width: 300 },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <UPButton
              onClick={() =>
                navigate(`/payroll-settings/paritair-comite/paritair-comite-details/${params.row.id}/general`)
              }
              text={t(GENERAL__EDIT)}
              outlined
            />
          );
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <TopSectionContainer>
        <PayrollAddNewItemButton
          text={`+ ${t(PARITAIR_COMITE__ADD_PARITAIR_COMITE)}`}
          onClick={() => navigate('/payroll-settings/paritair-comite/paritair-comite-details/new/general')}
        />
        <SearchContainer>
          <UPInput
            value={searchText}
            onChange={(event: ChangeEvent<HTMLInputElement>) => requestSearch(event.target.value)}
            label=""
            placeholder={`${t(GENERAL__SEARCH)}...`}
            icon="search"
          />
        </SearchContainer>
      </TopSectionContainer>
      <ParitairComiteGridContainer>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          withCheckboxes={false}
          getRowId={row => row.id}
        />
      </ParitairComiteGridContainer>
    </SectionContainer>
  );
};

export default ParitairComite;
