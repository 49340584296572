import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { CloseAlertIcon } from 'modules/companies/styles';
import moment from 'moment';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import localStorageService from 'services/local-storage-service';
import { useAppDispatch, useAppSelector } from 'store';
import { changeCompaniesAction, stopImpersonateAction } from 'store/actions/auth-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { onboardingSelector } from 'store/selectors/employee-selector';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { AllowedCompaniesForAvailability } from 'constants/CustomFeatureEnabledForCompanies';
import theme from 'theme';
import {
  GENERAL__CLICK_HERE_TO_CANCEL,
  GENERAL__YOU_ARE_LOGGED_IN_AS,
  HEADER__WEEK,
  MENU_LOGOUT,
  MENU__AVAILABILITIES,
  MENU__DASHBOARD,
  MENU__DOCS,
  MENU__EMPLOYEES,
  MENU__MY_PROFILE,
  MENU__PASSWORD,
} from 'translations/constants';
import { getInitials } from 'utils/helpers';
import { ReactComponent as AvailabilitiesIcon } from '../../assets/icons/AvailabilitiesIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as DocsIcon } from '../../assets/icons/Docs.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/icons/Employees.svg';
import { ReactComponent as ListViewContractsGreen } from '../../assets/icons/ListViewContractsGreen.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/Logo.svg';
import {
  ActiveItemStyled,
  AlertStyled,
  AppBar,
  CompanyDropdownStyled,
  CompanyNameStyled,
  HeaderBox,
  IconBox,
  IconButtonStyled,
  MenuLink,
  MenuStyled,
  NavBarButtonStyled,
  NavbarCloseIconStyled,
  UserName,
  WeekStyled,
} from './styles';

const AppHeader = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(profileSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);
  const activeItemFormatted = activeItem.split('- ').join('');
  const employeeOnboarding = useAppSelector(onboardingSelector);
  const [currentCompanyName, setCurrentCompanyName] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [companyId, setCompanyId] = useState<string | number>(user?.currentCompany);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [navBarOpen, setNavBarOpen] = useState(false);

  const allowedCompanies = AllowedCompaniesForAvailability;

  const menuItemsPrimary = useMemo(() => {
    const menuItems = [
      {
        name: t(MENU__DASHBOARD),
        icon: <DashboardIcon fill={theme.palette.primary.main} />,
        route: '/dashboard',
      },
    ];

    if (
      user?.role === CompanyRole.EMPLOYEE &&
      (allowedCompanies.includes(toNumber(companyId)) || toNumber(companyId) > 1000)
    ) {
      menuItems.push({
        name: t(MENU__AVAILABILITIES).split('- ').join(''),
        icon: <AvailabilitiesIcon fill={theme.palette.primary.main} />,
        route: '/availabilities',
      });
    }

    menuItems.push(
      {
        name: t(MENU__EMPLOYEES),
        icon: <EmployeesIcon fill={theme.palette.primary.main} />,
        route: '/employees',
      },
      {
        name: t(MENU__DOCS),
        icon: <DocsIcon fill={theme.palette.primary.main} />,
        route: '/docs',
      },
    );

    return menuItems;
  }, [t, user?.role, allowedCompanies, companyId]);

  const menuItemsSecondary = useMemo(
    () => [
      {
        name: t(MENU__MY_PROFILE),
        route: '/profile',
      },
      {
        name: t(MENU__PASSWORD),
        route: '/change-password',
      },
      {
        name: t(MENU_LOGOUT),
        route: '/logout',
      },
    ],
    [t],
  );

  const open = Boolean(anchorEl);
  const impersonationToken = localStorageService.getItem<string>('impersonationToken');
  const isImpersonated = localStorageService.getItem<boolean>('isImpersonated');
  const companiesOptions: UPDropdownItem[] = user?.userCompanies;
  const week = moment().format('W');
  const year = moment().format('Y');

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleNavBar = (navbarIsOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setNavBarOpen(navbarIsOpen);
  };

  useEffect(() => {
    if (impersonationToken !== null && isImpersonated !== null) {
      setAlertOpen(true);
    } else {
      setAlertOpen(false);
    }
  }, [impersonationToken, isImpersonated]);

  useEffect(() => {
    companiesOptions?.forEach((company: UPDropdownItem) => {
      if (company.value === user?.currentCompany) {
        setCurrentCompanyName(company.label);
      }
    });
  }, [companiesOptions, user]);

  const onChangeCompany = useCallback(
    event => {
      setCompanyId(event.target.value);
      dispatch(changeCompaniesAction(toNumber(event.target.value)));
    },
    [dispatch],
  );

  const listNavbarItems = () => (
    <Box
      style={{ paddingLeft: 15, marginTop: 40 }}
      sx={{ width: 300 }}
      role="presentation"
      onClick={toggleNavBar(false)}
    >
      <List>
        {menuItemsPrimary.map(item => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(setMenuStatusAction(item.name));
                navigate(item.route);
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {menuItemsSecondary.map(item => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              onClick={() => {
                dispatch(setMenuStatusAction(item.name));
                navigate(item.route);
              }}
            >
              {item.name === t(MENU__MY_PROFILE) ? (
                <ListItemAvatar>
                  <UserName>{user && getInitials(user?.firstName, user?.lastName)}</UserName>
                </ListItemAvatar>
              ) : (
                <ListItemIcon />
              )}
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconBox>
          <LogoIcon />
        </IconBox>
        <HeaderBox>
          <Box sx={{ display: 'flex' }}>
            <ActiveItemStyled variant="h6" noWrap>
              {activeItemFormatted}
            </ActiveItemStyled>
            {user?.role &&
              user?.role?.includes('manager') &&
              (companiesOptions?.length > 1 ? (
                <CompanyDropdownStyled>
                  <UPDropdown
                    items={companiesOptions}
                    onChange={onChangeCompany}
                    value={companyId.toString()}
                    label=""
                    disableUnderline
                  />
                </CompanyDropdownStyled>
              ) : (
                <CompanyNameStyled noWrap>{currentCompanyName}</CompanyNameStyled>
              ))}
          </Box>
          {!alertOpen && (
            <WeekStyled>
              {t(HEADER__WEEK)} {week}/{year}
            </WeekStyled>
          )}
          {alertOpen && (
            <AlertStyled
              severity="success"
              icon={false}
              variant="filled"
              action={
                <CloseAlertIcon
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    setAlertOpen(false);
                    dispatch(stopImpersonateAction());
                  }}
                >
                  <CloseIcon fill="white" />
                </CloseAlertIcon>
              }
              onClose={() => setAlertOpen(false)}
            >
              <Typography
                onClick={() => dispatch(stopImpersonateAction())}
                sx={{ fontSize: '14px', cursor: 'pointer' }}
                align="center"
              >
                {t(GENERAL__YOU_ARE_LOGGED_IN_AS)}&nbsp;{user.firstName} {user.lastName}.{' '}
                {t(GENERAL__CLICK_HERE_TO_CANCEL)}.
              </Typography>
            </AlertStyled>
          )}
          {!employeeOnboarding && (
            <IconButtonStyled
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <UserName>{user && getInitials(user?.firstName, user?.lastName)}</UserName>
            </IconButtonStyled>
          )}
          {!employeeOnboarding && (
            <NavBarButtonStyled
              onClick={toggleNavBar(true)}
              size="small"
              aria-controls={navBarOpen ? 'nav-bar' : undefined}
              aria-haspopup="true"
              aria-expanded={navBarOpen ? 'true' : undefined}
            >
              <ListViewContractsGreen />
            </NavBarButtonStyled>
          )}
          <MenuStyled
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {menuItemsSecondary.map(item => (
              <MenuLink
                key={item.name}
                onClick={() => {
                  dispatch(setMenuStatusAction(item.name));
                  navigate(item.route);
                }}
              >
                <MenuItem>{item.name}</MenuItem>
              </MenuLink>
            ))}
          </MenuStyled>
          <Drawer sx={{ zIndex: '1300' }} anchor="right" open={navBarOpen} onClose={toggleNavBar(false)}>
            <NavbarCloseIconStyled onClick={toggleNavBar(false)}>
              <CloseIcon fill={theme.palette.secondary.contrastText} />
            </NavbarCloseIconStyled>
            {listNavbarItems()}
          </Drawer>
        </HeaderBox>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
