import { styled, Box, Modal, Typography } from '@mui/material';
import UPButton from 'components/button';

export const DeleteModalStyled = styled(Modal)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  zIndex: '5000',
  maxHeight: '500px',
  height: 'auto',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.up('sm')]: {
    width: '550px',
  },

  [theme.breakpoints.down(611)]: {
    width: '90%',
  },
}));

export const ModalContainer = styled(Box)(() => ({
  width: '100%',
  background: 'white',
  padding: '32px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
  maxHeight: '650px',
  overflowX: 'auto',
}));

export const DeleteModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'center',
}));

export const ModalActionContainer = styled(Box)(() => ({
  marginTop: '32px',
  display: 'flex',
  justifyContent: 'center',
  gap: '16px',
}));

export const ModalActionButton = styled(UPButton)(() => ({
  width: '100px',
}));
