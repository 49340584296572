import { DateFormat } from 'constants/DateFormat';
import { AvailabilitiesCalendarRowProps } from '../../types';
import AvailabilitiesCalendarDay from '../day';
import { AvailabilitiesCalendarRowContainer } from './styles';

const AvailabilitiesCalendarRow = (props: AvailabilitiesCalendarRowProps): JSX.Element => {
  const { firstDayOfWeek, sixRowsCalendar } = props;

  const weekDays = [firstDayOfWeek];
  for (let i = 1; i < 7; i++) {
    weekDays.push(firstDayOfWeek.clone().add(i, 'days'));
  }

  return (
    <AvailabilitiesCalendarRowContainer>
      {weekDays.map(day => (
        <AvailabilitiesCalendarDay key={day.format(DateFormat.FULL_DATE)} day={day} sixRowsCalendar={sixRowsCalendar} />
      ))}
    </AvailabilitiesCalendarRowContainer>
  );
};

export default AvailabilitiesCalendarRow;
