import { IconWithMargin } from 'modules/contracts/styles';
import { useTranslation } from 'react-i18next';
import {
  CONTRACTS__CLEAR_ALL,
  CONTRACTS__CONFIRM_MULTIPLE_ERROR,
  CONTRACTS__CONFIRM_SELECTED,
  CONTRACTS__DELETE_SELECTED,
  CONTRACTS__SELECTED,
} from 'translations/constants';
import './styles.scss';
import Tooltip from '@mui/material/Tooltip';
import { AlertStyled } from './styles';
import { BottomGreenBarProps } from './types';

const BottomGreenBar = (props: BottomGreenBarProps): JSX.Element => {
  const { numberOfItems, actions, onClearAll, onDelete, onCopy, total, error, copyWeekly } = props;
  const [t] = useTranslation();

  return (
    <AlertStyled severity="success" icon={false}>
      <div className="contracts-number">
        {copyWeekly ? 1 : numberOfItems} {t(CONTRACTS__SELECTED).toLocaleLowerCase()} |
      </div>
      {total && <div className="contracs-number">{total} |</div>}
      <button type="button" className="clear-all" onClick={onClearAll}>
        {t(CONTRACTS__CLEAR_ALL)}
      </button>
      <div className="icons-container">
        {actions.map(current => {
          if (error && current.content === t(CONTRACTS__CONFIRM_SELECTED)) {
            return (
              <Tooltip title={t(CONTRACTS__CONFIRM_MULTIPLE_ERROR)} arrow key={`${current.content} ${total}`}>
                <button
                  className="icon-container"
                  type="button"
                  onClick={current.content === t(CONTRACTS__DELETE_SELECTED) ? onDelete : onCopy}
                  key={current.content}
                >
                  <IconWithMargin>{current.icon}</IconWithMargin>
                  <div className="icon-content">{current.content}</div>
                </button>
              </Tooltip>
            );
          }
          return (
            <button
              className="icon-container"
              type="button"
              onClick={current.content === t(CONTRACTS__DELETE_SELECTED) ? onDelete : onCopy}
              key={current.content}
            >
              <IconWithMargin>{current.icon}</IconWithMargin>
              <div className="icon-content">{current.content}</div>
            </button>
          );
        })}
      </div>
    </AlertStyled>
  );
};

BottomGreenBar.defaultProps = {
  open: false,
  numberOfItems: 0,
  actions: {},
};

export default BottomGreenBar;
