import { InputLabel, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import UPButton from 'components/button';
import { ReactComponent as ContractIcon } from '../../assets/icons/ContractIcon.svg';
import { ReactComponent as AddVendorSlotIcon } from '../../assets/icons/AddVendorSlotIcon.svg';

export const GridContainer = styled(Box)(() => ({
  width: '100%',
  paddingBottom: '16px',
}));

export const Header = styled(Box)(() => ({
  height: '80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  borderRadius: '8px',
  border: 'none',
  background: 'white',
}));

export const HeaderCell = styled(Box, {
  shouldForwardProp: prop => prop !== 'highlight',
})<{ highlight?: boolean }>(({ theme, highlight }) => ({
  backgroundColor: 'white',
  ...(highlight && { background: theme.palette.primary.dark }),
  height: '64px',
  width: '172px',
  borderRadius: '8px',
  color: theme.palette.secondary.contrastText,
  padding: 8,
  margin: '0px 8px',
}));

export const DayOfWeek = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: theme.palette.secondary.contrastText,
  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
  },
  '.MuiCheckbox-root': {
    padding: 0,
  },
}));

export const NumberOfItems = styled(Box)(() => ({
  width: '32px',
  height: ' 20px',
  background: '#71B790',
  borderRadius: '16px',
  color: 'white',
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: '20px',
}));

export const GridCardStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'active' && prop !== 'hover' && prop !== 'confirmed',
})<{ active?: boolean; hover?: boolean; confirmed?: boolean }>(({ theme, active, hover, confirmed }) => ({
  // eslint-disable-next-line no-nested-ternary
  background: active ? 'white' : confirmed ? theme.palette.primary.dark : theme.palette.info.main,
  ...(active ? { border: '1px solid #DBEDE3' } : { border: '1px solid #FAFAFA' }),
  opacity: hover ? 0.5 : 1,
  borderRadius: '8px',
  padding: '8px 8px',
}));

export const ContractHours = styled(Box, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '20px',
  color: error ? theme.palette.error.main : theme.palette.primary.main,
  display: 'flex',
}));

export const EmployeeData = styled(Box)(() => ({
  fontSize: '14px',
  lineHeight: '20px',
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  flexDirection: 'row',
  margin: '10px 0px',
  height: '68px',
}));

export const EmployeeName = styled(Box, {
  shouldForwardProp: prop => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => ({
  fontSize: '14px',
  paddingLeft: '10px',
  color: active ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
  fontWeight: 'bold',
}));

export const EmployeeRole = styled(Box)(({ theme }) => ({
  fontSize: '14px',
  paddingLeft: '10px',
  color: theme.palette.secondary.main,
}));

export const GridColumn = styled(Box)(() => ({
  minWidth: '188px',
}));

export const AddNewContractButton = styled(UPButton)(() => ({
  margin: '16px 16px 16px 0px',
  height: '48px',
}));

export const CardsDivider = styled(Box)(({ theme }) => ({
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '50px',
  minWidth: '172px',
  marginBottom: '8px',
}));

export const CardOptions = styled(Box)(() => ({
  background: 'rgba(219, 237, 227, 0.5)',
  position: 'absolute',
  top: 0,
  left: 0,
  width: 'calc(100% - 16px)',
  height: 'calc(100% - 16px)',
  marginTop: '8px',
  marginLeft: '8px',
}));

export const PasteHereCardStyled = styled(Box)(() => ({
  background: 'rgba(219, 237, 227, 0.5)',
  border: `2px dotted`,
  borderColor: '#94C8AB',
  width: '172px',
  minHeight: '80px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '8px',
  cursor: 'pointer',
}));

export const PasteHereText = styled(Box)(() => ({
  color: '#94C8AB',
}));

export const CardActions = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

export const DimonaRefreshContainer = styled(Box)(() => ({
  marginLeft: '130px',
  marginTop: '60px',
  position: 'absolute',
}));

export const IconWithMargin = styled(Box)(() => ({
  marginLeft: '8px',
  cursor: 'pointer',
}));

export const WeeklyIcon = styled(Box)(() => ({
  position: 'relative',
  top: '10px',
  left: '-30px',
}));

export const DayPickerError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));

export const DayPickerHoursError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  marginLeft: '328px',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(768)]: {
    marginLeft: '5px',
  },
}));

export const DayPickerEndHourError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  display: 'flex',
  flexDirection: 'row-reverse',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(768)]: {
    marginLeft: '5px',
  },
}));

export const DayPickerHoursSecondError = styled(InputLabel)<{ isSlot?: boolean }>(({ theme, isSlot }) => ({
  fontSize: 12,
  marginLeft: '328px',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(768)]: {
    marginLeft: 'unset',
    position: 'unset',
    marginTop: 'unset',
    paddingRight: '10px',
  },
}));

export const DateDropdownContainer = styled(Box)(({ theme }) => ({
  marginRight: '15px',
  width: '50%',
  [theme.breakpoints.down(768)]: {
    minWidth: 'unset',
    marginRight: 'unset',
    width: '100%',
    paddingBottom: '10px',
  },
  [theme.breakpoints.up(768)]: {
    minWidth: '300px',
  },
}));

export const ConfirmDateDropdownContainer = styled(Box)(() => ({
  marginRight: '15px',
  width: '50%',
}));

export const DateAndNoShowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '33%',
  [theme.breakpoints.down(768)]: {
    width: '100%',
  },
}));

export const ConfirmHoursContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '66%',
  [theme.breakpoints.down(768)]: {
    width: '100%',
    flexWrap: 'wrap',
  },
}));

export const HourDropdownContainer = styled(Box)(({ theme }) => ({
  marginRight: '15px',
  minWidth: '93px',
  width: '25%',
  [theme.breakpoints.down(768)]: {
    marginRight: 'unset',
    padding: '10px',
    minWidth: 'unset',
    width: '50%',
  },
}));

export const ConfirmHourDateContainer = styled(Box)(({ theme }) => ({
  marginRight: '15px',
  minWidth: '93px',
  width: '100%',
  [theme.breakpoints.down(768)]: {
    marginRight: 'unset',
    padding: '10px',
    minWidth: 'unset',
    width: '100%',
  },
}));

export const EndWorkContainer = styled(Box)(({ theme }) => ({
  minWidth: '93px',
  width: 1 / 6,
  marginRight: '0px',
  [theme.breakpoints.down(768)]: {
    marginRight: 'unset',
    padding: '10px',
    minWidth: 'unset',
    width: '50%',
  },
}));

export const CompanyUserFilter = styled(Box)(() => ({
  width: '136px',
  display: 'inline-block',
  marginLeft: '8px',
}));

export const RowContainerForContractDate = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '24px',
  [theme.breakpoints.down(1500)]: {
    flexWrap: 'wrap',
  },
  [theme.breakpoints.down(768)]: {
    flexWrap: 'wrap',
  },
}));

export const NoShowBox = styled(Box)(() => ({
  display: 'flex',
  paddingTop: '10px',
  width: 1 / 2,
}));

export const RowContainerForNoShow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '24px',
  [theme.breakpoints.down(768)]: {
    flexDirection: 'column',
  },
}));

export const ModalTitleContainer = styled(Box)(() => ({
  display: 'flex',
}));

export const ContractDateContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down(768)]: {
    flexWrap: 'wrap',
  },
}));

export const ContractXIcon = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignSelf: 'flex-start',
  paddingTop: '53px',
  [theme.breakpoints.down(768)]: {
    paddingTop: '10px',
  },
}));

export const DeleteCostDeclarationButton = styled(Box)(() => ({
  cursor: 'pointer',
  display: 'flex',
  width: 'auto',
  paddingTop: '20px',
  alignItems: 'center',
}));

export const ConfirmServiceActionContainer = styled(Box)<{ hidden?: boolean }>(({ theme, hidden }) => ({
  display: hidden ? 'none' : 'block',
  cursor: 'pointer',
}));

export const ModalCloseIconStyled = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',

  [theme.breakpoints.down(768)]: {
    position: 'unset',
    margin: '0 0 0 auto',
  },
}));

export const NoShowModalStyled = styled(Modal)(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  maxHeight: '500px',
  height: 'auto',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.up('sm')]: {
    width: '550px',
  },

  [theme.breakpoints.down(611)]: {
    width: '90%',
  },
}));

export const NoShowModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
}));

const ITEM_HEIGHT = 49;
const ITEM_PADDING_TOP = 8;

export const StyledContractIcon = styled(ContractIcon)(({ theme }) => ({
  fill: 'white',
  'button:hover &': {
    fill: theme.palette.primary.main,
  },
}));

export const StyledAddVendorSlotIcon = styled(AddVendorSlotIcon)(({ theme }) => ({
  fill: 'white',
  'button:hover &': {
    fill: theme.palette.primary.main,
  },
}));
