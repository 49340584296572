import { useTranslation } from 'react-i18next';
import {
  REPORTING_CURRENT_MONTH_HOURS,
  REPORTING__PAST_MONTH_HOURS,
  REPORTING__PAST_YEAR_SELECTED_MONTH_HOURS,
} from 'translations/constants';
import {
  CompanyNameLabel,
  CurrentMonthHoursLabel,
  CustomTooltipWrapper,
  PastMonthHoursLabel,
  PastYearCurrentMonthHoursLabel,
} from './styles';

const CustomChartToolTip = ({ active, payload, label }: any) => {
  const [t] = useTranslation();

  if (active && payload && payload.length) {
    return (
      <CustomTooltipWrapper>
        <CompanyNameLabel className="label">{`${label}`}</CompanyNameLabel>
        <CurrentMonthHoursLabel>{`${t(REPORTING_CURRENT_MONTH_HOURS)}: ${payload[0].value}`}</CurrentMonthHoursLabel>
        <PastMonthHoursLabel>{`${t(REPORTING__PAST_MONTH_HOURS)}: ${payload[1].value}`}</PastMonthHoursLabel>
        <PastYearCurrentMonthHoursLabel>{`${t(REPORTING__PAST_YEAR_SELECTED_MONTH_HOURS)}: ${
          payload[2].value
        }`}</PastYearCurrentMonthHoursLabel>
      </CustomTooltipWrapper>
    );
  }

  return null;
};

export default CustomChartToolTip;
