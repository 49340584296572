import { createReducer } from '@reduxjs/toolkit';
import {
  setOvertimeLimitsAction,
  setUpdateOvertimeLimitsErrorAction,
  setUpdateOvertimeLimitsSuccessAction,
  setUpdateWorkhourSettingsErrorAction,
  setUpdateWorkhourSettingsSuccessAction,
  setWageCalculationAction,
  setWageCalculationErrorAction,
  setWageCalculationRequestsLoadingAction,
  setWorkhourSettingsAction,
} from 'store/actions/wage-calculation-actions';
import { IWageCalculationState } from 'types/wage-calculation';

const initialState: IWageCalculationState = {
  loading: false,
  wageCalculation: null,
  wageCalculationError: false,
  overtimeLimits: null,
  updateOvertimeLimitsErrorMessage: '',
  updateOvertimeLimitsSuccess: false,
  workhourSettings: null,
  updateWorkhourSettingsErrorMessage: '',
  updateWorkhourSettingsSuccess: false,
};

const wageCalculationReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setWageCalculationAction, (state, action) => ({ ...state, wageCalculation: action.payload }))
    .addCase(setWageCalculationRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setWageCalculationErrorAction, (state, action) => ({
      ...state,
      wageCalculationError: action.payload,
    }))
    .addCase(setOvertimeLimitsAction, (state, action) => ({
      ...state,
      overtimeLimits: action.payload,
    }))
    .addCase(setUpdateOvertimeLimitsErrorAction, (state, action) => ({
      ...state,
      updateOvertimeLimitsErrorMessage: action.payload,
    }))
    .addCase(setUpdateOvertimeLimitsSuccessAction, (state, action) => ({
      ...state,
      updateOvertimeLimitsSuccess: action.payload,
    }))
    .addCase(setWorkhourSettingsAction, (state, action) => ({
      ...state,
      workhourSettings: action.payload,
    }))
    .addCase(setUpdateWorkhourSettingsErrorAction, (state, action) => ({
      ...state,
      updateWorkhourSettingsErrorMessage: action.payload,
    }))
    .addCase(setUpdateWorkhourSettingsSuccessAction, (state, action) => ({
      ...state,
      updateWorkhourSettingsSuccess: action.payload,
    }));
});

export default wageCalculationReducer;
