import { Alert, FormControlLabel, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import UPButton from 'components/button';
import DeleteModal from 'components/delete-modal';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import UPFileInput from 'components/file-input';
import UPInput from 'components/input';
import UPSwitch from 'components/switch/UPSwitch';
import { CompanyFormat } from 'constants/CompanyFormat';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  checkIsParentCompanyAction,
  clearCompaniesAction,
  clearSaveCompanyStatusAction,
  clearUpdateCompanyStatusAction,
  companyErrorMessageAction,
  deleteCompanyAction,
  getChildCompaniesAction,
  getCompanyByIdAction,
  getParentCompaniesAction,
  saveCompanyAction,
  saveCompanyErrorAction,
  saveCompanySuccessAction,
  updateCompanyAction,
  updateCompanyErrorAction,
  updateCompanySuccessAction,
} from 'store/actions/company-actions';
import { getCountriesNamesAction } from 'store/actions/countries-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import {
  childCompaniesSelector,
  companyErrorMessageSelector,
  companyIdSelector,
  companySelector,
  getParentCompaniesSelector,
  saveCompanyErrorSelector,
  saveCompanySuccessSelector,
  updateCompanyErrorSelector,
  updateCompanySuccessSelector,
} from 'store/selectors/company-selectors';
import { countriesNames } from 'store/selectors/countries-selectors';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  COMPANY_INFORMATION__ACTIVE,
  COMPANY_INFORMATION__ASSIGN_CHILD_WARNING,
  COMPANY_INFORMATION__BUS_NUMBER,
  COMPANY_INFORMATION__CITY,
  COMPANY_INFORMATION__COMMERCIAL_NAME,
  COMPANY_INFORMATION__COMPANY_ADDED_SUCCESSFULLY,
  COMPANY_INFORMATION__COMPANY_FORMAT,
  COMPANY_INFORMATION__COMPANY_ID_NUMBER,
  COMPANY_INFORMATION__COMPANY_NAME,
  COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY,
  COMPANY_INFORMATION__LOGO,
  COMPANY_INFORMATION__LOGO_PLACEHOLDER,
  COMPANY_INFORMATION__NON_ACTIVE,
  COMPANY_INFORMATION__PARENT_COMPANY,
  COMPANY_INFORMATION__PARENT_COMPANY_LABEL,
  COMPANY_INFORMATION__POSTAL_CODE,
  COMPANY_INFORMATION__SMALL_COMPANY,
  COMPANY_INFORMATION__STREET_NAME,
  COMPANY_INFORMATION__STREET_NUMBER,
  COMPANY_INFORMATION__UNION_DELEGATION,
  GENERAL__CANCEL_BUTTON,
  GENERAL__COUNTRY,
  GENERAL__DELETE,
  GENERAL__DELETE_MESSAGE,
  GENERAL__ERROR_MESSAGE,
  GENERAL__SAVE_AND_NEXT_BUTTON,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  LOGIN__EMAIL_LABEL,
  MENU__COMPANIES,
  MENU__COMPANY,
} from 'translations/constants';
import Lang from 'types/Lang';
import { ICompany, ICompanyAddress } from 'types/company';
import {
  allowOnlyPositiveNumber,
  getInputErrorText,
  getUpDropdownItemsFromEnum,
  getUpDropdownItemsWithIdAsValueFromEnum,
} from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { countriesWithBelgiumFirst } from '../helpers';
import {
  ButtonsContainer,
  CloseAlertIcon,
  CompanyInformationLeftContainer,
  CompanyInformationRightContainer,
  InputBox,
  LeftSideButtons,
  LogoErrorText,
  RightSideButtons,
  SectionContainer,
  SubSectionContainer,
} from '../styles';

const AddressInitialState: ICompanyAddress = {
  id: 0,
  streetName: '',
  streetNumber: '',
  busNumber: '',
  city: '',
  postalCode: '',
  countryId: '',
};

const CompanyInitialState: ICompany = {
  id: 0,
  name: '',
  commercialName: '',
  address: AddressInitialState,
  companyIdNumber: null,
  companyFormat: '',
  email: '',
  smallCompany: false,
  unionDelegation: false,
  logo: null,
  logoURL: '',
  active: true,
  parentCompany: true,
  parentCompanyId: 0,
  isDeleted: false,
};

const EditCompanyInformation = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const userRole = useAppSelector(profileSelector).role;
  const countriesNamesOptions = useAppSelector(countriesNames);
  const userLanguageId = useAppSelector(profileSelector)?.languageId;
  const activeItem = useAppSelector(activeMenuItemSelector);
  const company = useAppSelector(companySelector);
  const updateCompanySuccess = useAppSelector(updateCompanySuccessSelector);
  const updateCompanyError = useAppSelector(updateCompanyErrorSelector);
  const saveCompanySuccess = useAppSelector(saveCompanySuccessSelector);
  const saveCompanyError = useAppSelector(saveCompanyErrorSelector);
  const newCompanyId = useAppSelector(companyIdSelector);
  const parentCompanies = useAppSelector(getParentCompaniesSelector);
  const childCompanies = useAppSelector(childCompaniesSelector);
  const companyErrorMessage = useAppSelector(companyErrorMessageSelector);

  const [countries, setCountries] = useState<UPDropdownItem[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [saveAndNextClicked, setSaveAndNextClicked] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string>('');
  const [logoError, setLogoError] = useState<boolean>(false);
  const [childCompanyError, setChildCompanyError] = useState<boolean>(false);

  const isAdmin = userRole === CompanyRole.ADMIN;

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const companyFormatOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsFromEnum(CompanyFormat);
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    getValues,
    setError,
    formState: { errors, isDirty },
  } = useForm<ICompany>({
    defaultValues: CompanyInitialState,
  });

  const watchParentCompany = watch('parentCompany');

  function getErrorMessage(customError: string): string {
    return typeof customError !== 'undefined' && customError ? customError : t(GENERAL__ERROR_MESSAGE);
  }

  useEffect(() => {
    if (userLanguageId) {
      const userLanguage = languageOptions.find(l => l?.value === userLanguageId).label;
      dispatch(getCountriesNamesAction(userLanguage));
    } else {
      dispatch(getCountriesNamesAction('en'));
    }
  }, [dispatch, languageOptions, userLanguageId]);

  useEffect(() => {
    if (countriesNamesOptions) {
      const mappedLocationNames = countriesNamesOptions.map(item => ({ value: item?.value, label: item?.label }));
      const auxCountries = countriesWithBelgiumFirst(mappedLocationNames);
      setCountries(auxCountries);
    }
  }, [countriesNamesOptions]);

  useEffect(() => {
    if (!isAdmin && activeItem !== t(MENU__COMPANY)) {
      dispatch(setMenuStatusAction(t(MENU__COMPANY)));
    }
    if (isAdmin && activeItem !== t(MENU__COMPANIES)) {
      dispatch(setMenuStatusAction(t(MENU__COMPANIES)));
    }
  }, [activeItem, dispatch, isAdmin, t]);

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(getCompanyByIdAction(toNumber(companyId)));
      dispatch(getChildCompaniesAction(toNumber(companyId)));
      dispatch(getParentCompaniesAction(toNumber(companyId)));
    } else {
      dispatch(clearUpdateCompanyStatusAction());
      dispatch(getParentCompaniesAction(0));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (company && companyId && newCompanyId === undefined) {
      if (company.parentCompanyId === null) {
        reset({ ...company, parentCompanyId: 0 });
      } else {
        reset(company);
      }

      setLogoUrl(company.logoURL);
    }
  }, [company, companyId, newCompanyId, reset, dispatch]);

  const resetParentCompanyId = useCallback(() => {
    const values = getValues();

    if (values.parentCompany) {
      setValue('parentCompanyId', 0);
    }
  }, [getValues, setValue]);

  useEffect(() => {
    if (saveCompanyError || saveCompanySuccess) {
      setAlertOpen(true);
      if (saveCompanySuccess) {
        resetParentCompanyId();
        setTimeout(() => {
          setAlertOpen(false);
          if (newCompanyId) {
            const url = saveAndNextClicked ? `/company/${newCompanyId}/contact` : `/company/${newCompanyId}/general`;
            dispatch(clearSaveCompanyStatusAction());
            navigate(url);
            setSaveAndNextClicked(false);
            dispatch(checkIsParentCompanyAction(toNumber(newCompanyId)));
          }
        }, 1000);
      }
    }
  }, [
    dispatch,
    navigate,
    saveAndNextClicked,
    saveCompanyError,
    saveCompanySuccess,
    newCompanyId,
    resetParentCompanyId,
  ]);

  useEffect(() => {
    if (updateCompanyError || updateCompanySuccess) {
      setAlertOpen(true);
      if (updateCompanySuccess) {
        resetParentCompanyId();
        dispatch(checkIsParentCompanyAction(toNumber(companyId)));
      }
      if (updateCompanySuccess && saveAndNextClicked) {
        setSaveAndNextClicked(false);
        setTimeout(() => {
          setAlertOpen(false);
          navigate(`/company/${companyId}/contact`);
          dispatch(clearUpdateCompanyStatusAction());
        }, 1000);
      }
    }
  }, [
    updateCompanyError,
    updateCompanySuccess,
    navigate,
    saveAndNextClicked,
    companyId,
    dispatch,
    resetParentCompanyId,
  ]);

  const onSave: SubmitHandler<ICompany> = useCallback(
    data => {
      if (!data.parentCompany && data.parentCompanyId === 0) {
        setError('parentCompanyId', { type: 'required' });
      }
      if ((isDirty || (logoUrl !== '' && logoUrl !== company.logoURL)) && !logoError && !errors.parentCompanyId) {
        if (data.id === 0) {
          dispatch(saveCompanyAction({ ...data, companyIdNumber: toNumber(data.companyIdNumber) }));
        } else {
          dispatch(
            updateCompanyAction({
              companyId,
              company: { ...data, companyIdNumber: toNumber(data.companyIdNumber) },
            }),
          );
        }
      }
    },
    [companyId, dispatch, isDirty, company, logoUrl, logoError, setError, errors],
  );

  const onSaveAndNext = useCallback(() => {
    handleSubmit(onSave)();
    setSaveAndNextClicked(true);
  }, [handleSubmit, onSave]);

  const onCancel = useCallback(() => {
    reset(CompanyInitialState);
    navigate('/companies');
  }, [navigate, reset]);

  const onChangeLogo = useCallback(
    event => {
      const splittedLogoName = event.target.files[0].name.split('.');
      if (splittedLogoName[1] === 'jpg' || splittedLogoName[1] === 'jpeg' || splittedLogoName[1] === 'png') {
        setValue('logo', event.target.files[0]);
        setLogoUrl(event.target.files[0].name);
        setLogoError(false);
      } else {
        setLogoError(true);
      }
    },
    [setValue],
  );

  const onChangeParentCompanyToggle = useCallback(
    (event, onChange) => {
      if (childCompanies.length > 0 && !event.currentTarget.checked) {
        setChildCompanyError(true);
        setAlertOpen(true);
      } else {
        setChildCompanyError(false);
      }
      onChange(event);
    },
    [childCompanies],
  );

  return (
    <SectionContainer>
      <>
        <SubSectionContainer>
          <CompanyInformationLeftContainer>
            <InputBox>
              <Controller
                name="name"
                control={control}
                rules={{ required: true, maxLength: 100 }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t(COMPANY_INFORMATION__COMPANY_NAME)}
                    placeholder={t(COMPANY_INFORMATION__COMPANY_NAME)}
                    required
                    error={!!errors.name}
                    helperText={getInputErrorText(errors, 'name', undefined, 100)}
                    disabled={!isAdmin}
                  />
                )}
              />
            </InputBox>
            <InputBox>
              <Controller
                name="commercialName"
                control={control}
                rules={{ maxLength: 100 }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t(COMPANY_INFORMATION__COMMERCIAL_NAME)}
                    placeholder={t(COMPANY_INFORMATION__COMMERCIAL_NAME)}
                    disabled={!isAdmin}
                  />
                )}
              />
            </InputBox>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <InputBox width={2 / 3} pr="5px">
                <Controller
                  name="address.streetName"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      label={t(COMPANY_INFORMATION__STREET_NAME)}
                      placeholder={t(COMPANY_INFORMATION__STREET_NAME)}
                      required
                      error={!!errors.address?.streetName}
                      helperText={getInputErrorText(errors, 'address', 'streetName', 100)}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </InputBox>
              <InputBox width={1 / 3}>
                <Controller
                  name="address.streetNumber"
                  control={control}
                  rules={{ required: true, maxLength: 100, min: 0 }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value}
                      onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                      label={t(COMPANY_INFORMATION__STREET_NUMBER)}
                      placeholder={t(COMPANY_INFORMATION__STREET_NUMBER)}
                      required
                      error={!!errors.address?.streetNumber}
                      helperText={getInputErrorText(errors, 'address', 'streetNumber', 100)}
                      helperTextExtended
                      disabled={!isAdmin}
                    />
                  )}
                />
              </InputBox>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <InputBox pr="5px" width={1 / 3}>
                <Controller
                  name="address.busNumber"
                  control={control}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      label={t(COMPANY_INFORMATION__BUS_NUMBER)}
                      placeholder={t(COMPANY_INFORMATION__BUS_NUMBER)}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </InputBox>
              <InputBox pr="5px" width={1 / 3}>
                <Controller
                  name="address.city"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      label={t(COMPANY_INFORMATION__CITY)}
                      placeholder={t(COMPANY_INFORMATION__CITY)}
                      required
                      error={!!errors.address?.city}
                      helperText={getInputErrorText(errors, 'address', 'city', 100)}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </InputBox>
              <InputBox width={1 / 3}>
                <Controller
                  name="address.postalCode"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      label={t(COMPANY_INFORMATION__POSTAL_CODE)}
                      placeholder={t(COMPANY_INFORMATION__POSTAL_CODE)}
                      required
                      error={!!errors.address?.postalCode}
                      helperText={getInputErrorText(errors, 'address', 'postalCode', 100)}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </InputBox>
              <InputBox width={1 / 2}>
                <Controller
                  name="address.countryId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      value={field?.value}
                      onChange={field.onChange}
                      label={t(GENERAL__COUNTRY)}
                      placeholder={t(GENERAL__SELECT)}
                      required
                      error={!!errors.address?.countryId}
                      helperText={getInputErrorText(errors, 'address', 'countryId')}
                      className="dropdown--white-background"
                      items={countries || [{ value: null, label: '' }]}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </InputBox>
            </Box>
          </CompanyInformationLeftContainer>
          <CompanyInformationRightContainer>
            <InputBox>
              <Controller
                name="companyIdNumber"
                control={control}
                rules={{ required: true, maxLength: 10 }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t(COMPANY_INFORMATION__COMPANY_ID_NUMBER)}
                    placeholder={t(COMPANY_INFORMATION__COMPANY_ID_NUMBER)}
                    required
                    error={!!errors.companyIdNumber}
                    helperText={getInputErrorText(errors, 'companyIdNumber', undefined, 10)}
                    disabled={!isAdmin}
                    type="number"
                    min={0}
                  />
                )}
              />
            </InputBox>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <InputBox width={1 / 3}>
                <Controller
                  name="companyFormat"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={companyFormatOptions}
                      onChange={field.onChange}
                      value={field?.value}
                      label={t(COMPANY_INFORMATION__COMPANY_FORMAT)}
                      required
                      error={!!errors.companyFormat}
                      helperText={getInputErrorText(errors, 'companyFormat', undefined)}
                      disabled={!isAdmin}
                      placeholder={t(GENERAL__SELECT)}
                    />
                  )}
                />
              </InputBox>
              <Box sx={{ marginLeft: '5px' }} width={2 / 3}>
                <InputBox>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true, maxLength: 50, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                    render={({ field }) => (
                      <UPInput
                        value={field.value}
                        onChange={field.onChange}
                        label={t(LOGIN__EMAIL_LABEL)}
                        placeholder={t(LOGIN__EMAIL_LABEL)}
                        required
                        error={!!errors.email}
                        helperText={getInputErrorText(errors, 'email', undefined, 50)}
                        disabled={!isAdmin}
                      />
                    )}
                  />
                </InputBox>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }} pl="10px" mb="22px" pt="8px">
              <Box sx={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }} width={2 / 4}>
                <Controller
                  name="smallCompany"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                      label={<span>{t(COMPANY_INFORMATION__SMALL_COMPANY)}</span>}
                      sx={{
                        '.MuiTypography-root': {
                          fontSize: '14px',
                          marginLeft: '10px',
                        },
                        '&.MuiFormControlLabel-root': {
                          marginBottom: '8px',
                        },
                      }}
                      disabled={!isAdmin}
                    />
                  )}
                />
                <Controller
                  name="unionDelegation"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                      label={<span>{t(COMPANY_INFORMATION__UNION_DELEGATION)}</span>}
                      sx={{
                        '.MuiTypography-root': {
                          fontSize: '14px',
                          marginLeft: '10px',
                          marginTop: '2px',
                        },
                      }}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }} width={2 / 4}>
                <Controller
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__NON_ACTIVE)}</Typography>
                      <UPSwitch checked={field.value} onChange={field.onChange} disabled={!isAdmin} />
                      <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__ACTIVE)}</Typography>
                    </Stack>
                  )}
                />
                <Controller
                  name="parentCompany"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <UPSwitch
                          checked={field.value}
                          onChange={event => onChangeParentCompanyToggle(event, field.onChange)}
                        />
                      }
                      label={<span>{t(COMPANY_INFORMATION__PARENT_COMPANY)}</span>}
                      sx={{
                        '.MuiTypography-root': {
                          fontSize: '14px',
                          marginLeft: '10px',
                        },
                        '&.MuiFormControlLabel-root': {
                          marginTop: '8px',
                          marginRight: '0px',
                        },
                      }}
                      disabled={!isAdmin}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box sx={{ marginLeft: '5px' }} width={1 / 3}>
                <>
                  <UPFileInput
                    value={logoUrl}
                    onChange={onChangeLogo}
                    label={t(COMPANY_INFORMATION__LOGO)}
                    placeholder={t(COMPANY_INFORMATION__LOGO_PLACEHOLDER)}
                    error={logoError}
                    disabled={!isAdmin}
                  />
                  <LogoErrorText>
                    {logoError && 'The file formats supported for logo are: jpg, jpeg, png.'}
                  </LogoErrorText>
                </>
              </Box>

              <Box width={2 / 3} sx={{ marginLeft: '5px' }}>
                {!watchParentCompany && !childCompanyError && (
                  <Controller
                    name="parentCompanyId"
                    control={control}
                    rules={{ required: !watchParentCompany }}
                    render={({ field }) => (
                      <UPDropdownWithSearch
                        items={parentCompanies}
                        onChange={selectedItem => field.onChange(selectedItem)}
                        value={field.value === 0 || field.value === null ? '' : field.value.toString()}
                        label={t(COMPANY_INFORMATION__PARENT_COMPANY_LABEL)}
                        error={!!errors.parentCompanyId}
                        helperText={getInputErrorText(errors, 'parentCompanyId', undefined)}
                        required={!watchParentCompany}
                        disabled={!isAdmin}
                      />
                    )}
                  />
                )}
              </Box>
            </Box>
          </CompanyInformationRightContainer>
        </SubSectionContainer>
        {isAdmin && (
          <ButtonsContainer sx={{ display: 'flex', flexDirection: 'row' }}>
            <LeftSideButtons>
              <Box>
                <UPButton
                  text={t(GENERAL__SAVE_BUTTON)}
                  onClick={() => handleSubmit(onSave)()}
                  disabled={childCompanyError}
                />
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <UPButton
                  text={t(GENERAL__SAVE_AND_NEXT_BUTTON)}
                  onClick={onSaveAndNext}
                  disabled={childCompanyError}
                />
              </Box>
              <Box sx={{ marginLeft: '10px' }}>
                <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} outlined />
              </Box>
            </LeftSideButtons>
            {companyId !== undefined && isAdmin && (
              <RightSideButtons>
                <Box sx={{ marginLeft: '10px' }}>
                  <UPButton text={t(GENERAL__DELETE)} onClick={() => setOpenDeleteModal(true)} outlined />
                </Box>
              </RightSideButtons>
            )}
          </ButtonsContainer>
        )}
      </>
      <Box sx={{ marginTop: '32px' }}>
        {alertOpen && updateCompanyError && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updateCompanyErrorAction(false));
                  dispatch(companyErrorMessageAction(null));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
            onClose={() => setAlertOpen(false)}
          >
            {getErrorMessage(companyErrorMessage)}
          </Alert>
        )}
        {alertOpen && saveCompanyError && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(saveCompanyErrorAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
            onClose={() => setAlertOpen(false)}
          >
            {t(GENERAL__ERROR_MESSAGE)}
          </Alert>
        )}
        {updateCompanySuccess && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updateCompanySuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY)}
          </Alert>
        )}
        {saveCompanySuccess && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(saveCompanySuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_INFORMATION__COMPANY_ADDED_SUCCESSFULLY)}
          </Alert>
        )}
        {alertOpen && childCompanyError && (
          <Alert severity="error" icon={false} variant="filled" sx={{ width: '800px' }}>
            {t(COMPANY_INFORMATION__ASSIGN_CHILD_WARNING)}
          </Alert>
        )}
        <DeleteModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          content={t(GENERAL__DELETE_MESSAGE)}
          onDelete={() => {
            dispatch(deleteCompanyAction(toNumber(companyId)));
            dispatch(clearCompaniesAction());
            setTimeout(() => navigate('/companies'), 1000);
          }}
        />
      </Box>
    </SectionContainer>
  );
};

export default EditCompanyInformation;
