import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Alert, Box, Button, Modal, styled } from '@mui/material';

export const AvailabilitiesContainer = styled(Box)(({ theme }) => ({
  width: '80%',
  height: '75vh',
  margin: '20px auto',
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0px',
  },
}));

export const AvailabilitiesNavigationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '20px 20px',
  cursor: 'pointer',
  '&.hover': {
    backgroundColor: '#F5F5F5',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const ScaledUpLeftArrow = styled(KeyboardArrowLeft)(({ theme }) => ({
  transform: 'scale(2.5)',
  color: theme.palette.primary.main,
}));

export const ScaledUpRightArrow = styled(KeyboardArrowRight)(({ theme }) => ({
  transform: 'scale(2.5)',
  color: theme.palette.primary.main,
}));
