import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import localStorageService from 'services/local-storage-service';
import {
  changeCompanyReqquest,
  impersonateRequest,
  loginRequest,
  companyLogintRequest,
  ssoLoginRequest,
  impersonateEmployeeRequest,
  validateTokenRequest,
} from 'api/requests/auth-requests';
import {
  AUTH__SET_TOKEN,
  AUTH__SET_ERROR,
  AUTH__LOGIN,
  AUTH__LOGOUT,
  AUTH__IMPERSONATE,
  AUTH__STOP_IMPERSONATE,
  AUTH__CHANGE_COMPANIES,
  AUTH__GENERAL_LOADING,
  AUTH__SSO_LOGIN,
  AUTH__IMPERSONATE_EMPLOYEE,
  AUTH__SET_TEXT_ERROR,
  AUTH__SET_PASSWORD_TOKEN_VALIDITY,
  AUTH__SET_PASSWORD_TOKEN_VALIDITY_RESULT,
} from 'store/constants';
import ILoginRequest from 'types/auth/ILoginRequest';

export const setAuthRequestsLoadingAction = createAction<boolean>(AUTH__GENERAL_LOADING);

export const setAuthTokenAction = createAction<string>(AUTH__SET_TOKEN);
export const setAuthErrorAction = createAction<boolean>(AUTH__SET_ERROR);

export const loginAction = createAsyncThunk(AUTH__LOGIN, async (data: ILoginRequest, thunkApi) => {
  thunkApi.dispatch(setAuthRequestsLoadingAction(true));
  thunkApi.dispatch(setAuthErrorAction(false));

  try {
    const response = await loginRequest(data);
    if (response?.token) {
      thunkApi.dispatch(setAuthTokenAction(response.token));

      localStorageService.setItem({ authToken: response.token });
    }
  } catch (error) {
    thunkApi.dispatch(setAuthErrorAction(true));
  }

  thunkApi.dispatch(setAuthRequestsLoadingAction(false));
});

export const setAuthErrorTextAction = createAction<string>(AUTH__SET_TEXT_ERROR);
export const ssoLoginAction = createAsyncThunk(AUTH__SSO_LOGIN, async (data: ILoginRequest, thunkApi) => {
  thunkApi.dispatch(setAuthRequestsLoadingAction(true));
  thunkApi.dispatch(setAuthErrorAction(false));

  try {
    const response = await ssoLoginRequest(data);
    if (response?.token) {
      thunkApi.dispatch(setAuthTokenAction(response.token));

      localStorageService.setItem({ authToken: response.token });
    }
  } catch (error) {
    thunkApi.dispatch(setAuthErrorAction(true));
    thunkApi.dispatch(setAuthErrorTextAction(error.toString()));
  }

  thunkApi.dispatch(setAuthRequestsLoadingAction(false));
});

export const logoutAction = createAsyncThunk(AUTH__LOGOUT, async (withRedirect: boolean, thunkApi) => {
  try {
    thunkApi.dispatch(setAuthTokenAction(null));
    localStorageService.removeItem('authToken');
    localStorageService.removeItem('impersonationToken');
    if (withRedirect) {
      window.location.href = '/';
    }
  } catch (err) {
    // swallow exception
  }
});

export const impersonateAction = createAsyncThunk(
  AUTH__IMPERSONATE,
  async (props: { userId: number; companyId: number }) => {
    try {
      const response = await impersonateRequest(props.userId, props.companyId);
      if (response?.token) {
        localStorageService.setItem({ impersonationToken: response.token });
        localStorageService.setItem({ isImpersonated: true });
        window.location.href = '/';
      }
    } catch (err) {
      // swallow exception
    }
  },
);

export const impersonateEmployeeAction = createAsyncThunk(
  AUTH__IMPERSONATE_EMPLOYEE,
  async (props: { employeeId: number; companyId: number }) => {
    try {
      const response = await impersonateEmployeeRequest(props.employeeId, props.companyId);
      if (response?.token) {
        localStorageService.setItem({ impersonationToken: response.token });
        localStorageService.setItem({ isImpersonated: true });
        window.location.href = '/';
      }
    } catch (err) {
      // swallow exception
    }
  },
);

export const stopImpersonateAction = createAsyncThunk(AUTH__STOP_IMPERSONATE, async (__, thunkApi) => {
  try {
    const token = localStorageService.getItem<string>('authToken');
    thunkApi.dispatch(setAuthTokenAction(token));
    localStorageService.removeItem('impersonationToken');
    localStorageService.removeItem('isImpersonated');
    window.location.href = '/';
  } catch (err) {
    // swallow exception
  }
});

export const changeCompanyAction = createAsyncThunk(AUTH__IMPERSONATE, async (companyId: number) => {
  try {
    const response = await companyLogintRequest(companyId);
    if (response?.token) {
      localStorageService.removeItem('isImpersonated');
      localStorageService.setItem({ impersonationToken: response.token });
      window.location.href = '/';
    }
  } catch (err) {
    // swallow exception
  }
});

export const changeCompaniesAction = createAsyncThunk(AUTH__CHANGE_COMPANIES, async (companyId: number) => {
  try {
    const response = await changeCompanyReqquest(companyId);
    if (response?.token) {
      localStorageService.setItem({ impersonationToken: response.token });
      window.location.href = '/';
    }
  } catch (err) {
    // swallow exception
  }
});

export const setPasswordValidityResultAction = createAction<boolean>(AUTH__SET_PASSWORD_TOKEN_VALIDITY_RESULT);
export const passwordTokenValidityAction = createAsyncThunk(
  AUTH__SET_PASSWORD_TOKEN_VALIDITY,
  async (token: string, thunkApi) => {
    thunkApi.dispatch(setAuthRequestsLoadingAction(true));
    try {
      const res = await validateTokenRequest(token);
      thunkApi.dispatch(setPasswordValidityResultAction(res));
    } catch (err) {
      thunkApi.dispatch(setPasswordValidityResultAction(false));
    }
    thunkApi.dispatch(setAuthRequestsLoadingAction(false));
  },
);
