import { DateFormat } from 'constants/DateFormat';
import { useContext } from 'react';
import { AvailabilitiesContext } from '../../Availabilities';
import { AvailabilitiesCalendarDayProps } from '../../types';
import {
  AvailabilitiesCalendarDayContainer,
  AvailabilitiesCalendarDayGraph,
  AvailabilitiesCalendarDayGraphContainer,
  AvailabilitiesCalendarDayNumber,
  AvailabilitiesCalendarDaySegment,
} from './styles';

const AvailabilitiesCalendarDay = (props: AvailabilitiesCalendarDayProps): JSX.Element => {
  const { day, sixRowsCalendar } = props;
  const { currentMonth, openAvailabilityFillModal, availability } = useContext(AvailabilitiesContext);

  const availabilityDay = availability?.days.find(av => av.day === day.date());

  const fullDayAvailable =
    availabilityDay?.isAvailableMorning && availabilityDay?.isAvailableAfternoon && availabilityDay?.isAvailableEvening;

  const fullDayUnavailable =
    !availabilityDay?.isAvailableMorning &&
    !availabilityDay?.isAvailableAfternoon &&
    !availabilityDay?.isAvailableEvening;

  const onClickDay = () => {
    openAvailabilityFillModal(day);
  };

  const isAvailabilityDayFullSquare = fullDayAvailable || fullDayUnavailable || day.month() !== currentMonth;

  return (
    <AvailabilitiesCalendarDayContainer sixRowsCalendar={sixRowsCalendar}>
      <AvailabilitiesCalendarDayNumber>{day.format(DateFormat.DAY_NUMBER)}</AvailabilitiesCalendarDayNumber>
      <AvailabilitiesCalendarDayGraphContainer>
        {isAvailabilityDayFullSquare ? (
          <AvailabilitiesCalendarDayGraph
            onClick={day.month() !== currentMonth ? null : onClickDay}
            disabled={day.month() !== currentMonth}
            fullyAvailable={fullDayAvailable}
            fullyUnavailable={fullDayUnavailable}
          />
        ) : (
          <AvailabilitiesCalendarDayGraph onClick={onClickDay}>
            <AvailabilitiesCalendarDaySegment availabile={availabilityDay?.isAvailableMorning} />
            <AvailabilitiesCalendarDaySegment availabile={availabilityDay?.isAvailableAfternoon} />
            <AvailabilitiesCalendarDaySegment availabile={availabilityDay?.isAvailableEvening} />
          </AvailabilitiesCalendarDayGraph>
        )}
      </AvailabilitiesCalendarDayGraphContainer>
    </AvailabilitiesCalendarDayContainer>
  );
};

export default AvailabilitiesCalendarDay;
