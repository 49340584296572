import UPInput from 'components/input';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_PAYROLL__FACTOR_S,
  COMPANY_PAYROLL__MAXIMUM_PLANNED_HOURS,
  COMPANY_PAYROLL__MINIMUM_PERFORMED_HOURS,
} from 'translations/constants';
import { onChangeHourValue } from './helpers';
import { IntervalHourBox, IntervalText } from './styles';
import { WorkhourSettingsFields, WorkhourSettingsProps } from './types';

const WorkhourSettings = (props: WorkhourSettingsProps) => {
  const { workhourSettings, setWorkhourSettings, setShouldUpdate, setValidValues } = props;
  const [t] = useTranslation();

  return (
    <>
      <IntervalHourBox>
        <IntervalText sx={{ width: '70%' }}>{t(COMPANY_PAYROLL__FACTOR_S)}</IntervalText>
        <UPInput
          sx={{ width: '30%' }}
          value={workhourSettings?.schedule ?? ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHourValue(
              event,
              setWorkhourSettings,
              WorkhourSettingsFields.SCHEDULE,
              setValidValues,
              setShouldUpdate,
            )
          }
          placeholder="0"
          label=""
        />
      </IntervalHourBox>
      <IntervalHourBox>
        <IntervalText sx={{ width: '70%' }}>{t(COMPANY_PAYROLL__MINIMUM_PERFORMED_HOURS)}</IntervalText>
        <UPInput
          sx={{ width: '30%' }}
          value={workhourSettings?.minimumWorkhours ?? ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHourValue(
              event,
              setWorkhourSettings,
              WorkhourSettingsFields.MIN_HOURS,
              setValidValues,
              setShouldUpdate,
            )
          }
          placeholder="0"
          label=""
        />
      </IntervalHourBox>
      <IntervalHourBox>
        <IntervalText sx={{ width: '70%' }}>{t(COMPANY_PAYROLL__MAXIMUM_PLANNED_HOURS)}</IntervalText>
        <UPInput
          sx={{ width: '30%' }}
          value={workhourSettings?.maximumWorkhours ?? ''}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHourValue(
              event,
              setWorkhourSettings,
              WorkhourSettingsFields.MAX_HOURS,
              setValidValues,
              setShouldUpdate,
            )
          }
          placeholder="0"
          label=""
        />
      </IntervalHourBox>
    </>
  );
};

export default WorkhourSettings;
