import { Box, styled } from '@mui/material';
import UPButton from 'components/button';
import { ReactComponent as FilterIcon } from '../../assets/icons/FilterIcon.svg';

export const FiltersMenuContainer = styled(Box)(({ theme }) => ({
  width: '300px',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

export const FiltersMenuButtonsContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '80px',
  gap: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
}));

export const FilterButton = styled(UPButton)(({ theme }) => ({
  margin: `${theme.spacing(2)} ${theme.spacing(1)}`,
  height: '48px',
  [theme.breakpoints.down(690)]: {
    margin: '6px',
  },
}));

export const StyledFilterIcon = styled(FilterIcon)(({ theme }) => ({
  fill: 'white',
  'button:hover &': {
    fill: theme.palette.primary.main,
  },
}));
