import { useTranslation } from 'react-i18next';
import { CONTRACTS__PASTE_HERE } from 'translations/constants';
import { PasteHereCardStyled, PasteHereText } from '../styles';
import { IPasteHereCardProps } from '../types';

const PasteHereCard = (props: IPasteHereCardProps): JSX.Element => {
  const { date, onClickPaste } = props;
  const [t] = useTranslation();

  return (
    <PasteHereCardStyled onClick={() => onClickPaste(date)}>
      <PasteHereText>{t(CONTRACTS__PASTE_HERE).toLocaleLowerCase()}</PasteHereText>
    </PasteHereCardStyled>
  );
};
export default PasteHereCard;
