export enum CompanyFormat {
  BVBVBA = 'BV-BVBA',
  BVBA = 'BVBA',
  CVBA = 'CVBA',
  NV = 'NV',
  CVOA = 'CVOA',
  ESV = 'ESV',
  CommV = 'Comm. V',
  CommVA = 'Comm. VA',
  VOF = 'VOF',
  VZW = 'VZW',
  FeitVereniging = 'Feit. Vereniging',
  EBVBA = 'EBVBA',
  Eenmanszaak = 'Eenmanszaak',
  BV = 'BV',
}
