import { Avatar, Box, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getAllUserCompaniesAction } from 'store/actions/company-actions';
import { companiesSelector } from 'store/selectors/company-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { AVAILABILITIES__COMPANY_PICK_SCREEN_TEXT } from 'translations/constants';
import {
  CompanyCardContainer,
  CompanyCardLocationContainer,
  CompanyCardNameContainer,
  CompanyCardPhotoContainer,
} from './styles';

const CompanyPickScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  const profile = useAppSelector(profileSelector);
  const companies = useAppSelector(companiesSelector);

  useEffect(() => {
    dispatch(getAllUserCompaniesAction(profile.id));
  }, [dispatch, profile.id]);

  return (
    <Box>
      <strong>{t(AVAILABILITIES__COMPANY_PICK_SCREEN_TEXT)}</strong>
      <br />
      <br />
      <Grid container spacing={1}>
        {companies.map(company => (
          <Grid item xs={6} md={4} lg={2} key={company.Id}>
            <CompanyCardContainer onClick={() => navigate(`/availabilities/${company.Id}`)}>
              <CompanyCardPhotoContainer>
                <Avatar src={company.Logo} sx={{ height: '90px', width: '90px', margin: 'auto' }} />
              </CompanyCardPhotoContainer>
              <CompanyCardNameContainer>{company.Name}</CompanyCardNameContainer>
              <CompanyCardLocationContainer>
                {company.Address.streetName}, {company.Address.streetNumber}, {company.Address.city},{' '}
                {company.Address.postalCode}
                <br />
                {company.Address.countryName}
              </CompanyCardLocationContainer>
            </CompanyCardContainer>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CompanyPickScreen;
