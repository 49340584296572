export const generateLimitedString = (value: string, maxCharacters: number) => {
  let auxString = value.substring(0, maxCharacters);
  if (value.split('').length > maxCharacters) {
    auxString = `${auxString} [...]`;
  }
  return auxString;
};

export const generateRightFormatForDate = (date: string) => {
  const auxArr = date.split('/');
  return `${auxArr[1]}/${auxArr[0]}/${auxArr[2]}`;
};

export const isValidUrl = urlString => {
  const urlPattern = new RegExp(
    '^(https?:\\/\\/)?' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' + //
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!urlPattern.test(urlString);
};
