/* eslint-disable react/jsx-props-no-spreading */
import { Box, FormControlLabel, Tooltip, Typography } from '@mui/material';
import UPDatepicker from 'components/datepicker';
import UPFileInput from 'components/file-input';
import UPInput from 'components/input';
import UPSwitch from 'components/switch';
import { toNumber } from 'lodash';
import { Document } from 'modules/documents/types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { downloadIdAction } from 'store/actions/employee-actions';
import { employeeSelector, onboardingSelector } from 'store/selectors/employee-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';

import UPButton from 'components/button';
import { CompanyRole } from 'constants/CompanyRole';
import { useDropzone } from 'react-dropzone';
import {
  EMPLOYEE__ACADEMIC_YEAR_END,
  EMPLOYEE__ACADEMIC_YEAR_START,
  EMPLOYEE__DECLARATION_OF_TRUTH,
} from 'store/constants';
import {
  DOCUMENTS__BROWSE,
  DOCUMENTS__DRAG_AND_DROP_STUD_DECLARATION,
  EMPLOYEES__ACCOUNT,
  EMPLOYEES__AGREE_TO_GDPR,
  EMPLOYEES__AGREE_TO_GDPR_ERROR,
  EMPLOYEES__CONFIRM_PASSWORD,
  EMPLOYEES__ID_CARD_NUMBER,
  EMPLOYEES__ID_EXPIRATION_DATE,
  EMPLOYEES__UPLOAD_ID_BACK,
  EMPLOYEES__UPLOAD_ID_FRONT,
  EMPLOYEES__UPLOAD_PICTURE,
  EMPLOYEES__VIEW_ID,
  EMPLOYEE__FIND_LETTER_OF_INTENT_TEXT,
  EMPLOYEE__FIND_STUDENT_DECLARATION_TEXT,
  EMPLOYEE__INVALID_ACADEMIC_PERIOD,
  EMPLOYEE__LETTER_OF_INTENT,
  EMPLOYEE__STUDENT_DECLARATION,
  EMPLOYEE__UPLOADED_DOCUMENT,
  EMPLOYEE__WORKERCLASS_NOT_SELECTED_ERROR,
  GENERAL__AND,
  LOGIN__PASSWORD_LABEL,
  LOGIN__PASSWORD_MIN_LENGTH_ERROR,
} from 'translations/constants';
import { getInputErrorText, validatePassword } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { getDaysBetweenDates } from '../helpers';
import {
  AccountSmallBox,
  AccountWithMarginLeftBox,
  AccountWithMarginRightBox,
  EmployeeInfoSmallBox,
  EmployeeModalInfoContainer,
  ErrorText,
  RowContainer,
  SectionTitle,
  SmallBoxWithNoMargin,
} from './styles';
import { IAccountTabProps } from './types';

const AccountTab = (props: IAccountTabProps) => {
  const {
    control,
    errors,
    setValue,
    getValues,
    setError,
    clearErrors,
    employeeIdURL,
    employeeIdBackURL,
    invalidDateError,
    companyId,
    watch,
    proofOfInscription,
    setProofOfInscription,
    setNewDocToAdd,
    setDocToUpdate,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const employeeOnboarding = useAppSelector(onboardingSelector);
  const isAdmin = useAppSelector(profileSelector)?.role === CompanyRole.ADMIN;
  const employee = useAppSelector(employeeSelector);

  const watchPermanent = watch('permanent');
  const watchWorkerClass = watch('workerclass');
  const watchLetterOfIntentCheck = watch('letterOfIntentCheck');

  function getBase64(file: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setValue('pictureBase64', reader.result);
    };
  }

  function formatAndSaveValue(value: any, input: string): string {
    // check if value.target.files[0] is undefined or has no value
    if (!value.target.files || value.target.files[0] === undefined || value.target.files[0] === null) {
      return '';
    }

    if (input === 'id-upload') {
      setValue('idUpload', value.target.files[0]);
    } else if (input === 'id-upload-back') {
      setValue('idUploadBack', value.target.files[0]);
    } else if (input === 'picture-upload') {
      setValue('pictureUpload', value.target.files[0]);
      getBase64(value.target.files[0]);
    }

    return value.target.files[0]?.name;
  }

  const isAcademicPeriodValid = (startDate: Date, endDate: Date) => {
    if (!endDate && startDate) {
      return true;
    }

    const difference = getDaysBetweenDates(startDate, endDate);
    const numberOfDaysInAYear = 365;
    if (difference < 0 || difference > numberOfDaysInAYear) {
      return false;
    }
    return true;
  };

  const onDropFiles = (accFiles: any) => {
    if (accFiles) {
      const newDocument: Document = {
        file: accFiles[0],
        id: 1,
        name: (accFiles[0] as any).path,
        date: new Date(),
        type: 6,
        error: {
          dateError: false,
          typeError: false,
          invalidDate: false,
        },
      };
      setNewDocToAdd(true);
      setProofOfInscription(newDocument);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    },
    noDrag: !!proofOfInscription,
    disabled: !!proofOfInscription,
    multiple: false,
    onDrop: accFiles => onDropFiles(accFiles),
    preventDropOnDocument: false,
  });

  const onDownloadImage = (image: string, isBack: boolean) => {
    if (image) {
      dispatch(
        downloadIdAction({
          companyId: toNumber(companyId),
          employeeId: employee.id,
          imageName: image,
          isBackImage: isBack,
        }),
      );
    }
  };

  const onDownloadBackImage = () => {
    onDownloadImage(employeeIdBackURL, true);
  };

  const onDownloadFrontImage = () => {
    onDownloadImage(employeeIdURL, false);
  };

  return (
    <Box>
      <RowContainer>
        <SectionTitle>{t(EMPLOYEES__ACCOUNT)}</SectionTitle>
      </RowContainer>
      <EmployeeModalInfoContainer>
        <EmployeeInfoSmallBox>
          <Controller
            name="idUploadURL"
            control={control}
            rules={{ required: !watchPermanent }}
            render={({ field: { value, onChange } }) => (
              <UPFileInput
                value={value}
                onChange={ev => onChange(formatAndSaveValue(ev, 'id-upload'))}
                label={t(EMPLOYEES__UPLOAD_ID_FRONT)}
                placeholder={t(EMPLOYEES__UPLOAD_ID_FRONT)}
                error={!!errors.idUploadURL}
                required={!watchPermanent}
                helperText={getInputErrorText(errors, 'idUploadURL', undefined)}
              />
            )}
          />
          {employeeIdURL && isAdmin && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <a
              onClick={() => onDownloadFrontImage()}
              style={{
                textDecoration: 'none',
                fontSize: '12px',
                color: '#008C15',
                marginLeft: '5px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              {t(EMPLOYEES__VIEW_ID)}
            </a>
          )}
        </EmployeeInfoSmallBox>
        <EmployeeInfoSmallBox>
          <Controller
            name="idUploadBackURL"
            control={control}
            rules={{ required: !watchPermanent }}
            render={({ field: { value, onChange } }) => (
              <UPFileInput
                value={value}
                onChange={ev => onChange(formatAndSaveValue(ev, 'id-upload-back'))}
                label={t(EMPLOYEES__UPLOAD_ID_BACK)}
                placeholder={t(EMPLOYEES__UPLOAD_ID_BACK)}
                error={!!errors.idUploadBackURL}
                required={!watchPermanent}
                helperText={getInputErrorText(errors, 'idUploadBackURL', undefined)}
              />
            )}
          />
          {employeeIdBackURL && isAdmin && (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <a
              onClick={() => onDownloadBackImage()}
              style={{
                textDecoration: 'none',
                fontSize: '12px',
                color: '#008C15',
                marginLeft: '5px',
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              {t(EMPLOYEES__VIEW_ID)}
            </a>
          )}
        </EmployeeInfoSmallBox>

        <EmployeeInfoSmallBox>
          <Controller
            name="idCardNumber"
            control={control}
            rules={{ required: !watchPermanent, maxLength: 50 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__ID_CARD_NUMBER)}
                placeholder={t(EMPLOYEES__ID_CARD_NUMBER)}
                required={!watchPermanent}
                error={!!errors.idCardNumber}
                helperText={getInputErrorText(errors, 'idCardNumber', undefined, 50)}
                inputClassName="white-background"
              />
            )}
          />
        </EmployeeInfoSmallBox>

        <SmallBoxWithNoMargin>
          <Controller
            name="expirationDate"
            control={control}
            rules={{ required: !watchPermanent }}
            render={({ field }) => (
              <UPDatepicker
                value={field.value}
                onChange={field.onChange}
                label={t(EMPLOYEES__ID_EXPIRATION_DATE)}
                required={!watchPermanent}
                error={!!errors.expirationDate || invalidDateError}
                helperText={
                  invalidDateError
                    ? getInputErrorText(errors, 'expirationDate', undefined, 50, invalidDateError)
                    : getInputErrorText(errors, 'expirationDate', undefined, 50)
                }
                className="datepicker--white-background"
              />
            )}
          />
        </SmallBoxWithNoMargin>
      </EmployeeModalInfoContainer>
      {!employeeOnboarding && (
        <EmployeeModalInfoContainer>
          <AccountWithMarginRightBox>
            <Controller
              name="pictureUploadURL"
              control={control}
              render={({ field: { value, onChange } }) => (
                <UPFileInput
                  value={value}
                  onChange={ev => onChange(formatAndSaveValue(ev, 'picture-upload'))}
                  label={t(EMPLOYEES__UPLOAD_PICTURE)}
                  placeholder={t(EMPLOYEES__UPLOAD_PICTURE)}
                  error={!!errors.pictureUploadURL}
                  helperText={getInputErrorText(errors, 'pictureUploadURL', undefined)}
                />
              )}
            />
          </AccountWithMarginRightBox>
          <AccountSmallBox>
            <Controller
              name="password"
              control={control}
              rules={{
                required: !watchPermanent,
                minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
                maxLength: 100,
              }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={e => {
                    field.onChange(e);
                    if (!validatePassword(e.target.value)) {
                      setError('password', { type: 'adminEmployeePolicy' });
                    } else {
                      clearErrors('password');
                    }
                  }}
                  label={t(LOGIN__PASSWORD_LABEL)}
                  placeholder={t(LOGIN__PASSWORD_LABEL)}
                  required={!watchPermanent}
                  type="password"
                  error={!!errors.password}
                  helperText={getInputErrorText(errors, 'password', undefined, 100, false, 8, false, true)}
                  inputClassName="white-background"
                  autoComplete="new-password"
                />
              )}
            />
          </AccountSmallBox>
        </EmployeeModalInfoContainer>
      )}
      {employeeOnboarding && (
        <EmployeeModalInfoContainer>
          <AccountWithMarginRightBox>
            <Controller
              name="pictureUploadURL"
              control={control}
              render={({ field: { value, onChange } }) => (
                <UPFileInput
                  value={value}
                  onChange={ev => onChange(formatAndSaveValue(ev, 'picture-upload'))}
                  label={t(EMPLOYEES__UPLOAD_PICTURE)}
                  placeholder={t(EMPLOYEES__UPLOAD_PICTURE)}
                  error={!!errors.pictureUploadURL}
                  helperText={getInputErrorText(errors, 'pictureUploadURL', undefined)}
                />
              )}
            />
          </AccountWithMarginRightBox>
          <AccountSmallBox>
            <Controller
              name="password"
              control={control}
              rules={{
                required: true,
                minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
                maxLength: 100,
              }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={event => {
                    const { confirmPassword } = getValues();
                    field.onChange(event.target.value);
                    if (confirmPassword !== event.target.value) {
                      setError('notMatchingPassword', { type: 'match' });
                    } else if (!validatePassword(event.target.value)) {
                      setError('notMatchingPassword', { type: 'policy' });
                    } else {
                      clearErrors('notMatchingPassword');
                    }
                  }}
                  label={t(LOGIN__PASSWORD_LABEL)}
                  placeholder={t(LOGIN__PASSWORD_LABEL)}
                  required
                  type="password"
                  error={!!errors.password}
                  helperText={getInputErrorText(errors, 'password', undefined, 100, false, 8, false, true)}
                  inputClassName="white-background"
                  autoComplete="new-password"
                />
              )}
            />
          </AccountSmallBox>
          <AccountWithMarginLeftBox>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: true,
                minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
                maxLength: 200,
              }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={event => {
                    const { password } = getValues();
                    field.onChange(event.target.value);
                    if (password !== event.target.value) {
                      setError('notMatchingPassword', { type: 'match' });
                    } else if (!validatePassword(event.target.value)) {
                      setError('notMatchingPassword', { type: 'policy' });
                    } else {
                      clearErrors('notMatchingPassword');
                    }
                  }}
                  label={t(EMPLOYEES__CONFIRM_PASSWORD)}
                  placeholder={t(LOGIN__PASSWORD_LABEL)}
                  required
                  type="password"
                  error={!!errors.notMatchingPassword}
                  helperText={getInputErrorText(errors, 'notMatchingPassword', undefined, 100, false, 8, false, true)}
                  inputClassName="white-background"
                  autoComplete="new-password"
                />
              )}
            />
          </AccountWithMarginLeftBox>
        </EmployeeModalInfoContainer>
      )}
      <EmployeeModalInfoContainer>
        <Box sx={{ marginLeft: '16px' }}>
          <Controller
            name="agreeGdpr"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControlLabel
                control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                label={<span>{t(EMPLOYEES__AGREE_TO_GDPR)}</span>}
                sx={{
                  '.MuiTypography-root': {
                    fontSize: '14px',
                    marginLeft: '10px',
                  },
                  '&.MuiFormControlLabel-root': {
                    marginTop: '8px',
                    marginRight: '0px',
                  },
                }}
              />
            )}
          />
        </Box>
      </EmployeeModalInfoContainer>
      {!!errors.agreeGdpr && (
        <RowContainer sx={{ marginTop: '5px', marginLeft: '7px' }}>
          <ErrorText>{t(EMPLOYEES__AGREE_TO_GDPR_ERROR)}</ErrorText>
        </RowContainer>
      )}
      {!watchPermanent && (
        <EmployeeModalInfoContainer sx={{ marginTop: '0px' }}>
          <Box sx={{ marginLeft: '16px', marginBottom: '10px' }}>
            <Controller
              name="letterOfIntentCheck"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControlLabel
                  control={<UPSwitch checked={field.value} onChange={field.onChange} disabled={!watchWorkerClass} />}
                  label={
                    <Tooltip
                      title={
                        <>
                          {watchWorkerClass === 1
                            ? t(EMPLOYEE__FIND_STUDENT_DECLARATION_TEXT)
                            : t(EMPLOYEE__FIND_LETTER_OF_INTENT_TEXT)}
                          <a
                            href={
                              watchWorkerClass === 1
                                ? 'https://updateproproduction.blob.core.windows.net/common/student_declarations/Employee%20approval_Intentieverklaring%202022-2023%20student.pdf'
                                : 'https://updateproproduction.blob.core.windows.net/common/letter_of_intent/Employee%20approval_Intentieverklaring.pdf'
                            }
                            target="blank"
                            style={{
                              fontStyle: 'italic',
                              textDecoration: 'underline',
                              color: '#DBEDE3',
                            }}
                          >
                            {' NED '}
                          </a>
                          {t(GENERAL__AND)}
                          <a
                            href={
                              watchWorkerClass === 1
                                ? 'https://updateproproduction.blob.core.windows.net/common/student_declarations/Employee%20approval_Intention%20de%20Conclure%20Etudiant%202022-2023.pdf'
                                : 'https://updateproproduction.blob.core.windows.net/common/letter_of_intent/Employee%20approval_Intention%20de%20Conclure.pdf'
                            }
                            target="blank"
                            style={{
                              fontStyle: 'italic',
                              textDecoration: 'underline',
                              color: '#DBEDE3',
                            }}
                          >
                            {' FR '}
                          </a>
                        </>
                      }
                      placement="top"
                    >
                      <span>{`${t(EMPLOYEE__LETTER_OF_INTENT)} / ${t(EMPLOYEE__STUDENT_DECLARATION)}`}</span>
                    </Tooltip>
                  }
                  sx={{
                    '.MuiTypography-root': {
                      fontSize: '14px',
                      marginLeft: '10px',
                    },
                    '&.MuiFormControlLabel-root': {
                      marginTop: '8px',
                      marginRight: '0px',
                    },
                  }}
                />
              )}
            />
          </Box>
        </EmployeeModalInfoContainer>
      )}
      {!!errors.letterOfIntentCheck && (
        <RowContainer sx={{ marginTop: '0px', marginLeft: '7px' }}>
          <ErrorText>{t(EMPLOYEES__AGREE_TO_GDPR_ERROR)}</ErrorText>
        </RowContainer>
      )}
      {!watchWorkerClass && !watchPermanent && (
        <RowContainer sx={{ marginTop: '5px', marginLeft: '10px' }}>
          <ErrorText>{t(EMPLOYEE__WORKERCLASS_NOT_SELECTED_ERROR)}</ErrorText>
        </RowContainer>
      )}
      {watchWorkerClass === 1 && watchLetterOfIntentCheck && (
        <>
          {!proofOfInscription ? (
            <RowContainer
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '10px 20px',
              }}
            >
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>{t(DOCUMENTS__DRAG_AND_DROP_STUD_DECLARATION)}</p>
                <UPButton
                  text={t(DOCUMENTS__BROWSE)}
                  onClick={e => {
                    e.stopPropagation();
                    open();
                  }}
                  outlined
                />
              </div>
            </RowContainer>
          ) : (
            <Typography>{t(EMPLOYEE__UPLOADED_DOCUMENT)}: </Typography>
          )}
          <RowContainer
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px 32px',
              marginTop: '0px',
              maxHeight: '100px',
              overflowY: 'auto',
            }}
          >
            {proofOfInscription && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ marginRight: '16px', alignSelf: 'center' }} width={3 / 4}>
                    <div>{proofOfInscription.name}</div>
                  </Box>
                </Box>
                <Box
                  sx={{ cursor: 'pointer', display: 'flex', alignSelf: 'flex-start' }}
                  onClick={() => {
                    setProofOfInscription(null);
                    setNewDocToAdd(true);
                  }}
                >
                  <CloseIcon fill={theme.palette.secondary.contrastText} />
                </Box>
              </Box>
            )}
          </RowContainer>
          <EmployeeModalInfoContainer>
            <AccountWithMarginLeftBox>
              <Controller
                name="academicPeriodStart"
                control={control}
                render={({ field }) => (
                  <UPDatepicker
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                      setDocToUpdate(true);
                      const { academicPeriodEnd } = getValues();
                      if (!isAcademicPeriodValid(value, academicPeriodEnd)) {
                        setError('periodInvalid', { type: t(EMPLOYEE__INVALID_ACADEMIC_PERIOD) });
                      } else {
                        clearErrors('periodInvalid');
                      }
                    }}
                    isAcademicPicker
                    label={t(EMPLOYEE__ACADEMIC_YEAR_START)}
                    error={!!errors.periodInvalid}
                    helperText={getInputErrorText(errors, 'periodInvalid', undefined, 50)}
                    className="datepicker--white-background"
                  />
                )}
              />
            </AccountWithMarginLeftBox>
            <AccountWithMarginLeftBox>
              <Controller
                name="academicPeriodEnd"
                control={control}
                render={({ field }) => (
                  <UPDatepicker
                    value={field.value}
                    onChange={value => {
                      setDocToUpdate(true);
                      field.onChange(value);
                      const { academicPeriodStart } = getValues();
                      if (!isAcademicPeriodValid(academicPeriodStart, value)) {
                        setError('periodInvalid', { type: t(EMPLOYEE__INVALID_ACADEMIC_PERIOD) });
                      } else {
                        clearErrors('periodInvalid');
                      }
                    }}
                    isAcademicPicker
                    label={t(EMPLOYEE__ACADEMIC_YEAR_END)}
                    error={!!errors.periodInvalid}
                    helperText={getInputErrorText(errors, 'periodInvalid', undefined, 50)}
                    className="datepicker--white-background"
                  />
                )}
              />
            </AccountWithMarginLeftBox>
            <AccountWithMarginLeftBox sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box
                sx={{
                  marginLeft: '16px',
                }}
              >
                <Controller
                  name="declarationOfTruth"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                      label={<span>{t(EMPLOYEE__DECLARATION_OF_TRUTH)}</span>}
                      sx={{
                        '.MuiTypography-root': {
                          fontSize: '14px',
                          marginLeft: '10px',
                        },
                        '&.MuiFormControlLabel-root': {
                          marginTop: '8px',
                          marginRight: '0px',
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </AccountWithMarginLeftBox>
          </EmployeeModalInfoContainer>
          {!!errors.periodInvalid && (
            <RowContainer sx={{ marginTop: '5px', marginLeft: '20px' }}>
              <ErrorText>{t(errors.periodInvalid.type)}</ErrorText>
            </RowContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default AccountTab;
