import { Box, Typography } from '@mui/material';
import { CompanyAvailabilityCalendarHeaderCellProps } from 'modules/availabilities/company-availability-2/types';
import { DayOfWeek, NumberOfItems } from 'modules/contracts/styles';
import { HeaderCellBody } from './styles';

const CompanyAvailabilityCalendarHeaderCell = (props: CompanyAvailabilityCalendarHeaderCellProps) => {
  const { day, year } = props;

  const formatDate = (value: Date) => {
    const dayNumber = value.getDate();
    const m = value.toLocaleString('default', { month: 'short' });
    return `${dayNumber} ${m}`;
  };

  return (
    <HeaderCellBody>
      <DayOfWeek>
        <Typography>
          <strong>{day.dayOfWeek}</strong> {formatDate(day.date)}
        </Typography>
      </DayOfWeek>
      <Box width={1 / 3}>
        <NumberOfItems>{day.numberOfItems || 0}</NumberOfItems>
      </Box>
    </HeaderCellBody>
  );
};

export default CompanyAvailabilityCalendarHeaderCell;
