import { Alert, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import UPDatepicker from 'components/datepicker';
import UPInput from 'components/input';
import UPSwitch from 'components/switch/UPSwitch';
import UPButton from 'components/button';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useCallback, useState, useEffect } from 'react';
import { UPDropdown } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_FINANCIAL__BANK_ACCOUNT,
  COMPANY_FINANCIAL__DATE_MANDATE_SIGNED,
  COMPANY_FINANCIAL__INVOICE_EMAIL,
  COMPANY_FINANCIAL__MANDATE_REFERENCE,
  COMPANY_FINANCIAL__PAYMENT_METHOD,
  COMPANY_FINANCIAL__UPDATED_SUCCESSFULLY,
  GENERAL__CANCEL_BUTTON,
  GENERAL__ERROR_MESSAGE,
  GENERAL__SAVE_AND_NEXT_BUTTON,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  VALIDATION__IS_REQUIRED,
} from 'translations/constants';
import IPaymentInformation from 'types/company/financial/IPaymentInformation';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  companyFinancialErrorSelector,
  companyFinancialLoadingSelector,
  companyFinancialStateSelector,
  companyFinancialUpdateSuccessfulSelector,
  companyFinancialErrorMessageSelector,
} from 'store/selectors/company-financial-selectors';
import {
  getCompanyFinancialAction,
  putCompanyFinancialAction,
  setCompanyFinancialUpdateSuccessfulAction,
} from 'store/actions/company-financial-actions';
import { toNumber } from 'lodash';
import { getInputErrorText, getPaymentMethodsWithTranslation } from 'utils/helpers';
import { bicStateSelector } from 'store/selectors/financial-selectors';
import { getBicAction, setBicAction, setBicErrorAction } from 'store/actions/financial-actions';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  ButtonsContainer,
  CloseAlertIcon,
  CompanyInformationLeftContainer,
  CompanyInformationRightContainer,
  InputBox,
  SectionContainer,
  SubSectionContainer,
} from '../styles';

const CompanyFinancialInitialState: IPaymentInformation = {
  bankAccount: '',
  bic: '',
  invoiceEmail: '',
  paymentMethod: '',
  mandateReference: '',
  dateMandateSigned: null,
};

const EditPaymentInformation = (): JSX.Element => {
  const [t] = useTranslation();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const companyFinancial = useAppSelector(companyFinancialStateSelector);
  const companyBic = useAppSelector(bicStateSelector);
  const companyFinancialLoading = useAppSelector(companyFinancialLoadingSelector);
  const companyFinancialError = useAppSelector(companyFinancialErrorSelector);
  const companyFinancialUpdateSuccessful = useAppSelector(companyFinancialUpdateSuccessfulSelector);
  const companyErrorFinancialErrorMessage = useAppSelector(companyFinancialErrorMessageSelector);

  const [saveAndNextClicked, setSaveAndNextClicked] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [paymentMethodError, setPaymentMethodError] = useState<boolean>(false);
  const [useDifferentEmail, setUseDifferentEmail] = useState<boolean>(CompanyFinancialInitialState.invoiceEmail !== '');

  function getErrorMessage(customError: string): string {
    return typeof customError !== 'undefined' && customError ? customError : t(GENERAL__ERROR_MESSAGE);
  }
  const paymentMethodOptions = getPaymentMethodsWithTranslation();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm<IPaymentInformation>({
    defaultValues: CompanyFinancialInitialState,
  });

  const onChangePaymentMethod = useCallback(event => {
    setPaymentMethod(event.target.value);
    setPaymentMethodError(false);
  }, []);

  const onChangeBic = useCallback(
    event => {
      dispatch(setBicAction(event.target.value));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getCompanyFinancialAction(toNumber(companyId)));
    dispatch(setCompanyFinancialUpdateSuccessfulAction(false));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyFinancial) {
      reset(companyFinancial);
      setPaymentMethod(companyFinancial.paymentMethod);
      setUseDifferentEmail(companyFinancial.invoiceEmail !== '');
    }
  }, [companyFinancial, reset]);

  useEffect(() => {
    if (saveAndNextClicked && companyFinancialUpdateSuccessful) {
      setSaveAndNextClicked(false);
      setTimeout(() => {
        dispatch(setCompanyFinancialUpdateSuccessfulAction(false));
        navigate(`/company/${companyId}/integration`);
      }, 1000);
    }
  }, [navigate, saveAndNextClicked, companyId, dispatch, companyFinancialError, companyFinancialUpdateSuccessful]);

  const onSaveFinancial: SubmitHandler<any> = useCallback(() => {
    const values = getValues();
    if (paymentMethod === '') {
      setPaymentMethodError(true);
    } else {
      setPaymentMethodError(false);
    }
    let switchWasInnactivated = false;
    if (!useDifferentEmail) {
      if (values.invoiceEmail !== null) {
        switchWasInnactivated = true;
        values.invoiceEmail = null;
      }
    }
    if ((isDirty || paymentMethod !== values.paymentMethod || switchWasInnactivated) && paymentMethod !== '') {
      if (paymentMethod === '2') {
        values.mandateReference = '';
        values.dateMandateSigned = null;
      }
      values.paymentMethod = paymentMethod;
      values.bic = companyBic;
      dispatch(putCompanyFinancialAction({ companyId, data: values }));
    }
  }, [companyBic, companyId, dispatch, getValues, isDirty, paymentMethod, useDifferentEmail]);

  const onSaveAndNext = useCallback(() => {
    handleSubmit(onSaveFinancial)();
    setSaveAndNextClicked(true);
  }, [handleSubmit, onSaveFinancial]);

  const onCancel = useCallback(() => {
    dispatch(setCompanyFinancialUpdateSuccessfulAction(false));
    dispatch(setBicErrorAction(false));
    navigate(`/company/${companyId}/managers`);
  }, [companyId, dispatch, navigate]);

  const onChangeEmailSwitch = useCallback(value => {
    setUseDifferentEmail(!value);
  }, []);

  return (
    <SectionContainer>
      <SubSectionContainer>
        <CompanyInformationLeftContainer>
          <InputBox>
            <Controller
              name="bankAccount"
              control={control}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={() => {
                    dispatch(getBicAction({ id: companyId, iban: field.value }));
                  }}
                  label={t(COMPANY_FINANCIAL__BANK_ACCOUNT)}
                  placeholder={t(COMPANY_FINANCIAL__BANK_ACCOUNT)}
                />
              )}
            />
          </InputBox>
          <InputBox>
            <UPInput value={companyBic} onChange={onChangeBic} label="BIC" placeholder="BIC" />
          </InputBox>
          <Box pl="10px" pt="10px" mt="32px" height="49px">
            <FormControlLabel
              control={<UPSwitch checked={useDifferentEmail} onChange={() => onChangeEmailSwitch(useDifferentEmail)} />}
              label={<span>{`${t(COMPANY_FINANCIAL__INVOICE_EMAIL)}?`}</span>}
              sx={{
                '.MuiTypography-root': {
                  fontSize: '14px',
                  marginLeft: '10px',
                  marginTop: '2px',
                },
                '&.MuiFormControlLabel-root': {
                  marginBottom: '8px',
                },
              }}
            />
          </Box>
          {useDifferentEmail && (
            <Controller
              name="invoiceEmail"
              control={control}
              rules={{ required: true, maxLength: 50, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(COMPANY_FINANCIAL__INVOICE_EMAIL)}
                  placeholder={t(COMPANY_FINANCIAL__INVOICE_EMAIL)}
                  error={!!errors.invoiceEmail}
                  required
                  helperText={getInputErrorText(errors, 'invoiceEmail', undefined, 50)}
                />
              )}
            />
          )}
        </CompanyInformationLeftContainer>
        <CompanyInformationRightContainer>
          <InputBox>
            <UPDropdown
              items={paymentMethodOptions}
              onChange={onChangePaymentMethod}
              value={paymentMethod}
              label={t(COMPANY_FINANCIAL__PAYMENT_METHOD)}
              required
              error={paymentMethodError}
              helperText={t(VALIDATION__IS_REQUIRED)}
              placeholder={t(GENERAL__SELECT)}
            />
          </InputBox>
          {paymentMethod === '1' && (
            <Box>
              <InputBox sx={{ width: '230px' }}>
                <Controller
                  name="mandateReference"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      label={t(COMPANY_FINANCIAL__MANDATE_REFERENCE)}
                      placeholder={t(COMPANY_FINANCIAL__MANDATE_REFERENCE)}
                      error={!!errors.mandateReference}
                      required
                      helperText={getInputErrorText(errors, 'mandateReference', undefined, 50)}
                    />
                  )}
                />
              </InputBox>
              <InputBox>
                <Controller
                  name="dateMandateSigned"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDatepicker
                      label={t(COMPANY_FINANCIAL__DATE_MANDATE_SIGNED)}
                      value={field.value}
                      onChange={field.onChange}
                      required
                      error={!!errors.dateMandateSigned}
                      helperText={getInputErrorText(errors, 'dateMandateSigned')}
                    />
                  )}
                />
              </InputBox>
            </Box>
          )}
        </CompanyInformationRightContainer>
      </SubSectionContainer>
      <ButtonsContainer sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex' }}>
          <Box>
            <UPButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={() => handleSubmit(onSaveFinancial)()}
              disabled={companyFinancialLoading}
            />
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            <UPButton
              text={t(GENERAL__SAVE_AND_NEXT_BUTTON)}
              onClick={onSaveAndNext}
              disabled={companyFinancialLoading}
            />
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} outlined />
          </Box>
        </Box>
      </ButtonsContainer>
      <Box sx={{ marginTop: '32px' }}>
        {companyFinancialError && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {getErrorMessage(companyErrorFinancialErrorMessage)}
          </Alert>
        )}
        {companyFinancialUpdateSuccessful && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_FINANCIAL__UPDATED_SUCCESSFULLY)}
          </Alert>
        )}
      </Box>
    </SectionContainer>
  );
};

export default EditPaymentInformation;
