import { ResponsiveContainer } from 'recharts';
import { ClientPerformanceData } from 'types/reporting/ClientPerformanceData';
import PerformanceLine from './PerformanceLine';

const ClientPerformanceReport = (props: { items: ClientPerformanceData[] }): JSX.Element => {
  const { items } = props;

  return (
    <ResponsiveContainer width="100%" height="60%">
      <PerformanceLine items={items} />
    </ResponsiveContainer>
  );
};

export default ClientPerformanceReport;
