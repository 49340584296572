/* eslint-disable react/jsx-props-no-spreading */
import { ReactComponent as UPLogo } from '../../assets/icons/UpdateProLogo.svg';
import { AuthHeaderBox } from './styles';

const AuthHeader = (): JSX.Element => {
  return (
    <AuthHeaderBox>
      <UPLogo />
    </AuthHeaderBox>
  );
};

export default AuthHeader;
