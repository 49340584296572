import { Box } from '@mui/material';
import { toNumber } from 'lodash';
import { CompanyAvailabilityEmployeeCellProps } from 'modules/availabilities/company-availability-2/types';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { requestAvailabilityAction } from 'store/actions/availabilities-actions';
import {
  AVAILABILITIES__REQUEST_AVAILABILITY,
  EMPLOYEES__EXTRA,
  EMPLOYEES__FLEXI,
  EMPLOYEES__LABOURER,
  EMPLOYEES__PERMANENT,
  EMPLOYEES__SERVANT,
  EMPLOYEES__STUDENT,
} from 'translations/constants';
import { ReactComponent as EditEmployeeAvatar } from '../../../../../../assets/icons/EditEmployeeAvatar.svg';
import {
  EmployeeAvatarBox,
  EmployeeCellBody,
  EmployeeCellContainer,
  EmployeeDetailsBox,
  EmployeeNameBox,
  RequestAvailabilityButton,
} from './styles';

const CompanyAvailabilityEmployeeCell = (props: CompanyAvailabilityEmployeeCellProps) => {
  const { employee, hasSubmittedAvailability, month, companyId } = props;

  const dispatch = useAppDispatch();
  const [t] = useTranslation();

  // constants!!
  const findRightTranslation = (workerclass: number) => {
    switch (workerclass) {
      case 1:
        return t(EMPLOYEES__STUDENT);
      case 2:
        return t(EMPLOYEES__FLEXI);
      case 3:
        return t(EMPLOYEES__EXTRA);
      case 4:
        return t(EMPLOYEES__LABOURER);
      case 5:
        return t(EMPLOYEES__SERVANT);
      default:
        return t(EMPLOYEES__PERMANENT);
    }
  };

  const onClickRequestAvailability = () => {
    dispatch(
      requestAvailabilityAction({
        companyId: toNumber(companyId),
        month,
        employeeId: employee.id,
      }),
    );
  };

  return (
    <EmployeeCellContainer>
      <EmployeeCellBody>
        <EmployeeAvatarBox>
          <EditEmployeeAvatar />
        </EmployeeAvatarBox>
        <EmployeeDetailsBox>
          <EmployeeNameBox>{`${employee.firstName} ${employee.lastName}`}</EmployeeNameBox>
          {hasSubmittedAvailability ? (
            <Box sx={{ fontSize: 'small' }}>{findRightTranslation(employee.workerclass)}</Box>
          ) : (
            <RequestAvailabilityButton variant="outlined" onClick={onClickRequestAvailability}>
              {t(AVAILABILITIES__REQUEST_AVAILABILITY)}
            </RequestAvailabilityButton>
          )}
        </EmployeeDetailsBox>
      </EmployeeCellBody>
    </EmployeeCellContainer>
  );
};

export default CompanyAvailabilityEmployeeCell;
