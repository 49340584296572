import { createReducer } from '@reduxjs/toolkit';
import { setDrawerStatusAction } from 'store/actions/drawer-actions';
import IDrawerState from 'types/drawer/IDrawerState';

const initialState: IDrawerState = {
  status: false,
};

const drawerReducer = createReducer(initialState, builder =>
  builder.addCase(setDrawerStatusAction, (state, action) => ({ ...state, status: action.payload })),
);

export default drawerReducer;
