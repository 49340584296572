import { Box, styled } from '@mui/material';

export const CalendarHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '85%',
  padding: `${theme.spacing(1)} 0`,
}));

export const CalendarHeaderBody = styled(Box)(({ theme }) => ({
  height: '80px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.03)',
  backgroundColor: 'white',
  borderRadius: '8px',
  border: 'none',
  padding: `${theme.spacing(2)} 0px`,
}));

export const LeftArrowContainer = styled(Box)(() => ({
  width: '2%',
  display: 'flex',
  cursor: 'pointer',
}));

export const RightArrowContainer = styled(Box)(() => ({
  width: '1%',
  display: 'flex',
  cursor: 'pointer',
  flexDirection: 'row-reverse',
}));
