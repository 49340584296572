import { Input, Select, styled } from '@mui/material';

const ITEM_HEIGHT = 49;
const ITEM_PADDING_TOP = 8;

export const UPSelectStyled = styled(Select)(({ theme }) => ({
  height: ITEM_HEIGHT,
  borderRadius: '4px',
  background: 'white',
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

export const DropdownPlaceholder = styled('span')(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '14px',
}));

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export const UPDropdownHelperText = styled('p')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.error.main,
  marginTop: '3px',
  marginBottom: '0px',
}));

export const CompanyNameInputStyled = styled(Input)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));
