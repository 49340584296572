import { Area, ComposedChart, LabelList, Line, ResponsiveContainer, Scatter, XAxis, YAxis } from 'recharts';

const ContractsGraph = (props: { data; maxValue }) => {
  const { data, maxValue } = props;

  return (
    <ResponsiveContainer width="100%" height="100%" className="contracts-graph">
      <ComposedChart data={data} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
        <YAxis reversed dataKey="place" domain={[0, maxValue]} axisLine={false} hide />
        <XAxis dataKey="name" axisLine={false} tickLine={false} interval={0} tick={{ fontSize: 14 }} />
        <Area dataKey="place" fill="#66ba72" stroke="none" type="monotone" fillOpacity={1} />
        <Line dataKey="place" stroke="#008C15" type="monotone" strokeWidth={6} dot={false} />
        <Scatter
          dataKey="value"
          fill="#008C15"
          shape={<circle cx={5} cy={5} r={10} fill="#008c15" />}
          fillOpacity={1}
          strokeWidth={3}
          stroke="#fff"
          isAnimationActive={false}
        >
          <LabelList
            dataKey="value"
            position="top"
            style={{
              fontSize: '24px',
              fontFamily: 'Montserrat,sans-serif',
              fill: '#fff',
              fontWeight: 'bold',
            }}
            offset={15}
          />
        </Scatter>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ContractsGraph;
