import { Box, Tooltip, Typography } from '@mui/material';
import { getWageRequest } from 'api/requests/contract-requests';
import UPSnackBar from 'components/app-container/snackbar';
import BottomGreenBar from 'components/bottom-green-bar';
import CopyWeeklyModal from 'components/copy-weekly-modal';
import DeleteModal from 'components/delete-modal';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import { FilterButton, StyledFilterIcon } from 'components/filters-menu/styles';
import MessageModal from 'components/message-modal';
import UPWeekPicker from 'components/week-picker';
import { AllowedCompaniesForAvailability } from 'constants/CustomFeatureEnabledForCompanies';
import { CoefficientsPerWorkerClassOptions } from 'constants/CoefficientsPerWorkerclass';
import { CompanyAvailabilityFilterType } from 'constants/CompanyAvailabilityFilterType';
import { CompanyRole } from 'constants/CompanyRole';
import { ContractListViewTypes } from 'constants/ContractListViewTypes';
import { ContractPageSize } from 'constants/ContractPageSize';
import { ContractsPageViewType } from 'constants/ContractsPageViewType';
import { toNumber } from 'lodash';
import { createDefaultContractFilters } from 'mappers/contracts-mapper';
import { CompanyAvailability } from 'modules/availabilities/company-availability-2';
import moment from 'moment';
import { SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  exportCompanyAvailabilityAction,
  requestAvailabilityFilteredAction,
} from 'store/actions/availabilities-actions';
import { getCompaniesAsDropdownItemsAction } from 'store/actions/company-actions';
import {
  clearCompanyAvailabilityFiltersAction,
  setCompanyAvailabilityFiltersAction,
} from 'store/actions/company-availability-filters-actions';
import { getCostCentersAction, getCostCentersDropdownItemsAction } from 'store/actions/company-cost-centers-actions';
import { getFunctionsAsDropdownItemsAction } from 'store/actions/company-functions-actions';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import { getVendorsAction } from 'store/actions/company-vendors-actions';
import {
  deleteContractsAction,
  directlyCardToCopyAction,
  getContractsAction,
  getContractsInListViewAction,
  saveContractAction,
  saveContractSlotAction,
  selectedContractsAction,
  shouldCopyAction,
  tooManyContractsAction,
  validateContractErrorMessageAction,
  validateContractErrorMessageExtraInfoAction,
  validateContractErrorMessageRowAction,
  validateContractSuccessAction,
  validateContractsAction,
  validateContractsSuccessAction,
} from 'store/actions/contract-actions';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { companyAvailabilityFiltersSelector } from 'store/selectors/company-availability-filters-selector';
import { costCentersDropdownItemsSelector } from 'store/selectors/company-cost-centers-selectors';
import { functionsDropdownItemsSelector } from 'store/selectors/company-functions-selectors';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { companiesDropdownItemsSelector } from 'store/selectors/company-selectors';
import { vendorsSelector } from 'store/selectors/company-vendors-selectors';
import {
  saveContractSuccessSelector,
  validateContractErrorMessageSelector,
  validateContractExtraMessageSelector,
  validateContractsSuccessSelector,
} from 'store/selectors/contract-selectors';
import {
  deleteContractsSuccessActionSelector,
  getContractsErrors,
  getContractsSelector,
  getSelectedContractsSelector,
  getShouldCopyContractsSelector,
  tooManyContractsSelector,
} from 'store/selectors/contracts-selector';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  AVAILABILITIES__REQUEST_ALL_AVAILABILITIES,
  AVAILABILITIES__REQUEST_BASED_ON_FILTERS,
  COMPANY__COMPANY,
  CONTRACTS__ALREADY_CONFIRMED,
  CONTRACTS__ARE_YOU_SURE,
  CONTRACTS__COPY_SELECTED,
  CONTRACTS__COST_CENTER,
  CONTRACTS__DEFAULT_ERROR,
  CONTRACTS__DELETE_CONTRACT_WITH_ID_ERROR,
  CONTRACTS__DELETE_PAST_CONTRACT_ERROR,
  CONTRACTS__DELETE_SELECTED,
  CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING,
  CONTRACTS__FUNCTION,
  CONTRACTS__NEW_CONTRACT,
  CONTRACTS__NEW_CONTRACT_SLOT,
  CONTRACTS__NEW_CONTRACT_SLOT_DISABLED,
  CONTRACTS__PASTE_WEEKLY_CONTRACT,
  CONTRACTS__PLEASE_USE_FILTERS,
  CONTRACTS__SIGNED_CONTRACT_ERROR,
  CONTRACTS__TO_BE_CONFIRMED,
  EMPLOYEE__COMPANY_LOCATION,
  GENERAL__AGE,
  GENERAL__CANCEL_BUTTON,
  GENERAL__DAY,
  GENERAL__NAME,
  GENERAL__NO,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  GENERAL__YES,
  HEADER__WEEK,
  MENU__CONTRACTS,
} from 'translations/constants';
import ContractFilters from 'types/contract/IContractFilters';
import IWageData from 'types/contract/IWageData';
import { addOrSubtractDays, generateCurrentWeekDays, getWeekDaysAbbreviations, treatAsUTC } from 'utils/helpers';
import { ReactComponent as AvailabilityIconGreen } from '../../assets/icons/AvailabilitiesIconGreen.svg';
import { ReactComponent as AvailabilityIconGrey } from '../../assets/icons/AvailabilitiesIconGrey.svg';
import { ReactComponent as CalendarViewIconGreen } from '../../assets/icons/CalendarViewContractsGreen.svg';
import { ReactComponent as CalendarViewIconGrey } from '../../assets/icons/CalendarViewContractsGrey.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/CopyFull.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/DeleteFull.svg';
import { ReactComponent as ListViewIconGreen } from '../../assets/icons/ListViewContractsGreen.svg';
import { ReactComponent as ListViewIconGrey } from '../../assets/icons/ListViewContractsGrey.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/DownloadGreen.svg';
import FiltersMenu from '../../components/filters-menu';
import ContractsGridView from './grid/ContractsGridView';
import {
  generateAgeFilterOptions,
  generateCardsObject,
  generateCompanyEmployeesList,
  generateCurrentWeekDaysList,
  generateFormattedContractToAddOnCopy,
  generateWeeklyContractToCopy,
  getCurrentTime,
  getCurrentWeek,
  isAnyContractWeekly,
} from './helpers';
import ContractsListView from './list/ContractsListView';
import AddContractSlotModal from './modals/AddContractSlotModal';
import AddEditContractModal from './modals/AddEditContractModal';
import ConfirmContractModal from './modals/ConfirmContractModal';
import {
  AddNewContractButton,
  CompanyUserFilter,
  IconWithMargin,
  StyledAddVendorSlotIcon,
  StyledContractIcon,
} from './styles';
import './styles.scss';
import { ErrorMessages, IContractData, IContractToEdit, IDayProps, PageFilter } from './types';

const Contracts = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { pCompanyId, pYear, pWeek } = useParams();
  const contracts = useAppSelector(getContractsSelector);
  const vendors = useAppSelector(vendorsSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);
  const contractsErrors = useAppSelector(getContractsErrors);
  const allContractsAreValid = useAppSelector(validateContractsSuccessSelector);
  const contractValidationErrors = useAppSelector(validateContractErrorMessageSelector);
  const contractValidationExtraInfo = useAppSelector(validateContractExtraMessageSelector);
  const selectedContractsFromRedux = useAppSelector(getSelectedContractsSelector);
  const copy = useAppSelector(getShouldCopyContractsSelector);
  const deleteContractsSuccess = useAppSelector(deleteContractsSuccessActionSelector);
  const saveContractSuccess = useAppSelector(saveContractSuccessSelector);
  const companyEmployees = useAppSelector(employeesWithWorkerclassSelector);
  const profile = useAppSelector(profileSelector);
  const isAdmin = profile.role === CompanyRole.ADMIN;
  const userCompanyId = profile.companyId;
  const isEmployee = profile.role === CompanyRole.EMPLOYEE;
  const allowedVendorRoles = useMemo(() => [CompanyRole.ADMIN, CompanyRole.COMPANY_MANAGER], []);
  const allowedAvailabilityRoles = useMemo(
    () => [CompanyRole.ADMIN, CompanyRole.COMPANY_MANAGER, CompanyRole.HR_MANAGER, CompanyRole.LOCATION_MANAGER],
    [],
  );
  const companiesDropdownItems = useAppSelector(companiesDropdownItemsSelector);
  const costCentersList = useAppSelector(costCentersDropdownItemsSelector);
  const [costCenters, setCostCenters] = useState<UPDropdownItem[]>(costCentersList);
  const functionsList = useAppSelector(functionsDropdownItemsSelector);
  const locationsList = useAppSelector(locationsDropdownItemsSelector);
  const companyAvailabilityFilters = useAppSelector(companyAvailabilityFiltersSelector);

  const tooManyContacts = useAppSelector(tooManyContractsSelector);
  const [openTooManyContractsModal, setOpenTooManyContractsModal] = useState<boolean>(false);
  const [contractsErrorWithOpen, setContractsErrorWithOpen] = useState<any>([]);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [companyId, setCompanyId] = useState<string>(userCompanyId?.toString() ?? pCompanyId);
  const [costCenter, setCostCenter] = useState<string>('');
  const [selectedEmployee, setSelectedEmployee] = useState<string>('');
  const [selectedDay, setSelectedDay] = useState<string>('');
  const [selectedFunction, setSelectedFunction] = useState<string>('');
  const [contractType, setContractType] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>();
  const [openAlreadyConfirmed, setOpenAlreadyConfirmed] = useState<boolean>();
  const [cardToConfirm, setCardToConfirm] = useState<IContractData>();
  const [toBeConfirmed, setToBeConfirmed] = useState<string>('');
  const [showBottomGreenBar, setShowBottomGreenBar] = useState<boolean>(true);
  const [allContracts, setAllContracts] = useState<IContractData[]>([]);
  const [selectedContractsIds, setSelectedContractsIds] = useState<number[]>([]);
  const [contractsForValidation, setContractsForValidation] = useState<any[]>([]);
  const [openConfirmCloseModal, setOpenConfirmCloseModal] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [week, setWeek] = useState<Date | null>(getCurrentTime().date);
  const currentWeek = pWeek ? toNumber(pWeek) : getCurrentWeek(week);
  const [checkedDays, setCheckedDays] = useState<{ date: Date; checked: boolean }[]>(
    generateCurrentWeekDays(currentWeek),
  );
  const [numberOfContractsSelected, setNumberOfContractsSelected] = useState<number>(0);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openContractSlotModal, setOpenContractSlotModal] = useState<boolean>(false);
  const [cardToEdit, setCardToEdit] = useState<IContractToEdit>(null);
  const [openDeletePastContractModal, setOpenDeletePastContractModal] = useState<boolean>(false);
  const [openConfirmDeleteWeeklyModal, setOpenConfirmDeleteWeeklyModal] = useState<boolean>(false);
  const [viewType, setViewType] = useState<ContractsPageViewType>(ContractsPageViewType.CALENDAR);
  const [employees, setEmployees] = useState<UPDropdownItem[]>([]);
  const [copyWeekly, setCopyWeekly] = useState<boolean>(false);
  const [year, setYear] = useState<number | null>(() =>
    pYear?.length > 1 ? toNumber(pYear) : new Date(Date.now()).getFullYear(),
  );
  const [auxMaxDate, setAuxMaxDate] = useState<any>(null);
  const [openValidationError, setOpenValidationError] = useState<boolean>(false);
  const [filtersPopoverAnchorEl, setFiltersPopoverAnchorEl] = useState<null | HTMLElement>(null);
  const [age, setAge] = useState<string>('');
  const [disabledCreateButtons, setDisabledCreateButtons] = useState<boolean>(false);
  const [displayedContracts, setDisplayedContracts] = useState<number>(0);
  const [fetchPressed, setFetchPressed] = useState<boolean>(false);
  const [batchSize, setBatchSize] = useState<number>(ContractPageSize.Default);
  const [listViewType, setListViewType] = useState<number>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const toBeConfirmedOptions = useMemo(() => {
    return [
      { value: '0', label: t(GENERAL__NO) },
      { value: '1', label: t(GENERAL__YES) },
    ];
  }, [t]);

  const weekDaysNames = getWeekDaysAbbreviations();

  const ageOptions = generateAgeFilterOptions();

  const contractsErrorMessages = useMemo(() => {
    return {
      default: t(CONTRACTS__DEFAULT_ERROR),
      signedPastContract: t(CONTRACTS__SIGNED_CONTRACT_ERROR),
      deletePastContract: t(CONTRACTS__DELETE_CONTRACT_WITH_ID_ERROR),
    };
  }, [t]);

  const bottomGreenBarActions = useMemo(() => {
    return [
      {
        content: t(CONTRACTS__DELETE_SELECTED),
        icon: <DeleteIcon />,
      },
      {
        content: t(CONTRACTS__COPY_SELECTED),
        icon: <CopyIcon />,
      },
    ];
  }, [t]);

  const checkConfirmedFilter = useCallback(() => {
    let confirmed = false;

    if (toBeConfirmed === '1') {
      confirmed = true;
    } else if (toBeConfirmed === '0') {
      confirmed = false;
    } else {
      confirmed = null;
    }

    return confirmed;
  }, [toBeConfirmed]);

  const createFilters = useCallback(
    (pageSize = 0) => {
      if (pageSize > 0) {
        setDisplayedContracts(displayedContracts + pageSize + batchSize);
        setBatchSize(batchSize + pageSize);
      }

      const filter = {
        year,
        week: currentWeek,
        pageNumber: 1,
        pageSize: batchSize + pageSize,
        costCenterId: toNumber(costCenter),
        functionId: toNumber(selectedFunction),
        workerClass: contractType,
        locationId: toNumber(location),
        confirmHours: checkConfirmedFilter(),
        employeeId: toNumber(selectedEmployee),
        date: selectedDay ? new Date(selectedDay).toISOString() : null,
      };

      return filter;
    },
    [
      year,
      currentWeek,
      costCenter,
      selectedFunction,
      contractType,
      location,
      selectedEmployee,
      selectedDay,
      displayedContracts,
      batchSize,
      checkConfirmedFilter,
    ],
  );

  const createDefaultListViewFilters = useCallback((): ContractFilters => {
    const filter = {
      year,
      week: currentWeek,
      pageNumber: 1,
      pageSize: ContractPageSize.DefaultListView,
      costCenterId: toNumber(costCenter),
      functionId: toNumber(selectedFunction),
      workerClass: contractType,
      locationId: toNumber(location),
      confirmHours: checkConfirmedFilter(),
      employeeId: toNumber(selectedEmployee),
      date: selectedDay ? new Date(selectedDay).toISOString() : null,
    };

    return filter;
  }, [
    year,
    currentWeek,
    costCenter,
    selectedFunction,
    contractType,
    location,
    selectedEmployee,
    selectedDay,
    checkConfirmedFilter,
  ]);

  const workerclassList = useMemo(() => CoefficientsPerWorkerClassOptions(), []);

  const listViewFilters = useMemo((): PageFilter[] => {
    const filters: PageFilter[] = [
      {
        label: t(EMPLOYEE__COMPANY_LOCATION),
        value: location,
        setValue: setLocation,
        items: locationsList,
        component: UPDropdownWithSearch,
      },
      {
        label: t(GENERAL__WORKERCLASS),
        value: contractType,
        setValue: setContractType,
        items: workerclassList,
        component: UPDropdown,
        canBeEmpty: true,
      },
      {
        label: t(CONTRACTS__FUNCTION),
        value: selectedFunction,
        setValue: setSelectedFunction,
        items: functionsList,
        component: UPDropdownWithSearch,
      },
    ];

    return filters;
  }, [contractType, functionsList, location, locationsList, selectedFunction, t, workerclassList]);

  const calendarViewFilters = useMemo((): PageFilter[] => {
    const filters: PageFilter[] = [
      {
        label: t(EMPLOYEE__COMPANY_LOCATION),
        value: location,
        setValue: setLocation,
        items: locationsList,
        component: UPDropdownWithSearch,
      },
      {
        label: t(GENERAL__WORKERCLASS),
        value: contractType,
        setValue: setContractType,
        items: workerclassList,
        component: UPDropdown,
        canBeEmpty: true,
      },
      {
        label: t(CONTRACTS__FUNCTION),
        value: selectedFunction,
        setValue: setSelectedFunction,
        items: functionsList,
        component: UPDropdownWithSearch,
      },
      {
        label: t(CONTRACTS__TO_BE_CONFIRMED),
        value: toBeConfirmed,
        setValue: setToBeConfirmed,
        items: toBeConfirmedOptions,
        component: UPDropdown,
        canBeEmpty: true,
      },
    ];

    return filters;
  }, [
    contractType,
    functionsList,
    location,
    locationsList,
    selectedFunction,
    t,
    toBeConfirmed,
    toBeConfirmedOptions,
    workerclassList,
  ]);

  const availabilityViewFilters = useMemo((): PageFilter[] => {
    const filters: PageFilter[] = [
      {
        label: t(EMPLOYEE__COMPANY_LOCATION),
        value: location,
        setValue: setLocation,
        items: locationsList,
        component: UPDropdownWithSearch,
      },
      {
        label: t(GENERAL__WORKERCLASS),
        value: contractType,
        setValue: setContractType,
        items: workerclassList,
        component: UPDropdown,
        canBeEmpty: true,
      },
      {
        label: t(GENERAL__AGE),
        value: age,
        setValue: setAge,
        items: ageOptions,
        component: UPDropdown,
        canBeEmpty: true,
      },
    ];

    return filters;
  }, [age, ageOptions, contractType, location, locationsList, t, workerclassList]);

  const isThereAnyItemsChecked = useCallback((itemsArray: IContractData[] | { date: Date; checked: boolean }[]) => {
    return itemsArray.find((i: IContractData | { date: Date; checked: boolean }) => i.checked === true);
  }, []);

  const createCheckedContractsArray = useCallback(() => {
    const constractsIds: SetStateAction<number[]> = [];
    const checkedContracts = allContracts.filter((c: IContractData) => c.checked === true);

    checkedContracts.forEach((c: IContractData) => constractsIds.push(c.id));

    let maxDate: Date | null = null;
    checkedContracts.forEach((c: IContractData) => {
      if (new Date(c.date) > maxDate) maxDate = new Date(c.date);
    });

    setSelectedContractsIds(constractsIds);
    setNumberOfContractsSelected(checkedContracts.length);
  }, [allContracts]);

  const onCompanyChange = useCallback(
    (company: string) => {
      setCompanyId(company);
      setViewType(ContractsPageViewType.CALENDAR);
      setListViewType(ContractListViewTypes.Future);
      dispatch(
        getContractsAction({
          companyId: toNumber(company),
          filter: createDefaultContractFilters(year, currentWeek),
        }),
      );

      dispatch(getCostCentersAction(toNumber(company)));

      if (allowedVendorRoles.includes(profile.role)) {
        dispatch(getVendorsAction(company));
      }
    },
    [dispatch, year, currentWeek, allowedVendorRoles, profile],
  );

  const onChangeWeek = useCallback(
    (timePeriod: string, date: Date) => {
      const timeMultiplier = timePeriod === 'past' ? -1 : 1;
      const newCheckedDays: { date: Date; checked: boolean }[] = [];
      for (let counter = 1; counter <= 7; ++counter) {
        const day = {
          date: addOrSubtractDays(counter * timeMultiplier, date),
          checked: false,
        };
        if (timePeriod === 'past') {
          newCheckedDays.unshift(day);
        } else {
          newCheckedDays.push(day);
        }
      }
      setWeek(treatAsUTC(newCheckedDays[0].date));
      setYear(newCheckedDays[0].date.getFullYear());
      setCheckedDays(newCheckedDays);
    },
    [setWeek, setYear, setCheckedDays],
  );

  const onClearAll = () => {
    setShowBottomGreenBar(false);
    const contractsCleared = allContracts.map((c: IContractData) => {
      if (c.checked)
        return {
          ...c,
          checked: false,
        };

      return c;
    });
    setAllContracts(contractsCleared);
    dispatch(shouldCopyAction({ copy: false }));
    dispatch(selectedContractsAction({ selectedContracts: [] }));
  };

  const makeDeleteRequest = (ids: number[]) => {
    dispatch(
      deleteContractsAction({
        companyId: toNumber(companyId),
        contractsIds: ids,
        year,
        weekNumber: currentWeek,
      }),
    ).then(() => {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createFilters(),
        }),
      );
    });

    dispatch(selectedContractsAction({ selectedContracts: [] }));
  };

  const onDeleteCheckedContracts = (ids: number[]) => {
    const newContracts = allContracts.filter((c: IContractData) => ids.includes(c.id));
    setSelectedContractsIds(ids);
    const pastContractFound = newContracts.find((c: IContractData) => !c.editable);
    if (pastContractFound && !isAdmin) setOpenDeletePastContractModal(true);
    else if (isAnyContractWeekly(newContracts)) setOpenConfirmDeleteWeeklyModal(true);
    else {
      makeDeleteRequest(ids);
    }
  };

  const onCopyCheckedContracts = () => {
    if (selectedContractsFromRedux.length === 0) {
      const checkedContracts = allContracts.filter((c: IContractData) => c.checked);
      dispatch(selectedContractsAction({ selectedContracts: checkedContracts }));
    }
    dispatch(shouldCopyAction({ copy: true }));
  };

  const onCloseCopyWeeklyModal = () => {
    dispatch(shouldCopyAction({ copy: false }));
    const uncheckedContracts = allContracts.map((c: IContractData) => {
      return {
        ...c,
        checked: false,
      };
    });
    dispatch(directlyCardToCopyAction({ id: null }));
    setAllContracts(uncheckedContracts);
  };

  const onClickEditCard = (id: number, isDaily: boolean, parentId?: string) => {
    if (isDaily) {
      const card = allContracts.find((c: IContractData) => c.id === id);
      const specialCard = {
        contractId: card.contractId,
        employee: card?.employee,
        error: false,
        contractType: card?.contractType,
        functionId: card?.functionId,
        costCenterId: card?.costCenterId,
        locationId: card?.locationId,
        workingLocation: card?.workingLocation,
        companyId: card?.companyId,
        dates: [
          {
            id: card.id,
            date: card.date,
            startHour: card.startHour,
            endHour: card.endHour,
            startBreak: card.startBreak,
            endBreak: card.endBreak,
          },
        ],
        date: card.date,
        errorMessage: card.errorMessage ? card.errorMessage : null,
        warningMessage: card.warningMessage,
        dimonaId: card?.dimonaId?.toString(),
      };
      setCardToEdit(specialCard);
      setOpenEditModal(true);
      dispatch(validateContractSuccessAction(false));
    } else {
      const weeklyArray = generateCardsObject(parentId, allContracts);
      setCardToEdit(weeklyArray);
      setOpenEditModal(true);
      dispatch(validateContractSuccessAction(false));
    }
  };

  const onClickFillSlot = (id: number) => {
    const contractSlot = allContracts.find((c: IContractData) => c.id === id);
    const prefilledContractSlot: IContractToEdit = {
      contractId: contractSlot.contractId,
      employee: null,
      vendor: contractSlot.vendor,
      error: false,
      contractType: contractSlot?.contractType,
      functionId: contractSlot?.functionId,
      costCenterId: contractSlot?.costCenterId,
      locationId: contractSlot?.locationId,
      workingLocation: contractSlot?.workingLocation,
      companyId: contractSlot?.companyId,
      dates: [
        {
          id: contractSlot.id,
          date: contractSlot.date,
          startHour: contractSlot.startHour,
          endHour: contractSlot.endHour,
          startBreak: contractSlot.startBreak,
          endBreak: contractSlot.endBreak,
        },
      ],
      date: contractSlot.date,
      errorMessage: contractSlot.errorMessage ? contractSlot.errorMessage : null,
    };
    setCardToEdit(prefilledContractSlot);
    setOpenEditModal(true);
    dispatch(validateContractSuccessAction(false));
  };

  const onClickConfirmCard = (cardId: number) => {
    const auxCardToConfirm = allContracts.find(c => c.id === cardId);
    if (auxCardToConfirm.confirmHours) {
      setOpenAlreadyConfirmed(true);
    } else {
      setCardToConfirm(auxCardToConfirm);
      setOpenConfirmModal(true);
    }
  };

  const onConfirmContract = () => {
    setTimeout(() => {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createFilters(),
        }),
      );
    }, 1500);
  };

  const onAcceptClosingEditModal = () => {
    setOpenEditModal(false);
    setOpenConfirmCloseModal(false);
    dispatch(validateContractSuccessAction(false));
    dispatch(validateContractErrorMessageAction(''));
    dispatch(validateContractErrorMessageExtraInfoAction(''));
    dispatch(validateContractErrorMessageRowAction(null));
  };

  const onWeekPickerChange = (value: Date) => {
    setWeek(value);
    setSelectedDay('');
    const newWeek = getCurrentWeek(value);
    setCheckedDays(generateCurrentWeekDays(newWeek, value));
  };

  const handleErrorClose = (id: number) => {
    const auxErrors = contractsErrorWithOpen.map((c: any) => {
      if (c.id === id)
        return {
          ...c,
          open: false,
        };
      return c;
    });
    setContractsErrorWithOpen(auxErrors);
  };

  const getTheNextFourWeeks = (): UPDropdownItem[] => {
    const currentWeekNumber = Number(moment().format('W'));
    const nextWeeks: UPDropdownItem[] = [];
    for (let i = currentWeekNumber + 1; i <= currentWeekNumber + 4; i++) {
      nextWeeks.push({ value: i, label: `${t(HEADER__WEEK)} ${i}` });
    }
    return nextWeeks;
  };

  const onPasteContracts = async (newContractsDate: Date) => {
    const auxContracts = selectedContractsFromRedux.filter((c: IContractData) => !c.vendor);
    const auxContractSlots = selectedContractsFromRedux.filter((c: IContractData) => c.vendor);
    const auxContractsWithWage = await Promise.all(
      auxContracts.map(async (c: any) => {
        const wageData: IWageData = {
          employeeId: c.employee.id,
          companyId: c.companyId,
          functionId: c.functionId,
        };
        const response = await getWageRequest({
          wageData,
          companyId: c.companyId.toString(),
        });
        return {
          ...c,
          wage: response,
        };
      }),
    );
    const newContracts = [];
    auxContractsWithWage.forEach((c: IContractData) => {
      const newContract = generateFormattedContractToAddOnCopy(c, newContractsDate, `${c.wage}`, currentWeek);
      newContracts.push(newContract);
    });
    auxContractSlots.forEach((c: IContractData) => {
      const newContractSlot = generateFormattedContractToAddOnCopy(c, newContractsDate, '', currentWeek);
      dispatch(saveContractSlotAction({ saveContractSlot: newContractSlot, quantity: 1 })).then(() => {
        dispatch(
          getContractsAction({
            companyId: toNumber(newContractSlot.companyId),
            filter: createDefaultContractFilters(
              newContractSlot.year,
              newContractSlot.week,
              null,
              null,
              ContractPageSize.Default,
            ),
          }),
        );
      });
    });
    setContractsForValidation(newContracts);
    dispatch(validateContractsAction(newContracts));
  };

  const onPasteWeeklyContract = async (weekNumber: number) => {
    const auxContracts = selectedContractsFromRedux;
    const auxContractsWithWage = await Promise.all(
      auxContracts.map(async c => {
        const wageData: IWageData = {
          employeeId: c.employee.id,
          companyId: c.companyId,
          functionId: c.functionId,
        };
        const response = await getWageRequest({
          wageData,
          companyId: c.companyId.toString(),
        });
        return {
          ...c,
          wage: response,
        };
      }),
    );

    const newContracts = [];
    newContracts.push(
      generateWeeklyContractToCopy(auxContractsWithWage, weekNumber, auxContractsWithWage[0].wage.toString()),
    );
    setContractsForValidation(newContracts);
    dispatch(validateContractsAction(newContracts));
  };

  const onClickFilterButton = event => {
    setFiltersPopoverAnchorEl(event.currentTarget);
  };

  const onCloseFilterPopover = () => {
    setFiltersPopoverAnchorEl(null);
  };

  const onChangeLocationFilter = () => {
    dispatch(
      setCompanyAvailabilityFiltersAction([
        { filterType: CompanyAvailabilityFilterType.LOCATION_ID, value: toNumber(location) },
      ]),
    );
  };

  const onChangeWorkerclassFilter = () => {
    dispatch(
      setCompanyAvailabilityFiltersAction([
        { filterType: CompanyAvailabilityFilterType.WORKERCLASS, value: contractType },
      ]),
    );
  };

  const onChangeAgeFilter = () => {
    dispatch(setCompanyAvailabilityFiltersAction([{ filterType: CompanyAvailabilityFilterType.AGE, value: age }]));
  };

  useEffect(onChangeLocationFilter, [location, dispatch]);

  useEffect(onChangeWorkerclassFilter, [contractType, dispatch]);

  useEffect(onChangeAgeFilter, [age, dispatch]);

  const onClearCompanyAvailabilityFilters = () => {
    setLocation('');
    setContractType('');
    setAge('');
    setFiltersPopoverAnchorEl(null);
    dispatch(clearCompanyAvailabilityFiltersAction());
  };

  const onClearContractsFilters = () => {
    setLocation('');
    setContractType('');
    setSelectedFunction('');
    setToBeConfirmed('');
  };

  const updateCompanyAvailabilityMainFilters = () => {
    const filters = [
      {
        filterType: CompanyAvailabilityFilterType.WEEK,
        value: currentWeek,
      },
      {
        filterType: CompanyAvailabilityFilterType.YEAR,
        value: year,
      },
      {
        filterType: CompanyAvailabilityFilterType.COMPANY_ID,
        value: companyId ? toNumber(companyId) : 0,
      },
    ];

    dispatch(setCompanyAvailabilityFiltersAction(filters));
  };

  useEffect(updateCompanyAvailabilityMainFilters, [currentWeek, year, dispatch, companyId]);

  useEffect(() => {
    if (openValidationError) {
      setOpenValidationError(false);
    }
  }, [openValidationError]);

  useEffect(() => {
    if (activeItem !== t(MENU__CONTRACTS)) {
      dispatch(setMenuStatusAction(t(MENU__CONTRACTS)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    if (companyId) {
      setAllContracts(contracts);
    } else {
      setAllContracts([]);
      setEmployees([]);
    }
  }, [contracts, companyId, viewType]);

  useEffect(() => {
    if (copy && selectedContractsFromRedux.length === 0) {
      dispatch(shouldCopyAction({ copy: false }));
    }
  }, [copy, dispatch, selectedContractsFromRedux]);

  useEffect(() => {
    const auxSelectedContracts = selectedContractsFromRedux;
    let maxDate: Date | null = null;
    auxSelectedContracts.forEach((c: IContractData) => {
      if (new Date(c.date) > maxDate) maxDate = new Date(c.date);
    });
    setAuxMaxDate(maxDate);
  }, [selectedContractsFromRedux]);

  useEffect(() => {
    if (contractsErrors) {
      const auxErrors = contractsErrors.map((c: any) => {
        return { ...c, open: true };
      });
      setContractsErrorWithOpen(auxErrors);
    }

    if (tooManyContacts) {
      setOpenTooManyContractsModal(true);
    }
  }, [contractsErrors, tooManyContacts]);

  useEffect(() => {
    if (companyId && !isEmployee && !fetchPressed) {
      dispatch(getCostCentersAction(toNumber(companyId)));
      dispatch(getCostCentersDropdownItemsAction({ companyId: toNumber(companyId) }));
      dispatch(getFunctionsAsDropdownItemsAction(toNumber(companyId)));
      dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));

      if (viewType === ContractsPageViewType.CALENDAR) {
        setListViewType(ContractListViewTypes.Future);
        dispatch(
          getContractsAction({
            companyId: toNumber(companyId),
            filter: createFilters(),
          }),
        );
      }

      if (viewType === ContractsPageViewType.LIST) {
        const f = createDefaultListViewFilters();
        console.log('listViewType', listViewType);
        f.listViewType = listViewType ?? ContractListViewTypes.Future;
        dispatch(
          getContractsInListViewAction({
            companyId: toNumber(companyId),
            filter: f,
          }),
        );
      }

      if (allowedVendorRoles.includes(profile.role)) {
        dispatch(getVendorsAction(companyId));
      }

      setLoaded(false);
    }

    if (!companyId && !isEmployee && !loaded && viewType === ContractsPageViewType.LIST) {
      const f = createDefaultListViewFilters();
      f.listViewType = listViewType ?? ContractListViewTypes.Future;
      f.pageNumber = 1;
      f.pageSize = 0;
      dispatch(
        getContractsInListViewAction({
          companyId: toNumber(companyId),
          filter: f,
        }),
      );

      setLoaded(true);
    }

    if (isEmployee) {
      dispatch(
        getContractsAction({
          companyId: 0,
          filter: createFilters(),
        }),
      );
    }
  }, [
    companyId,
    year,
    currentWeek,
    dispatch,
    isEmployee,
    allowedVendorRoles,
    profile,
    fetchPressed,
    viewType,
    listViewType,
    loaded,
    createFilters,
    createDefaultListViewFilters,
  ]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getCompaniesAsDropdownItemsAction());
    }
  }, [isAdmin, dispatch]);

  useEffect(() => {
    if (isThereAnyItemsChecked(allContracts)) {
      setShowBottomGreenBar(true);
    } else {
      setShowBottomGreenBar(false);
    }
    createCheckedContractsArray();
  }, [allContracts, createCheckedContractsArray, isThereAnyItemsChecked]);

  useEffect(() => {
    let shouldSetCheckedDays = false;
    const newCheckedDays = checkedDays.map((day: IDayProps, index: number) => {
      let allChecked = true;
      const sameDayContracts = allContracts.filter((card: IContractData) => {
        return (
          day.date.getDate() === new Date(card.date).getDate() && day.date.getMonth() === new Date(card.date).getMonth()
        );
      });
      if (sameDayContracts.length === 0) allChecked = false;

      let allContractsAreWeekly = true;
      sameDayContracts.forEach((card: IContractData) => {
        if (!card.weeklyId) {
          allContractsAreWeekly = false;
          allChecked = allChecked && card.checked;
        }
      });

      if (allContractsAreWeekly) allChecked = false;

      if (day.checked !== allChecked) shouldSetCheckedDays = true;
      if (day.numberOfItems !== sameDayContracts.length) shouldSetCheckedDays = true;
      return {
        ...day,
        dayOfWeek: weekDaysNames[index],
        checked: allChecked,
        numberOfItems: sameDayContracts.length,
      };
    });

    if (shouldSetCheckedDays) setCheckedDays(newCheckedDays);
  }, [allContracts, checkedDays, weekDaysNames]);

  useEffect(() => {
    if (deleteContractsSuccess) {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createFilters(),
        }),
      );
    }
  }, [companyId, year, currentWeek, deleteContractsSuccess, dispatch, createFilters]);

  useEffect(() => {
    if (isAdd && saveContractSuccess) {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createFilters(),
        }),
      );
    }
  }, [companyId, year, currentWeek, dispatch, isAdd, saveContractSuccess, createFilters]);

  useEffect(() => {
    if (!isEmployee && companyId) dispatch(getEmployeesWithWorkerclass(toNumber(companyId)));
  }, [companyId, dispatch, isEmployee]);

  useEffect(() => {
    if (companyEmployees && companyId) {
      const employeesNames = generateCompanyEmployeesList(companyEmployees);
      setEmployees(employeesNames);
    }

    if (costCentersList) {
      setCostCenters(costCentersList);
    }

    if (!companyId) {
      setEmployees([]);
      setCostCenter('');
      setCostCenters([]);
    }
  }, [companyEmployees, companyId, costCentersList]);

  useEffect(() => {
    if (allContractsAreValid) {
      contractsForValidation.forEach((c: any) => {
        dispatch(saveContractAction(c));
      });
      dispatch(selectedContractsAction({ selectedContracts: [] }));
      dispatch(validateContractErrorMessageRowAction(null));
      dispatch(validateContractsSuccessAction(false));
      setTimeout(() => {
        dispatch(
          getContractsAction({
            companyId: toNumber(companyId),
            filter: createFilters(),
          }),
        );
      }, 2000);
    } else {
      setOpenValidationError(true);
    }
  }, [
    allContractsAreValid,
    selectedContractsFromRedux,
    contractsForValidation,
    dispatch,
    copyWeekly,
    companyId,
    currentWeek,
    year,
    createFilters,
  ]);

  const filterContracts = useCallback(
    (updates: { key: string; value: any }[] = []) => {
      if (toNumber(companyId) > 0) {
        const filter = createFilters();
        if (updates.length > 0) {
          updates.forEach(update => {
            filter[update.key] = update.value;
          });
        }

        dispatch(
          getContractsAction({
            companyId: toNumber(companyId),
            filter,
          }),
        );
      }
    },
    [companyId, dispatch, createFilters],
  );

  const fetchMoreContracts = useCallback(() => {
    const filter = createFilters(ContractPageSize.Default);
    setFetchPressed(true);
    dispatch(
      getContractsAction({
        companyId: toNumber(companyId),
        filter,
      }),
    );
  }, [companyId, dispatch, createFilters]);

  const canViewAvailabilities = (): boolean => {
    // production id concat acceptance id to test conditional access
    const allowedCompanies = AllowedCompaniesForAvailability;
    const managerRoles = [CompanyRole.COMPANY_MANAGER, CompanyRole.HR_MANAGER, CompanyRole.LOCATION_MANAGER];

    return (
      profile.role === CompanyRole.ADMIN ||
      (allowedCompanies.includes(toNumber(companyId)) && managerRoles.includes(profile.role)) ||
      toNumber(companyId) > 1000
    );
  };

  const exportAvailabilities = () => {
    dispatch(
      exportCompanyAvailabilityAction({
        companyId: toNumber(companyId),
        locationId: companyAvailabilityFilters.locationId,
        workerclass: companyAvailabilityFilters.workerclass,
        name: companyAvailabilityFilters.name,
        age: companyAvailabilityFilters.age,
        week: companyAvailabilityFilters.week,
        year: companyAvailabilityFilters.year,
      }),
    );
  };

  const reloadContracts = () => {
    if (viewType === ContractsPageViewType.CALENDAR) {
      dispatch(
        getContractsAction({
          companyId: toNumber(companyId),
          filter: createFilters(),
        }),
      );
    }

    if (viewType === ContractsPageViewType.LIST) {
      const f = createDefaultListViewFilters();
      f.listViewType = listViewType ?? ContractListViewTypes.Future;
      dispatch(
        getContractsInListViewAction({
          companyId: toNumber(companyId),
          filter: f,
        }),
      );
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {!isEmployee && (
            <Box display="flex">
              <AddNewContractButton
                text={
                  <Tooltip title={t(CONTRACTS__NEW_CONTRACT)} placement="top">
                    <Box display="flex" alignItems="center">
                      <StyledContractIcon />
                    </Box>
                  </Tooltip>
                }
                onClick={() => {
                  dispatch(validateContractSuccessAction(false));
                  setOpenAddEditModal(true);
                }}
                disabled={
                  (isAdmin && !companyId) ||
                  copy ||
                  (viewType === ContractsPageViewType.AVAILABILITY && disabledCreateButtons)
                }
              />
              <AddNewContractButton
                text={
                  <Tooltip
                    title={vendors.length ? t(CONTRACTS__NEW_CONTRACT_SLOT) : t(CONTRACTS__NEW_CONTRACT_SLOT_DISABLED)}
                    placement="top"
                  >
                    <Box display="flex" alignItems="center">
                      <Typography fontSize="xx-large"> + </Typography>
                      <StyledAddVendorSlotIcon />
                    </Box>
                  </Tooltip>
                }
                onClick={() => {
                  dispatch(validateContractSuccessAction(false));
                  setOpenContractSlotModal(true);
                }}
                disabled={
                  (isAdmin && !companyId) ||
                  copy ||
                  vendors.length === 0 ||
                  (viewType === ContractsPageViewType.AVAILABILITY && disabledCreateButtons)
                }
              />
            </Box>
          )}
          <UPWeekPicker
            value={week}
            weekValue={currentWeek}
            onChange={newValue => {
              onWeekPickerChange(newValue);
              setYear(new Date(newValue).getFullYear());
            }}
            label={t(HEADER__WEEK)}
            className="datepicker--white-background"
          />
          {isAdmin && (
            <Box sx={{ width: '280px', display: 'inline-block', marginLeft: '8px' }}>
              <UPDropdownWithSearch
                value={companyId}
                onChange={selectedItem => {
                  setCompanyId(selectedItem);
                  setSelectedEmployee('');
                  setCostCenter('');
                }}
                placeholder={t(GENERAL__SELECT)}
                items={companiesDropdownItems}
                label={t(COMPANY__COMPANY)}
                disabled={copy}
                disableClearable={false}
              />
            </Box>
          )}
          {viewType === ContractsPageViewType.LIST && (
            <Box sx={{ width: '178px', display: 'inline-block', marginLeft: '8px' }}>
              <UPDropdownWithSearch
                value={selectedDay}
                onChange={selectedItem => {
                  setSelectedDay(selectedItem || null);
                  filterContracts([{ key: 'date', value: selectedItem ? new Date(selectedItem) : null }]);
                }}
                placeholder={t(GENERAL__SELECT)}
                items={generateCurrentWeekDaysList(currentWeek, weekDaysNames)}
                label={t(GENERAL__DAY)}
                disableClearable={false}
              />
            </Box>
          )}
          {viewType !== ContractsPageViewType.AVAILABILITY && (
            <Box sx={{ width: '244px', display: 'inline-block', marginLeft: '8px' }}>
              <UPDropdownWithSearch
                value={selectedEmployee}
                onChange={selectedItem => {
                  setSelectedEmployee(selectedItem || null);
                  filterContracts([{ key: 'employeeId', value: selectedItem ? toNumber(selectedItem) : null }]);
                }}
                placeholder={t(GENERAL__SELECT)}
                items={employees}
                label={t(GENERAL__NAME)}
                disableClearable={false}
              />
            </Box>
          )}
        </Box>
        {!isEmployee && viewType !== ContractsPageViewType.AVAILABILITY && (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <CompanyUserFilter>
              <UPDropdownWithSearch
                value={costCenter}
                onChange={selectedItem => {
                  setCostCenter(selectedItem || null);
                  filterContracts([{ key: 'costCenterId', value: selectedItem ? toNumber(selectedItem) : null }]);
                }}
                placeholder={t(GENERAL__SELECT)}
                items={costCenters}
                label={t(CONTRACTS__COST_CENTER)}
                disabled={copy}
                disableClearable={false}
              />
            </CompanyUserFilter>
          </Box>
        )}
        {!isEmployee && viewType === ContractsPageViewType.AVAILABILITY && (
          <Box sx={{ display: 'flex', flexDirection: 'row', height: '48px' }}>
            <CompanyUserFilter>
              <AddNewContractButton
                text={
                  <Tooltip title={t(AVAILABILITIES__REQUEST_BASED_ON_FILTERS)} placement="top">
                    {t(AVAILABILITIES__REQUEST_ALL_AVAILABILITIES)}
                  </Tooltip>
                }
                onClick={() => {
                  dispatch(
                    requestAvailabilityFilteredAction({
                      companyId: toNumber(companyId),
                      month: checkedDays[6].date.getMonth() + 1,
                      locationId: companyAvailabilityFilters.locationId,
                      workerclass: companyAvailabilityFilters.workerclass,
                      age: companyAvailabilityFilters.age,
                    }),
                  );
                }}
                disabled={!companyId}
              />
            </CompanyUserFilter>
          </Box>
        )}

        {!isEmployee && (
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse', width: 1 }}>
            <FilterButton
              text={
                <Box display="flex" alignItems="center">
                  <StyledFilterIcon />
                </Box>
              }
              onClick={onClickFilterButton}
              ref={filtersPopoverAnchorEl}
              disabled={(isAdmin && !companyId) || copy}
            />
            {viewType === ContractsPageViewType.CALENDAR && (
              <FiltersMenu
                open={Boolean(filtersPopoverAnchorEl)}
                anchorEl={filtersPopoverAnchorEl}
                onClose={onCloseFilterPopover}
                onApplyFilters={onCloseFilterPopover}
                onClearFilters={onClearContractsFilters}
                filters={calendarViewFilters}
              />
            )}
            {viewType === ContractsPageViewType.LIST && (
              <FiltersMenu
                open={Boolean(filtersPopoverAnchorEl)}
                anchorEl={filtersPopoverAnchorEl}
                onClose={onCloseFilterPopover}
                onApplyFilters={onCloseFilterPopover}
                onClearFilters={onClearContractsFilters}
                filters={listViewFilters}
              />
            )}
            {viewType === ContractsPageViewType.AVAILABILITY && (
              <FiltersMenu
                open={Boolean(filtersPopoverAnchorEl)}
                anchorEl={filtersPopoverAnchorEl}
                onClose={onCloseFilterPopover}
                onApplyFilters={onCloseFilterPopover}
                onClearFilters={onClearCompanyAvailabilityFilters}
                filters={availabilityViewFilters}
              />
            )}
            {viewType === ContractsPageViewType.AVAILABILITY && (
              <FilterButton
                text={
                  <Box display="flex" alignItems="center">
                    <DownloadIcon fill="white" />
                  </Box>
                }
                onClick={exportAvailabilities}
                disabled={(isAdmin && !companyId) || copy}
              />
            )}
          </Box>
        )}
        {!isEmployee && (
          <Box sx={{ display: 'flex', alignItems: 'center', height: '90px' }}>
            <IconWithMargin
              sx={{
                border: viewType === ContractsPageViewType.CALENDAR ? '1px solid #008C15' : '1px solid #9EA2A2',
                display: 'flex',
                padding: '5px',
                borderRadius: '5px 0px 0px 5px',
              }}
              onClick={() => {
                setViewType(ContractsPageViewType.CALENDAR);
                setListViewType(ContractListViewTypes.Future);
                filterContracts([
                  { key: 'employeeId', value: null },
                  { key: 'date', value: null },
                ]);
                setSelectedEmployee('');
                setSelectedDay('');
              }}
            >
              {viewType === ContractsPageViewType.CALENDAR ? <CalendarViewIconGreen /> : <CalendarViewIconGrey />}
            </IconWithMargin>
            <IconWithMargin
              sx={{
                border: viewType === ContractsPageViewType.LIST ? '1px solid #008C15' : '1px solid #9EA2A2',
                marginLeft: '0px',
                display: 'flex',
                padding: '5px',
              }}
              onClick={() => {
                setViewType(ContractsPageViewType.LIST);
                setShowBottomGreenBar(false);
                setToBeConfirmed('');
                // filterContracts([{ key: 'confirmHours', value: null }]);
              }}
            >
              {viewType === ContractsPageViewType.LIST ? <ListViewIconGreen /> : <ListViewIconGrey />}
            </IconWithMargin>
            {canViewAvailabilities() && allowedAvailabilityRoles.includes(profile.role) && (
              // {allowedAvailabilityRoles.includes(profile.role) && ( // original
              <IconWithMargin
                sx={{
                  border: viewType === ContractsPageViewType.AVAILABILITY ? '1px solid #008C15' : '1px solid #9EA2A2',
                  marginLeft: '0px',
                  display: 'flex',
                  padding: '5px',
                  borderRadius: '0px 5px 5px 0px',
                }}
                onClick={() => {
                  setViewType(ContractsPageViewType.AVAILABILITY);
                  setLocation('');
                  setContractType('');
                  setAllContracts([]);
                  setShowBottomGreenBar(false);
                  setListViewType(ContractListViewTypes.Future);
                }}
              >
                {viewType === ContractsPageViewType.AVAILABILITY ? <AvailabilityIconGreen /> : <AvailabilityIconGrey />}
              </IconWithMargin>
            )}
          </Box>
        )}
      </Box>
      {openAddEditModal && (
        <AddEditContractModal
          open={openAddEditModal}
          onClose={() => {
            setOpenAddEditModal(false);
            reloadContracts();
          }}
          onSuccess={() => {
            setIsAdd(true);
          }}
          title={t(CONTRACTS__NEW_CONTRACT)}
          companyId={companyId}
          week={currentWeek}
          weekForYear={week}
        />
      )}
      {openEditModal && (
        <AddEditContractModal
          open={openEditModal}
          onClose={() => {
            if (isEmployee) setOpenEditModal(false);
            else setOpenConfirmCloseModal(true);
          }}
          onSuccess={() => {
            setOpenEditModal(false);
            setOpenConfirmCloseModal(false);
            reloadContracts();
          }}
          title={t(CONTRACTS__NEW_CONTRACT)}
          companyId={`${cardToEdit.companyId}`}
          week={currentWeek}
          cardData={cardToEdit}
          weekForYear={week}
        />
      )}
      {openContractSlotModal && (
        <AddContractSlotModal
          onClose={() => {
            setOpenContractSlotModal(false);
            dispatch(
              getContractsAction({
                companyId: toNumber(companyId),
                filter: createFilters(),
              }),
            );
          }}
          week={currentWeek}
          weekForYear={week}
          companyId={companyId}
        />
      )}
      {openConfirmModal && (
        <ConfirmContractModal
          open={openConfirmModal}
          onClose={() => {
            setListViewType(ContractListViewTypes.Future);
            setOpenConfirmModal(false);
            dispatch(
              getContractsAction({
                companyId: toNumber(companyId),
                filter: createFilters(),
              }),
            );
          }}
          companyId={companyId}
          contractDateId={cardToConfirm.id}
          contractError={cardToConfirm.errorMessage ? cardToConfirm.errorMessage : null}
          onSuccess={() => {
            onConfirmContract();
          }}
        />
      )}
      {openAlreadyConfirmed && (
        <MessageModal
          message={t(CONTRACTS__ALREADY_CONFIRMED)}
          buttonText={t(GENERAL__CANCEL_BUTTON)}
          open={openAlreadyConfirmed}
          onClose={() => setOpenAlreadyConfirmed(false)}
        />
      )}
      {openDeletePastContractModal && (
        <MessageModal
          message={t(CONTRACTS__DELETE_PAST_CONTRACT_ERROR)}
          buttonText={t(GENERAL__CANCEL_BUTTON)}
          open={openDeletePastContractModal}
          onClose={() => setOpenDeletePastContractModal(false)}
        />
      )}
      {openConfirmDeleteWeeklyModal && (
        <DeleteModal
          open={openConfirmDeleteWeeklyModal}
          content={t(CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING)}
          onClose={() => {
            setListViewType(ContractListViewTypes.Future);
            setOpenConfirmDeleteWeeklyModal(false);
          }}
          onDelete={() => {
            makeDeleteRequest(selectedContractsIds);
          }}
        />
      )}
      {openTooManyContractsModal && (
        <MessageModal
          message={t(CONTRACTS__PLEASE_USE_FILTERS)}
          buttonText={t(GENERAL__CANCEL_BUTTON)}
          open={openTooManyContractsModal}
          onClose={() => {
            setOpenTooManyContractsModal(false);
            dispatch(tooManyContractsAction(false));
          }}
        />
      )}
      {viewType === ContractsPageViewType.CALENDAR ? (
        <ContractsGridView
          copyWeekly={setCopyWeekly}
          setOpenConfirmModal={onClickConfirmCard}
          contracts={allContracts}
          onChangeContracts={setAllContracts}
          onPasteContracts={onPasteContracts}
          selectedContractsMaxDate={auxMaxDate}
          days={checkedDays}
          onChangeWeek={onChangeWeek}
          onClickEditCard={onClickEditCard}
          onClickFillSlot={onClickFillSlot}
          onDeleteCheckedContracts={onDeleteCheckedContracts}
          companyId={companyId}
          year={year}
          weekNumber={currentWeek}
          filters={createFilters()}
          getMoreContracts={fetchMoreContracts}
        />
      ) : (
        <Box />
      )}
      {viewType === ContractsPageViewType.LIST ? (
        <ContractsListView
          companyId={companyId}
          currentWeek={currentWeek}
          currentYear={year}
          setViewType={setListViewType}
          filters={createDefaultListViewFilters()}
        />
      ) : (
        <Box />
      )}
      {viewType === ContractsPageViewType.AVAILABILITY ? (
        <CompanyAvailability
          week={week}
          setWeek={setWeek}
          currentWeek={currentWeek}
          companyId={companyId}
          year={year}
          disableCreateButtons={setDisabledCreateButtons}
        />
      ) : (
        <Box />
      )}
      {showBottomGreenBar && (
        <BottomGreenBar
          numberOfItems={numberOfContractsSelected}
          actions={bottomGreenBarActions}
          copyWeekly={copyWeekly}
          onClearAll={onClearAll}
          onDelete={() => onDeleteCheckedContracts(selectedContractsIds)}
          onCopy={onCopyCheckedContracts}
        />
      )}
      {openConfirmCloseModal && (
        <DeleteModal
          open={openConfirmCloseModal}
          content={t(CONTRACTS__ARE_YOU_SURE)}
          onClose={() => {
            setOpenConfirmCloseModal(false);
          }}
          onDelete={() => {
            onAcceptClosingEditModal();
          }}
        />
      )}
      {copy && copyWeekly && (
        <CopyWeeklyModal
          open={copy && copyWeekly}
          content={t(CONTRACTS__PASTE_WEEKLY_CONTRACT)}
          onClose={onCloseCopyWeeklyModal}
          items={getTheNextFourWeeks()}
          onCopy={onPasteWeeklyContract}
        />
      )}
      {contractsErrors.length > 0 &&
        contractsErrorWithOpen.map((err: any, i: number) => {
          const auxKey = err.type as keyof ErrorMessages;
          return (
            <UPSnackBar
              vertical="bottom"
              horizontal="right"
              open={err.open}
              content={
                err?.contractId ? `${contractsErrorMessages[auxKey]} ${err.contractId}` : contractsErrorMessages[auxKey]
              }
              type="error"
              onClose={() => handleErrorClose(err.id)}
              index={i}
            />
          );
        })}
      {contractValidationErrors && (
        <UPSnackBar
          vertical="bottom"
          horizontal="right"
          open={openValidationError}
          content={`${t(contractValidationErrors)} ${contractValidationExtraInfo ?? ''}`}
          type="error"
          onClose={() => setOpenValidationError(false)}
          index={0}
        />
      )}
    </Box>
  );
};

export default Contracts;
