import { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ClearIcon from '@mui/icons-material/Clear';
import { UIInputIconContainer } from 'components/input/styles';
import { GENERAL__SEARCH } from 'translations/constants';
import { CustomSearchBoxProps } from './types';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search.svg';

export const CustomSearchBox = (props: CustomSearchBoxProps): JSX.Element => {
  const { onSearch } = props;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [t] = useTranslation();

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
    onSearch(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    onSearch('');
  };

  const handleSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(searchTerm);
    }
  };
  return (
    <TextField
      value={searchTerm}
      variant="outlined"
      onChange={handleSearchChange}
      onKeyDown={handleSubmit}
      placeholder={t(GENERAL__SEARCH)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchTerm && (
              <IconButton onClick={handleClearSearch}>
                <ClearIcon />
              </IconButton>
            )}
            <UIInputIconContainer>
              <SearchIcon />
            </UIInputIconContainer>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CustomSearchBox;
