import { Avatar, Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import UPInput from 'components/input';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { clearCompanyAction, getCompaniesAction } from 'store/actions/company-actions';
import { clearCompanyContactAction } from 'store/actions/company-contact-actions';
import { removeAllCostCentersAction } from 'store/actions/company-cost-centers-actions';
import { clearCompanyFinancialAction } from 'store/actions/company-financial-actions';
import { removeAllFunctionsAction } from 'store/actions/company-functions-actions';
import { removeAllLocationsAction } from 'store/actions/company-locations-actions';
import { removeAllManagersAction } from 'store/actions/company-managers-actions';
import { clearCompanyWageExemptionsAction } from 'store/actions/company-wage-exemptions-actions';
import { clearBicAction } from 'store/actions/financial-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { clearCompanyPayrollAction } from 'store/actions/payroll-actions';
import { companiesSelector } from 'store/selectors/company-selectors';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import {
  COMPANIES__ADD_NEW_COMPANY,
  COMPANY_INFORMATION__COMPANY_ID_NUMBER,
  COMPANY_INFORMATION__COMPANY_NAME,
  GENERAL__ADDRESS,
  GENERAL__EDIT,
  GENERAL__SEARCH,
  MENU__COMPANIES,
} from 'translations/constants';
import { ICompanyGridDetails } from 'types/company';
import { escapeRegExp, getFormattedCompanyAddress } from 'utils/helpers';
import { PayrollAddNewItemButton } from './payroll/styles';
import { SectionContainer } from './styles';

const Companies = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState<ICompanyGridDetails[]>([]);

  const companies = useAppSelector(companiesSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);

  useEffect(() => {
    if (activeItem !== t(MENU__COMPANIES)) {
      dispatch(setMenuStatusAction(t(MENU__COMPANIES)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    dispatch(getCompaniesAction());
    dispatch(clearCompanyAction());
    dispatch(clearCompanyContactAction());
    dispatch(removeAllLocationsAction());
    dispatch(removeAllManagersAction());
    dispatch(clearCompanyPayrollAction());
    dispatch(removeAllCostCentersAction());
    dispatch(removeAllFunctionsAction());
    dispatch(clearCompanyWageExemptionsAction());
    dispatch(clearCompanyFinancialAction());
    dispatch(clearBicAction());
  }, [dispatch]);

  useEffect(() => {
    if (companies) {
      setRows(companies);
    }
  }, [companies]);

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    if (searchValue) {
      const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
      const filteredRows = companies.filter((row: ICompanyGridDetails) => {
        return Object.keys(row).some((field: string) => {
          return searchRegex.test(row[field]) || searchRegex.test(getFormattedCompanyAddress(row.Address));
        });
      });
      setRows(filteredRows);
    } else {
      setRows(companies);
    }
  };

  const columns: GridColDef[] = [
    { field: 'Id', headerName: '', hide: true },
    {
      field: 'Logo',
      headerName: '',
      width: 80,
      sortable: false,
      renderCell: params => {
        return (
          <div>
            <Avatar src={params.value} />
          </div>
        );
      },
    },
    { field: 'Name', headerName: t(COMPANY_INFORMATION__COMPANY_NAME), width: 200 },
    { field: 'CompanyIdNumber', headerName: t(COMPANY_INFORMATION__COMPANY_ID_NUMBER), width: 220 },
    {
      field: 'Address',
      headerName: t(GENERAL__ADDRESS),
      width: 300,
      valueGetter: params => {
        return getFormattedCompanyAddress(params.row.Address);
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <UPButton onClick={() => navigate(`/company/${params.row.Id}/general`)} text={t(GENERAL__EDIT)} outlined />
          );
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', gap: '16px' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(COMPANIES__ADD_NEW_COMPANY)}`}
          onClick={() => navigate('/company/new/general')}
        />
        <Box
          sx={{
            m: '16px 0px',
            width: '300px',
          }}
        >
          <UPInput
            value={searchText}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              requestSearch(event.target.value);
            }}
            label=""
            placeholder={`${t(GENERAL__SEARCH)}...`}
            icon="search"
          />
        </Box>
      </Box>
      <Box sx={{ height: '70vh', width: '100%' }}>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          withCheckboxes={false}
          getRowId={r => r.Id}
        />
      </Box>
    </SectionContainer>
  );
};

export default Companies;
