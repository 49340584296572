import localStorageService from 'services/local-storage-service';
import store from 'store';
import { setAuthTokenAction } from 'store/actions/auth-actions';
import { setProfileAction } from 'store/actions/profile-actions';
/* eslint camelcase: "off" */
import { CompanyRole } from 'constants/CompanyRole';
import jwt_decode from 'jwt-decode';
import logError from 'utils/log-error';

const getToken = (): string => {
  let token = '';
  const impersonationToken = localStorageService.getItem<string>('impersonationToken');
  if (impersonationToken !== null) {
    token = impersonationToken;
  } else {
    token = localStorageService.getItem<string>('authToken');
  }
  return token;
};

const getRole = (decodedToken: string): CompanyRole => {
  let decodedRole = '';
  Object.keys(decodedToken).forEach(key => {
    const res = key.split('/');
    if (res.length > 1) {
      if (res[res.length - 1] === 'role') {
        decodedRole = decodedToken[key];
      }
    }
  });

  return decodedRole as CompanyRole;
};

const clearAuth = (): void => {
  localStorageService.clear();
  store.dispatch(setAuthTokenAction(null));
};

const loadAuth = async (): Promise<void> => {
  const token = getToken();
  try {
    if (token) {
      // https://github.com/microsoft/TypeScript/issues/45566
      const tokenData = JSON.parse(window.atob(token.split('.')[1]));

      if (tokenData.exp < Date.now() / 1000) {
        clearAuth();
      } else {
        const decodedToken: any = jwt_decode(token);
        const decodedRole = getRole(decodedToken);
        store.dispatch(
          setProfileAction({
            id: decodedToken.Id,
            firstName: decodedToken.FirstName,
            lastName: decodedToken.LastName,
            email: decodedToken.Email,
            role: decodedRole,
            languageId: '',
            phoneNumber: '',
            companyId: '',
            hasTwoFactorAuth: decodedToken.HasTwoFactorAuth,
          }),
        );
        store.dispatch(setAuthTokenAction(token));
      }
    }
  } catch (err) {
    logError(err);
  }
};

export default loadAuth;
