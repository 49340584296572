export enum DateFormat {
  DAY_NUMBER = 'DD',
  DAY_ABBREVIATION = 'ddd',
  DAY_FULL = 'dddd',
  MONTH_NUMBER = 'MM',
  MONTH_ABBREVIATION = 'MMM',
  MONTH_FULL = 'MMMM',
  YEAR = 'YYYY',
  MONTH_YEAR_FULL = 'MMMM YYYY',
  FULL_DATE = 'DD/MM/YYYY',
}
