import { Box } from '@mui/material';
import BottomGreenBar from 'components/bottom-green-bar';
import { CompanyAvailabilityFilterType } from 'constants/CompanyAvailabilityFilterType';
import { t } from 'i18next';
import { toNumber } from 'lodash';
import AddEditContractModal from 'modules/contracts/modals/AddEditContractModal';
import { IDayProps } from 'modules/contracts/types';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getCompanyAvailabilityForWeekAction,
  resetAvailabilityForWeekAction,
} from 'store/actions/availabilities-actions';
import {
  resetCompanyAvailabilityFiltersAction,
  setCompanyAvailabilityFiltersAction,
} from 'store/actions/company-availability-filters-actions';
import { companyAvailabilityFiltersSelector } from 'store/selectors/company-availability-filters-selector';
import { CONTRACTS__NEW_CONTRACT } from 'translations/constants';
import { ReactComponent as NewContractIcon } from '../../../assets/icons/CircleAddNewContractIcon.svg';
import CompanyAvailabilityTable from './table';
import { CompanyAvailabilityProps } from './types';

const CompanyAvailability = (props: CompanyAvailabilityProps): JSX.Element => {
  const { week, setWeek, currentWeek, companyId, disableCreateButtons } = props;

  const dispatch = useAppDispatch();

  const companyAvailabilityFilters = useAppSelector(companyAvailabilityFiltersSelector);

  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [numberOfPages, setNumberOfPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [days, setDays] = useState<IDayProps[]>([]);
  const [showBottomGreenBar, setShowBottomGreenBar] = useState<boolean>(false);
  const [addContractModalOpen, setAddContractModalOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<number>(null);
  const [isContractSaved, setIsContractSaved] = useState<boolean>(false);
  const [areAllCardsUnhovered, setAreAllCardsUnhovered] = useState<boolean>(false);
  const [createdContractsArray, setCreatedContractsArray] = useState<
    {
      employeeName: string;
      dates: number[];
    }[]
  >([]);

  const daysChecked = useMemo(() => days.filter(day => day.checked).length, [days]);

  const bottomGreenBarActions = [
    {
      content: t(CONTRACTS__NEW_CONTRACT),
      icon: <NewContractIcon />,
    },
  ];

  useEffect(() => {
    setCreatedContractsArray([]);
  }, [week, companyId]);

  useEffect(() => {
    dispatch(resetCompanyAvailabilityFiltersAction());
  }, [dispatch]);

  useEffect(() => {
    if (!companyId) {
      dispatch(resetAvailabilityForWeekAction());
      return;
    }
    dispatch(getCompanyAvailabilityForWeekAction(companyAvailabilityFilters));
  }, [dispatch, companyAvailabilityFilters, companyId]);

  useEffect(() => {
    dispatch(
      setCompanyAvailabilityFiltersAction([
        {
          filterType: CompanyAvailabilityFilterType.PAGE,
          value: toNumber(currentPage),
        },
      ]),
    );
  }, [currentPage, dispatch]);

  useEffect(() => {
    dispatch(
      setCompanyAvailabilityFiltersAction([
        {
          filterType: CompanyAvailabilityFilterType.PAGE_SIZE,
          value: toNumber(itemsPerPage),
        },
      ]),
    );
    setCurrentPage(1);
  }, [itemsPerPage, dispatch]);

  useEffect(() => {
    if (daysChecked > 0) {
      setShowBottomGreenBar(true);
      disableCreateButtons(true);
    } else {
      setShowBottomGreenBar(false);
      disableCreateButtons(false);
    }
  }, [daysChecked, disableCreateButtons]);

  useEffect(() => {
    if (isContractSaved) {
      setDays(days.map(d => ({ ...d, checked: false })));
      setIsContractSaved(false);
    }
  }, [isContractSaved, days]);

  useEffect(() => {
    if (areAllCardsUnhovered) {
      setTimeout(() => {
        setAreAllCardsUnhovered(false);
      }, 1000);
    }
  }, [areAllCardsUnhovered]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CompanyAvailabilityTable
        companyId={companyId}
        week={week}
        setWeek={setWeek}
        currentWeek={currentWeek}
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        numberOfPages={numberOfPages}
        setNumberOfPages={setNumberOfPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        days={days}
        setDays={setDays}
        selectedEmployee={selectedEmployee}
        setSelectedEmployee={setSelectedEmployee}
        setAddContractModalOpen={setAddContractModalOpen}
        setContractSaved={setIsContractSaved}
        createdContractsArray={createdContractsArray}
        areAllCardsUnhovered={areAllCardsUnhovered}
      />
      {showBottomGreenBar && !addContractModalOpen && (
        <BottomGreenBar
          numberOfItems={daysChecked}
          actions={bottomGreenBarActions}
          onClearAll={() => {
            setDays(days.map(d => ({ ...d, checked: false })));
          }}
          onCopy={() => {
            setAddContractModalOpen(true);
          }}
        />
      )}
      {addContractModalOpen && (
        <AddEditContractModal
          open
          onClose={() => setAddContractModalOpen(false)}
          title={t(CONTRACTS__NEW_CONTRACT)}
          companyId={companyId}
          week={currentWeek}
          weekForYear={week}
          onSuccess={({ dates, employee }) => {
            const existentEmployeeIndex = createdContractsArray.findIndex(
              contract => contract.employeeName === `${employee.firstName} ${employee.lastName}`,
            );
            if (existentEmployeeIndex !== -1) {
              const newDates = [...createdContractsArray[existentEmployeeIndex].dates, ...dates];
              const newContractsArray = [...createdContractsArray];
              newContractsArray[existentEmployeeIndex] = {
                employeeName: `${employee.firstName} ${employee.lastName}`,
                dates: newDates,
              };
              setCreatedContractsArray(newContractsArray);
              setAddContractModalOpen(false);
              setSelectedEmployee(null);
              return;
            }

            setCreatedContractsArray(prev => {
              return [...prev, { employeeName: `${employee.firstName} ${employee.lastName}`, dates }];
            });
            setAddContractModalOpen(false);
            setAreAllCardsUnhovered(true);
            setSelectedEmployee(null);
          }}
          prefilledEmployeeId={selectedEmployee}
          prefilledDates={days.filter(d => d.checked).map(d => d.date.getDate())}
          setSavedContract={setIsContractSaved}
        />
      )}
      {/* {addContractModalOpen && (
        <AddContractModal
          onClose={() => setAddContractModalOpen(false)}
          onSuccess={null}
          companyId={companyId}
          week={currentWeek}
          weekForYear={week}
          prefilledEmployeeId={selectedEmployee}
          prefilledDates={days.filter(d => d.checked).map(d => d.date.getDate())}
          setSavedContract={setIsContractSaved}
        />
      )} */}
    </Box>
  );
};

export default CompanyAvailability;
