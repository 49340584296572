import { useTranslation } from 'react-i18next';
import { REASON__EXTRA_CASH, REASON__SEEKING_PERMANENT_JOB, REASON__SEEKING_EXPERIENCE } from 'translations/constants';

const useReasonForEmploymentTranslation = (employeeReasonForEmployment: number) => {
  const [t] = useTranslation();
  const getTranslation = () => {
    switch (employeeReasonForEmployment) {
      case 1:
        return t(REASON__EXTRA_CASH);
      case 2:
        return t(REASON__SEEKING_PERMANENT_JOB);
      case 3:
        return t(REASON__SEEKING_EXPERIENCE);
      default:
        return '';
    }
  };
  return getTranslation();
};
export default useReasonForEmploymentTranslation;
