import * as Sentry from '@sentry/react';
import { getConfig } from 'config';

const initSentry = async (): Promise<void> => {
  const targetPropagationString = getConfig('SENTRY_TARGET_PROPAGATION');
  const targetPopagation = new RegExp(targetPropagationString);
  Sentry.init({
    dsn: getConfig('SENTRY_ENDPOINT'),

    ignoreErrors: [
      // chromium os errors ignored
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
    // may be useful if we use the same project for different environments
    // but right now we have different projects for different environments
    // environment: getConfig('SENTRY_ENVIRONMENT'),
    attachStacktrace: true,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [targetPopagation],
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,

    // this will log the content of the request body
    beforeBreadcrumb(breadcrumb, hint) {
      if (hint?.xhr && hint?.input) {
        // eslint-disable-next-line no-param-reassign
        breadcrumb.data.body = hint.input;
      }
      return breadcrumb;
    },

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export default initSentry;
