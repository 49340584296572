import { createReducer } from '@reduxjs/toolkit';
import {
  addMinWageErrorAction,
  addOneMinWageAction,
  setMinimumWagesAction,
  updateMinWageErrorAction,
  setMinimumWagesRequestsLoadingAction,
  updateOneMinWageAction,
} from 'store/actions/minimum-wages-actions';
import minimumWagesAdapter from 'store/adapters/minimum-wages-adapter';
import { IMinimumWagesState } from 'types/minimum-wage';

const initialState: IMinimumWagesState = {
  minimumWages: [],
  loading: false,
  minimumWagesError: false,
  updateMinWageLoading: false,
  updateMinWageError: false,
  addMinWageError: false,
};

const minimumWagesReducer = createReducer(
  minimumWagesAdapter.getInitialState<IMinimumWagesState>(initialState),
  builder => {
    return builder
      .addCase(setMinimumWagesAction, minimumWagesAdapter.setAll)
      .addCase(updateOneMinWageAction, minimumWagesAdapter.updateOne)
      .addCase(addOneMinWageAction, minimumWagesAdapter.addOne)
      .addCase(setMinimumWagesRequestsLoadingAction, (state, action) => ({
        ...state,
        loading: action.payload,
      }))
      .addCase(updateMinWageErrorAction, (state, action) => ({
        ...state,
        updateMinWageError: action.payload,
      }))
      .addCase(addMinWageErrorAction, (state, action) => ({
        ...state,
        addMinWageError: action.payload,
      }));
  },
);

export default minimumWagesReducer;
