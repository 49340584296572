import { Box, InputLabel, styled, InputBase } from '@mui/material';

export const UPSearchInputStyled = styled(Box)(() => ({
  // width: '280px',
  width: '100%',
}));

export const UPInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.secondary.main,
}));

export const UpInputBaseStyled = styled(InputBase)(({ theme }) => ({
  flex: 1,
  fontSize: '14px',
  '& input::placeholder': {
    color: theme.palette.secondary.main,
    opacity: '1',
  },
}));
