import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteVendorsRequest,
  getAllVendorsRequest,
  getVendorRequest,
  postVendorRequest,
  putVendorRequest,
} from 'api/requests/company-requests';
import {
  COMPANY_VENDORS__DELETE,
  COMPANY_VENDORS__GET_ALL,
  COMPANY_VENDORS__GET_BY_ID,
  COMPANY_VENDORS__POST,
  COMPANY_VENDORS__PUT,
  COMPANY_VENDORS__SET_VENDOR,
  COMPANY_VENDORS__SET_VENDORS,
  COMPANY_VENDORS__SET_VENDORS_ERROR,
  COMPANY_VENDOR__GENERAL_LOADING,
} from 'store/constants';
import ICompanyVendor from 'types/company/managers/ICompanyVendor';

export const setVendorRequestsLoadingAction = createAction<boolean>(COMPANY_VENDOR__GENERAL_LOADING);

export const setVendorsAction = createAction<ICompanyVendor[]>(COMPANY_VENDORS__SET_VENDORS);
export const setVendorsErrorAction = createAction<boolean>(COMPANY_VENDORS__SET_VENDORS_ERROR);

export const setVendorAction = createAction<ICompanyVendor>(COMPANY_VENDORS__SET_VENDOR);

export const setVendorErrorAction = createAction<boolean>(COMPANY_VENDORS__SET_VENDORS_ERROR);

export const getVendorsAction = createAsyncThunk(COMPANY_VENDORS__GET_ALL, async (companyId: string, thunkApi) => {
  thunkApi.dispatch(setVendorRequestsLoadingAction(true));
  thunkApi.dispatch(setVendorsErrorAction(false));
  try {
    const response = await getAllVendorsRequest(companyId);
    thunkApi.dispatch(setVendorsAction(response));
  } catch (error) {
    thunkApi.dispatch(setVendorsErrorAction(true));
  }
  thunkApi.dispatch(setVendorRequestsLoadingAction(false));
});

export const getVendorAction = createAsyncThunk(
  COMPANY_VENDORS__GET_BY_ID,
  async (props: { companyId: string; vendorId: number }, thunkApi) => {
    thunkApi.dispatch(setVendorRequestsLoadingAction(true));
    thunkApi.dispatch(setVendorErrorAction(false));
    try {
      const response = await getVendorRequest(props);
      thunkApi.dispatch(setVendorAction(response));
    } catch (error) {
      thunkApi.dispatch(setVendorErrorAction(true));
    }
    thunkApi.dispatch(setVendorRequestsLoadingAction(false));
  },
);

export const postVendorAction = createAsyncThunk(
  COMPANY_VENDORS__POST,
  async (props: { companyId: string; vendor: ICompanyVendor }, thunkApi) => {
    thunkApi.dispatch(setVendorRequestsLoadingAction(true));
    thunkApi.dispatch(setVendorErrorAction(false));
    try {
      await postVendorRequest(props);
      thunkApi.dispatch(getVendorsAction(props.companyId));
    } catch (error) {
      thunkApi.dispatch(setVendorErrorAction(true));
    }
    thunkApi.dispatch(setVendorRequestsLoadingAction(false));
  },
);

export const putVendorAction = createAsyncThunk(
  COMPANY_VENDORS__PUT,
  async (props: { companyId: string; vendor: ICompanyVendor }, thunkApi) => {
    thunkApi.dispatch(setVendorRequestsLoadingAction(true));
    thunkApi.dispatch(setVendorErrorAction(false));
    try {
      await putVendorRequest(props);
      thunkApi.dispatch(getVendorsAction(props.companyId));
    } catch (error) {
      thunkApi.dispatch(setVendorErrorAction(true));
    }
    thunkApi.dispatch(setVendorRequestsLoadingAction(false));
  },
);

export const deleteVendorsAction = createAsyncThunk(
  COMPANY_VENDORS__DELETE,
  async (props: { companyId: string; vendorIds: number[] }, thunkApi) => {
    thunkApi.dispatch(setVendorRequestsLoadingAction(true));
    thunkApi.dispatch(setVendorErrorAction(false));
    try {
      await deleteVendorsRequest(props);
      thunkApi.dispatch(getVendorsAction(props.companyId));
    } catch (error) {
      thunkApi.dispatch(setVendorErrorAction(true));
    }
    thunkApi.dispatch(setVendorRequestsLoadingAction(false));
  },
);
