import { useEffect } from 'react';
import { CompanyAvailabilityTableRowProps } from '../../types';
import CompanyAvailabilityEmployeeCell from './employee-cell';
import CompanyAvailabilityRegularCell from './regular-cell';
import { TableRowContainer } from './styles';

const CompanyAvailabilityTableRow = (props: CompanyAvailabilityTableRowProps): JSX.Element => {
  const {
    employeeAvailability,
    companyAvailabilityIntervals,
    month,
    companyId,
    selectedEmployee,
    onSelectDay,
    days,
    openModal,
    createdContractsArray,
    areAllCardsUnhovered,
  } = props;

  const hasSubmittedAvailability = employeeAvailability.days.every(day => day.isSubmitted);
  const canBeSelected = selectedEmployee === employeeAvailability.employeeId || selectedEmployee === null;
  const newContractButtonDisplayed = days.reduce((acc, day) => {
    if (day.checked) {
      return false;
    }
    return acc;
  }, true);
  const hasDaysInPreviousMonth = employeeAvailability.days[0].day > employeeAvailability.days[6].day;

  const employeesThatHaveContracts = createdContractsArray.map(contract => contract.employeeName);

  const doesEmployeeHaveContract = employeesThatHaveContracts.includes(
    `${employeeAvailability.employee.firstName} ${employeeAvailability.employee.lastName}`,
  );

  const hasContractToday = (day: number) => {
    if (!doesEmployeeHaveContract) {
      return false;
    }
    const hasCreatedContract = createdContractsArray.some(
      contract =>
        contract.employeeName ===
          `${employeeAvailability.employee.firstName} ${employeeAvailability.employee.lastName}` &&
        contract.dates.includes(day),
    );
    return hasCreatedContract;
  };

  return (
    <TableRowContainer>
      <CompanyAvailabilityEmployeeCell
        employee={employeeAvailability.employee}
        hasSubmittedAvailability={hasSubmittedAvailability}
        month={month}
        companyId={companyId}
      />
      {employeeAvailability.days.map(day => (
        <CompanyAvailabilityRegularCell
          key={day.day}
          availabilityDay={day}
          companyAvailabilityIntervals={companyAvailabilityIntervals}
          canBeSelected={canBeSelected}
          onSelectDay={(dayNumber: number) => onSelectDay(employeeAvailability.employeeId, dayNumber)}
          isChecked={
            selectedEmployee === employeeAvailability.employeeId &&
            days.find(d => d.date.getDate() === day.day)?.checked
          }
          buttonDisplayed={newContractButtonDisplayed}
          openModal={openModal}
          month={month}
          hasDaysInPreviousMonth={hasDaysInPreviousMonth}
          hasContract={hasContractToday(day.day)}
          areAllCardsUnhovered={areAllCardsUnhovered}
        />
      ))}
    </TableRowContainer>
  );
};

export default CompanyAvailabilityTableRow;
