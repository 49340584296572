import { UPDropdownItem } from 'components/dropdown';
import { RootState } from 'store';
import functionsAdapter from 'store/adapters/company-functions-adapter';

export const functionsSelectors = functionsAdapter.getSelectors((state: RootState) => state.functions);
export const functionsSelector = functionsSelectors.selectAll;
export const functionsErrorSelector = (state: RootState): boolean => state.functions.functionsError;
export const functionsDropdownItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.functions.functionsDropdownItems;

export const functionsLoadingSelector = (state: RootState): boolean => state.functions.loading;
