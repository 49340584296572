import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UPInputLabel } from 'components/input/styles';
import { CompanyRole } from 'constants/CompanyRole';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import nlLocale from 'date-fns/locale/nl';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { UPDatepickerContainerStyled, UPDatepickerHelperText } from './styles';
import './styles.scss';
import { UPDatepickerProps } from './types';

const UPDatepicker = (props: UPDatepickerProps): JSX.Element => {
  const { value, className, onChange, label, required, error, helperText, isAcademicPicker, disableInput } = props;

  const location = useLocation();

  const userLanguage = useAppSelector(profileSelector)?.languageId;
  const isEmployee = useAppSelector(profileSelector)?.role === CompanyRole.EMPLOYEE;

  const handleCalendarLanguage = useMemo(() => {
    switch (userLanguage) {
      case '1':
        return nlLocale;
      case '2':
        return frLocale;
      case '3':
        return enLocale;
      default:
        return enLocale;
    }
  }, [userLanguage]);

  const onKeyDown = e => {
    e.preventDefault();
  };

  return (
    <UPDatepickerContainerStyled className={className} error={error}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <LocalizationProvider locale={handleCalendarLanguage} dateAdapter={AdapterDateFns}>
        <DatePicker
          value={value}
          onChange={onChange}
          renderInput={params =>
            // eslint-disable-next-line react/jsx-props-no-spreading
            disableInput ? <TextField onKeyDown={onKeyDown} {...params} /> : <TextField {...params} />
          }
          inputFormat="dd/MM/yyyy"
          disabled={isEmployee && location.pathname === '/employees' && !isAcademicPicker}
        />
      </LocalizationProvider>
      {error && <UPDatepickerHelperText>{helperText}</UPDatepickerHelperText>}
    </UPDatepickerContainerStyled>
  );
};

export default UPDatepicker;
