import { Box, styled } from '@mui/material';

export const AvailabilitiesCalendarHeaderContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2.5)} 0`,
  display: 'flex',
}));

export const CalendarHeaderDay = styled(Box)(({ theme }) => ({
  width: '14.28%',
  display: 'flex',
  justifyContent: 'center',
  fontWeight: 'bold',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));
