import { Box, Slider, Typography } from '@mui/material';
import { UPMultipleSelectionDropdown } from 'components/dropdown';
import UPInput from 'components/input';
import { UPInputLabel } from 'components/input/styles';
import UPSwitch from 'components/switch';
import { useTranslation } from 'react-i18next';
import {
  EMPLOYEES__AGE,
  EMPLOYEES__BIRTH_COUNTRY,
  EMPLOYEES__NATIONALITY,
  EMPLOYEES__REASON_FOR_EMPLOYMENT,
  EMPLOYEES__SEARCH_EMPLOYEE_NAME,
  EMPLOYEES__YEARS_OF_EXPERIENCE,
  GENERAL__GENDER,
  GENERAL__LANGUAGE,
  REPORTING__CONFIRMED_CONTRACTS_ONLY,
  REPORTING__NOSHOW_CONTRACTS_ONLY,
} from 'translations/constants';
import { CheckBoxFilterItem, FilterItem, FiltersContainer, RangeFilterItem, SmallFilterItem } from '../styles';
import { BaseReportingFiltersProps } from '../types';

const GeneralStatisticsFilters = (props: BaseReportingFiltersProps) => {
  const { filters, dropdownData, handleFilterChange } = props;

  const [t] = useTranslation();

  const { countriesDropdownItems, languagesDropdownItems, gendersDropdownItems, reasonsForEmploymentDropdownItems } =
    dropdownData;

  const {
    selectedNationalities,
    selectedBirthCountries,
    selectedLanguages,
    selectedGenders,
    selectedReasonsForEmployment,
    employeeName,
    ageRange,
    experienceRange,
    confirmedOnly,
    noShowOnly,
  } = filters;

  return (
    <>
      <FiltersContainer>
        <FilterItem>
          <UPMultipleSelectionDropdown
            items={countriesDropdownItems}
            value={selectedNationalities}
            dropdownLabel={t(EMPLOYEES__NATIONALITY)}
            handleChange={event => handleFilterChange('selectedNationalities', event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <UPMultipleSelectionDropdown
            items={countriesDropdownItems}
            value={selectedBirthCountries}
            dropdownLabel={t(EMPLOYEES__BIRTH_COUNTRY)}
            handleChange={event => handleFilterChange('selectedBirthCountries', event.target.value)}
          />
        </FilterItem>
        <SmallFilterItem>
          <UPMultipleSelectionDropdown
            items={languagesDropdownItems}
            value={selectedLanguages}
            dropdownLabel={t(GENERAL__LANGUAGE)}
            handleChange={event => handleFilterChange('selectedLanguages', event.target.value)}
          />
        </SmallFilterItem>
        <SmallFilterItem>
          <UPMultipleSelectionDropdown
            items={gendersDropdownItems}
            value={selectedGenders}
            dropdownLabel={t(GENERAL__GENDER)}
            handleChange={event => handleFilterChange('selectedGenders', event.target.value)}
          />
        </SmallFilterItem>
        <FilterItem>
          <UPMultipleSelectionDropdown
            items={reasonsForEmploymentDropdownItems}
            value={selectedReasonsForEmployment}
            dropdownLabel={t(EMPLOYEES__REASON_FOR_EMPLOYMENT)}
            handleChange={event => handleFilterChange('selectedReasonsForEmployment', event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <UPInput
            type="text"
            onChange={event => handleFilterChange('employeeName', event.target.value)}
            label={t(EMPLOYEES__SEARCH_EMPLOYEE_NAME)}
            placeholder={t(EMPLOYEES__SEARCH_EMPLOYEE_NAME)}
            inputClassName="white-background"
            value={employeeName}
          />
        </FilterItem>
      </FiltersContainer>
      <FiltersContainer>
        <RangeFilterItem sx={{ width: '270px' }}>
          <UPInputLabel>{t(EMPLOYEES__AGE)}</UPInputLabel>
          <Slider
            getAriaLabel={() => t(EMPLOYEES__AGE)}
            value={ageRange}
            onChange={(_, newValue) => handleFilterChange('ageRange', newValue as number[])}
            valueLabelDisplay="auto"
            getAriaValueText={value => `${value}`}
          />
        </RangeFilterItem>
        <RangeFilterItem sx={{ marginLeft: '50px' }}>
          <UPInputLabel>{t(EMPLOYEES__YEARS_OF_EXPERIENCE)}</UPInputLabel>
          <Slider
            getAriaLabel={() => t(EMPLOYEES__YEARS_OF_EXPERIENCE)}
            value={experienceRange}
            onChange={(_, newValue) => handleFilterChange('experienceRange', newValue as number[])}
            valueLabelDisplay="auto"
            getAriaValueText={value => `${value}`}
          />
        </RangeFilterItem>
        <CheckBoxFilterItem sx={{ marginLeft: '28px' }}>
          <Box display="flex">
            <UPSwitch
              checked={confirmedOnly}
              onChange={event => handleFilterChange('confirmedOnly', event.target.checked)}
            />
            <Typography marginLeft="10px">{t(REPORTING__CONFIRMED_CONTRACTS_ONLY)}</Typography>
          </Box>
        </CheckBoxFilterItem>
        <CheckBoxFilterItem>
          <Box display="flex">
            <UPSwitch checked={noShowOnly} onChange={event => handleFilterChange('noShowOnly', event.target.checked)} />
            <Typography marginLeft="10px">{t(REPORTING__NOSHOW_CONTRACTS_ONLY)}</Typography>
          </Box>
        </CheckBoxFilterItem>
      </FiltersContainer>
    </>
  );
};

export default GeneralStatisticsFilters;
