import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCompanyOvertimeLimitsRequest,
  getWageCalculationRequest,
  getWorkhourSettingsRequest,
  resetWageCalculationRequest,
  updateCompanyOvertimeLimitsRequest,
  updateWageCalculationRequest,
  updateWorkhourSettingsRequest,
} from 'api/requests/wage-components-requests';
import {
  WAGE_CALCULATION__GENERAL_LOADING,
  WAGE_CALCULATION__GET_OVERTIME_LIMITS,
  WAGE_CALCULATION__GET_WORKHOUR_SETTINGS,
  WAGE_CALCULATION__RESET_WAGE_CALCULATION,
  WAGE_CALCULATION__RESET_WAGE_CALCULATION_ERROR,
  WAGE_CALCULATION__SET_OVERTIME_LIMITS,
  WAGE_CALCULATION__SET_WAGE_CALCULATION,
  WAGE_CALCULATION__SET_WAGE_CALCULATION_ERROR,
  WAGE_CALCULATION__SET_WORKHOUR_SETTINGS,
  WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS,
  WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS_ERROR,
  WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS_SUCCESS,
  WAGE_CALCULATION__UPDATE_WAGE_CALCULATION,
  WAGE_CALCULATION__UPDATE_WAGE_CALCULATION_ERROR,
  WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_ERROR,
  WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_SUCCESS,
} from 'store/constants';
import { IWageCalculation, IOvertimeLimits, IWorkhourSettings } from 'types/wage-calculation';

export const setWageCalculationRequestsLoadingAction = createAction<boolean>(WAGE_CALCULATION__GENERAL_LOADING);

export const setWageCalculationAction = createAction<IWageCalculation>(WAGE_CALCULATION__SET_WAGE_CALCULATION);
export const setWageCalculationErrorAction = createAction<boolean>(WAGE_CALCULATION__SET_WAGE_CALCULATION_ERROR);

export const getWageCalculationAction = createAsyncThunk(
  WAGE_CALCULATION__SET_WAGE_CALCULATION,
  async (props: { paritairComiteId: number; companyId: number }, thunkApi) => {
    const { paritairComiteId, companyId } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    thunkApi.dispatch(setWageCalculationErrorAction(false));
    try {
      const response = await getWageCalculationRequest(paritairComiteId, companyId);
      thunkApi.dispatch(setWageCalculationAction(response));
    } catch (error) {
      thunkApi.dispatch(setWageCalculationErrorAction(true));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);

export const updateWageCalculationErrorAction = createAction<boolean>(WAGE_CALCULATION__UPDATE_WAGE_CALCULATION_ERROR);

export const updateWageCalculationAction = createAsyncThunk(
  WAGE_CALCULATION__UPDATE_WAGE_CALCULATION,
  async (props: { paritairComiteId: number; companyId: number; wageCalculation: IWageCalculation }, thunkApi) => {
    const { paritairComiteId, companyId, wageCalculation } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    thunkApi.dispatch(updateWageCalculationErrorAction(false));
    try {
      await updateWageCalculationRequest(paritairComiteId, companyId, wageCalculation);
      thunkApi.dispatch(getWageCalculationAction({ paritairComiteId, companyId }));
    } catch (error) {
      thunkApi.dispatch(updateWageCalculationErrorAction(true));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);

export const resetWageCalculationActionErrorAction = createAction<boolean>(
  WAGE_CALCULATION__RESET_WAGE_CALCULATION_ERROR,
);

export const resetWageCalculationAction = createAsyncThunk(
  WAGE_CALCULATION__RESET_WAGE_CALCULATION,
  async (props: { paritairComiteId: number; companyId: number; wageCalculation: IWageCalculation }, thunkApi) => {
    const { paritairComiteId, companyId, wageCalculation } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    thunkApi.dispatch(resetWageCalculationActionErrorAction(false));
    try {
      await resetWageCalculationRequest(paritairComiteId, companyId, wageCalculation);
      thunkApi.dispatch(getWageCalculationAction({ paritairComiteId, companyId }));
    } catch (error) {
      thunkApi.dispatch(resetWageCalculationActionErrorAction(true));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);

export const setOvertimeLimitsAction = createAction<IOvertimeLimits>(WAGE_CALCULATION__SET_OVERTIME_LIMITS);

export const getOvertimeLimitsAction = createAsyncThunk(
  WAGE_CALCULATION__GET_OVERTIME_LIMITS,
  async (
    props: {
      companyId: number;
      paritairComiteId: number;
    },
    thunkApi,
  ) => {
    const { companyId, paritairComiteId } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    try {
      const response = await getCompanyOvertimeLimitsRequest(companyId, paritairComiteId);
      thunkApi.dispatch(setOvertimeLimitsAction(response));
    } catch (error) {
      thunkApi.dispatch(setWageCalculationErrorAction(true));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);

export const setUpdateOvertimeLimitsSuccessAction = createAction<boolean>(
  WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS_SUCCESS,
);
export const setUpdateOvertimeLimitsErrorAction = createAction<string>(WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS_ERROR);

export const updateOvertimeLimitsAction = createAsyncThunk(
  WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS,
  async (
    props: {
      companyId: number;
      paritairComiteId: number;
      overtimeLimits: IOvertimeLimits;
    },
    thunkApi,
  ) => {
    const { companyId, paritairComiteId, overtimeLimits } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    thunkApi.dispatch(setUpdateOvertimeLimitsSuccessAction(false));
    thunkApi.dispatch(setUpdateOvertimeLimitsErrorAction(''));
    try {
      const { success, messageTranslationKey } = await updateCompanyOvertimeLimitsRequest({
        companyId,
        paritairComiteId,
        overtimeLimits,
      });
      if (success) {
        thunkApi.dispatch(setOvertimeLimitsAction(overtimeLimits));
        thunkApi.dispatch(setUpdateOvertimeLimitsSuccessAction(true));
      } else {
        thunkApi.dispatch(setUpdateOvertimeLimitsErrorAction(messageTranslationKey));
      }
    } catch (error) {
      thunkApi.dispatch(setWageCalculationErrorAction(true));
      thunkApi.dispatch(setUpdateOvertimeLimitsSuccessAction(false));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);

export const setWorkhourSettingsAction = createAction<IWorkhourSettings>(WAGE_CALCULATION__SET_WORKHOUR_SETTINGS);

export const getWorkhourSettingsAction = createAsyncThunk(
  WAGE_CALCULATION__GET_WORKHOUR_SETTINGS,
  async (
    props: {
      companyId: number;
      paritairComiteId: number;
    },
    thunkApi,
  ) => {
    const { companyId, paritairComiteId } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    try {
      const response = await getWorkhourSettingsRequest(companyId, paritairComiteId);
      thunkApi.dispatch(setWorkhourSettingsAction(response));
    } catch (error) {
      thunkApi.dispatch(setWageCalculationErrorAction(true));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);

export const setUpdateWorkhourSettingsErrorAction = createAction<string>(
  WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_ERROR,
);

export const setUpdateWorkhourSettingsSuccessAction = createAction<boolean>(
  WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_SUCCESS,
);

export const updateWorkhourSettingsAction = createAsyncThunk(
  WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_SUCCESS,
  async (
    props: {
      companyId: number;
      paritairComiteId: number;
      workhourSettings: IWorkhourSettings;
    },
    thunkApi,
  ) => {
    const { companyId, paritairComiteId, workhourSettings } = props;
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(true));
    thunkApi.dispatch(setUpdateWorkhourSettingsSuccessAction(false));
    thunkApi.dispatch(setUpdateWorkhourSettingsErrorAction(''));
    try {
      const { success, messageTranslationKey } = await updateWorkhourSettingsRequest({
        companyId,
        paritairComiteId,
        workhourSettings,
      });
      if (success) {
        thunkApi.dispatch(setWorkhourSettingsAction(workhourSettings));
        thunkApi.dispatch(setUpdateWorkhourSettingsSuccessAction(true));
      } else {
        thunkApi.dispatch(setUpdateWorkhourSettingsErrorAction(messageTranslationKey));
      }
    } catch (error) {
      thunkApi.dispatch(setWageCalculationErrorAction(true));
      thunkApi.dispatch(setUpdateWorkhourSettingsSuccessAction(false));
    }
    thunkApi.dispatch(setWageCalculationRequestsLoadingAction(false));
  },
);
