import { createReducer } from '@reduxjs/toolkit';
import {
  clearCompanyContactAction,
  setCompanyContactAction,
  setCompanyContactErrorAction,
  setCompanyContactRequestsLoadingAction,
  setCompanyContactUpdateSuccessfulAction,
} from 'store/actions/company-contact-actions';

import IContactState from 'types/company/contact/IContactState';

const initialState: IContactState = {
  companyContact: null,
  loading: false,
  companyContactError: false,
  companyContactUpdateSuccessful: false,
};

const companyContactReducer = createReducer(initialState, builder =>
  builder
    .addCase(setCompanyContactAction, (state, action) => ({ ...state, companyContact: action.payload }))
    .addCase(setCompanyContactRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setCompanyContactErrorAction, (state, action) => ({
      ...state,
      companyContactError: action.payload,
    }))
    .addCase(setCompanyContactUpdateSuccessfulAction, (state, action) => ({
      ...state,
      companyContactUpdateSuccessful: action.payload,
    }))
    .addCase(clearCompanyContactAction, state => ({
      ...state,
      companyContact: null,
      companyContactLoading: false,
      companyContactError: false,
      companyContactUpdateSuccessful: false,
    })),
);

export default companyContactReducer;
