import { RootState } from 'store';
import { IOvertimeLimits, IWageCalculation, IWorkhourSettings } from 'types/wage-calculation';

export const wageCalculationSelector = (state: RootState): IWageCalculation => state.wageCalculation.wageCalculation;

export const wageCalculationLoadingSelector = (state: RootState): boolean => state.wageCalculation.loading;

export const wageCalculationErrorSelector = (state: RootState): boolean => state.wageCalculation.wageCalculationError;

export const overtimeLimitsSelector = (state: RootState): IOvertimeLimits => state.wageCalculation.overtimeLimits;

export const updateOvertimeLimitsErrorMessageSelector = (state: RootState): string =>
  state.wageCalculation.updateOvertimeLimitsErrorMessage;

export const updateOvertimeLimitsSuccessSelector = (state: RootState): boolean =>
  state.wageCalculation.updateOvertimeLimitsSuccess;

export const workhourSettingsSelector = (state: RootState): IWorkhourSettings => state.wageCalculation.workhourSettings;

export const updateWorkhourSettingsErrorMessageSelector = (state: RootState): string =>
  state.wageCalculation.updateWorkhourSettingsErrorMessage;

export const updateWorkhourSettingsSuccessSelector = (state: RootState): boolean =>
  state.wageCalculation.updateWorkhourSettingsSuccess;
