import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  confirmContractRequest,
  contractsMultipleConfirmationRequest,
  deleteContractsRequest,
  downloadArchivedContractsRequestForCompanies,
  downloadContractsRequest,
  editContractRequest,
  getContractDimonaNumberRequest,
  getContractForDateRequest,
  getContractsCountFromPreviousWeeksRequest,
  getContractsCountRequest,
  getContractsOfAdjacentWeeksRequest,
  getContractsRequest,
  getContractsRequestForListView,
  getCostEstimationRequest,
  getWageRequest,
  reopenConfirmationRequest,
  saveContractRequest,
  saveContractSlotRequest,
  validateContractRequest,
  validateContractSlotHoursRequest,
  validateHoursOnConfirmContractRequest,
  validateHoursOnEditContractRequest,
  validateHoursOnMultipleConfirmContractRequest,
} from 'api/requests/contract-requests';
import { toNumber } from 'lodash';
import { fromConfirmHoursToValidateConfirmHours, fromContractModelToContractRequest } from 'mappers/contracts-mapper';
import { IContractData, IContractToEdit, IContractsCountPerWeek } from 'modules/contracts/types';
import {
  CONTRACTS__DELETE,
  CONTRACTS__DELETE_ERROR,
  CONTRACTS__DELETE_SUCCESS,
  CONTRACTS__DIRECTLY_CARD_TO_COPY,
  CONTRACTS__DIRECTLY_CARD_TO_COPY_ACTION,
  CONTRACTS__DOWNLOAD_COMPANIES_CONTRACTS,
  CONTRACTS__DOWNLOAD_COMPANIES_CONTRACTS_ERROR,
  CONTRACTS__DOWNLOAD_CONTRACTS,
  CONTRACTS__DOWNLOAD_CONTRACTS_ERROR,
  CONTRACTS__EDIT_CONTRACT,
  CONTRACTS__EDIT_CONTRACT_ERROR,
  CONTRACTS__FILTER_CONTRACTS,
  CONTRACTS__GENERAL_LOADING,
  CONTRACTS__GET_ARCHIVED_CONTRACTS_BY_COMPANY_ERROR,
  CONTRACTS__GET_CONTRACTS,
  CONTRACTS__GET_CONTRACTS_COUNT,
  CONTRACTS__GET_CONTRACTS_COUNT_ERROR,
  CONTRACTS__GET_CONTRACTS_COUNT_FROM_PREV_WEEKS,
  CONTRACTS__GET_CONTRACTS_COUNT_FROM_PREV_WEEKS_ERROR,
  CONTRACTS__GET_CONTRACTS_ERROR,
  CONTRACTS__GET_CONTRACTS_OF_ADJACENT_WEEKS,
  CONTRACTS__GET_CONTRACTS_PAYLOAD,
  CONTRACTS__GET_COST_ESTIMATION,
  CONTRACTS__GET_COST_ESTIMATION_ERROR,
  CONTRACTS__GET_COST_ESTIMATION_PAYLOAD,
  CONTRACTS__GET_DIMONA_CONTRACT_ERROR,
  CONTRACTS__GET_DIMONA_CONTRACT_NUMBER,
  CONTRACTS__GET_DIMONA_CONTRACT_NUMBER_SUCCESS,
  CONTRACTS__MULTIPLE_CONFIRM,
  CONTRACTS__MULTIPLE_CONFIRMATION_ERROR,
  CONTRACTS__MULTIPLE_CONFIRMATION_PAYLOAD,
  CONTRACTS__MULTIPLE_CONFIRMATION_SUCCESS,
  CONTRACTS__REOPEN_CONFIRMATION,
  CONTRACTS__REOPEN_CONFIRMATION_ERROR,
  CONTRACTS__SELECTED_CONTRACTS,
  CONTRACTS__SET_CONTRACTS_COUNT,
  CONTRACTS__SET_CONTRACTS_COUNT_FROM_PREV_WEEKS,
  CONTRACTS__SET_ERRORS,
  CONTRACTS__SET_FILTER,
  CONTRACTS__SET_SELECTED_CONTRACTS,
  CONTRACTS__SHOULD_COPY,
  CONTRACTS__SHOULD_COPY_ACTION,
  CONTRACTS__VALIDATE_HOURS_ON_MULTIPLE_CONFIRM,
  CONTRACT__CLEAR,
  CONTRACT__CONFIRM,
  CONTRACT__CONFIRM_ERROR,
  CONTRACT__GET_CONTRACT_FOR_DATE,
  CONTRACT__GET_CONTRACT_FOR_DATE_ERROR,
  CONTRACT__GET_WAGE,
  CONTRACT__GET_WAGE_ERROR,
  CONTRACT__SAVE,
  CONTRACT__SAVE_CONTRACT_SLOT,
  CONTRACT__SAVE_ERROR,
  CONTRACT__SAVE_SUCCESS,
  CONTRACT__SET_CONTRACT,
  CONTRACT__SET_WAGE,
  CONTRACT__VALIDATE,
  CONTRACT__VALIDATE_AND_EDIT,
  CONTRACT__VALIDATE_AND_SAVE,
  CONTRACT__VALIDATE_AND_SAVE_HOURS,
  CONTRACT__VALIDATE_CONTRACT_SLOT_HOURS,
  CONTRACT__VALIDATE_EDIT,
  CONTRACTS__TOO_MANY_CONTRACTS_ERROR,
  CONTRACT__VALIDATE_ERROR_MESSAGE,
  CONTRACT__VALIDATE_ERROR_MESSAGE_ROW,
  CONTRACT__VALIDATE_HOURS,
  CONTRACT__VALIDATE_MULTIPLE,
  CONTRACT__VALIDATE_SUCCESS,
  CONTRACT__VALIDATE_SUCCESS_MULTIPLE,
  CONTRACTS__GET_CONTRACTS_TOTAL_COUNT,
  CONTRACTS__GET_CONTRACTS_LISTVIEW_ERROR,
  CONTRACTS__GET_CONTRACTS_LISTVIEW_PAYLOAD,
  CONTRACTS__GET_CONTRACTS_LISTVIEW,
  CONTRACT__VALIDATE_ERROR_MESSAGE_EXTRA_INFO,
} from 'store/constants';
import ContractFilters from 'types/contract/IContractFilters';
import { DimonaContractKeyPair } from 'types/contract/DimonaDetails';
import IAddEditContract from 'types/contract/IAddEditContract';
import IConfirmContract from 'types/contract/IConfirmContract';
import IConfirmCostDeclaration from 'types/contract/IConfirmCostDeclaration';
import IConfirmHours from 'types/contract/IConfirmHours';
import IContractTotals from 'types/contract/IContractTotals';
import ICostDeclarationRow from 'types/contract/ICostDeclarationRow';
import ISaveContract from 'types/contract/ISaveContract';
import IValidateContract from 'types/contract/IValidateContract';
import IValidateResponse from 'types/contract/IValidateResponse';
import IWageData from 'types/contract/IWageData';
import IWeekDaysRow from 'types/contract/IWeekDaysRow';
import logError from 'utils/log-error';
import { ContractPageState } from 'store/reducers/contracts-reducer';

export const setContractsRequestsLoadingAction = createAction<boolean>(CONTRACTS__GENERAL_LOADING);

export const getContractsErrorAction = createAction<boolean>(CONTRACTS__GET_CONTRACTS_ERROR);
export const getContractsPayloadAction = createAction<any[]>(CONTRACTS__GET_CONTRACTS_PAYLOAD);
export const tooManyContractsAction = createAction<boolean>(CONTRACTS__TOO_MANY_CONTRACTS_ERROR);
export const contractCountAction = createAction<ContractPageState>(CONTRACTS__GET_CONTRACTS_TOTAL_COUNT);

export const getContractsAction = createAsyncThunk(
  CONTRACTS__GET_CONTRACTS,
  async (props: { companyId: number; filter: ContractFilters }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getContractsErrorAction(false));
    try {
      const data = await getContractsRequest({
        companyId: props?.companyId,
        filter: props?.filter,
      });

      // if (data.items.length > 500) {
      //   thunkApi.dispatch(tooManyContractsAction(true));
      // } else {
      thunkApi.dispatch(getContractsPayloadAction(data.items));
      // }
      thunkApi.dispatch(
        contractCountAction({
          numberOfResults: data.numberOfResults,
          pageIndex: data.pageIndex,
          pageSize: data.pageSize,
        }),
      );
    } catch (error) {
      thunkApi.dispatch(getContractsErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const getContractsInListViewErrorAction = createAction<boolean>(CONTRACTS__GET_CONTRACTS_LISTVIEW_ERROR);
export const getContractsInListViewPayloadAction = createAction<IContractData[]>(
  CONTRACTS__GET_CONTRACTS_LISTVIEW_PAYLOAD,
);

export const getContractsInListViewAction = createAsyncThunk(
  CONTRACTS__GET_CONTRACTS_LISTVIEW,
  async (props: { companyId: number; filter: ContractFilters }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getContractsErrorAction(false));
    try {
      const data = await getContractsRequestForListView({
        companyId: props?.companyId,
        filter: props?.filter,
      });

      thunkApi.dispatch(getContractsInListViewPayloadAction(data));
    } catch (error) {
      thunkApi.dispatch(getContractsErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const getContractsOfAdjacentWeeksAction = createAsyncThunk(
  CONTRACTS__GET_CONTRACTS_OF_ADJACENT_WEEKS,
  async (props: { companyId: number; year: number; weekNumber: number }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getContractsErrorAction(false));
    try {
      const contracts = await getContractsOfAdjacentWeeksRequest({
        companyId: props?.companyId,
        year: props?.year,
        weekNumber: props.weekNumber,
      });
      thunkApi.dispatch(getContractsPayloadAction(contracts));
    } catch (error) {
      thunkApi.dispatch(getContractsErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const getCostEstimationErrorAction = createAction<boolean>(CONTRACTS__GET_COST_ESTIMATION_ERROR);
export const getCostEstimationPayloadAction = createAction<any>(CONTRACTS__GET_COST_ESTIMATION_PAYLOAD);

export const getCostEstimationAction = createAsyncThunk(
  CONTRACTS__GET_COST_ESTIMATION,
  async (props: { companyId: number; weekNumber: number; year: number }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getCostEstimationErrorAction(false));
    try {
      const costEstimations = await getCostEstimationRequest({
        companyId: props?.companyId,
        weekNumber: props.weekNumber,
        year: props.year,
      });
      thunkApi.dispatch(getCostEstimationPayloadAction(costEstimations));
    } catch (error) {
      thunkApi.dispatch(getCostEstimationErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const saveContractSuccessAction = createAction<boolean>(CONTRACT__SAVE_SUCCESS);
export const saveContractErrorAction = createAction<boolean>(CONTRACT__SAVE_ERROR);

export const validateContractSuccessAction = createAction<boolean>(CONTRACT__VALIDATE_SUCCESS);
export const validateContractErrorMessageAction = createAction<string>(CONTRACT__VALIDATE_ERROR_MESSAGE);
export const validateContractErrorMessageExtraInfoAction = createAction<string>(
  CONTRACT__VALIDATE_ERROR_MESSAGE_EXTRA_INFO,
);
export const validateContractErrorMessageRowAction = createAction<number>(CONTRACT__VALIDATE_ERROR_MESSAGE_ROW);

export const saveContractAction = createAsyncThunk(
  CONTRACT__SAVE,
  async (
    props: {
      contract: any;
      weekDaysRows: any[];
      companyId: string;
      week: number;
      year: number;
      wage?: string;
      workingLocation: string;
      weekNumber?: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(saveContractErrorAction(false));
    thunkApi.dispatch(saveContractSuccessAction(false));
    try {
      const contractRequest = fromContractModelToContractRequest(
        props.contract,
        props.weekDaysRows,
        props.week,
        props.year,
        props.wage,
        props.workingLocation,
      );
      await saveContractRequest({ contract: contractRequest, companyId: props.companyId });
      thunkApi.dispatch(saveContractSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(saveContractErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

/**
 * TO DO: Refactor this action
 */
export const validateContractAction = createAsyncThunk(
  CONTRACT__VALIDATE,
  async (
    props: {
      contract: IAddEditContract;
      weekDaysRows: IWeekDaysRow[];
      companyId: string;
      week: number;
      year: number;
      wage: string;
      workingLocation: string;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(validateContractSuccessAction(false));
    try {
      const contractRequest = fromContractModelToContractRequest(
        props.contract,
        props.weekDaysRows,
        props.week,
        props.year,
        props.wage,
        props.workingLocation,
      );
      const response: IValidateResponse = await validateContractRequest({
        contract: contractRequest,
        companyId: props.companyId,
      });
      thunkApi.dispatch(validateContractSuccessAction(response.success));
      thunkApi.dispatch(validateContractErrorMessageAction(response.messageTranslationKey));
      thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(response.errorMessageDetails));
      thunkApi.dispatch(validateContractErrorMessageRowAction(response.rowNumber));
    } catch (error) {
      thunkApi.dispatch(validateContractSuccessAction(false));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const validateContractsSuccessAction = createAction<boolean>(CONTRACT__VALIDATE_SUCCESS_MULTIPLE);
export const validateContractsAction = createAsyncThunk(
  CONTRACT__VALIDATE_MULTIPLE,
  async (
    props: {
      contract: any;
      weekDaysRows: any[];
      companyId: string;
      week: number;
      year: number;
      wage: string;
      workingLocation: string;
    }[],
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(validateContractsSuccessAction(false));
    try {
      let allContractsValid = true;
      // eslint-disable-next-line no-restricted-syntax
      for await (const currentContract of props) {
        const contractRequest = fromContractModelToContractRequest(
          currentContract.contract,
          currentContract.weekDaysRows,
          currentContract.week,
          currentContract.year,
          currentContract.wage,
          currentContract.workingLocation,
        );
        // eslint-disable-next-line no-await-in-loop
        const response: IValidateResponse = await validateContractRequest({
          contract: contractRequest,
          companyId: currentContract.companyId,
        });
        if (!response.success) {
          allContractsValid = false;
          thunkApi.dispatch(validateContractsSuccessAction(false));
          thunkApi.dispatch(validateContractErrorMessageAction(response.messageTranslationKey));
          thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(response.errorMessageDetails));
          thunkApi.dispatch(validateContractErrorMessageRowAction(response.rowNumber));
          break;
        }
      }
      if (allContractsValid) thunkApi.dispatch(validateContractsSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(validateContractsSuccessAction(false));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const clearContractAction = createAction<void>(CONTRACT__CLEAR);

export const getWageErrorAction = createAction<boolean>(CONTRACT__GET_WAGE_ERROR);
export const setWageAction = createAction<string>(CONTRACT__SET_WAGE);

export const getWageAction = createAsyncThunk(
  CONTRACT__GET_WAGE,
  async (props: { wageData: IWageData; companyId: string }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getWageErrorAction(false));
    try {
      const response = await getWageRequest({
        wageData: props.wageData,
        companyId: props.companyId,
      });
      thunkApi.dispatch(setWageAction(response));
    } catch (error) {
      thunkApi.dispatch(getWageErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const getContractForDateError = createAction<boolean>(CONTRACT__GET_CONTRACT_FOR_DATE_ERROR);
export const setContract = createAction<IConfirmContract>(CONTRACT__SET_CONTRACT);

export const getContractForDate = createAsyncThunk(
  CONTRACT__GET_CONTRACT_FOR_DATE,
  async (props: { contractDateId: number; companyId: string }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getContractForDateError(false));
    try {
      const response = await getContractForDateRequest({
        contractDateId: props.contractDateId,
        companyId: props.companyId,
      });

      thunkApi.dispatch(setContract(response));
    } catch (error) {
      thunkApi.dispatch(getContractForDateError(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

const generateErrorsToDispatch = (err: any) => {
  const auxErr: { type: string; contractId: number } = { type: '', contractId: null };
  if (err.errorMessage === 'Contract is in the past.' || err.errorMessage === 'Invalid contract date id.') {
    auxErr.type = 'deletePastContract';
    const regex = /with id (.*)./;
    const str = err.error;
    const result = regex.exec(str);
    auxErr.contractId = toNumber(result[1]);
  } else if (err.errorMessage === 'Contract is signed.') {
    auxErr.type = 'signedPastContract';
    const regex = /with id (.*)./;
    const str = err.error;
    const result = regex.exec(str);
    auxErr.contractId = toNumber(result[1]);
  } else auxErr.type = 'default';
  return auxErr;
};

export const setErrors = createAction<any>(CONTRACTS__SET_ERRORS);

export const confirmContractError = createAction<boolean>(CONTRACT__CONFIRM_ERROR);

export const confirmContract = createAsyncThunk(
  CONTRACT__CONFIRM,
  async (
    props: { confirmHours: IConfirmHours; companyId: string; confirmCostDeclarations: ICostDeclarationRow[] },
    thunkApi,
  ) => {
    thunkApi.dispatch(confirmContractError(false));
    thunkApi.dispatch(setErrors([]));

    const costDeclarations: IConfirmCostDeclaration[] = props.confirmCostDeclarations.map(costDeclaration => {
      return {
        costDeclarationId: toNumber(costDeclaration.costDeclarationId),
        amount: costDeclaration.amount,
      };
    });

    const hours = props.confirmHours;
    hours.confirmedCostDeclarations = costDeclarations;
    hours.costCenterId = toNumber(props.confirmHours.costCenterId);

    try {
      await confirmContractRequest({
        contractHours: hours,
        companyId: props.companyId,
      });
    } catch (error) {
      thunkApi.dispatch(
        setErrors([
          {
            type: 'default',
          },
        ]),
      );
      thunkApi.dispatch(confirmContractError(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const validateHoursOnConfirmContractAction = createAsyncThunk(
  CONTRACT__VALIDATE_HOURS,
  async (
    props: {
      confirmHours: IConfirmHours;
      employeeId: number;
      functionId: number;
      companyId: string;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(validateContractSuccessAction(false));
    try {
      const validateHours = fromConfirmHoursToValidateConfirmHours(
        props.confirmHours,
        props.employeeId,
        props.companyId,
        props.functionId,
      );
      const response: IValidateResponse = await validateHoursOnConfirmContractRequest({
        validateHours,
        companyId: props.companyId,
      });
      thunkApi.dispatch(validateContractSuccessAction(response.success));
      thunkApi.dispatch(validateContractErrorMessageAction(response.messageTranslationKey));
      thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(response.errorMessageDetails));
      thunkApi.dispatch(validateContractErrorMessageRowAction(response.rowNumber));
    } catch (error) {
      thunkApi.dispatch(validateContractSuccessAction(false));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const setContractsMultipleConfimrationPayload = createAction<any>(CONTRACTS__MULTIPLE_CONFIRMATION_PAYLOAD);
export const setContractsMultipleConfirmationSucces = createAction<boolean>(CONTRACTS__MULTIPLE_CONFIRMATION_SUCCESS);

export const validateHoursOnMultipleConfirmContractAction = createAsyncThunk(
  CONTRACTS__VALIDATE_HOURS_ON_MULTIPLE_CONFIRM,
  async (
    props: {
      contractsData: any[];
      companyId: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(setContractsMultipleConfirmationSucces(false));
    try {
      const response: IValidateResponse = await validateHoursOnMultipleConfirmContractRequest({
        contractsData: props.contractsData,
        companyId: props.companyId,
      });
      thunkApi.dispatch(setContractsMultipleConfirmationSucces(true));
      thunkApi.dispatch(setContractsMultipleConfimrationPayload(response));
    } catch (error) {
      thunkApi.dispatch(setContractsMultipleConfirmationSucces(false));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const setMultipleConfirmationError = createAction<boolean>(CONTRACTS__MULTIPLE_CONFIRMATION_ERROR);

export const multipleConfirmContractsAction = createAsyncThunk(
  CONTRACTS__MULTIPLE_CONFIRM,
  async (
    props: {
      contractsData: any[];
      companyId: number;
      year: number;
      weekNumber: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(setMultipleConfirmationError(false));
    try {
      await contractsMultipleConfirmationRequest({
        contractsData: props.contractsData,
        companyId: props.companyId,
      });
    } catch (error) {
      thunkApi.dispatch(setMultipleConfirmationError(true));
      thunkApi.dispatch(
        setErrors([
          {
            type: 'default',
          },
        ]),
      );
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const editContractErrorAction = createAction<boolean>(CONTRACTS__EDIT_CONTRACT_ERROR);

export const validateContractEditAction = createAsyncThunk(
  CONTRACT__VALIDATE_EDIT,
  async (
    props: {
      contract: any;
      companyId: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    try {
      const response: IValidateResponse = await validateHoursOnEditContractRequest({
        contract: props.contract,
        companyId: props.companyId,
      });
      thunkApi.dispatch(validateContractSuccessAction(response.success));
      thunkApi.dispatch(validateContractErrorMessageAction(response.messageTranslationKey));
      thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(response.errorMessageDetails));
      thunkApi.dispatch(validateContractErrorMessageRowAction(response.rowNumber));
    } catch {
      thunkApi.dispatch(validateContractSuccessAction(false));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const editContractAction = createAsyncThunk(
  CONTRACTS__EDIT_CONTRACT,
  async (props: { companyId: number; contract: IContractToEdit }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(editContractErrorAction(false));
    try {
      await editContractRequest({
        companyId: props.companyId,
        contract: props.contract,
      });
    } catch (error) {
      thunkApi.dispatch(editContractErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const setFilter = createAction<any>(CONTRACTS__SET_FILTER);
export const filterContractsAction = createAsyncThunk(
  CONTRACTS__FILTER_CONTRACTS,
  async (props: { filterType: string; value: any }, thunkApi) => {
    thunkApi.dispatch(setFilter({ filterType: props.filterType, value: props.value }));
  },
);

export const deleteContractsSuccessAction = createAction<boolean>(CONTRACTS__DELETE_SUCCESS);
export const deleteContractsErrorAction = createAction<boolean>(CONTRACTS__DELETE_ERROR);
export const deleteContractsAction = createAsyncThunk(
  CONTRACTS__DELETE,
  async (props: { companyId: number; contractsIds: number[]; year: number; weekNumber: number }, thunkApi) => {
    const { companyId, contractsIds, year, weekNumber } = props;
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteContractsErrorAction(false));
    thunkApi.dispatch(deleteContractsSuccessAction(false));

    try {
      const response = await deleteContractsRequest({
        companyId,
        contractsIds,
      });

      if (response) {
        let errToSend: any[] = [];
        response.forEach((err: any) => {
          errToSend = [...errToSend, generateErrorsToDispatch(err)];
        });

        thunkApi.dispatch(setErrors(errToSend));
      }
      thunkApi.dispatch(deleteContractsSuccessAction(true));
    } catch (error) {
      thunkApi.dispatch(deleteContractsErrorAction(true));
      thunkApi.dispatch(
        setErrors([
          {
            type: 'default',
          },
        ]),
      );
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const deleteContractDashboardActionF = createAsyncThunk(
  CONTRACTS__DELETE,
  async (props: { companyId: number; contractsIds: number[]; year: number; weekNumber: number }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteContractsErrorAction(false));

    try {
      const response = await deleteContractsRequest({
        companyId: props.companyId,
        contractsIds: props.contractsIds,
      });
      if (response) {
        let errToSend: any[] = [];
        response.forEach((err: any) => {
          errToSend = [...errToSend, generateErrorsToDispatch(err)];
        });
      }
    } catch (error) {
      thunkApi.dispatch(deleteContractsErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const setSelectedContracts = createAction<any>(CONTRACTS__SET_SELECTED_CONTRACTS);
export const selectedContractsAction = createAsyncThunk(
  CONTRACTS__SELECTED_CONTRACTS,
  async (props: { selectedContracts: IContractData[] }, thunkApi) => {
    thunkApi.dispatch(setSelectedContracts(props.selectedContracts));
  },
);

export const setShouldCopyContracts = createAction<any>(CONTRACTS__SHOULD_COPY);
export const shouldCopyAction = createAsyncThunk(
  CONTRACTS__SHOULD_COPY_ACTION,
  async (props: { copy: boolean }, thunkApi) => {
    thunkApi.dispatch(setShouldCopyContracts(props.copy));
  },
);

export const setDirectlyCardToCopy = createAction<any>(CONTRACTS__DIRECTLY_CARD_TO_COPY);
export const directlyCardToCopyAction = createAsyncThunk(
  CONTRACTS__DIRECTLY_CARD_TO_COPY_ACTION,
  async (props: { id: number }, thunkApi) => {
    thunkApi.dispatch(setDirectlyCardToCopy(props.id));
  },
);

export const getContractsCountError = createAction<boolean>(CONTRACTS__GET_CONTRACTS_COUNT_ERROR);
export const setContractsCount = createAction<IContractTotals>(CONTRACTS__SET_CONTRACTS_COUNT);

export const getContractsCount = createAsyncThunk(
  CONTRACTS__GET_CONTRACTS_COUNT,
  async (props: { companyId?: number; year: number; weekNumber: number }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getContractsCountError(false));
    try {
      const response = await getContractsCountRequest({
        weekNumber: props.weekNumber,
        year: props.year,
        companyId: props?.companyId,
      });
      thunkApi.dispatch(setContractsCount(response));
    } catch (error) {
      thunkApi.dispatch(getContractsCountError(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const getContractsCountFromPreviousWeeksErrorAction = createAction<boolean>(
  CONTRACTS__GET_CONTRACTS_COUNT_FROM_PREV_WEEKS_ERROR,
);
export const setContractsCountFromPreviousWeeksAction = createAction<IContractsCountPerWeek[]>(
  CONTRACTS__SET_CONTRACTS_COUNT_FROM_PREV_WEEKS,
);

export const getContractsCountFromPreviousWeeksAction = createAsyncThunk(
  CONTRACTS__GET_CONTRACTS_COUNT_FROM_PREV_WEEKS,
  async (props: { companyId?: number; year: number; weekNumber: number; locationId: number }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(getContractsCountFromPreviousWeeksErrorAction(false));
    try {
      const response = await getContractsCountFromPreviousWeeksRequest({
        companyId: props?.companyId,
        year: props.year,
        weekNumber: props.weekNumber,
        locationId: props.locationId,
      });
      thunkApi.dispatch(setContractsCountFromPreviousWeeksAction(response));
    } catch (error) {
      thunkApi.dispatch(getContractsCountFromPreviousWeeksErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const downloadContractsErrorAction = createAction<boolean>(CONTRACTS__DOWNLOAD_CONTRACTS_ERROR);

export const downloadContractAction = createAsyncThunk(
  CONTRACTS__DOWNLOAD_CONTRACTS,
  async (props: { companyId: number; ids: number[]; type: string }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(downloadContractsErrorAction(false));
    try {
      const response = await downloadContractsRequest({
        contractsData: {
          companyId: props.companyId,
          type: props.type,
          contractIds: props.ids,
        },
        companyId: props.companyId,
      });

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      const date = new Date().toISOString().split('T')[0];
      link.href = url;
      link.setAttribute('download', `${props.type}_contracts_${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      thunkApi.dispatch(downloadContractsErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const downloadArchivedContractsForCompanyErrorAction = createAction<boolean>(
  CONTRACTS__DOWNLOAD_COMPANIES_CONTRACTS_ERROR,
);

export const downloadArchivedContractsForCompanyAction = createAsyncThunk(
  CONTRACTS__DOWNLOAD_COMPANIES_CONTRACTS,
  async (
    props: { companyPcPairList: number[][]; type: string; year: number; week: number; paritairComites?: number[] },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(downloadArchivedContractsForCompanyErrorAction(false));
    try {
      const response = await downloadArchivedContractsRequestForCompanies({
        companyPcPairList: props.companyPcPairList,
        type: props.type,
        year: props.year,
        week: props.week,
      });

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      const date = new Date().toISOString().split('T')[0];
      link.href = url;
      link.setAttribute('download', `${props.year}_${props.week}_contracts_${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      thunkApi.dispatch(downloadContractsErrorAction(true));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const saveContractSlotAction = createAsyncThunk(
  CONTRACT__SAVE_CONTRACT_SLOT,
  async (
    props: {
      saveContractSlot: ISaveContract;
      quantity: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    try {
      const contractRequest = fromContractModelToContractRequest(
        props.saveContractSlot.contract,
        props.saveContractSlot.weekDaysRows,
        props.saveContractSlot.week,
        props.saveContractSlot.year,
        props.saveContractSlot.wage,
        props.saveContractSlot.workingLocation,
      );

      await saveContractSlotRequest({
        contract: contractRequest,
        companyId: props.saveContractSlot.companyId,
        quantity: props.quantity,
      });
      thunkApi.dispatch(saveContractSuccessAction(true));
      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return true;
    } catch (error) {
      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return false;
    }
  },
);

export const validateAndSaveContractSlotHoursAction = createAsyncThunk(
  CONTRACT__VALIDATE_CONTRACT_SLOT_HOURS,
  async (
    props: {
      saveContractSlot: ISaveContract;
      quantity: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    try {
      const contractRequest = fromContractModelToContractRequest(
        props.saveContractSlot.contract,
        props.saveContractSlot.weekDaysRows,
        props.saveContractSlot.week,
        props.saveContractSlot.year,
        props.saveContractSlot.wage,
        props.saveContractSlot.workingLocation,
      );

      const response: IValidateResponse = await validateContractSlotHoursRequest({
        contract: contractRequest,
        companyId: props.saveContractSlot.companyId,
      });
      if (!response.success) {
        thunkApi.dispatch(validateContractSuccessAction(false));
        thunkApi.dispatch(validateContractErrorMessageAction(response.messageTranslationKey));
        thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(response.errorMessageDetails));
        thunkApi.dispatch(validateContractErrorMessageRowAction(response.rowNumber));
        thunkApi.dispatch(setContractsRequestsLoadingAction(false));
        return false;
      }
      thunkApi.dispatch(saveContractSlotAction(props));
      thunkApi.dispatch(validateContractSuccessAction(true));
      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return true;
    } catch (error) {
      thunkApi.dispatch(validateContractSuccessAction(false));
    }
    return false;
  },
);

export const validateAndSaveContractAction = createAsyncThunk(
  CONTRACT__VALIDATE_AND_SAVE,
  async (
    props: {
      validateContract: IValidateContract;
      saveContract: ISaveContract;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(validateContractSuccessAction(false));

    try {
      const validateRequest = fromContractModelToContractRequest(
        props.validateContract.contract,
        props.validateContract.weekDaysRows,
        props.validateContract.week,
        props.validateContract.year,
        props.validateContract.wage,
        props.validateContract.workingLocation,
      );
      const { success, messageTranslationKey, rowNumber, errorMessageDetails } = await validateContractRequest({
        contract: validateRequest,
        companyId: props.validateContract.companyId,
      });

      if (!success) {
        await thunkApi.dispatch(validateContractSuccessAction(false));
        await thunkApi.dispatch(validateContractErrorMessageAction(messageTranslationKey));
        await thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(errorMessageDetails));
        await thunkApi.dispatch(validateContractErrorMessageRowAction(rowNumber));
        thunkApi.dispatch(setContractsRequestsLoadingAction(false));
        return false;
      }

      const contractRequest = fromContractModelToContractRequest(
        props.saveContract.contract,
        props.saveContract.weekDaysRows,
        props.saveContract.week,
        props.saveContract.year,
        props.saveContract.wage,
        props.saveContract.workingLocation,
      );
      await saveContractRequest({ contract: contractRequest, companyId: props.saveContract.companyId });
      thunkApi.dispatch(saveContractSuccessAction(true));
      await thunkApi.dispatch(validateContractErrorMessageRowAction(rowNumber));
      await thunkApi.dispatch(validateContractErrorMessageAction(''));
      await thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(''));

      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return true;
    } catch (error) {
      await thunkApi.dispatch(validateContractSuccessAction(false));
      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return false;
    }
  },
);

export const validateAndSaveEditContractAction = createAsyncThunk(
  CONTRACT__VALIDATE_AND_EDIT,
  async (
    props: {
      contract: any;
      companyId: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(editContractErrorAction(false));
    try {
      const result = await validateHoursOnEditContractRequest({
        contract: props.contract,
        companyId: props.companyId,
      });

      if (!result.success) {
        thunkApi.dispatch(validateContractSuccessAction(result.success));
        thunkApi.dispatch(validateContractErrorMessageAction(result.messageTranslationKey));
        thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(result.errorMessageDetails));
        thunkApi.dispatch(validateContractErrorMessageRowAction(result.rowNumber));
        thunkApi.dispatch(setContractsRequestsLoadingAction(false));
        return false;
      }

      thunkApi.dispatch(validateContractSuccessAction(true));
      thunkApi.dispatch(validateContractErrorMessageAction(''));
      thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(''));
      thunkApi.dispatch(validateContractErrorMessageRowAction(null));

      // trigger edit contract
      await editContractRequest({
        companyId: props.companyId,
        contract: props.contract,
      });

      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return true;
    } catch {
      thunkApi.dispatch(validateContractSuccessAction(false));
      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return false;
    }
  },
);

export const getArchivedContractsByCompanyErrorAction = createAction<boolean>(
  CONTRACTS__GET_ARCHIVED_CONTRACTS_BY_COMPANY_ERROR,
);

export const validateAndConfirmContract = createAsyncThunk(
  CONTRACT__VALIDATE_AND_SAVE_HOURS,
  async (
    props: {
      confirmHours: IConfirmHours;
      employeeId: number;
      functionId: number;
      companyId: string;
      confirmCostDeclarations: ICostDeclarationRow[];
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    thunkApi.dispatch(validateContractSuccessAction(false));

    thunkApi.dispatch(confirmContractError(false));
    thunkApi.dispatch(setErrors([]));

    const costDeclarations: IConfirmCostDeclaration[] = props.confirmCostDeclarations.map(costDeclaration => {
      return {
        costDeclarationId: toNumber(costDeclaration.costDeclarationId),
        amount: costDeclaration.amount,
      };
    });

    const hours = props.confirmHours;
    hours.confirmedCostDeclarations = costDeclarations;
    hours.costCenterId = toNumber(props.confirmHours.costCenterId);

    try {
      const validateHours = fromConfirmHoursToValidateConfirmHours(
        props.confirmHours,
        props.employeeId,
        props.companyId,
        props.functionId,
      );

      const response = await validateHoursOnConfirmContractRequest({
        validateHours,
        companyId: props.companyId,
      });

      thunkApi.dispatch(validateContractSuccessAction(response.success));
      thunkApi.dispatch(validateContractErrorMessageAction(response.messageTranslationKey));
      thunkApi.dispatch(validateContractErrorMessageExtraInfoAction(response.errorMessageDetails));
      thunkApi.dispatch(validateContractErrorMessageRowAction(response.rowNumber));

      if (!response.success) {
        thunkApi.dispatch(setContractsRequestsLoadingAction(false));
        return false;
      }

      await confirmContractRequest({
        contractHours: hours,
        companyId: props.companyId,
      });
    } catch (error) {
      logError(error);
      thunkApi.dispatch(validateContractSuccessAction(false));
      thunkApi.dispatch(
        setErrors([
          {
            type: 'default',
          },
        ]),
      );
      thunkApi.dispatch(confirmContractError(true));
      thunkApi.dispatch(setContractsRequestsLoadingAction(false));
      return false;
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
    return true;
  },
);

export const setReopenConfirmationErrorAction = createAction<boolean>(CONTRACTS__REOPEN_CONFIRMATION_ERROR);

export const reopenConfirmationAction = createAsyncThunk(
  CONTRACTS__REOPEN_CONFIRMATION,
  async (props: { companyId: number; contractDateId: number; year: number; weekNumber: number }, thunkApi) => {
    const { companyId, contractDateId, year, weekNumber } = props;
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    try {
      await reopenConfirmationRequest({
        contractDateId,
        companyId,
      });
    } catch (error) {
      setReopenConfirmationErrorAction(true);
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);

export const getDimonaNumberErrorAction = createAction<DimonaContractKeyPair>(CONTRACTS__GET_DIMONA_CONTRACT_ERROR);
export const getDimonaNumberSuccessAction = createAction<DimonaContractKeyPair>(
  CONTRACTS__GET_DIMONA_CONTRACT_NUMBER_SUCCESS,
);

export const getDimonaNumberAction = createAsyncThunk(
  CONTRACTS__GET_DIMONA_CONTRACT_NUMBER,
  async (props: { companyId: number; contractId: number }, thunkApi) => {
    thunkApi.dispatch(setContractsRequestsLoadingAction(true));
    try {
      const response = await getContractDimonaNumberRequest({
        companyId: props?.companyId,
        contractId: props?.contractId,
      });

      const result: DimonaContractKeyPair = {
        contractId: props?.contractId,
        dimonaId: response.dimonaId,
      };
      if (response.dimonaId) thunkApi.dispatch(getDimonaNumberSuccessAction(result));
      else {
        result.dimonaId = response.dimonaError;
        thunkApi.dispatch(getDimonaNumberErrorAction(result));
      }
    } catch (error: any) {
      const errorData: DimonaContractKeyPair = {
        contractId: props?.contractId,
        dimonaId: error?.message,
      };
      thunkApi.dispatch(getDimonaNumberErrorAction(errorData));
    }
    thunkApi.dispatch(setContractsRequestsLoadingAction(false));
  },
);
