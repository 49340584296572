import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { ca } from 'date-fns/locale';
import { fromEmployeeRequestToEmployeeModel } from 'mappers/employee-mapper';
import BlobResponseType from 'types/blob/BlobResponseType';
import { IEmployeeGridDetails, IAddEditEmployee, IEmployeeGridFilters, IEmployeeTotals } from 'types/employee';
import ICompanyInvitationLink from 'types/employee/ICompanyInvitationLink';
import { IEmployeeGetResponse } from 'types/employee/IEmployeeGridDetails';
import IEmployeeOnboardingDetails from 'types/employee/IEmployeeOnboardingDetails';
import IEmployeeWithWorkerclass from 'types/employee/IEmployeeWithWorkerclass';

export const saveEmployeeRequest = async (
  employee: any,
  companyId: number | undefined,
  invitationToken?: string,
): Promise<number> => {
  const formData = new FormData();
  Object.keys(employee).map(key => key !== 'address' && key !== 'dependants' && formData.append(key, employee[key]));
  Object.keys(employee.address).map(key => formData.append(`address[${key}]`, employee.address[key]));
  if (employee.dependants !== null) {
    Object.keys(employee.dependants).map(key => formData.append(`dependants[${key}]`, employee.dependants[key]));
  }

  if (employee.stayAddress !== null) {
    Object.keys(employee.stayAddress).map(key => formData.append(`stayAddress[${key}]`, employee.stayAddress[key]));
  }

  if (employee.companyLocationIds) {
    formData.delete('companyLocationIds');
    Object.keys(employee.companyLocationIds).map(k =>
      formData.append(`companyLocationIds[${k}]`, employee.companyLocationIds[k]),
    );
  }

  if (employee.declarationOfTruth === null) {
    formData.delete('declarationOfTruth');
  }

  const response: AxiosResponse<number> = await getApi().post(`Employee`, formData, {
    headers: { 'Content-Type': `multipart/form-data`, companyId, invitationToken },
  });
  return response.data;
};

export const getAllEmployees = async (props: IEmployeeGridFilters): Promise<IEmployeeGetResponse> => {
  const { status, companyId, pageSize, pageIndex, workType, age, active, sortOrder, sortCriteria, name, locationId } =
    props;
  const response: AxiosResponse<IEmployeeGetResponse> = await getApi().post(
    `Employee/GetAll`,
    { status, pageSize, pageIndex, workType, age, active, sortOrder, sortCriteria, name, locationId },
    {
      headers: {
        companyId,
      },
    },
  );
  return response.data;
};

export const getEmployeeByIdRequest = async (employeeId: number, companyId: number): Promise<IAddEditEmployee> => {
  const response: AxiosResponse<IAddEditEmployee> = await getApi().get(`Employee/${employeeId}`, {
    headers: { companyId },
  });
  return fromEmployeeRequestToEmployeeModel(response.data);
};

export const getEmployeeSelfOnboardingRequest = async (
  companyToken: string,
  inviteToken: string,
): Promise<IEmployeeOnboardingDetails> => {
  const response: AxiosResponse<IEmployeeOnboardingDetails> = await getApi().get(
    `Employee/EmployeeSelfOnboarding/${companyToken}/${inviteToken}`,
    {
      headers: { companyId: companyToken },
    },
  );
  return response.data;
};

export const getEmployeeSelfOnboardingWithGeneralLinkRequest = async (
  inviteToken: string,
): Promise<IEmployeeOnboardingDetails> => {
  const response: AxiosResponse<IEmployeeOnboardingDetails> = await getApi().get(
    `CompanyOnboarding/selfOnboarding/${inviteToken}`,
  );
  return response.data;
};

export const updateEmployeeRequest = async (employee: any, companyId: number): Promise<number> => {
  const formData = new FormData();
  Object.keys(employee).map(key => key !== 'address' && key !== 'dependants' && formData.append(key, employee[key]));
  Object.keys(employee.address).map(key => formData.append(`address[${key}]`, employee.address[key]));

  if (employee.dependants !== null) {
    Object.keys(employee.dependants).map(key => formData.append(`dependants[${key}]`, employee.dependants[key]));
  }

  if (employee.stayAddress !== null) {
    Object.keys(employee.stayAddress).map(key => formData.append(`stayAddress[${key}]`, employee.stayAddress[key]));
  }

  if (employee.companyLocationIds) {
    formData.delete('companyLocationIds');
    Object.keys(employee.companyLocationIds).map(k =>
      formData.append(`companyLocationIds[${k}]`, employee.companyLocationIds[k]),
    );
  }

  if (employee.declarationOfTruth === null) {
    formData.delete('declarationOfTruth');
  }

  const response: AxiosResponse<number> = await getApi().put(`Employee`, formData, {
    headers: { companyId, 'Content-Type': `multipart/form-data` },
  });

  return response.data;
};

export const getPendingEmployees = async (props: {
  pageSize: number;
  pageIndex: number;
}): Promise<IEmployeeGridDetails[]> => {
  const response: AxiosResponse<IEmployeeGridDetails[]> = await getApi().get(
    `Employee/GetPendingEmployees?pageSize=${props.pageSize}&pageIndex=${props.pageIndex}`,
  );
  return response.data;
};

export const getEmployeesWithWorkerclassRequest = async (companyId: number): Promise<IEmployeeWithWorkerclass[]> => {
  const response: AxiosResponse<IEmployeeWithWorkerclass[]> = await getApi().get(
    `Employee/GetEmployeesWithWorkerclass`,
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const inviteEmployeeRequest = async (props: { employee: any; companyId: string }): Promise<string> => {
  const { companyId, employee } = props;
  const response: AxiosResponse<string> = await getApi().post(`Employee/InviteEmployee`, employee, {
    headers: { companyId },
  });
  return response.data;
};

export const getEmployeeCountRequest = async (props: {
  companyId: number;
  weekNumber: number;
}): Promise<IEmployeeTotals> => {
  const { companyId, weekNumber } = props;
  const response: AxiosResponse<IEmployeeTotals> = await getApi().get(`Employee/GetEmployeeCount/${weekNumber}`, {
    headers: {
      companyId,
    },
  });
  return response.data;
};

export const downloadIdImageRequest = async (props: {
  companyId: number;
  employeeId: number;
  imageName: string;
  isBackImage: boolean;
}): Promise<BlobResponseType> => {
  const { employeeId, imageName, companyId, isBackImage } = props;
  const response: AxiosResponse<number> = await getApi().get(
    `File/GetContent?eid=${employeeId}&img=${imageName}&uFor=${isBackImage}`,
    {
      headers: { companyId },
      responseType: 'blob',
    },
  );

  const result: BlobResponseType = {
    content: response.data,
    contentType: response.headers['content-type'],
  };

  return result;
};

export const downloadEmployeesRequest = async (props: IEmployeeGridFilters): Promise<any> => {
  const { companyId, pageSize, pageIndex, workType, age, active, sortOrder, sortCriteria, name, locationId } = props;
  const response: AxiosResponse<number> = await getApi().post(
    `DataExport/ExportEmployeeData`,
    { pageSize, pageIndex, workType, age, active, sortOrder, sortCriteria, name, locationId },
    {
      headers: {
        companyId,
      },
      responseType: 'blob',
    },
  );
  return response.data;
};

export const getCompanyEmployeeInvitationLinksRequest = async (
  companyId: number,
): Promise<ICompanyInvitationLink[]> => {
  const response: AxiosResponse<ICompanyInvitationLink[]> = await getApi().get(`CompanyOnboarding/Get/${companyId}`);
  return response.data;
};

export const deletetCompanyEmployeeInvitationLinksRequest = async (id: number): Promise<void> => {
  await getApi().delete(`CompanyOnboarding/${id}`);
};

export const addCompanyInvitationRequest = async (data: ICompanyInvitationLink): Promise<number> => {
  const response: AxiosResponse<number> = await getApi().post(`CompanyOnboarding/New`, data, {
    headers: { companyId: data.companyId },
  });
  return response.data;
};

export const employeeInvitationTokenValidityRequest = async (
  invitationToken: string,
  companyToken: string,
): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await getApi().get(
    `Employee/EmployeeInvitationTokenValidity/${invitationToken}/${companyToken}`,
  );
  return response.data;
};
