import { UPDropdownItem } from 'components/dropdown';

export enum ApiErrorBusinessObjects {
  Employee = 1,
  Contract,
  Company,
}

export const ApiErrorBusinessObjectsLabels = {
  [ApiErrorBusinessObjects.Employee]: 'Employee',
  [ApiErrorBusinessObjects.Contract]: 'Contract',
  [ApiErrorBusinessObjects.Company]: 'Company',
};

export const GetApiErrorBusinessObjectAsDropdownItems = (): UPDropdownItem[] => {
  return [
    {
      value: ApiErrorBusinessObjects.Employee,
      label: ApiErrorBusinessObjectsLabels[ApiErrorBusinessObjects.Employee],
    },
    {
      value: ApiErrorBusinessObjects.Contract,
      label: ApiErrorBusinessObjectsLabels[ApiErrorBusinessObjects.Contract],
    },
    {
      value: ApiErrorBusinessObjects.Company,
      label: ApiErrorBusinessObjectsLabels[ApiErrorBusinessObjects.Company],
    },
  ];
};
