import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import {
  fromCompanyFinancialResponseToPaymentInformation,
  fromCompanyGridDetailsResponseToCompanies,
  fromCompanyPayrollToAddUpdatePayrollRequest,
  fromCompanyManagersResponseToCompanyManagers,
  fromCompanyManagerToManagerAddUpdateRequest,
  fromPaymentInformationeToFinancialAddUpdateRequest,
  fromPayrollFunctionToFunctionAddUpdateRequest,
  fromWageExemptonsFunctionToWageExemptionsAddUpdateRequest,
} from 'mappers/company-mapper';
import { ICompany, ICompanyGridDetails, ICompanyGridDetailsResponse } from 'types/company';
import ICompanyContact from 'types/company/contact/ICompanyContact';
import IPaymentInformation from 'types/company/financial/IPaymentInformation';
import ICompanyPayrollGeneral from 'types/company/payroll/ICompanyPayrollGeneral';
import ILocation from 'types/company/locations/ILocation';
import ILocationName from 'types/company/locations/ILocationName';
import ICompanyManager from 'types/company/managers/ICompanyManager';
import IPayrollFunction from 'types/company/payroll/IPayrollFunction';
import IPayrollCostCenter, { IPayrollCostCenterRequest } from 'types/company/payroll/IPayrollCostCenter';
import IPayrollWageExemptions from 'types/company/payroll/IPayrollWageExemptions';
import IFunctionsDescription from 'types/company/payroll/IFunctionsDescription';
import { UPDropdownItem } from 'components/dropdown';
import ICompanyVendor from 'types/company/managers/ICompanyVendor';
import {
  ICompanyExternalReferences,
  IEditableCompanyExternalReferences,
} from 'types/company/ICompanyExternalReferences';

export const getAllCompaniesRequest = async (): Promise<ICompanyGridDetails[]> => {
  const response: AxiosResponse<ICompanyGridDetailsResponse[]> = await getApi().get('Company');
  return fromCompanyGridDetailsResponseToCompanies(response.data);
};

export const getAllAsDropdownItemsRequest = async (): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get('Company/GetAll');
  return response.data;
};

export const getAllUserCompaniesAsDropdownItemsRequest = async (userId: number): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(
    `Company/GetUserCompaniesDropdownItems/${userId}`,
  );
  return response.data;
};

export const getAllUserCompaniesRequest = async (userId: number): Promise<ICompanyGridDetails[]> => {
  const response: AxiosResponse<ICompanyGridDetailsResponse[]> = await getApi().get(
    `Company/GetUserCompanies/${userId}`,
  );
  return fromCompanyGridDetailsResponseToCompanies(response.data);
};

export const getCompanyByIdRequest = async (companyId: number): Promise<ICompany> => {
  const response: AxiosResponse<ICompany> = await getApi().get(`Company/${companyId}`);
  return response.data;
};

export const saveCompanyRequest = async (company: ICompany): Promise<number> => {
  const formData = new FormData();
  Object.keys(company).map(key => key !== 'address' && formData.append(key, company[key]));
  Object.keys(company.address).map(key => formData.append(`address[${key}]`, company.address[key]));

  const response: AxiosResponse<number> = await getApi().post(`Company`, formData, {
    headers: { 'Content-Type': `multipart/form-data` },
  });

  return response.data;
};

export const updateCompanyRequest = async (props: { companyId: string; company: ICompany }): Promise<void> => {
  const { companyId, company } = props;
  const formData = new FormData();
  Object.keys(company).map(key => key !== 'address' && formData.append(key, company[key]));
  Object.keys(company.address).map(key => formData.append(`address[${key}]`, company.address[key]));

  await getApi().put(`Company/${companyId}`, formData, {
    headers: { 'Content-Type': `multipart/form-data` },
  });
};

export const deleteCompanyRequest = async (companyId: number): Promise<void> => {
  await getApi().delete(`Company/${companyId}`);
};

export const getParentCompaniesRequest = async (companyId: number): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(`Company/${companyId}/GetParentCompanies`);
  return response.data;
};

export const getChildCompaniesRequest = async (companyId: number): Promise<ICompanyGridDetails[]> => {
  const response: AxiosResponse<ICompanyGridDetailsResponse[]> = await getApi().get(
    `Company/${companyId}/GetChildCompanies`,
  );
  return fromCompanyGridDetailsResponseToCompanies(response.data);
};

export const checkIsParentCompanyRequest = async (companyId: number): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await getApi().get(`Company/${companyId}/CheckIsParentCompany`);
  return response.data;
};

// Contact
export const getCompanyContactRequest = async (id: number): Promise<ICompanyContact> => {
  const response: AxiosResponse<ICompanyContact> = await getApi().get(`Company/${id}/Contact`);
  return response.data;
};

export const postCompanyContactRequest = async (data: ICompanyContact): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await getApi().put(`Company/${data.id}/Contact`, data);
  return response.data;
};

// Locations
export const getAllLocationsRequest = async (companyId: number): Promise<ILocation[]> => {
  const response: AxiosResponse<ILocation[]> = await getApi().get(`Company/${companyId}/Locations`);
  return response.data;
};

export const putLocationRequest = async (props: { companyId: string; location: ILocation }): Promise<boolean> => {
  const { companyId, location } = props;
  const response: AxiosResponse<boolean> = await getApi().put(
    `Company/${companyId}/Locations/${location.id}`,
    location,
  );
  return response.data;
};

export const postLocationRequest = async (props: { companyId: string; location: ILocation }): Promise<number> => {
  const { companyId, location } = props;
  const response: AxiosResponse<number> = await getApi().post(`Company/${companyId}/Locations/New`, location);
  return response.data;
};

export const deleteLocationsRequest = async (props: { companyId: string; locationsIds: number[] }): Promise<void> => {
  const { companyId, locationsIds } = props;
  await getApi().delete(`Company/${companyId}/Locations`, { data: { Ids: locationsIds } });
};

export const getAllLocationNamesRequest = async (
  companyId: number,
  includeAllForManager: boolean,
): Promise<ILocationName[]> => {
  const response: AxiosResponse<ILocationName[]> = await getApi().get(
    `Company/${companyId}/${includeAllForManager}/LocationNames`,
  );
  return response.data;
};

export const getAllLocationsAsSelectItems = async (
  companyId: number,
  includeAllForManager?: boolean,
): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(
    `Company/${companyId}/${includeAllForManager}/LocationsSelectItems`,
  );
  return response.data;
};

// Managers
export const getAllManagersRequest = async (companyId: number): Promise<ICompanyManager[]> => {
  const response: AxiosResponse<ICompanyManager[]> = await getApi().get(`Company/${companyId}/Managers`);
  return fromCompanyManagersResponseToCompanyManagers(response.data);
};

export const putManagerRequest = async (props: { companyId: string; manager: ICompanyManager }): Promise<boolean> => {
  const { companyId, manager } = props;
  const mappedManager = fromCompanyManagerToManagerAddUpdateRequest(manager);
  const response: AxiosResponse<boolean> = await getApi().put(
    `Company/${companyId}/Managers/${mappedManager.id}`,
    mappedManager,
  );
  return response.data;
};

export const postManagerRequest = async (props: { companyId: string; manager: ICompanyManager }): Promise<number> => {
  const { companyId, manager } = props;
  const mappedManager = fromCompanyManagerToManagerAddUpdateRequest(manager);
  const response: AxiosResponse<number> = await getApi().post(`Company/${companyId}/Managers/New`, mappedManager);
  return response.data;
};

export const deleteManagersRequest = async (props: { companyId: string; managersIds: number[] }): Promise<void> => {
  const { companyId, managersIds } = props;
  await getApi().delete(`Company/${companyId}/Managers`, { data: { Ids: managersIds }, headers: { companyId } });
};

// Vendors
export const getAllVendorsRequest = async (companyId: string): Promise<ICompanyVendor[]> => {
  const response: AxiosResponse<ICompanyVendor[]> = await getApi().get(`Company/${companyId}/Vendors`);
  return response.data;
};

export const getVendorRequest = async (props: { companyId: string; vendorId: number }): Promise<ICompanyVendor> => {
  const { companyId, vendorId } = props;
  const response: AxiosResponse<ICompanyVendor> = await getApi().get(`Company/${companyId}/Vendors/${vendorId}`);
  return response.data;
};

export const putVendorRequest = async (props: { companyId: string; vendor: ICompanyVendor }): Promise<boolean> => {
  const { companyId, vendor } = props;
  const response: AxiosResponse<boolean> = await getApi().put(`Company/${companyId}/Vendors/${vendor.id}`, vendor);
  return response.data;
};

export const postVendorRequest = async (props: { companyId: string; vendor: ICompanyVendor }): Promise<number> => {
  const { companyId, vendor } = props;
  const response: AxiosResponse<number> = await getApi().post(`Company/${companyId}/Vendors/New`, vendor);
  return response.data;
};

export const deleteVendorsRequest = async (props: { companyId: string; vendorIds: number[] }): Promise<void> => {
  const { companyId, vendorIds } = props;
  await getApi().delete(`Company/${companyId}/Vendors`, { data: { Ids: vendorIds } });
};

export const getCompanyPayrollRequest = async (companyId: number): Promise<ICompanyPayrollGeneral> => {
  const response: AxiosResponse<ICompanyPayrollGeneral> = await getApi().get(`Company/${companyId}/Payroll`);
  return response.data;
};

export const addCompanyPayrollRequest = async (payroll: ICompanyPayrollGeneral): Promise<number> => {
  const response: AxiosResponse<number> = await getApi().post(
    `Company/${payroll.companyId}/Payroll`,
    fromCompanyPayrollToAddUpdatePayrollRequest(payroll),
  );
  return response.data;
};

export const updateCompanyPayrollRequest = async (payroll: ICompanyPayrollGeneral): Promise<void> => {
  await getApi().put(`Company/${payroll.companyId}/Payroll`, fromCompanyPayrollToAddUpdatePayrollRequest(payroll));
};

// Functions
export const getFunctionsRequest = async (companyId: number): Promise<IPayrollFunction[]> => {
  const response: AxiosResponse<IPayrollFunction[]> = await getApi().get(`Company/${companyId}/Functions`);
  return response.data;
};
export const deleteFunctionsRequest = async (props: { companyId: string; functionIds: number[] }): Promise<void> => {
  const { companyId, functionIds } = props;
  await getApi().delete(`Company/${companyId}/Functions`, { data: { Ids: functionIds } });
};
export const updateFunctionRequest = async (props: {
  companyId: number;
  functionToUpdate: IPayrollFunction;
}): Promise<void> => {
  const { companyId, functionToUpdate } = props;
  await getApi().put(`Company/${companyId}/Functions`, fromPayrollFunctionToFunctionAddUpdateRequest(functionToUpdate));
};

export const addFunctionRequest = async (props: {
  companyId: number;
  functionToAdd: IPayrollFunction;
}): Promise<number> => {
  const { companyId, functionToAdd } = props;
  const response: AxiosResponse<number> = await getApi().post(
    `Company/${companyId}/Functions`,
    fromPayrollFunctionToFunctionAddUpdateRequest(functionToAdd),
  );
  return response.data;
};

export const getAllFunctionsAsSelectItems = async (companyId: number): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(`Company/${companyId}/FunctionsSelectItems`);
  return response.data;
};

// Financial
export const getCompanyFinancialRequest = async (id: number): Promise<IPaymentInformation> => {
  const response: AxiosResponse<IPaymentInformation> = await getApi().get(`Company/${id}/Financial`);
  return fromCompanyFinancialResponseToPaymentInformation(response.data);
};

export const postCompanyFinancialRequest = async (props: {
  companyId: string;
  data: IPaymentInformation;
}): Promise<boolean> => {
  const mappedFinance = fromPaymentInformationeToFinancialAddUpdateRequest(props.data);
  const response: AxiosResponse<boolean> = await getApi().put(`Company/${props.companyId}/Financial`, mappedFinance);
  return response.data;
};

export const getBicRequest = async (props: { id: string; iban: string }): Promise<string> => {
  const { id, iban } = props;
  const response: AxiosResponse<string> = await getApi().get(`Company/${id}/Financial/GetBic/${iban}`);
  return response.data;
};

// Cost centres
export const getAllCostCentresRequest = async (companyId: number): Promise<IPayrollCostCenter[]> => {
  const response: AxiosResponse<IPayrollCostCenter[]> = await getApi().get(`Company/${companyId}/CostCenters`);
  return response.data;
};

export const deleteCostCentresRequest = async (props: {
  companyId: string;
  costCentresIds: number[];
}): Promise<void> => {
  const { companyId, costCentresIds } = props;
  await getApi().delete(`Company/${companyId}/CostCenters`, { data: { Ids: costCentresIds } });
};

export const putCostCenterRequest = async (props: {
  companyId: string;
  costCenter: IPayrollCostCenterRequest;
}): Promise<boolean> => {
  const { companyId, costCenter } = props;
  const response: AxiosResponse<boolean> = await getApi().put(
    `Company/${companyId}/CostCenters/${costCenter.id}`,
    costCenter,
  );
  return response.data;
};

export const postCostCenterRequest = async (props: {
  companyId: string;
  costCenter: IPayrollCostCenterRequest;
}): Promise<number> => {
  const { companyId, costCenter } = props;
  const response: AxiosResponse<number> = await getApi().post(`Company/${companyId}/CostCenters/New`, costCenter);
  return response.data;
};

export const getAllCostCentresAsSelectItems = async (
  companyId: number,
  locationId?: number,
): Promise<UPDropdownItem[]> => {
  let requestPath = `Company/${companyId}/CostCentersSelectItems`;
  if (locationId) {
    requestPath = `Company/${companyId}/CostCentersSelectItems/${locationId}`;
  }
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(requestPath);
  return response.data;
};

// Wage exemptions
export const getWageExemptionsRequest = async (companyId: number): Promise<IPayrollWageExemptions> => {
  const response: AxiosResponse<IPayrollWageExemptions> = await getApi().get(`Company/${companyId}/WageExemptions`);
  return response.data;
};

export const updateCompanyWageExemptionsRequest = async (wageExemptions: IPayrollWageExemptions): Promise<void> => {
  await getApi().put(
    `Company/${wageExemptions.id}/WageExemptions`,
    fromWageExemptonsFunctionToWageExemptionsAddUpdateRequest(wageExemptions),
  );
};

export const getFunctionDescriptionsRequest = async (companyId: number): Promise<IFunctionsDescription[]> => {
  const response: AxiosResponse<IFunctionsDescription[]> = await getApi().get(
    `Company/${companyId}/FunctionsDescriptions`,
  );
  return response.data;
};

// integrations
export const getExternalReferenceRequest = async (id: number): Promise<ICompanyExternalReferences> => {
  const response: AxiosResponse<ICompanyExternalReferences> = await getApi().get(`Company/${id}/GetExternalReferences`);
  return response.data;
};

export const updateCompanyExternalReferencesRequest = async (props: {
  companyId: string;
  data: IEditableCompanyExternalReferences;
}): Promise<void> => {
  await getApi().put(`Company/${props.companyId}/ExternalReferences`, props.data);
};
