import loadAuth from './load-auth';
import loadConfig from './load-config';
import loadTranslations from './load-translations';
import initSentry from './load-sentry';

const startup = async (): Promise<void> => {
  await loadConfig();
  await initSentry();
  await loadTranslations();
  await loadAuth();
};

export default startup;
