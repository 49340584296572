export interface IAddContractSlotFields {
  id: number;
  companyId: string;
  vendorId: string;
  functionId: string;
  contractType: string;
  costCenterId: string;
  locationId: string;
}

export enum ContractSlotFieldNames {
  VENDOR_ID = 'vendorId',
  FUNCTION_ID = 'functionId',
  CONTRACT_TYPE = 'contractType',
  COST_CENTER_ID = 'costCenterId',
  LOCATION_ID = 'locationId',
}
