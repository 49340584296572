import { createReducer } from '@reduxjs/toolkit';
import {
  setSuperAdminsAction,
  removeManySuperAdminsAction,
  removeAllSuperAdminsAction,
  setSuperAdminsRequestsLoadingAction,
  updateOneSuperAdminAction,
  addOneSuperAdminAction,
} from 'store/actions/superadmins-actions';
import superAdminsAdapter from 'store/adapters/superadmins-adapter';
import ISuperAdminsState from 'types/superadmin/ISuperAdminsState';

const initialState: ISuperAdminsState = {
  superAdmins: [],
  loading: false,
  superAdminsError: false,
  apiUsers: [],
};

const superAdminsReducer = createReducer(
  superAdminsAdapter.getInitialState<ISuperAdminsState>(initialState),
  builder => {
    return builder
      .addCase(setSuperAdminsAction, superAdminsAdapter.setAll)
      .addCase(updateOneSuperAdminAction, superAdminsAdapter.updateOne)
      .addCase(addOneSuperAdminAction, superAdminsAdapter.addOne)
      .addCase(removeManySuperAdminsAction, superAdminsAdapter.removeMany)
      .addCase(removeAllSuperAdminsAction, superAdminsAdapter.removeAll)
      .addCase(setSuperAdminsRequestsLoadingAction, (state, action) => ({
        ...state,
        loading: action.payload,
      }));
  },
);

export default superAdminsReducer;
