import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { INightShift, IOvertimeLimits, IServiceCode, IWorkhourSettings } from 'types/wage-calculation';
import { OvertimeLimitsFields, WorkhourSettingsFields } from './types';

const isValidCode = (code: string) => /^\d{4,6}$/.test(code);

const isValidNumber = (value: string) => /^\d{1,3}$/.test(value);

const onChangeValue = (
  event: ChangeEvent<HTMLInputElement>,
  setValue:
    | Dispatch<SetStateAction<INightShift>>
    | Dispatch<SetStateAction<IServiceCode>>
    | Dispatch<SetStateAction<IOvertimeLimits>>
    | Dispatch<SetStateAction<IWorkhourSettings>>,
  validationFunction: (value: string) => boolean,
  field: OvertimeLimitsFields | WorkhourSettingsFields | 'code',
  setValidValues: Dispatch<SetStateAction<boolean>>,
  setShouldUpdate: Dispatch<SetStateAction<boolean>>,
) => {
  const { value } = event.target;

  if (Number.isNaN(+value)) return;

  setValidValues(validationFunction(value));

  setValue(prevState => ({
    ...prevState,
    [field]: value,
  }));

  setShouldUpdate(true);
};

export const onChangeCode = (
  event: ChangeEvent<HTMLInputElement>,
  setCode: Dispatch<SetStateAction<INightShift>> | Dispatch<SetStateAction<IServiceCode>>,
  setValidValues: Dispatch<SetStateAction<boolean>>,
  setShouldUpdate: Dispatch<SetStateAction<boolean>>,
) => {
  onChangeValue(event, setCode, isValidCode, 'code', setValidValues, setShouldUpdate);
};

export const onChangeHourValue = (
  event: ChangeEvent<HTMLInputElement>,
  setHourValue: Dispatch<SetStateAction<IOvertimeLimits>> | Dispatch<SetStateAction<IWorkhourSettings>>,
  field: OvertimeLimitsFields | WorkhourSettingsFields,
  setValidValues: Dispatch<SetStateAction<boolean>>,
  setShouldUpdate: Dispatch<SetStateAction<boolean>>,
) => {
  onChangeValue(event, setHourValue, isValidNumber, field, setValidValues, setShouldUpdate);
};
