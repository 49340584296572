import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyContactRequest, postCompanyContactRequest } from 'api/requests/company-requests';
import {
  COMPANY_CONTACT__GET,
  COMPANY_CONTACT__SET_CONTACT,
  COMPANY_CONTACT__SET_CONTACT_ERROR,
  COMPANY_CONTACT__PUT,
  COMPANY_CONTACT__SET_CONTACT_UPDATE_SUCCESSFUL,
  COMPANY_CONTACT__CLEAR,
  COMPANY_CONTACT__GENERAL_LOADING,
} from 'store/constants';
import ICompanyContact from 'types/company/contact/ICompanyContact';

export const setCompanyContactRequestsLoadingAction = createAction<boolean>(COMPANY_CONTACT__GENERAL_LOADING);

export const setCompanyContactAction = createAction<ICompanyContact>(COMPANY_CONTACT__SET_CONTACT);
export const setCompanyContactErrorAction = createAction<boolean>(COMPANY_CONTACT__SET_CONTACT_ERROR);
export const setCompanyContactUpdateSuccessfulAction = createAction<boolean>(
  COMPANY_CONTACT__SET_CONTACT_UPDATE_SUCCESSFUL,
);

export const getCompanyContactAction = createAsyncThunk(COMPANY_CONTACT__GET, async (id: number, thunkApi) => {
  thunkApi.dispatch(setCompanyContactRequestsLoadingAction(true));
  thunkApi.dispatch(setCompanyContactErrorAction(false));

  try {
    const response = await getCompanyContactRequest(id);
    thunkApi.dispatch(setCompanyContactAction(response));
  } catch (error) {
    thunkApi.dispatch(setCompanyContactErrorAction(true));
  }
  thunkApi.dispatch(setCompanyContactRequestsLoadingAction(false));
});

export const putCompanyContactAction = createAsyncThunk(
  COMPANY_CONTACT__PUT,
  async (data: ICompanyContact, thunkApi) => {
    thunkApi.dispatch(setCompanyContactRequestsLoadingAction(true));
    thunkApi.dispatch(setCompanyContactErrorAction(false));
    try {
      await postCompanyContactRequest(data);
    } catch (error) {
      thunkApi.dispatch(setCompanyContactErrorAction(true));
      thunkApi.dispatch(setCompanyContactUpdateSuccessfulAction(false));
    }
    thunkApi.dispatch(setCompanyContactUpdateSuccessfulAction(true));
    thunkApi.dispatch(setCompanyContactRequestsLoadingAction(false));
  },
);

export const clearCompanyContactAction = createAction<void>(COMPANY_CONTACT__CLEAR);
