import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { toNumber } from 'lodash';
import IUser from 'types/superadmin/ISuperAdmin';

// SuperAdmin
export const getAllSuperAdminsRequest = async (): Promise<IUser[]> => {
  const response: AxiosResponse<IUser[]> = await getApi().get('User/GetAllSuperAdmins');
  return response.data;
};

export const updateSuperAdminRequest = async (superAdmin: IUser): Promise<void> => {
  await getApi().put(`User/SuperAdmin/${superAdmin.id}`, {
    ...superAdmin,
    languageId: toNumber(superAdmin.languageId),
  });
};

export const addSuperAdminRequest = async (superAdmin: IUser): Promise<number> => {
  const response: AxiosResponse<number> = await getApi().post(`User/SuperAdmin`, {
    ...superAdmin,
    languageId: toNumber(superAdmin.languageId),
  });
  return response.data;
};

export const deleteSuperAdminsRequest = async (superAdminIds: number[]): Promise<void> => {
  await getApi().delete(`User/SuperAdmin`, { data: { Ids: superAdminIds } });
};

// api users
export const getAllApiUsersRequest = async (): Promise<IUser[]> => {
  const response: AxiosResponse<IUser[]> = await getApi().get('User/GetAllApiUsers');
  return response.data;
};

export const updateApiUserRequest = async (user: IUser): Promise<void> => {
  const formData = new FormData();

  Object.keys(user).map(k => k !== 'selectedCompanies' && formData.append(k, user[k]));

  if (!user.password) {
    formData.delete('password');
  }

  Object.keys(user.selectedCompanies).map(key =>
    formData.append(`selectedCompanies[${key}]`, user.selectedCompanies[key]),
  );

  await getApi().put(`User/ApiUser/${user.id}`, formData, {
    headers: { 'Content-Type': `multipart/form-data` },
  });
};

export const addApiUserRequest = async (user: IUser): Promise<number> => {
  const formData = new FormData();

  Object.keys(user).map(k => k !== 'selectedCompanies' && formData.append(k, user[k]));
  Object.keys(user.selectedCompanies).map(key =>
    formData.append(`selectedCompanies[${key}]`, user.selectedCompanies[key]),
  );

  const response: AxiosResponse<number> = await getApi().post(`User/ApiUser`, formData, {
    headers: { 'Content-Type': `multipart/form-data` },
  });
  return response.data;
};

export const deleteApiUsersRequest = async (userIds: number[]): Promise<void> => {
  await getApi().delete(`User/ApiUser`, { data: { Ids: userIds } });
};
