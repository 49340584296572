import { RootState } from 'store';

export const itemsPerPageSelector = (state: RootState): number => state.app.itemsPerPage;
export const appGeneralLoadingSelector = (state: RootState): boolean =>
  state.auth.loading ||
  state.availabilities.loading ||
  state.blog.loading ||
  state.company.loading ||
  state.contact.loading ||
  state.costCenter.loading ||
  state.costDeclaration.loading ||
  state.costCenters.loading ||
  state.costDeclaration.loading ||
  state.financial.loading ||
  state.function.loading ||
  state.functions.loading ||
  state.location.loading ||
  state.locations.loading ||
  state.manager.loading ||
  state.managers.loading ||
  state.vendors.loading ||
  state.wageExemptions.loading ||
  state.contracts.loading ||
  state.countries.loading ||
  state.documents.loading ||
  state.employee.loading ||
  state.bic.loading ||
  state.minimumWages.loading ||
  state.paritairComite.loading ||
  state.payroll.loading ||
  state.profile.loading ||
  state.superAdmin.loading ||
  state.superAdmins.loading ||
  state.wageCalculation.loading ||
  state.apiUsers.loading ||
  state.reporting.loading ||
  state.apiErrors.getErrorsLoading;
