import { useTranslation } from 'react-i18next';
import {
  MODES_OF_TRANSPORT__PRIVATE,
  MODES_OF_TRANSPORT__BIKE,
  MODES_OF_TRANSPORT__PUBLIC_TRANSPORT,
} from 'translations/constants';

const useModesOfTransportTranslation = (transportMode: number) => {
  const [t] = useTranslation();
  const getTranslation = () => {
    switch (transportMode) {
      case 1:
        return t(MODES_OF_TRANSPORT__PRIVATE);
      case 2:
        return t(MODES_OF_TRANSPORT__BIKE);
      case 3:
        return t(MODES_OF_TRANSPORT__PUBLIC_TRANSPORT);
      default:
        return '';
    }
  };
  return getTranslation();
};
export default useModesOfTransportTranslation;
