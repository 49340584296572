import { createReducer } from '@reduxjs/toolkit';
import {
  setCountriesNamesAction,
  setCountriesRequestsLoadingAction,
  setCountriesNamesErrorAction,
} from 'store/actions/countries-actions';
import ICountriesState from 'types/country/ICountriesState';

const initialState: ICountriesState = {
  countriesNames: [],
  loading: false,
  countriesError: false,
};

const countriesReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setCountriesNamesAction, (state, action) => ({ ...state, countriesNames: action.payload }))
    .addCase(setCountriesRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setCountriesNamesErrorAction, (state, action) => ({
      ...state,
      countriesError: action.payload,
    }));
});

export default countriesReducer;
