import { Button, styled } from '@mui/material';
import { Box } from '@mui/system';

export const HeaderCell = styled(Box)(({ theme }) => ({
  width: '140px',
  height: '50px',
  borderRadius: '8px',
  color: theme.palette.secondary.contrastText,
}));

export const DayOfWeek = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: '12px',
  color: theme.palette.secondary.contrastText,
  '.MuiTypography-root': {
    fontSize: '14px',
    lineHeight: '20px',
  },
  '.MuiCheckbox-root': {
    padding: 0,
    marginRight: '5px',
  },
}));

export const DayPickerButtonStyled = styled(Button)(() => ({
  '&.MuiButton-root:hover': { backgroundColor: 'white' },
  '&.MuiButton-root': { width: '18px' },
  padding: '0px',
  color: 'white',
  width: '18px',
  height: '42px',
  '&.MuiButton-root:focus': { backgroundColor: 'white' },
}));

export const DatesBoxStyled = styled(Box)<{ error?: boolean }>(({ theme, error }) => ({
  border: `1px solid ${theme.palette.secondary.light}`,
  borderRadius: '4px',
  height: '48px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  ...(error && {
    borderColor: theme.palette.error.main,
  }),
}));

export const DisplayDatesStyled = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  float: 'left',
  padding: '6px 2px',
  maxWidth: '240px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  '& .MuiChip-root': {
    fontSize: '12px',
    borderRadius: '4px',
    margin: '1px',
    backgroundColor: theme.palette.info.main,
    '& .MuiChip-label': {
      padding: '0px 5px',
    },
  },
}));
