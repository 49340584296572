import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMinimumWagesByParitairComiteRequest,
  saveMinimumWageRequest,
  updateMinimumWageRequest,
} from 'api/requests/minimum-wage-requests';
import {
  MINIMUM_WAGES__SET_ALL,
  MINIMUM_WAGES__SET_ALL_ERROR,
  MINIMUM_WAGES__GET_ALL,
  MINIMUM_WAGES__ADD_ONE,
  MINIMUM_WAGES__UPDATE_ONE,
  MINIMUM_WAGES__UPDATE,
  MINIMUM_WAGES__UPDATE_ERROR,
  MINIMUM_WAGES__ADD_ERROR,
  MINIMUM_WAGES__ADD,
  MINIMUM_WAGES__GENERAL_LOADING,
} from 'store/constants';
import { IMinimumWage, IMinimumWageFilters } from 'types/minimum-wage';

export const setMinimumWagesRequestsLoadingAction = createAction<boolean>(MINIMUM_WAGES__GENERAL_LOADING);

export const setMinimumWagesAction = createAction<IMinimumWage[]>(MINIMUM_WAGES__SET_ALL);
export const setMinimumWagesErrorAction = createAction<boolean>(MINIMUM_WAGES__SET_ALL_ERROR);

export const getMinimumWagesAction = createAsyncThunk(
  MINIMUM_WAGES__GET_ALL,
  async (filters: IMinimumWageFilters, thunkApi) => {
    thunkApi.dispatch(setMinimumWagesRequestsLoadingAction(true));
    thunkApi.dispatch(setMinimumWagesErrorAction(false));
    try {
      const response = await getMinimumWagesByParitairComiteRequest(filters);
      thunkApi.dispatch(setMinimumWagesAction(response));
    } catch (error) {
      thunkApi.dispatch(setMinimumWagesErrorAction(true));
    }
    thunkApi.dispatch(setMinimumWagesRequestsLoadingAction(false));
  },
);

export const updateOneMinWageAction = createAction<{ id: number; changes: IMinimumWage }>(MINIMUM_WAGES__UPDATE_ONE);
export const addOneMinWageAction = createAction<IMinimumWage>(MINIMUM_WAGES__ADD_ONE);

export const updateMinWageErrorAction = createAction<boolean>(MINIMUM_WAGES__UPDATE_ERROR);

export const updateMinimumWageAction = createAsyncThunk(
  MINIMUM_WAGES__UPDATE,
  async (minimumWage: IMinimumWage, thunkApi) => {
    thunkApi.dispatch(setMinimumWagesRequestsLoadingAction(true));
    thunkApi.dispatch(updateMinWageErrorAction(false));
    try {
      await updateMinimumWageRequest(minimumWage);
      thunkApi.dispatch(updateOneMinWageAction({ id: minimumWage.id, changes: minimumWage }));
    } catch (error) {
      thunkApi.dispatch(updateMinWageErrorAction(true));
    }
    thunkApi.dispatch(setMinimumWagesRequestsLoadingAction(false));
  },
);

export const addMinWageErrorAction = createAction<boolean>(MINIMUM_WAGES__ADD_ERROR);

export const addMinimumWageAction = createAsyncThunk(
  MINIMUM_WAGES__ADD,
  async (minimumWage: IMinimumWage, thunkApi) => {
    thunkApi.dispatch(setMinimumWagesRequestsLoadingAction(true));
    thunkApi.dispatch(addMinWageErrorAction(false));
    try {
      const minimumWageRequest = {
        ...minimumWage,
        id: 0,
      };
      const minWageId = await saveMinimumWageRequest(minimumWageRequest);
      const newMinWage: IMinimumWage = { ...minimumWage, id: minWageId };
      thunkApi.dispatch(addOneMinWageAction(newMinWage));
    } catch (error) {
      thunkApi.dispatch(addMinWageErrorAction(true));
    }
    thunkApi.dispatch(setMinimumWagesRequestsLoadingAction(false));
  },
);
