import { GridColDef } from '@mui/x-data-grid';
import { UPDataGrid } from 'components/data-grid';
import { Box, Tooltip } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { costDeclarationsSelector } from 'store/selectors/cost-declaration-selectors';
import {
  bulkChangeActiveStatusAction,
  deleteCostDeclarationsAction,
  getCostDeclarationsAction,
  updateCostDeclarationAction,
} from 'store/actions/cost-declaration-actions';
import UPButton from 'components/button';
import {
  COMPANY_PAYROLL__PARITAIR_COMITE,
  CONTRACTS__CLEAR_ALL,
  CONTRACTS__DELETE_SELECTED,
  GENERAL__EDIT,
  GENERAL__LAST_MODIFIED_BY,
  GENERAL__LAST_MODIFIED_DATE,
  WAGE_COMPONENTS__COST_DECLARATION_HEADER,
  WAGE_COMPONENTS__COST_DECLARATION_ADD,
  WAGE_COMPONENTS__COST_DECLARATION_CHANGE_ACTIV_STATUS,
  WAGE_COMPONENTS__COST_DECLARATION_FOR_ALL_PC,
  WAGE_COMPONENTS__COST_DECLARATION_FOR_NO_PC,
} from 'translations/constants';
import { useTranslation } from 'react-i18next';
import { AlertStyled } from 'components/bottom-green-bar/styles';
import { ICostDeclaration } from 'types/cost-declaration';
import { paritairComiteSelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import { getParitairComiteSelectItemsAction } from 'store/actions/paritair-comite-actions';
import { PayrollAddNewItemButton } from 'modules/companies/payroll/styles';
import { ReactComponent as RedIcon } from '../../../assets/icons/RedCircle.svg';
import { ReactComponent as GreenIcon } from '../../../assets/icons/GreenCircle.svg';
import AddEditCostDeclaration from './AddEditCostDeclaration';
import { CostDeclarationGridContainer, RowActionContainer } from '../styles';

const CostDeclaration = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [costDeclarationToEdit, setCostDeclarationToEdit] = useState<ICostDeclaration>(null);
  const [addEditModalOpen, setAddEditModalOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const costDeclarations = useAppSelector(costDeclarationsSelector);
  const paritairComiteOptions = useAppSelector(paritairComiteSelectItemsSelector);

  const paritairComiteMap = useMemo(() => {
    const map: { [key: string]: string } = {};
    paritairComiteOptions.forEach(option => {
      map[option.value] = option.label;
    });
    return map;
  }, [paritairComiteOptions]);

  useEffect(() => {
    dispatch(getCostDeclarationsAction());
    dispatch(getParitairComiteSelectItemsAction());
  }, [dispatch]);

  const columnDef: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'name',
      headerName: t(WAGE_COMPONENTS__COST_DECLARATION_HEADER),
      width: 300,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 100,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        if (params.row.isActive) {
          return (
            <Tooltip title="Active">
              <GreenIcon />
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Inactive">
            <RedIcon />
          </Tooltip>
        );
      },
    },
    {
      field: 'lastModifiedUser',
      headerName: t(GENERAL__LAST_MODIFIED_BY),
      width: 200,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lastModifiedDate',
      headerName: t(GENERAL__LAST_MODIFIED_DATE),
      width: 200,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return new Date(params.row.lastModifiedDate).toDateString();
      },
    },
    {
      field: 'paritairComites',
      headerName: t(COMPANY_PAYROLL__PARITAIR_COMITE),
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        if (params.row.paritairComites.length > 0) {
          return params.row.paritairComites
            .map(paritairComite => {
              return paritairComiteMap[paritairComite];
            })
            .join(', ');
        }

        if (params.row.isForAllParitaireComites) {
          return t(WAGE_COMPONENTS__COST_DECLARATION_FOR_ALL_PC);
        }
        return t(WAGE_COMPONENTS__COST_DECLARATION_FOR_NO_PC);
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 300,
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id && selectedRows.length === 0) {
          return (
            <RowActionContainer>
              <UPButton
                onClick={() => {
                  setAddEditModalOpen(true);
                  setIsEdit(true);
                  setCostDeclarationToEdit(params.row);
                }}
                text={t(GENERAL__EDIT)}
                outlined
              />
              <UPButton
                onClick={() => dispatch(updateCostDeclarationAction({ ...params.row, isActive: !params.row.isActive }))}
                text={params.row.isActive ? 'Deactivate' : 'Activate'}
                outlined={params.row.isActive}
              />
            </RowActionContainer>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box>
      <Box>
        <PayrollAddNewItemButton
          text={`+ ${t(WAGE_COMPONENTS__COST_DECLARATION_ADD)}`}
          onClick={() => {
            setAddEditModalOpen(true);
            setIsEdit(false);
          }}
        />
      </Box>
      <CostDeclarationGridContainer>
        <UPDataGrid
          rows={costDeclarations}
          columns={columnDef}
          onSelectionModelChange={(selectedItems: number[]) => {
            setSelectedRows(selectedItems);
            setClearAll(false);
          }}
          selectionModel={clearAll ? [] : selectedRows}
          setRowOnHover={setRowOnHover}
          disableSelectionOnClick
        />
      </CostDeclarationGridContainer>
      {selectedRows.length > 0 && (
        <AlertStyled severity="success" icon={false}>
          {selectedRows.length} cost declaration(s) selected |
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            {t(CONTRACTS__CLEAR_ALL)}
          </button>
          |
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              dispatch(deleteCostDeclarationsAction(selectedRows));
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            {t(CONTRACTS__DELETE_SELECTED)}
          </button>
          |
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              dispatch(bulkChangeActiveStatusAction(selectedRows));
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            {t(WAGE_COMPONENTS__COST_DECLARATION_CHANGE_ACTIV_STATUS)}
          </button>
        </AlertStyled>
      )}
      {addEditModalOpen && (
        <AddEditCostDeclaration
          open={addEditModalOpen}
          onClose={() => {
            setAddEditModalOpen(false);
          }}
          isEdit={isEdit}
          PCOptions={paritairComiteOptions}
          costDeclarationToEdit={costDeclarationToEdit}
        />
      )}
    </Box>
  );
};

export default CostDeclaration;
