import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { useAppDispatch, useAppSelector } from 'store';
import { useParams } from 'react-router-dom';
import { deleteCostCentersAction, getCostCentersAction } from 'store/actions/company-cost-centers-actions';
import { toNumber } from 'lodash';
import { costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import { EditPayrollCostCenter } from '.';
import { PayrollAddNewItemButton } from './styles';
import {
  COMPANY_PAYROLL__COST_CENTER_DESCRIPTION,
  COMPANY_INFORMATION__STREET_NAME,
  COMPANY_INFORMATION__STREET_NUMBER,
  COMPANY_INFORMATION__BUS_NUMBER,
  COMPANY_INFORMATION__CITY,
  COMPANY_INFORMATION__POSTAL_CODE,
  GENERAL__EDIT,
  COMPANY_PAYROLL__COST_CENTER_ADD_NEW_COST_CENTER,
  GENERAL__DELETE_SELECTED,
  GENERAL__DELETE_MESSAGE,
  COMPANY_PAYROLL__COST_CENTER_EDIT_COST_CENTER,
  CONTRACTS__COMPANY_LOCATION,
} from '../../../translations/constants';

const PayrollCostCenters = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const costCenters = useAppSelector(costCentersSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [openAddEditCostCenter, setOpenAddEditCostCenter] = useState<boolean>(false);
  const [isAddNewButtonClicked, setIsAddNewButtonClicked] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCostCentersAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  const onOpenAddEditModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenAddEditCostCenter(true);
  }, []);

  const onCloseAddEditModal = useCallback(() => {
    setOpenAddEditCostCenter(false);
    setIsAddNewButtonClicked(false);
    setTimeout(() => {
      dispatch(getCostCentersAction(toNumber(companyId)));
    }, 1500);
  }, [companyId, dispatch]);

  const getCostCenterOnEdit = useCallback(() => {
    return costCenters.find(costCenter => costCenter.id === rowOnHover);
  }, [costCenters, rowOnHover]);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const onOpenDeleteModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeleteModal(true);
  }, []);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'name', headerName: t(COMPANY_PAYROLL__COST_CENTER_DESCRIPTION), width: 200 },
    { field: 'locationName', headerName: t(CONTRACTS__COMPANY_LOCATION), width: 200 },
    { field: 'streetName', headerName: t(COMPANY_INFORMATION__STREET_NAME), width: 200 },
    { field: 'streetNumber', headerName: t(COMPANY_INFORMATION__STREET_NUMBER), width: 200 },
    { field: 'busNumber', headerName: t(COMPANY_INFORMATION__BUS_NUMBER), width: 200 },
    { field: 'city', headerName: t(COMPANY_INFORMATION__CITY), width: 200 },
    { field: 'postalCode', headerName: t(COMPANY_INFORMATION__POSTAL_CODE), width: 200 },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return <UPButton onClick={onOpenAddEditModal} text={t(GENERAL__EDIT)} outlined />;
        }
        return null;
      },
    },
  ];

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(COMPANY_PAYROLL__COST_CENTER_ADD_NEW_COST_CENTER)}`}
          onClick={event => {
            setRowOnHover(null);
            setIsAddNewButtonClicked(true);
            onOpenAddEditModal(event);
          }}
        />
        <Box sx={{ marginLeft: '15px' }}>
          <PayrollAddNewItemButton
            text={t(GENERAL__DELETE_SELECTED)}
            onClick={onOpenDeleteModal}
            outlined
            disabled={!(selectedRows.length > 0)}
          />
        </Box>
      </Box>
      <Box sx={{ height: '400px', width: '100%' }}>
        <UPDataGrid
          rows={costCenters}
          columns={columns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={onSelectionChange}
          getRowId={r => r.id}
        />
        {openAddEditCostCenter && (
          <EditPayrollCostCenter
            open={openAddEditCostCenter}
            onClose={onCloseAddEditModal}
            costCenter={getCostCenterOnEdit()}
            title={
              isAddNewButtonClicked
                ? t(COMPANY_PAYROLL__COST_CENTER_ADD_NEW_COST_CENTER)
                : t(COMPANY_PAYROLL__COST_CENTER_EDIT_COST_CENTER)
            }
            isEdit={!isAddNewButtonClicked}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            content={t(GENERAL__DELETE_MESSAGE)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => {
              dispatch(deleteCostCentersAction({ companyId, costCentresIds: selectedRows }));
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PayrollCostCenters;
