import { Box, MenuItem } from '@mui/material';
import { useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendar.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';
import {
  UPInputLabel,
  UPInputTextField,
  UIInputIconContainer,
  UPInputFormControl,
  UPInputDivider,
  UPInputSelect,
} from './styles';
import { UPInputProps } from './types';
import './styles.scss';

const UPInput = (props: UPInputProps): JSX.Element => {
  const {
    value,
    onChange,
    label,
    type,
    placeholder,
    required,
    disabled,
    readOnly,
    error,
    helperText,
    helperTextExtended,
    fullWidth,
    icon,
    className,
    min,
    max,
    step,
    onKeyPress,
    onBlur,
    inputClassName,
    autoComplete,
    sx,
  } = props;

  const endAdornment = useMemo((): JSX.Element => {
    switch (icon) {
      case 'calendar':
        return (
          <UIInputIconContainer>
            <CalendarIcon />
          </UIInputIconContainer>
        );
      case 'search':
        return (
          <UIInputIconContainer>
            <SearchIcon />
          </UIInputIconContainer>
        );
      case 'arrow':
        return (
          <UIInputIconContainer sx={{ paddingTop: '5px' }}>
            <ArrowDropDownIcon />
          </UIInputIconContainer>
        );
      case 'currency':
        return (
          <UPInputFormControl>
            <UPInputDivider orientation="vertical" />
            <UPInputSelect value="1" onChange={() => null} variant="standard" disableUnderline>
              <MenuItem value={1}>EUR</MenuItem>
              <MenuItem value={2}>USD</MenuItem>
            </UPInputSelect>
          </UPInputFormControl>
        );
      default:
        return null;
    }
  }, [icon]);

  return (
    <Box className={className} sx={sx ?? { width: '100%' }}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <UPInputTextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
        onKeyPress={onKeyPress}
        InputProps={{
          endAdornment,
          inputProps: {
            min,
            max,
            step,
            autoComplete,
            readOnly,
          },
        }}
        FormHelperTextProps={{
          className: helperTextExtended ? 'extended-helper-text' : '',
        }}
        className={inputClassName}
      />
    </Box>
  );
};

UPInput.defaultProps = {
  type: 'text',
  required: false,
  disabled: false,
  readOnly: false,
  fullWidth: true,
  error: false,
  helperText: '',
  helperTextExtended: false,
  icon: '',
  className: '',
  onKeyPress: null,
  inputClassName: '',
  autoComplete: 'on',
};

export default UPInput;
