import { UPDropdownItem } from 'components/dropdown';
import { RootState } from 'store';
import costCentersAdapter from 'store/adapters/company-cost-centers-adapter';

export const costCentersSelectors = costCentersAdapter.getSelectors((state: RootState) => state.costCenters);
export const costCentersSelector = costCentersSelectors.selectAll;
export const costCentersErrorSelector = (state: RootState): boolean => state.costCenters.costCentersError;

export const costCentersDropdownItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.costCenters.costCentersDropdownItems;
export const costCentersDropdownItemsErrorSelector = (state: RootState): boolean =>
  state.costCenters.costCentersDropdownItemsError;

export const costCentersLoadingSelector = (state: RootState): boolean => state.costCenters.loading;
