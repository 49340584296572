import { createReducer } from '@reduxjs/toolkit';
import {
  clearCompanyWageExemptionsAction,
  getFunctionDescriptionsError,
  getWageExemptionsErrorAction,
  setWageExemptionsRequestsLoadingAction,
  setFunctionDescriptions,
  setWageExemptionsAction,
  updateWageExemptionsErrorAction,
  updateWageExemptionsSuccessAction,
} from 'store/actions/company-wage-exemptions-actions';
import IWageExemptionsState from 'types/company/payroll/IWageExemptionsState';

const initialState: IWageExemptionsState = {
  wageExemptions: null,
  loading: false,
  getWageExemptionsError: false,
  updateWageExemptionsError: false,
  updateWageExemptionsSuccess: false,
  getFunctionsDescriptionError: false,
  functionsDescription: null,
};

const wageExemptionsReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setWageExemptionsAction, (state, action) => ({ ...state, wageExemptions: action.payload }))
    .addCase(setWageExemptionsRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(getWageExemptionsErrorAction, (state, action) => ({ ...state, getWageExemptionsError: action.payload }))
    .addCase(updateWageExemptionsErrorAction, (state, action) => ({
      ...state,
      updateWageExemptionsError: action.payload,
    }))
    .addCase(updateWageExemptionsSuccessAction, (state, action) => ({
      ...state,
      updateWageExemptionsSuccess: action.payload,
    }))
    .addCase(getFunctionDescriptionsError, (state, action) => ({
      ...state,
      getFunctionsDescriptionError: action.payload,
    }))
    .addCase(setFunctionDescriptions, (state, action) => ({
      ...state,
      functionsDescription: action.payload,
    }))
    .addCase(clearCompanyWageExemptionsAction, state => ({
      ...state,
      wageExemptions: null,
      loading: false,
      getWageExemptionsError: false,
      updateWageExemptionsError: false,
      updateWageExemptionsSuccess: false,
      getFunctionsDescriptionError: false,
      functionsDescription: null,
    }));
});

export default wageExemptionsReducer;
