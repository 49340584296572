/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@mui/material';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import { UPInputLabel } from 'components/input/styles';
import { CompanyRole } from 'constants/CompanyRole';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import { profileSelector } from 'store/selectors/profile-selectors';
import { UPButtonGroupForEmployeeStyled, UPButtonGroupStyled } from './styles';
import { UPGroupButtonProps } from './types';

const UPButtonGroup = (props: UPGroupButtonProps): JSX.Element => {
  const { children, label, required, error, value, onChange, helperText, page } = props;
  const isEmployee = useAppSelector(profileSelector)?.role === CompanyRole.EMPLOYEE;
  const location = useLocation();

  const onChangeValue = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <Box>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      {page && page === 'employee' ? (
        <UPButtonGroupForEmployeeStyled
          color={error ? 'error' : 'primary'}
          value={value}
          onChange={onChangeValue}
          exclusive
          disabled={isEmployee && location.pathname === '/employees' && label === 'Sex'}
        >
          {children}
        </UPButtonGroupForEmployeeStyled>
      ) : (
        <UPButtonGroupStyled
          color={error ? 'error' : 'primary'}
          value={value}
          onChange={onChangeValue}
          exclusive
          disabled={isEmployee && location.pathname === '/employees' && label === 'Sex'}
        >
          {children}
        </UPButtonGroupStyled>
      )}

      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

UPButtonGroup.defaultProps = {
  error: false,
  required: false,
  helperText: '',
};

export default UPButtonGroup;
