import { Box, styled } from '@mui/system';

export const WageExemptionsContainer = styled(Box)(({ theme }) => ({
  marginLeft: '32px',
  marginTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
  [theme.breakpoints.down('lg')]: {
    width: '50%',
  },
  [theme.breakpoints.down('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

export const UPDataGridContainer = styled(Box)(() => ({
  height: '300px',
  width: '100%',
}));
