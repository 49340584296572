import { Box, Button, Typography, styled } from '@mui/material';

export const MonthChangeButton = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '10%',
  height: '100%',
  justifyContent: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  // transition: 'width 0.5s linear',
}));

export const DayChangeContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '50px',
  display: 'none',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down(600)]: {
    display: 'flex',
  },
}));

export const MonthDisplayContainer = styled(Box)(() => ({
  width: '80%',
  backgroundColor: 'white',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'bold',
  // transition: 'width 0.5s linear',
}));

export const MonthButton = styled(Button)<{ selected?: boolean }>(({ theme, selected }) => ({
  width: '33%',
  textTransform: 'none',
  color: theme.palette.secondary.dark,
  fontWeight: 'bold',
  backgroundColor: selected ? theme.palette.primary.dark : 'transparent',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: selected ? 'flex' : 'none',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
}));

export const MonthButtonChangesText = styled(Typography)(({ theme }) => ({
  display: 'none',
  color: theme.palette.secondary.dark,
  fontSize: '12px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4px',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
  },
}));

export const MonthChangeContainer = styled(Box)<{ hasMessage?: boolean }>(({ theme, hasMessage }) => ({
  width: '100%',
  margin: '0px auto',
  height: '8%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '8px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  [theme.breakpoints.down(600)]: {
    height: hasMessage ? '80px' : '60px',
  },
}));
