import { IAvailabilityDay, ICompanyAvailabilityIntervals, IEmployeeAvailabilityWeek } from 'types/availabilities';
import { AVAILABILITIES__UNAVAILABLE, AVAILABILITIES__NO_AVAILABILITIES } from 'translations/constants';
import { IDayProps } from 'modules/contracts/types';

export const getAvailabilityIntervals = (
  day: IAvailabilityDay,
  companyIntervals: ICompanyAvailabilityIntervals,
): string[] => {
  const intervals: string[] = [];
  if (day.isAvailableMorning && !day.isAvailableAfternoon && day.isAvailableEvening) {
    intervals.push(companyIntervals?.morningInterval, companyIntervals?.eveningInterval);
    return intervals;
  }
  if (day.isAvailableMorning) {
    let workingInterval = companyIntervals?.morningInterval.split(' ')[0];
    if (day.isAvailableEvening) {
      workingInterval = `${workingInterval} - ${companyIntervals?.eveningInterval.split(' ')[2]}`;
    } else if (day.isAvailableAfternoon) {
      workingInterval = `${workingInterval} - ${companyIntervals?.afternoonInterval.split(' ')[2]}`;
    } else {
      workingInterval = `${workingInterval} - ${companyIntervals?.morningInterval.split(' ')[2]}`;
    }
    intervals.push(workingInterval);
    return intervals;
  }
  if (day.isAvailableAfternoon) {
    let workingInterval = companyIntervals?.afternoonInterval.split(' ')[0];
    if (day.isAvailableEvening) {
      workingInterval = `${workingInterval} - ${companyIntervals?.eveningInterval.split(' ')[2]}`;
    } else {
      workingInterval = `${workingInterval} - ${companyIntervals?.afternoonInterval.split(' ')[2]}`;
    }
    intervals.push(workingInterval);
    return intervals;
  }
  if (day.isAvailableEvening) {
    intervals.push(companyIntervals?.eveningInterval);
    return intervals;
  }
  intervals.push(day.isSubmitted ? AVAILABILITIES__UNAVAILABLE : AVAILABILITIES__NO_AVAILABILITIES);
  return intervals;
};

const getAvailabilityCountForDay = (employees: IEmployeeAvailabilityWeek[], dayIndex: number): number => {
  return employees.reduce((acc, employee) => {
    const dayAvailability = employee.days[dayIndex];
    if (
      dayAvailability.isAvailableMorning ||
      dayAvailability.isAvailableAfternoon ||
      dayAvailability.isAvailableEvening
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);
};

export const getAvailabilityCountForWeek = (employees: IEmployeeAvailabilityWeek[], days: IDayProps[]): number[] => {
  const availabilityCountByDay: number[] = [];
  if (days.length > 0 && employees.length > 0) {
    days.forEach((_, index) => {
      const availableThisDayCount = getAvailabilityCountForDay(employees, index);
      availabilityCountByDay.push(availableThisDayCount);
    });
  }
  return availabilityCountByDay;
};
