import { Box, styled } from '@mui/material';

export const RegularCellContainer = styled(Box)(({ theme }) => ({
  width: '12%',
  padding: `${theme.spacing(1.25)} ${theme.spacing(1.5)}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderLeft: `2px solid ${theme.palette.primary.dark}`,
  position: 'relative',
}));

export const RegularCellBody = styled(Box)<{ isHovered: boolean }>(({ isHovered }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  opacity: isHovered ? 0.2 : 1,
}));

export const AvailabilityCellOptions = styled(Box)(({ theme }) => ({
  background: 'rgba(219, 237, 227, 0.7)',
  position: 'absolute',
  top: 0,
  left: 0,
  width: `calc(100% - ${theme.spacing(2)})`,
  height: `calc(100% - ${theme.spacing(2)})`,
  margin: `${theme.spacing(1)} 0px 0px ${theme.spacing(1)}`,
  borderRadius: '8px',
  zIndex: 1,
}));

export const AvailabilityCellActions = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

export const AvailabilityBarContainer = styled(Box)(() => ({
  width: '100%',
  height: '25px',
  flexShrink: 0,
}));

export const AvailabilityBarBody = styled(Box)<{ isAvailable: boolean; isNotSubmitted: boolean; hasContract: boolean }>(
  ({ theme, isAvailable, isNotSubmitted, hasContract }) => ({
    width: '100%',
    height: '100%',
    border: (() => {
      if (hasContract && isNotSubmitted) {
        return 'none';
      }
      return `2px solid ${isAvailable ? theme.palette.primary.main : theme.palette.secondary.main}`;
    })(),
    borderRadius: '4px',
    display: isNotSubmitted && !hasContract ? 'none' : 'flex',
    flexShrink: 0,
    position: 'relative',
  }),
);

export const HasContractDot = styled(Box)(() => ({
  position: 'absolute',
  top: 'calc(50% - 8px)',
  left: 'calc(50% - 10px)',
  width: '20px',
  height: '16px',
  borderRadius: '40%',
  backgroundColor: 'orange',
  color: 'white',
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const AvailabilityBarSegment = styled(Box)<{ isAvailable: boolean }>(({ theme, isAvailable }) => ({
  width: '33.34%',
  height: '100%',
  backgroundColor: isAvailable && theme.palette.primary.main,
}));

export const RegularCellTextContainer = styled(Box)<{ isAvailable?: boolean }>(({ theme, isAvailable }) => ({
  fontWeight: 'bold',
  color: isAvailable ? theme.palette.secondary.contrastText : theme.palette.secondary.main,
  height: '20px',
  flexShrink: 0,
}));
