/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { UPDropdownItem } from 'components/dropdown';
import CustomSearchBox from 'components/app-container/custom-search-box/CustomSearchBox';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { impersonateAction } from 'store/actions/auth-actions';
import { getLocationsDropdownItemsAction, getLocationsNamesAction } from 'store/actions/company-locations-actions';
import { deleteManagersAction, getManagersAction } from 'store/actions/company-managers-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { managersSelector } from 'store/selectors/company-managers-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  COMPANY_MANAGERS__ADD_NEW_MANAGER,
  COMPANY_MANAGERS__EDIT_MODAL_TITLE,
  COMPANY_MANAGERS__LOCATIONS,
  COMPANY_MANAGERS__ROLE,
  COMPANY_MANAGERS__VIEW_DETAILS,
  GENERAL__DELETE_MESSAGE,
  GENERAL__DELETE_SELECTED,
  GENERAL__EDIT,
  GENERAL__EMAIL,
  GENERAL__FIRST_NAME,
  GENERAL__IMPERSONATE,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__PHONE_NUMBER,
} from 'translations/constants';
import ICompanyManager from 'types/company/managers/ICompanyManager';
import { escapeRegExp, getUserRolesWithTranslation } from 'utils/helpers';
import { PayrollAddNewItemButton } from '../payroll/styles';
import { SectionContainer } from '../styles';
import EditManagers from './EditManagers';

const Managers = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const userRole = useAppSelector(profileSelector).role;
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const managers = useAppSelector(managersSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [openAddEditLocation, setOpenAddEditLocation] = useState<boolean>(false);
  const [isAddNewButtonClicked, setIsAddNewButtonClicked] = useState<boolean>(false);
  const [rows, setRows] = useState<ICompanyManager[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const isAdmin = userRole === CompanyRole.ADMIN;
  const locations = useMemo(() => locationsDropdownItems, [locationsDropdownItems]);
  const canEdit = isAdmin || userRole?.toLowerCase() === CompanyRole.COMPANY_MANAGER.toLowerCase();
  const roles = getUserRolesWithTranslation();

  useEffect(() => {
    dispatch(getManagersAction(toNumber(companyId)));
    dispatch(getLocationsNamesAction({ companyId: toNumber(companyId) }));
    dispatch(getLocationsDropdownItemsAction({ companyId: toNumber(companyId) }));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (managers) {
      setRows(managers);
    }
  }, [managers]);

  const onOpenAddEditModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenAddEditLocation(true);
  }, []);

  const onClickImpersonate = useCallback(
    userId => {
      dispatch(impersonateAction({ userId, companyId: toNumber(companyId) }));
    },
    [companyId, dispatch],
  );

  const onClickPreventDefault = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const onCloseAddEditModal = useCallback(() => {
    setOpenAddEditLocation(false);
    setIsAddNewButtonClicked(false);
  }, []);

  const getManagerOnEdit = useCallback(() => {
    return managers.find(manager => manager.id === rowOnHover);
  }, [managers, rowOnHover]);

  const onOpenDeleteModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeleteModal(true);
  }, []);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  const getManagerLocations = useCallback(
    managerLocations => {
      if (locations) {
        const managerLocationsNames = locations
          .filter(l => managerLocations.includes(l.value.toString()))
          .map(l => l.label)
          .join(', ');
        return managerLocationsNames;
      }
      return '';
    },
    [locations],
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'firstName', headerName: t(GENERAL__FIRST_NAME), width: 200, filterable: true },
    { field: 'lastName', headerName: t(GENERAL__LAST_NAME), width: 200, filterable: true },
    { field: 'email', headerName: t(GENERAL__EMAIL), width: 300, filterable: true },
    { field: 'language', headerName: t(GENERAL__LANGUAGE), width: 200, hide: true },
    {
      field: 'roleId',
      headerName: t(COMPANY_MANAGERS__ROLE),
      width: 200,
      valueGetter: params => {
        if (params.row.roleId !== null) {
          const index = toNumber(params.row.roleId) - 1;
          return roles[index]?.label;
        }
        return '';
      },
    },
    {
      field: 'locationName',
      headerName: t(COMPANY_MANAGERS__LOCATIONS),
      width: 200,
      renderCell: params => getManagerLocations(params.row.locations),
    },
    { field: 'phoneNumber', headerName: t(GENERAL__PHONE_NUMBER), width: 200 },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 315,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <Box display="flex">
              <Box marginRight="15px">
                <UPButton onClick={onOpenAddEditModal} text={canEdit ? t(GENERAL__EDIT) : 'View'} outlined />
              </Box>
              {isAdmin && !params.row.isInvitation && (
                <Box>
                  <UPButton
                    onClick={event => {
                      onClickImpersonate(params.id);
                      onClickPreventDefault(event);
                    }}
                    text={t(GENERAL__IMPERSONATE)}
                    outlined
                  />
                </Box>
              )}
            </Box>
          );
        }
        return null;
      },
    },
  ];

  const getRowClassName = (params: GridRowParams) => {
    if (params.row.isInvitation === true) {
      return 'MuiDataGrid-custom-background-row';
    }

    return '';
  };

  const handleGridSearch = (searchTerm: string) => {
    if (searchTerm) {
      const filteredRows = managers.filter((row: ICompanyManager) => {
        return (
          row.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
          roles[toNumber(row.roleId) - 1]?.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
          getManagerLocations(row.locations).toLowerCase().includes(searchTerm.toLowerCase())
        );
      });

      setRows(filteredRows);
    } else {
      setRows(managers);
    }
  };

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(COMPANY_MANAGERS__ADD_NEW_MANAGER)}`}
          onClick={event => {
            setRowOnHover(null);
            setIsAddNewButtonClicked(true);
            onOpenAddEditModal(event);
          }}
        />
        {isAdmin && (
          <Box sx={{ marginLeft: '15px' }}>
            <PayrollAddNewItemButton
              sx={{ height: '49px' }}
              text={t(GENERAL__DELETE_SELECTED)}
              onClick={onOpenDeleteModal}
              outlined
              disabled={!(selectedRows.length > 0)}
            />
          </Box>
        )}
        <Box
          sx={{
            m: '16px 0px 0px 16px',
            width: '300px',
            height: '90%',
          }}
        >
          <CustomSearchBox onSearch={handleGridSearch} />
        </Box>
      </Box>
      <Box sx={{ height: '70vh', width: '100%' }}>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          onSelectionModelChange={onSelectionChange}
          withCheckboxes={isAdmin}
          getRowClassName={getRowClassName}
        />
        {openAddEditLocation && (
          <EditManagers
            open={openAddEditLocation}
            onClose={onCloseAddEditModal}
            manager={getManagerOnEdit()}
            title={
              isAddNewButtonClicked
                ? t(COMPANY_MANAGERS__ADD_NEW_MANAGER)
                : canEdit
                ? t(COMPANY_MANAGERS__EDIT_MODAL_TITLE)
                : t(COMPANY_MANAGERS__VIEW_DETAILS)
            }
            isEdit={!isAddNewButtonClicked}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            content={t(GENERAL__DELETE_MESSAGE)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => {
              dispatch(deleteManagersAction({ companyId, managersIds: selectedRows }));
            }}
          />
        )}
      </Box>
    </SectionContainer>
  );
};

export default Managers;
