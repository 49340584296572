import { GridCellValue, GridComparatorFn, GridSortCellParams } from '@mui/x-data-grid';
import { UPDropdownItem } from 'components/dropdown';
import { toInteger, toNumber, trim } from 'lodash';
import moment from 'moment';
import IPayrollCostCenter from 'types/company/payroll/IPayrollCostCenter';
import IWeekDays from 'types/contract/IWeekDays';
import IEmployeeWithWorkerclass from 'types/employee/IEmployeeWithWorkerclass';
import { addOrSubtractDays, getFirstDayOfWeek } from 'utils/helpers';
import { IContractData } from './types';

export const getCurrentTime = () => {
  const minutes30 = 30 * 60 * 1000;
  const dateNow = new Date(Date.now() + minutes30);
  return {
    date: new Date(Date.now()),
    hours: dateNow.getHours(),
    minutes: dateNow.getMinutes(),
  };
};

export const getCurrentWeek = (date: Date) => {
  const currentDate = date;
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const daysBetween = Math.floor((currentDate.getTime() - firstDayOfYear.getTime()) / (24 * 60 * 60 * 1000));

  const firstDayOfYearWeekDay = firstDayOfYear.getDay(); // Day of the week for the first day of the year (0 = Sunday, 1 = Monday, ...)
  let weekNumber = Math.floor((daysBetween + firstDayOfYearWeekDay + 6) / 7);

  // Adjust week number for cases where the first week of the year has fewer than 4 days
  const firstWeekDays = 7 - firstDayOfYearWeekDay;
  if (firstWeekDays < 4) {
    weekNumber -= 1;
  }

  return weekNumber;
};

export const hoursToMinutes = (time: string) => {
  const startTimeA = time.split(':');
  return toNumber(startTimeA[0]) * 60 + toNumber(startTimeA[1]);
};

export const compareTwoValues = (a: number, b: number) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const sortedContracts = (cards: IContractData[]) => {
  cards.sort((a, b) => {
    let valueToReturn = 0;
    const contractStartMinutesA = hoursToMinutes(a.startHour);
    const contractStarMinutesB = hoursToMinutes(b.startHour);
    valueToReturn = compareTwoValues(contractStartMinutesA, contractStarMinutesB);
    if (!valueToReturn) {
      const contractEndMinutesA = hoursToMinutes(a.endHour);
      const contractEndMinutesB = hoursToMinutes(b.endHour);
      valueToReturn = compareTwoValues(contractEndMinutesA, contractEndMinutesB);
    }
    return valueToReturn;
  });
  return cards;
};

export const isContractEditable = (c: IContractData, dateReceived: Date) => {
  const currentTime: { date: Date; hours: number; minutes: number } = getCurrentTime();
  let editable = true;
  const date = new Date(dateReceived);
  if (
    (date.getMonth() < currentTime.date.getMonth() && date.getFullYear() === currentTime.date.getFullYear()) ||
    date.getFullYear() < currentTime.date.getFullYear()
  ) {
    editable = false;
  }
  if (date.getMonth() === currentTime.date.getMonth()) {
    if (date.getDate() < currentTime.date.getDate()) {
      editable = false;
    }
    if (date.getDate() === currentTime.date.getDate()) {
      const contractMinutes = hoursToMinutes(c.startHour);
      const currentMinutes = currentTime.hours * 60 + currentTime.minutes;
      if (contractMinutes < currentMinutes) {
        editable = false;
      }
    }
  }
  return editable;
};

const generateDaysArray = (cards: IContractData[]) => {
  const daysArray = cards.map((c: IContractData) => {
    return {
      id: c.id,
      date: c.date,
      startHour: c.startHour,
      endHour: c.endHour,
      startBreak: c.startBreak,
      endBreak: c.endBreak,
    };
  });
  return daysArray;
};

export const generateCardsObject = (parentId: string, allContracts: IContractData[], modifiedCard?: any) => {
  const sameParentCards = allContracts.filter((c: IContractData) => c.weeklyId && c.weeklyId === parentId);

  const days: { id: number; date: Date; startHour: string; endHour: string; startBreak: string; endBreak: string }[] =
    generateDaysArray(sameParentCards);
  const card = modifiedCard || sameParentCards[0];
  const contractObject = {
    contractId: card.contractId,
    employee: card?.employee,
    error: false,
    contractType: card?.contractType,
    functionId: card?.functionId,
    costCenterId: card?.costCenterId,
    locationId: card?.locationId,
    workingLocation: card?.workingLocation,
    companyId: card?.companyId,
    dates: days,
    errorMessage: card.errorMessage ? card.errorMessage : null,
  };
  return contractObject;
};

export const generateRowsWithDays = (contractObj: any) => {
  return contractObj.dates;
};

export const getWeekDaysByWeekNumber = (
  weeknumber: number,
  weekDaysAbrv: string[],
  weekForYear: number | Date,
  prefilledDates?: number[],
) => {
  const date = moment(weekForYear)
    .isoWeek(weeknumber || 1)
    .startOf('week');
  let weeklength = 7;
  let auxIndex = 0;

  const result: IWeekDays[] = [];
  while (weeklength--) {
    result.push({
      day: weekDaysAbrv[auxIndex],
      date: date.format('DD MMM').toLocaleLowerCase(),
      date2: date.format('DD.MM'),
      date3: date.format('DD/MM/YYYY'),
      checked: prefilledDates ? prefilledDates.includes(date.date()) : false,
      nativeDate: new Date(date.toISOString()),
    });
    ++auxIndex;
    date.add(1, 'day');
  }
  return result;
};

export const generateFormattedContractToAddOnCopy = (
  data: IContractData,
  date: Date,
  wage: string,
  currentWeek: number,
) => {
  const newContract = {
    companyId: data.companyId.toString(),
    week: currentWeek,
    year: new Date(date).getFullYear(),
    workingLocation: data.workingLocation,
    wage,
    contract: {
      id: 0,
      companyId: '',
      employeeId: data.vendor ? '0' : data.employee.id.toString(),
      vendorId: data.vendor ? data.vendor.id.toString() : '0',
      contractType: data.contractType,
      functionId: data.functionId.toString(),
      costCenterId: data.costCenterId.toString(),
      locationId: data.locationId.toString(),
    },
    weekDaysRows: [
      {
        days: [
          {
            day: '',
            date: '',
            date2: '',
            date3: moment(new Date(date)).format('DD/MM/YYYY'),
            checked: true,
            nativeDate: data.date,
          },
        ],
        hours: {
          startWork: data.startHour,
          startBreak: data.startBreak,
          endBreak: data.endBreak,
          endWork: data.endHour,
        },
      },
    ],
  };
  return newContract;
};

export const generateWeeklyContractToCopy = (data: any[], weekToCopy: number, wage: string) => {
  const weekRows = [];
  data.forEach((c: any) => {
    const dayOfContract = moment(c.date);
    const weekOfContract = dayOfContract.week();
    const daysToAdd = 7 * (weekToCopy - weekOfContract);
    const newContractDate = dayOfContract.add(daysToAdd, 'days');
    weekRows.push({
      days: [
        {
          date3: newContractDate.format('DD/MM/YYYY'),
          checked: true,
          nativeDate: c.date,
        },
      ],
      hours: {
        startWork: c.startHour,
        startBreak: c.startBreak,
        endBreak: c.endBreak,
        endWork: c.endHour,
      },
    });
  });

  const newContract = {
    companyId: data[0].companyId,
    week: weekToCopy,
    year: moment().year(),
    workingLocation: data[0].workingLocation,
    wage,
    contract: {
      companyId: '',
      employeeId: data[0].employee.id,
      contractType: data[0].contractType,
      functionId: data[0].functionId,
      costCenterId: data[0].costCenterId,
      locationId: data[0].locationId,
    },
    weekDaysRows: weekRows,
  };
  return newContract;
};

export const isAnyContractWeekly = (data: IContractData[]) => {
  const found = data.find((c: IContractData) => c.weeklyId);
  if (found) return true;
  return false;
};

export const generateEmployeeFunction = (functionName: string) => {
  const auxArray = functionName.split('');
  if (auxArray.length > 13) {
    auxArray.splice(11, auxArray.length);
    auxArray.push('.', '.', '.');
  }

  const auxFinal = auxArray.join('');
  return auxFinal;
};

export const generateFormatedDate = (weekDaysNames: string[], contractDate: Date) => {
  const date = moment(contractDate);

  const findRightTranslation = (dayAbr: string) => {
    switch (dayAbr) {
      case 'Mon':
        return weekDaysNames[0];
      case 'Tue':
        return weekDaysNames[1];
      case 'Wed':
        return weekDaysNames[2];
      case 'Thu':
        return weekDaysNames[3];
      case 'Fri':
        return weekDaysNames[4];
      case 'Sat':
        return weekDaysNames[5];
      default:
        return weekDaysNames[6];
    }
  };

  return `${findRightTranslation(date.format('ddd'))}  ${date.format('DD.MM')} `;
};

export const generateCurrentWeekDaysList = (currentWeek: number, weekDaysNames: string[]) => {
  const firstDayOfWeek = getFirstDayOfWeek(currentWeek);
  const week: { value: Date }[] = [
    {
      value: firstDayOfWeek,
    },
  ];
  let counter = 1;
  while (counter <= 6) {
    week.push({
      value: addOrSubtractDays(counter, firstDayOfWeek),
    });
    ++counter;
  }
  const auxWeek = week.map((weekDay: { value: Date }) => {
    return {
      value: moment(new Date(weekDay.value)).format('MM/DD/YYYY'),
      label: generateFormatedDate(weekDaysNames, weekDay.value),
    };
  });
  return auxWeek;
};

export const generateAgeFilterOptions = (): UPDropdownItem[] => {
  const options: UPDropdownItem[] = [];
  options.push({ label: '-18', value: '0-18' });
  options.push({ label: '18-20', value: '18-20' });
  options.push({ label: '21-24', value: '21-24' });
  options.push({ label: '25-34', value: '25-34' });
  options.push({ label: '35-44', value: '35-44' });
  options.push({ label: '45-54', value: '45-54' });
  options.push({ label: '55-64', value: '55-64' });
  options.push({ label: '65+', value: '65-100' });
  return options;
};

export const generateTotalHours = (start: string, end: string, startBreak: string, endBreak: string) => {
  const auxStart = start.split(':');
  const auxEnd = end.split(':');
  const startMinutes = toNumber(auxStart[0]) * 60 + toNumber(auxStart[1]);
  const endMinutes = toNumber(auxEnd[0]) * 60 + toNumber(auxEnd[1]);
  let dif = endMinutes - startMinutes;

  if (startBreak !== '' && endBreak !== '') {
    const auxStartBreak = startBreak.split(':');
    const auxEndBreak = endBreak.split(':');
    const startBreakMinutes = toNumber(auxStartBreak[0]) * 60 + toNumber(auxStartBreak[1]);
    const endBreakMinutes = toNumber(auxEndBreak[0]) * 60 + toNumber(auxEndBreak[1]);
    dif -= endBreakMinutes - startBreakMinutes;
  }

  if (dif < 0) dif += 24 * 60;
  const objToReturn = {
    value: dif % 60 ? `${toInteger(dif / 60)}h ${dif % 60}m` : `${toInteger(dif / 60)}h`,
    hours: toInteger(dif / 60),
    minutes: dif % 60,
  };
  return objToReturn;
};

export const generateCostCenterName = (id: number, costCentersList: UPDropdownItem[]) => {
  const found = costCentersList.find((c: UPDropdownItem) => c.value === id);
  return found ? found.label : '';
};

export const filterCostCentersByLocation = (
  costCentersList: IPayrollCostCenter[],
  locationId: number,
): UPDropdownItem[] => {
  return costCentersList
    .filter((c: IPayrollCostCenter) => toNumber(c.locationId) === locationId)
    .map((c: IPayrollCostCenter) => ({
      label: c.name,
      value: c.id,
    }));
};

const generateContractsWithDetails = (contracts: IContractData[], weekDaysNames: string[]) => {
  const auxContracts = contracts.map((c: IContractData) => {
    const timeValues = generateTotalHours(c.startHour, c.endHour, c.startBreak, c.endBreak);
    return {
      ...c,
      employeeFullName: `${c.employee.firstName} ${c.employee.lastName}`,
      employeeAvatar: c.employee.profilePicture,
      formatedDate: generateFormatedDate(weekDaysNames, c.date),
      totalHours: timeValues.value,
      hours: timeValues.hours,
      minutes: timeValues.minutes,
    };
  });
  return auxContracts;
};

export const generateContractsSortedByType = (contracts: IContractData[], weekDaysNames: string[]) => {
  const auxContracts = {
    futureContracts: [],
    toBeConfirmedContracts: [],
    archivedContracts: [],
  };
  contracts.forEach((c: IContractData) => {
    if (c.editable) auxContracts.futureContracts.push(c);
    else if (c.confirmHours) auxContracts.archivedContracts.push(c);
    else auxContracts.toBeConfirmedContracts.push(c);
  });

  auxContracts.futureContracts = generateContractsWithDetails(auxContracts.futureContracts, weekDaysNames);
  auxContracts.toBeConfirmedContracts = generateContractsWithDetails(
    auxContracts.toBeConfirmedContracts,
    weekDaysNames,
  );
  auxContracts.archivedContracts = generateContractsWithDetails(auxContracts.archivedContracts, weekDaysNames);
  return auxContracts;
};

export const generateCompanyEmployeesList = (employees: IEmployeeWithWorkerclass[]) => {
  const employeesNames = employees.map((item: IEmployeeWithWorkerclass) => ({
    value: item.id.toString(),
    label: `${item.firstName} ${item.lastName}`,
  }));
  return employeesNames;
};

export const calculateTotalTime = (contracts: any) => {
  let hours = 0;
  let minutes = 0;

  contracts.forEach((c: any) => {
    const totals = generateTotalHours(c.startHour, c.endHour, c.startBreak, c.endBreak);
    hours += totals.hours;
    minutes += totals.minutes;
  });

  // Adjust the total hours if the total minutes exceed 60
  hours += Math.floor(minutes / 60);
  minutes %= 60;

  return minutes ? `${hours}h ${minutes}m` : `${hours}h`;
};

export const generateListViewContractChecked = (contracts: any, ids: number[]) => {
  const auxContracts = [];
  contracts.forEach((c: any) => {
    ids.forEach((id: number) => {
      if (id === c.id) auxContracts.push(c);
    });
  });
  return auxContracts;
};

export const generateErrorsObject = (err: any, id: number, value: boolean, message: string) => {
  const auxErr = {
    ...err,
    [id]: {
      value,
      message,
    },
  };
  return auxErr;
};

export const checkIfThereIsAnyError = (contracts: any, errors: any) => {
  let err = false;
  if (errors.length === 0) err = false;
  else {
    contracts.forEach((c: any) => {
      if (errors[c.id]?.value) err = true;
    });
  }

  return err;
};

export const generateContractDate = (contract: any) => {
  return {
    confirmHours: {
      contractDateId: contract.id,
      day: contract.date,
      startWork: contract.startHour,
      startBreak: contract.startBreak,
      endBreak: contract.endBreak,
      endWork: contract.endHour,
      noShow: contract.noShow,
      costDeclarations: contract.costDeclarations,
      reasonForNoShow: contract.reasonForNoShow,
      costCenterId: contract.costCenterId,
      locationId: 0, // we don't send it back
    },
    employeeId: contract.employee.id,
    companyId: contract.companyId,
    functionId: contract.functionId,
  };
};

export const generateContractDateForMultiple = (contract: any) => {
  return {
    contractHourConfirmation: {
      contractDateId: contract.id,
      day: contract.date,
      startWork: contract.startHour,
      startBreak: contract.startBreak,
      endBreak: contract.endBreak,
      endWork: contract.endHour,
      noShow: contract.noShow,
      reasonForNoShow: contract.reasonForNoShow,
      costCenterId: contract.costCenterId,
    },
    employeeId: contract.employee.id,
    companyId: contract.companyId,
    functionId: contract.functionId,
  };
};

export const generateToBeConfirmedContracts = (contracts: IContractData[]) => {
  const auxContracts = contracts.map((c: IContractData) => {
    return {
      ...c,
      noShow: false,
      reasonForNoShow: '',
    };
  });
  return auxContracts;
};

export const compareDates = (d1: Date, d2: Date): number => {
  let val = 0;

  if (d1 > d2) {
    val = 1;
  }

  if (d1 < d2) {
    val = -1;
  }

  return val;
};

export const CompareContractDateSorting = (v1: GridSortCellParams, v2: GridSortCellParams): number => {
  const d1 = v1.api.getRow(v1.id).date;
  const d2 = v2.api.getRow(v2.id).date;
  return compareDates(d1, d2);
};

export const EmployeeNameComparator = (
  v1: GridCellValue,
  v2: GridCellValue,
  param1: GridSortCellParams,
  param2: GridSortCellParams,
): number => {
  const employeeName1 = `${param1.api.getRow(param1.id).employee.firstName} ${
    param1.api.getRow(param1.id).employee.lastName
  }`;
  const employeeName2 = `${param2.api.getRow(param2.id).employee.firstName} ${
    param2.api.getRow(param2.id).employee.lastName
  }`;

  const date1 = param1.api.getRow(param1.id).date;
  const date2 = param2.api.getRow(param2.id).date;
  const compareEmployee = employeeName1.toString().localeCompare(employeeName2.toString());

  if (compareEmployee === 0) {
    const d1 = new Date(date1 as string);
    const d2 = new Date(date2 as string);

    if (d1 < d2) {
      return -1;
    }
    if (d1 > d2) {
      return 1;
    }

    return 0;
  }

  return compareEmployee;
};

export const filterByHour = (options: UPDropdownItem[], state: { inputValue: string }) => {
  return options.filter(option => option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()));
};

const parseTimeLength = (time: string) => {
  const timeLength = time.split(' ');
  const hours = timeLength[0].replace('h', '');
  const minutes = timeLength[1] ? timeLength[1].replace('m', '') : '0';
  return toNumber(hours) * 60 + toNumber(minutes);
};

export const totalHoursComparator: GridComparatorFn = (v1, v2) => {
  const totalMinutes1 = parseTimeLength(v1.toString());
  const totalMinutes2 = parseTimeLength(v2.toString());
  debugger; // eslint-disable-line no-debugger
  return v1.toString().localeCompare(v2.toString());
};

export const compareTimes = (time1: string, time2: string): number => {
  const [hours1, minutes1] = time1.split(':').map(Number);
  const [hours2, minutes2] = time2.split(':').map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;

  if (totalMinutes1 < totalMinutes2) {
    return -1; // time1 is earlier
  }

  return totalMinutes1 > totalMinutes2 ? 1 : 0; // time2 is earlier
};

export const getFormattedTime = (date: Date): string => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};
