import { Typography, styled, Box, Divider, Link, ListItemButton } from '@mui/material';
import UPButton from 'components/button';
import UPInput from 'components/input';
import Background from '../../assets/icons/login/LoginBackground.svg';
import BackgroundMobile from '../../assets/icons/login/LoginBackgroundMobile.svg';

export const AuthContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '80vh',
  [theme.breakpoints.up(2000)]: {
    minHeight: '83vh',
  },
  [theme.breakpoints.up(2500)]: {
    minHeight: '85vh',
  },
}));

export const AuthBackground = styled('img')(() => ({
  width: '100%',
}));

export const AuthTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  top: '15%',
  fontSize: '40px',
  fontWeight: 600,
  maxWidth: '400px',
  left: '40px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '30px',
  },
  [theme.breakpoints.down(1095)]: {
    display: 'none',
  },
}));

export const AuthLeftContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100vh',
  background: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  zIndex: '-1',
  maxWidth: '70%',
  bottom: 0,
  left: 0,
  [theme.breakpoints.down(1095)]: {
    maxWidth: '90%',
    background: `url(${BackgroundMobile})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'bottom',
    backgroundPositionX: '0',
  },
}));

export const AuthRightContainer = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  marginTop: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '45%',
  [theme.breakpoints.down('lg')]: {
    width: '40%',
    marginTop: '130px',
  },
  [theme.breakpoints.down(1095)]: {
    width: '100%',
  },
}));

export const AuthSignInContainer = styled(Box)(({ theme }) => ({
  padding: '0 10px',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: '340px',
  minWidth: '250px',
  width: '100%',
  [theme.breakpoints.down(1095)]: {
    alignItems: 'center',
  },
}));

export const AuthSignIn = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: '40px',
  fontWeight: 600,
  [theme.breakpoints.down(1095)]: {
    fontSize: '32px',
  },
}));

export const AuthSignInExpired = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: '30px',
  fontWeight: 600,
  [theme.breakpoints.down(1095)]: {
    fontSize: '22px',
  },
}));

export const AuthUPInput = styled(UPInput)(({ theme }) => ({
  marginTop: '32px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '16px',
  },
}));

export const AuthUPButton = styled(UPButton)(({ theme }) => ({
  marginTop: '32px',
  marginBottom: '12px',
  [theme.breakpoints.down('sm')]: {
    marginTop: '16px',
  },
}));

export const AuthCreateAccountTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  fontSize: '22px',
  fontWeight: 600,
  marginTop: '32px',
  [theme.breakpoints.down(1095)]: {
    fontSize: '18px',
    marginTop: '16px',
  },
}));

export const AuthFooterContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '84px',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    height: '60px',
  },
  [theme.breakpoints.down('xs')]: {
    position: 'absolute',
    bottom: '0',
  },
}));

export const AuthFooterLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.secondary.contrastText,

  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export const AuthFooterDivider = styled(Divider)(() => ({
  width: '90%',
  height: '1px',
}));

export const AuthFooterLinksContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginTop: '24px',
  [theme.breakpoints.down('md')]: {
    marginTop: '14px',
  },
}));

export const AuthFooterText = styled(Typography, {
  shouldForwardProp: prop => prop !== 'centerText',
})<{ centerText?: boolean }>(({ centerText }) => ({
  fontSize: '12px',
  ...(centerText && { margin: '0px 40px' }),
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  color: theme.palette.error.main,
}));

export const SuccessText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 300,
  color: theme.palette.success.main,
}));

// new styles

export const AuthHeaderBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(4)} 0 0 ${theme.spacing(5)}`,
  [theme.breakpoints.down(600)]: {
    position: 'absolute',
  },
}));

export const MenuItemListButton = styled(ListItemButton)(({ theme }) => ({
  [theme.breakpoints.down(1600)]: {
    padding: '3px 16px 0px',
  },
}));
