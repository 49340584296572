/* eslint-disable react/jsx-props-no-spreading */
import { Box, Avatar } from '@mui/material';
import { UPDataGrid } from 'components/data-grid';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { createDefaultContractFilters } from 'mappers/contracts-mapper';
import { ContractPageSize } from 'constants/ContractPageSize';
import {
  COMPANY_PAYROLL__EMPLOYEE,
  CONTRACTS__ARE_YOU_SURE,
  CONTRACTS__COST_CENTER,
  CONTRACTS__DATE,
  CONTRACTS__DELETE_SELECTED,
  CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING,
  CONTRACTS__EDIT_MORE_CONTRACTS_ERROR,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_WORK,
  CONTRACTS__FUNCTION,
  CONTRACTS__START_BREAK,
  CONTRACTS__START_END_WORK_ERROR,
  CONTRACTS__START_WORK,
  GENERAL__TOTAL,
} from 'translations/constants';
import BottomGreenBar from 'components/bottom-green-bar';
import DeleteModal from 'components/delete-modal';
import { isEqual, toNumber } from 'lodash';
import DataGridEditInputCell from 'modules/companies/payroll/wage-exemptions/DataGridEditInputCell';
import { UPDropdownItem } from 'components/dropdown';
import { UPButtonStyledWithNoPadding } from 'components/button/styles';
import Tooltip from '@mui/material/Tooltip';
import { ContractListViewTypes } from 'constants/ContractListViewTypes';
import {
  editContractAction,
  getContractsInListViewAction,
  validateContractEditAction,
  validateContractSuccessAction,
} from 'store/actions/contract-actions';
import { useAppDispatch, useAppSelector } from 'store';
import { validateContractSuccessSelector } from 'store/selectors/contract-selectors';
import {
  getContractsListViewSelector,
  validateEditContractErrorMessageSelector,
} from 'store/selectors/contracts-selector';
import { costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import { getWeekDaysAbbreviations } from 'utils/helpers';
import {
  calculateTotalTime,
  generateCardsObject,
  generateCostCenterName,
  generateListViewContractChecked,
  generateErrorsObject,
  isAnyContractWeekly,
  CompareContractDateSorting,
  filterCostCentersByLocation,
  totalHoursComparator,
  EmployeeNameComparator,
  generateFormatedDate,
  generateTotalHours,
} from '../helpers';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/DeleteFull.svg';
import { IContractData, IContractsListViewProps, IContractToEdit } from '../types';
import { ReactComponent as EditEmployeeAvatar } from '../../../assets/icons/EditEmployeeAvatar.svg';
import { ReactComponent as DeleteIconGreen } from '../../../assets/icons/DeleteGreen.svg';
import { ReactComponent as EditIconGreen } from '../../../assets/icons/EditGreen.svg';
import { ReactComponent as EditIconGrey } from '../../../assets/icons/EditGrey.svg';
import { ReactComponent as CancelIcon } from '../../../assets/icons/CloseGreen.svg';
import { ReactComponent as SaveGreenIcon } from '../../../assets/icons/SaveGreen.svg';
import { ReactComponent as SaveGreyIcon } from '../../../assets/icons/SaveGrey.svg';
import DataEditCellHours from './DataEditCellHours';
import HOUR_OPTIONS from '../HourDropdownOptions';

const FutureContractsView = (props: IContractsListViewProps): JSX.Element => {
  const {
    makeDeleteRequest,
    costCentersList,
    functionsList,
    currentWeek,
    currentYear,
    setSelectedContractsId,
    companyId,
    filters,
  } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const contractIsValid = useAppSelector(validateContractSuccessSelector);
  const errorMessage = useAppSelector(validateEditContractErrorMessageSelector);
  const companyCostCenters = useAppSelector(costCentersSelector);
  const contractData = useAppSelector(getContractsListViewSelector);
  const [contracts, setContracts] = useState<IContractData[]>(contractData);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [totalTime, setTotalTime] = useState<string>('');
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [openConfirmDeleteWeeklyModal, setOpenConfirmDeleteWeeklyModal] = useState<boolean>(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<number[]>([]);
  const [editId, setEditId] = useState<number>(null);
  const [errors, setErrors] = useState({});
  const [hasClickedSearch, setHasClickedSearch] = useState(false);
  const [editedContract, setEditedContract] = useState<IContractData>(null);
  const [contractToSendToBE, setContractToSendToBE] = useState<IContractToEdit>(null);
  const weekDaysNames = useMemo(() => getWeekDaysAbbreviations(), []);

  const [pageSize, setPageSize] = useState<number>(ContractPageSize.DefaultListView);

  filters.listViewType = ContractListViewTypes.Future;

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const bottomGreenBarActions = useMemo(() => {
    return [
      {
        content: t(CONTRACTS__DELETE_SELECTED),
        icon: <DeleteIcon />,
      },
    ];
  }, [t]);

  const setPage = (newPage: number) => {
    filters.pageNumber = newPage;
    filters.pageSize = pageSize;
    filters.listViewType = ContractListViewTypes.Future;
    dispatch(
      getContractsInListViewAction({
        companyId: toNumber(companyId),
        filter: filters,
      }),
    );
  };

  const onDeleteContracts = (ids: number[]) => {
    const newContracts = contracts.filter((c: IContractData) => ids.includes(c.id));
    if (isAnyContractWeekly(newContracts)) setOpenConfirmDeleteWeeklyModal(true);
    else {
      setOpenConfirmDeleteModal(true);
    }
  };

  const updateNormalField = useCallback(
    (id, field, value) => {
      let auxContract = contracts.find((c: IContractData) => c.id === id);
      if (field === 'functionId') {
        auxContract = {
          ...auxContract,
          [field]: value,
          employee: {
            ...auxContract.employee,
            functionName: generateCostCenterName(value, functionsList),
          },
        };
      } else {
        auxContract = {
          ...auxContract,
          [field]: value,
        };
      }
      const updatedContracts = contracts.map((c: IContractData) => {
        if (c.id === id) return auxContract;
        return c;
      });
      setContracts(updatedContracts);
      setEditedContract(auxContract);
      return auxContract;
    },
    [contracts, functionsList, setContracts],
  );

  const updateHoursField = useCallback(
    (id, field, value) => {
      let auxContract = contracts.find((c: IContractData) => c.id === id);
      if (!value) {
        if (field === 'startHour' || field === 'endHour') {
          setErrors(generateErrorsObject(errors, id, true, t(CONTRACTS__START_END_WORK_ERROR)));
        } else if (auxContract.startHour && auxContract.endHour) {
          setErrors(generateErrorsObject(errors, id, false, ''));
        }
      } else if (field === 'startHour' || field === 'endHour') {
        setErrors(generateErrorsObject(errors, id, false, ''));
      }

      auxContract = {
        ...auxContract,
        [field]: value,
      };

      const updatedContracts = contracts.map((c: IContractData) => {
        if (c.id === id) return auxContract;
        return c;
      });
      setContracts(updatedContracts);
      setEditedContract(auxContract);
      if (auxContract.startHour && auxContract.startBreak && auxContract.endBreak && auxContract.endHour) {
        setErrors(generateErrorsObject(errors, id, false, ''));
        if (errors[id] && errors[id].message !== t(CONTRACTS__START_END_WORK_ERROR)) {
          setErrors(generateErrorsObject(errors, id, false, ''));
        }
      }
      return auxContract;
    },

    [contracts, errors, setContracts, t],
  );

  const onSave = (contract: IContractData, id: number) => {
    let contractToEdit;
    if (contract) {
      contractToEdit = contract;
    } else {
      const found = contracts.find((c: IContractData) => c.id === id);
      contractToEdit = found;
    }
    let contractToSend;
    if (!contractToEdit.weeklyId) {
      const card = contracts.find((c: IContractData) => c.id === id);
      contractToSend = {
        contractId: card.contractId,
        employee: card?.employee,
        error: false,
        contractType: card?.contractType,
        functionId: card?.functionId,
        costCenterId: card?.costCenterId,
        locationId: card?.locationId,
        workingLocation: card?.workingLocation,
        companyId: card?.companyId,
        dates: [
          {
            id: card.id,
            date: card.date,
            startHour: card.startHour,
            endHour: card.endHour,
            startBreak: card.startBreak,
            endBreak: card.endBreak,
          },
        ],
        errorMessage: card.errorMessage ? card.errorMessage : null,
      };
    } else {
      contractToSend = generateCardsObject(contractToEdit.weeklyId, contracts, contractToEdit);
    }

    const auxContract = {
      contractId: contractToSend.contractId,
      employeeId: contractToSend.employee.id,
      companyId: contractToSend.companyId,
      contractType: contractToSend.contractType,
      functionId: contractToSend.functionId,
      costCenterId: contractToSend.costCenterId,
      locationId: contractToSend.locationId,
      workingLocation: contractToSend.workingLocation,
      dates: contractToSend.dates,
    };
    setContractToSendToBE(auxContract);

    if (errors[id]) {
      if (errors[id].message !== t(CONTRACTS__START_END_WORK_ERROR)) {
        setHasClickedSearch(true);
        dispatch(
          validateContractEditAction({
            contract: auxContract,
            companyId: contractToSend.companyId,
          }),
        );
      }
    } else {
      setHasClickedSearch(true);
      dispatch(
        validateContractEditAction({
          contract: auxContract,
          companyId: contractToSend.companyId,
        }),
      );
    }
  };

  useEffect(() => {
    setEditId(null);
  }, [currentWeek]);

  useEffect(() => {
    if (contractData.length > 0) {
      setContracts(contractData);
    } else {
      setContracts([]);
    }
  }, [contractData]);

  useEffect(() => {
    setSelectedContractsId(selectedRows);
  }, [selectedRows, setSelectedContractsId]);

  useEffect(() => {
    if (!hasClickedSearch) return;

    if (!contractIsValid) {
      const auxErr = generateErrorsObject(errors, editId, true, t(errorMessage));
      if (!isEqual(auxErr, errors) && errorMessage) setErrors(auxErr);
    } else {
      const auxErr = generateErrorsObject(errors, editId, false, '');
      if (isEqual(auxErr, errors) === false || (!auxErr[editId].value && !errors[editId].value)) {
        setErrors(auxErr);
        setEditId(null);
        if (contractToSendToBE) {
          dispatch(
            editContractAction({
              companyId: toNumber(contractToSendToBE.companyId),
              contract: contractToSendToBE,
            }),
          ).then(() => {
            const filter = createDefaultContractFilters(currentYear, currentWeek, null, contractToSendToBE.locationId);
            filter.listViewType = ContractListViewTypes.Future;
            dispatch(
              getContractsInListViewAction({
                companyId: toNumber(contractToSendToBE.companyId),
                filter,
              }),
            );
          });
          dispatch(validateContractSuccessAction(false));
          setContractToSendToBE(null);
          setErrors({});
        }
      }
    }
  }, [
    editId,
    errorMessage,
    errors,
    t,
    contractIsValid,
    hasClickedSearch,
    dispatch,
    currentYear,
    currentWeek,
    contractToSendToBE,
  ]);

  useEffect(() => {
    if (selectedRows.length > 0) {
      const auxTotal = calculateTotalTime(generateListViewContractChecked(contracts, selectedRows));
      setTotalTime(auxTotal);
    }
  }, [contracts, selectedRows]);

  const contractsColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'contractId', headerName: '', hide: true },
    { field: 'companyId', headerName: '', hide: true },
    {
      field: 'employeeAvatar',
      headerName: '',
      width: 65,
      sortable: false,
      renderCell: params => {
        if (params.value === null) {
          return <EditEmployeeAvatar />;
        }
        return (
          <div>
            <Avatar src={params.row.employee.profilePicture} />
          </div>
        );
      },
    },
    {
      field: 'employeeFullName',
      headerName: t(COMPANY_PAYROLL__EMPLOYEE),
      width: 200,
      sortable: true,
      renderCell: params => {
        return `${params.row.employee.firstName} ${params.row.employee.lastName}`;
      },
      sortComparator: (v1, v2, param1, param2) => EmployeeNameComparator(v1, v2, param1, param2),
    },
    {
      field: 'date',
      headerName: t(CONTRACTS__DATE),
      width: 90,
      editable: false,
      align: 'center',
      sortable: true,
      renderCell: params => {
        return generateFormatedDate(weekDaysNames, params.row.date);
      },
      sortComparator: (v1, v2, param1, param2) => {
        return CompareContractDateSorting(param1, param2);
      },
    },
    {
      field: 'startHour',
      headerName: t(CONTRACTS__START_WORK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: editId === rowOnHover && editedContract !== null,
      renderCell: params => {
        if (params.id === editId) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="startHour"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }
        return params.row.starHour;
      },
      renderEditCell: params => {
        if (editId === params.id) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="startHour"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }

        return params.row.starHour;
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'startHour', params.value);
        return updatedContract;
      },
    },
    {
      field: 'startBreak',
      headerName: t(CONTRACTS__START_BREAK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: editId === rowOnHover && editedContract !== null,
      renderCell: params => {
        if (params.id === editId) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="startBreak"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }
        return params.row.startBreak;
      },
      renderEditCell: params => {
        if (editId === params.id) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="startBreak"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }

        return params.row.startBreak;
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'startBreak', params.value);
        return updatedContract;
      },
    },
    {
      field: 'endBreak',
      headerName: t(CONTRACTS__END_BREAK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: editId === rowOnHover && editedContract !== null,
      renderCell: params => {
        if (params.id === editId) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="endBreak"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }
        return params.row.endBreak;
      },
      renderEditCell: params => {
        if (editId === params.id) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="endBreak"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }

        return params.row.endBreak;
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'endBreak', params.value);
        return updatedContract;
      },
    },
    {
      field: 'endHour',
      headerName: t(CONTRACTS__END_WORK),
      width: 120,
      sortable: true,
      align: 'center',
      editable: editId === rowOnHover && editedContract !== null,
      renderCell: params => {
        if (params.id === editId) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="endHour"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }
        return params.row.endHour;
      },
      renderEditCell: params => {
        if (editId === params.id) {
          return (
            <DataEditCellHours
              {...params}
              value={params.value}
              dropdownOptions={HOUR_OPTIONS}
              type="endHour"
              errorMessage={errors[params.id]?.value || false}
            />
          );
        }

        return params.row.endHour;
      },
      valueSetter: params => {
        const updatedContract = updateHoursField(params.row.id, 'endHour', params.value);
        return updatedContract;
      },
    },
    {
      field: 'totalHours',
      headerName: t(GENERAL__TOTAL),
      width: 100,
      renderCell: params => {
        const totals = generateTotalHours(
          params.row.startHour,
          params.row.endHour,
          params.row.startBreak,
          params.row.endBreak,
        );
        return totals.value;
      },
      sortComparator: totalHoursComparator,
      align: 'center',
    },
    {
      field: 'functionId',
      headerName: t(CONTRACTS__FUNCTION),
      width: 166,
      sortable: true,
      editable: editId === rowOnHover && editedContract !== null,
      renderCell: params => {
        if (params.id === editId) {
          return <DataGridEditInputCell {...params} dropdownOptions={functionsList} category="contracts" />;
        }
        return generateCostCenterName(params.row.functionId, functionsList);
      },
      renderEditCell: params => {
        if (editId === params.id) {
          const found = costCentersList.find((costCenter: UPDropdownItem) => params.value === costCenter?.value);
          return (
            <DataGridEditInputCell
              {...params}
              value={found?.value}
              dropdownOptions={functionsList}
              category="contracts"
            />
          );
        }

        return generateCostCenterName(params.row.functionId, functionsList);
      },
      valueSetter: params => {
        const updatedContract = updateNormalField(params.row.id, 'functionId', params.value);
        return updatedContract;
      },
    },
    {
      field: 'costCenterId',
      headerName: t(CONTRACTS__COST_CENTER),
      width: 166,
      sortable: true,
      editable: editId === rowOnHover && editedContract !== null,
      renderCell: params => {
        if (params.id === editId) {
          let items = costCentersList;
          if (params.row.locationId !== 0) {
            items = filterCostCentersByLocation(companyCostCenters, params.row.locationId);
          }
          return <DataGridEditInputCell {...params} dropdownOptions={items} category="contracts" />;
        }
        return generateCostCenterName(params.row.costCenterId, costCentersList);
      },
      renderEditCell: params => {
        if (editId === params.id) {
          let items = costCentersList;
          if (params.row.locationId !== 0) {
            items = filterCostCentersByLocation(companyCostCenters, params.row.locationId);
          }

          const found = items.find((costCenter: UPDropdownItem) => params.value === costCenter?.value);
          return (
            <DataGridEditInputCell {...params} value={found?.value} dropdownOptions={items} category="contracts" />
          );
        }

        return generateCostCenterName(params.row.costCenterId, costCentersList);
      },
      valueSetter: params => {
        const updatedContract = updateNormalField(params.row.id, 'costCenterId', params.value);
        return updatedContract;
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 150,
      sortable: true,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
              {editId === params.id ? (
                <div>
                  <Tooltip title={errors[params.id]?.message || ''} arrow>
                    <span>
                      <UPButtonStyledWithNoPadding
                        disabled={
                          // eslint-disable-next-line no-unneeded-ternary
                          errors[params.id] && errors[params.id].message === t(CONTRACTS__START_END_WORK_ERROR)
                            ? true
                            : false
                        }
                        onClick={() => {
                          onSave(editedContract, toNumber(params.id));
                          setHasClickedSearch(true);
                        }}
                      >
                        {errors[params.id] && errors[params.id].message === t(CONTRACTS__START_END_WORK_ERROR) ? (
                          <SaveGreyIcon />
                        ) : (
                          <SaveGreenIcon />
                        )}
                      </UPButtonStyledWithNoPadding>
                    </span>
                  </Tooltip>
                  <UPButtonStyledWithNoPadding
                    onClick={() => {
                      setHasClickedSearch(false);
                      setEditId(null);
                      setEditedContract(null);
                      setContractToSendToBE(null);
                    }}
                  >
                    <CancelIcon />
                  </UPButtonStyledWithNoPadding>
                </div>
              ) : (
                <div>
                  {editId !== params.id && editId !== null ? (
                    <Tooltip title={t(CONTRACTS__EDIT_MORE_CONTRACTS_ERROR)} arrow>
                      <span>
                        <UPButtonStyledWithNoPadding disabled>
                          <EditIconGrey />
                        </UPButtonStyledWithNoPadding>
                      </span>
                    </Tooltip>
                  ) : (
                    <UPButtonStyledWithNoPadding onClick={() => setEditId(toNumber(params.id))}>
                      <EditIconGreen />
                    </UPButtonStyledWithNoPadding>
                  )}
                  <UPButtonStyledWithNoPadding
                    onClick={() => {
                      setRowToDelete([toNumber(params.id)]);
                      onDeleteContracts([toNumber(params.id)]);
                    }}
                  >
                    <DeleteIconGreen />
                  </UPButtonStyledWithNoPadding>
                </div>
              )}
            </div>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box>
      <UPDataGridContainer sx={{ height: '100vh' }}>
        <UPDataGrid
          rows={contracts}
          columns={contractsColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selectedItem: any) => {
            setSelectedRows(selectedItem);
            setClearAll(false);
          }}
          withCheckboxes
          getRowId={r => r.id}
          selectionModel={clearAll ? [] : selectedRows}
          disableSelectionOnClick
          onPageSizeChange={newSize => setPageSize(newSize)}
          onPageChange={newPage => setPage(newPage)}
        />
      </UPDataGridContainer>
      {selectedRows?.length > 0 && (
        <BottomGreenBar
          numberOfItems={selectedRows.length}
          actions={bottomGreenBarActions}
          onClearAll={() => {
            setSelectedRows([]);
            setClearAll(true);
          }}
          onDelete={() => onDeleteContracts(selectedRows)}
          onCopy={null}
          total={totalTime}
        />
      )}

      {openConfirmDeleteWeeklyModal && (
        <DeleteModal
          open={openConfirmDeleteWeeklyModal}
          content={t(CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING)}
          onClose={() => {
            setOpenConfirmDeleteWeeklyModal(false);
          }}
          onDelete={() => {
            setOpenConfirmDeleteModal(true);
          }}
        />
      )}
      {openConfirmDeleteModal && (
        <DeleteModal
          open={openConfirmDeleteModal}
          content={t(CONTRACTS__ARE_YOU_SURE)}
          onClose={() => {
            setOpenConfirmDeleteModal(false);
          }}
          onDelete={() => {
            makeDeleteRequest(rowToDelete.length === 1 ? rowToDelete : selectedRows, ContractListViewTypes.Future);
            setRowToDelete([]);
          }}
        />
      )}
    </Box>
  );
};

export default FutureContractsView;
