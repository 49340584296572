import { Alert, styled } from '@mui/material';

export const AlertStyled = styled(Alert)(() => ({
  color: '#EC4A67',
  background: '#FAD2D9',
  height: '48px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '.MuiAlert-message': {
    display: 'flex',
    alignItems: 'center',
  },
}));
