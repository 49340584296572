import { Backdrop, Checkbox, FormControlLabel } from '@mui/material';
import { Box } from '@mui/system';
import UPButton from 'components/button';
import UPDayPickerDisplay from 'components/day-picker-display';
import { ModalContainer } from 'components/delete-modal/styles';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPDropdownWithInput from 'components/dropdown-with-input';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import ErrorBar from 'components/error-bar';
import UPInput from 'components/input';
import UPSwitch from 'components/switch';
import { toNumber } from 'lodash';
import { fromConfirmContractToConfirmHours } from 'mappers/contracts-mapper';
import { ModalFooter, ModalTitle } from 'modules/employees/add-edit-employee-modal/styles';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getCostCentersAction } from 'store/actions/company-cost-centers-actions';
import {
  clearContractAction,
  getContractForDate,
  validateAndConfirmContract,
  validateContractErrorMessageAction,
  validateContractErrorMessageExtraInfoAction,
  validateContractSuccessAction,
} from 'store/actions/contract-actions';
import { getCostDeclarationSelectItemsForCompanyAction } from 'store/actions/cost-declaration-actions';
import { costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import {
  contractSelector,
  validateContractErrorMessageSelector,
  validateContractExtraMessageSelector,
} from 'store/selectors/contract-selectors';
import { costDeclarationSelectItemsForCompanySelector } from 'store/selectors/cost-declaration-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  COMPANY_PAYROLL__FUNCTION,
  CONTRACTS__CANCEL,
  CONTRACTS__CONFIRM_COST_DECLARATION_ERROR,
  CONTRACTS__CONFIRM_SERVICE,
  CONTRACTS__COST_CENTER,
  CONTRACTS__COST_DELCARATION,
  CONTRACTS__COST_DELCARATION_ADD,
  CONTRACTS__COST_DELCARATION_AMOUNT,
  CONTRACTS__DATE,
  CONTRACTS__END_BREAK,
  CONTRACTS__END_WORK,
  CONTRACTS__INVALID_HOUR_ERROR,
  CONTRACTS__SELECT_REASON,
  CONTRACTS__START_BREAK,
  CONTRACTS__START_WORK,
  CONTRACTS__WAGE,
  GENERAL__CONFIRM,
  GENERAL__NAME,
  GENERAL__SELECT,
  GENERAL__WORKERCLASS,
  WAGE_COMPONENTS__COST_DECLARATION_ADD,
} from 'translations/constants';
import IConfirmContract from 'types/contract/IConfirmContract';
import IConfirmHours from 'types/contract/IConfirmHours';
import ICostDeclarationRow from 'types/contract/ICostDeclarationRow';
import { getInputErrorText, getReasonForNoShowWithTranslation } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import HOUR_OPTIONS from '../HourDropdownOptions';
import {
  ConfirmDateDropdownContainer,
  ConfirmHourDateContainer,
  ConfirmHoursContainer,
  DateAndNoShowContainer,
  DayPickerError,
  DeleteCostDeclarationButton,
  HourDropdownContainer,
  ModalCloseIconStyled,
  ModalTitleContainer,
  NoShowBox,
  RowContainerForContractDate,
} from '../styles';
import { ConfirmContractModalProps } from '../types';
import {
  CostCenterStyled,
  FunctionStyled,
  ModalContentContainer,
  ModalHeaderContract,
  ModalWrapperContract,
  RowContainerForNameAndContractType,
  WageStyled,
} from './styles';

const initialContract: IConfirmContract = {
  id: 0,
  companyId: '',
  contractDateId: 0,
  employeeId: 0,
  employee: '',
  contractType: '',
  functionId: 0,
  function: '',
  costCenter: '',
  wage: 0,
  day: undefined,
  startWork: '',
  startBreak: '',
  endBreak: '',
  endWork: '',
  costCenterId: 0,
  locationId: 0,
};

const initialConfirmedHours: IConfirmHours = {
  contractDateId: 0,
  day: undefined,
  startWork: '',
  startBreak: '',
  endBreak: '',
  endWork: '',
  noShow: false,
  costDeclarations: false,
  reasonForNoShow: '',
  costCenterId: 0,
  locationId: 0,
};

const ConfirmContractModal = (props: ConfirmContractModalProps) => {
  const { open, onClose, companyId, contractDateId, onSuccess, contractError } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const theContract = useAppSelector(contractSelector);
  const profile = useAppSelector(profileSelector);
  const invalidContractError = useAppSelector(validateContractErrorMessageSelector);
  const contractValidationExtraInfo = useAppSelector(validateContractExtraMessageSelector);
  const activeCostDeclarationsForCompany = useAppSelector(costDeclarationSelectItemsForCompanySelector);
  const companyCostCenters = useAppSelector(costCentersSelector);
  const [contract, setContract] = useState<IConfirmContract>(initialContract);
  const [translatedError, setTranslatedError] = useState<string>('');
  const [costDeclarationRows, setCostDeclarationRows] = useState<ICostDeclarationRow[]>([]);
  const [formatError, setFormatError] = useState<boolean>(false);
  const [costDeclarationError, setCostDeclarationError] = useState<string>(null);
  const [costDeclarationOptions, setCostDeclarationOptions] = useState<UPDropdownItem[]>(
    activeCostDeclarationsForCompany,
  );
  const [costCenters, setCostCenters] = useState<UPDropdownItem[]>([]);
  const filterByHour = (options: UPDropdownItem[], state: { inputValue: string }) => {
    return options.filter(option => option.label.toLowerCase().startsWith(state.inputValue.toLowerCase()));
  };

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IConfirmHours>({
    defaultValues: initialConfirmedHours,
  });

  const watchNoShow: boolean = watch('noShow');
  const watchCostDeclarations: boolean = watch('costDeclarations');

  const reasons = getReasonForNoShowWithTranslation();

  useEffect(() => {
    if (invalidContractError) {
      setTranslatedError(`${t(invalidContractError)} ${contractValidationExtraInfo}`);
    }
  }, [invalidContractError, contractValidationExtraInfo, t]);

  useEffect(() => {
    if (activeCostDeclarationsForCompany) {
      setCostDeclarationOptions(activeCostDeclarationsForCompany);
    }
  }, [activeCostDeclarationsForCompany]);

  useEffect(() => {
    if (watchNoShow) {
      dispatch(validateContractErrorMessageAction(''));
      dispatch(validateContractErrorMessageExtraInfoAction(''));
      setTranslatedError('');
    }
  }, [dispatch, watchNoShow]);

  useEffect(() => {
    dispatch(getContractForDate({ contractDateId, companyId }));
    dispatch(getCostDeclarationSelectItemsForCompanyAction(toNumber(companyId)));
  }, [contractDateId, companyId, dispatch]);

  useEffect(() => {
    if (theContract) {
      setContract(theContract);
    }
  }, [theContract]);

  useEffect(() => {
    if (theContract) {
      const confirmHours = fromConfirmContractToConfirmHours(theContract);
      reset(confirmHours);
    }
  }, [theContract, reset]);

  useEffect(() => {
    dispatch(clearContractAction());
    dispatch(validateContractErrorMessageAction(''));
    dispatch(validateContractErrorMessageExtraInfoAction(''));
    setTranslatedError('');
  }, [dispatch]);

  const onPickCostDeclaration = (event, currentRow: ICostDeclarationRow, currentIndex: number) => {
    const {
      target: { value },
    } = event;

    const newCostDeclarationRows = [...costDeclarationRows];
    const newOptions = costDeclarationOptions.map((item: UPDropdownItem) => {
      if (item.value === value) {
        return { ...item, isDisabled: true };
      }
      if (item.value === currentRow.costDeclarationId && item.isDisabled) {
        return { ...item, isDisabled: false };
      }
      return item;
    });
    newCostDeclarationRows[currentIndex].costDeclarationId = value;
    setCostDeclarationOptions(newOptions);
    setCostDeclarationRows(newCostDeclarationRows);
  };

  useEffect(() => {
    dispatch(getCostCentersAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyCostCenters) {
      const constCentersNames = companyCostCenters
        .filter(e => toNumber(e.locationId) === contract.locationId || contract.locationId === 0)
        .map(item => ({ value: item.id.toString(), label: item.name }));
      setCostCenters(constCentersNames);
    }
  }, [setValue, contract, companyCostCenters]);

  const validateAndSaveContract = useCallback(
    (contractData: IConfirmHours) => {
      // if (!contractData.noShow) {
      const filteredCostDeclarations = costDeclarationRows.filter(row => row.costDeclarationId && row.amount > 0);
      (async () => {
        await dispatch(
          validateAndConfirmContract({
            confirmHours: contractData,
            employeeId: contract.employeeId,
            companyId,
            functionId: contract.functionId,
            confirmCostDeclarations: filteredCostDeclarations,
          }),
        ).then(result => {
          if (result.payload) {
            dispatch(validateContractSuccessAction(false));
            onSuccess();
            onClose();
          }
        });
      })();
      // } else {
      //   // if no show => no validation is needed (as per actual implementation)
      //   dispatch(validateContractSuccessAction(true));
      // }
    },
    [companyId, contract.employeeId, contract.functionId, costDeclarationRows, dispatch, onClose, onSuccess],
  );

  const validateCostDeclarations = useCallback(() => {
    if (watchCostDeclarations) {
      const incompleteRows = costDeclarationRows.filter(
        row =>
          (row.costDeclarationId === '' && row.amount > 0) ||
          (row.costDeclarationId !== '' && row.amount === 0) ||
          (!row.costDeclarationId && !row.amount),
      );
      if (incompleteRows.length > 0) {
        setCostDeclarationError(t(CONTRACTS__CONFIRM_COST_DECLARATION_ERROR));
        return false;
      }
    }
    setCostDeclarationError('');
    return true;
  }, [costDeclarationRows, t, watchCostDeclarations]);

  const onError = useCallback((err, e) => {
    console.log(err, e);
  }, []);

  const onSave: SubmitHandler<IConfirmHours> = useCallback(() => {
    const values = getValues();
    if (!validateCostDeclarations()) return;
    values.costCenterId = toNumber(values.costCenterId);

    const validTimeSpanRegex = /^(?:\d{2}:\d{2})?$/;

    if (!validTimeSpanRegex.test(values.endBreak)) {
      setFormatError(true);
      return;
    }

    if (!validTimeSpanRegex.test(values.startBreak)) {
      setFormatError(true);
      return;
    }

    if (!validTimeSpanRegex.test(values.startWork)) {
      setFormatError(true);
      return;
    }

    if (!validTimeSpanRegex.test(values.endWork)) {
      setFormatError(true);
      return;
    }

    validateAndSaveContract(values);
  }, [getValues, validateAndSaveContract, validateCostDeclarations]);

  return (
    <Backdrop open sx={{ zIndex: '1' }}>
      <ModalWrapperContract open={open} onClose={onClose} sx={{ width: '850px' }}>
        <ModalContainer sx={{ padding: '5px' }}>
          <ModalHeaderContract sx={{ padding: '20px 32px' }}>
            <ModalTitleContainer>
              <ModalTitle>{t(CONTRACTS__CONFIRM_SERVICE)}</ModalTitle>
              <ModalCloseIconStyled onClick={onClose}>
                <CloseIcon fill={theme.palette.secondary.contrastText} />
              </ModalCloseIconStyled>
            </ModalTitleContainer>
            {contractError && (
              <Box>
                <ErrorBar message={contractError} />
              </Box>
            )}
            <RowContainerForNameAndContractType>
              <Box sx={{ minWidth: '280px', display: 'inline-block', marginRight: '20px', width: 1 / 2 }}>
                <UPInput
                  value={contract.employee}
                  onChange={null}
                  placeholder=""
                  label={t(GENERAL__NAME)}
                  disabled
                  icon="arrow"
                />
              </Box>
              <Box sx={{ minWidth: '280px', display: 'inline-block', width: 1 / 2 }}>
                <UPInput
                  value={contract.contractType}
                  onChange={null}
                  label={t(GENERAL__WORKERCLASS)}
                  placeholder=""
                  disabled
                />
              </Box>
            </RowContainerForNameAndContractType>
          </ModalHeaderContract>
          <ModalContentContainer sx={{ paddingTop: '0px', paddingRight: '12px', height: 'auto' }}>
            <RowContainerForContractDate>
              <Controller
                name="costDeclarations"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={costDeclarationRows.length > 0}
                        onChange={value => {
                          if (value.target.checked) {
                            const newCostDeclarationRow: ICostDeclarationRow = {
                              id: costDeclarationRows.length + 1,
                              costDeclarationId: '',
                              amount: 0,
                            };
                            setCostDeclarationRows([...costDeclarationRows, newCostDeclarationRow]);
                          } else {
                            setCostDeclarationRows([]);
                            setCostDeclarationOptions(activeCostDeclarationsForCompany);
                          }
                          field.onChange(value);
                        }}
                      />
                    }
                    label={`${t(CONTRACTS__COST_DELCARATION)}`}
                  />
                )}
              />
            </RowContainerForContractDate>
            {watchCostDeclarations &&
              costDeclarationRows.map((row, index) => (
                <Box key={`row ${row.id}`} sx={{ display: 'flex', padding: '2px 0px', gap: '5px' }}>
                  <Box sx={{ display: 'flex', width: 3 / 4 }}>
                    <UPDropdown
                      value={row.costDeclarationId}
                      onChange={event => onPickCostDeclaration(event, row, index)}
                      placeholder={t(GENERAL__SELECT)}
                      items={costDeclarationOptions}
                      label={t(CONTRACTS__COST_DELCARATION_ADD)}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', width: 1 / 4 }}>
                    <UPInput
                      value={row.amount > 0 ? row.amount : ''}
                      onChange={event => {
                        const value = toNumber(event.target.value);
                        const newCostDeclarationRows = [...costDeclarationRows];
                        newCostDeclarationRows[index].amount = value;
                        setCostDeclarationRows(newCostDeclarationRows);
                      }}
                      placeholder="€ 0"
                      label={`${t(CONTRACTS__COST_DELCARATION_AMOUNT)} (€)`}
                      type="number"
                      step={0.0001}
                      inputClassName="white-background"
                    />
                  </Box>
                  <DeleteCostDeclarationButton
                    onClick={() => {
                      const newCostDeclarationRows = [...costDeclarationRows];
                      newCostDeclarationRows.splice(index, 1);
                      if (costDeclarationRows[index].costDeclarationId !== '') {
                        const newOptions = costDeclarationOptions.map((item: UPDropdownItem) => {
                          if (item.value === costDeclarationRows[index].costDeclarationId && item.isDisabled) {
                            return { ...item, isDisabled: false };
                          }
                          return item;
                        });
                        setCostDeclarationOptions(newOptions);
                      }
                      setCostDeclarationRows(newCostDeclarationRows);
                    }}
                  >
                    <CloseIcon fill={theme.palette.secondary.contrastText} />
                  </DeleteCostDeclarationButton>
                </Box>
              ))}
            {watchCostDeclarations &&
              costDeclarationRows.length > 0 &&
              costDeclarationRows.length < activeCostDeclarationsForCompany.length && (
                <Box sx={{ display: 'flex', pt: '5px' }}>
                  <UPButton
                    text={t(WAGE_COMPONENTS__COST_DECLARATION_ADD)}
                    outlined
                    onClick={() => {
                      const newCostDeclarationRow: ICostDeclarationRow = {
                        id: costDeclarationRows.length + 1,
                        costDeclarationId: '',
                        amount: 0,
                      };
                      setCostDeclarationRows([...costDeclarationRows, newCostDeclarationRow]);
                    }}
                  />
                </Box>
              )}

            {costDeclarationError && (
              <Box sx={{ display: 'flex', pt: '5px', cursor: 'default', height: '25px' }}>
                <ErrorBar message={costDeclarationError} />
              </Box>
            )}

            <RowContainerForContractDate>
              <DateAndNoShowContainer>
                <ConfirmDateDropdownContainer>
                  <ConfirmHourDateContainer>
                    <UPDayPickerDisplay label={t(CONTRACTS__DATE)} day={contract.day} />
                  </ConfirmHourDateContainer>
                </ConfirmDateDropdownContainer>
                <NoShowBox>
                  <Controller
                    name="noShow"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                        label="No show"
                      />
                    )}
                  />
                </NoShowBox>
              </DateAndNoShowContainer>
              <ConfirmHoursContainer>
                {watchNoShow ? (
                  <Controller
                    name="reasonForNoShow"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <UPDropdown
                        value={field.value}
                        onChange={field.onChange}
                        placeholder={t(CONTRACTS__SELECT_REASON)}
                        items={reasons}
                        label="Reason for no show"
                        error={!!errors.reasonForNoShow}
                        helperText={getInputErrorText(errors, 'reasonForNoShow')}
                      />
                    )}
                  />
                ) : (
                  <>
                    <HourDropdownContainer>
                      <Controller
                        name="startWork"
                        control={control}
                        render={({ field }) => (
                          <UPDropdownWithInput
                            withSearchIcon={false}
                            value={field.value}
                            handleChange={field.onChange}
                            placeholder=""
                            items={HOUR_OPTIONS}
                            label={t(CONTRACTS__START_WORK)}
                            required
                            type="startWork"
                            disabled={watchNoShow}
                            filterOptions={filterByHour}
                          />
                        )}
                      />
                    </HourDropdownContainer>
                    <HourDropdownContainer>
                      <Controller
                        name="startBreak"
                        control={control}
                        render={({ field }) => (
                          <UPDropdownWithInput
                            value={field.value}
                            handleChange={field.onChange}
                            placeholder=""
                            items={HOUR_OPTIONS}
                            label={t(CONTRACTS__START_BREAK)}
                            type="startBreak"
                            disabled={watchNoShow}
                            filterOptions={filterByHour}
                          />
                        )}
                      />
                    </HourDropdownContainer>
                    <HourDropdownContainer>
                      <Controller
                        name="endBreak"
                        control={control}
                        render={({ field }) => (
                          <UPDropdownWithInput
                            value={field.value}
                            handleChange={field.onChange}
                            placeholder=""
                            items={HOUR_OPTIONS}
                            label={t(CONTRACTS__END_BREAK)}
                            type="endBreak"
                            disabled={watchNoShow}
                            filterOptions={filterByHour}
                          />
                        )}
                      />
                    </HourDropdownContainer>
                    <HourDropdownContainer>
                      <Controller
                        name="endWork"
                        control={control}
                        render={({ field }) => (
                          <UPDropdownWithInput
                            value={field.value}
                            handleChange={field.onChange}
                            placeholder=""
                            items={HOUR_OPTIONS}
                            label={t(CONTRACTS__END_WORK)}
                            required
                            type="endWork"
                            disabled={watchNoShow}
                            filterOptions={filterByHour}
                          />
                        )}
                      />
                    </HourDropdownContainer>
                  </>
                )}
              </ConfirmHoursContainer>
            </RowContainerForContractDate>
            <DayPickerError sx={{ marginLeft: '328px' }}>{translatedError}</DayPickerError>
            {formatError && (
              <DayPickerError sx={{ marginLeft: '328px' }}>{t(CONTRACTS__INVALID_HOUR_ERROR)}</DayPickerError>
            )}
            <RowContainerForNameAndContractType>
              <FunctionStyled>
                <UPInput
                  value={contract.function}
                  onChange={null}
                  placeholder=""
                  label={t(COMPANY_PAYROLL__FUNCTION)}
                  disabled
                  icon="arrow"
                />
              </FunctionStyled>
              {profile.showWages && (
                <WageStyled>
                  <UPInput
                    value={`€ ${contract.wage}`}
                    label={t(CONTRACTS__WAGE)}
                    placeholder=""
                    onChange={null}
                    disabled
                  />
                </WageStyled>
              )}
              <CostCenterStyled>
                <Controller
                  name="costCenterId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdownWithSearch
                      onChange={field.onChange}
                      placeholder={t(GENERAL__SELECT)}
                      items={costCenters}
                      label={t(CONTRACTS__COST_CENTER)}
                      required
                      error={!!errors.costCenterId}
                      helperText={getInputErrorText(errors, 'costCenterId')}
                      hasDefaultValue
                      value={contract.costCenterId}
                    />
                  )}
                />
              </CostCenterStyled>
            </RowContainerForNameAndContractType>
          </ModalContentContainer>
          <ModalFooter>
            <UPButton
              text={t(GENERAL__CONFIRM)}
              onClick={() => {
                handleSubmit(onSave, onError)();
              }}
            />
            <Box sx={{ marginLeft: '12px' }}>
              <UPButton text={t(CONTRACTS__CANCEL)} onClick={onClose} outlined />
            </Box>
          </ModalFooter>
        </ModalContainer>
      </ModalWrapperContract>
    </Backdrop>
  );
};

export default ConfirmContractModal;
