import { Box, Button, styled } from '@mui/material';

export const EmployeeCellContainer = styled(Box)(({ theme }) => ({
  width: '16%',
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 0`,
}));

export const EmployeeCellBody = styled(Box)(({ theme }) => ({
  width: '90%',
  height: '100%',
  display: 'flex',
  backgroundColor: 'white',
  border: `2px solid ${theme.palette.primary.light}`,
  borderRadius: '4px',
}));

export const EmployeeAvatarBox = styled(Box)(() => ({
  width: '25%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
}));

export const EmployeeDetailsBox = styled(Box)(({ theme }) => ({
  width: '75%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  paddingRight: theme.spacing(1),
}));

export const EmployeeNameBox = styled(Box)(() => ({
  fontWeight: 'bold',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const RequestAvailabilityButton = styled(Button)(({ theme }) => ({
  fontSize: 'small',
  fontWeight: 'bold',
  textTransform: 'none',
  padding: theme.spacing(0.25),
}));
