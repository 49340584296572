import { countriesWithBelgiumFirst } from 'modules/companies/helpers';
import {
  getContractHoursTypeDropdownValues,
  getEmployeesDropdownValues,
  getGenderDropdownValues,
  getLanguageDropdownValues,
  getMonthsDropdownValues,
  getReportStatusDropdownValues,
  getWorkerClassesDropdownValues,
  getYearsDropdownValues,
} from 'modules/reporting/helpers';
import { ReportingFilterDropdownData } from 'modules/reporting/types';
import { useState } from 'react';
import { useAppSelector } from 'store';
import { costCentersDropdownItemsSelector } from 'store/selectors/company-cost-centers-selectors';
import { companiesDropdownItemsSelector } from 'store/selectors/company-selectors';
import { countriesNames } from 'store/selectors/countries-selectors';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';
import { paritairComiteSelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import { superAdminsSelector } from 'store/selectors/superadmins-selectors';
import { ReportingFilterState } from 'types/reporting';
import { getReasonForEmploymentWithTranslation } from 'utils/helpers';

const initialReportingFilters: ReportingFilterState = {
  selectedCompany: '',
  selectedCompanies: [],
  selectedParitairComites: [],
  selectedSuperAdmins: [],
  selectedEmployees: [],
  selectedWorkerClasses: [],
  selectedStatus: '',
  companyName: '',
  selectedNationalities: [],
  selectedBirthCountries: [],
  selectedLanguages: [],
  selectedGenders: [],
  selectedReasonsForEmployment: [],
  employeeName: '',
  ageRange: [14, 99],
  experienceRange: [0, 99],
  confirmedOnly: false,
  noShowOnly: false,
  selectedYear: new Date().getFullYear(),
  selectedMonth: new Date().getMonth() + 1,
  selectedHourType: '',
  selectedCostCenters: [],
};

const useReportingFilters = () => {
  const dropdownData: ReportingFilterDropdownData = {
    companiesDropdownItems: useAppSelector(companiesDropdownItemsSelector),
    paritairComitesDropdownItems: useAppSelector(paritairComiteSelectItemsSelector),
    superAdminsDropdownItems: useAppSelector(superAdminsSelector).map(superAdmin => ({
      value: superAdmin.id,
      label: `${superAdmin.firstName} ${superAdmin.lastName}`,
    })),
    employeesDropdownItems: getEmployeesDropdownValues(useAppSelector(employeesWithWorkerclassSelector)),
    workerClassesDropdownItems: getWorkerClassesDropdownValues(),
    reportStatusDropdownItems: getReportStatusDropdownValues(),
    countriesDropdownItems: countriesWithBelgiumFirst(useAppSelector(countriesNames)),
    languagesDropdownItems: getLanguageDropdownValues(),
    gendersDropdownItems: getGenderDropdownValues(),
    reasonsForEmploymentDropdownItems: getReasonForEmploymentWithTranslation(),
    costCentersDropdownItems: useAppSelector(costCentersDropdownItemsSelector),
    yearsDropdownItems: getYearsDropdownValues(),
    monthsDropdownItems: getMonthsDropdownValues(),
    hourTypesDropdownItems: getContractHoursTypeDropdownValues(),
  };

  const [reportingFilters, setReportingFilters] = useState<ReportingFilterState>(initialReportingFilters);

  const handleFilterChange = (
    filterName: keyof ReportingFilterState,
    value: string | string[] | number[] | boolean,
  ) => {
    setReportingFilters(prevState => ({
      ...prevState,
      [filterName]: value,
    }));
  };

  return {
    dropdownData,
    reportingFilters,
    handleFilterChange,
  };
};

export default useReportingFilters;
