import { Backdrop } from '@mui/material';
import UPInput from 'components/input';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { postVendorAction, putVendorAction } from 'store/actions/company-vendors-actions';
import theme from 'theme';
import {
  COMPANY_CONTACT__CONTACT_NAME,
  COMPANY_VENDORS__ADD_NEW_VENDOR,
  COMPANY_VENDORS__EDIT_VENDOR,
  COMPANY_VENDORS__VENDOR_NAME,
  GENERAL__CANCEL_BUTTON,
  GENERAL__EMAIL,
  GENERAL__PHONE_NUMBER,
  GENERAL__SAVE_BUTTON,
} from 'translations/constants';
import ICompanyVendor from 'types/company/managers/ICompanyVendor';
import { getInputErrorText } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  PayrollEditFunctionModal,
  PayrollEditFunctionModalActionButton,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalTitle,
} from '../payroll/styles';
import { AddEditVendorModalProps, VendorGridColumns } from './types';

const VendorInitialValues: ICompanyVendor = {
  id: 0,
  name: '',
  email: '',
  contactPerson: '',
  phone: '',
};

const AddEditVendorModal = (props: AddEditVendorModalProps) => {
  const { onClose, vendor } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const isEdit = !!vendor;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICompanyVendor>({
    defaultValues: isEdit ? vendor : VendorInitialValues,
  });

  const onSaveVendor = (data: ICompanyVendor) => {
    if (isEdit) {
      dispatch(putVendorAction({ companyId, vendor: data }));
    } else {
      dispatch(postVendorAction({ companyId, vendor: data }));
    }
    onClose();
  };

  return (
    <Backdrop open>
      <PayrollEditFunctionModal open onClose={onClose} aria-labelledby="modal-edit-payroll-function">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>
            {vendor ? t(COMPANY_VENDORS__EDIT_VENDOR) : t(COMPANY_VENDORS__ADD_NEW_VENDOR)}
          </PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn marginRight={theme.spacing(2)}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name={VendorGridColumns.VENDOR_NAME}
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_VENDORS__VENDOR_NAME)}
                      label={t(COMPANY_VENDORS__VENDOR_NAME)}
                      required
                      error={!!errors.name}
                      helperText={getInputErrorText(errors, VendorGridColumns.VENDOR_NAME, undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name={VendorGridColumns.EMAIL}
                  control={control}
                  rules={{ required: true, maxLength: 50, pattern: /^\S+@\S+$/i }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__EMAIL)}
                      label={t(GENERAL__EMAIL)}
                      required
                      error={!!errors.email}
                      helperText={getInputErrorText(errors, VendorGridColumns.EMAIL, undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name={VendorGridColumns.CONTACT_PERSON}
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_CONTACT__CONTACT_NAME)}
                      label={t(COMPANY_CONTACT__CONTACT_NAME)}
                      error={!!errors.contactPerson}
                      helperText={getInputErrorText(errors, VendorGridColumns.CONTACT_PERSON, undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name={VendorGridColumns.PHONE_NUMBER}
                  control={control}
                  rules={{ maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__PHONE_NUMBER)}
                      label={t(GENERAL__PHONE_NUMBER)}
                      error={!!errors.phone}
                      helperText={getInputErrorText(errors, VendorGridColumns.PHONE_NUMBER, undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            <PayrollEditFunctionModalActionButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={event => handleSubmit(onSaveVendor)(event)}
            />
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

export default AddEditVendorModal;
