import UPTab from 'components/tab';
import ParitairComite from 'modules/paritair-comite';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  MENU__PARITAIR_COMITE,
  MENU__PAYROLL_SETTINGS,
  PAYROLL_SETTINGS__WAGE_COMPONENTS,
} from 'translations/constants';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { useEffect } from 'react';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import WageComponents from './WageComponents';

const PayrollSettings = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const activeItem = useAppSelector(activeMenuItemSelector);
  useEffect(() => {
    if (activeItem !== t(MENU__PAYROLL_SETTINGS)) {
      dispatch(setMenuStatusAction(t(MENU__PAYROLL_SETTINGS)));
    }
  }, [activeItem, dispatch, t]);

  const PayrollSettingsTabs = [t(MENU__PARITAIR_COMITE), t(PAYROLL_SETTINGS__WAGE_COMPONENTS)];

  return (
    <UPTab sections={PayrollSettingsTabs}>
      <ParitairComite />
      <WageComponents />
    </UPTab>
  );
};

export default PayrollSettings;
