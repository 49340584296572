import { Backdrop, Box, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ModalTitle } from 'modules/documents/styles';
import UPButton from 'components/button';
import theme from 'theme';
import { Controller, useForm } from 'react-hook-form';
import UPInput from 'components/input';
import { UPDropdown, UPMultipleSelectionDropdown } from 'components/dropdown';
import { useAppDispatch } from 'store';
import { ICostDeclaration } from 'types/cost-declaration';
import { saveCostDeclarationAction, updateCostDeclarationAction } from 'store/actions/cost-declaration-actions';
import { useTranslation } from 'react-i18next';
import UPSwitch from 'components/switch';
import { getInputErrorText } from 'utils/helpers';
import { toNumber } from 'lodash';
import {
  COMPANY_PAYROLL__PARITAIR_COMITE,
  WAGE_COMPONENTS__COST_DECLARATION_ADD,
  WAGE_COMPONENTS__COST_DECLARATION_EDIT,
  WAGE_COMPONENTS__COST_DECLARATION_FOR_ALL_PC,
  WAGE_COMPONENTS__COST_DECLARATION_NAME,
} from 'translations/constants';
import {
  CostDeclarationModalContainer,
  CostDeclarationModalStyled,
  ModalContainer,
  ModalHeader,
  ModalCloseIcon,
  ModalInputContainer,
  ModalFooter,
  ModalActionButtonsContainer,
} from '../styles';
import { CostDeclarationModalProps, CostDeclarationStatus } from '../types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';

const AddEditCostDeclaration = (props: CostDeclarationModalProps): JSX.Element => {
  const { open, onClose, isEdit, costDeclarationToEdit, PCOptions } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const [PCSelection, setPCSelection] = useState<string[]>([]);
  const [allPCSelected, setAllPCSelected] = useState<boolean>(false);

  const {
    control,
    reset,
    getValues,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const onChangeSelectAllPC = useCallback((isChecked: boolean) => {
    setAllPCSelected(isChecked);
    if (isChecked) {
      // setPCSelection(PCOptions.map(item => item.value));
    } else {
      setPCSelection([]);
    }
  }, []);

  useEffect(() => {
    if (costDeclarationToEdit && isEdit) {
      reset({
        ...costDeclarationToEdit,
        status: costDeclarationToEdit.isActive ? CostDeclarationStatus.ACTIVE : CostDeclarationStatus.INACTIVE,
      });
      setPCSelection(costDeclarationToEdit.paritairComites);
      setAllPCSelected(costDeclarationToEdit.isForAllParitaireComites);
    }
  }, [costDeclarationToEdit, reset, isEdit]);

  useEffect(() => {
    if (PCSelection.length !== 0 || allPCSelected) {
      clearErrors('paritairComites');
    }
  }, [PCSelection, allPCSelected, clearErrors]);

  const onSave = () => {
    if (PCSelection.length === 0 && !allPCSelected) {
      setError('paritairComites', {
        type: 'required',
      });
      return;
    }
    let values = getValues();
    values = { ...values, isActive: values.status === CostDeclarationStatus.ACTIVE, paritairComites: PCSelection };
    delete values.status;
    const data: ICostDeclaration = values;
    if (isEdit) {
      dispatch(updateCostDeclarationAction(data));
    } else {
      dispatch(saveCostDeclarationAction(data));
    }
    onClose();
  };

  return (
    <Backdrop open={open} sx={{ zIndex: '1' }}>
      <CostDeclarationModalContainer>
        <CostDeclarationModalStyled open={open} onClose={onClose}>
          <ModalContainer>
            <ModalHeader>
              <ModalTitle>
                {isEdit ? t(WAGE_COMPONENTS__COST_DECLARATION_EDIT) : t(WAGE_COMPONENTS__COST_DECLARATION_ADD)}
              </ModalTitle>
              <ModalCloseIcon onClick={onClose}>
                <CloseIcon fill={theme.palette.secondary.contrastText} />
              </ModalCloseIcon>
            </ModalHeader>
            <ModalInputContainer>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    required
                    onChange={field.onChange}
                    placeholder={t(WAGE_COMPONENTS__COST_DECLARATION_NAME)}
                    label={t(WAGE_COMPONENTS__COST_DECLARATION_NAME)}
                    error={!!errors.name}
                    helperText={getInputErrorText(errors, 'name')}
                  />
                )}
              />
            </ModalInputContainer>
            <ModalInputContainer>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: true,
                  validate: value =>
                    !Number.isNaN(toNumber(value)) && toNumber(value) >= 1000 && toNumber(value) <= 999999,
                }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    required
                    onChange={field.onChange}
                    placeholder="Code"
                    label="Code"
                    error={!!errors.code}
                    helperText={getInputErrorText(errors, 'code')}
                  />
                )}
              />
            </ModalInputContainer>
            <ModalInputContainer>
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdown
                    value={field.value}
                    required
                    onChange={field.onChange}
                    items={[
                      { label: 'Active', value: CostDeclarationStatus.ACTIVE },
                      { label: 'Inactive', value: CostDeclarationStatus.INACTIVE },
                    ]}
                    placeholder="active"
                    label="Status"
                    error={!!errors.status}
                    helperText={getInputErrorText(errors, 'status')}
                  />
                )}
              />
            </ModalInputContainer>
            <ModalInputContainer>
              <Controller
                name="paritairComites"
                control={control}
                rules={{ required: PCSelection.length === 0 && !allPCSelected }}
                render={() => (
                  <UPMultipleSelectionDropdown
                    required
                    disabled={allPCSelected}
                    items={PCOptions}
                    value={PCSelection}
                    dropdownLabel={t(COMPANY_PAYROLL__PARITAIR_COMITE)}
                    handleChange={event => {
                      setPCSelection(event.target.value);
                    }}
                    error={!!errors.paritairComites}
                    helperText={getInputErrorText(errors, 'paritairComites')}
                  />
                )}
              />
            </ModalInputContainer>
            <ModalInputContainer>
              <Controller
                name="isForAllParitaireComites"
                control={control}
                render={({ field }) => (
                  <Box>
                    <UPSwitch
                      checked={field.value}
                      onChange={e => {
                        field.onChange(e);
                        onChangeSelectAllPC(Boolean(e.target.checked));
                      }}
                    />
                    <Typography sx={{ ml: '10px' }}>{t(WAGE_COMPONENTS__COST_DECLARATION_FOR_ALL_PC)}</Typography>
                  </Box>
                )}
              />
            </ModalInputContainer>
            <ModalFooter>
              <ModalActionButtonsContainer>
                <UPButton text="Save" onClick={handleSubmit(onSave)} />
                <UPButton text="Cancel" outlined onClick={onClose} />
              </ModalActionButtonsContainer>
            </ModalFooter>
          </ModalContainer>
        </CostDeclarationModalStyled>
      </CostDeclarationModalContainer>
    </Backdrop>
  );
};

export default AddEditCostDeclaration;
