export enum CompanyAvailabilityFilterType {
  COMPANY_ID = 'companyId',
  YEAR = 'year',
  WEEK = 'week',
  LOCATION_ID = 'locationId',
  NAME = 'name',
  WORKERCLASS = 'workerclass',
  AGE = 'age',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
}
