import { Avatar, Box, FormControlLabel } from '@mui/material';
import UPButtonGroup from 'components/button/UPGroupButton';
import { UPToggleButton } from 'components/button/styles';
import UPDatepicker from 'components/datepicker';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPInput from 'components/input';
import UPSwitch from 'components/switch';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber, toString } from 'lodash';
import { countriesWithBelgiumFirst } from 'modules/companies/helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { getCountriesNamesAction } from 'store/actions/countries-actions';
import { countriesNames } from 'store/selectors/countries-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import {
  EMPLOYEES__BIRTH_COUNTRY,
  EMPLOYEES__BIRTH_DATE,
  EMPLOYEES__BIRTH_PLACE,
  EMPLOYEES__DEPENDANTS,
  EMPLOYEES__DEPENDANTS_CHILDREN,
  EMPLOYEES__DEPENDANTS_DISABLED_CHILDREN,
  EMPLOYEES__DEPENDANTS_DISABLED_PARTNER,
  EMPLOYEES__DEPENDANTS_ELDERLY,
  EMPLOYEES__DEPENDANTS_PARTNER,
  EMPLOYEES__INSS,
  EMPLOYEES__INSS_ERROR,
  EMPLOYEES__MARITAL_STATUS,
  EMPLOYEES__MARITAL_STATUS_DIVORCED,
  EMPLOYEES__MARITAL_STATUS_LIVING_TOGETHER,
  EMPLOYEES__MARITAL_STATUS_MARRIED,
  EMPLOYEES__MARITAL_STATUS_SINGLE,
  EMPLOYEES__MARITAL_STATUS_WIDOW,
  EMPLOYEES__NATIONALITY,
  EMPLOYEES__PERSONAL_INFO,
  GENDER__MAN,
  GENDER__WOMAN,
  GENERAL__FIRST_NAME,
  GENERAL__GENDER,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__SELECT,
} from 'translations/constants';
import Lang from 'types/Lang';
import { allowOnlyPositiveNumber, getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { ReactComponent as EditEmployeeAvatar } from '../../../assets/icons/EditEmployeeAvatar.svg';
import {
  DependentsBottomContainer,
  DependentsContainer,
  EmployeeBigBox,
  EmployeeInfoSmallBox,
  EmployeeModalInfoContainer,
  LanguageBox,
  PersonalInfoSmallBoxWithNoMarginRight,
  RowContainer,
  SectionTitle,
  SmallBoxWithNoMargin,
} from './styles';
import { IPersonalInfoTabProps } from './types';

const PersonalInfoTab = (props: IPersonalInfoTabProps) => {
  const { control, errors, employeeId, watch, setError, clearErrors, inssError } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(profileSelector);
  const userLanguageId = profile?.languageId;
  const countriesNamesOptions = useAppSelector(countriesNames);
  const isEmployee = useAppSelector(profileSelector)?.role === CompanyRole.EMPLOYEE;
  const isManagerEdit =
    ['Company manager', 'Store/location manager', 'HR manager'].includes(profile?.role) && employeeId > 0;

  const [countries, setCountries] = useState<UPDropdownItem[]>([]);

  const watchHasDependants = watch('hasDependants');
  const watchBirthDate = watch('dateOfBirth');
  const watchINSS = watch('inss');
  const pictureUpload = watch('pictureUploadURL');
  const pictureBase64 = watch('pictureBase64');

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const checkINSSDateAndBirthDate = useCallback(
    (inssDate: string): boolean => {
      const birthDate = watchBirthDate;
      const bDay = typeof birthDate === 'string' ? new Date(birthDate) : birthDate;
      const year = bDay.getFullYear().toString().slice(-2).padStart(2, '0');
      const month = toNumber(bDay.getMonth() + 1);
      const day = bDay.getDate().toString().padStart(2, '0');
      const dateFormats = [
        `${year}${month.toString().padStart(2, '0')}${day}`,
        `${year}${(month + 20).toString().padStart(2, '0')}${day}`,
        `${year}${(month + 40).toString().padStart(2, '0')}${day}`,
      ];
      if (dateFormats.includes(inssDate)) return true;

      const acceptableFormatsForNoBirthdate = Array.from(
        { length: 100 },
        (_, i) => `${year}${i.toString().padStart(4, '0')}`,
      );
      return acceptableFormatsForNoBirthdate.includes(inssDate);
    },
    [watchBirthDate],
  );

  const isValidInssControlNumber = useCallback(
    (inss: string) => {
      if (!inss || inss.length !== 11) {
        return false;
      }

      const dateString = inss.substring(0, 6); // Extract the first 6 digits
      if (!checkINSSDateAndBirthDate(dateString)) {
        return false;
      }

      const dateYear = toNumber(dateString.substring(0, 2));
      const currentYear = toNumber(new Date().getFullYear().toString().substring(0, 2));

      const controlNumber = currentYear < dateYear ? 1900 : 2000;

      const year = dateYear + controlNumber;

      // we check only the first date of the inss year to include cases when months and days are 00 in inss
      // 98000016550 is a valid inss number
      const date = new Date(year, 0, 1);

      const controlDigits = inss.substring(9, 11);
      const numberToDivide =
        date.getFullYear() < 2000 ? toNumber(`${inss.substring(0, 9)}`) : toNumber(`2${inss.substring(0, 9)}`);

      const modulo = Math.floor(numberToDivide % 97);

      const calculatedControlDigits = 97 - modulo;

      return Number(controlDigits) === calculatedControlDigits;
    },
    [checkINSSDateAndBirthDate],
  );

  // INSS control number validator only
  useEffect(() => {
    const inss = toString(watchINSS.split('.').join('').split('-').join(''));
    if (inss.length === 0) {
      clearErrors('inss');
    }

    if (watchBirthDate && !isValidInssControlNumber(inss) && inss.length > 0) {
      setError('inss', { type: 'invalidINSS' });
    } else {
      clearErrors('inss');
    }
  }, [isValidInssControlNumber, setError, clearErrors, watchINSS, watchBirthDate]);

  useEffect(() => {
    if (userLanguageId) {
      const userLanguage = languageOptions.find(l => l.value === userLanguageId).label;
      dispatch(getCountriesNamesAction(userLanguage));
    } else {
      dispatch(getCountriesNamesAction('en'));
    }
  }, [dispatch, languageOptions, userLanguageId]);

  useEffect(() => {
    if (countriesNamesOptions) {
      const mappedLocationNames = countriesNamesOptions.map(item => ({ value: item.value, label: item.label }));
      const auxCountries = countriesWithBelgiumFirst(mappedLocationNames);
      setCountries(auxCountries);
    }
  }, [countriesNamesOptions]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {pictureBase64 !== null && pictureBase64 !== '' && pictureUpload !== null ? (
          <div>
            <Avatar src={pictureBase64 || pictureUpload} sx={{ width: '80px', height: '80px' }} />
          </div>
        ) : (
          <EditEmployeeAvatar />
        )}
      </Box>
      <RowContainer>
        <SectionTitle>{t(EMPLOYEES__PERSONAL_INFO)}</SectionTitle>
      </RowContainer>
      <EmployeeModalInfoContainer>
        <EmployeeInfoSmallBox>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true, maxLength: 50 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(GENERAL__FIRST_NAME)}
                placeholder={t(GENERAL__FIRST_NAME)}
                required
                error={!!errors.firstName}
                helperText={getInputErrorText(errors, 'firstName', undefined, 50)}
                inputClassName="white-background"
                disabled={isEmployee || isManagerEdit}
              />
            )}
          />
        </EmployeeInfoSmallBox>
        <EmployeeInfoSmallBox>
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true, maxLength: 50 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                onChange={field.onChange}
                label={t(GENERAL__LAST_NAME)}
                placeholder={t(GENERAL__LAST_NAME)}
                required
                error={!!errors.lastName}
                helperText={getInputErrorText(errors, 'lastName', undefined, 50)}
                inputClassName="white-background"
                disabled={isEmployee || isManagerEdit}
              />
            )}
          />
        </EmployeeInfoSmallBox>
        {isManagerEdit && (
          <LanguageBox>
            <Controller
              name="languageId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDropdown
                  value={field.value}
                  onChange={field.onChange}
                  label={t(GENERAL__LANGUAGE)}
                  placeholder={t(GENERAL__SELECT)}
                  required
                  error={!!errors.languageId}
                  helperText={getInputErrorText(errors, 'languageId')}
                  className="dropdown--white-background"
                  items={languageOptions}
                  disabled={isManagerEdit}
                />
              )}
            />
          </LanguageBox>
        )}
        {!isManagerEdit && (
          <>
            <EmployeeInfoSmallBox>
              <Controller
                name="gender"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPButtonGroup
                    label={t(GENERAL__GENDER)}
                    value={field.value}
                    onChange={field.onChange}
                    required
                    error={!!errors.gender}
                    helperText={getInputErrorText(errors, 'gender')}
                  >
                    <UPToggleButton value={1}>{t(GENDER__MAN)}</UPToggleButton>
                    <UPToggleButton value={2}>{t(GENDER__WOMAN)}</UPToggleButton>
                  </UPButtonGroup>
                )}
              />
            </EmployeeInfoSmallBox>
            <SmallBoxWithNoMargin>
              <Controller
                name="inss"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /^\d{11}$|\d{2}\.\d{2}\.\d{2}-\d{3}\.\d{2}$/,
                    message: t(EMPLOYEES__INSS_ERROR),
                  },
                }}
                render={({ field }) => (
                  <UPInput
                    value={field.value}
                    onChange={field.onChange}
                    label={t(EMPLOYEES__INSS)}
                    placeholder={t(EMPLOYEES__INSS)}
                    required
                    error={inssError || !!errors.inss}
                    helperText={getInputErrorText(errors, 'inss')}
                    inputClassName="white-background"
                    disabled={isEmployee}
                  />
                )}
              />
            </SmallBoxWithNoMargin>
          </>
        )}
      </EmployeeModalInfoContainer>
      {!isManagerEdit && (
        <EmployeeModalInfoContainer>
          <EmployeeInfoSmallBox>
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__BIRTH_DATE)}
                  required
                  error={!!errors.dateOfBirth}
                  helperText={getInputErrorText(errors, 'dateOfBirth')}
                  className="datepicker--white-background"
                />
              )}
            />
          </EmployeeInfoSmallBox>
          <EmployeeInfoSmallBox>
            <Controller
              name="birthplace"
              control={control}
              rules={{ required: true, maxLength: 50 }}
              render={({ field }) => (
                <UPInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__BIRTH_PLACE)}
                  placeholder={t(EMPLOYEES__BIRTH_PLACE)}
                  required
                  error={!!errors.birthplace}
                  helperText={getInputErrorText(errors, 'birthplace', undefined, 50)}
                  inputClassName="white-background"
                  disabled={isEmployee}
                />
              )}
            />
          </EmployeeInfoSmallBox>
          <EmployeeInfoSmallBox>
            <Controller
              name="birthCountry"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDropdown
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__BIRTH_COUNTRY)}
                  placeholder={t(GENERAL__SELECT)}
                  required
                  error={!!errors.birthCountry}
                  helperText={getInputErrorText(errors, 'birthCountry')}
                  className="dropdown--white-background"
                  items={countries}
                  disabled={isEmployee}
                />
              )}
            />
          </EmployeeInfoSmallBox>
          <SmallBoxWithNoMargin>
            <Controller
              name="nationality"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDropdown
                  value={field.value}
                  onChange={field.onChange}
                  label={t(EMPLOYEES__NATIONALITY)}
                  placeholder={t(GENERAL__SELECT)}
                  required
                  error={!!errors.nationality}
                  helperText={getInputErrorText(errors, 'nationality')}
                  className="dropdown--white-background"
                  items={countries}
                  disabled={isEmployee}
                />
              )}
            />
          </SmallBoxWithNoMargin>
        </EmployeeModalInfoContainer>
      )}
      {!isManagerEdit && (
        <>
          <EmployeeModalInfoContainer>
            <LanguageBox>
              <Controller
                name="languageId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDropdown
                    value={field.value}
                    onChange={field.onChange}
                    label={t(GENERAL__LANGUAGE)}
                    placeholder={t(GENERAL__SELECT)}
                    required
                    error={!!errors.languageId}
                    helperText={getInputErrorText(errors, 'languageId')}
                    className="dropdown--white-background"
                    items={languageOptions}
                  />
                )}
              />
            </LanguageBox>
            <EmployeeBigBox>
              <Controller
                name="maritalStatus"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPButtonGroup
                    label={t(EMPLOYEES__MARITAL_STATUS)}
                    value={field.value}
                    onChange={field.onChange}
                    required
                    error={!!errors.maritalStatus}
                    helperText={getInputErrorText(errors, 'maritalStatus')}
                    page="employee"
                  >
                    <UPToggleButton value={1}>{t(EMPLOYEES__MARITAL_STATUS_MARRIED)}</UPToggleButton>
                    <UPToggleButton value={2}>{t(EMPLOYEES__MARITAL_STATUS_SINGLE)}</UPToggleButton>
                    <UPToggleButton value={3}>{t(EMPLOYEES__MARITAL_STATUS_DIVORCED)}</UPToggleButton>
                    <UPToggleButton value={4}>{t(EMPLOYEES__MARITAL_STATUS_WIDOW)}</UPToggleButton>
                    <UPToggleButton value={5}>{t(EMPLOYEES__MARITAL_STATUS_LIVING_TOGETHER)}</UPToggleButton>
                  </UPButtonGroup>
                )}
              />
            </EmployeeBigBox>
          </EmployeeModalInfoContainer>
          <RowContainer>
            <EmployeeInfoSmallBox>
              <Controller
                name="hasDependants"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                    label={<span>{t(EMPLOYEES__DEPENDANTS)}</span>}
                    sx={{
                      '.MuiTypography-root': {
                        fontSize: '14px',
                        marginLeft: '10px',
                      },
                      '&.MuiFormControlLabel-root': {
                        marginTop: '8px',
                        marginLeft: '0px',
                      },
                    }}
                  />
                )}
              />
            </EmployeeInfoSmallBox>
          </RowContainer>

          {watchHasDependants && (
            <DependentsContainer>
              <Box sx={{ display: 'flex', flexDirection: 'row' }} width={1 / 2}>
                <Box sx={{ marginLeft: '16px', display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="dependants.partner"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                        label={<span>{t(EMPLOYEES__DEPENDANTS_PARTNER)}</span>}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '14px',
                            marginLeft: '10px',
                          },
                          '&.MuiFormControlLabel-root': {
                            marginTop: '8px',
                            marginRight: '0px',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ marginLeft: '16px', display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="dependants.disabledPartner"
                    control={control}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<UPSwitch checked={field.value} onChange={field.onChange} />}
                        label={<span>{t(EMPLOYEES__DEPENDANTS_DISABLED_PARTNER)}</span>}
                        sx={{
                          '.MuiTypography-root': {
                            fontSize: '14px',
                            marginLeft: '10px',
                          },
                          '&.MuiFormControlLabel-root': {
                            marginTop: '8px',
                            marginRight: '0px',
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Box>
              <DependentsBottomContainer>
                <PersonalInfoSmallBoxWithNoMarginRight>
                  <Controller
                    name="dependants.children"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <UPInput
                        value={value}
                        onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                        label={t(EMPLOYEES__DEPENDANTS_CHILDREN)}
                        placeholder={t(EMPLOYEES__DEPENDANTS_CHILDREN)}
                        inputClassName="white-background"
                      />
                    )}
                  />
                </PersonalInfoSmallBoxWithNoMarginRight>
                <PersonalInfoSmallBoxWithNoMarginRight>
                  <Controller
                    name="dependants.disabledChildren"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <UPInput
                        value={value}
                        onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                        label={t(EMPLOYEES__DEPENDANTS_DISABLED_CHILDREN)}
                        placeholder={t(EMPLOYEES__DEPENDANTS_DISABLED_CHILDREN)}
                        inputClassName="white-background"
                      />
                    )}
                  />
                </PersonalInfoSmallBoxWithNoMarginRight>
                <PersonalInfoSmallBoxWithNoMarginRight>
                  <Controller
                    name="dependants.elderly"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <UPInput
                        value={value}
                        onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                        label={t(EMPLOYEES__DEPENDANTS_ELDERLY)}
                        placeholder={t(EMPLOYEES__DEPENDANTS_ELDERLY)}
                        inputClassName="white-background"
                      />
                    )}
                  />
                </PersonalInfoSmallBoxWithNoMarginRight>
              </DependentsBottomContainer>
            </DependentsContainer>
          )}
        </>
      )}
    </Box>
  );
};

export default PersonalInfoTab;
