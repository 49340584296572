import { createReducer } from '@reduxjs/toolkit';
import {
  setApiUserAction,
  setApiUserRequestsLoadingAction,
  setApiUserErrorAction,
} from 'store/actions/superadmin-actions';
import ISuperAdminState from 'types/superadmin/ISuperAdminState';

const initialState: ISuperAdminState = {
  superAdmin: false,
  loading: false,
  superAdminError: false,
  apiUserError: false,
  apiUser: false,
};

const apiUserReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setApiUserAction, (state, action) => ({ ...state, apiUser: action.payload }))
    .addCase(setApiUserRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setApiUserErrorAction, (state, action) => ({
      ...state,
      apiUserError: action.payload,
    }));
});

export default apiUserReducer;
