import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import ReportingFilter from 'types/reporting/ReportingFilter';
import ReportingResult from 'types/reporting/ReportingResult';

export const getReportData = async (filters: ReportingFilter): Promise<ReportingResult> => {
  const response: AxiosResponse<any> = await getApi().post(`Reporting/Get`, filters);
  return response.data;
};

export const downloadReportData = async (filters: ReportingFilter): Promise<Blob> => {
  const response: AxiosResponse<Blob> = await getApi().post(`Reporting/Download`, filters, { responseType: 'blob' });
  return response.data;
};
