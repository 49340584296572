import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { List, ListItemIcon, ListItemText } from '@mui/material';
import { CompanyRole } from 'constants/CompanyRole';
import { MenuItemListButton } from 'modules/auth/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { setErrors } from 'store/actions/contract-actions';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { onboardingSelector } from 'store/selectors/employee-selector';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { toNumber } from 'lodash';
import { AllowedCompaniesForAvailability, AllowedCompaniesForWizard } from 'constants/CustomFeatureEnabledForCompanies';
import {
  BLOGS__BLOGS,
  MENU__AVAILABILITIES,
  MENU__COMPANIES,
  MENU__COMPANY,
  MENU__CONTRACTS,
  MENU__DASHBOARD,
  MENU__DOCS,
  MENU__EMPLOYEES,
  MENU__MY_INFORMATION,
  MENU__PAYROLL_SETTINGS,
  MENU__REPORTS,
  MENU__USERS,
} from 'translations/constants';
import { ReactComponent as AvailabilitiesIcon } from '../../assets/icons/AvailabilitiesIcon.svg';
import { ReactComponent as BlogsIcon } from '../../assets/icons/BlogsIcon.svg';
import { ReactComponent as ContractsIcon } from '../../assets/icons/Contracts.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/Dashboard.svg';
import { ReactComponent as DocsIcon } from '../../assets/icons/Docs.svg';
import { ReactComponent as EmployeesIcon } from '../../assets/icons/Employees.svg';

const MenuItemsByRole = (): JSX.Element => {
  const user = useAppSelector(profileSelector);
  const [menuItems, setMenuItems] = useState([]);
  const [t] = useTranslation();
  const activeItem = useAppSelector(activeMenuItemSelector);
  const employeeOnboarding = useAppSelector(onboardingSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const allowedCompanies = AllowedCompaniesForAvailability;
  const allowedCompaniesForWizard = AllowedCompaniesForWizard;

  const canAccessAvailabilities = useCallback((): boolean => {
    return allowedCompanies.includes(toNumber(user?.currentCompany)) || toNumber(user?.currentCompany) > 1000;
  }, [allowedCompanies, user]);

  const canAccessWizard = useCallback((): boolean => {
    return allowedCompaniesForWizard.includes(toNumber(user?.currentCompany)) || toNumber(user?.currentCompany) > 1000;
  }, [allowedCompaniesForWizard, user]);

  const adminMenuItems = useMemo(
    () => [
      {
        name: t(MENU__DASHBOARD),
        icon: <DashboardIcon />,
        route: '/dashboard',
      },
      {
        name: t(MENU__COMPANIES),
        icon: <BusinessCenterOutlinedIcon sx={{ fill: '#9EA2A2' }} />,
        route: '/companies',
      },
      {
        name: t(MENU__CONTRACTS),
        icon: <ContractsIcon />,
        route: '/contracts',
      },
      {
        name: t(MENU__EMPLOYEES),
        icon: <EmployeesIcon />,
        route: '/employees',
      },
      {
        name: t(MENU__DOCS),
        icon: <DocsIcon />,
        route: '/docs',
      },
      {
        name: t(MENU__PAYROLL_SETTINGS),
        icon: <NoteAltOutlinedIcon sx={{ fill: '#9EA2A2' }} />,
        route: '/payroll-settings',
      },
      {
        name: t(MENU__USERS),
        icon: <GroupAddOutlinedIcon sx={{ fill: '#9EA2A2' }} />,
        route: '/users',
      },
      {
        name: t(BLOGS__BLOGS),
        icon: <BlogsIcon />,
        route: '/blogs',
      },
      {
        name: t(MENU__REPORTS),
        icon: <AutoGraphOutlinedIcon />,
        route: '/reports',
      },
    ],
    [t],
  );

  const clientMenuItems = useMemo(
    () => [
      {
        name: t(MENU__DASHBOARD),
        icon: <DashboardIcon />,
        route: '/dashboard',
      },
      {
        name: t(MENU__CONTRACTS),
        icon: <ContractsIcon />,
        route: '/contracts',
      },
      {
        name: t(MENU__EMPLOYEES),
        icon: <EmployeesIcon />,
        route: '/employees',
      },
      {
        name: t(MENU__DOCS),
        icon: <DocsIcon />,
        route: '/docs',
      },
    ],
    [t],
  );

  const hrManagerMenuItems = useMemo(() => {
    const items = [...clientMenuItems];

    if (canAccessWizard()) {
      items.push({
        name: t(MENU__REPORTS),
        icon: <AutoGraphOutlinedIcon />,
        route: '/reports',
      });
    }

    return items;
  }, [clientMenuItems, t, canAccessWizard]);

  const clientManagerMenuItems = useMemo(() => {
    const items = [
      {
        name: t(MENU__DASHBOARD),
        icon: <DashboardIcon />,
        route: '/dashboard',
      },
      {
        name: t(MENU__COMPANY),
        icon: <BusinessCenterOutlinedIcon sx={{ fill: '#9EA2A2' }} />,
        route: `/company/${user?.currentCompany}/general`,
      },
      {
        name: t(MENU__CONTRACTS),
        icon: <ContractsIcon />,
        route: '/contracts',
      },
      {
        name: t(MENU__EMPLOYEES),
        icon: <EmployeesIcon />,
        route: '/employees',
      },
      {
        name: t(MENU__DOCS),
        icon: <DocsIcon />,
        route: '/docs',
      },
    ];

    if (canAccessWizard()) {
      items.push({
        name: t(MENU__REPORTS),
        icon: <AutoGraphOutlinedIcon />,
        route: '/reports',
      });
    }

    return items;
  }, [t, user, canAccessWizard]);

  const employeeMenuItemsWithAvailabilities = useMemo(
    () => [
      {
        name: t(MENU__DASHBOARD),
        icon: <DashboardIcon />,
        route: '/dashboard',
      },
      {
        name: t(MENU__CONTRACTS),
        icon: <ContractsIcon />,
        route: '/contracts',
      },
      {
        name: t(MENU__MY_INFORMATION),
        icon: <EmployeesIcon />,
        route: '/employees',
      },
      {
        name: t(MENU__AVAILABILITIES),
        icon: <AvailabilitiesIcon />,
        route: '/availabilities',
      },
      {
        name: t(MENU__DOCS),
        icon: <DocsIcon />,
        route: '/docs',
      },
    ],
    [t],
  );

  const employeeMenuItems = useMemo(
    () => [
      {
        name: t(MENU__DASHBOARD),
        icon: <DashboardIcon />,
        route: '/dashboard',
      },
      {
        name: t(MENU__CONTRACTS),
        icon: <ContractsIcon />,
        route: '/contracts',
      },
      {
        name: t(MENU__MY_INFORMATION),
        icon: <EmployeesIcon />,
        route: '/employees',
      },
      {
        name: t(MENU__DOCS),
        icon: <DocsIcon />,
        route: '/docs',
      },
    ],
    [t],
  );

  useEffect(() => {
    if (user) {
      switch (user.role) {
        case CompanyRole.ADMIN:
          setMenuItems(adminMenuItems);
          break;
        case CompanyRole.COMPANY_MANAGER:
          setMenuItems(clientManagerMenuItems);
          break;
        case CompanyRole.LOCATION_MANAGER:
          setMenuItems(clientMenuItems);
          break;
        case CompanyRole.HR_MANAGER:
          setMenuItems(hrManagerMenuItems);
          break;
        case CompanyRole.EMPLOYEE: {
          if (canAccessAvailabilities()) {
            setMenuItems(employeeMenuItemsWithAvailabilities);
          } else {
            setMenuItems(employeeMenuItems);
          }
          break;
        }
        default:
      }
    }
    // else if (employeeOnboarding) {
    //   setMenuItems(employeeMenuItems);
    //   dispatch(setMenuStatusAction(t(MENU__MY_INFORMATION)));
    // }
  }, [
    adminMenuItems,
    clientManagerMenuItems,
    clientMenuItems,
    dispatch,
    employeeMenuItems,
    employeeMenuItemsWithAvailabilities,
    canAccessAvailabilities,
    employeeOnboarding,
    user,
    t,
    hrManagerMenuItems,
  ]);

  return (
    <List>
      {menuItems.map(item => (
        <MenuItemListButton
          key={item.name}
          onClick={() => {
            dispatch(setMenuStatusAction(item.name));
            dispatch(setErrors([]));
            navigate(item.route);
          }}
          selected={activeItem === item.name}
          disabled={employeeOnboarding}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} sx={{ textAlign: 'center' }} />
        </MenuItemListButton>
      ))}
    </List>
  );
};
export default MenuItemsByRole;
