import { createReducer } from '@reduxjs/toolkit';
import {
  getAvailabilitiesErrorAction,
  getAvailabilityForWeekErrorAction,
  getAvailabilityForWeekPayloadAction,
  getAvailabilityPayloadAction,
  resetAvailabilityForWeekAction,
  setAvailabilitiesRequestsLoading,
  updateAvailabilityErrorAction,
} from 'store/actions/availabilities-actions';
import { IAvailabilitiesState, IAvailabilityForWeek, IEmployeeAvailabilityWeek } from 'types/availabilities';

const initialAvailabilityForWeek: IAvailabilityForWeek = {
  employeeAvailabilities: {
    items: [],
    numberOfResults: 0,
    pageIndex: 1,
    pageSize: 20,
  },
  companyAvailabilityIntervals: {
    morningInterval: '',
    afternoonInterval: '',
    eveningInterval: '',
  },
};

const initialState: IAvailabilitiesState = {
  availability: null,
  availabilityForWeek: initialAvailabilityForWeek,
  loading: false,
  error: false,
};

const availabilityIndex = (employeeAvailabilities: IEmployeeAvailabilityWeek) => {
  return employeeAvailabilities.days.reduce((acc, day) => {
    return acc + Number(day.isAvailableMorning) + Number(day.isAvailableAfternoon) + Number(day.isAvailableEvening);
  }, 0);
};

const sortEmployeeAvailabilities = (availabilityForWeek: IAvailabilityForWeek) => {
  availabilityForWeek.employeeAvailabilities.items.sort((a, b) => availabilityIndex(b) - availabilityIndex(a));
  return availabilityForWeek;
};

const availabilitiesReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setAvailabilitiesRequestsLoading, (state, action) => ({ ...state, loading: action.payload }))
    .addCase(getAvailabilityPayloadAction, (state, action) => ({ ...state, availability: action.payload }))
    .addCase(getAvailabilitiesErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(updateAvailabilityErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(getAvailabilityForWeekErrorAction, (state, action) => ({ ...state, error: action.payload }))
    .addCase(getAvailabilityForWeekPayloadAction, (state, action) => ({
      ...state,
      availabilityForWeek: sortEmployeeAvailabilities(action.payload),
    }))
    .addCase(resetAvailabilityForWeekAction, state => ({
      ...state,
      availabilityForWeek: {
        ...state.availabilityForWeek,
        employeeAvailabilities: {
          ...state.availabilityForWeek.employeeAvailabilities,
          items: [],
          numberOfResults: 0,
          pageIndex: 1,
          pageSize: 20,
        },
      },
    }));
});

export default availabilitiesReducer;
