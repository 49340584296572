import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getBicRequest } from 'api/requests/company-requests';
import {
  FINANCIAL__BIC_REQUIRED_ERROR,
  FINANCIAL__CLEAR,
  FINANCIAL__GENERAL_LOADING,
  FINANCIAL__GET_BIC,
  FINANCIAL__SET_BIC,
  FINANCIAL__SET_BIC_ERROR,
} from 'store/constants';

export const setFinancialRequestsLoadingAction = createAction<boolean>(FINANCIAL__GENERAL_LOADING);

export const setBicAction = createAction<string>(FINANCIAL__SET_BIC);
export const setBicErrorAction = createAction<boolean>(FINANCIAL__SET_BIC_ERROR);
export const setBicRequiredErrorAction = createAction<boolean>(FINANCIAL__BIC_REQUIRED_ERROR);

export const getBicAction = createAsyncThunk(
  FINANCIAL__GET_BIC,
  async (props: { id: string; iban: string }, thunkApi) => {
    thunkApi.dispatch(setFinancialRequestsLoadingAction(true));
    thunkApi.dispatch(setBicErrorAction(false));

    try {
      const response = await getBicRequest({ id: props.id, iban: props.iban });
      thunkApi.dispatch(setBicAction(response));
    } catch (error) {
      thunkApi.dispatch(setBicErrorAction(true));
      thunkApi.dispatch(setBicAction(''));
    }
    thunkApi.dispatch(setFinancialRequestsLoadingAction(false));
  },
);

export const clearBicAction = createAction<void>(FINANCIAL__CLEAR);
