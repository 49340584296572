import { Backdrop, Box, Stack, Typography } from '@mui/material';
import UPInput from 'components/input';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import theme from 'theme';
import { useTranslation } from 'react-i18next';
import {
  GENERAL__CANCEL_BUTTON,
  GENERAL__EMAIL,
  GENERAL__FIRST_NAME,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__SAVE_BUTTON,
  COMPANY_INFORMATION__ACTIVE,
  COMPANY_INFORMATION__NON_ACTIVE,
  GENERAL__PHONE_NUMBER,
  GENERAL__SELECT,
  GENERAL__NO,
  GENERAL__YES,
  GENERAL__HAS_TWO_FACTOR_AUTH,
} from 'translations/constants';
import { getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { useMemo } from 'react';
import { useAppDispatch } from 'store';
import Lang from 'types/Lang';
import UPSwitch from 'components/switch';
import IUser from 'types/superadmin/ISuperAdmin';
import { postSuperAdminAction, updateSuperAdminAction } from 'store/actions/superadmin-actions';
import {
  PayrollEditFunctionModal,
  PayrollEditFunctionModalActionButton,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalTitle,
} from 'modules/companies/payroll/styles';
import { UPInputLabel } from 'components/input/styles';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { EditSuperAdminProps } from './types';

const SuperAdminInitialState: IUser = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  languageId: '',
  roleId: undefined,
  phoneNumber: '',
  active: true,
  hasTwoFactorAuth: false,
};

const EditSuperAdmin = (props: EditSuperAdminProps): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { open, onClose, superAdmin, title, isEdit } = props;

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IUser>({
    defaultValues: superAdmin || SuperAdminInitialState,
  });

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const onSaveSuperAdmin: SubmitHandler<IUser> = () => {
    const values = getValues();
    if (isDirty) {
      if (isEdit) {
        dispatch(updateSuperAdminAction(values));
      } else {
        dispatch(postSuperAdminAction(values));
      }
    }
    onClose();
  };
  return (
    <Backdrop open={open}>
      <PayrollEditFunctionModal open={open} onClose={onClose} aria-labelledby="modal-edit-payroll-function">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>{title}</PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn sx={{ marginRight: '16px' }}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__FIRST_NAME)}
                      label={t(GENERAL__FIRST_NAME)}
                      required
                      error={!!errors.firstName}
                      helperText={getInputErrorText(errors, 'firstName', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__LAST_NAME)}
                      label={t(GENERAL__LAST_NAME)}
                      required
                      error={!!errors.lastName}
                      helperText={getInputErrorText(errors, 'lastName', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, maxLength: 50, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__EMAIL)}
                      label={t(GENERAL__EMAIL)}
                      required
                      error={!!errors.email}
                      helperText={getInputErrorText(errors, 'email', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__PHONE_NUMBER)}
                      label={t(GENERAL__PHONE_NUMBER)}
                      required
                      error={!!errors.phoneNumber}
                      helperText={getInputErrorText(errors, 'phoneNumber', undefined, 50)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
            <PayrollEditFunctionModalColumn>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="languageId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={languageOptions}
                      onChange={field.onChange}
                      value={field.value.toString()}
                      label={t(GENERAL__LANGUAGE)}
                      className="company-information-input--margin"
                      required
                      error={!!errors.languageId}
                      helperText={getInputErrorText(errors, 'languageId', undefined)}
                      placeholder={t(GENERAL__SELECT)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>

              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <Stack direction="row" spacing={1} alignItems="center" pt="25px">
                    <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__NON_ACTIVE)}</Typography>
                    <UPSwitch checked={field.value} onChange={field.onChange} />
                    <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__ACTIVE)}</Typography>
                  </Stack>
                )}
              />
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <UPInputLabel sx={{ width: '15%' }}>{t(GENERAL__HAS_TWO_FACTOR_AUTH)}</UPInputLabel>
                <Controller
                  name="hasTwoFactorAuth"
                  control={control}
                  render={({ field }) => (
                    <Stack direction="row" spacing={1} alignItems="center" pt="25px">
                      <Typography sx={{ fontSize: '14px' }}>{t(GENERAL__NO)}</Typography>
                      <UPSwitch checked={field.value} onChange={field.onChange} />
                      <Typography sx={{ fontSize: '14px' }}>{t(GENERAL__YES)}</Typography>
                    </Stack>
                  )}
                />
              </Box>
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            <PayrollEditFunctionModalActionButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={() => handleSubmit(onSaveSuperAdmin)()}
            />
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

export default EditSuperAdmin;
