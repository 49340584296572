import { createReducer } from '@reduxjs/toolkit';
import {
  clearCompanyAvailabilityFiltersAction,
  resetCompanyAvailabilityFiltersAction,
  setCompanyAvailabilityFiltersAction,
} from 'store/actions/company-availability-filters-actions';
import { ICompanyAvailabilityFilters, ICompanyAvailabilityFiltersState } from 'types/availabilities';
import { getNewFiltersFromPayload } from 'utils/helpers';

const initialFilters: ICompanyAvailabilityFilters = {
  companyId: 0,
  year: 0,
  week: 0,
  locationId: 0,
  name: '',
  workerclass: '',
  age: '',
  page: 1,
  pageSize: 20,
};

const initialState: ICompanyAvailabilityFiltersState = {
  filters: initialFilters,
};

const companyAvailabilityFiltersReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setCompanyAvailabilityFiltersAction, (state, action) => {
      const newFilters = getNewFiltersFromPayload(action.payload);

      return {
        ...state,
        filters: {
          ...state.filters,
          ...newFilters,
        },
      };
    })
    .addCase(clearCompanyAvailabilityFiltersAction, state => ({
      ...state,
      filters: {
        ...state.filters,
        locationId: 0,
        age: '',
        workerclass: '',
      },
    }))
    .addCase(resetCompanyAvailabilityFiltersAction, state => ({
      ...state,
      filters: {
        ...state.filters,
        name: '',
        locationId: 0,
        age: '',
        workerclass: '',
      },
    }));
});

export default companyAvailabilityFiltersReducer;
