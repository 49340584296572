import { toNumber } from 'lodash';
import { ICompanyGridDetails, ICompanyGridDetailsResponse } from 'types/company';
import IFinancialAddUpdateRequest from 'types/company/financial/IFinancialAddUpdateRequest';
import IPaymentInformation from 'types/company/financial/IPaymentInformation';
import ICompanyPayrollGeneral from 'types/company/payroll/ICompanyPayrollGeneral';
import ICompanyManager from 'types/company/managers/ICompanyManager';
import IManagerAddUpdateRequest from 'types/company/managers/IManagerAddUpdateRequest';
import IPayrollFunction from 'types/company/payroll/IPayrollFunction';
import IFunctionExemption from 'types/company/payroll/IFunctionExemption';
import IFunctionExemptionForUpdate from 'types/company/payroll/IFunctionExemptionForUpdate';
import IPayrollWageExemptions from 'types/company/payroll/IPayrollWageExemptions';
import IPayrollWageExemptionsRequest from 'types/company/payroll/IPayrollWageExemptionsRequest';

export const fromCompanyGridDetailsResponseToCompanies = (
  data: ICompanyGridDetailsResponse[],
): ICompanyGridDetails[] => {
  const result: ICompanyGridDetails[] = data.map((company: ICompanyGridDetailsResponse): ICompanyGridDetails => {
    const companyGridDetails: ICompanyGridDetails = {
      Id: company.id,
      Name: company.name,
      CompanyIdNumber: company.companyIdNumber,
      CommercialName: company.commercialName,
      Address: null,
      Logo: company.logo,
    };
    if (company.address) {
      companyGridDetails.Address = {
        id: company.address.id,
        streetName: company.address.streetName,
        streetNumber: company.address.streetNumber,
        city: company.address.city,
        postalCode: company.address.postalCode,
        countryName: company.address.countryName,
      };
    } else {
      companyGridDetails.Address = {
        id: null,
        streetName: '',
        streetNumber: '',
        city: '',
        postalCode: '',
        countryName: '',
      };
    }
    return companyGridDetails;
  });

  return result;
};

export const fromCompanyManagersResponseToCompanyManagers = (data: ICompanyManager[]): ICompanyManager[] => {
  const result: ICompanyManager[] = data.map((manager: ICompanyManager): ICompanyManager => {
    const companyManagers: ICompanyManager = {
      id: manager.id,
      firstName: manager.firstName,
      lastName: manager.lastName,
      email: manager.email,
      languageId: manager.languageId === null ? '' : manager.languageId.toString(),
      roleName: manager.roleName,
      roleId: manager.roleId === null ? '' : manager.roleId.toString(),
      locations: manager.locations.map((l: number) => l.toString()),
      active: manager.active,
      isInvitation: manager.isInvitation,
      phoneNumber: manager.phoneNumber,
      showWages: manager.showWages,
    };
    return companyManagers;
  });
  return result;
};

export const fromCompanyManagerToManagerAddUpdateRequest = (manager: ICompanyManager): IManagerAddUpdateRequest => {
  const companyManager: IManagerAddUpdateRequest = {
    id: manager.id,
    firstName: manager.firstName,
    lastName: manager.lastName,
    email: manager.email,
    languageId: manager.languageId === '' ? null : toNumber(manager.languageId),
    roleName: manager.roleName,
    roleId: manager.roleId === '' ? null : toNumber(manager.roleId),
    locations: manager.locations.map(l => toNumber(l)),
    active: manager.active,
    phoneNumber: manager.phoneNumber,
    showWages: manager.showWages,
  };
  return companyManager;
};
export const fromCompanyPayrollToAddUpdatePayrollRequest = (data: ICompanyPayrollGeneral): ICompanyPayrollGeneral => {
  return {
    ...data,
    companyId: toNumber(data.companyId),
    coefficientsPerWorkerclass: data.coefficientsPerWorkerclass.map(c => ({
      ...c,
      id: c.id > 0 && c.id < 1 ? 0 : toNumber(c.id),
      coefficient: toNumber(c.coefficient),
    })),
  };
};

export const fromCompanyFinancialResponseToPaymentInformation = (
  financial: IPaymentInformation,
): IPaymentInformation => {
  const companyFinancial: IPaymentInformation = {
    bankAccount: financial.bankAccount === null ? '' : financial.bankAccount,
    bic: financial.bic === null ? '' : financial.bic,
    invoiceEmail: financial.invoiceEmail === null ? '' : financial.invoiceEmail,
    paymentMethod: financial.paymentMethod === null ? '' : financial.paymentMethod.toString(),
    mandateReference: financial.mandateReference === null ? '' : financial.mandateReference,
    dateMandateSigned: financial.dateMandateSigned,
  };
  return companyFinancial;
};

export const fromPaymentInformationeToFinancialAddUpdateRequest = (
  financial: IPaymentInformation,
): IFinancialAddUpdateRequest => {
  const companyFinancial: IFinancialAddUpdateRequest = {
    bankAccount: financial.bankAccount,
    bic: financial.bic,
    invoiceEmail: financial.invoiceEmail === '' ? null : financial.invoiceEmail,
    paymentMethod: financial.paymentMethod === '' ? null : toNumber(financial.paymentMethod),
    mandateReference: financial.mandateReference === '' ? null : financial.mandateReference,
    dateMandateSigned: financial.dateMandateSigned,
  };
  return companyFinancial;
};

export const fromPayrollFunctionToFunctionAddUpdateRequest = (payrollFunction: IPayrollFunction): IPayrollFunction => {
  return {
    ...payrollFunction,
    companyId: toNumber(payrollFunction.companyId),
    paritairComiteId: toNumber(payrollFunction.paritairComiteId),
    category: payrollFunction.category,
    type: toNumber(payrollFunction.type) !== 0 ? toNumber(payrollFunction.type) : null,
  };
};

export const fromFunctionExemptionToFunctionExemptionAddUpdateRequest = (
  data: IFunctionExemption[],
): IFunctionExemptionForUpdate[] => {
  const result: IFunctionExemptionForUpdate[] = data.map(
    (functionExemption: IFunctionExemption): IFunctionExemptionForUpdate => {
      const functionExemptions: IFunctionExemptionForUpdate = {
        id: functionExemption.id,
        functionId: toNumber(functionExemption.functionId),
        wageExemption: functionExemption.wageExemption,
        paritairComiteId: toNumber(functionExemption.paritairComiteId),
      };
      return functionExemptions;
    },
  );
  return result;
};

export const fromWageExemptonsFunctionToWageExemptionsAddUpdateRequest = (
  wageExemptions: IPayrollWageExemptions,
): IPayrollWageExemptionsRequest => {
  return {
    ...wageExemptions,
    functionExemptions: fromFunctionExemptionToFunctionExemptionAddUpdateRequest(wageExemptions.functionExemptions),
  };
};
