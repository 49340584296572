import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  exportCompanyAvailabilityRequest,
  getAvailabilitiesRequest,
  getAvailabilityForWeekRequest,
  putAvailabilitiesRequest,
  requestAvailabilityFilteredRequest,
  requestAvailabilityRequest,
} from 'api/requests/availabilities-requests';
import {
  AVAILABILITIES__GENERAL_LOADING,
  AVAILABILITIES__GET,
  AVAILABILITIES__GET_ERROR,
  AVAILABILITIES__GET_PAYLOAD,
  AVAILABILITIES__UPDATE,
  AVAILABILITIES__UPDATE_ERROR,
  COMPANY_AVAILABILITY__EXPORT_COMPANY_AVAILABILITY,
  COMPANY_AVAILABILITY__GET_FOR_WEEK,
  COMPANY_AVAILABILITY__GET_FOR_WEEK_ERROR,
  COMPANY_AVAILABILITY__GET_FOR_WEEK_PAYLOAD,
  COMPANY_AVAILABILITY__REQUEST_AVAILABILITY,
  COMPANY_AVAILABILITY__REQUEST_AVAILABILITY_ERROR,
  COMPANY_AVAILABILITY__REQUEST_AVAILABILITY_FILTERED,
  COMPANY_AVAILABILITY__RESET_FOR_WEEK,
} from 'store/constants';
import {
  IAvailability,
  IAvailabilityForWeek,
  ICompanyAvailabilityFilters,
  IGetAvailabilitiesProps,
  IRequestAvailabilityFilters,
} from 'types/availabilities';

export const setAvailabilitiesRequestsLoading = createAction<boolean>(AVAILABILITIES__GENERAL_LOADING);

export const getAvailabilityPayloadAction = createAction<IAvailability>(AVAILABILITIES__GET_PAYLOAD);

export const getAvailabilitiesErrorAction = createAction<boolean>(AVAILABILITIES__GET_ERROR);

export const getAvailabilitiesAction = createAsyncThunk(
  AVAILABILITIES__GET,
  async (props: IGetAvailabilitiesProps, thunkApi) => {
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(true));
    thunkApi.dispatch(getAvailabilitiesErrorAction(false));

    try {
      const response = await getAvailabilitiesRequest(props);
      thunkApi.dispatch(getAvailabilityPayloadAction(response));
    } catch {
      thunkApi.dispatch(getAvailabilitiesErrorAction(true));
    }
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(false));
  },
);

export const updateAvailabilityErrorAction = createAction<boolean>(AVAILABILITIES__UPDATE_ERROR);

export const updateAvailabilitiesAction = createAsyncThunk(
  AVAILABILITIES__UPDATE,
  async (props: { availability: IAvailability }, thunkApi) => {
    const { availability } = props;

    thunkApi.dispatch(setAvailabilitiesRequestsLoading(true));
    thunkApi.dispatch(updateAvailabilityErrorAction(false));

    try {
      await putAvailabilitiesRequest({ availability });
      thunkApi.dispatch(
        getAvailabilitiesAction({
          employeeId: availability.employeeId,
          companyId: availability.companyId,
          year: availability.year,
          month: availability.month,
        }),
      );
    } catch {
      thunkApi.dispatch(updateAvailabilityErrorAction(true));
    }

    thunkApi.dispatch(setAvailabilitiesRequestsLoading(false));
  },
);

export const getAvailabilityForWeekPayloadAction = createAction<IAvailabilityForWeek>(
  COMPANY_AVAILABILITY__GET_FOR_WEEK_PAYLOAD,
);

export const resetAvailabilityForWeekAction = createAction(COMPANY_AVAILABILITY__RESET_FOR_WEEK);

export const getAvailabilityForWeekErrorAction = createAction<boolean>(COMPANY_AVAILABILITY__GET_FOR_WEEK_ERROR);

export const getCompanyAvailabilityForWeekAction = createAsyncThunk(
  COMPANY_AVAILABILITY__GET_FOR_WEEK,
  async (props: ICompanyAvailabilityFilters, thunkApi) => {
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(true));
    thunkApi.dispatch(getAvailabilityForWeekErrorAction(false));

    try {
      let response: IAvailabilityForWeek;
      if (props.companyId && props.companyId > 0) {
        response = await getAvailabilityForWeekRequest(props);
      }
      thunkApi.dispatch(getAvailabilityForWeekPayloadAction(response));
    } catch {
      thunkApi.dispatch(getAvailabilityForWeekErrorAction(true));
    }
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(false));
  },
);

export const requestAvailabilityErrorAction = createAction<boolean>(COMPANY_AVAILABILITY__REQUEST_AVAILABILITY_ERROR);

export const requestAvailabilityAction = createAsyncThunk(
  COMPANY_AVAILABILITY__REQUEST_AVAILABILITY,
  async (props: { companyId: number; month: number; employeeId: number }, thunkApi) => {
    const { companyId, month, employeeId } = props;

    thunkApi.dispatch(setAvailabilitiesRequestsLoading(true));
    thunkApi.dispatch(requestAvailabilityErrorAction(false));

    try {
      await requestAvailabilityRequest({ companyId, month, employeeId });
    } catch {
      thunkApi.dispatch(requestAvailabilityErrorAction(true));
    }

    thunkApi.dispatch(setAvailabilitiesRequestsLoading(false));
  },
);

export const requestAvailabilityFilteredAction = createAsyncThunk(
  COMPANY_AVAILABILITY__REQUEST_AVAILABILITY_FILTERED,
  async (props: IRequestAvailabilityFilters, thunkApi) => {
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(true));
    thunkApi.dispatch(requestAvailabilityErrorAction(false));

    try {
      await requestAvailabilityFilteredRequest(props);
    } catch {
      thunkApi.dispatch(requestAvailabilityErrorAction(true));
    }

    thunkApi.dispatch(setAvailabilitiesRequestsLoading(false));
  },
);

export const exportCompanyAvailabilityAction = createAsyncThunk(
  COMPANY_AVAILABILITY__EXPORT_COMPANY_AVAILABILITY,
  async (
    props: {
      companyId: number;
      locationId: number;
      workerclass: string;
      age: string;
      name: string;
      week: number;
      year: number;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(true));
    try {
      const response = await exportCompanyAvailabilityRequest(props);

      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      const date = new Date().toISOString().split('T')[0];
      link.href = url;
      link.setAttribute('download', `CompanyAvailability_Week_${props.week}_${date}.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch {
      thunkApi.dispatch(requestAvailabilityErrorAction(true));
    }
    thunkApi.dispatch(setAvailabilitiesRequestsLoading(false));
  },
);
