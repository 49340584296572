import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { deleteManagersRequest, getAllManagersRequest } from 'api/requests/company-requests';
import {
  COMPANY_MANAGERS__SET_MANAGERS,
  COMPANY_MANAGERS__SET_MANAGERS_ERROR,
  COMPANY_MANAGERS__GET_ALL,
  COMPANY_MANAGERS__UPDATE_ONE,
  COMPANY_MANAGERS__ADD_ONE,
  COMPANY_MANAGERS__REMOVE_MANY,
  COMPANY_MANAGERS__DELETE_SUCCESS,
  COMPANY_MANAGERS__DELETE_ERROR,
  COMPANY_MANAGERS__DELETE,
  COMPANY_MANAGERS__CLEAR,
  COMPANY_MANAGERS__GENERAL_LOADING,
} from 'store/constants';
import ICompanyManager from 'types/company/managers/ICompanyManager';

export const setManagersRequestsLoadingAction = createAction<boolean>(COMPANY_MANAGERS__GENERAL_LOADING);

export const setManagersAction = createAction<ICompanyManager[]>(COMPANY_MANAGERS__SET_MANAGERS);
export const setManagersErrorAction = createAction<boolean>(COMPANY_MANAGERS__SET_MANAGERS_ERROR);

export const updateOneManagersAction =
  createAction<{ id: number; changes: ICompanyManager }>(COMPANY_MANAGERS__UPDATE_ONE);
export const addOneManagersAction = createAction<ICompanyManager>(COMPANY_MANAGERS__ADD_ONE);
export const removeManyManagersAction = createAction<number[]>(COMPANY_MANAGERS__REMOVE_MANY);
export const removeAllManagersAction = createAction<void>(COMPANY_MANAGERS__CLEAR);

export const getManagersAction = createAsyncThunk(COMPANY_MANAGERS__GET_ALL, async (companyId: number, thunkApi) => {
  thunkApi.dispatch(setManagersRequestsLoadingAction(true));
  thunkApi.dispatch(setManagersErrorAction(false));
  try {
    const response = await getAllManagersRequest(companyId);
    thunkApi.dispatch(setManagersAction(response));
  } catch (error) {
    thunkApi.dispatch(setManagersErrorAction(true));
  }
  thunkApi.dispatch(setManagersRequestsLoadingAction(false));
});

export const deleteManagersSuccessAction = createAction<boolean>(COMPANY_MANAGERS__DELETE_SUCCESS);
export const deleteManagersErrorAction = createAction<boolean>(COMPANY_MANAGERS__DELETE_ERROR);

export const deleteManagersAction = createAsyncThunk(
  COMPANY_MANAGERS__DELETE,
  async (props: { companyId: string; managersIds: number[] }, thunkApi) => {
    thunkApi.dispatch(setManagersRequestsLoadingAction(true));
    thunkApi.dispatch(deleteManagersErrorAction(false));
    try {
      await deleteManagersRequest(props);
      thunkApi.dispatch(deleteManagersSuccessAction(true));
      thunkApi.dispatch(removeManyManagersAction(props.managersIds));
    } catch (error) {
      thunkApi.dispatch(deleteManagersErrorAction(true));
    }
    thunkApi.dispatch(setManagersRequestsLoadingAction(false));
  },
);
