import { createReducer } from '@reduxjs/toolkit';
import {
  saveEmployeeSuccessAction,
  saveEmployeeErrorAction,
  setEmployeesAction,
  setEmployeesErrorAction,
  getEmployeeAction,
  getEmployeeErrorAction,
  updateEmployeeErrorAction,
  updateEmployeeSuccessAction,
  clearEmployeeAction,
  setEmployeesWithWorkerclass,
  setOnboardingEmployeeDetails,
  setOnboarding,
  setEmployeesCountAction,
  setEmployeeError,
  setEmployeeRequestsLoadingAction,
  setCompanyEmployeeInvitationLinks,
  getCompanyEmployeeInvitationLinksError,
  getCompanyEmployeeInvitationLinkSuccess,
  setInvitationLinkInvalid,
} from 'store/actions/employee-actions';
import { IEmployeeGridDetails } from 'types/employee';
import IEmployeeState from 'types/employee/IEmployeeState';

const initialState: IEmployeeState = {
  saveEmployeeSuccess: false,
  saveEmployeeError: false,
  employees: [],
  setEmployeesError: false,
  employee: null,
  getEmployeeError: false,
  updateEmployeeSuccess: false,
  updateEmployeeError: false,
  employeesWithWorkerclass: [],
  onboardingDetails: null,
  loading: false,
  onboarding: false,
  employeesCount: null,
  employeeError: null,
  numberOfEmployees: null,
  companyEmployeeInvitationLinks: [],
  companyEmployeeInvitationLinkError: null,
  companyEmployeeInvitationLinkSuccess: false,
  isInvitationTokenInvalid: false,
};

const generateEmployeesWithRightURL = (employees: IEmployeeGridDetails[]) => {
  const auxEmployees = employees.map((e: IEmployeeGridDetails) => {
    return {
      ...e,
      pictureUpload: `${e.pictureUpload}?time=${Date.now()}`,
    };
  });
  return auxEmployees;
};

const employeeReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setEmployeeRequestsLoadingAction, (state, action) => ({ ...state, loading: action.payload }))
    .addCase(saveEmployeeSuccessAction, (state, action) => ({ ...state, saveEmployeeSuccess: action.payload }))
    .addCase(saveEmployeeErrorAction, (state, action) => ({ ...state, saveEmployeeError: action.payload }))
    .addCase(setEmployeesAction, (state, action) => {
      const updatedEmployees = generateEmployeesWithRightURL(action.payload.items);
      return { ...state, employees: updatedEmployees, numberOfEmployees: action.payload.numberOfResults };
    })
    .addCase(setEmployeesErrorAction, (state, action) => ({ ...state, setEmployeesError: action.payload }))
    .addCase(getEmployeeAction, (state, action) => ({ ...state, employee: action.payload }))
    .addCase(getEmployeeErrorAction, (state, action) => ({ ...state, getEmployeeError: action.payload }))
    .addCase(updateEmployeeSuccessAction, (state, action) => ({ ...state, updateEmployeeSuccess: action.payload }))
    .addCase(updateEmployeeErrorAction, (state, action) => ({ ...state, updateEmployeeError: action.payload }))
    .addCase(setEmployeesWithWorkerclass, (state, action) => ({ ...state, employeesWithWorkerclass: action.payload }))
    .addCase(clearEmployeeAction, state => ({
      ...state,
      saveEmployeeSuccess: false,
      saveEmployeeError: false,
      employee: null,
      getEmployeeError: false,
      updateEmployeeError: false,
      updateEmployeeSuccess: false,
      loading: false,
    }))
    .addCase(setOnboardingEmployeeDetails, (state, action) => ({ ...state, onboardingDetails: action.payload }))
    .addCase(setOnboarding, (state, action) => ({ ...state, onboarding: action.payload }))
    .addCase(setEmployeesCountAction, (state, action) => ({ ...state, employeesCount: action.payload }))
    .addCase(setEmployeeError, (state, action) => ({ ...state, employeeError: action.payload }))
    .addCase(getCompanyEmployeeInvitationLinkSuccess, (state, action) => ({
      ...state,
      companyEmployeeInvitationLinkSuccess: action.payload,
    }))
    .addCase(getCompanyEmployeeInvitationLinksError, (state, action) => ({
      ...state,
      companyEmployeeInvitationLinkError: action.payload,
    }))
    .addCase(setCompanyEmployeeInvitationLinks, (state, action) => ({
      ...state,
      companyEmployeeInvitationLinks: action.payload,
    }))
    .addCase(setInvitationLinkInvalid, (state, action) => ({
      ...state,
      isInvitationTokenInvalid: action.payload,
    }));
});

export default employeeReducer;
