import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts';
import { ClientPerformanceData } from 'types/reporting/ClientPerformanceData';
import CustomChartToolTip from './CustomChartToolTip';
import CustomYAxisTick from './CustomYAxisTick';

const PerformanceLine = (props: { items: ClientPerformanceData[] }): JSX.Element => {
  const { items } = props;
  const BAR_SIZE = 25;
  const HEIGHT = items.length * 3 * BAR_SIZE;
  return (
    <BarChart width={1500} height={HEIGHT} data={items} layout="vertical">
      <XAxis type="number" axisLine={false} tickLine={false} />
      <YAxis type="category" width={200} axisLine={false} tickLine={false} dataKey="companyName" />
      {/* <YAxis dataKey="companyName" tickFormatter={value => value} tick={CustomYAxisTick}>
        <defs>
          <marker id="arrow" markerWidth={10} markerHeight={10} refX={0} refY={5}>
            <polyline points="0,0 10,5 0,10" fill="#ccc" />
          </marker>
        </defs>
        <path className="axis-line" d="M0,0L0,300" stroke="#ccc" strokeWidth={1} markerEnd="url(#arrow)" />
      </YAxis> */}
      <Tooltip content={<CustomChartToolTip />} />
      <Bar dataKey="currentMonthHours" fill="#8884d8" barSize={BAR_SIZE} />
      <Bar dataKey="pastMonthHours" fill="#82ca9d" barSize={BAR_SIZE} />
      <Bar dataKey="pastYearHours" fill="#409660" barSize={BAR_SIZE} />
    </BarChart>
  );
};

export default PerformanceLine;
