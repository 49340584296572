import { createReducer } from '@reduxjs/toolkit';
import {
  setFunctionAction,
  setFunctionRequestsLoadingAction,
  setFunctionErrorAction,
} from 'store/actions/company-function-actions';
import IPayrollFunctionState from 'types/company/payroll/IPayrollFunctionState';

const initialState: IPayrollFunctionState = {
  function: false,
  loading: false,
  functionError: false,
};

const functionReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setFunctionAction, (state, action) => ({ ...state, setFunction: action.payload }))
    .addCase(setFunctionRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setFunctionErrorAction, (state, action) => ({
      ...state,
      functionError: action.payload,
    }));
});

export default functionReducer;
