import { createReducer } from '@reduxjs/toolkit';
import {
  setAuthErrorAction,
  setAuthErrorTextAction,
  setAuthRequestsLoadingAction,
  setAuthTokenAction,
  setPasswordValidityResultAction,
} from 'store/actions/auth-actions';
import IAuthState from 'types/auth/IAuthState';

const initialState: IAuthState = {
  token: null,
  loading: false,
  error: false,
  errorText: '',
  setPasswordTokenValidity: true,
};

const authReducer = createReducer(initialState, builder =>
  builder
    .addCase(setAuthTokenAction, (state, action) => ({ ...state, token: action.payload }))
    .addCase(setAuthRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setAuthErrorAction, (state, action) => ({
      ...state,
      error: action.payload,
    }))
    .addCase(setAuthErrorTextAction, (state, action) => {
      return {
        ...state,
        errorText: action.payload,
      };
    })
    .addCase(setPasswordValidityResultAction, (state, action) => {
      return {
        ...state,
        setPasswordTokenValidity: action.payload,
      };
    }),
);

export default authReducer;
