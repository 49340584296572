import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { getConfig } from 'config';
import logError from 'utils/log-error';

const options = {
  fallbackLng: 'en',
  lng: 'en',
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
  },
};

export const initTranslations = async (): Promise<void> => {
  try {
    i18next.use(initReactI18next);
    i18next.use(HttpApi);

    await i18next.init({
      ...options,
      backend: { loadPath: `${getConfig('API_ENDPOINT')}Translations/GetTranslations/{{lng}}` },
    });
  } catch (err) {
    logError(err);
  }
};
