import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { UPDropdownItem } from 'components/dropdown';
import { DocumentToSend } from 'modules/documents/types';
import moment from 'moment';
import { IDocument } from 'types/documents';

export const uploadDocumentsRequest = async (props: {
  documents: DocumentToSend[];
  companyId: number;
  academicPeriodStart?: string;
  academicPeriodEnd?: string;
  employeeId?: number;
  categoryId?: number;
}): Promise<void> => {
  const { companyId, documents, academicPeriodStart, academicPeriodEnd, employeeId, categoryId } = props;
  const config = { headers: { 'Content-Type': 'multipart/form-data', companyId } };
  const fd = new FormData();

  documents.forEach(doc => {
    fd.append('File', doc.file);
    fd.append('Category', doc.category.toString());
    fd.append('Date', doc.date);
    fd.append('Hour', doc.hour);
    if (doc.periodStart) fd.append('PeriodStart', moment(new Date(doc.periodStart)).format('DD/MM/YYYY'));
    if (doc.periodEnd) fd.append('PeriodEnd', moment(new Date(doc.periodEnd)).format('DD/MM/YYYY'));
    if (employeeId) fd.append('EmployeeId', employeeId?.toString());
    if (doc.employeeId) fd.append('Employee', doc.employeeId.toString());
    if (doc.category === 6 || categoryId === 6) {
      if (academicPeriodStart) fd.append('AcademicPeriodStart', academicPeriodStart);
      if (academicPeriodEnd) fd.append('AcademicPeriodEnd', academicPeriodEnd);
    }
  });

  await getApi().post(`Documents`, fd, config);
};

export const updateStudentDeclarationRequest = async (props: {
  companyId: number;
  employeeId: number;
  startDate: Date;
  endDate: Date;
}): Promise<any> => {
  const { companyId, employeeId, startDate, endDate } = props;

  const response: AxiosResponse<any> = await getApi().post(
    `Documents/UpdateStudentDeclaration`,
    {
      employeeId,
      startDate,
      endDate,
    },
    {
      headers: {
        companyId,
      },
    },
  );
  return response.data;
};

export const getDocumentsRequest = async (props: {
  companyId: number;
  category: number | null;
  employeeId: number | null;
  startDate: Date | null;
  endDate: Date | null;
}): Promise<IDocument[]> => {
  const { companyId, category, startDate, endDate, employeeId } = props;
  const response: AxiosResponse<IDocument[]> = await getApi().post(
    'Documents/GetAll',
    { category, employeeId, startDate, endDate },
    { headers: { companyId } },
  );

  return response.data;
};

export const deleteDocumentRequest = async (props: { companyId: number; id: number }): Promise<any> => {
  const { companyId, id } = props;
  const response: AxiosResponse<number> = await getApi().delete(`Documents/${id}`, {
    headers: { companyId },
  });
  return response.data;
};

export const downloadDocumentRequest = async (props: { companyId: number; id: number }): Promise<any> => {
  const { companyId, id } = props;
  const response: AxiosResponse<number> = await getApi().get(`Documents/${id}`, {
    headers: { companyId },
    responseType: 'blob',
  });
  return response.data;
};

export const downloadMultipleDocumentRequest = async (props: { companyId: number; id: number[] }): Promise<any> => {
  const { companyId, id } = props;
  const url = `Documents/DownloadMultiple`;
  const headers = {
    'Content-Type': 'application/json',
    companyId,
  };

  const response: AxiosResponse<number> = await getApi().post(url, id, {
    headers,
    responseType: 'blob',
  });

  return response.data;
};

export const syncExpressoDocumentRequest = async (props: {
  companyId: number;
  startDate: Date;
  endDate: Date;
  addEmployeeDocs: boolean;
}): Promise<any> => {
  const { companyId, startDate, endDate, addEmployeeDocs } = props;

  const response: AxiosResponse<number> = await getApi().post(
    'Documents/SyncExpressoInvoices',
    { companyId, startDate, endDate, includeEmployeeDocuments: addEmployeeDocs },
    { headers: { companyId } },
  );

  return response.data;
};

export const updateEmployeeOnDocumentRequest = async (props: {
  documentId: number;
  employeeId: number;
  companyId: number;
}): Promise<any> => {
  const { documentId, employeeId, companyId } = props;

  const response: AxiosResponse<number> = await getApi().post(
    'Documents/UpdateEmployeeOnDocument',
    {
      documentId,
      employeeId,
    },
    { headers: { companyId } },
  );

  return response.data;
};

export const getDocumentCategoriesRequest = async (): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get('Documents/GetCategoriesByRole');
  return response.data;
};
