import { Modal, TextareaAutosize, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import UPButton from 'components/button';

export const AddNewBlogButton = styled(UPButton)(() => ({
  margin: '16px 16px 16px 0px',
}));

export const ModalWrapper = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '944px',
  maxHeight: '650px',
  height: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.down('lg')]: {
    width: '850px',
  },
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  padding: '32px 32px 0px 32px',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));
export const ModalContainer = styled(Box)(() => ({
  width: '100%',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  background: 'rgb(250, 250, 250)',
  borderRadius: '8px',
  border: 'none',
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
}));

export const ModalFooter = styled(Box)(() => ({
  backgroundColor: 'white',
  padding: '12px 0px',
  display: 'flex',
  justifyContent: 'center',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
}));

export const ModalCloseIcon = styled(Box)(() => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const BlogTextareaStyled = styled(TextareaAutosize)(() => ({
  width: '100%',
  padding: '14px',
  fontSize: '14px',
  color: '#5F6161',
  borderRadius: '2px',
  fontFamily: 'Montserrat,sans-serif',
  '&:focus': {
    outline: '2px solid #008C15',
  },
}));
