import { Backdrop } from '@mui/material';
import { CONTRACTS__SELECT_REASON, GENERAL__CANCEL_BUTTON, GENERAL__SAVE_BUTTON } from 'translations/constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler } from 'react-hook-form';
import { ModalActionButton, ModalActionContainer, ModalContainer } from 'components/delete-modal/styles';
import { getReasonForNoShowWithTranslation } from 'utils/helpers';
import { UPDropdown } from 'components/dropdown';
import { NoShowModalStyled, NoShowModalTitle } from '../styles';
import { NoShowModalProps } from '../types';

const NoShowNodal = (props: NoShowModalProps): JSX.Element => {
  const { open, onClose, content, onSave, id } = props;

  const [t] = useTranslation();

  const [selectedReason, setSelectedReason] = useState('');

  const reasons = getReasonForNoShowWithTranslation();

  const onClickSave: SubmitHandler<any> = event => {
    onSave(id, selectedReason);
    onClose(event);
  };

  return (
    <Backdrop open={open}>
      <NoShowModalStyled open={open} onClose={onClose}>
        <ModalContainer>
          <NoShowModalTitle>{content}</NoShowModalTitle>
          <UPDropdown
            value={selectedReason}
            onChange={e => setSelectedReason(e.target.value)}
            placeholder={t(CONTRACTS__SELECT_REASON)}
            items={reasons}
            label=""
          />
          <ModalActionContainer>
            <ModalActionButton text={t(GENERAL__SAVE_BUTTON)} onClick={onClickSave} disabled={selectedReason === ''} />
            <ModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </ModalActionContainer>
        </ModalContainer>
      </NoShowModalStyled>
    </Backdrop>
  );
};

export default NoShowNodal;
