import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { postCostCenterRequest, putCostCenterRequest } from 'api/requests/company-requests';
import { fromCostCenterPayrolltoToCostCenterRequest } from 'mappers/costcenter-mappers';

import {
  COMPANY_COST_CENTER__GENERAL_LOADING,
  COMPANY_COST_CENTER__POST,
  COMPANY_COST_CENTER__PUT,
  COMPANY_COST_CENTER__SET_COST_CENTER,
  COMPANY_COST_CENTER__SET_COST_CENTER_ERROR,
} from 'store/constants';
import IPayrollCostCenter from 'types/company/payroll/IPayrollCostCenter';
import { addOneCostCenterAction, updateOneCostCenterAction } from './company-cost-centers-actions';

export const setCostCenterRequestsLoadingAction = createAction<boolean>(COMPANY_COST_CENTER__GENERAL_LOADING);

export const setCostCenterAction = createAction<boolean>(COMPANY_COST_CENTER__SET_COST_CENTER);
export const setCostCenterErrorAction = createAction<boolean>(COMPANY_COST_CENTER__SET_COST_CENTER_ERROR);

export const putCostCenterAction = createAsyncThunk(
  COMPANY_COST_CENTER__PUT,
  async (data: { companyId: string; costCenter: IPayrollCostCenter }, thunkApi) => {
    thunkApi.dispatch(setCostCenterRequestsLoadingAction(true));
    thunkApi.dispatch(setCostCenterErrorAction(false));
    try {
      const costCenterRequest = fromCostCenterPayrolltoToCostCenterRequest(data.costCenter);
      const companyId = data.companyId.toString();
      await putCostCenterRequest({ companyId, costCenter: costCenterRequest });
      thunkApi.dispatch(setCostCenterAction(true));
      thunkApi.dispatch(updateOneCostCenterAction({ id: data.costCenter.id, changes: data.costCenter }));
    } catch (error) {
      thunkApi.dispatch(setCostCenterErrorAction(true));
    }
    thunkApi.dispatch(setCostCenterRequestsLoadingAction(false));
  },
);

export const postCostCenterAction = createAsyncThunk(
  COMPANY_COST_CENTER__POST,
  async (data: { companyId: string; costCenter: IPayrollCostCenter }, thunkApi) => {
    thunkApi.dispatch(setCostCenterRequestsLoadingAction(true));
    thunkApi.dispatch(setCostCenterErrorAction(false));
    try {
      const costCenterRequest = fromCostCenterPayrolltoToCostCenterRequest(data.costCenter);
      const costCenterId = await postCostCenterRequest({ companyId: data.companyId, costCenter: costCenterRequest });
      const newCostCenter: IPayrollCostCenter = { ...data.costCenter, id: costCenterId };
      thunkApi.dispatch(setCostCenterAction(true));
      thunkApi.dispatch(addOneCostCenterAction(newCostCenter));
    } catch (error) {
      thunkApi.dispatch(setCostCenterErrorAction(true));
    }
    thunkApi.dispatch(setCostCenterRequestsLoadingAction(false));
  },
);
