import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteLocationsRequest,
  getAllLocationNamesRequest,
  getAllLocationsAsSelectItems,
  getAllLocationsRequest,
} from 'api/requests/company-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  COMPANY_LOCATIONS__ADD_ONE,
  COMPANY_LOCATIONS__DELETE,
  COMPANY_LOCATIONS__DELETE_ERROR,
  COMPANY_LOCATIONS__DELETE_SUCCESS,
  COMPANY_LOCATIONS__GENERAL_LOADING,
  COMPANY_LOCATIONS__GET_ALL,
  COMPANY_LOCATIONS__GET_ALL_DROPDOWN_ITEMS,
  COMPANY_LOCATIONS__GET_ALL_LOCATIONS_NAMES,
  COMPANY_LOCATIONS__REMOVE_ALL,
  COMPANY_LOCATIONS__REMOVE_MANY,
  COMPANY_LOCATIONS__SET_LOCATIONS,
  COMPANY_LOCATIONS__SET_LOCATIONS_DROPDOWN_ITEMS,
  COMPANY_LOCATIONS__SET_LOCATIONS_DROPDOWN_ITEMS_ERROR,
  COMPANY_LOCATIONS__SET_LOCATIONS_ERROR,
  COMPANY_LOCATIONS__SET_LOCATIONS_NAMES,
  COMPANY_LOCATIONS__SET_LOCATIONS_NAMES_ERROR,
  COMPANY_LOCATIONS__UPDATE_ONE,
} from 'store/constants';
import ILocation from 'types/company/locations/ILocation';
import ILocationName from 'types/company/locations/ILocationName';

export const setLocationsRequestsLoadingAction = createAction<boolean>(COMPANY_LOCATIONS__GENERAL_LOADING);

export const setLocationsAction = createAction<ILocation[]>(COMPANY_LOCATIONS__SET_LOCATIONS);
export const setLocationsErrorAction = createAction<boolean>(COMPANY_LOCATIONS__SET_LOCATIONS_ERROR);

export const updateOneLocationAction = createAction<{ id: number; changes: ILocation }>(COMPANY_LOCATIONS__UPDATE_ONE);
export const addOneLocationAction = createAction<ILocation>(COMPANY_LOCATIONS__ADD_ONE);
export const removeManyLocationAction = createAction<number[]>(COMPANY_LOCATIONS__REMOVE_MANY);
export const removeAllLocationsAction = createAction<void>(COMPANY_LOCATIONS__REMOVE_ALL);

export const getLocationsAction = createAsyncThunk(COMPANY_LOCATIONS__GET_ALL, async (companyId: number, thunkApi) => {
  thunkApi.dispatch(setLocationsRequestsLoadingAction(true));
  thunkApi.dispatch(setLocationsErrorAction(false));
  try {
    const response = await getAllLocationsRequest(companyId);
    thunkApi.dispatch(setLocationsAction(response));
  } catch (error) {
    thunkApi.dispatch(setLocationsErrorAction(true));
  }
  thunkApi.dispatch(setLocationsRequestsLoadingAction(false));
});

export const deleteLocationsSuccessAction = createAction<boolean>(COMPANY_LOCATIONS__DELETE_SUCCESS);
export const deleteLocationsErrorAction = createAction<boolean>(COMPANY_LOCATIONS__DELETE_ERROR);

export const deleteLocationsAction = createAsyncThunk(
  COMPANY_LOCATIONS__DELETE,
  async (props: { companyId: string; locationsIds: number[] }, thunkApi) => {
    thunkApi.dispatch(setLocationsRequestsLoadingAction(true));
    thunkApi.dispatch(deleteLocationsErrorAction(false));
    try {
      await deleteLocationsRequest(props);
      thunkApi.dispatch(deleteLocationsSuccessAction(true));
      thunkApi.dispatch(removeManyLocationAction(props.locationsIds));
    } catch (error) {
      thunkApi.dispatch(deleteLocationsErrorAction(true));
    }
    thunkApi.dispatch(setLocationsRequestsLoadingAction(false));
  },
);

export const setLocationsNamesAction = createAction<ILocationName[]>(COMPANY_LOCATIONS__SET_LOCATIONS_NAMES);
export const setLocationsNamesErrorAction = createAction<boolean>(COMPANY_LOCATIONS__SET_LOCATIONS_NAMES_ERROR);

export const getLocationsNamesAction = createAsyncThunk(
  COMPANY_LOCATIONS__GET_ALL_LOCATIONS_NAMES,
  async (props: { companyId: number; includeAllForManager?: boolean }, thunkApi) => {
    thunkApi.dispatch(setLocationsRequestsLoadingAction(true));
    thunkApi.dispatch(setLocationsNamesErrorAction(false));
    try {
      if (props.companyId > 0) {
        const response = await getAllLocationNamesRequest(props.companyId, props.includeAllForManager ?? false);
        thunkApi.dispatch(setLocationsNamesAction(response));
      }
    } catch (error) {
      thunkApi.dispatch(setLocationsNamesErrorAction(true));
    }
    thunkApi.dispatch(setLocationsRequestsLoadingAction(false));
  },
);

export const setLocationsDropdownItemsAction = createAction<UPDropdownItem[]>(
  COMPANY_LOCATIONS__SET_LOCATIONS_DROPDOWN_ITEMS,
);
export const setLocationsDropdownItemsErrorAction = createAction<boolean>(
  COMPANY_LOCATIONS__SET_LOCATIONS_DROPDOWN_ITEMS_ERROR,
);

export const getLocationsDropdownItemsAction = createAsyncThunk(
  COMPANY_LOCATIONS__GET_ALL_DROPDOWN_ITEMS,
  async (props: { companyId: number; includeAllForManager?: boolean }, thunkApi) => {
    thunkApi.dispatch(setLocationsRequestsLoadingAction(true));
    thunkApi.dispatch(setLocationsDropdownItemsErrorAction(false));
    try {
      const response = await getAllLocationsAsSelectItems(props.companyId, props.includeAllForManager ?? false);
      thunkApi.dispatch(setLocationsDropdownItemsAction(response));
    } catch (error) {
      thunkApi.dispatch(setLocationsDropdownItemsErrorAction(true));
    }
    thunkApi.dispatch(setLocationsRequestsLoadingAction(false));
  },
);
