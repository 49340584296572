import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { PayrollAddNewItemButton } from 'modules/companies/payroll/styles';
import { SectionContainer } from 'modules/companies/styles';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { apiUsersSelector } from 'store/selectors/apiusers-selectors';
import CustomSearchBox from 'components/app-container/custom-search-box/CustomSearchBox';
import { deleteApiUsersAction, getApiUsersAction } from 'store/actions/superadmins-actions';
import { getCompaniesAsDropdownItemsAction } from 'store/actions/company-actions';
import { escapeRegExp } from 'utils/helpers';
import {
  API_USERS__ADD_NEW,
  API_USERS__ADD_NEW_BUTTON,
  API_USERS__DELETE_SUPERADMIN,
  API_USERS__EDIT_SUPERADMIN,
  GENERAL__DELETE_SELECTED,
  GENERAL__EDIT,
  GENERAL__FIRST_NAME,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__USERNAME,
  MENU__USERS,
} from 'translations/constants';
import IUser from 'types/superadmin/ISuperAdmin';
import DeleteModal from 'components/delete-modal';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import EditApiUser from './EditApiUser';

const ApiUsers = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const users = useAppSelector(apiUsersSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [openAddEditUser, setOpenAddEditUser] = useState<boolean>(false);
  const [isAddNewButtonClicked, setIsAddNewButtonClicked] = useState<boolean>(false);
  const [rows, setRows] = useState<IUser[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const onOpenAddEditModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenAddEditUser(true);
  }, []);

  const onCloseAddEditModal = useCallback(() => {
    setOpenAddEditUser(false);
    setIsAddNewButtonClicked(false);
  }, []);

  const getSuperAdminOnEdit = useCallback(() => {
    return users.find(s => s.id === rowOnHover);
  }, [users, rowOnHover]);

  const onOpenDeleteModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeleteModal(true);
  }, []);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  useEffect(() => {
    if (activeItem !== t(MENU__USERS)) {
      dispatch(setMenuStatusAction(t(MENU__USERS)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    dispatch(getApiUsersAction());
    dispatch(getCompaniesAsDropdownItemsAction());
  }, [dispatch]);

  useEffect(() => {
    if (users) {
      setRows(users);
    }
  }, [users]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'firstName', headerName: t(GENERAL__FIRST_NAME), width: 200 },
    { field: 'lastName', headerName: t(GENERAL__LAST_NAME), width: 200 },
    { field: 'email', headerName: t(GENERAL__USERNAME), width: 300 },
    { field: 'language', headerName: t(GENERAL__LANGUAGE), width: 200, hide: true },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return <UPButton onClick={onOpenAddEditModal} text={t(GENERAL__EDIT)} outlined />;
        }
        return null;
      },
    },
  ];

  const handleGridSearch = (searchTerm: string) => {
    if (searchTerm) {
      const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');
      const filteredRows = users.filter((row: IUser) => {
        return Object.keys(row).some((field: string) => {
          return searchRegex.test(row[field]);
        });
      });
      setRows(filteredRows);
    } else {
      setRows(users);
    }
  };

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(API_USERS__ADD_NEW_BUTTON)}`}
          onClick={event => {
            setRowOnHover(null);
            setIsAddNewButtonClicked(true);
            onOpenAddEditModal(event);
          }}
        />
        <Box sx={{ marginLeft: '15px' }}>
          <PayrollAddNewItemButton
            sx={{ height: '49px' }}
            text={t(GENERAL__DELETE_SELECTED)}
            onClick={onOpenDeleteModal}
            outlined
            disabled={!(selectedRows.length > 0)}
          />
        </Box>
        <Box
          sx={{
            m: '16px 0px 0px 16px',
            width: '300px',
            height: '90%',
          }}
        >
          <CustomSearchBox onSearch={handleGridSearch} />
        </Box>
      </Box>
      <Box sx={{ height: '65vh', width: '100%' }}>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          onSelectionModelChange={onSelectionChange}
        />
        {openAddEditUser && (
          <EditApiUser
            open={openAddEditUser}
            onClose={onCloseAddEditModal}
            superAdmin={getSuperAdminOnEdit()}
            title={isAddNewButtonClicked ? t(API_USERS__ADD_NEW) : t(API_USERS__EDIT_SUPERADMIN)}
            isEdit={!isAddNewButtonClicked}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            content={t(API_USERS__DELETE_SUPERADMIN)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => {
              dispatch(deleteApiUsersAction(selectedRows));
            }}
          />
        )}
      </Box>
    </SectionContainer>
  );
};

export default ApiUsers;
