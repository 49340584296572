import { createReducer } from '@reduxjs/toolkit';
import { setItemsPerPage } from 'store/actions/app-action';

const initialState = {
  itemsPerPage: 25,
};

const appReducer = createReducer(initialState, builder => {
  return builder.addCase(setItemsPerPage, (state, action) => {
    return {
      ...state,
      itemsPerPage: action.payload,
    };
  });
});

export default appReducer;
