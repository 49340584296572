import { Backdrop } from '@mui/material';
import { GENERAL__CANCEL_BUTTON, GENERAL__YES } from 'translations/constants';
import { useTranslation } from 'react-i18next';
import { SubmitHandler } from 'react-hook-form';
import { DeleteModalStyled, ModalActionButton, ModalActionContainer, ModalContainer, DeleteModalTitle } from './styles';
import { DeleteModalProps } from './types';

const DeleteModal = (props: DeleteModalProps): JSX.Element => {
  const { open, onClose, content, onDelete } = props;
  const [t] = useTranslation();

  const onClickDelete: SubmitHandler<any> = event => {
    onDelete();
    onClose(event);
  };

  return (
    <Backdrop open={open} sx={{ zIndex: '2000' }}>
      <DeleteModalStyled open={open} onClose={onClose}>
        <ModalContainer>
          <DeleteModalTitle>{content}</DeleteModalTitle>
          <ModalActionContainer>
            <ModalActionButton text={t(GENERAL__YES)} onClick={onClickDelete} />
            <ModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </ModalActionContainer>
        </ModalContainer>
      </DeleteModalStyled>
    </Backdrop>
  );
};

export default DeleteModal;
