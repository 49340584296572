/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@mui/material';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { generateLimitedString } from 'modules/blogs/helpers';
import { DASHBOARD__FOR_YOU } from 'translations/constants';
import { useAppSelector } from 'store';
import { dashboardBlogSelector } from 'store/selectors/blog-selector';
import { BlogsContainer, CardForBlogsStyled } from './styles';

const DashboardBlogsList = (): JSX.Element => {
  const [t] = useTranslation();
  const blogs = useAppSelector(dashboardBlogSelector);

  return (
    <BlogsContainer>
      <div className="dashboard-for-you">{t(DASHBOARD__FOR_YOU)}</div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {blogs.map(blog => {
          return (
            <a href={blog.url} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer" key={blog.id}>
              <CardForBlogsStyled>
                <Box sx={{ display: 'flex', color: '#008C15', fontWeight: '600', paddingBottom: '10px' }}>
                  <div>{blog.date}</div>
                  <div style={{ padding: '0px 10px' }}>|</div>
                  <div>{generateLimitedString(blog.title, 90)}</div>
                </Box>
                <div style={{ color: '#5F6161' }}>{generateLimitedString(blog.description, 200)}</div>
              </CardForBlogsStyled>
            </a>
          );
        })}
      </Box>
    </BlogsContainer>
  );
};

export default DashboardBlogsList;
