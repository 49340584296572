import { Snackbar } from '@mui/material';
import { UPAlertStyled } from './styles';
import { UPSnackBarProps } from './types';

const defaultHeight = 70;

const UPSnackBar = (props: UPSnackBarProps): JSX.Element => {
  const { open, onClose, content, type, vertical, horizontal, index } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
      sx={{
        '@media (min-width: 992px)': {
          bottom: index === 0 ? '20px' : `${index * defaultHeight + 20}px`,
        },
      }}
      onClose={onClose}
    >
      <UPAlertStyled onClose={onClose} severity={type} variant="filled">
        {content}
      </UPAlertStyled>
    </Snackbar>
  );
};

export default UPSnackBar;
