import { useTranslation } from 'react-i18next';
import {
  MANAGER_ROLE__CLIENT_MANAGER,
  MANAGER_ROLE__STORE_MANAGER,
  MANAGER_ROLE__HR_MANAGER,
} from 'translations/constants';

const useRolesTranslations = (functionType: number) => {
  const [t] = useTranslation();
  const getTranslation = () => {
    switch (functionType) {
      case 1:
        return t(MANAGER_ROLE__CLIENT_MANAGER);
      case 2:
        return t(MANAGER_ROLE__STORE_MANAGER);
      case 3:
        return t(MANAGER_ROLE__HR_MANAGER);
      default:
        return '';
    }
  };
  return getTranslation();
};
export default useRolesTranslations;
