import { Box, styled } from '@mui/material';

export const AvailabilitiesTableContainer = styled(Box)(({ theme }) => ({
  width: '80%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
