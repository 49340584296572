import { Avatar, Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { toNumber } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { getChildCompaniesAction } from 'store/actions/company-actions';
import { changeCompanyAction } from 'store/actions/auth-actions';
import { childCompaniesSelector } from 'store/selectors/company-selectors';
import {
  COMPANY_INFORMATION__COMPANY_NAME,
  COMPANY_INFORMATION__COMPANY_ID_NUMBER,
  GENERAL__ADDRESS,
} from 'translations/constants';
import { ICompanyGridDetails } from 'types/company';
import { getFormattedCompanyAddress } from 'utils/helpers';
import { SectionContainer } from '../styles';

const ChildCompaniesStructure = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const childCompanies = useAppSelector(childCompaniesSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [rows, setRows] = useState<ICompanyGridDetails[]>([]);

  const onClickCompanyChange = useCallback(
    changeCompanyId => {
      dispatch(changeCompanyAction(changeCompanyId));
    },
    [dispatch],
  );

  useEffect(() => {
    if (companyId) {
      dispatch(getChildCompaniesAction(toNumber(companyId)));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (childCompanies) {
      setRows(childCompanies);
    }
  }, [childCompanies]);

  const columns: GridColDef[] = [
    { field: 'Id', headerName: '', hide: true },
    {
      field: 'Logo',
      headerName: '',
      align: 'center',
      width: 80,
      sortable: false,
      renderCell: params => {
        return (
          <div>
            <Avatar src={params.value} />
          </div>
        );
      },
    },
    { field: 'Name', headerName: t(COMPANY_INFORMATION__COMPANY_NAME), width: 200 },
    { field: 'CompanyIdNumber', headerName: t(COMPANY_INFORMATION__COMPANY_ID_NUMBER), width: 220 },
    {
      field: 'Address',
      headerName: t(GENERAL__ADDRESS),
      width: 300,
      valueGetter: params => {
        return getFormattedCompanyAddress(params.row.Address);
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <UPButton
              onClick={() => {
                onClickCompanyChange(params.row.Id);
              }}
              text="Login"
              outlined
            />
          );
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ height: '70vh', width: '100%' }}>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          withCheckboxes={false}
          getRowId={r => r.Id}
        />
      </Box>
    </SectionContainer>
  );
};

export default ChildCompaniesStructure;
