import { UPSwitchStyled } from './styles';
import { UPSwitchProps } from './types';

const UPSwitch = (props: UPSwitchProps): JSX.Element => {
  const { checked, onChange, disabled } = props;
  return (
    <UPSwitchStyled
      checked={checked}
      onChange={onChange}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      disabled={disabled}
    />
  );
};

export default UPSwitch;
