import { Backdrop } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import UPInput from 'components/input';
import theme from 'theme';
import { useEffect, useState, useMemo } from 'react';
import ILocation from 'types/company/locations/ILocation';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_INFORMATION__BUS_NUMBER,
  COMPANY_INFORMATION__CITY,
  COMPANY_INFORMATION__POSTAL_CODE,
  COMPANY_INFORMATION__STREET_NAME,
  COMPANY_INFORMATION__STREET_NUMBER,
  GENERAL__CANCEL_BUTTON,
  GENERAL__NAME,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
} from 'translations/constants';
import { useAppDispatch, useAppSelector } from 'store';
import { useParams } from 'react-router-dom';
import { postLocationAction, putLocationAction } from 'store/actions/company-location-actions';
import { allowOnlyPositiveNumber, getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import { countriesNames } from 'store/selectors/countries-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { getCountriesNamesAction } from 'store/actions/countries-actions';
import Lang from 'types/Lang';
import {
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModal,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalTitle,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalActionButton,
} from '../payroll/styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { EditLocationProps } from './types';
import { countriesWithBelgiumFirst } from '../helpers';

const LocationInitialState: ILocation = {
  id: 0,
  name: '',
  streetName: '',
  streetNumber: '',
  busNumber: '',
  city: '',
  postalCode: '',
  countryId: '',
};

const EditLocations = (props: EditLocationProps): JSX.Element => {
  const { open, onClose, location, title, isEdit } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const countriesNamesOptions = useAppSelector(countriesNames);
  const userLanguageId = useAppSelector(profileSelector)?.languageId;

  const [countries, setCountries] = useState<UPDropdownItem[]>([]);

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm<ILocation>({
    defaultValues: location || LocationInitialState,
  });

  useEffect(() => {
    if (userLanguageId) {
      const userLanguage = languageOptions.find(l => l.value === userLanguageId).label;
      dispatch(getCountriesNamesAction(userLanguage));
    } else {
      dispatch(getCountriesNamesAction('en'));
    }
  }, [dispatch, languageOptions, userLanguageId]);

  useEffect(() => {
    if (countriesNamesOptions) {
      const mappedLocationNames = countriesNamesOptions.map(item => ({ value: item.value, label: item.label }));
      const auxCountries = countriesWithBelgiumFirst(mappedLocationNames);
      setCountries(auxCountries);
    }
  }, [countriesNamesOptions]);

  const onSaveLocation: SubmitHandler<ILocation> = event => {
    const values = getValues();
    if (isDirty) {
      if (isEdit) {
        dispatch(putLocationAction({ companyId, location: values }));
      } else {
        dispatch(postLocationAction({ companyId, location: values }));
      }
    }
    onClose(event);
  };

  return (
    <Backdrop open={open}>
      <PayrollEditFunctionModal open={open} onClose={onClose} aria-labelledby="modal-edit-location">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>{title}</PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn sx={{ marginRight: '16px' }}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__NAME)}
                      label={t(GENERAL__NAME)}
                      required
                      error={!!errors.name}
                      helperText={getInputErrorText(errors, 'name', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="streetName"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__STREET_NAME)}
                      label={t(COMPANY_INFORMATION__STREET_NAME)}
                      required
                      error={!!errors.streetName}
                      helperText={getInputErrorText(errors, 'streetName', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="streetNumber"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value}
                      onChange={ev => onChange(allowOnlyPositiveNumber(ev.target.value))}
                      placeholder={t(COMPANY_INFORMATION__STREET_NUMBER)}
                      label={t(COMPANY_INFORMATION__STREET_NUMBER)}
                      required
                      error={!!errors.streetNumber}
                      helperText={getInputErrorText(errors, 'streetNumber', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="countryId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      value={field.value}
                      onChange={field.onChange}
                      label="Country"
                      placeholder={t(GENERAL__SELECT)}
                      required
                      error={!!errors.countryId}
                      helperText={getInputErrorText(errors, 'countryId')}
                      className="dropdown--white-background"
                      items={countries || [{ value: null, label: '' }]}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
            <PayrollEditFunctionModalColumn>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="busNumber"
                  control={control}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__BUS_NUMBER)}
                      label={t(COMPANY_INFORMATION__BUS_NUMBER)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__CITY)}
                      label={t(COMPANY_INFORMATION__CITY)}
                      required
                      error={!!errors.city}
                      helperText={getInputErrorText(errors, 'city', undefined, 100)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="postalCode"
                  control={control}
                  rules={{ required: true, maxLength: 100 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(COMPANY_INFORMATION__POSTAL_CODE)}
                      required
                      error={!!errors.postalCode}
                      helperText={getInputErrorText(errors, 'postalCode', undefined, 100)}
                      label={t(COMPANY_INFORMATION__POSTAL_CODE)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            <PayrollEditFunctionModalActionButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={event => handleSubmit(onSaveLocation)(event)}
            />
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

EditLocations.defaultProps = {
  location: {
    id: 0,
    name: '',
    streetName: '',
    streetNumber: '',
    busNumber: '',
    city: '',
    postalCode: '',
  },
  isEdit: true,
};

export default EditLocations;
