import { UPDropdown, UPMultipleSelectionDropdown } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import {
  COMPANY__COMPANY,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  GENERAL__MONTH,
  GENERAL__YEAR,
  REPORTING__HOUR_TYPES,
  SUPERADMINS,
} from 'translations/constants';
import { FilterItem, FiltersContainer } from '../styles';
import { BaseReportingFiltersProps } from '../types';

const ClientPerformanceFilters = (props: BaseReportingFiltersProps): JSX.Element => {
  const { filters, dropdownData, handleFilterChange } = props;

  const [t] = useTranslation();

  const {
    companiesDropdownItems,
    paritairComitesDropdownItems,
    superAdminsDropdownItems,
    yearsDropdownItems,
    monthsDropdownItems,
    hourTypesDropdownItems,
  } = dropdownData;

  const {
    selectedCompanies,
    selectedParitairComites,
    selectedSuperAdmins,
    selectedYear,
    selectedMonth,
    selectedHourType,
  } = filters;

  return (
    <>
      <FiltersContainer>
        <FilterItem>
          <UPMultipleSelectionDropdown
            items={companiesDropdownItems}
            value={selectedCompanies}
            dropdownLabel={t(COMPANY__COMPANY)}
            handleChange={event => handleFilterChange('selectedCompanies', event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <UPMultipleSelectionDropdown
            items={paritairComitesDropdownItems}
            value={selectedParitairComites}
            dropdownLabel={t(COMPANY_PAYROLL__PARITAIR_COMITE)}
            handleChange={event => handleFilterChange('selectedParitairComites', event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <UPMultipleSelectionDropdown
            items={superAdminsDropdownItems}
            value={selectedSuperAdmins}
            dropdownLabel={t(SUPERADMINS)}
            handleChange={event => handleFilterChange('selectedSuperAdmins', event.target.value)}
          />
        </FilterItem>
        <FilterItem>
          <UPDropdown
            items={hourTypesDropdownItems}
            onChange={e => handleFilterChange('selectedHourType', e.target.value)}
            value={selectedHourType}
            label={t(REPORTING__HOUR_TYPES)}
          />
        </FilterItem>
      </FiltersContainer>
      <FiltersContainer>
        <FilterItem>
          <UPDropdown
            items={yearsDropdownItems}
            required
            onChange={e => handleFilterChange('selectedYear', e.target.value)}
            value={selectedYear?.toString()}
            label={t(GENERAL__YEAR)}
            error={!selectedYear}
            helperText={!selectedYear ? 'This field is required' : ''}
          />
        </FilterItem>
        <FilterItem>
          <UPDropdown
            items={monthsDropdownItems}
            required
            onChange={e => handleFilterChange('selectedMonth', e.target.value)}
            value={selectedMonth?.toString()}
            label={t(GENERAL__MONTH)}
            error={!selectedMonth}
            helperText={!selectedMonth ? 'This field is required' : ''}
          />
        </FilterItem>
      </FiltersContainer>
    </>
  );
};

export default ClientPerformanceFilters;
