import UPInput from 'components/input';
import { ChangeEvent } from 'react';
import { IntervalHourBox, IntervalText } from './styles';
import { OvertimeLimitsFields, OvertimeLimitsProps } from './types';
import { onChangeHourValue } from './helpers';

const OvertimeLimits = (props: OvertimeLimitsProps) => {
  const { overtimeLimits, setOvertimeLimits, setShouldUpdate, setValidValues } = props;

  return (
    <>
      <IntervalHourBox>
        <IntervalText sx={{ width: '65%' }}>Daily limit</IntervalText>
        <UPInput
          sx={{ width: '35%' }}
          value={overtimeLimits?.dailyLimit}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHourValue(event, setOvertimeLimits, OvertimeLimitsFields.DAILY, setValidValues, setShouldUpdate)
          }
          placeholder="0"
          label=""
        />
      </IntervalHourBox>
      <IntervalHourBox>
        <IntervalText sx={{ width: '65%' }}>Weekly limit</IntervalText>
        <UPInput
          sx={{ width: '35%' }}
          value={overtimeLimits?.weeklyLimit}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            onChangeHourValue(event, setOvertimeLimits, OvertimeLimitsFields.WEEKLY, setValidValues, setShouldUpdate)
          }
          placeholder="0"
          label=""
        />
      </IntervalHourBox>
    </>
  );
};

export default OvertimeLimits;
