import { Box, styled } from '@mui/material';

export const CompanyCardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  height: '240px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#F5F5F5',
  },
  [theme.breakpoints.down(600)]: {
    height: '200px',
  },
}));

export const CompanyCardLocationContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '30%',
  textAlign: 'center',
  [theme.breakpoints.down(600)]: {
    display: 'none',
  },
}));

export const CompanyCardPhotoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '40%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down(600)]: {
    height: '50%',
  },
}));

export const CompanyCardNameContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '20%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  [theme.breakpoints.down(600)]: {
    height: '30%',
  },
}));
