import { RootState } from 'store';
import { IEmployeeGridDetails, IAddEditEmployee, IEmployeeTotals } from 'types/employee';
import ICompanyInvitationLink from 'types/employee/ICompanyInvitationLink';
import IEmployeeOnboardingDetails from 'types/employee/IEmployeeOnboardingDetails';
import IEmployeeWithWorkerclass from 'types/employee/IEmployeeWithWorkerclass';

export const saveEmployeeSuccessSelector = (state: RootState): boolean => state.employee.saveEmployeeSuccess;
export const saveEmployeeErrorSelector = (state: RootState): boolean => state.employee.saveEmployeeError;
export const employeesSelector = (state: RootState): IEmployeeGridDetails[] => state.employee.employees;
export const setEmployeesErrorSelector = (state: RootState): boolean => state.employee.setEmployeesError;
export const employeeSelector = (state: RootState): IAddEditEmployee => state.employee.employee;
export const employeeErrorSelector = (state: RootState): boolean => state.employee.getEmployeeError;
export const updateEmployeeSuccessSelector = (state: RootState): boolean => state.employee.updateEmployeeSuccess;
export const updateEmployeeErrorSelector = (state: RootState): boolean => state.employee.updateEmployeeError;
export const employeesWithWorkerclassSelector = (state: RootState): IEmployeeWithWorkerclass[] =>
  state.employee.employeesWithWorkerclass;
export const onboardingDetailsSelector = (state: RootState): IEmployeeOnboardingDetails =>
  state.employee.onboardingDetails;
export const onboardingSelector = (state: RootState): boolean => state.employee.onboarding;
export const employeesCountSelector = (state: RootState): IEmployeeTotals => state.employee.employeesCount;
export const employeesErrorSelector = (state: RootState): string => state.employee.employeeError;
export const numberOfEmployeesSelector = (state: RootState): number => state.employee.numberOfEmployees;

export const employeeCompanyEmployeeInvitationLinksSelector = (state: RootState): ICompanyInvitationLink[] =>
  state.employee.companyEmployeeInvitationLinks;

export const employeeCompanyEmployeeInvitationLinkErrorSelector = (state: RootState): string =>
  state.employee.companyEmployeeInvitationLinkError;

export const employeeCompanyEmployeeInvitationLinkSuccessSelector = (state: RootState): boolean =>
  state.employee.companyEmployeeInvitationLinkSuccess;

export const setInviteTokenValiditySelector = (state: RootState): boolean => state.employee.isInvitationTokenInvalid;

export const employeeLoadingSelector = (state: RootState): boolean => state.employee.loading;
