import { Backdrop } from '@mui/material';
import { UPDropdown } from 'components/dropdown';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CONTRACTS__PASTE_HERE, GENERAL__CANCEL_BUTTON, GENERAL__SELECT } from 'translations/constants';
import {
  CopyWeeklyModalStyled,
  CopyWeeklyModalTitle,
  ModalActionButton,
  ModalActionContainer,
  ModalContainer,
} from './styles';
import { CopyWeeklyModalProps } from './types';

const CopyWeeklyModal = (props: CopyWeeklyModalProps): JSX.Element => {
  const { open, onClose, items, onCopy, content } = props;

  const [t] = useTranslation();

  const [weekToCopy, setWeekToCopy] = useState<string>('');

  return (
    <Backdrop open={open} sx={{ zIndex: '1000' }}>
      <CopyWeeklyModalStyled open={open} onClose={onClose}>
        <ModalContainer>
          <CopyWeeklyModalTitle>{content}</CopyWeeklyModalTitle>
          <UPDropdown
            value={weekToCopy}
            onChange={event => {
              setWeekToCopy(event.target.value);
            }}
            placeholder={t(GENERAL__SELECT)}
            items={items}
            label=""
          />
          <ModalActionContainer>
            <ModalActionButton
              text={t(CONTRACTS__PASTE_HERE)}
              onClick={() => {
                onCopy(Number(weekToCopy));
              }}
            />
            <ModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </ModalActionContainer>
        </ModalContainer>
      </CopyWeeklyModalStyled>
    </Backdrop>
  );
};

export default CopyWeeklyModal;
