import { Box } from '@mui/material';
import UPButton from 'components/button';
import { useTranslation } from 'react-i18next';
import theme from 'theme';
import {
  AVAILABILITIES__CHANGES_NOT_SUBMITTED,
  AVAILABILITIES__SUBMIT_AVAILABILITIES,
  GENERAL__UNSAVED_CHANGES,
} from 'translations/constants';
import { ReactComponent as AvailabilityWarningIcon } from '../../../../assets/icons/AvailabilityWarningIcon.svg';
import { AvailabilitiesAlertStyled, AvailabilitiesBarText, SubmitAvailabilityButtonContainer } from './styles';

const AvailabilitiesGreenBar = (props: {
  onSubmit: () => void;
  isSubmissionNeeded: boolean;
  numberOfChanges: number;
}): JSX.Element => {
  const { onSubmit, isSubmissionNeeded, numberOfChanges } = props;

  const [t] = useTranslation();

  return (
    <AvailabilitiesAlertStyled severity={isSubmissionNeeded ? 'warning' : 'success'} icon={false}>
      {isSubmissionNeeded && (
        <AvailabilitiesBarText>
          <AvailabilityWarningIcon /> {t(AVAILABILITIES__CHANGES_NOT_SUBMITTED)}
          {numberOfChanges > 0 && (
            <Box sx={{ ml: theme.spacing(1) }}>{`(${numberOfChanges} ${t(GENERAL__UNSAVED_CHANGES)})`}</Box>
          )}
        </AvailabilitiesBarText>
      )}
      <SubmitAvailabilityButtonContainer>
        <UPButton text={t(AVAILABILITIES__SUBMIT_AVAILABILITIES)} onClick={onSubmit} disabled={!isSubmissionNeeded} />
      </SubmitAvailabilityButtonContainer>
    </AvailabilitiesAlertStyled>
  );
};

export default AvailabilitiesGreenBar;
