import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { UPDataGrid } from 'components/data-grid';
import { AlertStyled } from 'components/error-bar/styles';
import { t } from 'i18next';
import { toNumber } from 'lodash';
import { UPDataGridContainer } from 'modules/companies/payroll/wage-exemptions/styles';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { downloadArchivedContractsForCompanyAction } from 'store/actions/contract-actions';
import {
  COMPANY_INFORMATION_WEEKLY_STATUS,
  COMPANY_INFORMATION__ACTIVE_CONTRACT_COUNT,
  COMPANY_INFORMATION__COMPANY_NAME,
  COMPANY_INFORMATION__PAST_CONTRACT_COUNT,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  GENERAL__CLEAR_ALL,
  GENERAL__SELECTED,
  REPORTING__PAYROLL_OFFICER,
  REPORTING__TOTAL_CONTRACT_HOURS,
} from 'translations/constants';
import { WeeklyContractReportData } from 'types/reporting';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/DownloadGreen.svg';

const WeeklyContractCountReport = (props: {
  items: WeeklyContractReportData[];
  year: number;
  week: number;
  renderData: () => void;
}): JSX.Element => {
  const { items, year, week, renderData } = props;

  const dispatch = useAppDispatch();
  const [, setRowOnHover] = useState<number>();
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const columnDef: GridColDef[] = [
    { field: 'companyId', headerName: '', hide: true },
    {
      field: 'companyName',
      headerName: t(COMPANY_INFORMATION__COMPANY_NAME),
      width: 300,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return <Link to={`/contracts/${params.row.companyId}/${year}/${week}`}>{params.row.companyName}</Link>;
      },
    },
    {
      field: 'payrollOfficer',
      headerName: t(REPORTING__PAYROLL_OFFICER),
      width: 300,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'jointComission',
      headerName: t(COMPANY_PAYROLL__PARITAIR_COMITE),
      width: 300,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'activeContractCount',
      headerName: t(COMPANY_INFORMATION__ACTIVE_CONTRACT_COUNT),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pastContractCount',
      headerName: t(COMPANY_INFORMATION__PAST_CONTRACT_COUNT),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'totalHours',
      headerName: t(REPORTING__TOTAL_CONTRACT_HOURS),
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'status',
      headerName: t(COMPANY_INFORMATION_WEEKLY_STATUS),
      width: 100,
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const downloadData = useCallback(() => {
    const data: number[][] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const item of selectedRows) {
      const [firstPart, secondPart] = item.split('_');
      data.push([toNumber(firstPart), toNumber(secondPart)]);
    }

    dispatch(
      downloadArchivedContractsForCompanyAction({
        companyPcPairList: data,
        type: '',
        year,
        week,
      }),
    );

    setTimeout(() => {
      renderData();
    }, 2000);
  }, [dispatch, selectedRows, year, week, renderData]);

  return (
    <Box>
      <UPDataGridContainer sx={{ height: '100vh' }}>
        <UPDataGrid
          rows={items}
          columns={columnDef}
          setRowOnHover={setRowOnHover}
          withCheckboxes
          getRowId={r => `${r.companyId}_${r.paritaireComiteId}`}
          disableSelectionOnClick
          headerHeight={75}
          selectionModel={clearAll ? [] : selectedRows}
          onSelectionModelChange={(selectedItem: any) => {
            setSelectedRows(selectedItem);
            setClearAll(false);
          }}
          // useToolbar
        />
      </UPDataGridContainer>
      {selectedRows?.length > 0 && (
        <AlertStyled sx={{ color: '#5F6161', backgroundColor: '#DBEDE3' }} icon={false}>
          <div className="contracts-number">
            {selectedRows.length} {t(GENERAL__SELECTED).toLocaleLowerCase()} |
          </div>
          {items.length > 0 && <div className="contracs-number">{items.length} |</div>}
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            {t(GENERAL__CLEAR_ALL)}
          </button>
          <div className="icons-container">
            <button
              className="icon-container"
              type="button"
              onClick={() => {
                downloadData();
              }}
            >
              <DownloadIcon />
            </button>
          </div>
        </AlertStyled>
      )}
    </Box>
  );
};

export default WeeklyContractCountReport;
