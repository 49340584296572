import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { IContractData, IContractToEdit, IContractsCountPerWeek, PagedContractData } from 'modules/contracts/types';
import ContractFilters from 'types/contract/IContractFilters';
import { DimonaDetails } from 'types/contract/DimonaDetails';
import IConfirmContract from 'types/contract/IConfirmContract';
import IConfirmHours from 'types/contract/IConfirmHours';
import IContractRequest from 'types/contract/IContractRequest';
import IContractTotals from 'types/contract/IContractTotals';
import IValidateHours from 'types/contract/IValidateHours';
import IValidateResponse from 'types/contract/IValidateResponse';
import IWageData from 'types/contract/IWageData';
import moment from 'moment';
import { fromValidateResponseToValidateReadyForDisplay } from 'mappers/contracts-mapper';

export const saveContractRequest = async (props: {
  contract: IContractRequest;
  companyId: string;
}): Promise<number> => {
  const { companyId, contract } = props;
  const response: AxiosResponse<number> = await getApi().post(`Contract`, contract, { headers: { companyId } });
  return response.data;
};

export const saveContractSlotRequest = async (props: {
  contract: IContractRequest;
  companyId: string;
  quantity: number;
}): Promise<number> => {
  const { companyId, contract, quantity } = props;
  const response: AxiosResponse<number> = await getApi().post(
    `Contract/SaveContractSlot`,
    { contract, quantity },
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const validateContractRequest = async (props: {
  contract: IContractRequest;
  companyId: string;
}): Promise<IValidateResponse> => {
  const { companyId, contract } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().post(`Contract/Validate`, contract, {
    headers: { companyId },
  });
  return response.data;
};

export const validateContractSlotHoursRequest = async (props: {
  contract: IContractRequest;
  companyId: string;
}): Promise<IValidateResponse> => {
  const { companyId, contract } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().post(
    `Contract/ValidateContractSlotHours`,
    contract,
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const getWageRequest = async (props: { wageData: IWageData; companyId: string }): Promise<string> => {
  const { companyId, wageData } = props;
  const response: AxiosResponse<number> = await getApi().post(`Contract/GetMinimumWage`, wageData, {
    headers: { companyId },
  });
  return response.data.toString();
};

export const getContractForDateRequest = async (props: {
  contractDateId: number;
  companyId: string;
}): Promise<IConfirmContract> => {
  const { contractDateId, companyId } = props;
  const response: AxiosResponse<IConfirmContract> = await getApi().get(
    `Contract/GetContractByDateId/${contractDateId.toString()}`,
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const reopenConfirmationRequest = async (props: { contractDateId: number; companyId: number }): Promise<any> => {
  const { contractDateId, companyId } = props;
  const response: AxiosResponse<any> = await getApi().post(
    `Contract/ReopenConfirmation/${contractDateId.toString()}`,
    null,
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const confirmContractRequest = async (props: {
  contractHours: IConfirmHours;
  companyId: string;
}): Promise<IConfirmContract> => {
  const { contractHours, companyId } = props;
  const response: AxiosResponse<IConfirmContract> = await getApi().post(`Contract/Confirm`, contractHours, {
    headers: { companyId },
  });
  return response.data;
};

export const validateHoursOnConfirmContractRequest = async (props: {
  validateHours: IValidateHours;
  companyId: string;
}): Promise<IValidateResponse> => {
  const { companyId, validateHours } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().post(`Contract/ValidateHours`, validateHours, {
    headers: { companyId },
  });
  return fromValidateResponseToValidateReadyForDisplay(response.data);
};

export const validateHoursOnEditContractRequest = async (props: {
  contract: IContractToEdit;
  companyId: number;
}): Promise<IValidateResponse> => {
  const { contract, companyId } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().post(
    `Contract/ValidateHoursOnEditContract`,
    contract,
    { headers: { companyId } },
  );
  return response.data;
};

export const validateHoursOnMultipleConfirmContractRequest = async (props: {
  contractsData: any;
  companyId: number;
}): Promise<IValidateResponse> => {
  const { companyId, contractsData } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().post(
    `/Contract/ValidateHoursInBulk`,
    contractsData,
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const contractsMultipleConfirmationRequest = async (props: {
  contractsData: any;
  companyId: number;
}): Promise<IValidateResponse> => {
  const { companyId, contractsData } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().post(`/Contract/BulkConfirm`, contractsData, {
    headers: { companyId },
  });
  return response.data;
};

export const getContractsRequest = async (props: {
  companyId: number;
  filter: ContractFilters;
}): Promise<PagedContractData> => {
  const { companyId, filter } = props;
  let response: AxiosResponse<PagedContractData>;

  if (Number.isNaN(filter.year) || Number.isNaN(filter.week)) {
    return response.data;
  }

  if (filter.date) {
    filter.date = moment(new Date(filter.date)).format('DD/MM/YYYY');
  }

  if (!Number.isNaN(companyId) && companyId !== 0) {
    response = await getApi().post(`Contract/GetContractsByWeek`, filter, {
      headers: { companyId, 'Content-Type': 'application/json' },
    });
  } else {
    response = await getApi().post(`Contract/GetContractsByWeek`, filter, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  return response.data;
};

export const getContractsRequestForListView = async (props: {
  companyId: number;
  filter: ContractFilters;
}): Promise<IContractData[]> => {
  const { companyId, filter } = props;
  let response: AxiosResponse<IContractData[]>;

  if (Number.isNaN(filter.year) || Number.isNaN(filter.week)) {
    return response.data;
  }

  if (filter.date) {
    filter.date = moment(new Date(filter.date)).format('DD/MM/YYYY');
  }

  if (!Number.isNaN(companyId) && companyId !== 0) {
    response = await getApi().post(`Contract/GetContractsByWeekListView`, filter, {
      headers: { companyId, 'Content-Type': 'application/json' },
    });
  } else {
    response = await getApi().post(`Contract/GetContractsByWeekListView`, filter, {
      headers: { 'Content-Type': 'application/json' },
    });
  }

  return response.data;
};

export const getContractsOfAdjacentWeeksRequest = async (props: {
  companyId: number;
  year: number;
  weekNumber: number;
}): Promise<IContractToEdit[]> => {
  const { companyId, year, weekNumber } = props;
  const response: AxiosResponse<IContractToEdit[]> = await getApi().get(
    `Contract/GetContractsOfAdjacentWeeks/${year}/${weekNumber}/0/100`,
    {
      headers: { companyId },
    },
  );
  return response.data;
};

export const getCostEstimationRequest = async (props: {
  companyId: number;
  weekNumber: number;
  year: number;
}): Promise<IValidateResponse> => {
  const { companyId, weekNumber, year } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().get(
    `Contract/GetCostEstimation/${companyId}/${weekNumber}/${year}`,
  );
  return response.data;
};

export const editContractRequest = async (props: {
  companyId: number;
  contract: IContractToEdit;
}): Promise<IValidateResponse> => {
  const { companyId, contract } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().put(`Contract`, contract, {
    headers: { companyId, 'Content-Type': 'application/json' },
  });
  return response.data;
};

export const deleteContractsRequest = async (props: { companyId: number; contractsIds: number[] }): Promise<any> => {
  const { contractsIds, companyId } = props;
  const response: AxiosResponse<IValidateResponse> = await getApi().delete(`Contract`, {
    headers: {
      companyId,
      'Content-Type': 'application/json',
    },
    data: contractsIds,
  });
  return response.data;
};

export const getContractsCountRequest = async (props: {
  companyId?: number;
  year: number;
  weekNumber: number;
}): Promise<IContractTotals> => {
  const { companyId, year, weekNumber } = props;
  let response: AxiosResponse<IContractTotals>;
  if (companyId) {
    response = await getApi().get(`Contract/GetContractsCountByDate/${year}/${weekNumber}`, {
      headers: { companyId },
    });
  } else {
    response = await getApi().get(`Contract/GetContractsCountByDate/${year}/${weekNumber}`);
  }

  return response.data;
};

export const getContractsCountFromPreviousWeeksRequest = async (props: {
  companyId?: number;
  year: number;
  weekNumber: number;
  locationId: number;
}): Promise<IContractsCountPerWeek[]> => {
  const { companyId, year, weekNumber, locationId } = props;
  let response: AxiosResponse<IContractsCountPerWeek[]>;
  if (companyId) {
    response = await getApi().get(`Contract/GetContractsCountFromPreviousWeeks/${year}/${weekNumber}/${locationId}`, {
      headers: { companyId },
      data: locationId,
    });
  } else {
    response = await getApi().get(`Contract/GetContractsCountFromPreviousWeeks/${year}/${weekNumber}/${locationId}`);
  }
  return response.data;
};

export const downloadContractsRequest = async (props: { companyId: number; contractsData: any }): Promise<any> => {
  const { contractsData, companyId } = props;
  const response: AxiosResponse<number> = await getApi().post(`Contract/ExportContracts`, contractsData, {
    headers: { companyId },
    responseType: 'blob',
  });
  return response.data;
};

export const downloadArchivedContractsRequestForCompanies = async (props: {
  companyPcPairList: number[][];
  type: string; // type would be 'archived' but for sake of reusability we will define as string
  year: number;
  week: number;
}): Promise<any> => {
  const { companyPcPairList, type, year, week } = props;
  const CompanyPcPairs = companyPcPairList.map(([first, second]) => ({ Company: first, Pc: second }));
  const response: AxiosResponse<number> = await getApi().post(
    `Contract/ExportContracts`,
    { CompanyPcPairs, type, year, week },
    {
      // headers: { companyId },
      responseType: 'blob',
    },
  );
  return response.data;
};

export const getContractDimonaNumberRequest = async (props: {
  companyId: number;
  contractId: number;
}): Promise<DimonaDetails> => {
  const { companyId, contractId } = props;
  const response: AxiosResponse<DimonaDetails> = await getApi().get(`Contract/GetDimonaNumber/${contractId}`, {
    headers: { companyId },
  });
  return response.data;
};
