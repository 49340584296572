/* eslint-disable react/jsx-props-no-spreading */
import { Box, styled } from '@mui/material';
import AutocompleteWrapper from './AutocompleteWrapper';

const searchIconPath =
  "path('M5 8C6.65685 8 8 6.65685 8 5C8 3.34315 6.65685 2 5 2C3.34315 2 2 3.34315 2 5C2 6.65685 3.34315 8 5 8ZM5 10C7.76142 10 10 7.76142 10 5C10 2.23858 7.76142 0 5 0C2.23858 0 0 2.23858 0 5C0 7.76142 2.23858 10 5 10Z M9.70711 10.2929C10.0976 9.9024 10.7308 9.9024 11.1213 10.2929L12.7071 11.8787C13.0976 12.2692 13.0976 12.9024 12.7071 13.2929C12.3166 13.6834 11.6834 13.6834 11.2929 13.2929L9.70711 11.7071C9.31658 11.3166 9.31658 10.6834 9.70711 10.2929Z')";
export const UPSearchDropdownStyled = styled(AutocompleteWrapper)(({ theme }) => ({
  borderRadius: '4px',
  background: 'white',
  '& .MuiAutocomplete-inputRoot': {
    fontSize: '16px',
    '& input::placeholder': {
      color: theme.palette.secondary.main,
      opacity: '1',
    },
    '& .MuiAutocomplete-popupIndicatorOpen': {
      '& .MuiSvgIcon-root': {
        fillRule: 'evenodd',
        '& path': {
          d: searchIconPath,
        },
        marginRight: '-4px',
        marginTop: '6px',
      },
    },
    '& .MuiButtonBase-root.MuiAutocomplete-popupIndicatorOpen': {
      webkitTransform: {
        rotate: 'none',
      },
      transform: 'none',
    },
  },
}));

export const UIDropdownIconContainer = styled(Box)(() => ({
  padding: '0px 7px 2px 0px',
}));
