import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { postManagerRequest, putManagerRequest } from 'api/requests/company-requests';
import {
  COMPANY_MANAGER__GENERAL_LOADING,
  COMPANY_MANAGER__POST,
  COMPANY_MANAGER__PUT,
  COMPANY_MANAGER__SET_MANAGER,
  COMPANY_MANAGER__SET_MANAGER_ERROR,
} from 'store/constants';
import ICompanyManager from 'types/company/managers/ICompanyManager';
import { updateOneManagersAction } from './company-managers-actions';

export const setManagerRequestsLoadingAction = createAction<boolean>(COMPANY_MANAGER__GENERAL_LOADING);

export const setManagerAction = createAction<boolean>(COMPANY_MANAGER__SET_MANAGER);
export const setManagerErrorAction = createAction<boolean>(COMPANY_MANAGER__SET_MANAGER_ERROR);

export const putManagerAction = createAsyncThunk(
  COMPANY_MANAGER__PUT,
  async (props: { companyId: string; manager: ICompanyManager }, thunkApi) => {
    thunkApi.dispatch(setManagerRequestsLoadingAction(true));
    thunkApi.dispatch(setManagerErrorAction(false));
    try {
      await putManagerRequest(props);
      thunkApi.dispatch(setManagerAction(true));
      thunkApi.dispatch(updateOneManagersAction({ id: props.manager.id, changes: props.manager }));
    } catch (error) {
      thunkApi.dispatch(setManagerErrorAction(true));
    }
    thunkApi.dispatch(setManagerRequestsLoadingAction(false));
  },
);

export const postManagerAction = createAsyncThunk(
  COMPANY_MANAGER__POST,
  async (props: { companyId: string; manager: ICompanyManager }, thunkApi) => {
    thunkApi.dispatch(setManagerRequestsLoadingAction(true));
    thunkApi.dispatch(setManagerErrorAction(false));
    try {
      await postManagerRequest({ companyId: props.companyId, manager: props.manager });
      thunkApi.dispatch(setManagerAction(true));
    } catch (error) {
      thunkApi.dispatch(setManagerErrorAction(true));
    }
    thunkApi.dispatch(setManagerRequestsLoadingAction(false));
  },
);
