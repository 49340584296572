import { CircularProgress } from '@mui/material';
import { LoadingSpinnerContainer } from './styles';
import { ReactComponent as LoadingPersonIcon } from '../../assets/icons/LoadingPersonIcon.svg';

const UPLoadingSpinner = (): JSX.Element => {
  return (
    <LoadingSpinnerContainer>
      <LoadingPersonIcon />
      <CircularProgress size={100} />
    </LoadingSpinnerContainer>
  );
};

export default UPLoadingSpinner;
