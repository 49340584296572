import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { getParitairComiteSelectItemsByCompanyAction } from 'store/actions/paritair-comite-actions';
import { getFunctionDescriptions } from 'store/actions/company-wage-exemptions-actions';
import { functionsDescriptionSelector } from 'store/selectors/company-wage-exemptions-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import { paritairComiteByCompanySelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import {
  COMPANY_PAYROLL__ADD_NEW_FUNCTION_EXEMPTION,
  COMPANY_PAYROLL__DELETE_FUNCTION_EXEMPTION,
  COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION,
  COMPANY_PAYROLL__FUNCTION,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  COMPANY_PAYROLL__WAGE_EXEMPTION,
  COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO,
  GENERAL__SELECT,
} from 'translations/constants';
import { FunctionExemptionsGridProps } from '../types';
import DataGridEditInputCell from './DataGridEditInputCell';
import { arrayToString, findDuplicates } from './helpers';
import { UPDataGridContainer } from './styles';

const FunctionExemptionsGrid = (props: FunctionExemptionsGridProps): JSX.Element => {
  const { functionExemptions, setFunctionExemptions, setErrorMessage } = props;

  const [t] = useTranslation();
  const { companyId } = useParams();
  const dispatch = useDispatch();

  const userIsCompanyManager = useAppSelector(profileSelector).role === CompanyRole.COMPANY_MANAGER;
  const functions = useAppSelector(functionsDescriptionSelector);
  const paritairComites = useAppSelector(paritairComiteByCompanySelectItemsSelector);

  const [functionOptions, setFunctionOptions] = useState<UPDropdownItem[]>([]);
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();
  const [rowIsUpdated, setRowIsUpdated] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getFunctionDescriptions(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsByCompanyAction(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (functions) {
      const options: UPDropdownItem[] = [];
      functions.map(func => {
        options.push({ value: func.id.toString(), label: func.description });
        return null;
      });
      const updatedOptions = options.map(functionOption => {
        const functionExemption = functionExemptions.find(w => w.functionId.toString() === functionOption.value);
        return { ...functionOption, isDisabled: functionExemption !== undefined };
      });
      setFunctionOptions(updatedOptions);
    }
  }, [functionExemptions, functions]);

  useEffect(() => {
    if (rowIsUpdated) {
      const items = functionExemptions.map(w => ({ keyId: w.functionId, jointCommissionId: w.paritairComiteId }));

      const duplicates = findDuplicates(items);

      if (duplicates.length > 0) {
        setErrorMessage(
          `${t(COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION)} ${t(COMPANY_PAYROLL__FUNCTION)}: ${arrayToString(
            duplicates.map(w => ({
              keyId: functionOptions.find(item => item.value === w.keyId)?.label,
              jointCommissionId: paritairComites?.find(item => item.value === w.jointCommissionId)?.label,
            })),
          )}`,
        );
      } else if (functionExemptions.some(e => toNumber(e.wageExemption) <= 0)) {
        setErrorMessage(t(COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO));
      } else {
        setErrorMessage('');
      }

      setRowIsUpdated(false);
    }
  }, [functionExemptions, functionOptions, rowIsUpdated, paritairComites, setErrorMessage, t]);

  const getFunction = useCallback(
    functionId => {
      const functionData = functionOptions.find(pc => toNumber(pc.value) === functionId || pc.value === functionId);
      return functionData ? functionData.label : <span>{t(GENERAL__SELECT)}</span>;
    },
    [functionOptions, t],
  );

  const renderFunctionEditInputCell = useCallback(
    (params: any) => {
      /* eslint-disable react/jsx-props-no-spreading */
      return <DataGridEditInputCell {...params} dropdownOptions={functionOptions} />;
    },
    [functionOptions],
  );

  const updateFunctionExemption = useCallback(
    (id, field, value) => {
      let functionExemption = functionExemptions.find(w => w.id === id);
      const index = functionExemptions.indexOf(functionExemption);
      functionExemption = { ...functionExemption, [field]: value };
      const updatedList = [
        ...functionExemptions.slice(0, index),
        functionExemption,
        ...functionExemptions.slice(index + 1, functionExemptions.length),
      ];
      setFunctionExemptions(updatedList);
      setRowIsUpdated(true);
      return functionExemption;
    },
    [functionExemptions, setFunctionExemptions],
  );

  const onAddNewFunctionWageExemption = useCallback(() => {
    setFunctionExemptions([
      ...functionExemptions,
      { id: Math.random(), functionId: '', wageExemption: 0, paritairComiteId: '' },
    ]);
  }, [functionExemptions, setFunctionExemptions]);

  const onDeleteWageExemption = useCallback(() => {
    setFunctionExemptions([...functionExemptions.filter(c => c.id !== selectedRow)]);
    setSelectedRow(null);
    setRowIsUpdated(true);
  }, [functionExemptions, selectedRow, setFunctionExemptions]);

  const functionColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'functionId',
      headerName: t(COMPANY_PAYROLL__FUNCTION),
      width: 200,
      editable: !userIsCompanyManager,
      renderCell: params => {
        return getFunction(params.row.functionId);
      },
      renderEditCell: renderFunctionEditInputCell,
      valueSetter: params => {
        const updatedFunctionExemption = updateFunctionExemption(params.row.id, 'functionId', params.value);
        return updatedFunctionExemption;
      },
      sortable: false,
    },
    {
      field: 'paritairComiteId',
      headerName: t(COMPANY_PAYROLL__PARITAIR_COMITE),
      width: 200,
      renderCell: params => {
        return paritairComites?.find(item => item.value === params.row.paritairComiteId)?.label;
      },
      renderEditCell: params => {
        const found = paritairComites?.find((item: UPDropdownItem) => item.value === params.row.paritairComiteId);
        return (
          <DataGridEditInputCell
            {...params}
            value={found?.value}
            dropdownOptions={paritairComites}
            category="functionExemptions"
          />
        );
      },
      valueSetter: params => {
        const updatedFunctionExemption = updateFunctionExemption(params.row.id, 'paritairComiteId', params.value);
        return updatedFunctionExemption;
      },
      editable: !userIsCompanyManager,
      sortable: false,
    },
    {
      field: 'wageExemption',
      headerName: t(COMPANY_PAYROLL__WAGE_EXEMPTION),
      width: 200,
      type: 'number',
      editable: !userIsCompanyManager,
      valueSetter: params => {
        const updatedFunctionExemption = updateFunctionExemption(params.row.id, 'wageExemption', params.value);
        return updatedFunctionExemption;
      },
      sortable: false,
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          marginTop: '32px',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ marginBottom: '16px', marginRight: '16px' }}>
          <UPButton
            text={`+ ${t(COMPANY_PAYROLL__ADD_NEW_FUNCTION_EXEMPTION)}`}
            onClick={onAddNewFunctionWageExemption}
            disabled={userIsCompanyManager}
          />
        </Box>
        <Box sx={{ marginBottom: '16px' }}>
          <UPButton
            text={t(COMPANY_PAYROLL__DELETE_FUNCTION_EXEMPTION)}
            onClick={onDeleteWageExemption}
            disabled={userIsCompanyManager}
            outlined
          />
        </Box>
      </Box>
      <UPDataGridContainer>
        <UPDataGrid
          rows={functionExemptions}
          columns={functionColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selection: any) => setSelectedRow(selection[0])}
          withCheckboxes={false}
          getRowId={r => r.id}
        />
      </UPDataGridContainer>
    </Box>
  );
};
export default FunctionExemptionsGrid;
