import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { deleteVendorsAction, getVendorsAction } from 'store/actions/company-vendors-actions';
import { vendorsSelector } from 'store/selectors/company-vendors-selectors';
import theme from 'theme';
import {
  COMPANY_CONTACT__CONTACT_NAME,
  COMPANY_VENDORS__VENDOR_NAME,
  GENERAL__DELETE_SELECTED,
  GENERAL__EDIT,
  GENERAL__EMAIL,
  GENERAL__PHONE_NUMBER,
} from 'translations/constants';
import ICompanyVendor from 'types/company/managers/ICompanyVendor';
import { ReactComponent as VendorIcon } from '../../../assets/icons/VendorIcon.svg';
import { PayrollAddNewItemButton } from '../payroll/styles';
import { SectionContainer } from '../styles';
import AddEditVendorModal from './AddEditVendorModal';
import { VendorGridColumns } from './types';

const Vendors = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const vendors = useAppSelector(vendorsSelector);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [rowOnHover, setRowOnHover] = useState<number>();
  const [vendorToEdit, setVendorToEdit] = useState<ICompanyVendor>(null);
  const [vendorItems, setVendorItems] = useState<ICompanyVendor[]>([]);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  useEffect(() => {
    dispatch(getVendorsAction(companyId));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (vendors) {
      setVendorItems(vendors);
    }
  }, [vendors]);

  const deleteSelected = () => {
    dispatch(deleteVendorsAction({ companyId, vendorIds: selectedRows }));
  };

  const columns: GridColDef[] = [
    { field: VendorGridColumns.ID.toString(), headerName: '', hide: true },
    { field: VendorGridColumns.VENDOR_NAME, headerName: t(COMPANY_VENDORS__VENDOR_NAME), width: 400 },
    { field: VendorGridColumns.EMAIL, headerName: t(GENERAL__EMAIL), width: 200 },
    { field: VendorGridColumns.CONTACT_PERSON, headerName: t(COMPANY_CONTACT__CONTACT_NAME), width: 300 },
    { field: VendorGridColumns.PHONE_NUMBER, headerName: t(GENERAL__PHONE_NUMBER), width: 200 },
    {
      field: VendorGridColumns.ACTIONS,
      headerName: '',
      width: 200,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <UPButton
              text={t(GENERAL__EDIT)}
              onClick={() => {
                setOpenModal(true);
                setVendorToEdit(params.row);
              }}
              outlined
            />
          );
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer paddingTop={theme.spacing(2)}>
      <Box display="flex">
        <PayrollAddNewItemButton
          text={
            <Box display="flex" alignItems="center">
              <Typography> + </Typography>
              <VendorIcon />
            </Box>
          }
          onClick={() => {
            setOpenModal(true);
            setVendorToEdit(null);
          }}
        />
        <Box marginLeft={theme.spacing(2)}>
          <PayrollAddNewItemButton
            text={t(GENERAL__DELETE_SELECTED)}
            onClick={deleteSelected}
            disabled={selectedRows.length === 0}
            outlined
          />
        </Box>
      </Box>
      <Box sx={{ height: '70vh', width: '100%' }}>
        <UPDataGrid
          columns={columns}
          rows={vendorItems}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          onSelectionModelChange={onSelectionChange}
          disableSelectionOnClick
        />
      </Box>
      {openModal && <AddEditVendorModal onClose={() => setOpenModal(false)} vendor={vendorToEdit} />}
    </SectionContainer>
  );
};

export default Vendors;
