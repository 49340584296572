import { useEffect, useState } from 'react';
import { UPInputLabel } from 'components/input/styles';
import { Box, TextField } from '@mui/material';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { UPDropdownItem } from 'components/dropdown/types';
import { UPDropdownWithInputStyled } from './styles';
import { UPDropdownWithInputProps } from './types';

const UPDropdownWithInput = (props: UPDropdownWithInputProps): JSX.Element => {
  const {
    items,
    value,
    type,
    handleChange,
    placeholder,
    filterOptions,
    label,
    required,
    disabled,
    error,
    helperText,
    category,
    handleChangeWithEvent,
  } = props;

  const [inputValue, setInputValue] = useState<string>(value);
  const [dropdownItem, setDropdownItem] = useState<UPDropdownItem>(null);

  useEffect(() => {
    setInputValue(value);
    setDropdownItem({ value: `${Math.random()}`, label: value });
  }, [value]);

  const getOptionLabel = (option: any): string => {
    if (option instanceof Array && option.length > 0) {
      if (option[0]) {
        const first = option[0] as UPDropdownItem;

        return first?.label;
      }
    }

    if (option && !(option instanceof Array)) {
      return option.label;
    }

    return '';
  };

  const isOptionEqualToValue = (option: any, value2: any): boolean => {
    if (value2 instanceof Array && value2.length > 0) {
      const first = value2[0] as UPDropdownItem;

      return first?.value === option.value;
    }

    if (value2 && !(value2 instanceof Array)) {
      return value2.value === option.value;
    }

    return option.value === value2.value;
  };

  return (
    <Box sx={{ width: '100%' }}>
      {!category && (
        <UPInputLabel>
          {label} {required && '*'}
        </UPInputLabel>
      )}
      <UPDropdownWithInputStyled
        value={[dropdownItem]}
        options={items}
        onInputChange={(e, newValue) => {
          if (newValue === inputValue) return;
          setInputValue(newValue);
          const foundValue = items.find(i => i.label === newValue);
          if (foundValue) {
            setDropdownItem({ value: foundValue, label: newValue });
          } else {
            setDropdownItem({ value: `${Math.random()}`, label: newValue });
          }

          if (category && (newValue.length === 5 || newValue.length === 0)) handleChangeWithEvent(newValue, type, e);
          if (!category && (newValue.length === 5 || newValue.length === 0)) handleChange(newValue, type);
        }}
        filterOptions={filterOptions}
        isOptionEqualToValue={(option, value2) => isOptionEqualToValue(option, value2)}
        getOptionLabel={option => getOptionLabel(option)}
        forcePopupIcon
        disabled={disabled}
        freeSolo
        popupIcon={<ArrowDropDownIcon />}
        disableClearable
        renderInput={params => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder={placeholder}
            variant="outlined"
            error={error}
          />
        )}
      />
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
};

UPDropdownWithInput.defaultProps = {
  placeholder: 'Select',
  required: false,
  disabled: false,
  error: false,
  withSearchIcon: true,
};
export default UPDropdownWithInput;
