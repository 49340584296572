import { createReducer } from '@reduxjs/toolkit';
import {
  setVendorAction,
  setVendorRequestsLoadingAction,
  setVendorsAction,
  setVendorsErrorAction,
} from 'store/actions/company-vendors-actions';
import IVendorsState from 'types/company/managers/IVendorsState';

const initialState: IVendorsState = {
  vendors: [],
  vendor: null,
  loading: false,
  vendorsError: false,
};

const vendorsReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setVendorsAction, (state, action) => ({ ...state, vendors: action.payload }))
    .addCase(setVendorAction, (state, action) => ({ ...state, vendor: action.payload }))
    .addCase(setVendorRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setVendorsErrorAction, (state, action) => ({
      ...state,
      vendorsError: action.payload,
    }));
});

export default vendorsReducer;
