import { Box, styled } from '@mui/material';
import AutocompleteWrapper from './AutocompleteWrapper';

export const UPDropdownWithInputStyled = styled(AutocompleteWrapper)(({ theme }) => ({
  borderRadius: '4px',
  background: 'white',
  '& .MuiOutlinedInput-root.MuiInputBase-root': {
    paddingRight: '21px',
    paddingTop: '6px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  '.Mui-disabled': {
    backgroundColor: theme.palette.info.light,
    '.MuiSvgIcon-root': {
      fill: theme.palette.secondary.main,
      cursor: 'default',
    },
  },
}));

export const UIDropdownIconContainer = styled(Box)(() => ({
  padding: '0px 7px 2px 0px',
}));
