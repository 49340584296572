import { Box, styled } from '@mui/material';

export const AvailabilitiesCalendarRowContainer = styled(Box)<{ sixRowsCalendar?: boolean }>(
  ({ theme, sixRowsCalendar }) => ({
    display: 'flex',
    height: sixRowsCalendar ? '16.6%' : '20%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: '14%',
    },
  }),
);
