import { Autocomplete, Box, TextField } from '@mui/material';
import { UPDropdownItem } from 'components/dropdown/types';
import { UPInputLabel } from 'components/input/styles';
import { SyntheticEvent, useState } from 'react';
import './styles.scss';
import { UPMultipleSelectAutocompleteProps } from './types';

const UPMultipleSelectAutocomplete = (props: UPMultipleSelectAutocompleteProps): JSX.Element => {
  const { items, handleChange, placeholder, label, required, sx, defaultValue } = props;

  const [selectedOptions, setSelectedOptions] = useState<UPDropdownItem[]>(defaultValue || []);

  const getFilteredItems = () => {
    return items.filter(
      item =>
        !selectedOptions.some(option => option.value === item.value) ||
        defaultValue.some(
          option =>
            option.value === item.value && !selectedOptions.some(selectedOption => selectedOption.value === item.value),
        ),
    );
  };

  const handleSelectionChange = (event: SyntheticEvent<Element, Event>, value: UPDropdownItem[]) => {
    setSelectedOptions(value);
    handleChange(event, value);
  };

  return (
    <Box sx={sx}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <Autocomplete
        sx={{ lineHeight: '30px' }}
        multiple
        filterSelectedOptions
        options={getFilteredItems()}
        onChange={handleSelectionChange}
        placeholder={placeholder}
        getOptionLabel={option => option.label}
        defaultValue={defaultValue}
        // below to avoid duplication of options
        renderOption={(p, o: UPDropdownItem) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...p} key={o.value}>
            {o.label}
          </li>
        )}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={params => <TextField className="dynamic-height" {...params} placeholder={placeholder} />}
        disableCloseOnSelect
        // eslint-disable-next-line @typescript-eslint/no-shadow
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div key={option.value} {...getTagProps({ index })}>
              {option.label}
              {index < value.length - 1 ? ', ' : ''}
            </div>
          ))
        }
      />
    </Box>
  );
};

export default UPMultipleSelectAutocomplete;
