import './styles.scss';
import { AlertStyled } from './styles';
import { ErrorBarProps } from './types';
import { ReactComponent as WarningIcon } from '../../assets/icons/WarningIcon.svg';

const ErrorBar = (props: ErrorBarProps): JSX.Element => {
  const { message } = props;

  return (
    <AlertStyled severity="error" icon={false}>
      <WarningIcon className="error-warning-icon" />
      <div>{message}</div>
    </AlertStyled>
  );
};

ErrorBar.defaultProps = {
  message: '',
};

export default ErrorBar;
