import { Button, lighten, styled, ToggleButton, ToggleButtonGroup } from '@mui/material';

export const UPButtonStyled = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
}));

export const UPButtonStyledWithNoPadding = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
}));

export const UPButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  height: '49px',
  [theme.breakpoints.down(527)]: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const UPButtonGroupForEmployeeStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  height: '49px',
  [theme.breakpoints.down(527)]: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '80px',
  },
  [theme.breakpoints.down(447)]: {
    marginBottom: '100px',
  },
  [theme.breakpoints.down(295)]: {
    marginBottom: '171px',
  },
}));

export const UPToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: 'white',
    borderColor: theme.palette.secondary.contrastText,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.light, 0.6),
    },
  },
  '&.MuiToggleButtonGroup-grouped:not(:first-of-type).Mui-selected': {
    borderLeft: `1px solid ${theme.palette.secondary.contrastText}`,
  },
  '&:hover': {
    backgroundColor: lighten(theme.palette.secondary.light, 0.6),
  },
  '&.Mui-error': {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

export const BrowseDocumentsButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  [theme.breakpoints.up(1000)]: {
    padding: '10px 24px',
  },
}));

export const UPButtonHoverStyled = styled(Button)(({ theme }) => ({
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  '&:hover': {
    backgroundColor: lighten(theme.palette.secondary.light, 0.6),
  },
}));
