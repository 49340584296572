import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { NavigationDirection } from 'constants/NavigationDirection';
import { CompanyAvailabilityCalendarHeaderProps } from 'modules/availabilities/company-availability-2/types';
import { IDayProps } from 'modules/contracts/types';
import { getWeekDaysAbbreviations } from 'utils/helpers';
import CompanyAvailabilityCalendarHeaderCell from './cell';
import { CalendarHeaderBody, CalendarHeaderContainer, LeftArrowContainer, RightArrowContainer } from './styles';

const CompanyAvailabilityCalendarHeader = (props: CompanyAvailabilityCalendarHeaderProps): JSX.Element => {
  const { days, onChangeWeek, availabilityCountByDay } = props;

  const weekDaysNames = getWeekDaysAbbreviations();

  const getDayNames = (): IDayProps[] => {
    return days.map((d: IDayProps, index: number) => {
      return { ...d, dayOfWeek: weekDaysNames[index], numberOfItems: availabilityCountByDay[index] };
    });
  };

  return (
    <CalendarHeaderContainer>
      <CalendarHeaderBody>
        <LeftArrowContainer>
          <KeyboardArrowLeft onClick={() => onChangeWeek(NavigationDirection.PREVIOUS)} />
        </LeftArrowContainer>
        {getDayNames().map((day: IDayProps) => (
          <CompanyAvailabilityCalendarHeaderCell key={day.dayOfWeek} day={day} year={day.date.getFullYear()} />
        ))}
        <RightArrowContainer>
          <KeyboardArrowRight onClick={() => onChangeWeek(NavigationDirection.NEXT)} />
        </RightArrowContainer>
      </CalendarHeaderBody>
    </CalendarHeaderContainer>
  );
};

export default CompanyAvailabilityCalendarHeader;
