import { createReducer } from '@reduxjs/toolkit';
import {
  addPayrollErrorAction,
  addPayrollSuccessAction,
  clearCompanyPayrollAction,
  getPayrollErrorAction,
  setPayrollAction,
  setPayrollRequestsLoadingAction,
  updatePayrollErrorAction,
  updatePayrollSuccessAction,
} from 'store/actions/payroll-actions';
import IPayrollState from 'types/company/payroll/IPayrollState';

const initialState: IPayrollState = {
  payroll: null,
  getPayrollError: false,
  addPayrollSuccess: false,
  addPayrollError: false,
  loading: false,
  updatePayrollSuccess: false,
  updatePayrollError: false,
};

const payrollReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setPayrollAction, (state, action) => ({ ...state, payroll: action.payload }))
    .addCase(setPayrollRequestsLoadingAction, (state, action) => ({ ...state, loading: action.payload }))
    .addCase(getPayrollErrorAction, (state, action) => ({ ...state, getPayrollError: action.payload }))
    .addCase(addPayrollSuccessAction, (state, action) => ({ ...state, addPayrollSuccess: action.payload }))
    .addCase(addPayrollErrorAction, (state, action) => ({ ...state, addPayrollError: action.payload }))
    .addCase(updatePayrollSuccessAction, (state, action) => ({ ...state, updatePayrollSuccess: action.payload }))
    .addCase(updatePayrollErrorAction, (state, action) => ({ ...state, updatePayrollError: action.payload }))
    .addCase(clearCompanyPayrollAction, state => ({
      ...state,
      payroll: null,
      getPayrollError: false,
      addPayrollSuccess: false,
      addPayrollError: false,
      loading: false,
      updatePayrollSuccess: false,
      updatePayrollError: false,
    }));
});

export default payrollReducer;
