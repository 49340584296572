import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { SectionContainer } from 'modules/companies/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import './styles.scss';
import {
  BLOGS__DESCRIPTION,
  BLOGS__TITLE,
  BLOGS__URL,
  CONTRACTS__ARE_YOU_SURE,
  DOCUMENTS__DATE,
} from 'translations/constants';
import { deleteBlogAction } from 'store/actions/blog-actions';
import { ReactComponent as DeleteIcon } from '../../assets/icons/DeleteGrey.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/EditGrey.svg';
import { generateLimitedString } from './helpers';
import { BlogsGridViewProps, UPBlogWithNoDetails } from './types';

const BlogsGridView = (props: BlogsGridViewProps): JSX.Element => {
  const { blogs, onEdit } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [idToDelete, setIdToDelete] = useState<number>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const generateBlogsForGrid = (allBlogs: UPBlogWithNoDetails[]) => {
    const auxBlogs = allBlogs.map((blog: UPBlogWithNoDetails) => {
      return {
        ...blog,
        title: generateLimitedString(blog.title, 40),
        description: generateLimitedString(blog.description, 40),
        url: generateLimitedString(blog.url, 20),
      };
    });
    return auxBlogs;
  };

  const onDeleteBlog = (id: number) => {
    dispatch(deleteBlogAction({ id, type: 'delete' }));
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'noName', headerName: '', width: 50, sortable: false },
    { field: 'date', headerName: t(DOCUMENTS__DATE), width: 100 },
    { field: 'title', headerName: t(BLOGS__TITLE), width: 350 },
    { field: 'description', headerName: t(BLOGS__DESCRIPTION), width: 350, sortable: false },
    { field: 'url', headerName: t(BLOGS__URL), width: 200, sortable: false },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return (
            <div>
              <EditIcon className="blogsIcon" onClick={() => onEdit(rowOnHover)} />
              <DeleteIcon
                onClick={() => {
                  setIdToDelete(rowOnHover);
                  setOpenDeleteModal(true);
                }}
                className="blogsIcon"
              />
            </div>
          );
        }
        return null;
      },
    },
  ];

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ height: '100vh', width: '100%' }}>
        <UPDataGrid
          rows={generateBlogsForGrid(blogs)}
          columns={columns}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          withCheckboxes={false}
        />
      </Box>
      {openDeleteModal && (
        <DeleteModal
          content={t(CONTRACTS__ARE_YOU_SURE)}
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onDelete={() => {
            onDeleteBlog(idToDelete);
          }}
        />
      )}
    </SectionContainer>
  );
};

export default BlogsGridView;
