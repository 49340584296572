import { UPDropdownItem } from 'components/dropdown';
import { ICostDeclaration } from 'types/cost-declaration';

export interface CostDeclarationModalProps {
  open: boolean;
  onClose: () => void;
  isEdit: boolean;
  costDeclarationToEdit?: ICostDeclaration;
  PCOptions: UPDropdownItem[];
}

export enum CostDeclarationStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
