import { createReducer } from '@reduxjs/toolkit';
import {
  setSuperAdminAction,
  setSuperAdminRequestsLoadingAction,
  setSuperAdminErrorAction,
} from 'store/actions/superadmin-actions';
import ISuperAdminState from 'types/superadmin/ISuperAdminState';

const initialState: ISuperAdminState = {
  superAdmin: false,
  loading: false,
  superAdminError: false,
  apiUserError: false,
  apiUser: false,
};

const superAdminReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setSuperAdminAction, (state, action) => ({ ...state, superAdmin: action.payload }))
    .addCase(setSuperAdminRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setSuperAdminErrorAction, (state, action) => ({
      ...state,
      superAdminError: action.payload,
    }));
});

export default superAdminReducer;
