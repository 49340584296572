import { Backdrop, Box, Stack, Typography } from '@mui/material';
import { UPDropdown, UPDropdownItem } from 'components/dropdown';
import UPInput from 'components/input';
import UPSwitch from 'components/switch';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import UPMultiselectDropdown from 'components/app-container/multiselect-dropdown';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { postManagerAction, putManagerAction } from 'store/actions/company-manager-actions';
import { getManagersAction } from 'store/actions/company-managers-actions';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  COMPANY_INFORMATION__ACTIVE,
  COMPANY_INFORMATION__NON_ACTIVE,
  COMPANY_MANAGERS__LOCATION,
  COMPANY_MANAGERS__ROLE,
  GENERAL__CANCEL_BUTTON,
  GENERAL__EMAIL,
  GENERAL__FIRST_NAME,
  GENERAL__LANGUAGE,
  GENERAL__LAST_NAME,
  GENERAL__PHONE_NUMBER,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  PERMISSIONS_HIDE_WAGES,
  PERMISSIONS_SHOW_WAGES,
  VALIDATION__IS_REQUIRED,
} from 'translations/constants';
import Lang from 'types/Lang';
import ICompanyManager from 'types/company/managers/ICompanyManager';
import { getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum, getUserRolesWithTranslation } from 'utils/helpers';
import { UPDropdownHelperText } from 'components/dropdown/styles';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  PayrollEditFunctionModal,
  PayrollEditFunctionModalActionButton,
  PayrollEditFunctionModalActionContainer,
  PayrollEditFunctionModalCloseIcon,
  PayrollEditFunctionModalColumn,
  PayrollEditFunctionModalContainer,
  PayrollEditFunctionModalInputBox,
  PayrollEditFunctionModalInputContainer,
  PayrollEditFunctionModalTitle,
} from '../payroll/styles';
import { EditManagerProps } from './types';

const ManagerInitialState: ICompanyManager = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  languageId: '',
  roleId: '',
  roleName: '',
  locations: [],
  phoneNumber: '',
  active: true,
  isInvitation: false,
  showWages: true,
};

const EditManagers = (props: EditManagerProps): JSX.Element => {
  const { open, onClose, manager, title, isEdit } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const userRole = useAppSelector(profileSelector).role;
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);

  const [roleId, setRoleId] = useState<string>(manager.roleId);
  const [roleError, setRoleError] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<boolean>(false);
  const [managerLocations, setManagerLocations] = useState<UPDropdownItem[]>([]);
  const locations = useMemo(() => locationsDropdownItems, [locationsDropdownItems]);

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    formState: { errors, isDirty },
  } = useForm<ICompanyManager>({
    defaultValues: ManagerInitialState,
  });

  const canEdit = userRole === CompanyRole.ADMIN || !isEdit;
  const isClientManager = userRole === 'Company manager';
  const canEditEvenAsManager = isClientManager || userRole === 'Admin';
  const rolesOptions: UPDropdownItem[] = getUserRolesWithTranslation();
  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  useEffect(() => {
    if (manager.id !== 0 && locations?.length > 0) {
      reset(manager);
      const l = locations.filter(x => manager.locations.includes(x.value.toString()));
      setManagerLocations(l);
    }
  }, [manager, locations, reset]);

  const onChangeRole = useCallback(
    event => {
      setRoleId(event.target.value);
      setRoleError(false);
      setLocationError(false);
      setManagerLocations([]);
      setValue('locations', [], {
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const onError = useCallback((err, e) => {
    console.log(err, e);
  }, []);

  const onSaveManager: SubmitHandler<ICompanyManager> = event => {
    const values = getValues();

    let hasError = false;
    if (roleId === '') {
      setRoleError(true);
      hasError = true;
    } else {
      setRoleError(false);
    }
    if (roleId !== values.roleId && roleId !== '') {
      values.roleId = roleId;
      if (values.roleId !== '') {
        values.roleName = rolesOptions.find(x => x.value === values.roleId).label;
      }
    }

    if (values.roleId !== '2') {
      values.locations = managerLocations.map(x => x.value.toString());
    }

    if ((roleId === '2' || roleId === '3') && values.locations.length === 0) {
      setLocationError(true);
      hasError = true;
    } else {
      setLocationError(false);
    }

    if (hasError) {
      return;
    }

    if (isEdit) {
      dispatch(putManagerAction({ companyId, manager: values })).then(() =>
        dispatch(getManagersAction(toNumber(companyId))),
      );
    } else {
      dispatch(postManagerAction({ companyId, manager: values })).then(() =>
        dispatch(getManagersAction(toNumber(companyId))),
      );
    }
    onClose(event);
  };

  const onManagerLocationsChange = useCallback(
    data => {
      if (data) {
        const arrayOfIds = data.map((v: UPDropdownItem) => v.value) as [];
        setManagerLocations(data);
        setLocationError(false);
        setValue('locations', arrayOfIds, {
          shouldDirty: true,
        });
      } else {
        setValue('locations', [], {
          shouldDirty: true,
        });
        setManagerLocations([]);
        setLocationError(true);
      }
    },
    [setValue],
  );

  const getDefaultLocationValues = (selectedLocations: string[]): UPDropdownItem[] => {
    if (!selectedLocations || selectedLocations.length === 0) return [];
    const f = locations
      .filter(item => selectedLocations.includes(item.value.toString()))
      .map(item => ({ value: item.value, label: item.label }));
    return f;
  };

  const onSingleManagerLocationsChange = useCallback(
    event => {
      const {
        target: { value },
      } = event;
      if (typeof value === 'string') {
        const arrayOfValues = value.split(',');
        const arrayOfIds = arrayOfValues.map((v: string) => v) as [];
        setValue('locations', arrayOfIds, { shouldDirty: true });
      } else if (typeof value === 'number') {
        setValue('locations', [value], { shouldDirty: true });
      } else {
        const arrayOfIds = value.map((v: string) => v) as [];
        setValue('locations', arrayOfIds, {
          shouldDirty: true,
        });
      }
      setLocationError(false);
    },
    [setValue],
  );

  return (
    <Backdrop open={open}>
      <PayrollEditFunctionModal open={open} onClose={onClose} aria-labelledby="modal-edit-payroll-function">
        <PayrollEditFunctionModalContainer>
          <PayrollEditFunctionModalTitle>{title}</PayrollEditFunctionModalTitle>
          <PayrollEditFunctionModalCloseIcon onClick={onClose}>
            <CloseIcon fill={theme.palette.secondary.contrastText} />
          </PayrollEditFunctionModalCloseIcon>
          <PayrollEditFunctionModalInputContainer>
            <PayrollEditFunctionModalColumn sx={{ marginRight: '16px' }}>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__FIRST_NAME)}
                      label={t(GENERAL__FIRST_NAME)}
                      required
                      error={!!errors.firstName}
                      helperText={getInputErrorText(errors, 'firstName', undefined, 50)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true, maxLength: 50 }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__LAST_NAME)}
                      label={t(GENERAL__LAST_NAME)}
                      required
                      error={!!errors.lastName}
                      helperText={getInputErrorText(errors, 'lastName', undefined, 50)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: true, maxLength: 50, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__EMAIL)}
                      label={t(GENERAL__EMAIL)}
                      required
                      error={!!errors.email}
                      helperText={getInputErrorText(errors, 'email', undefined, 50)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 50,
                    pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                  }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t(GENERAL__PHONE_NUMBER)}
                      label={t(GENERAL__PHONE_NUMBER)}
                      required
                      error={!!errors.phoneNumber}
                      helperText={getInputErrorText(errors, 'phoneNumber', undefined, 50)}
                      disabled={!canEdit}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
              <PayrollEditFunctionModalInputBox>
                <Controller
                  name="languageId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <UPDropdown
                      items={languageOptions}
                      onChange={field.onChange}
                      value={field.value}
                      label={t(GENERAL__LANGUAGE)}
                      className="company-information-input--margin"
                      required
                      error={!!errors.languageId}
                      helperText={getInputErrorText(errors, 'languageId', undefined)}
                      disabled={!canEdit}
                      placeholder={t(GENERAL__SELECT)}
                    />
                  )}
                />
              </PayrollEditFunctionModalInputBox>
            </PayrollEditFunctionModalColumn>
            <PayrollEditFunctionModalColumn>
              <PayrollEditFunctionModalInputBox>
                <UPDropdown
                  items={rolesOptions}
                  onChange={onChangeRole}
                  value={roleId}
                  label={t(COMPANY_MANAGERS__ROLE)}
                  className="company-information-input--margin"
                  required
                  error={roleError}
                  helperText={t(VALIDATION__IS_REQUIRED)}
                  disabled={!canEditEvenAsManager}
                  placeholder={t(GENERAL__SELECT)}
                />
              </PayrollEditFunctionModalInputBox>
              {roleId === '3' && ( // HR MANAGER
                <PayrollEditFunctionModalInputBox>
                  <Controller
                    name="locations"
                    control={control}
                    render={({ field }) => (
                      <UPMultiselectDropdown
                        handleChange={v => {
                          onManagerLocationsChange(v);
                          field.onChange();
                        }}
                        items={locations}
                        required
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        label={t(COMPANY_MANAGERS__LOCATION)}
                        defaultValue={manager?.id > 0 ? getDefaultLocationValues(field.value) : []}
                        disabled={locations.length === 0 || !canEditEvenAsManager}
                        placeholder={locations.length === 0 ? 'No locations available' : t(COMPANY_MANAGERS__LOCATION)}
                        error={!!errors.locations}
                      />
                    )}
                  />
                  {locationError && <UPDropdownHelperText>This field is required.</UPDropdownHelperText>}
                </PayrollEditFunctionModalInputBox>
              )}
              {roleId === '2' && ( // STORE LOCATION MANAGER
                <PayrollEditFunctionModalInputBox>
                  <Controller
                    name="locations"
                    control={control}
                    // rules={{ required: true }}
                    render={({ field }) => (
                      <UPDropdown
                        items={locations}
                        required
                        onChange={onSingleManagerLocationsChange}
                        value={field.value.length > 0 ? field.value.at(0) : ''}
                        label={t(COMPANY_MANAGERS__LOCATION)}
                        placeholder={locations.length === 0 ? 'No locations available' : t(COMPANY_MANAGERS__LOCATION)}
                        className="company-information-input--margin"
                        disabled={locations.length === 0 || !canEditEvenAsManager}
                        error={!!errors.locations}
                      />
                    )}
                  />
                  {locationError && <UPDropdownHelperText>This field is required.</UPDropdownHelperText>}
                </PayrollEditFunctionModalInputBox>
              )}
              <Controller
                name="active"
                control={control}
                render={({ field }) => (
                  <Stack direction="row" spacing={1} alignItems="center" pt="25px">
                    <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__NON_ACTIVE)}</Typography>
                    <UPSwitch checked={field.value} onChange={field.onChange} disabled={!canEdit} />
                    <Typography sx={{ fontSize: '14px' }}>{t(COMPANY_INFORMATION__ACTIVE)}</Typography>
                  </Stack>
                )}
              />
              {(roleId === '2' || roleId === '3') && (
                <Controller
                  name="showWages"
                  control={control}
                  render={({ field }) => (
                    <Stack direction="row" spacing={1} alignItems="center" pt="25px">
                      <Typography sx={{ fontSize: '14px' }}>{t(PERMISSIONS_HIDE_WAGES)}</Typography>
                      <UPSwitch checked={field.value} onChange={field.onChange} disabled={!canEdit} />
                      <Typography sx={{ fontSize: '14px' }}>{t(PERMISSIONS_SHOW_WAGES)}</Typography>
                    </Stack>
                  )}
                />
              )}
            </PayrollEditFunctionModalColumn>
          </PayrollEditFunctionModalInputContainer>
          <PayrollEditFunctionModalActionContainer>
            {(canEdit || isClientManager) && (
              <PayrollEditFunctionModalActionButton
                text={t(GENERAL__SAVE_BUTTON)}
                onClick={event => handleSubmit(onSaveManager, onError)(event)}
              />
            )}
            <PayrollEditFunctionModalActionButton text={t(GENERAL__CANCEL_BUTTON)} outlined onClick={onClose} />
          </PayrollEditFunctionModalActionContainer>
        </PayrollEditFunctionModalContainer>
      </PayrollEditFunctionModal>
    </Backdrop>
  );
};

EditManagers.defaultProps = {
  manager: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    languageId: '',
    functionId: '',
    function: '',
    locations: [],
    roleId: '',
    roleName: '',
  },
  isEdit: true,
};
export default EditManagers;
