import { Box, styled } from '@mui/system';

export const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down(690)]: {
    flexDirection: 'column',
    width: 'unset',
    alignItems: 'start',
  },
}));

export const FilterItem = styled(Box)(({ theme }) => ({
  width: '300px',
  display: 'inline-block',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
  },
}));

export const SmallFilterItem = styled(Box)(({ theme }) => ({
  width: '100px',
  display: 'inline-block',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
  },
}));

export const RangeFilterItem = styled(Box)(({ theme }) => ({
  width: '250px',
  display: 'inline-block',
  marginLeft: '12px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
  },
}));

export const CheckBoxFilterItem = styled(Box)(({ theme }) => ({
  width: '500px',
  display: 'inline-block',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
  },
}));
