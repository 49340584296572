/* eslint-disable react/jsx-props-no-spreading */
import { Backdrop } from '@mui/material';
import { Box } from '@mui/system';
import UPButton from 'components/button';

import { BrowseDocumentsButton } from 'components/button/styles';
import DeleteModal from 'components/delete-modal';
import { UPDropdownItem } from 'components/dropdown';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { saveDocumentsAction } from 'store/actions/documents-action';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  CONTRACTS__ARE_YOU_SURE,
  DOCUMENTS__ADD_DOCUMENTS,
  DOCUMENTS__ADD_NEW_DOCUMENT,
  DOCUMENTS__BROWSE,
  DOCUMENTS__DRAG_AND_DROP_FILES,
  DOCUMENTS__SHARED_FILES,
  GENERAL__CANCEL_BUTTON,
} from 'translations/constants';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import AddNewDocumentRow from './AddNewDocumentRow';
import { generateDocumentsToSend, isDateValid, isPeriodValid, needsPeriod } from './helpers';
import {
  DragAndDropParagraph,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalRowContainer,
  ModalRowDetailsContainer,
  ModalTitle,
  ModalWrapper,
} from './styles';
import './styles.scss';
import { AddNewDocumentModalProps, Document, DocumentCategories } from './types';

const AddNewDocumentModal = (props: AddNewDocumentModalProps): JSX.Element => {
  const { onClose, companyId, categories, category } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const [documents, setDocuments] = useState<Document[]>([]);
  const [canAddFile, setCanAddFile] = useState<boolean>(true);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const companyEmployees = useAppSelector(employeesWithWorkerclassSelector);
  const userRole = useAppSelector(profileSelector).role;
  const isEmployee = userRole === CompanyRole.EMPLOYEE;
  const [employeesDropdownItems, setEmployeeDropdownItems] = useState<UPDropdownItem[]>([]);

  const [addCategories, setAddCategories] = useState<UPDropdownItem[]>([]);
  const canAddSharedFile = userRole === CompanyRole.ADMIN || userRole === CompanyRole.COMPANY_MANAGER;

  const onDropFiles = useCallback(
    (accFiles: any) => {
      const newDocuments: Document[] = [...documents];
      if (accFiles.length > 0) {
        accFiles.forEach(file => {
          newDocuments.push({
            file,
            id: newDocuments.length,
            name: (file as any).path,
            date: null,
            type: null,
            error: {
              dateError: false,
              typeError: false,
              invalidDate: false,
            },
          });
        });

        setDocuments(newDocuments);
      }
    },
    [documents],
  );

  const checkIfTheLastRowIsCompleted = (row: Document) => {
    if (!row.date || !row.type || row.error.invalidDate || row.error.dateError || row.error.typeError) return false;
    return true;
  };

  useEffect(() => {
    if (documents.length > 0) setCanAddFile(checkIfTheLastRowIsCompleted(documents[documents.length - 1]));
    else setCanAddFile(true);
  }, [canAddFile, documents]);

  useEffect(() => {
    if (!canAddSharedFile) {
      const finalCategories = categories.filter(c => Number(c.value) !== Number(DocumentCategories.SHARED_FILES));
      setAddCategories(finalCategories);
    } else setAddCategories(categories);
  }, [canAddSharedFile, categories, t]);

  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    },
    noDrag: !canAddFile || openConfirmModal,
    disabled: !canAddFile || openConfirmModal,
    multiple: false,
    onDrop: accFiles => onDropFiles(accFiles),
    preventDropOnDocument: false,
  });

  const onDeleteRow = (index: number) => {
    const newDocuments = documents.filter((doc: Document, i: number) => i !== index);
    setDocuments(newDocuments);
  };

  const onChangeValue = (index: number, value: any | Date, key: string) => {
    const newDocuments = documents.map((doc: Document, i: number) => {
      if (i === index) {
        const errorKey = key === 'type' ? 'typeError' : 'dateError';
        let invalidDateError = false;
        if (key === 'date' && !isDateValid(value)) invalidDateError = true;

        return {
          ...doc,
          [key]: value,
          error: {
            ...doc.error,
            [errorKey]: value === true,
            invalidDate: invalidDateError,
          },
        };
      }
      return doc;
    });
    setDocuments(newDocuments);
  };

  const handleSubmit = () => {
    let completed = true;
    const newDocuments: Document[] = documents.map((d: Document) => {
      if ((!d.date && !d.type) || !d.date || !d.type || d.error.invalidDate || d.date.getFullYear() < 2000) {
        completed = false;
      }

      if (!d.date && !d.type) {
        return {
          ...d,
          error: {
            ...d.error,
            typeError: true,
            dateError: true,
          },
        };
      }
      if (!d.type) {
        return {
          ...d,
          error: {
            ...d.error,
            typeError: true,
          },
        };
      }

      if (!d.date) {
        return {
          ...d,
          error: {
            ...d.error,
            dateError: true,
          },
        };
      }
      if (needsPeriod(d.type) && (!d.periodStart || !d.periodEnd)) {
        completed = false;
        return {
          ...d,
          error: {
            ...d.error,
            periodError: true,
          },
        };
      }
      if (!isPeriodValid(d.periodStart, d.periodEnd, d.type)) {
        completed = false;
        return {
          ...d,
          error: {
            ...d.error,
            periodError: true,
            academicPeriodError: d.type === 6,
          },
        };
      }

      return d;
    });

    if (completed) {
      dispatch(
        saveDocumentsAction({
          companyId,
          documents: generateDocumentsToSend(documents),
          category,
        }),
      );
      setOpenConfirmModal(false);
      onClose();
    } else setDocuments(newDocuments);
  };

  useEffect(() => {
    if (companyId) dispatch(getEmployeesWithWorkerclass(toNumber(companyId)));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyEmployees) {
      const items: UPDropdownItem[] = [];
      companyEmployees.forEach(employee => {
        items.push({
          value: employee.id,
          label: `${employee.firstName} ${employee.lastName}`,
        });
      });
      setEmployeeDropdownItems(items);
    }
  }, [companyEmployees]);

  return (
    <Backdrop open sx={{ zIndex: '1' }}>
      <ModalWrapper open>
        <ModalContainer>
          <ModalHeader>
            <ModalTitle>{t(DOCUMENTS__ADD_NEW_DOCUMENT)}</ModalTitle>
            <ModalCloseIcon onClick={() => setOpenConfirmModal(true)}>
              <CloseIcon fill={theme.palette.secondary.contrastText} />
            </ModalCloseIcon>
          </ModalHeader>
          <Box id="content_wrapper">
            <ModalRowContainer>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <DragAndDropParagraph>{t(DOCUMENTS__DRAG_AND_DROP_FILES)}</DragAndDropParagraph>
                <BrowseDocumentsButton
                  onClick={e => {
                    e.stopPropagation();
                    open();
                  }}
                  variant="outlined"
                >
                  {t(DOCUMENTS__BROWSE)}
                </BrowseDocumentsButton>
              </div>
            </ModalRowContainer>
            <ModalRowDetailsContainer id="scrollableNavigation">
              {documents.map((doc, index) => (
                <Box
                  sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`row ${doc.name} ${doc.id}`}
                >
                  <AddNewDocumentRow
                    name={doc.name}
                    type={doc.type}
                    date={doc.date ?? new Date()}
                    periodStart={doc.periodStart}
                    periodEnd={doc.periodEnd}
                    documentTypes={addCategories}
                    index={index}
                    isEmployee={isEmployee}
                    companyEmployees={employeesDropdownItems}
                    onChangeValue={onChangeValue}
                    error={doc.error}
                  />
                  <Box
                    sx={{ cursor: 'pointer', display: 'flex', alignSelf: 'flex-start', paddingTop: '53px' }}
                    onClick={() => {
                      onDeleteRow(index);
                    }}
                  >
                    <CloseIcon fill={theme.palette.secondary.contrastText} />
                  </Box>
                </Box>
              ))}
            </ModalRowDetailsContainer>
          </Box>
          <ModalFooter>
            <UPButton text={t(DOCUMENTS__ADD_DOCUMENTS)} onClick={handleSubmit} disabled={documents.length === 0} />
            <Box sx={{ marginLeft: '12px' }}>
              <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={() => setOpenConfirmModal(true)} outlined />
            </Box>
          </ModalFooter>
          {openConfirmModal && (
            <DeleteModal
              open={openConfirmModal}
              content={t(CONTRACTS__ARE_YOU_SURE)}
              onClose={() => {
                setOpenConfirmModal(false);
              }}
              onDelete={() => {
                onClose();
              }}
            />
          )}
        </ModalContainer>
      </ModalWrapper>
    </Backdrop>
  );
};

export default AddNewDocumentModal;
