import { createReducer } from '@reduxjs/toolkit';
import IProfileState from 'types/profile/IProfileState';
import {
  setCheckPasswordAction,
  setProfileAction,
  updateLanguageErrorAction,
  updatePasswordErrorAction,
  updatePasswordSuccessAction,
  setPasswordErrorAction,
  setProfileRequestsLoadingAction,
  setPasswordSuccessAction,
  updateLanguageSuccessAction,
} from '../actions/profile-actions';

const initialState: IProfileState = {
  loading: false,
  data: null,
  updatePasswordSuccess: false,
  updatePasswordError: false,
  updateLanguageSuccess: false,
  updateLanguageError: false,
  checkPassword: null,
  setPasswordSuccess: false,
  setPasswordError: false,
};

const profileReducer = createReducer(initialState, builder =>
  builder
    .addCase(setProfileAction, (state, action) => ({ ...state, data: action.payload }))
    .addCase(setProfileRequestsLoadingAction, (state, action) => ({ ...state, loading: action.payload }))
    .addCase(updatePasswordSuccessAction, (state, action) => ({ ...state, updatePasswordSuccess: action.payload }))
    .addCase(updatePasswordErrorAction, (state, action) => ({ ...state, updatePasswordError: action.payload }))
    .addCase(setCheckPasswordAction, (state, action) => ({ ...state, checkPassword: action.payload }))
    .addCase(updateLanguageErrorAction, (state, action) => ({ ...state, updateLanguageError: action.payload }))
    .addCase(updateLanguageSuccessAction, (state, action) => ({ ...state, updateLanguageSuccess: action.payload }))
    .addCase(setPasswordSuccessAction, (state, action) => ({ ...state, setPasswordSuccess: action.payload }))
    .addCase(setPasswordErrorAction, (state, action) => ({ ...state, setPasswordError: action.payload })),
);

export default profileReducer;
