import AppContainer from 'components/app-container';
import Auth from 'modules/auth';
import SetPassword from 'modules/auth/SetPassword';
import Employees from 'modules/employees/Employees';
import Register from 'modules/register';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'store';
import { onboardingSelector } from 'store/selectors/employee-selector';
import ForgotPassword from 'modules/forgotpassword/ForgotPassword';
import AuthRedirect from 'modules/authRedirect';

const AuthRouter = (): JSX.Element => {
  const employeeOnboarding = useAppSelector(onboardingSelector);

  if (employeeOnboarding) {
    return (
      <AppContainer>
        <Routes>
          <Route path="/employees/:companyToken/:inviteToken" element={<Employees />} />
          <Route path="/employees/:generalToken" element={<Employees />} />
          <Route path="/auth/set-password/:uniqueKey" element={<SetPassword />} />
          <Route path="/auth/login" element={<Auth />} />
          <Route path="/auth/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      </AppContainer>
    );
  }
  return (
    <Routes>
      <Route path="/employees/:companyToken/:inviteToken" element={<Employees />} />
      <Route path="/employees/:generalToken" element={<Employees />} />
      <Route path="/auth/set-password/:uniqueKey" element={<SetPassword />} />
      <Route path="/auth/login" element={<Auth />} />
      <Route path="/redirect" element={<AuthRedirect />} />
      <Route path="/auth/register" element={<Register />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

export default AuthRouter;
