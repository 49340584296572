export const APP__SET_LOADING = '[APP] SET_LOADING';
export const APP_SET_LOADING_ACTIONS = '[APP] SET_LOADING_ACTIONS';
export const APP__SET_ITEMS_PER_PAGE = '[APP] SET_ITEMS_PER_PAGE';
export const APP__SET_ITEMS_PER_PAGE_ACTIONS = '[APP] SET_ITEMS_PER_PAGE_ACTIONS';

export const AUTH__SET_TOKEN = '[AUTH] SET_TOKEN';
export const AUTH__SET_LOADING = '[AUTH] SET_LOADING';
export const AUTH__LOGIN = '[AUTH] LOGIN';
export const AUTH__SSO_LOGIN = '[AUTH] SSO_LOGIN';
export const AUTH__SET_ERROR = '[AUTH] SET_ERROR';
export const AUTH__SET_TEXT_ERROR = '[AUTH] SET_TEXT_ERROR';
export const AUTH__LOGOUT = '[AUTH] LOGOUT';
export const AUTH__IMPERSONATE = '[AUTH] IMPERSONATE';
export const AUTH__IMPERSONATE_EMPLOYEE = '[AUTH] IMPERSONATE EMPLOYEE';
export const AUTH__STOP_IMPERSONATE = '[AUTH] STOP_IMPERSONATE';
export const AUTH__CHANGE_COMPANIES = '[AUTH] CHANGE_COMPANIES';
export const AUTH__SET_PASSWORD_TOKEN_VALIDITY = '[AUTH] SET_PASSWORD_TOKEN_VALIDITY';
export const AUTH__SET_PASSWORD_TOKEN_VALIDITY_RESULT = '[AUTH] SET_PASSWORD_TOKEN_VALIDITY_RESULT';

export const API_ERRORS__GET_ALL = '[API_ERRORS] GET_ALL';
export const API_ERRORS__GET_ALL_LOADING = '[API_ERRORS] GET_ALL_LOADING';
export const API_ERRORS__GET_ALL_SUCCESS = '[API_ERRORS] GET_ALL_SUCCESS';
export const API_ERRORS__GET_ALL_ERROR = '[API_ERRORS] GET_ALL_ERROR';
export const API_ERRORS__SET_ALL = '[API_ERRORS] SET_ALL';
export const API_ERRORS__ARCHIVE_SELECTED = '[API_ERRORS] ARCHIVE_SELECTED';
export const API_ERRORS__GET_ERROR_TYPES = '[API_ERRORS] GET_ERROR_TYPES';
export const API_ERRORS__SET_ERROR_TYPES = '[API_ERRORS] SET_ERROR_TYPES';

export const PROFILE__SET = '[PROFILE] SET';
export const PROFILE__GET = '[PROFILE] GET';

export const DRAWER__SET_STATUS = '[DRAWER] SET_STATUS';

export const MENU__SET_ACTIVE = '[MENU] SET_ACTIVE';

// company
export const COMPANY__GET_ALL = '[COMPANY] GET_ALL';
export const COMPANY__SET_COMPANIES = '[COMPANY] SET_COMPANIES';
export const COMPANY__SET_COMPANIES_LOADING = '[COMPANY] SET_COMPANIES_LOADING';
export const COMPANY__SET_COMPANIES_ERROR = '[COMPANY] SET_COMPANIES_ERROR';

export const COMPANY__SET_COMPANIES_AS_DROPDOWN_ITEMS = '[COMPANY] SET_COMPANIES_AS_DROPDOWN_ITEMS';
export const COMPANY__SET_USER_COMPANIES_AS_DROPDOWN_ITEMS = '[COMPANY] SET_USER_COMPANIES_AS_DROPDOWN_ITEMS';
export const COMPANY__GET_USER_COMPANIES = '[COMPANY] GET_USER_COMPANIES';
export const COMPANY__SET_COMPANIES_AS_DROPDOWN_ITEMS_ERROR = '[COMPANY] SET_COMPANIES_AS_DROPDOWN_ITEMS_ERROR';
export const COMPANY__GET_COMPANIES_AS_DROPDOWN_ITEMS = '[COMPANY] GET_COMPANIES_AS_DROPDOWN_ITEMS';
export const COMPANY__GET_USER_COMPANIES_AS_DROPDOWN_ITEMS = '[COMPANY] GET_USER_COMPANIES_AS_DROPDOWN_ITEMS';

export const COMPANY__GET_COMPANY = '[COMPANY] GET_COMPANY';
export const COMPANY__GET_COMPANY_LOADING = '[COMPANY] GET_COMPANY_LOADING';
export const COMPANY__GET_COMPANY_ERROR = '[COMPANY] GET_COMPANY_ERROR';
export const COMPANY__GET_BY_ID = '[COMPANY] GET_BY_ID';
export const COMPANY__UPDATE = '[COMPANY] UPDATE';
export const COMPANY__UPDATE_LOADING = '[COMPANY] UPDATE_LOADING';
export const COMPANY__UPDATE_SUCCESS = '[COMPANY] UPDATE_SUCCESS';
export const COMPANY__UPDATE_ERROR = '[COMPANY] UPDATE_ERROR';
export const COMPANY__CLEAR_UPDATE_COMPANY_STATUS = '[COMPANY] CLEAR_UPDATE_COMPANY_STATUS';
export const COMPANY__DELETE_LOADING = '[COMPANY] DELETE_LOADING';
export const COMPANY__DELETE_ERROR = '[COMPANY] DELETE_ERROR';
export const COMPANY__DELETE_SUCCESS = '[COMPANY] DELETE_SUCCESS';
export const COMPANY__DELETE = '[COMPANY] DELETE';
export const COMPANY__CLEAR_COMPANIES = '[COMPANY] CLEAR_COMPANIES';
export const COMPANY__SAVE = '[COMPANY] SAVE';
export const COMPANY__SAVE_LOADING = '[COMPANY] SAVE_LOADING';
export const COMPANY__SAVE_SUCCESS = '[COMPANY] SAVE_SUCCESS';
export const COMPANY__SAVE_ERROR = '[COMPANY] SAVE_ERROR';
export const COMPANY__SET_COMPANY_ID = '[COMPANY] SET_COMPANY_ID';
export const COMPANY__CLEAR_SAVE_COMPANY_STATUS = '[COMPANY] CLEAR_SAVE_COMPANY_STATUS';
export const COMPANY__CLEAR = '[COMPANY] CLEAR';
export const COMPANY_CONTACT__GET = '[COMPANY_CONTACT] GET';
export const COMPANY_CONTACT__PUT = '[COMPANY_CONTACT] PUT';
export const COMPANY_CONTACT__SET_CONTACT = '[COMPANY_CONTACT] SET_CONTACT';
export const COMPANY_CONTACT__SET_CONTACT_ERROR = '[COMPANY_CONTACT] SET_CONTACT_ERROR';
export const COMPANY_CONTACT__SET_CONTACT_UPDATE_SUCCESSFUL = '[COMPANY_CONTACT] SET_CONTACT_UPDATE_SUCCESSFUL ';
export const COMPANY_CONTACT__CLEAR = '[COMPANY_CONTACT] CLEAR';

export const COMPANY__GET_PARENT_COMPANIES = '[COMPANY] GET_PARENT_COMPANIES';
export const COMPANY__GET_PARENT_COMPANIES_ERROR = '[COMPANY] GET_PARENT_COMPANIES_ERROR';
export const COMPANY__GET_PARENT_COMPANIES_LOADING = '[COMPANY] GET_PARENT_COMPANIES_LOADING';
export const COMPANY__SET_PARENT_COMPANIES = '[COMPANY] SET_PARENT_COMPANIES';

export const COMPANY__SET_CHILD_COMPANIES = '[COMPANY] SET_CHILD_COMPANIES';
export const COMPANY__SET_CHILD_COMPANIES_LOADING = '[COMPANY] SET_CHILD_COMPANIES_LOADING';
export const COMPANY__SET_CHILD_COMPANIES_ERROR = '[COMPANY] SET_CHILD_COMPANIES_ERROR';
export const COMPANY__GET_CHILD_COMPANIES = '[COMPANY] GET_CHILD_COMPANIES';
export const COMPANY__SET_IS_PARENT_COMPANY = '[COMPANY] SET_IS_PARENT_COMPANY';
export const COMPANY__CHECK_IS_PARENT_COMPANY = '[COMPANY] CHECK_IS_PARENT_COMPANY';
export const COMPANY__CLEAR_IS_PARENT_COMPANY = '[COMPANY] CLEAR_IS_PARENT_COMPANY';

export const PARITAIR_COMITE__SET_SELECT_ITEMS = '[PARITAIR_COMITE] SET_SELECT_ITEMS';
export const PARITAIR_COMITE__SET_SELECT_ITEMS_BY_COMPANY = '[PARITAIR_COMITE] SET_SELECT_ITEMS_BY_COMPANY';
export const PARITAIR_COMITE__GET_SELECT_ITEMS = '[PARITAIR_COMITE] GET_SELECT_ITEMS';
export const PARITAIR_COMITE__GET_SELECT_ITEMS_BY_COMPANY = '[PARITAIR_COMITE] GET_SELECT_ITEMS';
export const PARITAIR_COMITE__GET_SELECT_ITEMS_ERROR = '[PARITAIR_COMITE] GET_SELECT_ITEMS_ERROR';
export const PARITAIR_COMITE__GET_SELECT_ITEMS_BY_COMPANY_ERROR = '[PARITAIR_COMITE] GET_SELECT_ITEMS_BY_COMPANY_ERROR';
export const PARITAIR_COMITE__GET_ALL = '[PARITAIR_COMITE] GET_ALL';
export const PARITAIR_COMITE__SET_PARITAIR_COMITES = '[PARITAIR_COMITE] SET_PARITAIR_COMITES';
export const PARITAIR_COMITE__SET_PARITAIR_COMITES_LOADING = '[PARITAIR_COMITE] SET_PARITAIR_COMITES_LOADING';
export const PARITAIR_COMITE__GET_PARITAIR_COMITE = '[PARITAIR_COMITE] GET_PARITAIR_COMITE';
export const PARITAIR_COMITE__GET_PARITAIR_COMITE_LOADING = '[PARITAIR_COMITE] GET_PARITAIR_COMITE_LOADING';
export const PARITAIR_COMITE__GET_PARITAIR_COMITE_ERROR = '[PARITAIR_COMITE] GET_PARITAIR_COMITE_ERROR';
export const PARITAIR_COMITE__GET_BY_ID = '[PARITAIR_COMITE] GET_BY_ID';
export const PARITAIR_COMITE__SAVE = '[PARITAIR_COMITE] SAVE';
export const PARITAIR_COMITE__SAVE_LOADING = '[PARITAIR_COMITE] SAVE_LOADING';
export const PARITAIR_COMITE__SAVE_SUCCESS = '[PARITAIR_COMITE] SAVE_SUCCESS';
export const PARITAIR_COMITE__SAVE_ERROR = '[PARITAIR_COMITE] SAVE_ERROR';
export const PARITAIR_COMITE__SET_ID = '[PARITAIR_COMITE] SET_ID';
export const PARITAIR_COMITE__UPDATE = '[PARITAIR_COMITE] UPDATE';
export const PARITAIR_COMITE__UPDATE_LOADING = '[PARITAIR_COMITE] UPDATE_LOADING';
export const PARITAIR_COMITE__UPDATE_SUCCESS = '[PARITAIR_COMITE] UPDATE_SUCCESS';
export const PARITAIR_COMITE__UPDATE_ERROR = '[PARITAIR_COMITE] UPDATE_ERROR';
export const PARITAIR_COMITE__CLEAR_UPDATE_PARITAIR_COMITE_STATUS = '[PARITAIR_COMITE] CLEAR_UPDATE_PARITAIR_COMITE_STATUS';
export const PARITAIR_COMITE__CLEAR = '[PARITAIR_COMITE] CLEAR';

export const COST_DECLARATION__GET_ALL = '[COST_DECLARATION] GET_ALL';
export const COST_DECLARATION__SET_COST_DECLARATIONS = '[COST_DECLARATION] SET_COST_DECLARATIONS';
export const COST_DECLARATION__SET_COST_DECLARATION = '[COST_DECLARATION] SET_COST_DECLARATION';
export const COST_DECLARATION__SET_COST_DECLARATIONS_ERROR = '[COST_DECLARATION] SET_COST_DECLARATIONS_ERROR';
export const COST_DECLARATION__SET_COST_DECLARATIONS_SUCCESS = '[COST_DECLARATION] SET_COST_DECLARATIONS_SUCCESS';
export const COST_DECLARATION__GENERAL_LOADING = '[COST_DECLARATION] SET_COST_DECLARATION_LOADING';
export const COST_DECLARATION__SET_COST_DECLARATION_SUCCESS = '[COST_DECLARATION] SET_COST_DECLARATION_SUCCESS';
export const COST_DECLARATION__SET_COST_DECLARATION_ERROR = '[COST_DECLARATION] SET_COST_DECLARATION_ERROR';
export const COST_DECLARATION__GET_BY_ID = '[COST_DECLARATION] GET_BY_ID';
export const COST_DECLARATION__SAVE = '[COST_DECLARATION] SAVE';
export const COST_DECLARATION__SAVE_SUCCESS = '[COST_DECLARATION] SAVE_SUCCESS';
export const COST_DECLARATION__SAVE_ERROR = '[COST_DECLARATION] SAVE_ERROR';
export const COST_DECLARATION__SET_ID = '[COST_DECLARATION] SET_ID';
export const COST_DECLARATION__UPDATE = '[COST_DECLARATION] UPDATE';
export const COST_DECLARATION__UPDATE_SUCCESS = '[COST_DECLARATION] UPDATE_SUCCESS';
export const COST_DECLARATION__UPDATE_ERROR = '[COST_DECLARATION] UPDATE_ERROR';
export const COST_DECLARATION__DELETE = '[COST_DECLARATION] DELETE';
export const COST_DECLARATION__DELETE_SUCCESS = '[COST_DECLARATION] DELETE_SUCCESS';
export const COST_DECLARATION__DELETE_ERROR = '[COST_DECLARATION] DELETE_ERROR';
export const COST_DECLARATION__GET_SELECT_ITEMS = '[COST_DECLARATION] GET_SELECT_ITEMS';
export const COST_DECLARATION__SET_SELECT_ITEMS = '[COST_DECLARATION] SET_SELECT_ITEMS';
export const COST_DECLARATION__SET_SELECT_ITEMS_ERROR = '[COST_DECLARATION] SET_SELECT_ITEMS_ERROR';
export const COST_DECLARATION__SET_SELECT_ITEMS_SUCCESS = '[COST_DECLARATION] SET_SELECT_ITEMS_SUCCESS';
export const COST_DECLARATION__GET_FOR_COMPANY = '[COST_DECLARATION] GET_FOR_COMPANY';
export const COST_DECLARATION__GET_SELECT_ITEMS_FOR_COMPANY = '[COST_DECLARATION] GET_SELECT_ITEMS_FOR_COMPANY';
export const COST_DECLARATION__SET_SELECT_ITEMS_FOR_COMPANY = '[COST_DECLARATION] SET_SELECT_ITEMS_FOR_COMPANY';
export const COST_DECLARATION__SET_FOR_COMPANY = '[COST_DECLARATION] SET_FOR_COMPANY';
export const COST_DECLARATION__SAVE_FOR_COMPANY = '[COST_DECLARATION] SAVE_FOR_COMPANY';
export const COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS = '[COST_DECLARATION] BULK_CHANGE_ACTIVE_STATUS';
export const COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_SUCCESS = '[COST_DECLARATION] BULK_CHANGE_ACTIVE_STATUS_SUCCESS';
export const COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_ERROR = '[COST_DECLARATION] BULK_CHANGE_ACTIVE_STATUS_ERROR';
export const COST_DECLARATION__BULK_CHANGE_ACTIVE_STATUS_FOR_COMPANY = '[COST_DECLARATION] BULK_CHANGE_ACTIVE_STATUS_FOR_COMPANY';
export const COST_DECLARATION__DELETE_FOR_COMPANY = '[COST_DECLARATION] DELETE_FOR_COMPANY';

export const WAGE_CALCULATION__GENERAL_LOADING = '[WAGE_CALCULATION] GENERAL_LOADING';
export const WAGE_CALCULATION__SET_WAGE_CALCULATION = '[WAGE_CALCULATION] SET_WAGE_CALCULATION';
export const WAGE_CALCULATION__UPDATE_WAGE_CALCULATION = '[WAGE_CALCULATION] UPDATE_WAGE_CALCULATION';
export const WAGE_CALCULATION__SET_WAGE_CALCULATION_ERROR = '[WAGE_CALCULATION] SET_WAGE_CALCULATION_ERROR';
export const WAGE_CALCULATION__UPDATE_WAGE_CALCULATION_ERROR = '[WAGE_CALCULATION] UPDATE_WAGE_CALCULATION_ERROR';
export const WAGE_CALCULATION__RESET_WAGE_CALCULATION = '[WAGE_CALCULATION] RESET_WAGE_CALCULATION';
export const WAGE_CALCULATION__RESET_WAGE_CALCULATION_ERROR = '[WAGE_CALCULATION] RESET_WAGE_CALCULATION_ERROR';
export const WAGE_CALCULATION__SET_OVERTIME_LIMITS = '[WAGE_CALCULATION] SET_OVERTIME_LIMITS';
export const WAGE_CALCULATION__GET_OVERTIME_LIMITS = '[WAGE_CALCULATION] GET_OVERTIME_LIMITS';
export const WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS = '[COMPANY] UPDATE_OVERTIME_LIMITS';
export const WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS_ERROR = '[COMPANY] UPDATE_OVERTIME_LIMITS_ERROR';
export const WAGE_CALCULATION__UPDATE_OVERTIME_LIMITS_SUCCESS = '[WAGE_CALCULATION] UPDATE_OVERTIME_LIMITS_SUCCESS';
export const WAGE_CALCULATION__GET_WORKHOUR_SETTINGS = '[WAGE_CALCULATION] GET_WORKHOUR_SETTINGS';
export const WAGE_CALCULATION__SET_WORKHOUR_SETTINGS = '[WAGE_CALCULATION] SET_WORKHOUR_SETTINGS';
export const WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS = '[WAGE_CALCULATION] UPDATE_WORKHOUR_SETTINGS';
export const WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_ERROR = '[WAGE_CALCULATION] UPDATE_WORKHOUR_SETTINGS_ERROR';
export const WAGE_CALCULATION__UPDATE_WORKHOUR_SETTINGS_SUCCESS = '[WAGE_CALCULATION] UPDATE_WORKHOUR_SETTINGS_SUCCESS';

export const COMPANY__SET_PAYROLL = '[COMPANY] SET_PAYROLL';
export const COMPANY__SET_PAYROLL_LOADING = '[COMPANY] SET_PAYROLL_LOADING';
export const COMPANY__SET_PAYROLL_ERROR = '[COMPANY] SET_PAYROLL_ERROR';
export const COMPANY__GET_PAYROLL = '[COMPANY] GET_PAYROLL';
export const COMPANY__CLEAR_PAYROLL = '[COMPANY] CLEAR_PAYROLL';

export const COMPANY__ADD_PAYROLL = '[COMPANY] ADD_PAYROLL';
export const COMPANY__ADD_PAYROLL_LOADING = '[COMPANY] ADD_PAYROLL_LOADING';
export const COMPANY__ADD_PAYROLL_SUCCESS = '[COMPANY] ADD_PAYROLL_SUCCESS';
export const COMPANY__ADD_PAYROLL_ERROR = '[COMPANY] ADD_PAYROLL_ERROR';

export const COMPANY__UPDATE_PAYROLL = '[COMPANY] UPDATE_PAYROLL';
export const COMPANY__UPDATE_PAYROLL_LOADING = '[COMPANY] UPDATE_PAYROLL_LOADING';
export const COMPANY__UPDATE_PAYROLL_SUCCESS = '[COMPANY] UPDATE_PAYROLL_SUCCESS';
export const COMPANY__UPDATE_PAYROLL_ERROR = '[COMPANY] UPDATE_PAYROLL_ERROR';
export const COMPANY_LOCATIONS__GET_ALL = '[COMPANY_LOCATIONS] GET_ALL';
export const COMPANY_LOCATIONS__SET_LOCATIONS = '[COMPANY_LOCATIONS] SET_LOCATIONS';
export const COMPANY_LOCATIONS__SET_LOCATIONS_LOADING = '[COMPANY_LOCATIONS] SET_LOCATIONS_LOADING';
export const COMPANY_LOCATIONS__SET_LOCATIONS_ERROR = '[COMPANY_LOCATIONS] SET_LOCATIONS_ERROR';
export const COMPANY_LOCATIONS__DELETE = '[COMPANY_LOCATIONS] DELETE';
export const COMPANY_LOCATIONS__DELETE_LOADING = '[COMPANY_LOCATIONS] DELETE_LOADING';
export const COMPANY_LOCATIONS__DELETE_ERROR = '[COMPANY_LOCATIONS] DELETE_ERROR';
export const COMPANY_LOCATIONS__DELETE_SUCCESS = '[COMPANY_LOCATIONS] DELETE_SUCCESS';
export const COMPANY_LOCATIONS__UPDATE_ONE = '[COMPANY_LOCATIONS] UPDATE_ONE';
export const COMPANY_LOCATIONS__ADD_ONE = '[COMPANY_LOCATIONS] ADD_ONE';
export const COMPANY_LOCATIONS__REMOVE_MANY = '[COMPANY_LOCATIONS] REMOVE_MANY';
export const COMPANY_LOCATIONS__GET_ALL_LOCATIONS_NAMES = '[COMPANY_LOCATIONS] GET_ALL_LOCATIONS_NAMES';
export const COMPANY_LOCATIONS__SET_LOCATIONS_NAMES = '[COMPANY_LOCATIONS] SET_LOCATIONS_NAMES';
export const COMPANY_LOCATIONS__SET_LOCATIONS_NAMES_LOADING = '[COMPANY_LOCATIONS] SET_LOCATIONS_NAMES_LOADING';
export const COMPANY_LOCATIONS__SET_LOCATIONS_NAMES_ERROR = '[COMPANY_LOCATIONS] SET_LOCATIONS_NAMES_ERROR';
export const COMPANY_LOCATIONS__CLEAR = '[COMPANY_LOCATIONS] CLEAR';
export const COMPANY_LOCATIONS__REMOVE_ALL = '[COMPANY_LOCATIONS] REMOVE_ALL';
export const COMPANY_LOCATIONS__SET_LOCATIONS_DROPDOWN_ITEMS = '[COMPANY_LOCATIONS] SET_LOCATIONS_DROPDOWN_ITEMS';
export const COMPANY_LOCATIONS__SET_LOCATIONS_DROPDOWN_ITEMS_ERROR = '[COMPANY_LOCATIONS] SET_LOCATIONS_DROPDOWN_ITEMS_ERROR';
export const COMPANY_LOCATIONS__GET_ALL_DROPDOWN_ITEMS = '[COMPANY_LOCATIONS] GET_ALL_DROPDOWN_ITEMS';

export const COMPANY_LOCATION__PUT = '[COMPANY_LOCATION] PUT';
export const COMPANY_LOCATION__SET_LOCATION = '[COMPANY_LOCATION] SET_LOCATION';
export const COMPANY_LOCATION__SET_LOCATION_LOADING = '[COMPANY_LOCATION] SET_LOCATION_LOADING';
export const COMPANY_LOCATION__SET_LOCATION_ERROR = '[COMPANY_LOCATION] SET_LOCATION_ERROR';
export const COMPANY_LOCATION__POST = '[COMPANY_LOCATION] POST';

export const COMPANY_MANAGERS__GET_ALL = '[COMPANY_MANAGERS] GET_ALL';
export const COMPANY_MANAGERS__SET_MANAGERS = '[COMPANY_MANAGERS] SET_MANAGERS';
export const COMPANY_MANAGERS__SET_MANAGERS_LOADING = '[COMPANY_MANAGERS] SET_MANAGERS_LOADING';
export const COMPANY_MANAGERS__SET_MANAGERS_ERROR = '[COMPANY_MANAGERS] SET_MANAGERS_ERROR';
export const COMPANY_MANAGERS__CLEAR = '[COMPANY_MANAGERS] CLEAR';

export const COMPANY_MANAGER__PUT = '[COMPANY_MANAGER] PUT';
export const COMPANY_MANAGER__SET_MANAGER = '[COMPANY_MANAGER] SET_MANAGER';
export const COMPANY_MANAGER__SET_MANAGER_LOADING = '[COMPANY_MANAGER] SET_MANAGER_LOADING';
export const COMPANY_MANAGER__SET_MANAGER_ERROR = '[COMPANY_MANAGER] SET_MANAGER_ERROR';
export const COMPANY_MANAGER__POST = '[COMPANY_LOCATION] POST';
export const COMPANY_MANAGERS__DELETE = '[COMPANY_MANAGERS] DELETE';
export const COMPANY_MANAGERS__DELETE_LOADING = '[COMPANY_MANAGERS] DELETE_LOADING';
export const COMPANY_MANAGERS__DELETE_ERROR = '[COMPANY_MANAGERS] DELETE_ERROR';
export const COMPANY_MANAGERS__DELETE_SUCCESS = '[COMPANY_MANAGERS] DELETE_SUCCESS';
export const COMPANY_MANAGERS__UPDATE_ONE = '[COMPANY_MANAGERS] UPDATE_ONE';
export const COMPANY_MANAGERS__ADD_ONE = '[COMPANY_MANAGERS] ADD_ONE';
export const COMPANY_MANAGERS__REMOVE_MANY = '[COMPANY_MANAGERS] REMOVE_MANY';

export const COMPANY_VENDORS__GET_ALL = '[COMPANY_VENDORS] GET_ALL';
export const COMPANY_VENDORS__GET_BY_ID = '[COMPANY_VENDORS] GET_BY_ID';
export const COMPANY_VENDORS__SET_VENDORS = '[COMPANY_VENDORS] SET_VENDORS';
export const COMPANY_VENDORS__SET_VENDOR = '[COMPANY_VENDORS] SET_VENDOR';
export const COMPANY_VENDORS__POST = '[COMPANY_VENDORS] POST';
export const COMPANY_VENDORS__PUT = '[COMPANY_VENDORS] PUT';
export const COMPANY_VENDORS__DELETE = '[COMPANY_VENDORS] DELETE';
export const COMPANY_VENDORS__SET_VENDORS_ERROR = '[COMPANY_VENDORS] SET_VENDORS_ERROR';
export const COMPANY_VENDORS__SET_VENDOR_ERROR = '[COMPANY_VENDORS] SET_VENDOR_ERROR';
export const COMPANY_VENDORS__DELETE_ERROR = '[COMPANY_VENDORS] DELETE_ERROR';
export const COMPANY_VENDORS__PUT_ERROR = '[COMPANY_VENDORS] PUT_ERROR';
export const COMPANY_VENDORS__POST_ERROR = '[COMPANY_VENDORS] POST_ERROR';

export const COMPANY_FINANCIAL__GET = '[COMPANY_FINANCIAL] GET';
export const COMPANY_FINANCIAL__PUT = '[COMPANY_FINANCIAL] PUT';
export const COMPANY_FINANCIAL__SET_FINANCIAL = '[COMPANY_FINANCIAL] SET_FINANCIAL';
export const COMPANY_FINANCIAL__SET_FINANCIAL_LOADING = '[COMPANY_FINANCIAL] SET_FINANCIAL_LOADING';
export const COMPANY_FINANCIAL__SET_FINANCIAL_ERROR = '[COMPANY_FINANCIAL] SET_FINANCIAL_ERROR';
export const COMPANY_FINANCIAL__SET_FINANCIAL_UPDATE_SUCCESSFUL = '[COMPANY_FINANCIAL] SET_FINANCIAL_UPDATE_SUCCESSFUL ';
export const FINANCIAL__GET_BIC = '[FINANCIAL] GET_BIC';
export const FINANCIAL__SET_BIC = '[FINANCIAL] SET_BIC';
export const FINANCIAL__SET_BIC_LOADING = '[FINANCIAL] SET_BIC_LOADING';
export const FINANCIAL__SET_BIC_ERROR = '[FINANCIAL] SET_BIC_ERROR';
export const FINANCIAL__BIC_REQUIRED_ERROR = '[FINANCIAL] BIC_REQUIRED_ERROR';
export const FINANCIAL__CLEAR = '[FINANCIAL] CLEAR';
export const COMPANY_FINANCIAL__CLEAR = '[COMPANY_FINANCIAL] CLEAR';

export const COMPANY_FUNCTIONS__GET = '[COMPANY_FUNCTIONS] GET';
export const COMPANY_FUNCTIONS__SET = '[COMPANY_FUNCTIONS] SET';
export const COMPANY_FUNCTIONS__SET_LOADING = '[COMPANY_FUNCTIONS] SET_LOADING';
export const COMPANY_FUNCTIONS__SET_ERROR = '[COMPANY_FUNCTIONS] SET_ERROR';
export const COMPANY_FUNCTIONS__CLEAR = '[COMPANY_FUNCTIONS] CLEAR';

export const CATEGORIES__GET = '[CATEGORIES] GET';
export const CATEGORIES__SET = '[CATEGORIES] SET';
export const CATEGORIES__SET_LOADING = '[CATEGORIES] SET_LOADING';
export const CATEGORIES__SET_ERROR = '[CATEGORIES] SET_ERROR';
export const FILTERED_CATEGORIES__GET = '[FILTERED_CATEGORIES] GET';
export const FILTERED_CATEGORIES__SET = '[FILTERED_CATEGORIES] SET';
export const FILTERED_CATEGORIES__SET_LOADING = '[FILTERED_CATEGORIES] SET_LOADING';
export const FILTERED_CATEGORIES__SET_ERROR = '[FILTERED_CATEGORIES] SET_ERROR';

export const COMPANY_FUNCTIONS__DELETE = '[COMPANY_FUNCTIONS] DELETE';
export const COMPANY_FUNCTIONS__DELETE_LOADING = '[COMPANY_FUNCTIONS] DELETE_LOADING';
export const COMPANY_FUNCTIONS__DELETE_ERROR = '[COMPANY_FUNCTIONS] DELETE_ERROR';
export const COMPANY_FUNCTIONS__DELETE_SUCCESS = '[COMPANY_FUNCTIONS] DELETE_SUCCESS';
export const COMPANY_FUNCTIONS__UPDATE_ONE = '[COMPANY_FUNCTIONS] UPDATE_ONE';
export const COMPANY_FUNCTIONS__ADD_ONE = '[COMPANY_FUNCTIONS] ADD_ONE';
export const COMPANY_FUNCTIONS__REMOVE_MANY = '[COMPANY_FUNCTIONS] REMOVE_MANY';
export const COMPANY_FUNCTIONS__SET_DROPDOWN_ITEMS = '[COMPANY_FUNCTIONS] SET_DROPDOWN_ITEMS';
export const COMPANY_FUNCTIONS__SET_DROPDOWN_ITEMS_ERROR = '[COMPANY_FUNCTIONS] SET_DROPDOWN_ITEMS_ERROR';
export const COMPANY_FUNCTIONS__GET_DROPDOWN_ITEMS = '[COMPANY_FUNCTIONS] GET_DROPDOWN_ITEMS';

export const COMPANY_FUNCTION__POST = '[COMPANY_FUNCTION] POST';
export const COMPANY_FUNCTION__PUT = '[COMPANY_FUNCTION] PUT';
export const COMPANY_FUNCTION__SET_FUNCTION = '[COMPANY_FUNCTION] SET';
export const COMPANY_FUNCTION__SET_FUNCTION_ERROR = '[COMPANY_FUNCTION] SET_ERROR';
export const COMPANY_FUNCTION__SET_FUNCTION_LOADING = '[COMPANY_FUNCTION] SET_LOADING';

export const COMPANY_COST_CENTERS__GET_ALL = '[COMPANY_COST_CENTERS] GET_ALL';
export const COMPANY_COST_CENTERS__SET_COST_CENTERS = '[COMPANY_COST_CENTERS] SET_COST_CENTERS';
export const COMPANY_COST_CENTERS__SET_COST_CENTERS_LOADING = '[COMPANY_COST_CENTERS] SET_COST_CENTERS_LOADING';
export const COMPANY_COST_CENTERS__SET_COST_CENTERS_ERROR = '[COMPANY_COST_CENTERS] SET_COST_CENTERS_ERROR';
export const COMPANY_COST_CENTERS__DELETE = '[COMPANY_COST_CENTERS] DELETE';
export const COMPANY_COST_CENTERS__DELETE_LOADING = '[COMPANY_COST_CENTERS] DELETE_LOADING';
export const COMPANY_COST_CENTERS__DELETE_ERROR = '[COMPANY_COST_CENTERS] DELETE_ERROR';
export const COMPANY_COST_CENTERS__DELETE_SUCCESS = '[COMPANY_COST_CENTERS] DELETE_SUCCESS';
export const COMPANY_COST_CENTERS__REMOVE_MANY = '[COMPANY_COST_CENTERS] REMOVE_MANY';
export const COMPANY_COST_CENTERS__UPDATE_ONE = '[COMPANY_COST_CENTERS] UPDATE_ONE';
export const COMPANY_COST_CENTERS__ADD_ONE = '[COMPANY_COST_CENTERS] ADD_ONE';
export const COMPANY_COST_CENTERS__CLEAR = '[COMPANY_COST_CENTERS] CLEAR';
export const COMPANY_COST_CENTERS__SET_COST_CENTERS_DROPDOWN_ITEMS = '[COMPANY_COST_CENTERS] SET_COST_CENTERS_DROPDOWN_ITEMS';
export const COMPANY_COST_CENTERS__SET_COST_CENTERS_DROPDOWN_ITEMS_ERROR = '[COMPANY_COST_CENTERS] SET_COST_CENTERS_DROPDOWN_ITEMS_ERROR';

export const COMPANY_COST_CENTER__PUT = '[COMPANY_COST_CENTER] PUT';
export const COMPANY_COST_CENTER__SET_COST_CENTER = '[COMPANY_COST_CENTER] SET_COST_CENTER';
export const COMPANY_COST_CENTER__SET_COST_CENTER_LOADING = '[COMPANY_COST_CENTER] SET_COST_CENTER_LOADING';
export const COMPANY_COST_CENTER__SET_COST_CENTER_ERROR = '[COMPANY_COST_CENTER] SET_COST_CENTER_ERROR';
export const COMPANY_COST_CENTER__POST = '[COMPANY_COST_CENTER] POST';

export const PROFILE__UPDATE_PASSWORD = '[PROFILE] UPDATE_PASSWORD';
export const PROFILE__UPDATE_PASSWORD_LOADING = '[PROFILE] UPDATE_PASSWORD_LOADING';
export const PROFILE__UPDATE_PASSWORD_ERROR = '[PROFILE] UPDATE_PASSWORD_ERROR';
export const PROFILE__UPDATE_PASSWORD_SUCCESS = '[PROFILE] UPDATE_PASSWORD_SUCCESS';
export const PROFILE__CHECK_PASSWORD_RESULT = '[PROFILE] CHECK_PASSWORD_RESULT';
export const PROFILE__CHECK_PASSWORD = '[PROFILE] CHECK_PASSWORD';
export const PROFILE__UPDATE_LANGUAGE = '[PROFILE] UPDATE_LANGUAGE';
export const PROFILE__UPDATE_LANGUAGE_SUCCESS = '[PROFILE] UPDATE_LANGUAGE_SUCCESS';
export const PROFILE__UPDATE_LANGUAGE_ERROR = '[PROFILE] UPDATE_LANGUAGE_ERROR';
export const PROFILE__SET_PASSWORD = '[PROFILE] SET_PASSWORD';
export const PROFILE__SET_PASSWORD_SUCCESS = '[PROFILE] SET_PASSWORD_SUCCESS';
export const PROFILE__SET_PASSWORD_ERROR = '[PROFILE] SET_PASSWORD_ERROR';
export const PROFILE__SET_PASSWORD_LOADING = '[PROFILE] SET_PASSWORD_LOADING';
export const PROFILE__FORGOT_PASSWORD = '[PROFILE] FORGOT_PASSWORD';
export const PROFILE__FORGOT_PASSWORD_SUCCESS = '[PROFILE] FORGOT_PASSWORD_SUCCESS';
export const PROFILE__FORGOT_PASSWORD_ERROR = '[PROFILE] FORGOT_PASSWORD_ERROR';

export const COMPANY_WAGE_EXEMPTIONS__SET = '[COMPANY_WAGE_EXEMPTIONS] SET';
export const COMPANY_WAGE_EXEMPTIONS__SET_LOADING = '[COMPANY_WAGE_EXEMPTIONS] SET_LOADING';
export const COMPANY_WAGE_EXEMPTIONS__SET_ERROR = '[COMPANY_WAGE_EXEMPTIONS] SET_ERROR';
export const COMPANY_WAGE_EXEMPTIONS__GET = '[COMPANY_WAGE_EXEMPTIONS] GET';
export const COMPANY_WAGE_EXEMPTIONS__UPDATE = '[COMPANY_WAGE_EXEMPTIONS] UPDATE';
export const COMPANY_WAGE_EXEMPTIONS__UPDATE_ERROR = '[COMPANY_WAGE_EXEMPTIONS] UPDATE_ERROR';
export const COMPANY_WAGE_EXEMPTIONS__UPDATE_SUCCESS = '[COMPANY_WAGE_EXEMPTIONS] UPDATE_SUCCESS';
export const COMPANY_WAGE_EXEMPTIONS__GET_FUNCTIONS_DESCRIPTION = '[COMPANY_WAGE_EXEMPTIONS] GET_FUNCTIONS_DESCRIPTION';
export const COMPANY_WAGE_EXEMPTIONS__GET_FUNCTIONS_DESCRIPTION_ERROR = '[COMPANY_WAGE_EXEMPTIONS] GET_FUNCTIONS_DESCRIPTION_ERROR';
export const COMPANY_WAGE_EXEMPTIONS__SET_FUNCTIONS_DESCRIPTION = '[COMPANY_WAGE_EXEMPTIONS] SET_FUNCTIONS_DESCRIPTION';
export const COMPANY_WAGE_EXEMPTIONS__CLEAR = '[COMPANY_WAGE_EXEMPTIONS] CLEAR';

export const COMPANY__SET_SALARY_ENGINE_REFERENCE_ID = '[COMPANY] SET_SALARY_ENGINE_REFERENCE_ID';
export const COMPANY__GET_SALARY_ENGINE_REFERENCE_ID = '[COMPANY] GET_SALARY_ENGINE_REFERENCE_ID';
export const COMPANY__SET_SALARY_ENGINE_REFERENCE_ID_ERROR = '[COMPANY] SET_SALARY_ENGINE_REFERENCE_ID_ERROR';

export const COMPANY__PUT_EXTERNAL_REFERENCES = '[COMPANY] PUT_EXTERNAL_REFERENCES';

// SuperAdmins
export const SUPERADMINS__SET = '[SUPERADMINS] SET';
export const SUPERADMINS__SET_LOADING = '[SUPERADMINS] SET_LOADING';
export const SUPERADMINS__SET_ERROR = '[SUPERADMINS] SET_ERROR';
export const SUPERADMINS__GET = '[SUPERADMINS] GET';
export const SUPERADMINS__DELETE = '[SUPERADMINS] DELETE';
export const SUPERADMINS__DELETE_LOADING = '[SUPERADMINS] DELETE_LOADING';
export const SUPERADMINS__DELETE_ERROR = '[SUPERADMINS] DELETE_ERROR';
export const SUPERADMINS__DELETE_SUCCESS = '[SUPERADMINS] DELETE_SUCCESS';
export const SUPERADMINS__UPDATE_ONE = '[SUPERADMINS] UPDATE_ONE';
export const SUPERADMINS__ADD_ONE = '[SUPERADMINS] ADD_ONE';
export const SUPERADMINS__REMOVE_MANY = '[SUPERADMINS] REMOVE_MANY';
export const SUPERADMINS__CLEAR = '[SUPERADMINS] CLEAR';

export const SUPERADMIN__PUT = '[SUPERADMIN] PUT';
export const SUPERADMIN__SET = '[SUPERADMIN] SET';
export const SUPERADMIN__SET_LOADING = '[SUPERADMIN] SET_LOADING';
export const SUPERADMIN__SET_ERROR = '[SUPERADMIN] SET_ERROR';
export const SUPERADMIN__POST = '[SUPERADMIN] POST';

// api users
export const API_USERS__SET = '[API_USER] SET';
export const API_USERS__SET_LOADING = '[API_USER] SET_LOADING';
export const API_USERS__SET_ERROR = '[API_USER] SET_ERROR';
export const API_USERS__GET = '[API_USER] GET';
export const API_USERS__DELETE = '[API_USER] DELETE';
export const API_USERS__DELETE_LOADING = '[API_USER] DELETE_LOADING';
export const API_USERS__DELETE_ERROR = '[API_USER] DELETE_ERROR';
export const API_USERS__DELETE_SUCCESS = '[API_USER] DELETE_SUCCESS';
export const API_USERS__UPDATE_ONE = '[API_USER] UPDATE_ONE';
export const API_USERS__ADD_ONE = '[API_USER] ADD_ONE';
export const API_USERS__REMOVE_MANY = '[API_USER] REMOVE_MANY';
export const API_USERS__CLEAR = '[API_USER] CLEAR';

export const API_USER__PUT = '[API_USER] PUT';
export const API_USER__SET = '[API_USER] SET';
export const API_USER__SET_LOADING = '[API_USER] SET_LOADING';
export const API_USER__SET_ERROR = '[API_USER] SET_ERROR';
export const API_USER__POST = '[API_USER] POST';

// minimum wages
export const MINIMUM_WAGES__SET_ALL = '[MINIMUM_WAGES] SET_ALL';
export const MINIMUM_WAGES__SET_ALL_LOADING = '[MINIMUM_WAGES] SET_ALL_LOADING';
export const MINIMUM_WAGES__SET_ALL_ERROR = '[MINIMUM_WAGES] SET_ALL_ERROR';
export const MINIMUM_WAGES__GET_ALL = '[MINIMUM_WAGES] GET_ALL';
export const MINIMUM_WAGES__ADD_ONE = '[MINIMUM_WAGES] ADD_ONE';
export const MINIMUM_WAGES__UPDATE_ONE = '[MINIMUM_WAGES] UPDATE_ONE';
export const MINIMUM_WAGES__UPDATE = '[MINIMUM_WAGES] UPDATE';
export const MINIMUM_WAGES__UPDATE_LOADING = '[MINIMUM_WAGES] UPDATE_LOADING';
export const MINIMUM_WAGES__UPDATE_ERROR = '[MINIMUM_WAGES] UPDATE_ERROR';
export const MINIMUM_WAGES__ADD_LOADING = '[MINIMUM_WAGES] ADD_LOADING';
export const MINIMUM_WAGES__ADD_ERROR = '[MINIMUM_WAGES] ADD_ERROR';
export const MINIMUM_WAGES__ADD = '[MINIMUM_WAGES] ADD';

// countries
export const COUNTRIES__GET_ALL_COUNTRIES_NAMES = '[COUNTRIES] GET_ALL_COUNTRIES_NAMES';
export const COUNTRIES__SET_COUNTRIES_NAMES = '[COUNTRIES] SET_COUNTRIES_NAMES';
export const COUNTRIES__SET_COUNTRIES_NAMES_LOADING = '[COUNTRIES] SET_COUNTRIES_NAMES_LOADING';
export const COUNTRIES__SET_COUNTRIES_NAMES_ERROR = '[COUNTRIES] SET_COUNTRIES_NAMES_ERROR';

// employee
export const EMPLOYEE__SAVE = '[EMPLOYEE] SAVE';
export const EMPLOYEE__SAVE_LOADING = '[EMPLOYEE] SAVE_LOADING';
export const EMPLOYEE__SAVE_SUCCESS = '[EMPLOYEE] SAVE_SUCCESS';
export const EMPLOYEE__SAVE_ERROR = '[EMPLOYEE] SAVE_ERROR';
export const EMPLOYEE__SET_ALL = '[EMPLOYEE] SET_ALL';
export const EMPLOYEE__SET_ALL_ERROR = '[EMPLOYEE] SET_ALL_ERROR';
export const EMPLOYEE__GET_ALL = '[EMPLOYEE] GET_ALL';
export const EMPLOYEE__GET_EMPLOYEE = '[EMPLOYEE] GET_EMPLOYEE';
export const EMPLOYEE__GET_EMPLOYEE_LOADING = '[EMPLOYEE] GET_EMPLOYEE_LOADING';
export const EMPLOYEE__GET_EMPLOYEE_ERROR = '[EMPLOYEE] GET_EMPLOYEE_ERROR';
export const EMPLOYEE__GET_BY_ID = '[EMPLOYEE] GET_BY_ID';
export const EMPLOYEE__UPDATE = '[EMPLOYEE] UPDATE';
export const EMPLOYEE__UPDATE_LOADING = '[EMPLOYEE] UPDATE_LOADING';
export const EMPLOYEE__UPDATE_SUCCESS = '[EMPLOYEE] UPDATE_SUCCESS';
export const EMPLOYEE__UPDATE_ERROR = '[EMPLOYEE] UPDATE_ERROR';
export const EMPLOYEE__ERROR = '[EMPLOYEE] ERROR';
export const EMPLOYEE__CLEAR = '[EMPLOYEE] CLEAR';
export const EMPLOYEE__GET_PENDING_EMPLOYEES = '[EMPLOYEE] GET_PENDING_EMPLOYEES';
export const EMPLOYEE__GET_EMPLOYEES_WITH_WORKERCLASS = '[EMPLOYEE] GET_EMPLOYEES_WITH_WORKERCLASS';
export const EMPLOYEE__SET_EMPLOYEES_WITH_WORKERCLASS = '[EMPLOYEE] SET_EMPLOYEES_WITH_WORKERCLASS';
export const EMPLOYEE__ONBOARDING_EMPLOYEE_DETAILS = '[EMPLOYEE] ONBOARDING_EMPLOYEE_DETAILS';
export const EMPLOYEE__ONBOARDING_EMPLOYEE_WITH_GENERAL_LINK = '[EMPLOYEE] ONBOARDING_EMPLOYEE_WITH_GENERAL_LINK';
export const EMPLOYEE__SET_ONBOARDING_EMPLOYEE_DETAILS = '[EMPLOYEE] SET_ONBOARDING_EMPLOYEE_DETAILS';
export const EMPLOYEE__SET_ONBOARDING = '[EMPLOYEE] SET_ONBOARDING';
export const EMPLOYEE__SET_COUNT = '[EMPLOYEE] SET_COUNT';
export const EMPLOYEE__SET_COUNT_ERROR = '[EMPLOYEE] SET_COUNT_ERROR';
export const EMPLOYEE__GET_COUNT = '[EMPLOYEE] GET_COUNT';
export const EMPLOYEE__DOWNLOAD_ID = '[EMPLOYEE] DOWNLOAD_ID';
export const EMPLOYEE__DOWNLOAD_ID_ERROR = '[EMPLOYEE] DOWNLOAD_ID_ERROR';
export const EMPLOYEE__DOWNLOAD_ALL = '[EMPLOYEE] DOWNLOAD_ALL';
export const EMPLOYEE__DOWNLOAD_ALL_ERROR = '[EMPLOYEE] DOWNLOAD_ALL_ERROR';
export const EMPLOYEE__ACADEMIC_YEAR_START = '[EMPLOYEE] ACADEMIC_YEAR_START';
export const EMPLOYEE__ACADEMIC_YEAR_END = '[EMPLOYEE] ACADEMIC_YEAR_END';
export const EMPLOYEE__DECLARATION_OF_TRUTH = '[EMPLOYEE] DECLARATION_OF_TRUTH';
export const EMPLOYEE__SET_COMPANY_INVITATION_LINK = '[EMPLOYEE] SET_COMPANY_INVITATION_LINK';
export const EMPLOYEE__GET_COMPANY_INVITATION_LINK = '[EMPLOYEE] GET_COMPANY_INVITATION_LINK';
export const EMPLOYEE__GET_COMPANY_INVITATION_LINK_ERROR = '[EMPLOYEE] GET_COMPANY_INVITATION_LINK_ERROR';
export const EMPLOYEE__GET_COMPANY_INVITATION_LINK_SUCCESS = '[EMPLOYEE] GET_COMPANY_INVITATION_LINK_SUCCESS';
export const EMPLOYEE__ADD_COMPANY_INVITATION_LINK = '[EMPLOYEE] ADD_COMPANY_INVITATION_LINK';
export const EMPLOYEE__DELETE_COMPANY_INVITATION_LINK = '[EMPLOYEE] DELETE_COMPANY_INVITATION_LINK';
export const EMPLOYEE__INVITATION_LINK_VALIDATION = '[EMPLOYEE] INVITATION_LINK_VALIDATION';
export const EMPLOYEE__CHECK_INVITATION_LINK_VALIDITY = '[EMPLOYEE] CHECK_INVITATION_LINK_VALIDITY';

// contract
export const CONTRACT__SAVE = '[CONTRACT] SAVE';
export const CONTRACT__SAVE_LOADING = '[CONTRACT] SAVE_LOADING';
export const CONTRACT__SAVE_SUCCESS = '[CONTRACT] SAVE_SUCCESS';
export const CONTRACT__SAVE_ERROR = '[CONTRACT] SAVE_ERROR';
export const CONTRACT__VALIDATE = '[CONTRACT] VALIDATE';
export const CONTRACT__VALIDATE_AND_SAVE = '[CONTRACT] VALIDATE_AND_SAVE';
export const CONTRACT__SAVE_CONTRACT_SLOT = '[CONTRACT] SAVE_CONTRACT_SLOT';
export const CONTRACT__VALIDATE_CONTRACT_SLOT_HOURS = '[CONTRACT] VALIDATE_CONTRACT_SLOT_HOURS';
export const CONTRACT__VALIDATE_MULTIPLE = '[CONTRACT] VALIDATE_MULTIPLE';
export const CONTRACT__VALIDATE_EDIT = '[CONTRACT] VALIDATE EDIT';
export const CONTRACT__VALIDATE_AND_EDIT = '[CONTRACT] VALIDATE AND EDIT';
export const CONTRACT__VALIDATE_AND_SAVE_SUCCESS = '[CONTRACT] VALIDATE_AND_SAVE_SUCCESS';
export const CONTRACT__VALIDATE_SUCCESS = '[CONTRACT] VALIDATE_SUCCESS';
export const CONTRACT__VALIDATE_SUCCESS_MULTIPLE = '[CONTRACT] VALIDATE_SUCCESS_MULTIPLE';
export const CONTRACT__VALIDATE_ERROR_MESSAGE = '[CONTRACT] VALIDATE_ERROR_MESSAGE';
export const CONTRACT__VALIDATE_ERROR_MESSAGE_EXTRA_INFO = '[CONTRACT] VALIDATE_ERROR_MESSAGE_EXTRA_INFO';
export const CONTRACT__VALIDATE_ERROR_MESSAGE_ROW = '[CONTRACT] VALIDATE_ERROR_MESSAGE_ROW';
export const CONTRACT__VALIDATE_AND_SAVE_ERROR_MESSAGE = '[CONTRACT] VALIDATE_AND_SAVE_ERROR_MESSAGE';
export const CONTRACT__VALIDATE_AND_SAVE_ERROR_MESSAGE_ROW = '[CONTRACT] VALIDATE_AND_SAVE_ERROR_MESSAGE_ROW';
export const CONTRACT__CLEAR = '[CONTRACT] CLEAR';
export const CONTRACT__GET_WAGE = '[CONTRACT] GET_WAGE';
export const CONTRACT__SET_WAGE = '[CONTRACT] SET_WAGE';
export const CONTRACT__GET_WAGE_ERROR = '[CONTRACT] GET_WAGE_ERROR';
export const CONTRACT__GET_CONTRACT_FOR_DATE = '[CONTRACT] GET_CONTRACT_FOR_DATE';
export const CONTRACT__GET_CONTRACT_FOR_DATE_ERROR = '[CONTRACT] GET_CONTRACT_FOR_DATE_ERROR';
export const CONTRACT__SET_CONTRACT = '[CONTRACT] SET_CONTRACT';
export const CONTRACT__CONFIRM = '[CONTRACT] CONFIRM';
export const CONTRACT__CONFIRM_ERROR = '[CONTRACT] CONFIRM_ERROR';
export const CONTRACT__VALIDATE_HOURS = '[CONTRACT] VALIDATE_HOURS';
export const CONTRACT__VALIDATE_HOURS_ERROR = '[CONTRACT] VALIDATE_HOURS';
export const CONTRACT__VALIDATE_AND_SAVE_HOURS = '[CONTRACT] VALIDATE_AND_SAVE_HOURS';
export const CONTRACT__VALIDATE_AND_SAVE_HOURS_ERROR = '[CONTRACT] VALIDATE_AND_SAVE_HOURS';

export const CONTRACTS__GET_CONTRACTS = '[CONTRACTS] GET_CONTRACTS';
export const CONTRACTS__GET_CONTRACTS_LISTVIEW = '[CONTRACTS] GET_CONTRACTS_LISTVIEW';
export const CONTRACTS__GET_CONTRACTS_OF_ADJACENT_WEEKS = '[CONTRACTS] GET_CONTRACTS_OF_ADJACENT_WEEKS';
export const CONTRACTS__GET_CONTRACTS_ERROR = '[CONTRACTS] GET_CONTRACTS_ERROR';
export const CONTRACTS__GET_CONTRACTS_LISTVIEW_ERROR = '[CONTRACTS] GET_CONTRACTS_LISTVIEW_ERROR';
export const CONTRACTS__TOO_MANY_CONTRACTS_ERROR = '[CONTRACTS] TOO_MANY_CONTRACTS_ERROR';
export const CONTRACTS__GET_CONTRACTS_TOTAL_COUNT = '[CONTRACTS] GET_CONTRACTS_TOTAL_COUNT';
export const CONTRACTS__GET_DIMONA_CONTRACT_ERROR = '[CONTRACTS] GET_DIMONA_CONTRACT_ERROR';
export const CONTRACTS__GET_DIMONA_CONTRACT_NUMBER = '[CONTRACTS] GET_DIMONA_CONTRACT_NUMBER';
export const CONTRACTS__GET_DIMONA_CONTRACT_NUMBER_SUCCESS = '[CONTRACTS] GET_DIMONA_CONTRACT_NUMBER_SUCCESS';
export const CONTRACTS__GET_CONTRACTS_PAYLOAD = '[CONTRACTS] GET_CONTRACTS_PAYLOAD';
export const CONTRACTS__GET_CONTRACTS_LISTVIEW_PAYLOAD = '[CONTRACTS] GET_CONTRACTS_LISTVIEW_PAYLOAD';
export const CONTRACTS__EDIT_CONTRACT_ERROR = '[CONTRACTS] EDIT_CONTRACT_ERROR';
export const CONTRACTS__EDIT_CONTRACT_SUCCESS = '[CONTRACTS] EDIT_CONTRACT_SUCCESS';
export const CONTRACTS__EDIT_CONTRACT = '[CONTRACTS] EDIT_CONTRACT';
export const CONTRACTS__FILTER_CONTRACTS = '[CONTRACTS] FILTER_CONTRACTS';
export const CONTRACTS__SET_FILTER = '[CONTRACTS] SET_FILTER';
export const CONTRACTS__DELETE_LOADING = '[CONTRACTS] DELETE_LOADING';
export const CONTRACTS__DELETE_SUCCESS = '[CONTRACTS] DELETE_SUCCESS';
export const CONTRACTS__DELETE_ERROR = '[CONTRACTS] DELETE_ERROR';
export const CONTRACTS__DELETE = '[CONTRACTS] DELETE';
export const CONTRACTS__SET_ERRORS = '[CONTRACTS] SET_ERRORS';
export const CONTRACTS__SET_SELECTED_CONTRACTS = '[CONTRACTS] SET_SELECTED_CONTRACTS';
export const CONTRACTS__SELECTED_CONTRACTS = '[CONTRACTS] SELECTED_CONTRACTS';
export const CONTRACTS__SHOULD_COPY_ACTION = '[CONTRACTS] SHOULD_COPY_ACTION';
export const CONTRACTS__SHOULD_COPY = '[CONTRACTS] SHOULD_COPY';
export const CONTRACTS__DIRECTLY_CARD_TO_COPY_ACTION = '[CONTRACTS] DIRECTLY_CARD_TO_COPY_ACTION';
export const CONTRACTS__DIRECTLY_CARD_TO_COPY = '[CONTRACTS] DIRECTLY_CARD_TO_COPY';
export const CONTRACTS__GET_CONTRACTS_COUNT_ERROR = '[CONTRACTS] GET_CONTRACTS_COUNT_ERROR';
export const CONTRACTS__GET_CONTRACTS_COUNT = '[CONTRACTS] GET_CONTRACTS_COUNT';
export const CONTRACTS__SET_CONTRACTS_COUNT = '[CONTRACTS] SET_CONTRACTS_COUNT';
export const CONTRACTS__GET_CONTRACTS_COUNT_FROM_PREV_WEEKS_ERROR = '[CONTRACTS] GET_CONTRACTS_COUNT_FROM_PREV_WEEKS_ERROR';
export const CONTRACTS__SET_CONTRACTS_COUNT_FROM_PREV_WEEKS = '[CONTRACTS] SET_CONTRACTS_COUNT_FROM_PREV_WEEKS';
export const CONTRACTS__GET_CONTRACTS_COUNT_FROM_PREV_WEEKS = '[CONTRACTS] GET_CONTRACTS_COUNT_FROM_PREV_WEEKS';
export const CONTRACTS__DOWNLOAD_CONTRACTS = '[CONTRACTS] DOWNLOAD_CONTRACTS';
export const CONTRACTS__DOWNLOAD_CONTRACTS_ERROR = '[CONTRACTS] DOWNLOAD_CONTRACTS_ERROR';
export const CONTRACTS__DOWNLOAD_COMPANIES_CONTRACTS = '[CONTRACTS] DOWNLOAD_COMPANIES_CONTRACTS';
export const CONTRACTS__DOWNLOAD_COMPANIES_CONTRACTS_ERROR = '[CONTRACTS] DOWNLOAD_COMPANIES_CONTRACTS_ERROR';
export const CONTRACTS__MULTIPLE_CONFIRMATION_PAYLOAD = '[CONTRACTS] MULTIPLE_CONFIRMATION_PAYLOAD';
export const CONTRACTS__MULTIPLE_CONFIRMATION_SUCCESS = '[CONTRACTS] MULTIPLE_CONFIRMATION_SUCCESS';
export const CONTRACTS__VALIDATE_HOURS_ON_MULTIPLE_CONFIRM = '[CONTRACTS] VALIDATE_HOURS_ON_MULTIPLE_CONFIRM';
export const CONTRACTS__MULTIPLE_CONFIRM = '[CONTRACTS] MULTIPLE_CONFIRM';
export const CONTRACTS__MULTIPLE_CONFIRMATION_ERROR = '[CONTRACTS] MULTIPLE_CONFIRMATION_ERROR';
export const CONTRACTS__ACTIONS_DISABLED = '[CONTRACTS] ACTIONS_DISABLED';
export const CONTRACTS__GET_COST_ESTIMATION = '[CONTRACTS] GET_COST_ESTIMATION';
export const CONTRACTS__GET_COST_ESTIMATION_PAYLOAD = '[CONTRACTS] GET_COST_ESTIMATION_PAYLOAD';
export const CONTRACTS__GET_COST_ESTIMATION_ERROR = '[CONTRACTS] GET_COST_ESTIMATION_ERROR';
export const CONTRACTS__GET_ARCHIVED_CONTRACTS_BY_COMPANY = '[CONTRACTS] GET_ARCHIVED_CONTRACTS_BY_COMPANY';
export const CONTRACTS__SET_ARCHIVED_CONTRACTS_BY_COMPANY = '[CONTRACTS] SET_ARCHIVED_CONTRACTS_BY_COMPANY';
export const CONTRACTS__GET_ARCHIVED_CONTRACTS_BY_COMPANY_ERROR = '[CONTRACTS] GET_ARCHIVED_CONTRACTS_BY_COMPANY_ERROR';
export const CONTRACTS__REOPEN_CONFIRMATION = '[CONTRACTS] REOPEN_CONFIRMATION';
export const CONTRACTS__REOPEN_CONFIRMATION_ERROR = '[CONTRACTS] REOPEN_CONFIRMATION_ERROR';
export const CONTRACTS__START_HOUR_AND_END_HOUR_COINCIDE_ERROR = '[CONTRACTS] START_HOUR_AND_END_HOUR_COINCIDE_ERROR';

// documents
export const DOCUMENTS__GET_DOCUMENTS_PAYLOAD = '[DOCUMENTS] GET_DOCUMENTS_PAYLOAD';
export const DOCUMENTS__GET_EMPLOYEE_DOCUMENTS_PAYLOAD = '[DOCUMENTS] GET_EMPLOYEE_DOCUMENTS_PAYLOAD';
export const DOCUMENTS__GET_DOCUMENTS_ERROR = '[DOCUMENTS] GET_DOCUMENTS_ERROR';
export const DOCUMENTS__GET_DOCUMENTS = '[DOCUMENTS] GET_DOCUMENTS';
export const DOCUMENTS__SAVE_DOCUMENTS = '[DOCUMENTS] SAVE_DOCUMENTS';
export const DOCUMENTS__UPDATE_STUDENT_DECLARATION = '[DOCUMENTS] UPDATE_STUDENT_DECLARAION';
export const DOCUMENTS__SAVE_DOCUMENTS_ERROR = '[DOCUMENTS] SAVE_DOCUMENTS_ERROR';
export const DOCUMENTS__DELETE_DOCUMENT = '[DOCUMENTS] DELETE_DOCUMENT';
export const DOCUMENTS__DELETE_DOCUMENT_ERROR = '[DOCUMENTS] DELETE_DOCUMENT_ERROR';
export const DOCUMENTS__DOWNLOAD_DOCUMENT = '[DOCUMENTS] DOWNLOAD_DOCUMENT';
export const DOCUMENTS__DOWNLOAD_DOCUMENT_ERROR = '[DOCUMENTS] DOWNLOAD_DOCUMENT_ERROR';
export const DOCUMENTS__DOWNLOAD_MULTIPLE_DOCUMENT_ERROR = '[DOCUMENTS] DOWNLOAD_MULTIPLE_DOCUMENT_ERROR';
export const DOCUMENTS__DOWNLOAD_EXPRESSO_DOCUMENT = '[DOCUMENTS] DOWNLOAD_EXPRESSO_DOCUMENT';
export const DOCUMENTS__DOWNLOAD_EXPRESSO_DOCUMENT_ERROR = '[DOCUMENTS] DOWNLOAD_EXPRESSO_DOCUMENT_ERROR';
export const DOCUMENTS__UPDATE_EMPLOYEE_ON_DOCUMENT = '[DOCUMENTS] UPDATE_EMPLOYEE_ON_DOCUMENT';

// blogs
export const BLOGS__GET_BLOG_BY_ID = '[BLOGS] GET_BLOG_BY_ID';
export const BLOGS__GET_BLOG_BY_ID_ERROR = '[BLOGS] GET_BLOG_BY_ID_ERROR';
export const BLOGS__GET_BLOG_BY_ID_PAYLOAD = '[BLOGS] GET_BLOG_BY_ID_PAYLOAD';
export const BLOGS__GET_BLOGS = '[BLOGS] GET_BLOGS';
export const BLOGS__GET_BLOGS_ERROR = '[BLOGS] GET_BLOGS_ERROR';
export const BLOGS__GET_BLOGS_PAYLOAD = '[BLOGS] GET_BLOGS_PAYLOAD';
export const BLOGS__DELETE_BLOGS_ERROR = '[BLOGS] DELETE_BLOGS_ERROR';
export const BLOGS__DELETE = '[BLOGS] DELETE';
export const BLOGS__ADD_BLOG_ERROR = '[BLOGS] ADD_BLOG_ERROR';
export const BLOGS__ADD = '[BLOGS] ADD';
export const BLOGS__EDIT_BLOG_ERROR = '[BLOGS] EDIT_BLOG_ERROR';
export const BLOGS__EDIT = '[BLOGS] EDIT';
export const BLOGS__GET_DASHBOARD_BLOGS_PAYLOAD = '[BLOGS] GET_DASHBOARD_BLOGS_PAYLOAD';
export const BLOGS__GET_DASHBOARD_BLOGS_ERROR = '[BLOGS] GET_DASHBOARD_BLOGS_ERROR';
export const BLOGS__GET_DASHBOARD_BLOGS = '[BLOGS] GET_DASHBOARD_BLOGS';

// app loading
export const AUTH__GENERAL_LOADING = '[AUTH] GENERAL_LOADING';
export const BLOGS__GENERAL_LOADING = '[BLOGS] GENERAL_LOADING';
export const COMPANY__GENERAL_LOADING = '[COMPANY] GENERAL_LOADING';
export const COMPANY_CONTACT__GENERAL_LOADING = '[COMPANY_CONTACT] GENERAL_LOADING';
export const COMPANY_COST_CENTER__GENERAL_LOADING = '[COMPANY_COST_CENTER] GENERAL_LOADING';
export const COMPANY_COST_CENTERS__GENERAL_LOADING = '[COMPANY_COST_CENTERS] GENERAL_LOADING';
export const COMPANY_FINANCIAL__GENERAL_LOADING = '[COMPANY_FINANCIAL] GENERAL_LOADING';
export const COMPANY_FUNCTION__GENERAL_LOADING = '[COMPANY_FUNCTION] GENERAL_LOADING';
export const COMPANY_FUNCTIONS__GENERAL_LOADING = '[COMPANY_FUNCTIONS] GENERAL_LOADING';
export const COMPANY_LOCATION__GENERAL_LOADING = '[COMPANY_LOCATION] GENERAL_LOADING';
export const COMPANY_LOCATIONS__GENERAL_LOADING = '[COMPANY_LOCATIONS] GENERAL_LOADING';
export const COMPANY_MANAGER__GENERAL_LOADING = '[COMPANY_MANAGER] GENERAL_LOADING';
export const COMPANY_MANAGERS__GENERAL_LOADING = '[COMPANY_MANAGERS] GENERAL_LOADING';
export const COMPANY_VENDOR__GENERAL_LOADING = '[COMPANY_VENDORS] GENERAL_LOADING';
export const COMPANY_WAGE_EXEMPTIONS__GENERAL_LOADING = '[COMPANY_WAGE_EXEMPTIONS] GENERAL_LOADING';
export const CONTRACTS__GENERAL_LOADING = '[CONTRACTS] GENERAL_LOADING';
export const COUNTRIES__GENERAL_LOADING = '[COUNTRIES] GENERAL_LOADING';
export const DOCUMENTS__GENERAL_LOADING = '[DOCUMENTS] GENERAL_LOADING';
export const DRAWER__GENERAL_LOADING = '[DRAWER] GENERAL_LOADING';
export const EMPLOYEE__GENERAL_LOADING = '[EMPLOYEE] GENERAL_LOADING';
export const FINANCIAL__GENERAL_LOADING = '[FINANCIAL] GENERAL_LOADING';
export const MENU__GENERAL_LOADING = '[MENU] GENERAL_LOADING';
export const MINIMUM_WAGES__GENERAL_LOADING = '[MINIMUM_WAGES] GENERAL_LOADING';
export const PARITAIR_COMITE__GENERAL_LOADING = '[PARITAIR_COMITE] GENERAL_LOADING';
export const PAYROLL__GENERAL_LOADING = '[PAYROLL] GENERAL_LOADING';
export const PROFILE__GENERAL_LOADING = '[PROFILE] GENERAL_LOADING';
export const SUPERADMIN__GENERAL_LOADING = '[SUPERADMIN] GENERAL_LOADING';
export const SUPERADMINS__GENERAL_LOADING = '[SUPERADMIN] GENERAL_LOADING';

export const APIUSER__GENERAL_LOADING = '[APIUSER] GENERAL_LOADING';
export const APIUSERS__GENERAL_LOADING = '[APIUSER] GENERAL_LOADING';

// reporting
export const REPORTING__GET_DATA = '[REPORTING] GET_DATA';
export const REPORTING__GET_DATA_ERROR = '[REPORTING] GET_DATA_ERROR';
export const REPORTING__GET_DATA_PAYLOAD = '[REPORTING] GET_DATA_PAYLOAD';
export const REPORTING__GENERAL_LOADING = '[REPORTING] GENERAL_LOADING';
export const REPORTING__CLEAR_REPORTS = '[REPORTING] CLEAR_REPORTS';
// availabilities
export const AVAILABILITIES__GENERAL_LOADING = '[AVAILABILITIES] GENERAL_LOADING';
export const AVAILABILITIES__GET_PAYLOAD = '[AVAILABILITIES] GET_PAYLOAD';
export const AVAILABILITIES__GET = '[AVAILABILITIES] GET';
export const AVAILABILITIES__GET_ERROR = '[AVAILABILITIES] GET_ERROR';
export const AVAILABILITIES__UPDATE = '[AVAILABILITIES] UPDATE';
export const AVAILABILITIES__UPDATE_ERROR = '[AVAILABILITIES] UPDATE_ERROR';

export const COMPANY_AVAILABILITY__GET = '[COMPANY_AVAILABILITY] GET';
export const COMPANY_AVAILABILITY__GET_ERROR = '[COMPANY_AVAILABILITY] GET_ERROR';
export const COMPANY_AVAILABILITY__GET_PAYLOAD = '[COMPANY_AVAILABILITY] GET_PAYLOAD';
export const COMPANY_AVAILABILITY__GET_FOR_WEEK = '[COMPANY_AVAILABILITY] GET_FOR_WEEK';
export const COMPANY_AVAILABILITY__GET_FOR_WEEK_PAYLOAD = '[COMPANY_AVAILABILITY] GET_FOR_WEEK_PAYLOAD';
export const COMPANY_AVAILABILITY__RESET_FOR_WEEK = '[COMPANY_AVAILABILITY] RESET_FOR_WEEK';
export const COMPANY_AVAILABILITY__GET_FOR_WEEK_ERROR = '[COMPANY_AVAILABILITY] GET_FOR_WEEK_ERROR';
export const COMPANY_AVAILABILITY__REQUEST_AVAILABILITY = '[COMPANY_AVAILABILITY] REQUEST_AVAILABILITY';
export const COMPANY_AVAILABILITY__REQUEST_AVAILABILITY_FILTERED = '[COMPANY_AVAILABILITY] REQUEST_AVAILABILITY_FILTERED';
export const COMPANY_AVAILABILITY__REQUEST_AVAILABILITY_ERROR = '[COMPANY_AVAILABILITY] REQUEST_AVAILABILITY_ERROR';
export const COMPANY_AVAILABILITY__EXPORT_COMPANY_AVAILABILITY = '[COMPANY_AVAILABILITY] EXPORT_COMPANY_AVAILABILITY';

export const COMPANY_AVAILABILITY_FILTERS__SET = '[COMPANY_AVAILABILITY_FILTERS] SET';
export const COMPANY_AVAILABILITY_FILTERS__CLEAR = '[COMPANY_AVAILABILITY_FILTERS] CLEAR';
export const COMPANY_AVAILABILITY_FILTERS__RESET = '[COMPANY_AVAILABILITY_FILTERS] RESET';

export const CONTRACTS_FILTERS__SET = '[CONTRACTS_FILTERS] SET';
export const CONTRACTS_FILTERS__CLEAR = '[CONTRACTS_FILTERS] CLEAR';
