import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { PayrollAddNewItemButton } from 'modules/companies/payroll/styles';
import { SectionContainer } from 'modules/companies/styles';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import CustomSearchBox from 'components/app-container/custom-search-box/CustomSearchBox';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { deleteSuperAdminsAction, getSuperAdminsAction } from 'store/actions/superadmins-actions';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import { superAdminsSelector } from 'store/selectors/superadmins-selectors';
import { escapeRegExp } from 'utils/helpers';
import {
  GENERAL__FIRST_NAME,
  GENERAL__LAST_NAME,
  GENERAL__EMAIL,
  GENERAL__LANGUAGE,
  GENERAL__EDIT,
  GENERAL__DELETE_SELECTED,
  GENERAL__PHONE_NUMBER,
  SUPERADMINS__ADD_NEW,
  SUPERADMINS__ADD_NEW_BUTTON,
  SUPERADMINS__DELETE_SUPERADMIN,
  SUPERADMINS__EDIT_SUPERADMIN,
  MENU__USERS,
  GENERAL__HAS_TWO_FACTOR_AUTH,
} from 'translations/constants';
import IUser from 'types/superadmin/ISuperAdmin';
import EditSuperAdmin from './EditSuperAdmin';

const SuperAdmins = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const superAdmins = useAppSelector(superAdminsSelector);
  const activeItem = useAppSelector(activeMenuItemSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [openAddEditSuperAdmin, setOpenAddEditSuperAdmin] = useState<boolean>(false);
  const [isAddNewButtonClicked, setIsAddNewButtonClicked] = useState<boolean>(false);
  const [rows, setRows] = useState<IUser[]>([]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const onOpenAddEditModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenAddEditSuperAdmin(true);
  }, []);

  const onCloseAddEditModal = useCallback(() => {
    setOpenAddEditSuperAdmin(false);
    setIsAddNewButtonClicked(false);
  }, []);

  const getSuperAdminOnEdit = useCallback(() => {
    return superAdmins.find(s => s.id === rowOnHover);
  }, [superAdmins, rowOnHover]);

  const onOpenDeleteModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeleteModal(true);
  }, []);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  useEffect(() => {
    if (activeItem !== t(MENU__USERS)) {
      dispatch(setMenuStatusAction(t(MENU__USERS)));
    }
  }, [activeItem, dispatch, t]);

  useEffect(() => {
    dispatch(getSuperAdminsAction());
  }, [dispatch]);

  useEffect(() => {
    if (superAdmins) {
      setRows(superAdmins);
    }
  }, [superAdmins]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'firstName', headerName: t(GENERAL__FIRST_NAME), width: 200 },
    { field: 'lastName', headerName: t(GENERAL__LAST_NAME), width: 200 },
    { field: 'email', headerName: t(GENERAL__EMAIL), width: 300 },
    { field: 'language', headerName: t(GENERAL__LANGUAGE), width: 200, hide: true },
    { field: 'phoneNumber', headerName: t(GENERAL__PHONE_NUMBER), width: 200 },
    {
      field: 'hasTwoFactorAuth',
      headerName: t(GENERAL__HAS_TWO_FACTOR_AUTH),
      width: 200,
      renderCell: params => (params.value ? 'Yes' : 'No'),
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return <UPButton onClick={onOpenAddEditModal} text={t(GENERAL__EDIT)} outlined />;
        }
        return null;
      },
    },
  ];

  const handleGridSearch = (searchTerm: string) => {
    if (searchTerm) {
      const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');
      const filteredRows = superAdmins.filter((row: IUser) => {
        return Object.keys(row).some((field: string) => {
          return searchRegex.test(row[field]);
        });
      });
      setRows(filteredRows);
    } else {
      setRows(superAdmins);
    }
  };

  return (
    <SectionContainer sx={{ paddingTop: '16px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(SUPERADMINS__ADD_NEW)}`}
          onClick={event => {
            setRowOnHover(null);
            setIsAddNewButtonClicked(true);
            onOpenAddEditModal(event);
          }}
        />
        <Box sx={{ marginLeft: '15px' }}>
          <PayrollAddNewItemButton
            sx={{ height: '49px' }}
            text={t(GENERAL__DELETE_SELECTED)}
            onClick={onOpenDeleteModal}
            outlined
            disabled={!(selectedRows.length > 0)}
          />
        </Box>
        <Box
          sx={{
            m: '16px 0px 0px 16px',
            width: '300px',
            height: '90%',
          }}
        >
          <CustomSearchBox onSearch={handleGridSearch} />
        </Box>
      </Box>
      <Box sx={{ height: '65vh', width: '100%' }}>
        <UPDataGrid
          rows={rows}
          columns={columns}
          setRowOnHover={setRowOnHover}
          getRowId={row => row.id}
          onSelectionModelChange={onSelectionChange}
        />
        {openAddEditSuperAdmin && (
          <EditSuperAdmin
            open={openAddEditSuperAdmin}
            onClose={onCloseAddEditModal}
            superAdmin={getSuperAdminOnEdit()}
            title={isAddNewButtonClicked ? t(SUPERADMINS__ADD_NEW_BUTTON) : t(SUPERADMINS__EDIT_SUPERADMIN)}
            isEdit={!isAddNewButtonClicked}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            content={t(SUPERADMINS__DELETE_SUPERADMIN)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => {
              dispatch(deleteSuperAdminsAction(selectedRows));
            }}
          />
        )}
      </Box>
    </SectionContainer>
  );
};

export default SuperAdmins;
