import { Alert } from '@mui/material';
import { WarningBarProps } from './types';

const WarningBar = (props: WarningBarProps): JSX.Element => {
  const { message } = props;

  return (
    <Alert severity="warning">
      <div>{message}</div>
    </Alert>
  );
};

WarningBar.defaultProps = {
  message: '',
};

export default WarningBar;
