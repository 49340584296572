import { Box, Grid, useTheme } from '@mui/material';
import UPButton from 'components/button';
import { UPDropdown } from 'components/dropdown';
import { toNumber } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getParitairComiteSelectItemsAction,
  getParitairComiteSelectItemsByCompanyAction,
} from 'store/actions/paritair-comite-actions';
import {
  getOvertimeLimitsAction,
  getWageCalculationAction,
  getWorkhourSettingsAction,
  resetWageCalculationAction,
  setUpdateOvertimeLimitsErrorAction,
  setUpdateWorkhourSettingsErrorAction,
  updateOvertimeLimitsAction,
  updateWageCalculationAction,
  updateWorkhourSettingsAction,
} from 'store/actions/wage-calculation-actions';
import {
  paritairComiteByCompanySelectItemsSelector,
  paritairComiteSelectItemsSelector,
} from 'store/selectors/paritair-comite-selectors';
import {
  overtimeLimitsSelector,
  updateOvertimeLimitsErrorMessageSelector,
  updateOvertimeLimitsSuccessSelector,
  wageCalculationSelector,
  workhourSettingsSelector,
} from 'store/selectors/wage-calculation-selectors';
import {
  COMPANY_PAYROLL__WORKHOURS_SETTINGS,
  GENERAL__SELECT,
  MENU__PARITAIR_COMITE,
  WAGE_COMPONENTS__HOLIDAY,
  WAGE_COMPONENTS__NIGHT_SHIFT,
  WAGE_COMPONENTS__OVERTIME,
} from 'translations/constants';
import {
  INightShift,
  IOvertimeLimits,
  IServiceCode,
  IWageCalculationComponent,
  IWorkhourSettings,
} from 'types/wage-calculation';
import NightShiftSchema from './NightShiftSchema';
import OvertimeLimits from './OvertimeLimits';
import ServiceCodesTable from './ServiceCodesTable';
import {
  ContainerTitle,
  NightShiftIntervalContainer,
  OvertimeLimitsContainer,
  ServiceCodesButtonsContainer,
  ServiceCodesContainer,
  WorkhourSettingsContainer,
} from './styles';
import { ServiceCodesAction, ServiceCodesProps } from './types';
import WorkhourSettings from './WorkhourSettings';

const START_TIME = '22:00';
const END_TIME = '06:00';

const NightshiftInitial: INightShift = {
  id: 0,
  startTime: START_TIME,
  endTime: END_TIME,
  code: 0,
};

const ServiceCodeInitial: IServiceCode = {
  id: 0,
  outcome: '',
  code: 0,
  description: '',
};

const ComponentInitial: IWageCalculationComponent = {
  workHoursWeekDay: ServiceCodeInitial,
  workHoursSaturday: ServiceCodeInitial,
  workHoursSunday: ServiceCodeInitial,
  overtimeWeekDay: ServiceCodeInitial,
  overtimeSaturday: ServiceCodeInitial,
  overtimeSunday: ServiceCodeInitial,
};

const ServiceCodes = (props: ServiceCodesProps) => {
  const { companyId } = props;

  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const paritairComiteSelectItems = useAppSelector(paritairComiteSelectItemsSelector);
  const paritairComiteSelectItemsByCompany = useAppSelector(paritairComiteByCompanySelectItemsSelector);
  const wageCalculationFromRedux = useAppSelector(wageCalculationSelector);
  const overtimeLimitsFromRedux = useAppSelector(overtimeLimitsSelector);
  const workhourSettingsFromRedux = useAppSelector(workhourSettingsSelector);
  const updateOvertimeLimitsErrorMessage = useAppSelector(updateOvertimeLimitsErrorMessageSelector);
  const updateOvertimeLimitsSuccess = useAppSelector(updateOvertimeLimitsSuccessSelector);

  const [nightShift, setNightShift] = useState<INightShift>(NightshiftInitial);
  const [overtime, setOvertime] = useState<IWageCalculationComponent>(ComponentInitial);
  const [holiday, setHoliday] = useState<IWageCalculationComponent>(ComponentInitial);
  const [overtimeLimits, setOvertimeLimits] = useState<IOvertimeLimits>(null);
  const [workhourSettings, setWorkhourSettings] = useState<IWorkhourSettings>(null);
  const [selectedParitairComite, setSelectedParitairComite] = useState<string>('');
  const [areOvertimeLimitsUpdated, setAreOvertimeLimitsUpdated] = useState<boolean>(false);
  const [areWorkhourSettingsUpdated, setAreWorkhourSettingsUpdated] = useState<boolean>(false);
  const [areServiceCodesUpdated, setAreServiceCodesUpdated] = useState<boolean>(false);
  const [validValues, setValidValues] = useState<boolean>(false);

  const shouldUpdate = useMemo(() => {
    return areOvertimeLimitsUpdated || areServiceCodesUpdated || areWorkhourSettingsUpdated;
  }, [areOvertimeLimitsUpdated, areServiceCodesUpdated, areWorkhourSettingsUpdated]);

  const paritairComiteItems = useMemo(() => {
    if (companyId) {
      return paritairComiteSelectItemsByCompany;
    }
    return paritairComiteSelectItems;
  }, [paritairComiteSelectItems, paritairComiteSelectItemsByCompany, companyId]);

  const onAction = (action: ServiceCodesAction) => {
    setAreServiceCodesUpdated(false);
    if (action === ServiceCodesAction.RESET) {
      dispatch(
        resetWageCalculationAction({
          paritairComiteId: toNumber(selectedParitairComite),
          companyId: companyId ?? 0,
          wageCalculation: {
            nightShiftRule: nightShift,
            overtimeRules: overtime,
            holidayRules: holiday,
          },
        }),
      );
      return;
    }
    dispatch(
      updateWageCalculationAction({
        paritairComiteId: toNumber(selectedParitairComite),
        companyId: companyId ?? 0,
        wageCalculation: {
          nightShiftRule: nightShift,
          overtimeRules: overtime,
          holidayRules: holiday,
        },
      }),
    );
  };

  const onClickUpdate = () => {
    if (areOvertimeLimitsUpdated) {
      dispatch(
        updateOvertimeLimitsAction({
          companyId,
          paritairComiteId: toNumber(selectedParitairComite),
          overtimeLimits,
        }),
      );
      setAreOvertimeLimitsUpdated(false);
    }
    if (areWorkhourSettingsUpdated) {
      dispatch(
        updateWorkhourSettingsAction({
          companyId,
          paritairComiteId: toNumber(selectedParitairComite),
          workhourSettings,
        }),
      );
      setAreWorkhourSettingsUpdated(false);
    }
    if (areServiceCodesUpdated) {
      onAction(ServiceCodesAction.UPDATE);
      setAreServiceCodesUpdated(false);
    }
  };

  useEffect(() => {
    if (paritairComiteItems.length > 0) {
      setSelectedParitairComite(paritairComiteItems[0].value);
    }
  }, [paritairComiteItems]);

  useEffect(() => {
    if (companyId) {
      dispatch(getParitairComiteSelectItemsByCompanyAction(companyId));
      dispatch(setUpdateOvertimeLimitsErrorAction(''));
      dispatch(setUpdateWorkhourSettingsErrorAction(''));
      return;
    }
    dispatch(getParitairComiteSelectItemsAction());
  }, [dispatch, companyId]);

  useEffect(() => {
    if (selectedParitairComite) {
      dispatch(
        getWageCalculationAction({ paritairComiteId: toNumber(selectedParitairComite), companyId: companyId ?? 0 }),
      );
      if (companyId) {
        dispatch(getOvertimeLimitsAction({ companyId, paritairComiteId: toNumber(selectedParitairComite) }));
        dispatch(getWorkhourSettingsAction({ companyId, paritairComiteId: toNumber(selectedParitairComite) }));
      }
    }
  }, [dispatch, selectedParitairComite, companyId]);

  useEffect(() => {
    if (wageCalculationFromRedux) {
      setNightShift(wageCalculationFromRedux.nightShiftRule);
      setOvertime(wageCalculationFromRedux.overtimeRules);
      setHoliday(wageCalculationFromRedux.holidayRules);
    }
    if (overtimeLimitsFromRedux) {
      setOvertimeLimits(overtimeLimitsFromRedux);
    }
    if (workhourSettingsFromRedux) {
      setWorkhourSettings(workhourSettingsFromRedux);
    }
  }, [overtimeLimitsFromRedux, wageCalculationFromRedux, workhourSettingsFromRedux]);

  return (
    <Grid container marginTop={theme.spacing(1.25)}>
      <Box marginBottom={theme.spacing(2)} width={1}>
        <Box display="flex" alignItems="flex-end">
          <Box width="200px">
            <UPDropdown
              label={t(MENU__PARITAIR_COMITE)}
              placeholder={t(GENERAL__SELECT)}
              items={paritairComiteItems}
              value={selectedParitairComite}
              onChange={event => {
                const { value } = event.target;
                setSelectedParitairComite(value);
                setAreOvertimeLimitsUpdated(false);
                setAreServiceCodesUpdated(false);
              }}
            />
          </Box>
          <ServiceCodesButtonsContainer>
            {!!companyId && <UPButton text="Reset" onClick={() => onAction(ServiceCodesAction.RESET)} />}
            {shouldUpdate && <UPButton text="Update" onClick={onClickUpdate} disabled={!validValues} />}
          </ServiceCodesButtonsContainer>
          {!updateOvertimeLimitsSuccess && (
            <Box sx={{ ml: theme.spacing(1), color: theme.palette.error.main, display: 'flex', alignItems: 'center' }}>
              {updateOvertimeLimitsErrorMessage}
            </Box>
          )}
        </Box>
      </Box>
      {!!companyId && (
        <>
          <Grid item xs={6} marginBottom="20px" paddingRight={theme.spacing(1)}>
            <ServiceCodesContainer>
              <ContainerTitle>{t(COMPANY_PAYROLL__WORKHOURS_SETTINGS)}</ContainerTitle>
              <WorkhourSettingsContainer>
                <WorkhourSettings
                  workhourSettings={workhourSettings}
                  setWorkhourSettings={setWorkhourSettings}
                  setShouldUpdate={setAreWorkhourSettingsUpdated}
                  setValidValues={setValidValues}
                />
              </WorkhourSettingsContainer>
            </ServiceCodesContainer>
          </Grid>
          <Grid item xs={6} marginBottom="20px" paddingLeft={theme.spacing(1)}>
            <ServiceCodesContainer>
              <ContainerTitle>Overtime limits</ContainerTitle>
              <OvertimeLimitsContainer>
                <OvertimeLimits
                  overtimeLimits={overtimeLimits}
                  setOvertimeLimits={setOvertimeLimits}
                  setShouldUpdate={setAreOvertimeLimitsUpdated}
                  setValidValues={setValidValues}
                />
              </OvertimeLimitsContainer>
            </ServiceCodesContainer>
          </Grid>
        </>
      )}

      <ServiceCodesContainer xs={12} marginBottom="20px">
        <ContainerTitle>{t(WAGE_COMPONENTS__NIGHT_SHIFT)}</ContainerTitle>
        <NightShiftIntervalContainer>
          <NightShiftSchema
            nightShiftRule={nightShift}
            setNightShiftRule={setNightShift}
            setShouldUpdate={setAreServiceCodesUpdated}
            setValidValues={setValidValues}
          />
        </NightShiftIntervalContainer>
      </ServiceCodesContainer>
      <Grid item xs={6} paddingRight={theme.spacing(1)}>
        <ServiceCodesContainer>
          <ContainerTitle>{t(WAGE_COMPONENTS__OVERTIME)}</ContainerTitle>
          <ServiceCodesTable
            wageCalculationComponent={overtime}
            setWageCalculationComponent={setOvertime}
            setShouldUpdate={setAreServiceCodesUpdated}
            setValidValues={setValidValues}
          />
        </ServiceCodesContainer>
      </Grid>
      <Grid item xs={6} paddingLeft={theme.spacing(1)}>
        <ServiceCodesContainer>
          <ContainerTitle>{t(WAGE_COMPONENTS__HOLIDAY)}</ContainerTitle>
          <ServiceCodesTable
            wageCalculationComponent={holiday}
            setWageCalculationComponent={setHoliday}
            setShouldUpdate={setAreServiceCodesUpdated}
            setValidValues={setValidValues}
          />
        </ServiceCodesContainer>
      </Grid>
    </Grid>
  );
};

export default ServiceCodes;
