import { Box } from '@mui/material';
import { GridColDef, GridPreProcessEditCellProps } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import { UPDropdownItem } from 'components/dropdown';
import { CoefficientsPerWorkerClass, CoefficientsPerWorkerClassOptions } from 'constants/CoefficientsPerWorkerclass';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  COMPANY_PAYROLL__ADD_NEW_WORKCLASS_COEFFICIENT,
  COMPANY_PAYROLL__COEFFICIENT,
  COMPANY_PAYROLL__DELETE_WORKCLASS_COEFFICIENT,
  COMPANY_PAYROLL__WORKERCLASS,
} from 'translations/constants';
import { CoefficientPerWorkerClassGridContainer } from './styles';
import { CoefficientsPerWorkerclassGridProps } from './types';
import DataGridEditInputCell from './wage-exemptions/DataGridEditInputCell';

const CoefficientsPerWorkerclassGrid = (props: CoefficientsPerWorkerclassGridProps): JSX.Element => {
  const { coefficientsPerWorkerclass, setCoefficientsPerWorkerclass } = props;

  const [t] = useTranslation();

  const [rowIsUpdated, setRowIsUpdated] = useState<boolean>(true);
  const [workerclassOptions, setWorkerclassOptions] = useState<UPDropdownItem[]>(CoefficientsPerWorkerClassOptions());

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [selectedRow, setSelectedRow] = useState<number>();

  useEffect(() => {
    if (rowIsUpdated) {
      const updatedOptions = workerclassOptions.map(workerclassOption => {
        const coefficient = coefficientsPerWorkerclass.find(w => w.workerclass === workerclassOption.value);
        return { ...workerclassOption, isDisabled: coefficient !== undefined };
      });
      if (updatedOptions !== workerclassOptions) {
        setWorkerclassOptions(updatedOptions);
      }
      setRowIsUpdated(false);
    }
  }, [coefficientsPerWorkerclass, workerclassOptions, rowIsUpdated]);

  const onAddNewCoefficientPerWorkerclass = useCallback(() => {
    setCoefficientsPerWorkerclass([
      ...coefficientsPerWorkerclass,
      { id: Math.random(), workerclass: '', coefficient: 0 },
    ]);
  }, [coefficientsPerWorkerclass, setCoefficientsPerWorkerclass]);

  const onDeleteCoefficient = useCallback(() => {
    setCoefficientsPerWorkerclass([...coefficientsPerWorkerclass.filter(c => c.id !== selectedRow)]);
    setSelectedRow(null);
    setRowIsUpdated(true);
  }, [selectedRow, coefficientsPerWorkerclass, setCoefficientsPerWorkerclass]);

  const getWorkerclass = useCallback(
    workerclassValue => {
      const workerclass = workerclassOptions.find(pc => pc.value === workerclassValue);
      return workerclass ? workerclass.value : '';
    },
    [workerclassOptions],
  );

  const renderWorkerclassEditCell = useCallback(
    (params: any) => {
      /* eslint-disable react/jsx-props-no-spreading */
      return (
        <DataGridEditInputCell {...params} value={getWorkerclass(params.value)} dropdownOptions={workerclassOptions} />
      );
    },
    [workerclassOptions, getWorkerclass],
  );

  const updateWorkerclass = useCallback(
    (id, field, value) => {
      let coefficientPerWorkerclass = coefficientsPerWorkerclass.find(c => c.id === id);
      const index = coefficientsPerWorkerclass.indexOf(coefficientPerWorkerclass);
      coefficientPerWorkerclass = { ...coefficientPerWorkerclass, [field]: value };
      const updatedList = [
        ...coefficientsPerWorkerclass.slice(0, index),
        coefficientPerWorkerclass,
        ...coefficientsPerWorkerclass.slice(index + 1, coefficientsPerWorkerclass.length),
      ];
      setCoefficientsPerWorkerclass(updatedList);
      setRowIsUpdated(true);

      return coefficientPerWorkerclass;
    },
    [coefficientsPerWorkerclass, setCoefficientsPerWorkerclass],
  );

  const workerclassColumns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'workerclass',
      headerName: t(COMPANY_PAYROLL__WORKERCLASS),
      width: 200,
      editable: true,
      renderEditCell: renderWorkerclassEditCell,
      valueSetter: params => {
        const updatedWorkerclass = updateWorkerclass(params.row.id, 'workerclass', params.value);
        return updatedWorkerclass;
      },
      sortable: false,
    },
    {
      field: 'coefficient',
      headerName: t(COMPANY_PAYROLL__COEFFICIENT),
      width: 200,
      editable: true,
      valueSetter: params => {
        const updatedWorkerclass = updateWorkerclass(params.row.id, 'coefficient', params.value);
        return updatedWorkerclass;
      },
      sortable: false,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value < 1 || params.props.value > 5;
        return { ...params.props, error: hasError };
      },
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          marginTop: '32px',
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ marginBottom: '16px' }}>
          <UPButton
            text={`+ ${t(COMPANY_PAYROLL__ADD_NEW_WORKCLASS_COEFFICIENT)}`}
            onClick={onAddNewCoefficientPerWorkerclass}
          />
        </Box>
        <Box sx={{ marginBottom: '16px' }}>
          <UPButton text={t(COMPANY_PAYROLL__DELETE_WORKCLASS_COEFFICIENT)} onClick={onDeleteCoefficient} outlined />
        </Box>
      </Box>
      <CoefficientPerWorkerClassGridContainer>
        <UPDataGrid
          rows={coefficientsPerWorkerclass}
          columns={workerclassColumns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={(selection: any) => setSelectedRow(selection[0])}
          withCheckboxes={false}
          hideFooter
        />
      </CoefficientPerWorkerClassGridContainer>
    </Box>
  );
};
export default CoefficientsPerWorkerclassGrid;
