import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import UPButton from 'components/button';
import { UPDataGrid } from 'components/data-grid';
import DeleteModal from 'components/delete-modal';
import { UPDropdownItem } from 'components/dropdown';
import CATEGORIES from 'constants/Categories';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { deleteFunctionsAction, getFunctionsAction } from 'store/actions/company-functions-actions';
import { getParitairComiteSelectItemsAction } from 'store/actions/paritair-comite-actions';
import { functionsSelector } from 'store/selectors/company-functions-selectors';
import { paritairComiteSelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import IPayrollFunction from 'types/company/payroll/IPayrollFunction';
import { getFunctionTypesWithTranslation } from 'utils/helpers';
import {
  COMPANY_PAYROLL__ADD_NEW_FUNCTION,
  COMPANY_PAYROLL__CATEGORY,
  COMPANY_PAYROLL__FUNCTION_COLOR,
  COMPANY_PAYROLL__FUNCTION_DESCRIPTION,
  COMPANY_PAYROLL__FUNCTION_TYPE,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  GENERAL__DELETE_MESSAGE,
  GENERAL__DELETE_SELECTED,
  GENERAL__EDIT,
} from '../../../translations/constants';
import EditPayrollFunction from './EditPayrollFunction';
import { PayrollAddNewItemButton } from './styles';

const PayrollFunctions = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const userIsCompanyManager = useAppSelector(profileSelector).role === CompanyRole.COMPANY_MANAGER;
  const functions = useAppSelector(functionsSelector);
  const paritairComites = useAppSelector(paritairComiteSelectItemsSelector);

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [openEditPayrollFunction, setOpenEditPayrollFunction] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const categories = useMemo((): UPDropdownItem[] => {
    return CATEGORIES;
  }, []);

  const functionTypes = getFunctionTypesWithTranslation();

  useEffect(() => {
    dispatch(getFunctionsAction(toNumber(companyId)));
    dispatch(getParitairComiteSelectItemsAction());
  }, [companyId, dispatch]);

  const onOpenModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenEditPayrollFunction(true);
  }, []);

  const onOpenDeleteModal = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
    setOpenDeleteModal(true);
  }, []);

  const onSelectionChange = useCallback((ids: Iterable<number>) => {
    const selectedIDs = Array.from(ids);
    setSelectedRows(selectedIDs);
  }, []);

  const getFunctionOnEdit = useCallback((): IPayrollFunction => {
    if (rowOnHover) {
      return functions.find(payrollFunction => payrollFunction.id === rowOnHover);
    }
    const newFunction: IPayrollFunction = {
      id: 0,
      description: '',
      companyId: toNumber(companyId),
      category: '',
      paritairComiteId: '',
      type: '',
      color: '',
      class: '',
    };
    return newFunction;
  }, [rowOnHover, functions, companyId]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    { field: 'description', headerName: t(COMPANY_PAYROLL__FUNCTION_DESCRIPTION), width: 200 },
    {
      field: 'paritairComiteId',
      headerName: t(COMPANY_PAYROLL__PARITAIR_COMITE),
      width: 250,
      valueGetter: params => {
        const paritairComite = paritairComites.find(p => p.value === params.row.paritairComiteId);
        return paritairComite.label;
      },
    },
    {
      field: 'category',
      headerName: t(COMPANY_PAYROLL__CATEGORY),
      width: 250,
      valueGetter: params => {
        const category = categories.find(c => params.row.category === c.value);
        return category?.label;
      },
    },
    {
      field: 'color',
      headerName: t(COMPANY_PAYROLL__FUNCTION_COLOR),
      width: 200,
    },
    {
      field: 'type',
      headerName: t(COMPANY_PAYROLL__FUNCTION_TYPE),
      width: 200,
      valueGetter: params => {
        if (params.row.type !== null) {
          const index = toNumber(params.row.type) - 1;
          return functionTypes[index].label;
        }
        return '';
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id) {
          return <UPButton onClick={onOpenModal} text={t(GENERAL__EDIT)} disabled={userIsCompanyManager} outlined />;
        }
        return null;
      },
    },
  ];

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <PayrollAddNewItemButton
          text={`+ ${t(COMPANY_PAYROLL__ADD_NEW_FUNCTION)}`}
          onClick={event => {
            setRowOnHover(null);
            onOpenModal(event);
          }}
          disabled={userIsCompanyManager}
        />
        <Box sx={{ marginLeft: '15px' }}>
          <PayrollAddNewItemButton
            text={t(GENERAL__DELETE_SELECTED)}
            onClick={onOpenDeleteModal}
            outlined
            disabled={!(selectedRows.length > 0) || userIsCompanyManager}
          />
        </Box>
      </Box>
      <Box sx={{ height: '400px', width: '100%' }}>
        <UPDataGrid
          rows={functions}
          columns={columns}
          setRowOnHover={setRowOnHover}
          onSelectionModelChange={onSelectionChange}
          withCheckboxes={!userIsCompanyManager}
        />
        {openEditPayrollFunction && (
          <EditPayrollFunction
            open={openEditPayrollFunction}
            onClose={() => setOpenEditPayrollFunction(false)}
            payrollFunction={getFunctionOnEdit()}
          />
        )}
        {openDeleteModal && (
          <DeleteModal
            content={t(GENERAL__DELETE_MESSAGE)}
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            onDelete={() => dispatch(deleteFunctionsAction({ companyId, functionIds: selectedRows }))}
          />
        )}
      </Box>
    </Box>
  );
};

export default PayrollFunctions;
