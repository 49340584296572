import { styled, Box } from '@mui/material';

export const ParitairComiteGridContainer = styled(Box)(() => ({
  height: '600px',
  width: '100%',
}));

export const ButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  button: {
    marginBottom: '2px',
    height: '47px',
  },
}));

export const TopSectionContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
}));

export const TopSectionContainerWithMargins = styled(Box)(() => ({
  marginTop: '16px',
  marginBottom: '16px',
}));

export const MinimumWageGridContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '55vh',
  '& .Mui-error': {
    color: theme.palette.error.main,
  },
}));

export const SearchContainer = styled(Box)(() => ({
  margin: '16px 0px',
  width: '300px',
}));
