import { RootState } from 'store';
import IFunctionsDescription from 'types/company/payroll/IFunctionsDescription';
import IPayrollWageExemptions from 'types/company/payroll/IPayrollWageExemptions';

export const wageExemptionsSelector = (state: RootState): IPayrollWageExemptions => state.wageExemptions.wageExemptions;
export const wageExemptionsErrorSelector = (state: RootState): boolean => state.wageExemptions.getWageExemptionsError;
export const updateWageExemptionsErrorSelector = (state: RootState): boolean =>
  state.wageExemptions.updateWageExemptionsError;
export const updateWageExemptionsSuccessSelector = (state: RootState): boolean =>
  state.wageExemptions.updateWageExemptionsSuccess;
export const getFunctionsDescriptionErrorSelector = (state: RootState): boolean =>
  state.wageExemptions.getFunctionsDescriptionError;
export const functionsDescriptionSelector = (state: RootState): IFunctionsDescription[] =>
  state.wageExemptions.functionsDescription;

export const wageExemptionsLoadingSelector = (state: RootState): boolean => state.wageExemptions.loading;
