import { useTranslation } from 'react-i18next';
import { PAYMENT_METHOD__VIA_DOMICILIE, PAYMENT_METHOD__MANUAL } from 'translations/constants';

const usePaymentMethodsTranslation = (functionType: number) => {
  const [t] = useTranslation();
  const getTranslation = () => {
    switch (functionType) {
      case 1:
        return t(PAYMENT_METHOD__VIA_DOMICILIE);
      case 2:
        return t(PAYMENT_METHOD__MANUAL);
      default:
        return '';
    }
  };
  return getTranslation();
};
export default usePaymentMethodsTranslation;
