import UPTab from 'components/tab';
import { SectionContainer } from 'modules/companies/styles';
import { useState } from 'react';
import MinimumWagePanel from './MinimumWagePanel';

const ParitairComiteMinimumWages = (): JSX.Element => {
  const MinimumWagesTabs = ['Bediende (BED)', 'Arbeider (ARB)'];

  const [openType, setOpenType] = useState<number>(0);

  return (
    <SectionContainer sx={{ paddingTop: '0px' }}>
      <UPTab sections={MinimumWagesTabs} onChangeTab={setOpenType}>
        <MinimumWagePanel type={openType === 0 ? 'BED' : 'ARB'} />
        <MinimumWagePanel type={openType === 0 ? 'BED' : 'ARB'} />
      </UPTab>
    </SectionContainer>
  );
};

export default ParitairComiteMinimumWages;
