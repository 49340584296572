import { Alert, Box } from '@mui/material';
import UPButton from 'components/button';
import UPInput from 'components/input';
import { CompanyRole } from 'constants/CompanyRole';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import {
  getCompanyContactAction,
  putCompanyContactAction,
  setCompanyContactUpdateSuccessfulAction,
} from 'store/actions/company-contact-actions';
import {
  companyContactErrorSelector,
  companyContactLoadingSelector,
  companyContactStateSelector,
  companyContactUpdateSuccessfulSelector,
} from 'store/selectors/company-contact-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import ICompanyContact from 'types/company/contact/ICompanyContact';
import { getInputErrorText } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  COMPANY_CONTACT__CONTACT_EMAIL,
  COMPANY_CONTACT__CONTACT_NAME,
  COMPANY_CONTACT__CONTACT_PHONE,
  COMPANY_CONTACT__CONTACT_UPDATED_SUCCESSFULLY,
  GENERAL__CANCEL_BUTTON,
  GENERAL__ERROR_MESSAGE,
  GENERAL__SAVE_AND_NEXT_BUTTON,
  GENERAL__SAVE_BUTTON,
} from '../../../translations/constants';
import { ButtonsContainer, CloseAlertIcon, SectionContainer } from '../styles';

const CompanyContactInitialState: ICompanyContact = {
  id: 0,
  contactName: '',
  contactPhone: '',
  contactEmail: '',
};

const EditContact = (): JSX.Element => {
  const [t] = useTranslation();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRole = useAppSelector(profileSelector).role;
  const companyContact = useAppSelector(companyContactStateSelector);
  const companyContactLoading = useAppSelector(companyContactLoadingSelector);
  const companyContactError = useAppSelector(companyContactErrorSelector);
  const companyContactUpdateSuccessful = useAppSelector(companyContactUpdateSuccessfulSelector);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [saveAndNextClicked, setSaveAndNextClicked] = useState<boolean>(false);

  const isAdmin = userRole === CompanyRole.ADMIN;

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
    getValues,
  } = useForm<ICompanyContact>({
    defaultValues: CompanyContactInitialState,
  });

  useEffect(() => {
    if (companyId !== undefined) {
      dispatch(getCompanyContactAction(toNumber(companyId)));
      dispatch(setCompanyContactUpdateSuccessfulAction(false));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyContact) {
      reset(companyContact);
    }
  }, [companyContact, reset]);

  useEffect(() => {
    if (saveAndNextClicked && companyContactUpdateSuccessful) {
      setAlertOpen(true);
      setSaveAndNextClicked(false);
      setTimeout(() => {
        setAlertOpen(false);
        dispatch(setCompanyContactUpdateSuccessfulAction(false));
        if (isAdmin) {
          navigate(`/company/${companyId}/locations`);
        } else {
          navigate(`/company/${companyId}/payroll`);
        }
      }, 1000);
    }
  }, [navigate, saveAndNextClicked, companyId, dispatch, companyContactError, companyContactUpdateSuccessful, isAdmin]);

  const onSaveContact: SubmitHandler<any> = useCallback(() => {
    const values = getValues();
    values.id = toNumber(companyId);
    if (isDirty) {
      dispatch(putCompanyContactAction(values));
    }
  }, [companyId, dispatch, getValues, isDirty]);

  const onSaveAndNext = useCallback(() => {
    handleSubmit(onSaveContact)();
    setSaveAndNextClicked(true);
  }, [handleSubmit, onSaveContact]);

  const onCancel = useCallback(() => {
    dispatch(setCompanyContactUpdateSuccessfulAction(false));
    navigate(`/company/${companyId}/general`);
  }, [companyId, dispatch, navigate]);

  return (
    <SectionContainer>
      <Box sx={{ width: '40%' }}>
        <Box sx={{ marginBottom: '24px' }}>
          <Controller
            name="contactName"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(COMPANY_CONTACT__CONTACT_NAME)}
                placeholder={t(COMPANY_CONTACT__CONTACT_NAME)}
                error={!!errors.contactName}
                required
                helperText={getInputErrorText(errors, 'contactName', undefined, 100)}
                onChange={field.onChange}
                disabled={!isAdmin}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <Controller
            name="contactPhone"
            control={control}
            rules={{
              required: true,
              maxLength: 100,
              pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(COMPANY_CONTACT__CONTACT_PHONE)}
                placeholder={t(COMPANY_CONTACT__CONTACT_PHONE)}
                error={!!errors.contactPhone}
                required
                helperText={getInputErrorText(errors, 'contactPhone', undefined, 100)}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '32px' }}>
          <Controller
            name="contactEmail"
            control={control}
            rules={{ required: true, maxLength: 100, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(COMPANY_CONTACT__CONTACT_EMAIL)}
                placeholder={t(COMPANY_CONTACT__CONTACT_EMAIL)}
                error={!!errors.contactEmail}
                required
                helperText={getInputErrorText(errors, 'contactEmail', undefined, 100)}
                onChange={field.onChange}
              />
            )}
          />
        </Box>
      </Box>
      <ButtonsContainer sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ marginBottom: '10px' }}>
            <UPButton
              text={t(GENERAL__SAVE_BUTTON)}
              onClick={() => handleSubmit(onSaveContact)()}
              disabled={companyContactLoading}
            />
          </Box>
          <Box sx={{ marginLeft: '10px', marginRight: '10px' }}>
            <UPButton
              text={t(GENERAL__SAVE_AND_NEXT_BUTTON)}
              onClick={onSaveAndNext}
              disabled={companyContactLoading}
            />
          </Box>
          <Box>
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} outlined />
          </Box>
        </Box>
      </ButtonsContainer>
      <Box sx={{ marginTop: '32px' }}>
        {alertOpen && companyContactError && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
            onClose={() => setAlertOpen(false)}
          >
            {t(GENERAL__ERROR_MESSAGE)}
          </Alert>
        )}
        {companyContactUpdateSuccessful && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_CONTACT__CONTACT_UPDATED_SUCCESSFULLY)}
          </Alert>
        )}
      </Box>
    </SectionContainer>
  );
};

export default EditContact;
