import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteBlogRequest,
  editBlogRequest,
  getAllBlogsRequest,
  getBlogByIdRequest,
  getDashboardBlogsRequest,
  postBlogRequest,
} from 'api/requests/blog-requests';
import { UPBlogWithDetails, UPBlogWithNoDetails } from 'modules/blogs/types';
import {
  BLOGS__GET_BLOGS,
  BLOGS__GET_BLOG_BY_ID,
  BLOGS__GET_BLOG_BY_ID_ERROR,
  BLOGS__GET_BLOG_BY_ID_PAYLOAD,
  BLOGS__GET_BLOGS_ERROR,
  BLOGS__GET_BLOGS_PAYLOAD,
  BLOGS__DELETE,
  BLOGS__DELETE_BLOGS_ERROR,
  BLOGS__EDIT_BLOG_ERROR,
  BLOGS__EDIT,
  BLOGS__ADD,
  BLOGS__ADD_BLOG_ERROR,
  BLOGS__GET_DASHBOARD_BLOGS_ERROR,
  BLOGS__GET_DASHBOARD_BLOGS_PAYLOAD,
  BLOGS__GET_DASHBOARD_BLOGS,
  BLOGS__GENERAL_LOADING,
} from 'store/constants';

export const setBlogRequestsLoadingAction = createAction<boolean>(BLOGS__GENERAL_LOADING);

export const getBlogByIDErrorAction = createAction<boolean>(BLOGS__GET_BLOG_BY_ID_ERROR);
export const getBlogByIDPayloadAction = createAction<UPBlogWithDetails>(BLOGS__GET_BLOG_BY_ID_PAYLOAD);

export const getBlogByIDAction = createAsyncThunk(
  BLOGS__GET_BLOG_BY_ID,
  async (props: { id: number | null }, thunkApi) => {
    thunkApi.dispatch(setBlogRequestsLoadingAction(true));
    thunkApi.dispatch(getBlogByIDErrorAction(false));
    try {
      const blogToEdit = await getBlogByIdRequest({
        id: props.id,
      });
      thunkApi.dispatch(getBlogByIDErrorAction(false));
      thunkApi.dispatch(getBlogByIDPayloadAction(blogToEdit));
    } catch (error) {
      thunkApi.dispatch(getBlogByIDErrorAction(true));
    }
    thunkApi.dispatch(setBlogRequestsLoadingAction(false));
  },
);

export const getBlogsErrorAction = createAction<boolean>(BLOGS__GET_BLOGS_ERROR);
export const getBlogsPayloadAction = createAction<UPBlogWithNoDetails[]>(BLOGS__GET_BLOGS_PAYLOAD);

export const getBlogsAction = createAsyncThunk(BLOGS__GET_BLOGS, async (props: { type: string }, thunkApi) => {
  thunkApi.dispatch(setBlogRequestsLoadingAction(true));
  thunkApi.dispatch(getBlogsErrorAction(false));
  try {
    const contracts = await getAllBlogsRequest();
    thunkApi.dispatch(getBlogsErrorAction(false));
    thunkApi.dispatch(getBlogsPayloadAction(contracts));
  } catch (error) {
    thunkApi.dispatch(getBlogsErrorAction(true));
  }
  thunkApi.dispatch(setBlogRequestsLoadingAction(false));
});

export const deleteBlogErrorAction = createAction<boolean>(BLOGS__DELETE_BLOGS_ERROR);

export const deleteBlogAction = createAsyncThunk(
  BLOGS__DELETE,
  async (props: { id: number; type: string }, thunkApi) => {
    thunkApi.dispatch(setBlogRequestsLoadingAction(true));
    try {
      await deleteBlogRequest({
        id: props.id,
      });

      thunkApi.dispatch(deleteBlogErrorAction(false));
      thunkApi.dispatch(getBlogsAction({ type: props.type }));
    } catch (error) {
      thunkApi.dispatch(deleteBlogErrorAction(true));
    }
    thunkApi.dispatch(setBlogRequestsLoadingAction(false));
  },
);

export const addBlogErrorAction = createAction<boolean>(BLOGS__ADD_BLOG_ERROR);

export const addBlogAction = createAsyncThunk(
  BLOGS__ADD,
  async (props: { blog: UPBlogWithDetails; type: string }, thunkApi) => {
    thunkApi.dispatch(setBlogRequestsLoadingAction(true));
    try {
      await postBlogRequest({
        blog: props.blog,
      });

      thunkApi.dispatch(addBlogErrorAction(false));
      thunkApi.dispatch(getBlogsAction({ type: props.type }));
    } catch (error) {
      thunkApi.dispatch(addBlogErrorAction(true));
    }
    thunkApi.dispatch(setBlogRequestsLoadingAction(false));
  },
);

export const editBlogErrorAction = createAction<boolean>(BLOGS__EDIT_BLOG_ERROR);

export const editBlogAction = createAsyncThunk(
  BLOGS__EDIT,
  async (props: { blog: UPBlogWithDetails; type: string }, thunkApi) => {
    thunkApi.dispatch(setBlogRequestsLoadingAction(true));
    try {
      await editBlogRequest({
        blog: props.blog,
      });

      thunkApi.dispatch(editBlogErrorAction(false));
      thunkApi.dispatch(getBlogsAction({ type: props.type }));
    } catch (error) {
      thunkApi.dispatch(editBlogErrorAction(true));
    }
    thunkApi.dispatch(setBlogRequestsLoadingAction(false));
  },
);

export const getDashboardBlogsErrorAction = createAction<boolean>(BLOGS__GET_DASHBOARD_BLOGS_ERROR);
export const getDashboardBlogsPayloadAction = createAction<UPBlogWithNoDetails[]>(BLOGS__GET_DASHBOARD_BLOGS_PAYLOAD);

export const getDashboardBlogsAction = createAsyncThunk(BLOGS__GET_DASHBOARD_BLOGS, async (_, thunkApi) => {
  thunkApi.dispatch(setBlogRequestsLoadingAction(true));
  thunkApi.dispatch(getDashboardBlogsErrorAction(false));
  try {
    const blogs = await getDashboardBlogsRequest();
    thunkApi.dispatch(getDashboardBlogsErrorAction(false));
    thunkApi.dispatch(getDashboardBlogsPayloadAction(blogs));
  } catch (error) {
    thunkApi.dispatch(getDashboardBlogsErrorAction(true));
  }
  thunkApi.dispatch(setBlogRequestsLoadingAction(false));
});
