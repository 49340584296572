import { createReducer } from '@reduxjs/toolkit';
import {
  getBlogByIDPayloadAction,
  getBlogsPayloadAction,
  getDashboardBlogsPayloadAction,
  setBlogRequestsLoadingAction,
} from 'store/actions/blog-actions';
import IBlogsState from 'types/blogs/IBlogsState';

const initialState: IBlogsState = {
  blogs: [],
  blogToEdit: null,
  loading: false,
  dashboardBlogs: [],
};

const blogReducer = createReducer(initialState, builder => {
  return builder
    .addCase(getBlogsPayloadAction, (state, action) => {
      return {
        ...state,
        blogs: action.payload,
      };
    })
    .addCase(getBlogByIDPayloadAction, (state, action) => {
      return {
        ...state,
        blogToEdit: action.payload,
      };
    })
    .addCase(getDashboardBlogsPayloadAction, (state, action) => {
      return {
        ...state,
        dashboardBlogs: action.payload,
      };
    })
    .addCase(setBlogRequestsLoadingAction, (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    });
});

export default blogReducer;
