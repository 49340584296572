import { useAppSelector } from 'store';
import { authStateSelector } from 'store/selectors/auth-selectors';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import AuthRouter from './AuthRouter';

const Router = (): JSX.Element => {
  const authStatus = useAppSelector(authStateSelector);

  return <BrowserRouter>{authStatus ? <AppRouter /> : <AuthRouter />}</BrowserRouter>;
};

export default Router;
