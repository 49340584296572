import { Checkbox, Box } from '@mui/material';
import { FormControlLabelStyled } from './styles';
import { UPCheckboxProps } from './types';

const UPCheckbox = (props: UPCheckboxProps): JSX.Element => {
  const { label, value, handleChange } = props;

  return (
    <Box>
      <FormControlLabelStyled label={label} control={<Checkbox checked={value} onChange={handleChange} />} />
    </Box>
  );
};

export default UPCheckbox;
