import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material';
import { useEffect, useState } from 'react';
import Router from 'routing/Router';
import initMsalConfig from 'services/load-msal-config';
import startup from 'services/startup';
import theme from 'theme';

const App = (): JSX.Element => {
  const [isReady, setIsReady] = useState(false);
  const [msalConfig, setMsalConfig] = useState({} as any);

  useEffect(() => {
    (async () => {
      await startup();
      const config = await initMsalConfig();
      setMsalConfig(config);
      setIsReady(true);
    })();
  }, []);

  if (!isReady || !msalConfig) {
    // Here you can also return a loading screen, while your app is bootstraping
    return null;
  }

  /**
   * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
   * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
   */

  const msalInstance = new PublicClientApplication(msalConfig);

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
  }

  // Listen for sign-in event and set active account
  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const { account } = payload;
      msalInstance.setActiveAccount(account);
    }
  });

  // // handle auth redired/do all initial setup for msal
  // msalInstance
  //   .handleRedirectPromise()
  //   .then(authResult => {
  //     // Check if user signed in
  //     const account = msalInstance.getActiveAccount();
  //     if (!account) {
  //       // redirect anonymous user to login page
  //       msalInstance.loginRedirect();
  //     }
  //   })
  //   .catch(err => {
  //     // TODO: Handle errors
  //     console.log(err);
  //   });

  /**
   * You can wrap your component with providers that require store access
   * or other dependencies loaded in the previous step (index.ts)
   */
  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
