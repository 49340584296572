import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  addApiUserRequest,
  addSuperAdminRequest,
  updateApiUserRequest,
  updateSuperAdminRequest,
} from 'api/requests/superadmin-requests';
import {
  SUPERADMIN__SET,
  SUPERADMIN__SET_ERROR,
  SUPERADMIN__PUT,
  SUPERADMIN__POST,
  SUPERADMIN__GENERAL_LOADING,
  APIUSER__GENERAL_LOADING,
  API_USERS__SET,
  API_USERS__SET_ERROR,
  API_USER__PUT,
  API_USER__POST,
} from 'store/constants';
import IUser from 'types/superadmin/ISuperAdmin';
import {
  addOneApiUserAction,
  addOneSuperAdminAction,
  updateOneApiUserAction,
  updateOneSuperAdminAction,
} from './superadmins-actions';

export const setSuperAdminRequestsLoadingAction = createAction<boolean>(SUPERADMIN__GENERAL_LOADING);

export const setSuperAdminAction = createAction<boolean>(SUPERADMIN__SET);
export const setSuperAdminErrorAction = createAction<boolean>(SUPERADMIN__SET_ERROR);

export const updateSuperAdminAction = createAsyncThunk(SUPERADMIN__PUT, async (superAdmin: IUser, thunkApi) => {
  thunkApi.dispatch(setSuperAdminRequestsLoadingAction(true));
  thunkApi.dispatch(setSuperAdminErrorAction(false));
  try {
    await updateSuperAdminRequest(superAdmin);
    thunkApi.dispatch(setSuperAdminAction(true));
    thunkApi.dispatch(updateOneSuperAdminAction({ id: superAdmin.id, changes: superAdmin }));
  } catch (error) {
    thunkApi.dispatch(setSuperAdminErrorAction(true));
  }
  thunkApi.dispatch(setSuperAdminRequestsLoadingAction(false));
});

export const postSuperAdminAction = createAsyncThunk(SUPERADMIN__POST, async (superAdmin: IUser, thunkApi) => {
  thunkApi.dispatch(setSuperAdminRequestsLoadingAction(true));
  thunkApi.dispatch(setSuperAdminErrorAction(false));
  try {
    const superAdminId = await addSuperAdminRequest(superAdmin);
    const newSuperAdmin: IUser = { ...superAdmin, id: superAdminId };
    thunkApi.dispatch(setSuperAdminAction(true));
    thunkApi.dispatch(addOneSuperAdminAction(newSuperAdmin));
  } catch (error) {
    thunkApi.dispatch(setSuperAdminErrorAction(true));
  }
  thunkApi.dispatch(setSuperAdminRequestsLoadingAction(false));
});

// api users
export const setApiUserRequestsLoadingAction = createAction<boolean>(APIUSER__GENERAL_LOADING);

export const setApiUserAction = createAction<boolean>(API_USERS__SET);
export const setApiUserErrorAction = createAction<boolean>(API_USERS__SET_ERROR);

export const updateApiUserAction = createAsyncThunk(API_USER__PUT, async (user: IUser, thunkApi) => {
  thunkApi.dispatch(setApiUserRequestsLoadingAction(true));
  thunkApi.dispatch(setApiUserErrorAction(false));
  try {
    await updateApiUserRequest(user);
    thunkApi.dispatch(setApiUserAction(true));
    thunkApi.dispatch(updateOneApiUserAction({ id: user.id, changes: user }));
  } catch (error) {
    thunkApi.dispatch(setApiUserErrorAction(true));
  }
  thunkApi.dispatch(setApiUserRequestsLoadingAction(false));
});

export const postApiUserAction = createAsyncThunk(API_USER__POST, async (user: IUser, thunkApi) => {
  thunkApi.dispatch(setApiUserRequestsLoadingAction(true));
  thunkApi.dispatch(setApiUserErrorAction(false));
  try {
    const id = await addApiUserRequest(user);
    const newUser: IUser = { ...user, id };
    thunkApi.dispatch(setApiUserAction(true));
    thunkApi.dispatch(addOneApiUserAction(newUser));
  } catch (error) {
    thunkApi.dispatch(setApiUserErrorAction(true));
  }
  thunkApi.dispatch(setApiUserRequestsLoadingAction(false));
});
