import { Avatar, Box } from '@mui/material';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as EditEmployeeAvatar } from '../../../../assets/icons/EditEmployeeAvatar.svg';
import { ReactComponent as WeeklyContractIcon } from '../../../../assets/icons/WeeklyContractIcon.svg';
import { generateEmployeeFunction } from '../../helpers';
import { EmployeeData, EmployeeName, EmployeeRole, WeeklyIcon } from '../../styles';

const GridCardEmployee = (props: { data: any; isWeekly: boolean }) => {
  const { data, isWeekly } = props;
  const location = useLocation();

  return (
    <Fragment key={data.employee.id}>
      <EmployeeData>
        {data.employee.profilePicture ? (
          <Avatar sx={{ width: '32px', height: '32px' }} src={data.employee?.profilePicture} />
        ) : (
          <Avatar sx={{ width: '32px', height: '32px' }}>
            <EditEmployeeAvatar />
          </Avatar>
        )}
        <Box sx={{ marginLeft: '30px', marginRight: '10px', position: 'absolute' }}>
          <EmployeeName active={data.editable}>
            {data.employee?.firstName} {data.employee?.lastName}
          </EmployeeName>
          {location.pathname === '/dashboard' ? (
            <EmployeeRole>{data.employee?.functionName}</EmployeeRole>
          ) : (
            <EmployeeRole>{generateEmployeeFunction(data.employee.functionName)}</EmployeeRole>
          )}
          {isWeekly && (
            <WeeklyIcon>
              <WeeklyContractIcon />
            </WeeklyIcon>
          )}
        </Box>
      </EmployeeData>
    </Fragment>
  );
};

export default GridCardEmployee;
