import { styled, Box, Modal, Typography, Tabs } from '@mui/material';
import { UPInputLabel } from 'components/input/styles';

export const ModalWrapper = styled(Modal)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '944px',
  maxHeight: '650px',
  height: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.down('lg')]: {
    width: '720px',
  },
  [theme.breakpoints.down(800)]: {
    width: '96%',
  },
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  background: 'white',
  padding: '32px 32px 0px 32px',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));
export const ModalContainer = styled(Box)(() => ({
  width: '100%',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
  height: '500px',
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
}));

export const ModalFooter = styled(Box)(() => ({
  background: 'white',
  padding: '12px 0px',
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
}));

export const TabsContainer = styled(Tabs)(({ theme }) => ({
  '.MuiTabs-flexContainer': {
    justifyContent: 'center',
  },
  [theme.breakpoints.down(722)]: {
    '.MuiTabs-flexContainer': {
      display: 'none',
    },
  },
}));

export const EmployeeModalInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '34px',
  [theme.breakpoints.down(722)]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const DependentsContainer = styled(Box)(({ theme }) => ({
  marginTop: '0px',
  display: 'flex',

  [theme.breakpoints.down(722)]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const WorkerclassBox = styled(Box)(({ theme }) => ({
  marginRight: '32px',
  width: '32%',

  [theme.breakpoints.down(722)]: {
    marginRight: ' unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const DependentsBottomContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '50%',
  [theme.breakpoints.down(722)]: {
    width: '100%',
    flexDirection: 'column',
  },
}));

export const EmployeeInfoSmallBox = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  width: '25%',
  [theme.breakpoints.down(722)]: {
    marginRight: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const InactiveBoxStyled = styled(Box)(({ theme }) => ({
  marginLeft: 'auto',
  position: 'absolute',
  right: '5px',
  [theme.breakpoints.down(722)]: {
    display: 'none',
  },
}));

export const AccountSmallBox = styled(Box)(({ theme }) => ({
  width: '33.3%',
  [theme.breakpoints.down(722)]: {
    marginBottom: '16px',
    width: '100%',
  },
}));
export const PostalCodeBox = styled(Box)(({ theme }) => ({
  width: '17%',
  [theme.breakpoints.down(722)]: {
    width: '50%',
  },
}));

export const EmailBox = styled(Box)(({ theme }) => ({
  width: '50%',
  marginRight: '16px',
  [theme.breakpoints.down(722)]: {
    marginRight: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const EmployeeBigBox = styled(Box)(({ theme }) => ({
  width: '50%',
  marginRight: '16px',
  [theme.breakpoints.down(722)]: {
    marginRight: 'unset',
    width: '100%',
  },
}));

export const CountryBox = styled(Box)(({ theme }) => ({
  width: '50%',
  marginTop: '24px',
  [theme.breakpoints.down(722)]: {
    width: '100%',
    marginTop: '16px',
  },
}));

export const ContactInfoSmallContainer = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  display: 'flex',
  width: '25%',
  [theme.breakpoints.down(722)]: {
    marginRight: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const LanguageBox = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  width: '10%',
  [theme.breakpoints.down(722)]: {
    marginRight: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const PersonalInfoSmallBoxWithNoMarginRight = styled(Box)(({ theme }) => ({
  marginLeft: '16px',
  [theme.breakpoints.down(722)]: {
    marginLeft: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const AccountWithMarginLeftBox = styled(Box)(({ theme }) => ({
  marginLeft: '16px',
  width: '33.3%',
  [theme.breakpoints.down(722)]: {
    marginLeft: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const ExpirationDateBox = styled(Box)(({ theme }) => ({
  width: '30%',
  [theme.breakpoints.down(722)]: {
    width: '100%',
  },
}));

export const AccountWithMarginRightBox = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  width: '33.3%',
  [theme.breakpoints.down(722)]: {
    marginRight: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));

export const TabsContainerContractsListView = styled(Tabs)(() => ({
  '.MuiTabs-flexContainer': {
    justifyContent: 'start',
  },
}));

export const SmallBoxWithNoMargin = styled(Box)(({ theme }) => ({
  width: '25%',
  [theme.breakpoints.down(722)]: {
    marginBottom: '16px',
    width: '100%',
  },
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(722)]: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '24px',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  display: 'block',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 14,
  textAlign: 'left',
}));

export const ContentContainer = styled(Box)<{ disableScroll?: boolean }>(({ theme, disableScroll = true }) => ({
  overflowY: disableScroll ? 'hidden' : 'scroll',
  background: 'rgb(250, 250, 250)',
  display: 'flex',
  flexDirection: 'column',
  height: '480px',
  padding: '32px',
  gap: '32px',
  [theme.breakpoints.down(722)]: {
    height: '470px',
  },
}));

export const ErrorText = styled(UPInputLabel)(({ theme }) => ({
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));

export const ModalCloseIcon = styled(Box)(() => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const StayAddressCheckboxStyled = styled(Box)(({ theme }) => ({
  marginTop: '45px',
  marginLeft: '50px',
  [theme.breakpoints.down(722)]: {
    marginLeft: 'unset',
    marginTop: '20px',
  },
}));
