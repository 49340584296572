import { Backdrop } from '@mui/material';
import {
  DeleteModalStyled,
  ModalContainer,
  DeleteModalTitle,
  ModalActionContainer,
  ModalActionButton,
} from 'components/delete-modal/styles';
import { MessageModalProps } from './types';

const MessageModal = (props: MessageModalProps): JSX.Element => {
  const { open, onClose, message, buttonText } = props;

  return (
    <Backdrop open={open} sx={{ zIndex: '1' }}>
      <DeleteModalStyled open={open} onClose={onClose}>
        <ModalContainer>
          <DeleteModalTitle>{message}</DeleteModalTitle>
          <ModalActionContainer>
            <ModalActionButton text={buttonText} outlined onClick={onClose} />
          </ModalActionContainer>
        </ModalContainer>
      </DeleteModalStyled>
    </Backdrop>
  );
};

export default MessageModal;
