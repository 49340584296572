import { UPDropdownItem } from 'components/dropdown';
import { t } from 'i18next';
import { GENERAL__ARB, GENERAL__BED, GENERAL__EXT, GENERAL__FLEX, GENERAL__STUD } from 'translations/constants';

export enum ContractTypes {
  STUD = 'STUD',
  FLEX = 'FLEX',
  ARB = 'ARB',
  EXT = 'EXT',
  BED = 'BED',
}

export const ContractTypeOptions = (): UPDropdownItem[] => {
  return [
    {
      label: t(GENERAL__STUD),
      value: ContractTypes.STUD,
    },
    {
      label: t(GENERAL__FLEX),
      value: ContractTypes.FLEX,
    },
    {
      label: t(GENERAL__ARB),
      value: ContractTypes.ARB,
    },
    {
      label: t(GENERAL__EXT),
      value: ContractTypes.EXT,
    },
    {
      label: t(GENERAL__BED),
      value: ContractTypes.BED,
    },
  ];
};
