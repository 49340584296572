import { Link, styled } from '@mui/material';

export const UPLinkStyled = styled(Link, {
  shouldForwardProp: prop => prop !== 'small',
})<{ small: boolean }>(({ theme, small }) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.fontSize,
  fontFamily: theme.typography.fontFamily,
  color: theme.palette.secondary.contrastText,
  ':hover': {
    color: theme.palette.primary.main,
  },
  ...(small && {
    fontSize: '12px',
  }),
}));
