import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  checkIsParentCompanyRequest,
  deleteCompanyRequest,
  getAllAsDropdownItemsRequest,
  getAllCompaniesRequest,
  getAllUserCompaniesAsDropdownItemsRequest,
  getAllUserCompaniesRequest,
  getChildCompaniesRequest,
  getCompanyByIdRequest,
  getParentCompaniesRequest,
  getExternalReferenceRequest,
  saveCompanyRequest,
  updateCompanyRequest,
  updateCompanyExternalReferencesRequest,
} from 'api/requests/company-requests';
import { UPDropdownItem } from 'components/dropdown';
import {
  COMPANY__GENERAL_LOADING,
  COMPANY__CHECK_IS_PARENT_COMPANY,
  COMPANY__CLEAR,
  COMPANY__CLEAR_COMPANIES,
  COMPANY__CLEAR_IS_PARENT_COMPANY,
  COMPANY__CLEAR_SAVE_COMPANY_STATUS,
  COMPANY__CLEAR_UPDATE_COMPANY_STATUS,
  COMPANY__DELETE,
  COMPANY__DELETE_ERROR,
  COMPANY__DELETE_SUCCESS,
  COMPANY__GET_ALL,
  COMPANY__GET_BY_ID,
  COMPANY__GET_CHILD_COMPANIES,
  COMPANY__GET_COMPANIES_AS_DROPDOWN_ITEMS,
  COMPANY__GET_COMPANY,
  COMPANY__GET_COMPANY_ERROR,
  COMPANY__GET_PARENT_COMPANIES,
  COMPANY__GET_SALARY_ENGINE_REFERENCE_ID,
  COMPANY__SAVE,
  COMPANY__SAVE_ERROR,
  COMPANY__SAVE_SUCCESS,
  COMPANY__SET_CHILD_COMPANIES,
  COMPANY__SET_CHILD_COMPANIES_ERROR,
  COMPANY__SET_COMPANIES,
  COMPANY__SET_COMPANIES_AS_DROPDOWN_ITEMS,
  COMPANY__SET_COMPANIES_AS_DROPDOWN_ITEMS_ERROR,
  COMPANY__SET_COMPANIES_ERROR,
  COMPANY__SET_COMPANY_ID,
  COMPANY__SET_IS_PARENT_COMPANY,
  COMPANY__SET_PARENT_COMPANIES,
  COMPANY__SET_SALARY_ENGINE_REFERENCE_ID,
  COMPANY__SET_SALARY_ENGINE_REFERENCE_ID_ERROR,
  COMPANY__UPDATE,
  COMPANY__UPDATE_ERROR,
  COMPANY__UPDATE_SUCCESS,
  COMPANY__GET_USER_COMPANIES_AS_DROPDOWN_ITEMS,
  COMPANY__SET_USER_COMPANIES_AS_DROPDOWN_ITEMS,
  COMPANY__GET_USER_COMPANIES,
  COMPANY__PUT_EXTERNAL_REFERENCES,
} from 'store/constants';
import { ICompanyGridDetails, ICompany } from 'types/company';
import {
  ICompanyExternalReferences,
  IEditableCompanyExternalReferences,
} from 'types/company/ICompanyExternalReferences';
import logError from 'utils/log-error';

export const setCompanyRequestsLoadingAction = createAction<boolean>(COMPANY__GENERAL_LOADING);

export const setCompaniesAction = createAction<ICompanyGridDetails[]>(COMPANY__SET_COMPANIES);
export const setCompaniesErrorAction = createAction<boolean>(COMPANY__SET_COMPANIES_ERROR);
export const clearCompaniesAction = createAction<void>(COMPANY__CLEAR_COMPANIES);

export const getCompaniesAction = createAsyncThunk(COMPANY__GET_ALL, async (_, thunkApi) => {
  thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
  thunkApi.dispatch(setCompaniesErrorAction(false));

  try {
    const response = await getAllCompaniesRequest();
    const sortedResponse = response.slice().sort((a, b) => a.Name.localeCompare(b.Name));
    thunkApi.dispatch(setCompaniesAction(sortedResponse));
  } catch (error) {
    thunkApi.dispatch(setCompaniesErrorAction(true));
  }
  thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
});

export const getCompanyAction = createAction<ICompany>(COMPANY__GET_COMPANY);
export const getCompanyErrorAction = createAction<boolean>(COMPANY__GET_COMPANY_ERROR);

export const getCompanyByIdAction = createAsyncThunk(COMPANY__GET_BY_ID, async (companyId: number, thunkApi) => {
  thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
  thunkApi.dispatch(getCompanyErrorAction(false));

  try {
    const response = await getCompanyByIdRequest(companyId);
    thunkApi.dispatch(getCompanyAction(response));
  } catch (error) {
    thunkApi.dispatch(getCompanyErrorAction(true));
  }
  thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
});

export const saveCompanySuccessAction = createAction<boolean>(COMPANY__SAVE_SUCCESS);
export const saveCompanyErrorAction = createAction<boolean>(COMPANY__SAVE_ERROR);
export const setCompanyIdAction = createAction<number>(COMPANY__SET_COMPANY_ID);
export const clearSaveCompanyStatusAction = createAction<void>(COMPANY__CLEAR_SAVE_COMPANY_STATUS);

export const saveCompanyAction = createAsyncThunk(COMPANY__SAVE, async (company: ICompany, thunkApi) => {
  thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
  thunkApi.dispatch(saveCompanyErrorAction(false));
  thunkApi.dispatch(saveCompanySuccessAction(false));
  try {
    const companyId = await saveCompanyRequest(company);
    thunkApi.dispatch(saveCompanySuccessAction(true));
    thunkApi.dispatch(setCompanyIdAction(companyId));
  } catch (error) {
    thunkApi.dispatch(saveCompanyErrorAction(true));
  }
  thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
});

export const updateCompanySuccessAction = createAction<boolean>(COMPANY__UPDATE_SUCCESS);
export const updateCompanyErrorAction = createAction<boolean>(COMPANY__UPDATE_ERROR);
export const clearUpdateCompanyStatusAction = createAction<void>(COMPANY__CLEAR_UPDATE_COMPANY_STATUS);
export const companyErrorMessageAction = createAction<any>('');

export const updateCompanyAction = createAsyncThunk(COMPANY__UPDATE, async (props: any, thunkApi) => {
  thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
  thunkApi.dispatch(updateCompanyErrorAction(false));
  thunkApi.dispatch(updateCompanySuccessAction(false));
  thunkApi.dispatch(companyErrorMessageAction(null));
  try {
    await updateCompanyRequest(props);
    thunkApi.dispatch(updateCompanySuccessAction(true));
  } catch (error: any) {
    thunkApi.dispatch(companyErrorMessageAction(error.response.data.error));
    thunkApi.dispatch(updateCompanyErrorAction(true));
  }

  thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
});

export const deleteCompanySuccessAction = createAction<boolean>(COMPANY__DELETE_SUCCESS);
export const deleteCompanyErrorAction = createAction<boolean>(COMPANY__DELETE_ERROR);
export const deleteCompanyAction = createAsyncThunk(COMPANY__DELETE, async (companyId: number, thunkApi) => {
  thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
  thunkApi.dispatch(deleteCompanyErrorAction(false));
  try {
    await deleteCompanyRequest(companyId);
    thunkApi.dispatch(deleteCompanySuccessAction(true));
  } catch (error) {
    thunkApi.dispatch(deleteCompanyErrorAction(true));
  }
  thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
});

export const clearCompanyAction = createAction<void>(COMPANY__CLEAR);

export const setParentCompaniesAction = createAction<UPDropdownItem[]>(COMPANY__SET_PARENT_COMPANIES);

export const getParentCompaniesAction = createAsyncThunk(
  COMPANY__GET_PARENT_COMPANIES,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));

    try {
      const response = await getParentCompaniesRequest(companyId);
      thunkApi.dispatch(setParentCompaniesAction(response));
    } catch (error) {
      logError(error);
    }

    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);

export const setChildCompaniesAction = createAction<ICompanyGridDetails[]>(COMPANY__SET_CHILD_COMPANIES);
export const setChildCompaniesErrorAction = createAction<boolean>(COMPANY__SET_CHILD_COMPANIES_ERROR);

export const getChildCompaniesAction = createAsyncThunk(
  COMPANY__GET_CHILD_COMPANIES,
  async (parentCompanyId: number, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    thunkApi.dispatch(setChildCompaniesErrorAction(false));

    try {
      const response = await getChildCompaniesRequest(parentCompanyId);
      thunkApi.dispatch(setChildCompaniesAction(response));
    } catch (error) {
      thunkApi.dispatch(setChildCompaniesErrorAction(true));
    }
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);
export const setIsParentCompanyAction = createAction<boolean>(COMPANY__SET_IS_PARENT_COMPANY);
export const clearIsParentCompanyAction = createAction<void>(COMPANY__CLEAR_IS_PARENT_COMPANY);
export const checkIsParentCompanyAction = createAsyncThunk(
  COMPANY__CHECK_IS_PARENT_COMPANY,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    try {
      const response = await checkIsParentCompanyRequest(companyId);
      thunkApi.dispatch(setIsParentCompanyAction(response));
    } catch (error) {
      logError(error);
    }
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);

export const setExternalReferenceAction = createAction<ICompanyExternalReferences>(
  COMPANY__SET_SALARY_ENGINE_REFERENCE_ID,
);
export const setExternalReferenceActionErrorAction = createAction<boolean>(
  COMPANY__SET_SALARY_ENGINE_REFERENCE_ID_ERROR,
);

export const getExternalReferenceAction = createAsyncThunk(
  COMPANY__GET_SALARY_ENGINE_REFERENCE_ID,
  async (companyId: number, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    try {
      const response = await getExternalReferenceRequest(companyId);
      thunkApi.dispatch(setExternalReferenceAction(response));
    } catch (error) {
      thunkApi.dispatch(setExternalReferenceActionErrorAction(true));
    }
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);

export const setCompaniesAsDropdownItemsAction = createAction<UPDropdownItem[]>(
  COMPANY__SET_COMPANIES_AS_DROPDOWN_ITEMS,
);
export const setCompaniesAsDropdownItemsErrorAction = createAction<boolean>(
  COMPANY__SET_COMPANIES_AS_DROPDOWN_ITEMS_ERROR,
);

export const getCompaniesAsDropdownItemsAction = createAsyncThunk(
  COMPANY__GET_COMPANIES_AS_DROPDOWN_ITEMS,
  async (_, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    try {
      const response = await getAllAsDropdownItemsRequest();
      thunkApi.dispatch(setCompaniesAsDropdownItemsAction(response.sort((a, b) => a.label.localeCompare(b.label))));
    } catch (error) {
      thunkApi.dispatch(setCompaniesAsDropdownItemsErrorAction(true));
    }
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);

export const setAllUserCompaniesAsDropdownItemsAction = createAction<UPDropdownItem[]>(
  COMPANY__SET_USER_COMPANIES_AS_DROPDOWN_ITEMS,
);

export const getAllUserCompaniesAsDropdownItemsAction = createAsyncThunk(
  COMPANY__GET_USER_COMPANIES_AS_DROPDOWN_ITEMS,
  async (userId: number, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    try {
      const response = await getAllUserCompaniesAsDropdownItemsRequest(userId);
      thunkApi.dispatch(
        setAllUserCompaniesAsDropdownItemsAction(response.sort((a, b) => a.label.localeCompare(b.label))),
      );
    } catch (error) {
      thunkApi.dispatch(setCompaniesAsDropdownItemsErrorAction(true));
    }
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);

export const getAllUserCompaniesAction = createAsyncThunk(
  COMPANY__GET_USER_COMPANIES,
  async (userId: number, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    try {
      const response = await getAllUserCompaniesRequest(userId);
      thunkApi.dispatch(setCompaniesAction(response));
    } catch (error) {
      thunkApi.dispatch(setCompaniesErrorAction(true));
    }
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);

export const putCompanyExternalReferences = createAsyncThunk(
  COMPANY__PUT_EXTERNAL_REFERENCES,
  async (props: { companyId: string; data: IEditableCompanyExternalReferences }, thunkApi) => {
    thunkApi.dispatch(setCompanyRequestsLoadingAction(true));
    await updateCompanyExternalReferencesRequest(props);
    thunkApi.dispatch(setCompanyRequestsLoadingAction(false));
  },
);
