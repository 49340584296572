import ICompanyVendor from 'types/company/managers/ICompanyVendor';

export enum VendorGridColumns {
  ID = 'id',
  VENDOR_NAME = 'name',
  EMAIL = 'email',
  CONTACT_PERSON = 'contactPerson',
  PHONE_NUMBER = 'phone',
  ACTIONS = 'actions',
}

export interface AddEditVendorModalProps {
  onClose: () => void;
  vendor?: ICompanyVendor;
}
