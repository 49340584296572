import { Alert, Box } from '@mui/material';
import UPButton from 'components/button';
import UPInput from 'components/input';
import { AvailabilityIntervalsFormat } from 'constants/AvailabilityIntervalsFormat';
import { EmployerLabel } from 'constants/EmployerLabel';
import { toNumber } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store';
import { getParitairComiteSelectItemsAction } from 'store/actions/paritair-comite-actions';
import {
  addPayrollAction,
  addPayrollSuccessAction,
  getPayrollAction,
  updatePayrollAction,
  updatePayrollSuccessAction,
} from 'store/actions/payroll-actions';
import { paritairComiteSelectItemsSelector } from 'store/selectors/paritair-comite-selectors';
import {
  addPayrollSuccessSelector,
  payrollSelector,
  updatePayrollSuccessSelector,
} from 'store/selectors/payroll-selectors';
import ICoefficientPerWorkerclass from 'types/company/payroll/ICoefficientPerWorkerclass';
import { allowOnlyPositiveNumber, getUpDropdownItemsFromEnum } from 'utils/helpers';
import { CoefficientsPerWorkerclassGrid } from '.';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import { UPDropdown, UPDropdownItem, UPMultipleSelectionDropdown } from '../../../components/dropdown/index';
import {
  COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY,
  COMPANY_PAYROLL__AFTERNOON_INTERVAL,
  COMPANY_PAYROLL__EMPLOYER_LABEL,
  COMPANY_PAYROLL__EVENING_INTERVAL,
  COMPANY_PAYROLL__FACTOR_S,
  COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT,
  COMPANY_PAYROLL__MAXIMUM_PLANNED_HOURS,
  COMPANY_PAYROLL__MINIMUM_PERFORMED_HOURS,
  COMPANY_PAYROLL__MORNING_INTERVAL,
  COMPANY_PAYROLL__PARITAIR_COMITE,
  COMPANY_PAYROLL__WORKHOURS_SETTINGS,
  GENERAL__CANCEL_BUTTON,
  GENERAL__SAVE_BUTTON,
  GENERAL__SELECT,
  VALIDATION__IS_REQUIRED,
  VALIDATION__SHOULD_BE_GREATER_THAN,
} from '../../../translations/constants';
import ICompanyPayrollGeneral from '../../../types/company/payroll/ICompanyPayrollGeneral';
import { areAvailabilityIntervalsValid } from '../helpers';
import { CloseAlertIcon } from '../styles';
import { PayrollInputLabel } from './styles';

const PayrollGeneralInitialState: ICompanyPayrollGeneral = {
  paritairComites: [],
  coefficientsPerWorkerclass: [],
  id: 0,
  companyId: 0,
  employerLabel: '',
  morningInterval: '',
  afternoonInterval: '',
  eveningInterval: '',
};

const PayrollGeneral = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();

  const paritairComiteOptions = useAppSelector(paritairComiteSelectItemsSelector);
  const payroll = useAppSelector(payrollSelector);
  const addPayrollSuccess = useAppSelector(addPayrollSuccessSelector);
  const updatePayrollSuccess = useAppSelector(updatePayrollSuccessSelector);

  const [coefficientsPerWorkerclass, setCoefficientsPerWorkerclass] = useState<ICoefficientPerWorkerclass[]>([]);

  const employerLabelOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsFromEnum(EmployerLabel);
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<ICompanyPayrollGeneral>({
    defaultValues: PayrollGeneralInitialState,
  });

  useEffect(() => {
    dispatch(getParitairComiteSelectItemsAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPayrollAction(toNumber(companyId)));
  }, [dispatch, companyId]);

  useEffect(() => {
    if (payroll) {
      reset(payroll);
      setCoefficientsPerWorkerclass(payroll.coefficientsPerWorkerclass);
    }
  }, [payroll, reset]);

  useEffect(() => {
    return () => {
      dispatch(addPayrollSuccessAction(false));
      dispatch(updatePayrollSuccessAction(false));
    };
  }, [dispatch]);

  const onSavePayroll: SubmitHandler<any> = useCallback(() => {
    const values = getValues();
    const morningIntervalEnds = values.morningInterval.split(' - ');
    const afternoonIntervalEnds = values.afternoonInterval.split(' - ');
    const eveningIntervalEnds = values.eveningInterval.split(' - ');

    const areIntervalsValid = areAvailabilityIntervalsValid(
      morningIntervalEnds,
      afternoonIntervalEnds,
      eveningIntervalEnds,
      setError,
      t,
    );

    if (!areIntervalsValid) {
      return;
    }

    if (values.paritairComites.length === 0) {
      setError('paritairComites', { type: 'required' });
      return;
    }
    setValue('companyId', toNumber(companyId));
    if (values.id === 0) {
      setValue(
        'coefficientsPerWorkerclass',
        coefficientsPerWorkerclass.map(c => ({ ...c, id: 0, coefficient: toNumber(c.coefficient) })),
      );
      dispatch(addPayrollAction(getValues()));
    } else {
      setValue(
        'coefficientsPerWorkerclass',
        coefficientsPerWorkerclass.map(c => ({ ...c, coefficient: toNumber(c.coefficient) })),
      );
      dispatch(updatePayrollAction(getValues()));
    }

    // intervals should not overlap, afternoon should start after morning ends, evening should start after afternoon ends
  }, [coefficientsPerWorkerclass, companyId, dispatch, getValues, setError, setValue, t]);

  const onCancel = useCallback(() => {
    reset(payroll);
    navigate(`/company/${companyId}/general`);
  }, [companyId, navigate, payroll, reset]);

  return (
    <Box sx={{ padding: '32px 32px 0px 32px' }}>
      <Box sx={{ width: '40%' }}>
        <Box sx={{ marginBottom: '32px' }}>
          <Controller
            name="paritairComites"
            control={control}
            rules={{ required: { value: true, message: t(VALIDATION__IS_REQUIRED) } }}
            render={({ field }) => (
              <UPMultipleSelectionDropdown
                items={paritairComiteOptions}
                value={field.value}
                handleChange={field.onChange}
                placeholder={t(GENERAL__SELECT)}
                dropdownLabel={t(COMPANY_PAYROLL__PARITAIR_COMITE)}
                required
                error={!!errors.paritairComites}
                helperText={t(VALIDATION__IS_REQUIRED)}
              />
            )}
          />
        </Box>
        <Box sx={{ marginBottom: '32px' }}>
          <CoefficientsPerWorkerclassGrid
            coefficientsPerWorkerclass={coefficientsPerWorkerclass}
            setCoefficientsPerWorkerclass={setCoefficientsPerWorkerclass}
          />
        </Box>

        <Box sx={{ marginBottom: '32px' }}>
          <Controller
            name="employerLabel"
            control={control}
            rules={{ required: { value: true, message: t(VALIDATION__IS_REQUIRED) } }}
            render={({ field }) => (
              <UPDropdown
                items={employerLabelOptions}
                value={field.value}
                onChange={field.onChange}
                placeholder={t(GENERAL__SELECT)}
                label={t(COMPANY_PAYROLL__EMPLOYER_LABEL)}
                error={!!errors.employerLabel}
                helperText={errors.employerLabel?.message}
                required
              />
            )}
          />
        </Box>
        <Box>
          <Box sx={{ marginTop: '32px' }}>
            <PayrollInputLabel>{t(COMPANY_PAYROLL__WORKHOURS_SETTINGS)}</PayrollInputLabel>
            <Box sx={{ marginTop: '16px' }}>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="morningInterval"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/,
                      message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT),
                    },
                    required: { value: true, message: t(VALIDATION__IS_REQUIRED) },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value || ''}
                      onChange={onChange}
                      placeholder={AvailabilityIntervalsFormat}
                      label={t(COMPANY_PAYROLL__MORNING_INTERVAL)}
                      error={!!errors.morningInterval}
                      helperText={errors.morningInterval?.message}
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="afternoonInterval"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/,
                      message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT),
                    },
                    required: { value: true, message: t(VALIDATION__IS_REQUIRED) },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value || ''}
                      onChange={onChange}
                      placeholder={AvailabilityIntervalsFormat}
                      label={t(COMPANY_PAYROLL__AFTERNOON_INTERVAL)}
                      error={!!errors.afternoonInterval}
                      helperText={errors.afternoonInterval?.message}
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '16px' }}>
                <Controller
                  name="eveningInterval"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^([01]\d|2[0-3]):([0-5]\d) - ([01]\d|2[0-3]):([0-5]\d)$/,
                      message: t(COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT),
                    },
                    required: { value: true, message: t(VALIDATION__IS_REQUIRED) },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <UPInput
                      value={value || ''}
                      onChange={onChange}
                      placeholder={AvailabilityIntervalsFormat}
                      label={t(COMPANY_PAYROLL__EVENING_INTERVAL)}
                      error={!!errors.eveningInterval}
                      helperText={errors.eveningInterval?.message}
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ marginTop: '32px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <Box sx={{ marginRight: '16px' }}>
                  <UPButton
                    text={t(GENERAL__SAVE_BUTTON)}
                    onClick={() => handleSubmit(onSavePayroll, error => console.log(error))()}
                  />
                </Box>
                <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onCancel} outlined />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: '32px' }}>
        {(addPayrollSuccess || updatePayrollSuccess) && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '800px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(addPayrollSuccessAction(false));
                  dispatch(updatePayrollSuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY)}
          </Alert>
        )}
      </Box>
    </Box>
  );
};

export default PayrollGeneral;
