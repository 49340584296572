import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { getCostCentersAction, getCostCentersDropdownItemsAction } from 'store/actions/company-cost-centers-actions';
import { getFunctionsAsDropdownItemsAction } from 'store/actions/company-functions-actions';
import { getLocationsDropdownItemsAction } from 'store/actions/company-locations-actions';
import { getEmployeesWithWorkerclass } from 'store/actions/employee-actions';
import { costCentersDropdownItemsSelector, costCentersSelector } from 'store/selectors/company-cost-centers-selectors';
import { functionsDropdownItemsSelector } from 'store/selectors/company-functions-selectors';
import { locationsDropdownItemsSelector } from 'store/selectors/company-locations-selectors';
import { wageSelector } from 'store/selectors/contract-selectors';
import { employeesWithWorkerclassSelector } from 'store/selectors/employee-selector';

const useContractModalDropdownData = (companyId: number, isEmployee: boolean) => {
  const dispatch = useAppDispatch();

  const functions = useAppSelector(functionsDropdownItemsSelector);
  const costCenters = useAppSelector(costCentersDropdownItemsSelector);
  const locationsDropdownItems = useAppSelector(locationsDropdownItemsSelector);
  const companyEmployees = useAppSelector(employeesWithWorkerclassSelector);
  const wage = useAppSelector(wageSelector);
  const costCentersWithDetails = useAppSelector(costCentersSelector);

  useEffect(() => {
    // dispatch(getFunctionsAsDropdownItemsAction(companyId));
    // dispatch(getCostCentersDropdownItemsAction({ companyId }));
    // dispatch(getLocationsDropdownItemsAction(companyId));
    if (!isEmployee) {
      dispatch(getEmployeesWithWorkerclass(companyId));
      dispatch(getCostCentersAction(companyId));
    }
  }, [companyId, dispatch, isEmployee]);

  return { functions, costCenters, companyEmployees, locationsDropdownItems, wage, costCentersWithDetails };
};

export default useContractModalDropdownData;
