import { Box } from '@mui/material';
import UPTab from 'components/tab';
import { UPTabSection } from 'components/tab/types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import { activeMenuItemSelector } from 'store/selectors/menu-selectors';
import {
  MENU__PAYROLL_SETTINGS,
  PARITAIR_COMITE__GENERAL,
  PARITAIR_COMITE__MINIMUM_WAGES,
  PARITAIR_COMITE__WAGE_CALCULATION,
} from 'translations/constants';
import { useParams } from 'react-router-dom';
import ParitairComiteGeneral from './ParitairComiteGeneral';
import ParitairComiteMinimumWages from './minimum-wages/ParitairComiteMinimumWages';
import WageCalculation from '../payroll-settings/service-codes/ServiceCodes';

const ParitairComiteDetails = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const { paritairComiteId } = useParams();

  const activeItem = useAppSelector(activeMenuItemSelector);

  const ParitairComiteTabs: UPTabSection[] = [
    { title: t(PARITAIR_COMITE__GENERAL), route: 'general' },
    { title: t(PARITAIR_COMITE__MINIMUM_WAGES), route: 'minimum-wages', isVisible: paritairComiteId !== undefined },
  ];

  useEffect(() => {
    if (activeItem !== t(MENU__PAYROLL_SETTINGS)) {
      dispatch(setMenuStatusAction(t(MENU__PAYROLL_SETTINGS)));
    }
  }, [activeItem, dispatch, t]);

  return (
    <Box>
      <UPTab sections={ParitairComiteTabs}>
        <ParitairComiteGeneral />
        <ParitairComiteMinimumWages />
      </UPTab>
    </Box>
  );
};

export default ParitairComiteDetails;
