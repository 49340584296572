import { Paper } from '@mui/material';
import { IconButtonStyled } from 'components/app-container/styles';
import { ReactComponent as SearchIcon } from '../../assets/icons/Search.svg';
import { UpInputBaseStyled, UPInputLabel, UPSearchInputStyled } from './styles';
import { UPSearchInputProps } from './types';

const UPSearchInput = (props: UPSearchInputProps): JSX.Element => {
  const { label, required, placeholder, value, onChange, onSearch, disabled } = props;
  return (
    <UPSearchInputStyled>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>
      <Paper
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '49px',
          padding: '8px',
          boxShadow: 'none',
          border: '1px solid #cccdcd',

          '&:hover': !disabled && {
            borderColor: 'rgba(0,0,0,0.87)',
          },

          '&:focus': {
            outline: '1px solid #008C15',
          },
        }}
      >
        <UpInputBaseStyled
          placeholder={placeholder}
          value={value}
          onChange={e => {
            onChange(e.target.value);
          }}
          disabled={disabled}
          onKeyUp={event => {
            if (event.key === 'Enter') {
              onSearch();
            }
          }}
        />
        <IconButtonStyled type="button" disabled={disabled}>
          <SearchIcon
            onClick={() => {
              onSearch();
            }}
          />
        </IconButtonStyled>
      </Paper>
    </UPSearchInputStyled>
  );
};

export default UPSearchInput;
