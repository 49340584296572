// general
export const GENERAL__SAVE_BUTTON = '[GENERAL] SAVE_BUTTON';
export const GENERAL__SEND_BUTTON = '[GENERAL] SEND_BUTTON';
export const GENERAL__SELECT = '[GENERAL] SELECT';
export const GENERAL__EDIT = '[GENERAL] EDIT';
export const GENERAL__CANCEL_BUTTON = '[GENERAL] CANCEL_BUTTON';
export const GENERAL__YES = '[GENERAL] YES';
export const GENERAL__SEARCH = '[GENERAL] SEARCH';
export const GENERAL__ADDRESS = '[GENERAL] ADDRESS';
export const GENERAL__SAVE_AND_NEXT_BUTTON = '[GENERAL] SAVE_AND_NEXT_BUTTON';
export const GENERAL__DELETE = '[GENERAL] DELETE';
export const GENERAL__DELETE_SELECTED = '[GENERAL] DELETE_SELECTED';
export const GENERAL__DELETE_MESSAGE = '[GENERAL] DELETE_MESSAGE';
export const GENERAL__ERROR_MESSAGE = '[GENERAL] ERROR_MESSAGE';
export const GENERAL__CREATE = '[GENERAL] CREATE';
export const GENERAL__QUANTITY = '[GENERAL] QUANTITY';
export const GENERAL__CLEAR_ALL = '[GENERAL] CLEAR_ALL';
export const GENERAL__NO = '[GENERAL] NO';
export const GENERAL__FIRST_NAME = '[GENERAL] FIRST_NAME';
export const GENERAL__LAST_NAME = '[GENERAL] LAST_NAME';
export const GENERAL__NAME = '[GENERAL] NAME';
export const GENERAL__EMAIL = '[GENERAL] EMAIL';
export const GENERAL__USERNAME = '[GENERAL] USERNAME';
export const GENERAL__LANGUAGE = '[GENERAL] LANGUAGE';
export const GENERAL__PHONE_NUMBER = '[GENERAL] PHONE_NUMBER';
export const GENERAL__CONTACT = '[GENERAL] CONTACT';
export const GENERAL__GENDER = '[GENERAL] GENDER';
export const GENDER__MAN = '[GENDER] MAN';
export const GENDER__WOMAN = '[GENDER] WOMAN';
export const GENERAL__IMPERSONATE = '[GENERAL] IMPERSONATE';
export const GENERAL__INVITE = '[GENERAL] INVITE';
export const GENERAL__ALL = '[GENERAL] ALL';
export const GENERAL__TOTAL = '[GENERAL] TOTAL';
export const GENERAL__YOU_ARE_LOGGED_IN_AS = '[GENERAL] YOU_ARE_LOGGED_IN_AS';
export const GENERAL__CLICK_HERE_TO_CANCEL = '[GENERAL] CLICK_HERE_TO_CANCEL';
export const GENERAL__COUNTRY = '[GENERAL] COUNTRY';
export const GENERAL__ITEMS_PER_PAGE = '[GENERAL] ITEMS_PER_PAGE';
export const GENERAL__AUTOMATIC = '[GENERAL] AUTOMATIC';
export const GENERAL__CONFIRM = '[GENERAL] CONFIRM';
export const GENERAL__DAY = '[GENERAL] DAY';
export const GENERAL__ADD = '[GENERAL] ADD';
export const GENERAL__AND = '[GENERAL] AND';
export const GENERAL__AGE = '[GENERAL] AGE';
export const GENERAL__COPIED_TO_CLIPBOARD = '[GENERAL] COPIED_TO_CLIPBOARD';
export const GENERAL__LINK_COPIED = '[GENERAL] LINK_COPIED';
export const GENERAL__LAST_MODIFIED_BY = '[GENERAL] LAST_MODIFIED_BY';
export const GENERAL__LAST_MODIFIED_DATE = '[GENERAL] LAST_MODIFIED_DATE';
export const GENERAL__HAS_TWO_FACTOR_AUTH = '[GENERAL] HAS_TWO_FACTOR_AUTH';
export const GENERAL__UNSAVED_CHANGES = '[GENERAL] UNSAVED_CHANGES';
export const GENERAL__DOWNLOAD = '[GENERAL] DOWNLOAD';
export const GENERAL__SHOW = '[GENERAL] SHOW';
export const GENERAL__SELECTED = '[GENERAL] SELECTED';
export const GENERAL__WORKERCLASS = '[GENERAL] WORKERCLASS';
export const GENERAL__GO = '[GENERAL] GO';
export const GENERAL__CLOSE = '[GENERAL] CLOSE';
export const GENERAL__NO_DATA_TO_SHOW = '[GENERAL] NO_DATA_TO_SHOW';
export const GENERAL__YEAR = '[GENERAL] YEAR';
export const GENERAL__MONTH = '[GENERAL] MONTH';
export const GENERAL__JANUARY = '[GENERAL] JANUARY';
export const GENERAL__FEBRUARY = '[GENERAL] FEBRUARY';
export const GENERAL__MARCH = '[GENERAL] MARCH';
export const GENERAL__APRIL = '[GENERAL] APRIL';
export const GENERAL__MAY = '[GENERAL] MAY';
export const GENERAL__JUNE = '[GENERAL] JUNE';
export const GENERAL__JULY = '[GENERAL] JULY';
export const GENERAL__AUGUST = '[GENERAL] AUGUST';
export const GENERAL__SEPTEMBER = '[GENERAL] SEPTEMBER';
export const GENERAL__OCTOBER = '[GENERAL] OCTOBER';
export const GENERAL__NOVEMBER = '[GENERAL] NOVEMBER';
export const GENERAL__DECEMBER = '[GENERAL] DECEMBER';
export const GENERAL__FROM = '[GENERAL] FROM';
export const GENERAL__UNTIL = '[GENERAL] UNTIL';

// worker classes
export const GENERAL__STUD = '[GENERAL] STUD';
export const GENERAL__FLEX = '[GENERAL] FLEX';
export const GENERAL__ARB = '[GENERAL] ARB';
export const GENERAL__BED = '[GENERAL] BED';
export const GENERAL__EXT = '[GENERAL] EXT';

export const DATAGRID__NO_ROWS = '[DATAGRID] NO_ROWS';
export const DATAGRID__ROWS_PER_PAGE = '[DATAGRID] NO_ROWS';
export const DROPDOWN_SEARCH__NO_OPTIONS = '[DROPDOWN_SEARCH] ROWS_PER_PAGE';

export const WEEKDAY__MONDAY = '[WEEKDAY] MONDAY';
export const WEEKDAY__MONDAY_ABBREVIATION = '[WEEKDAY] MONDAY_ABBREVIATION';
export const WEEKDAY__TUESDAY = '[WEEKDAY] TUESDAY';
export const WEEKDAY__TUESDAY_ABBREVIATION = '[WEEKDAY] TUESDAY_ABBREVIATION';
export const WEEKDAY__WEDNESDAY = '[WEEKDAY] WEDNESDAY';
export const WEEKDAY__WEDNESDAY_ABBREVIATION = '[WEEKDAY] WEDNESDAY_ABBREVIATION';
export const WEEKDAY__THURSDAY = '[WEEKDAY] THURSDAY';
export const WEEKDAY__THURSDAY_ABBREVIATION = '[WEEKDAY] THURSDAY_ABBREVIATION';
export const WEEKDAY__FRIDAY = '[WEEKDAY] FRIDAY';
export const WEEKDAY__FRIDAY_ABBREVIATION = '[WEEKDAY] FRIDAY_ABBREVIATION';
export const WEEKDAY__SATURDAY = '[WEEKDAY] SATURDAY';
export const WEEKDAY__SATURDAY_ABBREVIATION = '[WEEKDAY] SATURDAY_ABBREVIATION';
export const WEEKDAY__SUNDAY = '[WEEKDAY] SUNDAY';
export const WEEKDAY__SUNDAY_ABBREVIATION = '[WEEKDAY] SUNDAY_ABBREVIATION';

// login
export const LOGIN__EMAIL_LABEL = '[LOGIN] EMAIL_LABEL';
export const LOGIN__PASSWORD_LABEL = '[LOGIN] PASSWORD_LABEL';
export const LOGIN__PASSWORD_REQUIRED_ERROR = '[LOGIN] PASSWORD_REQUIRED_ERROR';
export const LOGIN__PAGE_TITLE = '[LOGIN] PAGE_TITLE';
export const LOGIN__PAGE_QUOTE = '[LOGIN] PAGE_QUOTE';
export const LOGIN__SIGN_IN = '[LOGIN] SIGN_IN';
export const LOGIN__CREATE_ACCOUNT = '[LOGIN] CREATE_ACCOUNT';
export const LOGIN__FORGOT_PASSWORD = '[LOGIN] FORGOT_PASSWORD';
export const LOGIN__CONTACT_US = '[LOGIN] CONTACT_US';
export const LOGIN__EMAIL_REQUIRED_ERROR = '[LOGIN] EMAIL_REQUIRED_ERROR';
export const LOGIN__EMAIL_MAX_LENGTH_ERROR = '[LOGIN] EMAIL_MAX_LENGTH_ERROR';
export const LOGIN__PASSWORD_MIN_LENGTH_ERROR = '[LOGIN] PASSWORD_MIN_LENGTH_ERROR';
export const LOGIN__INCORRECT_EMAIL_OR_PASSWORD = '[LOGIN] INCORRECT_EMAIL_OR_PASSWORD';
export const LOGIN__CONFIRM_EMAIL_TITLE = '[LOGIN] CONFIRM_EMAIL_TITLE';
export const LOGIN__RESET_PASSWORD_EMAIL_SENT = '[LOGIN] RESET_PASSWORD_EMAIL_SENT';

// password policy
export const PASSWORD_POLICY__PASSWORD_POLICY = '[PASSWORD_POLICY] PASSWORD_POLICY';

export const FOOTER__TERMS_AND_CONDITIONS = '[FOOTER] TERMS_AND_CONDITIONS';
export const FOOTER__PRIVACY_POLICY = '[FOOTER] PRIVACY_POLICY';

export const HEADER__WEEK = '[HEADER] WEEK';

// company
export const COMPANIES__ADD_NEW_COMPANY = '[COMPANIES] ADD_NEW_COMPANY';
export const COMPANY__COMPANY = '[COMPANY] COMPANY';
export const COMPANY__CONTACT = '[COMPANY] CONTACT';
export const COMPANY__LOCATIONS = '[COMPANY] LOCATIONS';
export const COMPANY__PAYROLL = '[COMPANY] PAYROLL';
export const COMPANY__MANAGERS = '[COMPANY] MANAGERS';
export const COMPANY__VENDORS = '[COMPANY] VENDORS';
export const COMPANY__FINANCIAL = '[COMPANY] FINANCIAL';
export const COMPANY__INTEGRATION = '[COMPANY] INTEGRATION';
export const COMPANY__STRUCTURE = '[COMPANY] STRUCTURE';
export const COMPANY__SEARCH_COMPANY_NAME = '[COMPANY] SEARCH_COMPANY_NAME';

export const COMPANY_INFORMATION__COMPANY_NAME = '[COMPANY_INFORMATION] COMPANY_NAME';
export const COMPANY_INFORMATION__COMMERCIAL_NAME = '[COMPANY_INFORMATION] COMMERCIAL_NAME';
export const COMPANY_INFORMATION__STREET_NAME = '[COMPANY_INFORMATION] STREET_NAME';
export const COMPANY_INFORMATION__STREET_NUMBER = '[COMPANY_INFORMATION] STREET_NUMBER';
export const COMPANY_INFORMATION__BUS_NUMBER = '[COMPANY_INFORMATION] BUS_NUMBER';
export const COMPANY_INFORMATION__CITY = '[COMPANY_INFORMATION] CITY';
export const COMPANY_INFORMATION__POSTAL_CODE = '[COMPANY_INFORMATION] POSTAL_CODE';
export const COMPANY_INFORMATION__COMPANY_ID_NUMBER = '[COMPANY_INFORMATION] COMPANY_ID_NUMBER';
export const COMPANY_INFORMATION__COMPANY_FORMAT = '[COMPANY_INFORMATION] COMPANY_FORMAT';
export const COMPANY_INFORMATION__SMALL_COMPANY = '[COMPANY_INFORMATION] SMALL_COMPANY';
export const COMPANY_INFORMATION__UNION_DELEGATION = '[COMPANY_INFORMATION] UNION_DELEGATION';
export const COMPANY_INFORMATION__LOGO = '[COMPANY_INFORMATION] LOGO';
export const COMPANY_INFORMATION__LOGO_PLACEHOLDER = '[COMPANY_INFORMATION] LOGO_PLACEHOLDER';
export const COMPANY_INFORMATION__ACTIVE = '[COMPANY_INFORMATION] ACTIVE';
export const COMPANY_INFORMATION__NON_ACTIVE = '[COMPANY_INFORMATION] NON_ACTIVE';
export const COMPANY_INFORMATION__DELETE_COMPANY = '[COMPANY_INFORMATION] DELETE_COMPANY';
export const COMPANY_INFORMATION__PARENT_COMPANY = '[COMPANY_INFORMATION] PARENT_COMPANY';
export const COMPANY_INFORMATION__PARENT_COMPANY_LABEL = '[COMPANY_INFORMATION] PARENT_COMPANY_LABEL';
export const COMPANY_INFORMATION__COMPANY_ADDED_SUCCESSFULLY = '[COMPANY_INFORMATION] COMPANY_ADDED_SUCCESSFULLY';
export const COMPANY_INFORMATION__COMPANY_UPDATED_SUCCESSFULLY = '[COMPANY_INFORMATION] COMPANY_UPDATED_SUCCESSFULLY';
export const COMPANY_INFORMATION__ASSIGN_CHILD_WARNING = '[COMPANY_INFORMATION] ASSIGN_CHILD_WARNING';

export const COMPANY_CONTACT__CONTACT_NAME = '[COMPANY_CONTACT] CONTACT_NAME';
export const COMPANY_CONTACT__CONTACT_PHONE = '[COMPANY_CONTACT] CONTACT_PHONE';
export const COMPANY_CONTACT__CONTACT_EMAIL = '[COMPANY_CONTACT] CONTACT_EMAIL';
export const COMPANY_CONTACT__CONTACT_UPDATED_SUCCESSFULLY = '[COMPANY_CONTACT] CONTACT_UPDATED_SUCCESSFULLY';

export const COMPANY_PAYROLL__PARITAIR_COMITE = '[COMPANY_PAYROLL] PARITAIR_COMITE';
export const COMPANY_PAYROLL__COEFFICIENT_PER_WORKERCLASS = '[COMPANY_PAYROLL] COEFFICIENT_PER_WORKERCLASS';
export const COMPANY_PAYROLL__EMPLOYER_LABEL = '[COMPANY_PAYROLL] EMPLOYER_LABEL';
export const COMPANY_PAYROLL__WORKHOURS_RESTRICTIONS = '[COMPANY_PAYROLL] WORKHOURS_RESTRICTIONS';
export const COMPANY_PAYROLL__WORKHOURS_SETTINGS = '[COMPANY_PAYROLL] WORKHOURS_SETTINGS';
export const COMPANY_PAYROLL__MINIMUM_PERFORMED_HOURS = '[COMPANY_PAYROLL] MINIMUM_PERFORMED_HOURS';
export const COMPANY_PAYROLL__MAXIMUM_PLANNED_HOURS = '[COMPANY_PAYROLL] MAXIMUM_PLANNED_HOURS';
export const COMPANY_PAYROLL__ADD_NEW_FUNCTION = '[COMPANY_PAYROLL] ADD_NEW_FUNCTION';
export const COMPANY_PAYROLL__FUNCTIONS = '[COMPANY_PAYROLL] FUNCTIONS';
export const COMPANY_PAYROLL__FUNCTION_DESCRIPTION = '[COMPANY_PAYROLL] FUNCTION_DESCRIPTION';
export const COMPANY_PAYROLL__FUNCTION_COLOR = '[COMPANY_PAYROLL] FUNCTION_COLOR';
export const COMPANY_PAYROLL__FUNCTION_TYPE = '[COMPANY_PAYROLL] FUNCTION_TYPE';
export const FUNCTION_TYPE__PER_HOUR = '[FUNCTION_TYPE] PER_HOUR';
export const FUNCTION_TYPE__PER_DAY = '[FUNCTION_TYPE] PER_DAY';
export const FUNCTION_TYPE__PER_MONTH = '[FUNCTION_TYPE] PER_MONTH';
export const COMPANY_PAYROLL__FUNCTION_WAGE = '[COMPANY_PAYROLL] FUNCTION_WAGE';
export const COMPANY_PAYROLL__EDIT_PAYROLL_FUNCTION = '[COMPANY_PAYROLL] EDIT_PAYROLL_FUNCTION';
export const COMPANY_PAYROLL__COST_CENTER_DESCRIPTION = '[COMPANY_PAYROLL] COST_CENTER_DESCRIPTION';
export const COMPANY_PAYROLL__COST_CENTER_ADD_NEW_COST_CENTER = '[COMPANY_PAYROLL] COST_CENTER_ADD_NEW_COST_CENTER';
export const COMPANY_PAYROLL__COST_CENTER_EDIT_COST_CENTER = '[COMPANY_PAYROLL] COST_CENTER_EDIT_COST_CENTER';
export const COMPANY_PAYROLL__GENERAL = '[COMPANY_PAYROLL] GENERAL';
export const COMPANY_PAYROLL__COST_CENTERS = '[COMPANY_PAYROLL] COST_CENTERS';
export const COMPANY_PAYROLL__WAGE_EXEMPTIONS = '[COMPANY_PAYROLL] WAGE_EXEMPTIONS';
export const COMPANY_PAYROLL__WAGE_SETUP = '[COMPANY_PAYROLL] WAGE_SETUP';
export const COMPANY_PAYROLL__WAGE_EXEMPTION = '[COMPANY_PAYROLL] WAGE_EXEMPTION';
export const COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE = '[COMPANY_PAYROLL] WAGE_EXEMPTIONS_ENABLE';
export const COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE_WORKERCLASS =
  '[COMPANY_PAYROLL] WAGE_EXEMPTIONS_ENABLE_WORKERCLASS';
export const COMPANY_PAYROLL__WAGE_EXEMPTIONS_ENABLE_FUNCTION = '[COMPANY_PAYROLL] WAGE_EXEMPTIONS_ENABLE_FUNCTION';
export const COMPANY_PAYROLL__ADD_NEW_WORKCLASS_EXEMPTION = '[COMPANY_PAYROLL] ADD_NEW_WORKCLASS_EXEMPTION';
export const COMPANY_PAYROLL__ADD_NEW_FUNCTION_EXEMPTION = '[COMPANY_PAYROLL] ADD_NEW_FUNCTION_EXEMPTION';
export const COMPANY_PAYROLL__DELETE_FUNCTION_EXEMPTION = '[COMPANY_PAYROLL] DELETE_FUNCTION_EXEMPTION';
export const COMPANY_PAYROLL__DELETE_WORKERCLASS_EXEMPTION = '[COMPANY_PAYROLL] DELETE_WORKERCLASS_EXEMPTION';
export const COMPANY_PAYROLL__ADD_NEW_EMPLOYEE_EXEMPTION = '[COMPANY_PAYROLL] ADD_NEW_EMPLOYEE_EXEMPTION';
export const COMPANY_PAYROLL__DELETE_EMPLOYEE_EXEMPTION = '[COMPANY_PAYROLL] DELETE_EMPLOYEE_EXEMPTION';
export const COMPANY_PAYROLL__EMPLOYEE = '[COMPANY_PAYROLL] EMPLOYEE';
export const COMPANY_PAYROLL__ENABLE_EMPLOYEE = '[COMPANY_PAYROLL] ENABLE_EMPLOYEE';
export const COMPANY_PAYROLL__FUNCTION = '[COMPANY_PAYROLL] FUNCTION';
export const COMPANY_PAYROLL__CATEGORY = '[COMPANY_PAYROLL] CATEGORY';
export const COMPANY_PAYROLL__LIMIT = '[COMPANY_PAYROLL] LIMIT';
export const COMPANY_PAYROLL__LIMIT_ERROR = '[COMPANY_PAYROLL] LIMIT_ERROR';
export const COMPANY_PAYROLL__INVALID_INTERVAL_FORMAT = '[COMPANY_PAYROLL] INVALID_INTERVAL_FORMAT';
export const COMPANY_PAYROLL__INTERVALS_OVERLAP = '[COMPANY_PAYROLL] INTERVALS_OVERLAP';
export const COMPANY_PAYROLL__MORNING_INTERVAL = '[COMPANY_PAYROLL] MORNING_INTERVAL';
export const COMPANY_PAYROLL__AFTERNOON_INTERVAL = '[COMPANY_PAYROLL] AFTERNOON_INTERVAL';
export const COMPANY_PAYROLL__EVENING_INTERVAL = '[COMPANY_PAYROLL] EVENING_INTERVAL';
export const COMPANY_PAYROLL__DUPLICATE_PER_JOINT_COMMISSION = '[COMPANY_PAYROLL] DUPLICATE_PER_JOINT_COMMISSION';
export const COMPANY_PAYROLL__WAGE_EXEPTION_VALUE_NOT_ZERO = '[COMPANY_PAYROLL] WAGE_EXEPTION_VALUE_NOT_ZERO';

export const COMPANY_FINANCIAL__BANK_ACCOUNT = '[COMPANY_FINANCIAL] BANK_ACCOUNT';
export const COMPANY_FINANCIAL__PAYMENT_METHOD = '[COMPANY_FINANCIAL] PAYMENT_METHOD';
export const COMPANY_FINANCIAL__MANDATE_REFERENCE = '[COMPANY_FINANCIAL] MANDATE_REFERENCE';
export const COMPANY_FINANCIAL__DATE_MANDATE_SIGNED = '[COMPANY_FINANCIAL] DATE_MANDATE_SIGNED';
export const COMPANY_FINANCIAL__INVOICE_EMAIL = '[COMPANY_FINANCIAL] INVOICE_EMAIL';
export const COMPANY_FINANCIAL__UPDATED_SUCCESSFULLY = '[COMPANY_FINANCIAL] UPDATED_SUCCESSFULLY';
export const PAYMENT_METHOD__VIA_DOMICILIE = '[PAYMENT_METHOD] VIA_DOMICILIE';
export const PAYMENT_METHOD__MANUAL = '[PAYMENT_METHOD] MANUAL';

export const COMPANY_MANAGERS__ADD_NEW_MANAGER = '[COMPANY_MANAGERS] ADD_NEW_MANAGER';
export const COMPANY_MANAGERS__LOCATION = '[COMPANY_MANAGERS] LOCATION';
export const COMPANY_MANAGERS__LOCATIONS = '[COMPANY_MANAGERS] LOCATIONS';
export const COMPANY_MANAGERS__EDIT_MODAL_TITLE = '[COMPANY_MANAGERS] EDIT_MODAL_TITLE';
export const COMPANY_MANAGERS__VIEW_DETAILS = '[COMPANY_MANAGERS] VIEW_DETAILS';
export const COMPANY_MANAGERS__ROLE = '[COMPANY_MANAGERS] ROLE';
export const MANAGER_ROLE__CLIENT_MANAGER = '[MANAGER_ROLE] CLIENT_MANAGER';
export const MANAGER_ROLE__STORE_MANAGER = '[MANAGER_ROLE] STORE_MANAGER';
export const MANAGER_ROLE__HR_MANAGER = '[MANAGER_ROLE] HR_MANAGER';

export const COMPANY_VENDORS__VENDOR_NAME = '[COMPANY_VENDORS] VENDOR_NAME';
export const COMPANY_VENDORS__ADD_NEW_VENDOR = '[COMPANY_VENDORS] ADD_NEW_VENDOR';
export const COMPANY_VENDORS__EDIT_VENDOR = '[COMPANY_VENDORS] EDIT_VENDOR';

export const COMPANY_INTEGRATION__SALARY_ENGINE_REFERENCE = '[COMPANY_INTEGRATION] SALARY_ENGINE_REFERENCE';
export const COMPANY_INTEGRATION__ENVIRONMENT_KEY = '[COMPANY_INTEGRATION] ENVIRONMENT_KEY';
export const COMPANY_INTEGRATION__API_KEY = '[COMPANY_INTEGRATION] API_KEY';

export const COMPANY_LOCATIONS__ADD_NEW_LOCATION = '[COMPANY_LOCATIONS] ADD_NEW_LOCATION';
export const COMPANY_LOCATIONS__EDIT_LOCATION = '[COMPANY_LOCATIONS] EDIT_LOCATION';

export const COMPANY_PAYROLL__ADD_NEW_WORKCLASS_COEFFICIENT = '[COMPANY_PAYROLL] ADD_NEW_WORKCLASS_COEFFICIENT';
export const COMPANY_PAYROLL__DELETE_WORKCLASS_COEFFICIENT = '[COMPANY_PAYROLL] DELETE_WORKCLASS_COEFFICIENT';
export const COMPANY_PAYROLL__WORKERCLASS = '[COMPANY_PAYROLL] WORKERCLASS';
export const COMPANY_PAYROLL__COEFFICIENT = '[COMPANY_PAYROLL] COEFFICIENT';
export const COMPANY_PAYROLL__FACTOR_S = '[COMPANY_PAYROLL] FACTOR_S';
export const COMPANY_INFORMATION__ACTIVE_CONTRACT_COUNT = '[COMPANY_INFORMATION] ACTIVE_CONTRACT_COUNT';
export const COMPANY_INFORMATION__PAST_CONTRACT_COUNT = '[COMPANY_INFORMATION] PAST_CONTRACT_COUNT';
export const COMPANY_INFORMATION_WEEKLY_STATUS = '[COMPANY_INFORMATION] WEEKLY_STATUS';

// paritair-comite
export const PARITAIR_COMITE__ADD_PARITAIR_COMITE = '[PARITAIR_COMITE] ADD_PARITAIR_COMITE';
export const PARITAIR_COMITE__DUTCH = '[PARITAIR_COMITE] DUTCH';
export const PARITAIR_COMITE__FRENCH = '[PARITAIR_COMITE] FRENCH';
export const PARITAIR_COMITE__ENGLISH = '[PARITAIR_COMITE] ENGLISH';
export const PARITAIR_COMITE__GENERAL = '[PARITAIR_COMITE] GENERAL';
export const PARITAIR_COMITE__MINIMUM_WAGES = '[PARITAIR_COMITE] MINIMUM_WAGES';
export const PARITAIR_COMITE__WAGE_CALCULATION = '[PARITAIR_COMITE] WAGE_CALCULATION';
export const PARITAIR_COMITE__START_DATE = '[PARITAIR_COMITE] START_DATE';
export const PARITAIR_COMITE__END_DATE = '[PARITAIR_COMITE] END_DATE';
export const PARITAIR_COMITE__WORKER_CLASS = '[PARITAIR_COMITE] WORKER_CLASS';
export const PARITAIR_COMITE__ADD_NEW_ROW = '[PARITAIR_COMITE] ADD_NEW_ROW';
export const PARITAIR_COMITE__FILTER_BY_DATE = '[PARITAIR_COMITE] FILTER_BY_DATE';
export const PARITAIR_COMITE__EXPERIENCE_OR_AGE = '[PARITAIR_COMITE] EXPERIENCE_OR_AGE';
export const PARITAIR_COMITE__UPDATED_SUCCESSFULLY = '[PARITAIR_COMITE] UPDATED_SUCCESSFULLY';
export const PARITAIR_COMITE__ADDED_SUCCESSFULLY = '[PARITAIR_COMITE] ADDED_SUCCESSFULLY';

// boilerplate
export const DRAWER__PROFILE = '[DRAWER] PROFILE';
export const DRAWER__GENERAL_SETTINGS = '[DRAWER] GENERAL_SETTINGS';
export const DRAWER__LOGOUT = '[DRAWER] LOGOUT';

export const LOGOUT_ALERT__CANCEL_BUTTON = '[LOGOUT_ALERT] CANCEL_BUTTON';
export const LOGOUT_ALERT__CONFIRM_BUTTON = '[LOGOUT_ALERT] CONFIRM_BUTTON';
export const LOGOUT_ALERT__MESSAGE = '[LOGOUT_ALERT] MESSAGE';
export const LOGOUT_ALERT__TITLE = '[LOGOUT_ALERT] TITLE';

export const TAB_ROUTE__HOME_NAME = '[TAB_ROUTE] HOME_NAME';
export const TAB_ROUTE__BOOKINGS_NAME = '[TAB_ROUTE] BOOKINGS_NAME';
export const TAB_ROUTE__COLLEGUES_NAME = '[TAB_ROUTE] COLLEGUES_NAME';
export const TAB_ROUTE__UPCOMING_NAME = '[TAB_ROUTE] UPCOMING_NAME';

export const PROFILE__SCREEN_TITLE = '[PROFILE] SCREEN_TITLE';
export const PROFILE__BUTTON_MY_BOOKINGS = '[PROFILE] BUTTON_MY_BOOKINGS';

export const GENERAL_SETTINGS__SCREEN_TITLE = '[GENERAL_SETTINGS] SCREEN_TITLE';
export const GENERAL_SETTINGS__LANGUAGE = '[GENERAL_SETTINGS] LANGUAGE';
export const GENERAL_SETTINGS__CHANGE_LANGUAGE = '[GENERAL_SETTINGS] CHANGE_LANGUAGE';

export const LANGUAGE__ENGLISH = '[LANGUAGE] ENGLISH';
export const LANGUAGE__FRENCH = '[LANGUAGE] FRENCH';

export const LOCATION__SCREEN_TITLE = '[LOCATION] SCREEN_TITLE';

// profile
export const PROFILE__CURRENT_PASSWORD = '[PROFILE] CURRENT_PASSWORD';
export const PROFILE__NEW_PASSWORD = '[PROFILE] NEW_PASSWORD';
export const PROFILE__CONFIRM_NEW_PASSWORD = '[PROFILE] CONFIRM_NEW_PASSWORD';
export const PROFILE__UPDATE_LANGUAGE_SUCCESSFUL = '[PROFILE] UPDATE_LANGUAGE_SUCCESSFUL';
export const PROFILE__UPDATE_PASSWORD_SUCCESSFUL = '[PROFILE] UPDATE_PASSWORD_SUCCESSFUL';

// menu
export const MENU__DASHBOARD = '[MENU] DASHBOARD';
export const MENU__COMPANIES = '[MENU] COMPANIES';
export const MENU__COMPANY = '[MENU] COMPANY';
export const MENU__CONTRACTS = '[MENU] CONTRACTS';
export const MENU__EMPLOYEES = '[MENU] EMPLOYEES';
export const MENU__AVAILABILITIES = '[MENU] AVAILABILITIES';
export const MENU__MY_INFORMATION = '[MENU] MY_INFORMATION';
export const MENU__DOCS = '[MENU] DOCS';
export const MENU__PARITAIR_COMITE = '[MENU] PARITAIR_COMITE';
export const MENU__PAYROLL_SETTINGS = '[MENU] PAYROLL_SETTINGS';
export const MENU__USERS = '[MENU] USERS';
export const MENU__REPORTS = '[MENU] REPORTS';

export const MENU__MY_PROFILE = '[MENU] MY_PROFILE';
export const MENU__PASSWORD = '[MENU] PASSWORD';
export const MENU_LOGOUT = '[MENU] LOGOUT';

// superadmins
export const SUPERADMINS__ADD_NEW = '[SUPERADMINS] ADD_NEW';
export const SUPERADMINS__ADD_NEW_BUTTON = '[SUPERADMINS] ADD_NEW_BUTTON';
export const SUPERADMINS__EDIT_SUPERADMIN = '[SUPERADMINS] EDIT_SUPERADMIN';
export const SUPERADMINS__DELETE_SUPERADMIN = '[SUPERADMINS] DELETE_ADMIN';
export const SUPERADMINS = '[SUPERADMINS] SUPERADMINS';

// availabilities
export const AVAILABILITIES__AVAILABILITY = '[AVAILABILITIES] AVAILABILITY';
export const AVAILABILITIES__FULL_DAY_AVAILABLE = '[AVAILABILITIES] FULL_DAY_AVAILABLE';
export const AVAILABILITIES__MORNING = '[AVAILABILITIES] MORNING';
export const AVAILABILITIES__AFTERNOON = '[AVAILABILITIES] AFTERNOON';
export const AVAILABILITIES__EVENING = '[AVAILABILITIES] EVENING';
export const AVAILABILITIES__MORNING_AND_AFTERNOON = '[AVAILABILITIES] MORNING_AND_AFTERNOON';
export const AVAILABILITIES__MORNING_AND_EVENING = '[AVAILABILITIES] MORNING_AND_EVENING';
export const AVAILABILITIES__AFTERNOON_AND_EVENING = '[AVAILABILITIES] AFTERNOON_AND_EVENING';
export const AVAILABILITIES__SUBMIT_AVAILABILITIES = '[AVAILABILITIES] SUBMIT_AVAILABILITIES';
export const AVAILABILITIES__COMPANY_PICK_SCREEN_TEXT = '[AVAILABILITIES] COMPANY_PICK_SCREEN_TEXT';
export const AVAILABILITIES__UNAVAILABLE = '[AVAILABILITIES] UNAVAILABLE';
export const AVAILABILITIES__NO_AVAILABILITIES = '[AVAILABILITIES] NO_AVAILABILITIES';
export const AVAILABILITIES__NO_EMPLOYEES_FOUND = '[AVAILABILITIES] NO_EMPLOYEES_FOUND';
export const AVAILABILITIES__REQUEST_AVAILABILITY = '[AVAILABILITIES] REQUEST_AVAILABILITY';
export const AVAILABILITIES__REQUEST_ALL_AVAILABILITIES = '[AVAILABILITIES] REQUEST_ALL_AVAILABILITIES';
export const AVAILABILITIES__REQUEST_BASED_ON_FILTERS = '[AVAILABILITIES] REQUEST_BASED_ON_FILTERS';
export const AVAILABILITIES__CHANGES_NOT_SUBMITTED = '[AVAILABILITIES] CHANGES_NOT_SUBMITTED';

// company availabilities
export const COMPANY_AVAILABILITY__AVAILABLE_EMPLOYEES = '[COMPANY_AVAILABILITY] AVAILABLE_EMPLOYEES';
export const COMPANY_AVAILABILITY__REQUEST_AVAILABILITY = '[COMPANY_AVAILABILITY] REQUEST_AVAILABILITY';
export const COMPANY_AVAILABILITY__REQUEST_ALL_AVAILABILITIES = '[COMPANY_AVAILABILITY] REQUEST_ALL_AVAILABILITIES';

// api users
export const API_USERS = '[API_USERS] API_USERS';
export const API_USERS__ADD_NEW = '[API_USERS] ADD_NEW';
export const API_USERS__ADD_NEW_BUTTON = '[API_USERS] ADD_NEW_BUTTON';
export const API_USERS__EDIT_SUPERADMIN = '[API_USERS] EDIT_SUPERADMIN';
export const API_USERS__DELETE_SUPERADMIN = '[API_USERS] DELETE_ADMIN';
// employees
export const EMPLOYEES__PERSONAL_INFO = '[EMPLOYEES] PERSONAL_INFO';
export const EMPLOYEES__CONTACT_INFO = '[EMPLOYEES] CONTACT_INFO';
export const EMPLOYEES__WORKING_INFO = '[EMPLOYEES] WORKING_INFO';
export const EMPLOYEES__ACCOUNT = '[EMPLOYEES] ACCOUNT';
export const EMPLOYEES__BIRTH_DATE = '[EMPLOYEES] BIRTH_DATE';
export const EMPLOYEES__BIRTH_PLACE = '[EMPLOYEES] BIRTH_PLACE';
export const EMPLOYEES__BIRTH_COUNTRY = '[EMPLOYEES] BIRTH_COUNTRY';
export const EMPLOYEES__NATIONALITY = '[EMPLOYEES] NATIONALITY';
export const EMPLOYEES__INSS = '[EMPLOYEES] INSS';
export const EMPLOYEES__MARITAL_STATUS = '[EMPLOYEES] MARITAL_STATUS';
export const EMPLOYEES__MARITAL_STATUS_MARRIED = '[EMPLOYEES] MARITAL_STATUS_MARRIED';
export const EMPLOYEES__MARITAL_STATUS_SINGLE = '[EMPLOYEES] MARITAL_STATUS_SINGLE';
export const EMPLOYEES__MARITAL_STATUS_DIVORCED = '[EMPLOYEES] MARITAL_STATUS_DIVORCED';
export const EMPLOYEES__MARITAL_STATUS_WIDOW = '[EMPLOYEES] MARITAL_STATUS_WIDOW';
export const EMPLOYEES__MARITAL_STATUS_LIVING_TOGETHER = '[EMPLOYEES] MARITAL_STATUS_LIVING_TOGETHER';
export const EMPLOYEES__DEPENDANTS = '[EMPLOYEES] DEPENDANTS';
export const EMPLOYEES__DEPENDANTS_PARTNER = '[EMPLOYEES] DEPENDANTS_PARTNER';
export const EMPLOYEES__DEPENDANTS_DISABLED_PARTNER = '[EMPLOYEES] DEPENDANTS_DISABLED_PARTNER';
export const EMPLOYEES__DEPENDANTS_CHILDREN = '[EMPLOYEES] DEPENDANTS_CHILDREN';
export const EMPLOYEES__DEPENDANTS_DISABLED_CHILDREN = '[EMPLOYEES] DEPENDANTS_DISABLED_CHILDREN';
export const EMPLOYEES__DEPENDANTS_ELDERLY = '[EMPLOYEES] DEPENDANTS_ELDERLY';
export const EMPLOYEES__MOBILE_NUMBER = '[EMPLOYEES] MOBILE_NUMBER';
export const EMPLOYEES__ICE_MOBILE_NUMBER = '[EMPLOYEES] ICE_MOBILE_NUMBER';
export const EMPLOYEES__REASON_FOR_EMPLOYMENT = '[EMPLOYEES] REASON_FOR_EMPLOYMENT';
export const EMPLOYEES__ID_CARD_NUMBER = '[EMPLOYEES] ID_CARD_NUMBER';
export const EMPLOYEES__MODE_OF_TRANSPORT = '[EMPLOYEES] MODE_OF_TRANSPORT';
export const EMPLOYEES__UPLOAD_ID = '[EMPLOYEES] UPLOAD_ID';
export const EMPLOYEES__UPLOAD_PICTURE = '[EMPLOYEES] UPLOAD_PICTURE';
export const EMPLOYEES__ID_EXPIRATION_DATE = '[EMPLOYEES] ID_EXPIRATION_DATE';
export const EMPLOYEES__ADD_NEW_EMPLOYEE = '[EMPLOYEES] ADD_NEW_EMPLOYEE';
export const EMPLOYEES__EDIT_EMPLOYEE = '[EMPLOYEES] EDIT_EMPLOYEE';
export const EMPLOYEES__VIEW_EMPLOYEE = '[EMPLOYEES] VIEW_EMPLOYEE';
export const EMPLOYEES__YEARS_OF_EXPERIENCE = '[EMPLOYEES] YEARS_OF_EXPERIENCE';
export const EMPLOYEES__VALIDATE_YEARS_OF_EXPERIENCE = '[EMPLOYEES] VALIDATE_YEARS_OF_EXPERIENCE';
export const EMPLOYEES__AGREE_TO_GDPR = '[EMPLOYEES] AGREE_TO_GDPR';
export const EMPLOYEES__AGREE_TO_GDPR_ERROR = '[EMPLOYEES] AGREE_TO_GDPR_ERROR';
export const EMPLOYEES__STREET_NAME = '[EMPLOYEES] STREET_NAME';
export const EMPLOYEES__STREET_NUMBER = '[EMPLOYEES] STREET_NUMBER';
export const EMPLOYEES__BUS_NUMBER = '[EMPLOYEES] BUS_NUMBER';
export const EMPLOYEES__INACTIVE = '[EMPLOYEES] INACTIVE';
export const EMPLOYEES__ACTIVE = '[EMPLOYEES] ACTIVE';
export const EMPLOYEES__SORT_CRITERIA = '[EMPLOYEES] SORT_CRITERIA';
export const EMPLOYEES__SORT_NEWEST = '[EMPLOYEES] SORT_NEWEST';
export const EMPLOYEES__SORT_ALPHABETICAL = '[EMPLOYEES] SORT_ALPHABETICAL';
export const MODES_OF_TRANSPORT__PRIVATE = '[MODES_OF_TRANSPORT] PRIVATE';
export const MODES_OF_TRANSPORT__BIKE = '[MODES_OF_TRANSPORT] BIKE';
export const MODES_OF_TRANSPORT__PUBLIC_TRANSPORT = '[MODES_OF_TRANSPORT] PUBLIC_TRANSPORT';
export const EMPLOYEES__SELECT_A_COMPANY = '[EMPLOYEES] SELECT_A_COMPANY';
export const EMPLOYEES__COMPANY_NO_EMPLOYEES = '[EMPLOYEES] COMPANY_NO_EMPLOYEES';
export const EMPLOYEES__STATUS = '[EMPLOYEES] STATUS';
export const EMPLOYEES__AGE = '[EMPLOYEES] AGE';
export const EMPLOYEES__GENDER = '[EMPLOYEES] GENDER';
export const EMPLOYEES__REQUIRES_ACTION = '[EMPLOYEES] REQUIRES_ACTION';
export const EMPLOYEES__INVITE = '[EMPLOYEES] INVITE';
export const EMPLOYEES__INVITE_EMPLOYEE = '[EMPLOYEES] INVITE_EMPLOYEE';
export const EMPLOYEES__INVITE_EMPLOYEE_SENT = '[EMPLOYEES] INVITE_EMPLOYEE_SENT';
export const EMPLOYEES__INVITE_EMPLOYEE_SAVED = '[EMPLOYEES] INVITE_EMPLOYEE_SAVED';
export const EMPLOYEES__RESEND_INVITATION = '[EMPLOYEES] RESEND_INVITATION';
export const EMPLOYEES__INVITATION_SENT_SUCCESSFULLY = '[EMPLOYEES] INVITATION_SENT_SUCCESSFULLY';
export const EMPLOYEES__INVITE_EMPLOYEE_COPY_INVITATION_LINK = '[EMPLOYEES] INVITE_EMPLOYEE_COPY_INVITATION_LINK';
export const EMPLOYEES__EMPLOYEE_ONBOARDING = '[EMPLOYEES] EMPLOYEE_ONBOARDING';
export const EMPLOYEES__ADDED_SUCCESSFULLY = '[EMPLOYEES] ADDED_SUCCESSFULLY';
export const EMPLOYEES__CONFIRM_PASSWORD = '[EMPLOYEES] CONFIRM_PASSWORD';
export const EMPLOYEES__COUNTRY = '[EMPLOYEES] COUNTRY';
export const EMPLOYEES__ALREADY_EXISTS_ERROR = '[EMPLOYEES] ALREADY_EXISTS_ERROR';
export const EMPLOYEES__INSS_ERROR = '[EMPLOYEES] INSS_ERROR';
export const EMPLOYEES__IBAN_ERROR = '[EMPLOYEES] IBAN_ERROR';
export const EMPLOYEES__EMAIL_ERROR = '[EMPLOYEES] EMAIL_ERROR';
export const EMPLOYEES__SEARCH_EMPLOYEE_NAME = '[EMPLOYEES] SEARCH_EMPLOYEE_NAME';
export const EMPLOYEES__VIEW_ID = '[EMPLOYEES] VIEW_ID';
export const EMPLOYEES__UPLOAD_ID_FRONT = '[EMPLOYEES] UPLOAD_ID_FRONT';
export const EMPLOYEES__UPLOAD_ID_BACK = '[EMPLOYEES] UPLOAD_ID_BACK';
export const EMPLOYEES__STUDENT = '[EMPLOYEES] STUDENT';
export const EMPLOYEES__FLEXI = '[EMPLOYEES] FLEXI';
export const EMPLOYEES__EXTRA = '[EMPLOYEES] EXTRA';
export const EMPLOYEES__LABOURER = '[EMPLOYEES] LABOURER';
export const EMPLOYEES__SERVANT = '[EMPLOYEES] SERVANT';
export const EMPLOYEES__PERMANENT = '[EMPLOYEES] PERMANENT';
export const EMPLOYEES__START_DATE = '[EMPLOYEES] START_DATE';
export const EMPLOYEES__PERMANENT_CHANGE_VALIDATION = '[EMPLOYEES] PERMANENT_CHANGE_VALIDATION';
export const EMPLOYEES__STUDENT_TOOLTIP = '[EMPLOYEES] STUDENT_TOOLTIP';
export const EMPLOYEES__FLEXI_TOOLTIP = '[EMPLOYEES] FLEXI_TOOLTIP';
export const EMPLOYEES__EXTRA_TOOLTIP = '[EMPLOYEES] EXTRA_TOOLTIP';
export const EMPLOYEES__LABOURER_TOOLTIP = '[EMPLOYEES] LABOURER_TOOLTIP';
export const EMPLOYEES__SERVANT_TOOLTIP = '[EMPLOYEES] SERVANT_TOOLTIP';
export const EMPLOYEES__STAY_ADDRESS = '[EMPLOYEES] STAY_ADDRESS';
export const EMPLOYEES__STAY_CITY = '[EMPLOYEES] STAY_CITY';
export const EMPLOYEES__STAY_STREET = '[EMPLOYEES] STAY_STREET';
export const EMPLOYEES__STAY_NUMBER = '[EMPLOYEES] STAY_NUMBER';
export const EMPLOYEES__STAY_BUS = '[EMPLOYEES] STAY_BUS';
export const EMPLOYEES__POSTAL_CODE = '[EMPLOYEES] STAY_POSTAL_CODE';
export const EMPLOYEES__STAY_COUNTRY = '[EMPLOYEES] STAY_COUNTRY';
export const EMPLOYEE__COMPANY_LOCATION = '[EMPLOYEE] COMPANY_LOCATION';
export const EMPLOYEE__COMPANY_LOCATION_NO_COMMON = '[EMPLOYEE] COMPANY_LOCATION_NO_COMMON';
export const EMPLOYEE__STUDENT_DECLARATION = '[EMPLOYEE] STUDENT_DECLARATION';
export const EMPLOYEE__LETTER_OF_INTENT = '[EMPLOYEE] LETTER_OF_INTENT';
export const EMPLOYEE__FIND_STUDENT_DECLARATION_TEXT = '[EMPLOYEE] FIND_STUDENT_DECLARATION_TEXT';
export const EMPLOYEE__FIND_LETTER_OF_INTENT_TEXT = '[EMPLOYEE] FIND_LETTER_OF_INTENT_TEXT';
export const EMPLOYEE__UPLOADED_DOCUMENT = '[EMPLOYEE] UPLOADED_DOCUMENT';
export const EMPLOYEE__WORKERCLASS_NOT_SELECTED_ERROR = '[EMPLOYEE] WORKERCLASS_NOT_SELECTED_ERROR';
export const EMPLOYEE__ACADEMIC_PERIOD_ERROR = '[EMPLOYEE] ACADEMIC_PERIOD_ERROR';
export const EMPLOYEE__INVALID_ACADEMIC_PERIOD = '[EMPLOYEE] INVALID_ACADEMIC_PERIOD';
export const EMPLOYEES__INDIVIDUAL_INVITATION = '[EMPLOYEE] INDIVIDUAL_INVITATION';
export const EMPLOYEES__ADD_GENERAL_INVITATION = '[EMPLOYEE] ADD_GENERAL_INVITATION';
export const EMPLOYEES__GENERAL_INVITATION_LIST = '[EMPLOYEE] GENERAL_INVITATION_LIST';
export const EMPLOYEES__IS_FOR_ALL_LOCATIONS = '[EMPLOYEE] IS_FOR_ALL_LOCATIONS';
export const EMPLOYEES__INVATION_TOKEN_INVALID = '[EMPLOYEE] INVATION TOKEN INVALID';

// set password
export const SET_PASSWORD__TITLE = '[SET_PASSWORD] TITLE';
export const SET_PASSWORD__CONFIRM_PASSWORD_LABEL = '[SET_PASSWORD] CONFIRM_PASSWORD_LABEL';
export const SET_PASSWORD__UNAUTHORISED_REQUEST = '[SET_PASSWORD] UNAUTHORISED_REQUEST';
export const SET_PASSWORD__LINK_EXPIRED = '[SET_PASSWORD] LINK_EXPIRED';

// validation messages
export const VALIDATION__IS_REQUIRED = '[VALIDATION] IS_REQUIRED';
export const VALIDATION__INVALID_NUMBER = '[VALIDATION] INVALID_NUMBER';
export const VALIDATION__INVALID_FIELD = '[VALIDATION] INVALID_FIELD';
export const VALIDATION__INVALID_FORMAT = '[VALIDATION] INVALID_FORMAT';
export const VALIDATION__PASSWORDS_NOT_MATCH = '[VALIDATION] PASSWORDS_NOT_MATCH';
export const VALIDATION__INVALID_INSS = '[VALIDATION] INVALID_INSS';
export const VALIDATION__MAX_LENGTH = '[VALIDATION] MAX_LENGTH';
export const VALIDATION__MIN_LENGTH = '[VALIDATION] MIN_LENGTH';
export const VALIDATION__CHARACTERS = '[VALIDATION] CHARACTERS';
export const VALIDATION__DIGITS = '[VALIDATION] DIGITS';
export const VALIDATION__SHOULD_BE_GREATER_THAN = '[VALIDATION] SHOULD_BE_GREATER_THAN';

// reason for employment enum
export const REASON__EXTRA_CASH = '[REASON] EXTRA_CASH';
export const REASON__SEEKING_PERMANENT_JOB = '[REASON] SEEKING_PERMANENT_JOB';
export const REASON__SEEKING_EXPERIENCE = '[REASON] SEEKING_EXPERIENCE';

// contracts
export const CONTRACTS__NEW_CONTRACT = '[CONTRACTS] NEW_CONTRACT';
export const CONTRACTS__NEW_CONTRACT_SLOT = '[CONTRACTS] NEW_CONTRACT_SLOT';
export const CONTRACTS__NEW_CONTRACT_SLOT_DISABLED = '[CONTRACTS] NEW_CONTRACT_SLOT_DISABLED';
export const CONTRACTS__COST_CENTER = '[CONTRACTS] COST_CENTER';
export const CONTRACTS__COMPANY_LOCATION = '[CONTRACTS] COMPANY_LOCATION';
export const CONTRACTS__CANCEL = '[CONTRACTS] CANCEL';
export const CONTRACTS__FUNCTION = '[CONTRACTS] FUNCTION';
export const CONTRACTS__VENDOR = '[CONTRACTS] VENDOR';
export const CONTRACTS__TO_BE_CONFIRMED = '[CONTRACTS] TO_BE_CONFIRMED';
export const CONTRACTS__START_WORK = '[CONTRACTS] START_WORK';
export const CONTRACTS__START_BREAK = '[CONTRACTS] START_BREAK';
export const CONTRACTS__END_BREAK = '[CONTRACTS] END_BREAK';
export const CONTRACTS__END_WORK = '[CONTRACTS] END_WORK';
export const CONTRACTS__DATES = '[CONTRACTS] DATES';
export const CONTRACTS__WAGE = '[CONTRACTS] WAGE';
export const CONTRACTS__ADD_ANOTHER = '[CONTRACTS] ADD_ANOTHER';
export const CONTRACTS__MIN_HOURS_VALIDATION = '[CONTRACTS] MIN_HOURS_VALIDATION';
export const CONTRACTS__MAX_HOURS_VALIDATION = '[CONTRACTS] MAX_HOURS_VALIDATION';
export const CONTRACTS__CHRONOLOGICAL_ORDER_VALIDATION = '[CONTRACTS] CHRONOLOGICAL_ORDER_VALIDATION';
export const CONTRACTS__SELECTED = '[CONTRACTS] SELECTED';
export const CONTRACTS__COPY_SELECTED = '[CONTRACTS] COPY_SELECTED';
export const CONTRACTS__WORKING_LOCATION = '[CONTRACTS] WORKING_LOCATION';
export const CONTRACTS__PASTE_HERE = '[CONTRACTS] PASTE_HERE';
export const CONTRACTS__CLEAR_ALL = '[CONTRACTS] CLEAR_ALL';
export const CONTRACTS__DELETE_SELECTED = '[CONTRACTS] DELETE_SELECTED';
export const CONTRACTS__PRESENT_DATE_ERROR = '[CONTRACTS] PRESENT_DATE_ERROR';
export const CONTRACTS__PRESENT_START_HOUR_ERROR = '[CONTRACTS] PRESENT_START_HOUR_ERROR';
export const CONTRACTS__END_HOUR_ERROR = '[CONTRACTS] END_HOUR_ERROR';
export const CONTRACTS__INVALID_HOUR_ERROR = '[CONTRACTS] INVALID_HOUR_ERROR';
export const CONTRACTS__CONFIRM_SERVICE = '[CONTRACTS] CONFIRM_SERVICE';
export const CONTRACTS__CONFIRM_COST_DECLARATION_ERROR = '[CONTRACTS] CONFIRM_COST_DECLARATION_ERROR';
export const CONTRACTS__SELECT_REASON = '[CONTRACTS] SELECT_REASON';
export const CONTRACTS__DATE = '[CONTRACTS] DATE';
export const CONTRACTS__EDIT_CONTRACT = '[CONTRACTS] EDIT_CONTRACT';
export const CONTRACTS__FILL_CONTRACT_SLOT = '[CONTRACTS] FILL_CONTRACT_SLOT';
export const CONTRACTS__VIEW_CONTRACT = '[CONTRACTS] VIEW_CONTRACT';
export const CONTRACTS__ARE_YOU_SURE = '[CONTRACTS] ARE_YOU_SURE';
export const CONTRACTS__ALREADY_CONFIRMED = '[CONTRACTS] ALREADY_CONFIRMED';
export const CONTRACTS__NONE = '[CONTRACTS] NONE';
export const CONTRACTS__DELETE_PAST_CONTRACT_ERROR = '[CONTRACTS] DELETE_PAST_CONTRACT_ERROR';
export const CONTRACTS__DELETE_WEEKLY_CONTRACT_WARNING = '[CONTRACTS] DELETE_WEEKLY_CONTRACT_WARNING';
export const CONTRACTS__DELETE_CONTRACT_WITH_ID_ERROR = '[CONTRACTS] DELETE_CONTRACT_WITH_ID_ERROR';
export const CONTRACTS__DEFAULT_ERROR = '[CONTRACTS] DEFAULT_ERROR';
export const CONTRACTS__SIGNED_CONTRACT_ERROR = '[CONTRACTS] SIGNED_CONTRACT_ERROR';
export const CONTRACTS__OTHER_CONTRACT_CHRONOLOGICAL_ORDER_VALIDATION =
  '[CONTRACTS] OTHER_CONTRACT_CHRONOLOGICAL_ORDER_VALIDATION';
export const CONTRACTS__NO_SHOW = '[CONTRACTS] NO_SHOW';
export const CONTRACTS__ARCHIVED = '[CONTRACTS] ARCHIVED';
export const CONTRACTS__CONFIRM_SELECTED = '[CONTRACTS] CONFIRM_SELECTED';
export const CONTRACTS__EDIT_MORE_CONTRACTS_ERROR = '[CONTRACTS] EDIT_MORE_CONTRACTS_ERROR';
export const CONTRACTS__START_END_WORK_ERROR = '[CONTRACTS] START_END_WORK_ERROR';
export const CONTRACTS__REASON_FOR_NO_SHOW = '[CONTRACTS] REASON_FOR_NO_SHOW';
export const CONTRACTS__FUTURE_CONTRACTS = '[CONTRACTS] FUTURE_CONTRACTS';
export const CONTRACTS__CONFIRM_MULTIPLE_ERROR = '[CONTRACTS] CONFIRM_MULTIPLE_ERROR';
export const CONTRACTS__PASTE_WEEKLY_CONTRACT = '[CONTRACTS] PASTE_WEEKLY_CONTRACTS';
export const CONTRACTS__ACTIVE_CONTRACTS = '[CONTRACTS] ACTIVE_CONTRACTS';
export const CONTRACTS__EXPORTED_CONTRACTS = '[CONTRACTS] EXPORTED_CONTRACTS';
export const CONTRACTS__ARCHIVED_CONTRACTS = '[CONTRACTS] ARCHIVED_CONTRACTS';
export const CONTRACTS__ITEMS_SELECTED = '[CONTRACTS] ITEMS_SELECTED';
export const CONTRACTS__COST_DELCARATION = '[CONTRACTS] COST_DELCARATION';
export const CONTRACTS__COST_DELCARATION_ADD = '[CONTRACTS] COST_DELCARATION_ADD';
export const CONTRACTS__COST_DELCARATION_AMOUNT = '[CONTRACTS] COST_DELCARATION_AMOUNT';
export const CONTRACTS__GET_DIMONA_ID = '[CONTRACTS] GET_DIMONA_ID';
export const CONTRACTS__PLEASE_USE_FILTERS = '[CONTRACTS] PLEASE_USE_FILTERS';
export const CONTRACTS__FETCH_MORE = '[CONTRACTS] FETCH_MORE';
// cost estimation
export const COST_ESTIMATION__COST_FOR_ALL = '[COST_ESTIMATION] COST_FOR_ALL';
export const COST_ESTIMATION__TOOLTIP = '[COST_ESTIMATION] TOOLTIP';
export const COST_ESTIMATION__STUDENTS = '[COST_ESTIMATION] STUDENTS';
export const COST_ESTIMATION__FLEXI = '[COST_ESTIMATION] FLEXI';
export const COST_ESTIMATION__EXTRA = '[COST_ESTIMATION] EXTRA';
export const COST_ESTIMATION__BED = '[COST_ESTIMATION] BED';
export const COST_ESTIMATION__ARB = '[COST_ESTIMATION] ARB';
export const COST_ESTIMATION__VIEW_INVOICES = '[COST_ESTIMATION] VIEW_INVOICES';

// employee status
export const EMPLOYEE_STATUS__INVITED = '[EMPLOYEE_STATUS] INVITED';
export const EMPLOYEE_STATUS__REQUIRES_ACTION = '[EMPLOYEE_STATUS] REQUIRES_ACTION';
export const EMPLOYEE_STATUS__READY = '[EMPLOYEE_STATUS] READY';
export const EMPLOYEE_STATUS__DEPLOYED = '[EMPLOYEE_STATUS] DEPLOYED';

// reason for no show in contracts
export const NO_SHOW__ALLOWED_ABSENCE = '[NO_SHOW] ALLOWED_ABSENCE';
export const NO_SHOW__NOT_ALLOWED_ABSENCE = '[NO_SHOW] NOT_ALLOWED_ABSENCE';
export const NO_SHOW__SICKNESS = '[NO_SHOW] SICKNESS';
export const NO_SHOW__OTHER = '[NO_SHOW] OTHER';

// documents
export const DOCUMENTS__ADD_NEW_DOCUMENT = '[DOCUMENTS] ADD_NEW_DOCUMENT';
export const DOCUMENTS__ADD_DOCUMENTS = '[DOCUMENTS] ADD_DOCUMENTS';
export const DOCUMENTS__DATE = '[DOCUMENTS] DATE';
export const DOCUMENTS__SELECT_CATEGORY = '[DOCUMENTS] SELECT_CATEGORY';
export const DOCUMENTS__CATEGORY = '[DOCUMENTS] CATEGORY';
export const DOCUMENTS__DRAG_AND_DROP_FILES = '[DOCUMENTS] DRAG_AND_DROP_FILES';
export const DOCUMENTS__DRAG_AND_DROP_STUD_DECLARATION = '[DOCUMENTS] DRAG_AND_DROP_STUD_DECLARATION';
export const DOCUMENTS__BROWSE = '[DOCUMENTS] BROWSE';
export const DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR = '[DOCUMENTS] ALL_FIELDS_ARE_REQUIRED_ERROR';
export const DOCUMENTS__CONTRACT = '[DOCUMENTS] CONTRACT';
export const DOCUMENTS__INVOICE = '[DOCUMENTS] INVOICE';
export const DOCUMENTS__WORKPOSTFILE = '[DOCUMENTS] WORKPOSTFILE';
export const DOCUMENTS__BANKING_MANDATE = '[DOCUMENTS] BANKING_MANDATE';
export const DOCUMENTS__STUDENT_PROOF_OF_INSCRIPTION = '[DOCUMENTS] STUDENT_PROOF_OF_INSCRIPTION';
export const DOCUMENTS__ID = '[DOCUMENTS] ID';
export const DOCUMENTS__DOCUMENT_NAME = '[DOCUMENTS] DOCUMENT_NAME';
export const DOCUMENTS__DOCUMENT_CATEGORY = '[DOCUMENTS] DOCUMENT_CATEGORY';
export const DOCUMENTS__DOCUMENT_USER = '[DOCUMENTS] DOCUMENT_USER';
export const DOCUMENTS__SYNC_WITH_EXPRESSO = '[DOCUMENTS] SYNC_WITH_EXPRESSO';
export const DOCUMENTS__SYNC_WITH_EXPRESSO_INCLUDE_EMPLOYEE_DOCS =
  '[DOCUMENTS] SYNC_WITH_EXPRESSO_INCLUDE_EMPLOYEE_DOCS';
export const DOCUMENTS__PERIOD_START = '[DOCUMENTS] PERIOD_START';
export const DOCUMENTS__PERIOD_END = '[DOCUMENTS] PERIOD_END';
export const DOCUMENTS__INVALID_PERIOD = '[DOCUMENTS] INVALID_PERIOD';
export const DOCUMENTS__VCA = '[DOCUMENTS] VCA';
export const DOCUMENTS__MEDICAL_CHECK = '[DOCUMENTS] MEDICAL_CHECK';
export const DOCUMENTS__SAFETY_PASSPORT = '[DOCUMENTS] SAFETY_PASSPORT';
export const DOCUMENTS__BANK_CARD = '[DOCUMENTS] BANK_CARD';
export const DOCUMENTS__DRIVING_LICENSE = '[DOCUMENTS] DRIVING_LICENSE';
export const DOCUMENTS__WORK_PERMIT = '[DOCUMENTS] WORK_PERMIT';
export const DOCUMENTS__CONFIRMATION_SHEET = '[DOCUMENTS] CONFIRMATION_SHEET';
export const DOCUMENTS__PARTNERSHIP_AGREEMENT = '[DOCUMENTS] PARTNERSHIP_AGREEMENT';
export const DOCUMENTS__SHARED_FILES = '[DOCUMENTS] SHARED_FILES';
export const DOCUMENTS__UPLOAD_DATE = '[DOCUMENTS] UPLOAD_DATE';
export const DOCUMENTS__EMPLOYEE = '[DOCUMENTS] EMPLOYEE';
export const DOCUMENTS__SELECTED = '[DOCUMENTS] SELECTED';
export const DOCUMENTS__CLEAR_ALL = '[DOCUMENTS] CLEAR_ALL';

// blogs
export const BLOGS__TITLE = '[BLOGS] TITLE';
export const BLOGS__DESCRIPTION = '[BLOGS] DESCRIPTION';
export const BLOGS__ADD_NEW_BLOG = '[BLOGS] ADD_NEW_BLOG';
export const BLOGS__URL = '[BLOGS] URL';
export const BLOGS__BLOGS = '[BLOGS] BLOGS';
export const BLOGS__TARGET_GROUP = '[BLOGS] TARGET_GROUP';
export const BLOGS__TARGET_GROUP_ERROR = '[BLOGS] TARGET_GROUP_ERROR';
export const BLOGS__EMPLOYEE = '[BLOGS] EMPLOYEE';
export const BLOGS__EDIT_BLOG_TITLE = '[BLOGS] EDIT_BLOG_TITLE';

// dashboard
export const DASHBOARD__EDENRED_TEXT = '[DASHBOARD] EDENRED_TEXT';
export const DASHBOARD__UPDATEPRO_TEXT = '[DASHBOARD] UPDATEPRO_TEXT';
export const DASHBOARD__GO_TO_CONTRACTS = '[DASHBOARD] GO_TO_CONTRACTS';
export const DASHBOARD__THIS_WEEK = '[DASHBOARD] THIS_WEEK';
export const DASHBOARD__GO_TO_EMPLOYEES = '[DASHBOARD] GO_TO_EMPLOYEES';
export const DASHBOARD__SEE_HOURS_LEFT = '[DASHBOARD] SEE_HOURS_LEFT';
export const DASHBOARD__FOR_YOU = '[DASHBOARD] FOR_YOU';
export const DASHBOARD__SHORTCUTS = '[DASHBOARD] SHORTCUTS';
export const DASHBOARD__SIGN_CONTRACT = '[DASHBOARD] SIGN_CONTRACT';
export const DASHBOARD__ERROR_RELATED_TO = '[DASHBOARd] ERRPR_RELATED_TO';
export const DASHBOARD__INCLUDE_ARCHIVED = '[DASHBOARD] INCLUDE_ARCHIVED';
export const DASHBOARD__SHOW_WITHOUT_TYPE = '[DASHBOARD] SHOW_WITHOUT_TYPE';
export const DASHBOARD__SHOW_ARCHIVED = '[DASHBOARD] SHOW_ARCHIVED';
export const DASHBOARD__BUSINESS_OBJECT = '[DASHBOARD] BUSINESS_OBJECT';

// payroll-settings
export const PAYROLL_SETTINGS__PARITAIR_COMITE = '[PAYROLL_SETTINGS] PAIRTAIR_COMITE';
export const PAYROLL_SETTINGS__WAGE_COMPONENTS = '[PAYROLL_SETTINGS] WAGE_COMPONENTS';
export const WAGE_COMPONENTS__SERVICE_CODES = '[WAGE_COMPONENTS] SERVICE_CODES';
export const WAGE_COMPONENTS__COST_DECLARATION = '[WAGE_COMPONENTS] COST_DECLARATION';
export const WAGE_COMPONENTS__COST_DECLARATIONS = '[WAGE_COMPONENTS] COST_DECLARATIONS';
export const WAGE_COMPONENTS__COST_DECLARATION_HEADER = '[WAGE_COMPONENTS] COST_DECLARATION_HEADER';
export const WAGE_COMPONENTS__COST_DECLARATION_NAME = '[WAGE_COMPONENTS] COST_DECLARATION_NAME';
export const WAGE_COMPONENTS__COST_DECLARATION_ADD = '[WAGE_COMPONENTS] COST_DECLARATION_ADD';
export const WAGE_COMPONENTS__COST_DECLARATION_EDIT = '[WAGE_COMPONENTS] COST_DECLARATION_EDIT';

export const WAGE_COMPONENTS__COST_DECLARATION_CHANGE_ACTIV_STATUS =
  '[WAGE_COMPONENTS] COST_DECLARATION_CHANGE_ACTIV_STATUS';
export const WAGE_COMPONENTS__COST_DECLARATION_FOR_ALL_PC = '[WAGE_COMPONENTS] COST_DECLARATION_FOR_ALL_PC';
export const WAGE_COMPONENTS__COST_DECLARATION_FOR_NO_PC = '[WAGE_COMPONENTS] COST_DECLARATION_FOR_NO_PC';

export const WAGE_COMPONENTS__NIGHT_SHIFT = '[WAGE_COMPONENTS] NIGHT_SHIFT';
export const WAGE_COMPONENTS__OVERTIME = '[WAGE_COMPONENTS] OVERTIME';
export const WAGE_COMPONENTS__HOLIDAY = '[WAGE_COMPONENTS] HOLIDAY';
export const WAGE_COMPONENTS__STARTS_FROM = '[WAGE_COMPONENTS] STARTS_FROM';
export const WAGE_COMPONENTS__ENDS_AT = '[WAGE_COMPONENTS] ENDS_AT';
export const WAGE_COMPONENTS__OUTCOME = '[WAGE_COMPONENTS] OUTCOME';
export const WAGE_COMPONENTS__CODE = '[WAGE_COMPONENTS] CODE';
export const WAGE_COMPONENTS__DESCRIPTION = '[WAGE_COMPONENTS] DESCRIPTION';
export const WAGE_COMPONENTS__WORK_HOURS_WEEKDAY = '[WAGE_COMPONENTS] WORK_HOURS_WEEKDAY';
export const WAGE_COMPONENTS__WORK_HOURS_SATURDAY = '[WAGE_COMPONENTS] WORK_HOURS_SATURDAY';
export const WAGE_COMPONENTS__WORK_HOURS_SUNDAY = '[WAGE_COMPONENTS] WORK_HOURS_SUNDAY';
export const WAGE_COMPONENTS__OVERTIME_WEEKDAY = '[WAGE_COMPONENTS] OVERTIME_WEEKDAY';
export const WAGE_COMPONENTS__OVERTIME_SATURDAY = '[WAGE_COMPONENTS] OVERTIME_SATURDAY';
export const WAGE_COMPONENTS__OVERTIME_SUNDAY = '[WAGE_COMPONENTS] OVERTIME_SUNDAY';

// reporting
export const REPORTING__WEEKLY_CONTRACT_COUNT = '[REPORTING] WEEKLY_CONTRACT_COUNT';
export const REPORTING__GENERAL_STATISTICS = '[REPORTING] GENERAL_STATISTICS';
export const REPORTING__REQUIRED_ACTIONS = '[REPORTING] REQUIRED_ACTIONS';
export const REPORTING__CONFIRMED_CONTRACTS_ONLY = '[REPORTING] CONFIRMED_CONTRACTS_ONLY';
export const REPORTING__NOSHOW_CONTRACTS_ONLY = '[REPORTING] NOSHOW_CONTRACTS_ONLY';
export const REPORTING__PAYROLL_OFFICER = '[REPORTING] PAYROLL_OFFICER';
export const REPORTING__TOTAL_CONTRACT_HOURS = '[REPORTING] TOTAL_CONTRACT_HOURS';
export const REPORTING__CLIENT_PERFORMANCE_REPORT = '[REPORTING] CLIENT_PERFORMANCE_REPORT';
export const REPORTING__STATISTICS_PER_COST_CENTER = '[REPORTING] STATISTICS_PER_COST_CENTER';
export const REPORTING__FTE_CHECK = '[REPORTING] FTE_CHECK';
export const REPORTING__HOUR_TYPES = '[REPORTING] HOUR_TYPES';
export const REPORTING_CURRENT_MONTH_HOURS = '[REPORTING] CURRENT_MONTH_HOURS';
export const REPORTING__PAST_MONTH_HOURS = '[REPORTING] PAST_MONTH_HOURS';
export const REPORTING__PAST_YEAR_SELECTED_MONTH_HOURS = '[REPORTING] PAST_YEAR_SELECTED_MONTH_HOURS';
export const REPORTING__PLANNED_HOURS = '[REPORTING] PLANNED_HOURS';
export const REPORTING__WORKED_HOURS = '[REPORTING] WORKED_HOURS';
export const REPORTING__NOSHOW_HOURS = '[REPORTING] NOSHOW_HOURS';
export const REPORTING__CONTRACTED_COST = '[REPORTING] CONTRACTED_COST';
export const REPORTING__WORKED_COST = '[REPORTING] WORKED_COST';

// permissions
export const PERMISSIONS_SHOW_WAGES = '[PERMISSIONS] SHOW_WAGES';
export const PERMISSIONS_HIDE_WAGES = '[PERMISSIONS] HIDE_WAGES';
