import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import IProfile from 'types/profile/IProfile';
import IProfileUpdateLanguage from 'types/profile/IProfileUpdateLanguage';
import IProfileUpdatePassword from 'types/profile/IProfileUpdatePassword';
import ISetPasswordRequest from 'types/profile/ISetPasswordRequest';
import IForgotPasswordRequest from 'types/profile/IForgotPasswordRequest';

export const getProfileRequest = async (): Promise<IProfile> => {
  const response: AxiosResponse<IProfile> = await getApi().get('/profile');
  return response.data;
};

export const updatePasswordRequest = async (data: IProfileUpdatePassword): Promise<void> => {
  await getApi().put('/profile/ChangePassword', data);
};

export const checkPasswordRequest = async (data: IProfileUpdatePassword): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await getApi().post('/profile/CheckPassword', data);
  return response.data;
};

export const updateLanguageRequest = async (data: IProfileUpdateLanguage): Promise<void> => {
  await getApi().put('/profile/ChangeLanguage', data);
};

export const setPasswordRequest = async (data: ISetPasswordRequest): Promise<void> => {
  await getApi().put('/profile/SetPassword', data);
};

export const forgotPasswordRequest = async (data: IForgotPasswordRequest): Promise<void> => {
  await getApi().put('/profile/forgotPassword', data);
};
