/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import UPInput from 'components/input';
import { getInputErrorText, getUpDropdownItemsWithIdAsValueFromEnum, validatePassword } from 'utils/helpers';
import { setPasswordAsync } from 'store/actions/profile-actions';
import ISetPassword from 'types/profile/ISetPassword';
import {
  GENERAL__SAVE_BUTTON,
  LOGIN__FORGOT_PASSWORD,
  LOGIN__PAGE_QUOTE,
  LOGIN__PASSWORD_LABEL,
  LOGIN__PASSWORD_MIN_LENGTH_ERROR,
  SET_PASSWORD__CONFIRM_PASSWORD_LABEL,
  SET_PASSWORD__LINK_EXPIRED,
  SET_PASSWORD__TITLE,
  SET_PASSWORD__UNAUTHORISED_REQUEST,
} from 'translations/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { setPasswordErrorSelector, setPasswordSuccessSelector } from 'store/selectors/profile-selectors';
import { logoutAction, passwordTokenValidityAction } from 'store/actions/auth-actions';
import { authStateSelector, setPasswordTokenValiditySelector } from 'store/selectors/auth-selectors';
import { UPDropdownItem } from 'components/dropdown';
import Lang from 'types/Lang';
import AuthHeader from './AuthHeader';
import AuthFooter from './AuthFooter';
import {
  AuthContainer,
  AuthTitle,
  AuthLeftContainer,
  AuthRightContainer,
  AuthSignInContainer,
  AuthSignIn,
  AuthUPButton,
  ErrorText,
  AuthSignInExpired,
} from './styles';

const PasswordChangeInitialState: ISetPassword = {
  password: '',
  confirmedPassword: '',
};

const SetPassword = (): JSX.Element => {
  const { uniqueKey } = useParams();
  const navigate = useNavigate();
  const setPasswordSuccess = useAppSelector(setPasswordSuccessSelector);
  const setPasswordError = useAppSelector(setPasswordErrorSelector);
  const isAuthenticated = useAppSelector(authStateSelector);
  const isLinkTokenValid = useAppSelector(setPasswordTokenValiditySelector);
  const {
    handleSubmit,
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors, isDirty },
  } = useForm<ISetPassword>({ defaultValues: PasswordChangeInitialState });
  const [t, i18next] = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(logoutAction(false));
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    dispatch(passwordTokenValidityAction(uniqueKey));
  }, [uniqueKey, dispatch]);

  const onSave: SubmitHandler<ISetPassword> = useCallback(() => {
    const values = getValues();
    if (isDirty) {
      dispatch(setPasswordAsync({ userUniqueKey: uniqueKey, password: values.confirmedPassword }));
    }
  }, [dispatch, getValues, isDirty, uniqueKey]);

  useEffect(() => {
    if (setPasswordSuccess) {
      navigate(`/auth/login`);
    }
  }, [navigate, setPasswordSuccess]);

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const goToForgotPassword = useCallback(() => {
    navigate('/forgotpassword');
  }, [navigate]);

  useEffect(() => {
    const userLang = navigator.language;
    const languageMatch = languageOptions.find(l => l.label === userLang);
    if (languageMatch !== undefined) {
      i18next.changeLanguage(languageMatch.label);
    }
  }, [i18next, languageOptions]);

  return (
    <Box>
      <AuthHeader />
      <AuthContainer sx={{ display: 'flex' }}>
        <AuthLeftContainer>
          <AuthTitle>{t(LOGIN__PAGE_QUOTE)}</AuthTitle>
        </AuthLeftContainer>
        <AuthRightContainer>
          {!isLinkTokenValid && (
            <AuthSignInContainer>
              <AuthSignInExpired>{t(SET_PASSWORD__LINK_EXPIRED)}</AuthSignInExpired>
              <AuthUPButton
                text={t(LOGIN__FORGOT_PASSWORD)}
                onClick={() => goToForgotPassword()}
                sx={{ marginTop: '16px' }}
              />
            </AuthSignInContainer>
          )}
          {isLinkTokenValid && (
            <AuthSignInContainer>
              <AuthSignIn>{t(SET_PASSWORD__TITLE)}</AuthSignIn>
              <Box mt={5} sx={{ width: '100%' }}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: true,
                    minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
                    maxLength: 100,
                  }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      label={t(LOGIN__PASSWORD_LABEL)}
                      placeholder={t(LOGIN__PASSWORD_LABEL)}
                      error={!!errors.password}
                      required
                      helperText={getInputErrorText(errors, 'password', undefined, 100, false, 8, false, true)}
                      onChange={e => {
                        field.onChange(e);
                        const { confirmedPassword } = getValues();
                        if (confirmedPassword !== e.target.value) {
                          setError('confirmedPassword', { type: 'match' });
                        } else {
                          clearErrors('confirmedPassword');
                        }

                        if (!validatePassword(e.target.value)) {
                          errors.password = { type: 'policy' };
                        } else {
                          errors.password = undefined;
                        }
                      }}
                      // onBlur={() => }
                      type="password"
                    />
                  )}
                />
              </Box>
              <Box mt={5} mb={4} sx={{ width: '100%' }}>
                <Controller
                  name="confirmedPassword"
                  control={control}
                  rules={{
                    required: true,
                    minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
                    maxLength: 100,
                  }}
                  render={({ field }) => (
                    <UPInput
                      value={field.value}
                      label={t(SET_PASSWORD__CONFIRM_PASSWORD_LABEL)}
                      placeholder={t(SET_PASSWORD__CONFIRM_PASSWORD_LABEL)}
                      error={!!errors.confirmedPassword}
                      required
                      helperText={getInputErrorText(errors, 'confirmedPassword', undefined, 100, false, 8, false, true)}
                      onChange={e => {
                        field.onChange(e);
                        const { password } = getValues();
                        if (password !== e.target.value) {
                          setError('confirmedPassword', { type: 'match' });
                        } else if (!validatePassword(e.target.value)) {
                          setError('confirmedPassword', { type: 'policy' });
                        } else {
                          clearErrors('confirmedPassword');
                        }
                      }}
                      type="password"
                    />
                  )}
                />
              </Box>
              {setPasswordError && <ErrorText>{t(SET_PASSWORD__UNAUTHORISED_REQUEST)}</ErrorText>}
              <AuthUPButton
                text={t(GENERAL__SAVE_BUTTON)}
                onClick={() => handleSubmit(onSave)()}
                sx={{ marginTop: '16px' }}
              />
            </AuthSignInContainer>
          )}
        </AuthRightContainer>
      </AuthContainer>
      <AuthFooter />
    </Box>
  );
};

export default SetPassword;
