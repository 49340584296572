import { useState, useEffect, useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { UPDataGrid } from 'components/data-grid';
import UPButton from 'components/button';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store';
import { useTranslation } from 'react-i18next';
import { costDeclarationsForCompanySelector } from 'store/selectors/cost-declaration-selectors';
import {
  bulkChangeActiveStatusForCompanyAction,
  deleteCostDeclarationsForCompanyAction,
  getCostDeclarationsForCompanyAction,
} from 'store/actions/cost-declaration-actions';
import { toNumber } from 'lodash';
import { CONTRACTS__CLEAR_ALL, CONTRACTS__DELETE_SELECTED } from 'translations/constants';
import { AlertStyled } from 'components/bottom-green-bar/styles';
import { ReactComponent as RedIcon } from '../../../assets/icons/RedCircle.svg';
import { ReactComponent as GreenIcon } from '../../../assets/icons/GreenCircle.svg';
import AddWageComponentModal from './AddWageComponentModal';
import { PayrollAddNewItemButton, WageSetupDataGridContainer } from './styles';

const PayrollCostDeclarations = (): JSX.Element => {
  const { companyId } = useParams();
  const [t] = useTranslation();
  const dispatch = useAppDispatch();

  const [rowOnHover, setRowOnHover] = useState<number>();
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [clearAll, setClearAll] = useState<boolean>(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const costDeclarationsForCompany = useAppSelector(costDeclarationsForCompanySelector);

  const costDeclarationsForCompanyMap = useMemo(() => {
    const map: { [key: number]: number } = {};
    costDeclarationsForCompany.forEach(item => {
      map[item.id] = item.costDeclaration?.id;
    });
    return map;
  }, [costDeclarationsForCompany]);

  const selectedCostDeclarationIds = useMemo(() => {
    return selectedRows.map((id: number) => costDeclarationsForCompanyMap[id]);
  }, [selectedRows, costDeclarationsForCompanyMap]);

  useEffect(() => {
    dispatch(getCostDeclarationsForCompanyAction(toNumber(companyId)));
  }, [dispatch, companyId]);

  const changeSingleActiveStatus = (id: number) => {
    dispatch(
      bulkChangeActiveStatusForCompanyAction({
        costDeclarationIds: [id],
        companyId: toNumber(companyId),
      }),
    );
  };

  const columnDef: GridColDef[] = [
    { field: 'id', headerName: '', hide: true },
    {
      field: 'name',
      headerName: 'Cost declaration',
      width: 300,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return <Box>{params.row.costDeclaration.name}</Box>;
      },
    },
    {
      field: 'code',
      headerName: 'Code',
      width: 100,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        return <Box>{params.row.costDeclaration.code}</Box>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      sortable: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => {
        if (params.row.isActive) {
          return <GreenIcon />;
        }
        return <RedIcon />;
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 300,
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        if (rowOnHover === params.id && selectedRows.length === 0) {
          return (
            <Box>
              <UPButton
                outlined={params.row.isActive}
                text={params.row.isActive ? 'Deactivate' : 'Activate'}
                onClick={() => {
                  changeSingleActiveStatus(params.row.costDeclaration.id);
                }}
              />
            </Box>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Box>
      <Box>
        <PayrollAddNewItemButton
          text="+ Add cost declaration"
          onClick={() => {
            setAddModalOpen(true);
          }}
        />
      </Box>
      <WageSetupDataGridContainer>
        <UPDataGrid
          rows={costDeclarationsForCompany}
          columns={columnDef}
          disableSelectionOnClick
          onSelectionModelChange={(selectedItems: number[]) => {
            setSelectedRows(selectedItems);
            setClearAll(false);
          }}
          selectionModel={clearAll ? [] : selectedRows}
          setRowOnHover={setRowOnHover}
        />
      </WageSetupDataGridContainer>
      {selectedRows.length > 0 && (
        <AlertStyled severity="success" icon={false}>
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            {t(CONTRACTS__CLEAR_ALL)}
          </button>
          |
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              dispatch(
                deleteCostDeclarationsForCompanyAction({
                  costDeclarationIds: selectedCostDeclarationIds,
                  companyId: toNumber(companyId),
                }),
              );
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            {t(CONTRACTS__DELETE_SELECTED)}
          </button>
          |
          <button
            type="button"
            className="clear-all"
            onClick={() => {
              dispatch(
                bulkChangeActiveStatusForCompanyAction({
                  costDeclarationIds: selectedCostDeclarationIds,
                  companyId: toNumber(companyId),
                }),
              );
              setSelectedRows([]);
              setClearAll(true);
            }}
          >
            Change Active Status
          </button>
        </AlertStyled>
      )}
      {addModalOpen && <AddWageComponentModal open={addModalOpen} onClose={() => setAddModalOpen(false)} />}
    </Box>
  );
};

export default PayrollCostDeclarations;
