import { Box } from '@mui/material';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import {
  DASHBOARD__EDENRED_TEXT,
  DASHBOARD__SEE_HOURS_LEFT,
  DASHBOARD__SHORTCUTS,
  DASHBOARD__SIGN_CONTRACT,
  DASHBOARD__UPDATEPRO_TEXT,
} from 'translations/constants';
import IShortcut from 'types/common/IShortcut';
import { CardStyled, ShortcutsListContainer } from './styles';
import { ReactComponent as StudentAtWorkLogo } from '../../assets/icons/StudentAtWorkLogo.svg';
import { ReactComponent as EdenredLogo } from '../../assets/icons/EdenredLogo.svg';
import { ReactComponent as UnifiedpostGreyLogo } from '../../assets/icons/UnifiedpostGreyLogo.svg';
import { ReactComponent as LogoIcon } from '../../assets/icons/Logo.svg';

const DashboardShortcutsList = (): JSX.Element => {
  const [t] = useTranslation();

  const shortcutsList: IShortcut[] = useMemo(
    () => [
      {
        logo: <StudentAtWorkLogo />,
        text: t(DASHBOARD__SEE_HOURS_LEFT),
        url: 'https://www.studentatwork.be/nl/',
      },
      {
        logo: <EdenredLogo />,
        text: t(DASHBOARD__EDENRED_TEXT),
        url: 'https://myedenred.be/',
      },
      {
        logo: <UnifiedpostGreyLogo />,
        text: t(DASHBOARD__SIGN_CONTRACT),
        url: 'https://www.adminbox.eu/users/sign_in',
      },
      {
        logo: <LogoIcon />,
        text: t(DASHBOARD__UPDATEPRO_TEXT),
        url: 'https://www.update-pro.be/',
      },
    ],

    [t],
  );

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        color: '#9EA2A2',
        padding: '10px',
        fontSize: '14px',
      }}
    >
      <div style={{ paddingBottom: '10px' }}>{t(DASHBOARD__SHORTCUTS)}</div>
      <ShortcutsListContainer>
        {shortcutsList.map(shortcut => {
          return (
            <a href={shortcut.url} style={{ textDecoration: 'none' }} target="_blank" rel="noreferrer">
              <CardStyled>
                {shortcut.logo}
                <div style={{ color: '#008C15', fontWeight: '600' }}>{shortcut.text}</div>
              </CardStyled>
            </a>
          );
        })}
      </ShortcutsListContainer>
    </Box>
  );
};

export default DashboardShortcutsList;
