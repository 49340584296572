import { RootState } from 'store';
import ICompanyPayrollGeneral from 'types/company/payroll/ICompanyPayrollGeneral';

export const payrollSelector = (state: RootState): ICompanyPayrollGeneral => state.payroll.payroll;

export const addPayrollSuccessSelector = (state: RootState): boolean => state.payroll.addPayrollSuccess;
export const addPayrollErrorSelector = (state: RootState): boolean => state.payroll.addPayrollError;

export const updatePayrollSuccessSelector = (state: RootState): boolean => state.payroll.updatePayrollSuccess;
export const updatePayrollErrorSelector = (state: RootState): boolean => state.payroll.updatePayrollError;

export const payrollLoadingSelector = (state: RootState): boolean => state.payroll.loading;
