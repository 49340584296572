import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { DateFormat } from 'constants/DateFormat';
import { DayChangeContainer, MonthChangeButton, MonthDisplayContainer } from './styles';

const DayChangeComponent = (props: {
  day: moment.Moment;
  onChangeDay: (day: moment.Moment, direction: number) => void;
}): JSX.Element => {
  const { day, onChangeDay } = props;
  return (
    <DayChangeContainer>
      <MonthChangeButton>
        <KeyboardArrowLeft onClick={() => onChangeDay(day, -1)} />
      </MonthChangeButton>
      <MonthDisplayContainer>
        {day.format(DateFormat.DAY_FULL)} {day.format(DateFormat.DAY_NUMBER)} {day.format(DateFormat.MONTH_FULL)}
      </MonthDisplayContainer>
      <MonthChangeButton>
        <KeyboardArrowRight onClick={() => onChangeDay(day, 1)} />
      </MonthChangeButton>
    </DayChangeContainer>
  );
};

export default DayChangeComponent;
