import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  WAGE_COMPONENTS__CODE,
  WAGE_COMPONENTS__DESCRIPTION,
  WAGE_COMPONENTS__OUTCOME,
  WAGE_COMPONENTS__OVERTIME_SATURDAY,
  WAGE_COMPONENTS__OVERTIME_SUNDAY,
  WAGE_COMPONENTS__OVERTIME_WEEKDAY,
  WAGE_COMPONENTS__WORK_HOURS_SATURDAY,
  WAGE_COMPONENTS__WORK_HOURS_SUNDAY,
  WAGE_COMPONENTS__WORK_HOURS_WEEKDAY,
} from 'translations/constants';
import { IServiceCode } from 'types/wage-calculation';
import { useState, useEffect } from 'react';
import { ServiceCodesTableProps } from './types';
import ServiceCodesTableRow from './ServiceCodesTableRow';
import { BorderlessTableStyled } from './styles';

const ServiceCodesTable = (props: ServiceCodesTableProps) => {
  const { wageCalculationComponent, setWageCalculationComponent, setShouldUpdate, setValidValues } = props;

  const [t] = useTranslation();

  const [workHoursWeekDay, setWorkHoursWeekDay] = useState<IServiceCode>(wageCalculationComponent.workHoursWeekDay);
  const [workHoursSaturday, setWorkHoursSaturday] = useState<IServiceCode>(wageCalculationComponent.workHoursSaturday);
  const [workHoursSunday, setWorkHoursSunday] = useState<IServiceCode>(wageCalculationComponent.workHoursSunday);
  const [overtimeWeekDay, setOvertimeWeekDay] = useState<IServiceCode>(wageCalculationComponent.overtimeWeekDay);
  const [overtimeSaturday, setOvertimeSaturday] = useState<IServiceCode>(wageCalculationComponent.overtimeSaturday);
  const [overtimeSunday, setOvertimeSunday] = useState<IServiceCode>(wageCalculationComponent.overtimeSunday);

  useEffect(() => {
    if (wageCalculationComponent) {
      setWorkHoursWeekDay(wageCalculationComponent.workHoursWeekDay);
      setWorkHoursSaturday(wageCalculationComponent.workHoursSaturday);
      setWorkHoursSunday(wageCalculationComponent.workHoursSunday);
      setOvertimeWeekDay(wageCalculationComponent.overtimeWeekDay);
      setOvertimeSaturday(wageCalculationComponent.overtimeSaturday);
      setOvertimeSunday(wageCalculationComponent.overtimeSunday);
    }
  }, [wageCalculationComponent]);

  useEffect(() => {
    setWageCalculationComponent(prevState => ({
      ...prevState,
      workHoursWeekDay,
      workHoursSaturday,
      workHoursSunday,
      overtimeWeekDay,
      overtimeSaturday,
      overtimeSunday,
    }));
  }, [
    workHoursWeekDay,
    workHoursSaturday,
    workHoursSunday,
    overtimeWeekDay,
    overtimeSaturday,
    overtimeSunday,
    setWageCalculationComponent,
  ]);

  return (
    <TableContainer>
      <BorderlessTableStyled>
        <TableHead>
          <TableRow>
            <TableCell>{t(WAGE_COMPONENTS__OUTCOME)}</TableCell>
            <TableCell width="20%">{t(WAGE_COMPONENTS__CODE)}</TableCell>
            <TableCell>{t(WAGE_COMPONENTS__DESCRIPTION)}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <ServiceCodesTableRow
            outcome={WAGE_COMPONENTS__WORK_HOURS_WEEKDAY}
            serviceCode={workHoursWeekDay}
            setServiceCode={setWorkHoursWeekDay}
            setShouldUpdate={setShouldUpdate}
            setValidValues={setValidValues}
          />
          <ServiceCodesTableRow
            outcome={WAGE_COMPONENTS__WORK_HOURS_SATURDAY}
            serviceCode={workHoursSaturday}
            setServiceCode={setWorkHoursSaturday}
            setShouldUpdate={setShouldUpdate}
            setValidValues={setValidValues}
          />
          <ServiceCodesTableRow
            outcome={WAGE_COMPONENTS__WORK_HOURS_SUNDAY}
            serviceCode={workHoursSunday}
            setServiceCode={setWorkHoursSunday}
            setShouldUpdate={setShouldUpdate}
            setValidValues={setValidValues}
          />
          <ServiceCodesTableRow
            outcome={WAGE_COMPONENTS__OVERTIME_WEEKDAY}
            serviceCode={overtimeWeekDay}
            setServiceCode={setOvertimeWeekDay}
            setShouldUpdate={setShouldUpdate}
            setValidValues={setValidValues}
          />
          <ServiceCodesTableRow
            outcome={WAGE_COMPONENTS__OVERTIME_SATURDAY}
            serviceCode={overtimeSaturday}
            setServiceCode={setOvertimeSaturday}
            setShouldUpdate={setShouldUpdate}
            setValidValues={setValidValues}
          />
          <ServiceCodesTableRow
            outcome={WAGE_COMPONENTS__OVERTIME_SUNDAY}
            serviceCode={overtimeSunday}
            setServiceCode={setOvertimeSunday}
            setShouldUpdate={setShouldUpdate}
            setValidValues={setValidValues}
          />
        </TableBody>
      </BorderlessTableStyled>
    </TableContainer>
  );
};

export default ServiceCodesTable;
