import UPTab from 'components/tab';
import { useTranslation } from 'react-i18next';
import { API_USERS, SUPERADMINS } from 'translations/constants';
import SuperAdmins from './SuperAdmins';
import ApiUsers from './ApiUsers';

const Users = (): JSX.Element => {
  const [t] = useTranslation();

  const tabs = [
    { title: t(SUPERADMINS), route: 'superadmins' },
    { title: t(API_USERS), route: 'apiusers' },
  ];

  return (
    <UPTab sections={tabs}>
      <SuperAdmins />
      <ApiUsers />
    </UPTab>
  );
};

export default Users;
