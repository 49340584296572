import { Box, styled, Typography } from '@mui/material';

export const CustomTooltipWrapper = styled(Box)(({ theme }) => ({
  backGroundColor: theme.palette.common.white,
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  // border: `1px solid ${theme.palette.grey[300]}`,
  whiteSpace: 'nowrap',
  padding: '10px',
}));

export const CompanyNameLabel = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 'bold',
}));

export const CurrentMonthHoursLabel = styled(Typography)(({ theme }) => ({
  color: '#8884d8',
  fontSize: '12px',
}));

export const PastMonthHoursLabel = styled(Typography)(({ theme }) => ({
  color: '#82ca9d',
  fontSize: '12px',
}));

export const PastYearCurrentMonthHoursLabel = styled(Typography)(({ theme }) => ({
  color: '#409660',
  fontSize: '12px',
}));
