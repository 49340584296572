import { Box, Divider, FormControl, InputLabel, Select, styled, TextField } from '@mui/material';

export const UPInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.secondary.main,
}));

export const UPInputTextField = styled(TextField)(({ theme }) => ({
  height: 49,
  '.MuiInputBase-root': { borderColor: theme.palette.secondary.main, display: 'flex', flexWrap: 'nowrap' },
  '.MuiOutlinedInput-input': {
    height: 16,
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.secondary.dark,
  },
  '.MuiOutlinedInput-root': { paddingRight: 0 },
  '.Mui-error input': { color: theme.palette.error.main },
  '.Mui-disabled': {
    backgroundColor: theme.palette.info.light,
    '.MuiSvgIcon-root': {
      fill: theme.palette.secondary.main,
      cursor: 'default',
    },
  },
  '.MuiFormHelperText-root': { marginLeft: 0 },
}));

export const UIInputIconContainer = styled(Box)(() => ({
  paddingRight: '16px',
  paddingTop: '2px',
  cursor: 'pointer',
}));

export const UPInputFormControl = styled(FormControl)(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '2px',
  alignItems: 'center',
  width: '90px',
}));

export const UPInputDivider = styled(Divider)(() => ({
  height: '30px',
  width: '1px',
  display: 'inline-block',
  position: 'absolute',
  left: '0px',
}));

export const UPInputSelect = styled(Select)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.primary.main,
  position: 'absolute',
  display: 'inline-block',
  height: '28px',

  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    top: '2px',
    left: '28px',
  },
  '.MuiInput-root&.MuiInputBase-root&.MuiInputBase-colorPrimary&.MuiInputBase-formControl': {
    margin: 0,
    padding: 0,
    '.MuiInputBase-input': {
      paddingRight: 10,
    },
  },
  div: {
    height: 'auto',
    '&:focus': {
      background: 'transparent',
    },
  },
}));
