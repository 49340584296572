import { MonthChangeComponent } from 'modules/availabilities/date-change-components';
import { useContext } from 'react';
import { AvailabilitiesContext } from '../Availabilities';
import AvailabilitiesCalendar from '../calendar';
import { AvailabilitiesTableContainer } from './styles';
import { AvailabilitiesTableProps } from '../types';

const AvailabilitiesTable = (props: AvailabilitiesTableProps): JSX.Element => {
  const { onChangeMonth } = props;
  const { currentMonth, currentYear, availability } = useContext(AvailabilitiesContext);

  return (
    <AvailabilitiesTableContainer>
      <MonthChangeComponent
        currentMonth={currentMonth}
        onChangeMonth={onChangeMonth}
        currentYear={currentYear}
        isAvailabilitySubmitted={availability?.isSubmitted}
      />
      <AvailabilitiesCalendar />
    </AvailabilitiesTableContainer>
  );
};

export default AvailabilitiesTable;
