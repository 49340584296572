import { createReducer } from '@reduxjs/toolkit';
import {
  getApiErrorsErrorAction,
  getApiErrorsLoadingAction,
  getApiErrorsSuccessAction,
  setApiErrorTypesAction,
  setApiErrorsAction,
} from 'store/actions/api-errors-actions';
import { IApiErrorsState } from 'types/errors';

const initialState: IApiErrorsState = {
  errors: null,
  getErrorsLoading: false,
  getErrorsSuccess: false,
  getErrorsError: false,
  errorTypes: [],
};

const apiErrorsReducer = createReducer(initialState, builder => {
  return builder
    .addCase(getApiErrorsLoadingAction, (state, action) => {
      return {
        ...state,
        getErrorsLoading: action.payload,
      };
    })
    .addCase(getApiErrorsSuccessAction, (state, action) => {
      return {
        ...state,
        getErrorsSuccess: action.payload,
      };
    })
    .addCase(getApiErrorsErrorAction, (state, action) => {
      return {
        ...state,
        getErrorsError: action.payload,
      };
    })
    .addCase(setApiErrorsAction, (state, action) => {
      return {
        ...state,
        errors: action.payload,
      };
    })
    .addCase(setApiErrorTypesAction, (state, action) => {
      return {
        ...state,
        errorTypes: action.payload,
      };
    });
});

export default apiErrorsReducer;
