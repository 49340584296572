import { Box, Modal, styled } from '@mui/material';

export const CostDeclarationModalContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CostDeclarationModalStyled = styled(Modal)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  zIndex: '2',
  maxHeight: '600px',
  height: 'auto',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.up('sm')]: {
    width: '550px',
  },

  [theme.breakpoints.down(611)]: {
    width: '90%',
  },
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  background: 'white',
  padding: '5px 5px 0px 5px',
  display: 'flex',
  justifyContent: 'space-between',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));

export const ModalCloseIcon = styled(Box)(() => ({
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const ModalContainer = styled(Box)(() => ({
  width: '100%',
  background: 'white',
  position: 'relative',
  padding: '20px',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  borderRadius: '8px',
  border: 'none',
  maxHeight: '650px',
  overflowX: 'auto',
}));

export const RowActionContainer = styled(Box)(() => ({
  width: '90%',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
}));

export const CostDeclarationGridContainer = styled(Box)(() => ({
  width: '100%',
  height: '65vh',
}));

export const ModalInputContainer = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  marginTop: '20px',
}));

export const ModalFooter = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
}));

export const ModalActionButtonsContainer = styled(Box)(({ theme }) => ({
  width: '40%',
  display: 'flex',
  gap: '10px',
  justifyContent: 'space-between',

  [theme.breakpoints.down(611)]: {
    width: '60%',
  },
}));
