import { createReducer } from '@reduxjs/toolkit';
import {
  clearParitairComiteAction,
  clearUpdateParitairComiteStatusAction,
  getParitairComiteAction,
  getParitairComiteErrorAction,
  getParitairComiteSelectItemsErrorAction,
  getParitairComiteSelectItemsErrorByCompanyAction,
  saveParitairComiteErrorAction,
  saveParitairComiteSuccessAction,
  setParitairComiteIdAction,
  setParitairComitesAction,
  setParitairComiteSelectItemsAction,
  setParitairComiteSelectItemsByCompanyAction,
  updateParitairComiteErrorAction,
  setParitairComiteRequestsLoadingAction,
  updateParitairComiteSuccessAction,
} from 'store/actions/paritair-comite-actions';
import { IParitairComiteState } from 'types/paritair-comite';

const initialState: IParitairComiteState = {
  paritairComites: [],
  paritairComite: null,
  paritairComiteError: false,
  paritairComiteSelectItems: [],
  paritairComiteByCompanySelectItems: [],
  getParitairComiteSelectItemsError: false,
  getParitairComiteSelectItemsByCompanyError: false,
  saveParitairComiteSuccess: false,
  saveParitairComiteError: false,
  paritairComiteId: undefined,
  loading: false,
  updateParitairComiteSuccess: false,
  updateParitairComiteError: false,
};
const companyReducer = createReducer(initialState, builder => {
  return builder
    .addCase(setParitairComiteSelectItemsAction, (state, action) => ({
      ...state,
      paritairComiteSelectItems: action.payload,
    }))
    .addCase(getParitairComiteSelectItemsErrorAction, (state, action) => ({
      ...state,
      getParitairComiteSelectItemsError: action.payload,
    }))
    .addCase(setParitairComiteSelectItemsByCompanyAction, (state, action) => ({
      ...state,
      paritairComiteByCompanySelectItems: action.payload,
    }))
    .addCase(getParitairComiteSelectItemsErrorByCompanyAction, (state, action) => ({
      ...state,
      getParitairComiteSelectItemsByCompanyError: action.payload,
    }))
    .addCase(setParitairComitesAction, (state, action) => ({ ...state, paritairComites: action.payload }))
    .addCase(setParitairComiteRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(getParitairComiteAction, (state, action) => ({ ...state, paritairComite: action.payload }))
    .addCase(getParitairComiteErrorAction, (state, action) => ({ ...state, paritairComiteError: action.payload }))
    .addCase(saveParitairComiteSuccessAction, (state, action) => ({
      ...state,
      saveParitairComiteSuccess: action.payload,
    }))
    .addCase(saveParitairComiteErrorAction, (state, action) => ({ ...state, saveParitairComiteError: action.payload }))
    .addCase(setParitairComiteIdAction, (state, action) => ({ ...state, paritairComiteId: action.payload }))
    .addCase(updateParitairComiteSuccessAction, (state, action) => ({
      ...state,
      updateParitairComiteSuccess: action.payload,
    }))
    .addCase(updateParitairComiteErrorAction, (state, action) => ({
      ...state,
      updateParitairComiteError: action.payload,
    }))
    .addCase(clearUpdateParitairComiteStatusAction, state => ({
      ...state,
      updateParitairComiteError: false,
      updateParitairComiteSuccess: false,
      loading: false,
    }))
    .addCase(clearParitairComiteAction, state => ({
      ...state,
      paritairComite: null,
      loading: false,
      paritairComiteError: false,
      saveParitairComiteSuccess: false,
      saveParitairComiteError: false,
      paritairComiteId: undefined,
      updateParitairComiteSuccess: false,
      updateParitairComiteError: false,
    }));
});

export default companyReducer;
