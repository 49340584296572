import { InputLabel, Modal, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import UPButton from 'components/button';

export const AddNewDocumentButton = styled(UPButton)(({ theme }) => ({
  margin: '26px 16px 16px 0px',
  height: '15%',
  width: '6%',
  top: '3px',
  [theme.breakpoints.down(690)]: {
    width: '89%',
    alignSelf: 'center',
    margin: '16px 16px 16px 24px',
  },
}));

export const ModalWrapper = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '944px',
  maxHeight: '650px',
  height: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },
  [theme.breakpoints.down(1000)]: {
    width: '99%',
    // maxHeight: '534px',
    maxHeight: '99%',
    height: '99%',
  },
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  padding: '32px 32px 0px 32px',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  background: 'rgb(250, 250, 250)',
  borderRadius: '8px',
  border: 'none',
  [theme.breakpoints.down(1000)]: {
    height: '80%',
    width: '90%',
    top: '13.5%',
    left: '5%',
    position: 'relative',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
  // [theme.breakpoints.down(800)]: {
  //   textIndent: '-9999px',
  //   overflow: 'hidden',
  // },
}));

export const ModalFooter = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: '12px 0px',
  display: 'flex',
  justifyContent: 'center',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
  [theme.breakpoints.down(1000)]: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
  },
}));

export const ModalCloseIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',
  [theme.breakpoints.down(800)]: {
    display: 'none',
  },
}));

export const FiltersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.down(690)]: {
    flexDirection: 'column',
    width: 'unset',
    alignItems: 'start',
  },
}));

export const FilterItem = styled(Box)(({ theme }) => ({
  width: '300px',
  display: 'inline-block',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
  },
}));

export const InvoiceFilterItem = styled(Box)(({ theme }) => ({
  width: '300px',
  marginLeft: '8px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    alignSelf: 'center',
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down(722)]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const ModalRowContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0px 32px',
  [theme.breakpoints.down(722)]: {
    margin: '0px 32px 0px 12px',
  },
}));

export const ModalRowDetailsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 32px',
  marginTop: '0px',
  maxHeight: '300px',
  overflowY: 'auto',
  [theme.breakpoints.down(722)]: {
    padding: '10px 28px 0px 12px',
    margin: '0px, 12px 0px 12px',
  },
}));

export const DocumentBoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  border: '1px dotted black',
  borderRadius: '5px',
  marginBottom: '5px',
  [theme.breakpoints.up(730)]: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export const ColumnContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up(730)]: {
    width: '50%',
    marginTop: '16px',
    marginLeft: '8px',
  },
}));

export const DocumentBox = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  minWidth: '150px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    marginLeft: '5px',
    marginTop: '2px',
    marginBottom: '2px',
  },
}));

export const DocumentBoxForEmployee = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  [theme.breakpoints.down(690)]: {
    minWidth: '90%',
    marginLeft: '5px',
    marginTop: '2px',
    marginBottom: '2px',
  },
  [theme.breakpoints.up(691)]: {
    minWidth: '97%',
  },
}));

export const DocumentError = styled(InputLabel)(({ theme }) => ({
  fontSize: 12,
  marginLeft: '24px',
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
  [theme.breakpoints.down(690)]: {
    marginLeft: '20px',
    marginBottom: '10px',
    marginTop: '10px',
  },
}));

export const DragAndDropParagraph = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  [theme.breakpoints.down(1000)]: {
    display: 'none',
  },
}));

export const DragAndDropContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0px 32px',
  [theme.breakpoints.down(1000)]: {
    margin: '0px 32px 0px 12px',
    height: '50%',
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(1000)]: {
    height: '80%',
    width: '100%',
    position: 'absolute',
  },
}));
