import { useTranslation } from 'react-i18next';
import { DATAGRID__NO_ROWS } from 'translations/constants';
import { useAppSelector } from 'store';
import { itemsPerPageSelector } from 'store/selectors/app-selectors';
import { UPDataGridProps } from './types';
import { DataGridStyled } from './styles';
import CustomPagination from './CustomPagination';

const UPDataGrid = (props: UPDataGridProps): JSX.Element => {
  const {
    rows,
    columns,
    setRowOnHover,
    onSelectionModelChange,
    withCheckboxes,
    hideFooter,
    getRowId,
    experimentalFeatures,
    onCellEditStop,
    selectionModel,
    disableSelectionOnClick,
    sortModel,
    handleSortModelChange,
    onHeaderColumnClick,
    getRowClassName,
    headerHeight,
    onPageSizeChange,
    onPageChange,
  } = props;

  const [t] = useTranslation();
  const itemsPerPage = useAppSelector(itemsPerPageSelector);

  return (
    <DataGridStyled
      rows={rows}
      columns={columns}
      checkboxSelection={withCheckboxes}
      disableColumnMenu
      getRowId={getRowId}
      componentsProps={{
        row: {
          onMouseEnter: (event: { currentTarget: { dataset: { id: any } } }) => {
            setRowOnHover(Number(event.currentTarget.dataset.id));
          },
        },
      }}
      selectionModel={selectionModel}
      disableSelectionOnClick={disableSelectionOnClick}
      onSelectionModelChange={onSelectionModelChange}
      hideFooter={hideFooter}
      experimentalFeatures={experimentalFeatures}
      onCellEditStop={onCellEditStop}
      localeText={{ noRowsLabel: t(DATAGRID__NO_ROWS) }}
      pagination
      pageSize={itemsPerPage}
      onPageSizeChange={onPageSizeChange}
      onPageChange={onPageChange}
      components={{ Pagination: CustomPagination }}
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
      onColumnHeaderClick={onHeaderColumnClick}
      headerHeight={headerHeight ?? 56} // 56 is the default header height
      getRowClassName={getRowClassName}
    />
  );
};

UPDataGrid.defaultProps = {
  withCheckboxes: true,
  getRowId: null,
  hideFooter: false,
  experimentalFeatures: null,
  onCellEditStop: null,
};

export default UPDataGrid;
