import { Box } from '@mui/material';
import { UPInputLabel } from 'components/input/styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UPDateRangePickerProps } from './types';

const UPDateRangePicker = (props: UPDateRangePickerProps): JSX.Element => {
  const { startDate, endDate, setDateRange } = props;

  return (
    <Box>
      <UPInputLabel>Date Range Picker</UPInputLabel>
      <DatePicker
        sx={{ width: '100%' }}
        className="up-date-range-picker"
        dateFormat="dd/MM/yyyy"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={update => {
          setDateRange(update);
        }}
        calendarStartDay={1}
        isClearable
        onKeyDown={e => {
          e.preventDefault();
        }}
      />
    </Box>
  );
};

export default UPDateRangePicker;
