import { styled, Box, Modal, Typography } from '@mui/material';
import { UPInputLabel } from 'components/input/styles';

export const ModalWrapper = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '944px',
  maxHeight: '650px',
  height: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },

  [theme.breakpoints.down(1000)]: {
    width: '97%',
    maxHeight: '534px',
    height: '70%',
  },
}));

export const InviteEmployeeModalWrapper = styled(Modal)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '944px',
  maxHeight: '650px',
  height: '650px',
  '.MuiBackdrop-root': {
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
    borderRadius: '8px',
    position: 'relative',
  },

  [theme.breakpoints.down(1000)]: {
    width: '97%',
    // maxHeight: '534px',
    height: '99%',
  },
}));

export const ModalHeader = styled(Box)(() => ({
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  padding: '32px 32px 0px 32px',
  zIndex: 999,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  width: '90%',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  background: 'rgb(250, 250, 250)',
  borderRadius: '8px',
  border: 'none',
  [theme.breakpoints.down(1000)]: {
    height: '100%',
    width: '100%',
  },
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  color: theme.palette.secondary.contrastText,
  fontWeight: 600,
  fontSize: 16,
  textAlign: 'left',
}));

export const ModalFooter = styled(Box)(() => ({
  backgroundColor: 'white',
  padding: '12px 0px',
  display: 'flex',
  justifyContent: 'center',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
}));

export const ErrorText = styled(UPInputLabel)(({ theme }) => ({
  '&.MuiFormLabel-colorPrimary': {
    color: theme.palette.error.main,
  },
}));

export const ModalCloseIcon = styled(Box)(() => ({
  position: 'absolute',
  right: 32,
  top: 32,
  width: 24,
  height: 24,
  cursor: 'pointer',
}));

export const RowContainerForContactInfo = styled(Box)(({ theme }) => ({
  display: 'flex',

  padding: '20px',
  marginTop: '0px',
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column',
  },
}));

export const NameStyled = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  width: '25%',
  [theme.breakpoints.down(800)]: {
    marginRight: 'unset',
    marginBottom: '16px',
    width: '100%',
  },
}));
export const EmailStyled = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  width: '50%',
  [theme.breakpoints.down(800)]: {
    marginRignt: 'unset',
    width: '100%',
  },
}));

export const RowContainer = styled(Box)(({ theme }) => ({
  marginRight: '16px',
  width: '50%',
  [theme.breakpoints.down(800)]: {
    minWidth: '100%',
  },
}));
