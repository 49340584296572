import { UPDropdownItem } from 'components/dropdown';
import { RootState } from 'store';
import locationsAdapter from 'store/adapters/company-locations-adapter';

export const locationsSelectors = locationsAdapter.getSelectors((state: RootState) => state.locations);
export const locationsSelector = locationsSelectors.selectAll;
export const locationsErrorSelector = (state: RootState): boolean => state.locations.locationsError;
export const locationsDropdownItemsSelector = (state: RootState): UPDropdownItem[] =>
  state.locations.locationsDropdownItems;

export const locationsLoadingSelector = (state: RootState): boolean => state.locations.loading;
