import { createReducer } from '@reduxjs/toolkit';
import {
  clearBicAction,
  setBicAction,
  setBicErrorAction,
  setFinancialRequestsLoadingAction,
  setBicRequiredErrorAction,
} from 'store/actions/financial-actions';
import IBicState from 'types/common/IBicState';

const initialState: IBicState = {
  bic: '',
  loading: false,
  bicError: false,
  bicRequiredError: false,
};

const financialReducer = createReducer(initialState, builder =>
  builder
    .addCase(setFinancialRequestsLoadingAction, (state, action) => ({
      ...state,
      loading: action.payload,
    }))
    .addCase(setBicErrorAction, (state, action) => ({
      ...state,
      bicError: action.payload,
    }))
    .addCase(setBicRequiredErrorAction, (state, action) => ({
      ...state,
      bicRequiredError: action.payload,
    }))
    .addCase(setBicAction, (state, action) => ({
      ...state,
      bic: action.payload,
    }))
    .addCase(clearBicAction, state => ({
      ...state,
      bic: '',
      bicError: false,
      loading: false,
      bicRequiredError: false,
    })),
);

export default financialReducer;
