import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { UPBlogWithDetails, UPBlogWithNoDetails } from 'modules/blogs/types';

export const getAllBlogsRequest = async (): Promise<UPBlogWithNoDetails[]> => {
  const response: AxiosResponse<UPBlogWithNoDetails[]> = await getApi().get('/Blog');
  return response.data;
};

export const postBlogRequest = async (props: { blog: UPBlogWithDetails }): Promise<number> => {
  const { blog } = props;
  const response: AxiosResponse<number> = await getApi().post('/Blog/New', blog);
  return response.data;
};

export const editBlogRequest = async (props: { blog: UPBlogWithDetails }): Promise<void> => {
  const { blog } = props;
  await getApi().put('/Blog', blog);
};

export const getBlogByIdRequest = async (props: { id: number }): Promise<UPBlogWithDetails> => {
  const { id } = props;
  const response: AxiosResponse<UPBlogWithDetails> = await getApi().get(`/Blog/${id}`);
  return response.data;
};

export const deleteBlogRequest = async (props: { id: number }): Promise<void> => {
  const { id } = props;
  await getApi().delete(`Blog/${id}`);
};

export const getDashboardBlogsRequest = async (): Promise<UPBlogWithNoDetails[]> => {
  const response: AxiosResponse<UPBlogWithNoDetails[]> = await getApi().get('/Blog/GetForDashboard');
  return response.data;
};
