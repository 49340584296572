import * as Sentry from '@sentry/react';

/* eslint-disable no-console */
const logError = (error: Error | unknown, extra?: unknown): void => {
  console.error(error);

  if (extra) {
    Sentry.captureException(error, scope => scope.setExtra('extra', extra));
  } else {
    Sentry.captureException(error);
  }
};

export default logError;
