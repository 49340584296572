import { Box, MenuItem } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { UPInputLabel } from 'components/input/styles';
import { CloseAlertIcon } from 'modules/companies/styles';
import React from 'react';
import theme from 'theme';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { CompanyNameInputStyled, DropdownPlaceholder, MenuProps, UPDropdownHelperText, UPSelectStyled } from './styles';
import { UPDropdownProps } from './types';

const UPDropdown = React.forwardRef((props: UPDropdownProps, ref): JSX.Element => {
  const {
    items,
    value,
    onChange,
    placeholder,
    label,
    className,
    required,
    error,
    helperText,
    disabled,
    isFilter,
    disableUnderline,
  } = props;

  const onClearSelection = () => {
    onChange({
      target: {
        value: '',
      },
    });
  };

  return (
    <Box className={className} sx={{ width: '100%' }}>
      <UPInputLabel>
        {label} {required && '*'}
      </UPInputLabel>

      <UPSelectStyled
        displayEmpty
        value={value}
        onChange={onChange}
        input={disableUnderline ? <CompanyNameInputStyled /> : <OutlinedInput />}
        renderValue={value === '' ? () => <DropdownPlaceholder>{placeholder}</DropdownPlaceholder> : undefined}
        MenuProps={MenuProps}
        fullWidth
        ref={ref}
        required
        error={error}
        disabled={disabled}
        disableUnderline={disableUnderline}
        endAdornment={
          value !== '' &&
          isFilter &&
          !disabled && (
            <Box onClick={() => onClearSelection()} sx={{ mr: theme.spacing(2), cursor: 'pointer', height: '24px' }}>
              <CloseIcon fill={theme.palette.primary.main} />
            </Box>
          )
        }
      >
        {/* {isFilter && (
          <MenuItem value="">
            <div>{t(CONTRACTS__NONE)}</div>
          </MenuItem>
        )} */}
        {items.map(item => (
          <MenuItem key={item?.value} value={item?.value} disabled={item?.isDisabled}>
            {item?.label}
          </MenuItem>
        ))}
      </UPSelectStyled>
      {error && <UPDropdownHelperText>{helperText}</UPDropdownHelperText>}
    </Box>
  );
});

UPDropdown.defaultProps = {
  placeholder: 'Select',
  className: '',
  required: false,
  error: false,
  helperText: '',
  disabled: false,
};
export default UPDropdown;
