import { RootState } from 'store';
import IProfile from 'types/profile/IProfile';

export const profileSelector = (state: RootState): IProfile => state.profile.data;
export const updatePasswordErrorSelector = (state: RootState): boolean => state.profile.updatePasswordError;
export const updatePasswordSuccessSelector = (state: RootState): boolean => state.profile.updatePasswordSuccess;
export const checkPasswordSelector = (state: RootState): boolean => state.profile.checkPassword;
export const setPasswordErrorSelector = (state: RootState): boolean => state.profile.setPasswordError;
export const setPasswordSuccessSelector = (state: RootState): boolean => state.profile.setPasswordSuccess;
export const updateLanguageSuccessSelector = (state: RootState): boolean => state.profile.updateLanguageSuccess;
export const updateLanguageErrorSelector = (state: RootState): boolean => state.profile.updateLanguageError;

export const profileLoadingSelector = (state: RootState): boolean => state.profile.loading;
