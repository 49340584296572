import UPDropdownWithInput from 'components/dropdown-with-input';
import UPInput from 'components/input';
import { filterByHour } from 'modules/contracts/helpers';
import HOUR_OPTIONS from 'modules/contracts/HourDropdownOptions';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { WAGE_COMPONENTS__CODE, WAGE_COMPONENTS__ENDS_AT, WAGE_COMPONENTS__STARTS_FROM } from 'translations/constants';
import { onChangeCode } from './helpers';
import { IntervalHourBox, IntervalText } from './styles';
import { NightShiftSchemaProps } from './types';

const NightShiftSchema = (props: NightShiftSchemaProps) => {
  const { nightShiftRule, setNightShiftRule, setShouldUpdate, setValidValues } = props;

  const [t] = useTranslation();

  const onChangeHours = (newValue: string, isStartTime: boolean) => {
    if (newValue === '') {
      setValidValues(false);
    } else {
      setValidValues(true);
    }

    if (isStartTime) {
      setNightShiftRule(prevState => ({
        ...prevState,
        startTime: newValue,
      }));
    } else {
      setNightShiftRule(prevState => ({
        ...prevState,
        endTime: newValue,
      }));
    }

    setShouldUpdate(true);
  };

  return (
    <>
      <IntervalHourBox>
        <IntervalText>{t(WAGE_COMPONENTS__STARTS_FROM)}:</IntervalText>
        <UPDropdownWithInput
          withSearchIcon={false}
          value={nightShiftRule.startTime}
          handleChange={newValue => onChangeHours(newValue, true)}
          filterOptions={filterByHour}
          placeholder={t(WAGE_COMPONENTS__STARTS_FROM)}
          items={HOUR_OPTIONS}
          label=""
          type=""
        />
      </IntervalHourBox>
      <IntervalHourBox>
        <IntervalText>{t(WAGE_COMPONENTS__ENDS_AT)}:</IntervalText>
        <UPDropdownWithInput
          withSearchIcon={false}
          value={nightShiftRule.endTime}
          handleChange={newValue => onChangeHours(newValue, false)}
          filterOptions={filterByHour}
          placeholder={t(WAGE_COMPONENTS__ENDS_AT)}
          items={HOUR_OPTIONS}
          label=""
          type=""
        />
      </IntervalHourBox>
      <IntervalHourBox>
        <IntervalText>Code:</IntervalText>
        <UPInput
          label=""
          placeholder={t(WAGE_COMPONENTS__CODE)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChangeCode(event, setNightShiftRule, setValidValues, setShouldUpdate);
          }}
          value={nightShiftRule.code.toString()}
        />
      </IntervalHourBox>
    </>
  );
};

export default NightShiftSchema;
