import { UPDropdownItem } from 'components/dropdown';

const CATEGORIES: UPDropdownItem[] = [
  { value: 'Category 1', label: 'Category 1' },
  { value: 'Category 2', label: 'Category 2' },
  { value: 'Category 3', label: 'Category 3' },
  { value: 'Category 4', label: 'Category 4' },
  { value: 'Category 5', label: 'Category 5' },
  { value: 'Category 6', label: 'Category 6' },
  { value: 'Category 7', label: 'Category 7' },
  { value: 'Category 8', label: 'Category 8' },
  { value: 'Category 9', label: 'Category 9' },
  { value: 'Category 10', label: 'Category 10' },
  { value: 'Category 11', label: 'Category 11' },
  { value: 'Category 12', label: 'Category 12' },
  { value: 'Category 13', label: 'Category 13' },
  { value: 'Category 14', label: 'Category 14' },
  { value: 'Category 15', label: 'Category 15' },
];

export default CATEGORIES;
