export enum EmployerLabel {
  TeamRetail = 'Team 1: Retail',
  TeamHorecaEvents = 'Team 1: Horeca & Events',
  TeamAlgemeen = 'Team 1: Algemeen bedienden',
  Team1Overige = 'Team 1: Overige',
  TeamSecurity = 'Team 2: Security',
  TeamTransport = 'Team 2: Transport & Logistiek',
  TeamSchoonmaak = 'Team 2: Schoonmaak',
  Team2Overige = 'Team 2: Overige',
}
