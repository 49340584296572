import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { UPDropdownItem } from 'components/dropdown';
import IValidationWithMessage from 'types/company/payroll/IValidationWithMessage';
import { ICostDeclaration, ICostDeclarationForCompany } from 'types/cost-declaration';
import { IOvertimeLimits, IWageCalculation, IWorkhourSettings } from 'types/wage-calculation';

export const getAllCostDeclarationsRequest = async (): Promise<ICostDeclaration[]> => {
  const response: AxiosResponse<ICostDeclaration[]> = await getApi().get('CostDeclaration/GetAll');
  return response.data;
};

export const getCostDeclarationByIdRequest = async (costDeclarationId: number): Promise<ICostDeclaration> => {
  const response: AxiosResponse<ICostDeclaration> = await getApi().get(`CostDeclaration/${costDeclarationId}`);
  return response.data;
};

export const saveCostDeclarationRequest = async (costDeclaration: ICostDeclaration): Promise<number> => {
  const response: AxiosResponse<number> = await getApi().post('CostDeclaration', costDeclaration);
  return response.data;
};

export const updateCostDeclarationRequest = async (costDeclaration: ICostDeclaration): Promise<void> => {
  await getApi().put('CostDeclaration', costDeclaration);
};

export const deleteCostDeclarationsRequest = async (costDeclarationIds: number[]): Promise<void> => {
  await getApi().delete('CostDeclaration', { data: costDeclarationIds });
};

export const getCostDeclarationSelectItemsRequest = async (companyId: number): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(`CostDeclaration/GetSelectItems/${companyId}`);
  return response.data;
};

export const getCostDeclarationsForCompanyRequest = async (
  companyId: number,
): Promise<ICostDeclarationForCompany[]> => {
  const response: AxiosResponse<ICostDeclarationForCompany[]> = await getApi().get(
    `CompanyCostDeclaration/${companyId}`,
  );
  return response.data;
};

export const getCostDeclarationSelectItemsForCompanyRequest = async (companyId: number): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(
    `CompanyCostDeclaration/GetSelectItems/${companyId}`,
  );
  return response.data;
};

export const saveCostDeclarationsForCompanyRequest = async (
  costDeclarationIds: number[],
  companyId: number,
): Promise<void> => {
  const response: AxiosResponse<void> = await getApi().post(`CompanyCostDeclaration`, {
    companyId,
    costDeclarationIds,
  });
  return response.data;
};

export const bulkChangeActiveStatusRequest = async (costDeclarationIds: number[]): Promise<void> => {
  const response: AxiosResponse<void> = await getApi().post(
    `CostDeclaration/BulkChangeActiveStatus`,
    costDeclarationIds,
  );
  return response.data;
};

export const bulkChangeActiveStatusForCompanyRequest = async (
  costDeclarationIds: number[],
  companyId: number,
): Promise<void> => {
  const response: AxiosResponse<void> = await getApi().post(`CompanyCostDeclaration/BulkChangeActiveStatus`, {
    companyId,
    costDeclarationIds,
  });
  return response.data;
};

export const deleteCostDeclarationsForCompanyRequest = async (
  costDeclarationIds: number[],
  companyId: number,
): Promise<void> => {
  await getApi().delete(`CompanyCostDeclaration`, { data: { companyId, costDeclarationIds } });
};

export const getWageCalculationRequest = async (
  paritairComiteId: number,
  companyId?: number,
): Promise<IWageCalculation> => {
  const response: AxiosResponse<IWageCalculation> = await getApi().get(`WageCalculation/${paritairComiteId}`, {
    headers: { companyId },
  });
  return response.data;
};

export const updateWageCalculationRequest = async (
  paritairComiteId: number,
  companyId: number,
  wageCalculation: IWageCalculation,
): Promise<void> => {
  await getApi().put(`WageCalculation/${paritairComiteId}`, wageCalculation, {
    headers: { companyId },
  });
};

export const resetWageCalculationRequest = async (
  paritairComiteId: number,
  companyId: number,
  wageCalculation: IWageCalculation,
): Promise<void> => {
  await getApi().put(`WageCalculation/${paritairComiteId}/reset`, wageCalculation, {
    headers: { companyId },
  });
};

export const getCompanyOvertimeLimitsRequest = async (
  companyId: number,
  paritairComiteId: number,
): Promise<IOvertimeLimits> => {
  const response: AxiosResponse<IOvertimeLimits> = await getApi().get(
    `WageCalculation/OvertimeLimits/${companyId}/${paritairComiteId}`,
  );
  return response.data;
};

export const updateCompanyOvertimeLimitsRequest = async (props: {
  companyId: number;
  paritairComiteId: number;
  overtimeLimits: IOvertimeLimits;
}): Promise<IValidationWithMessage> => {
  const { companyId, paritairComiteId, overtimeLimits } = props;
  const response = await getApi().put(
    `WageCalculation/OvertimeLimits/${companyId}/${paritairComiteId}`,
    overtimeLimits,
  );
  return response.data;
};

export const getWorkhourSettingsRequest = async (
  companyId: number,
  paritairComiteId: number,
): Promise<IWorkhourSettings> => {
  const response: AxiosResponse<IWorkhourSettings> = await getApi().get(
    `WageCalculation/WorkhourSettings/${companyId}/${paritairComiteId}`,
  );
  return response.data;
};

export const updateWorkhourSettingsRequest = async (props: {
  companyId: number;
  paritairComiteId: number;
  workhourSettings: IWorkhourSettings;
}): Promise<IValidationWithMessage> => {
  const { companyId, paritairComiteId, workhourSettings } = props;
  const response = await getApi().put(
    `WageCalculation/WorkhourSettings/${companyId}/${paritairComiteId}`,
    workhourSettings,
  );
  return response.data;
};
