import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { APP__SET_ITEMS_PER_PAGE, APP__SET_ITEMS_PER_PAGE_ACTIONS } from 'store/constants';

export const setItemsPerPage = createAction<number>(APP__SET_ITEMS_PER_PAGE);
export const setItemsPerPageAction = createAsyncThunk(
  APP__SET_ITEMS_PER_PAGE_ACTIONS,
  async (props: { numberOfItems: number }, thunkApi) => {
    thunkApi.dispatch(setItemsPerPage(props.numberOfItems));
  },
);
