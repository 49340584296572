import { AxiosResponse } from 'axios';
import { getApi } from 'api';
import { fromLoginResponseToToken } from 'mappers/auth-mapper';
import IToken from 'types/auth/IToken';
import ILoginRequest from 'types/auth/ILoginRequest';
import ILoginResponse from 'types/auth/ILoginResponse';

export const loginRequest = async (data: ILoginRequest): Promise<IToken> => {
  const response: AxiosResponse<ILoginResponse> = await getApi().post('Auth/login', data, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  return fromLoginResponseToToken(response.data);
};

export const ssoLoginRequest = async (data: ILoginRequest): Promise<IToken> => {
  const response: AxiosResponse<ILoginResponse> = await getApi().post('Auth/ssologin', data, {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  return fromLoginResponseToToken(response.data);
};

export const logoutRequest = async (): Promise<void> => {
  await getApi().post('/private/authentication/logout');
};

export const impersonateRequest = async (userId: number, companyId: number): Promise<IToken> => {
  const response: AxiosResponse<ILoginResponse> = await getApi().post(
    `Auth/impersonate/${userId}`,
    {},
    {
      headers: {
        companyId,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  );
  return fromLoginResponseToToken(response.data);
};

export const impersonateEmployeeRequest = async (employeeId: number, companyId: number): Promise<IToken> => {
  const response: AxiosResponse<ILoginResponse> = await getApi().post(
    `Auth/impersonateemployee/${employeeId}`,
    {},
    {
      headers: {
        companyId,
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    },
  );
  return fromLoginResponseToToken(response.data);
};

export const changeCompanyReqquest = async (companyId: number): Promise<IToken> => {
  const response: AxiosResponse<ILoginResponse> = await getApi().post(
    `Auth/changeCompany/${companyId}`,
    {},
    {
      headers: { companyId },
    },
  );
  return fromLoginResponseToToken(response.data);
};

export const companyLogintRequest = async (companyId: number): Promise<IToken> => {
  const response: AxiosResponse<ILoginResponse> = await getApi().post(`Auth/getCompanyAccess/${companyId}`);
  return fromLoginResponseToToken(response.data);
};

export const validateTokenRequest = async (token: string): Promise<boolean> => {
  const response: AxiosResponse<boolean> = await getApi().get(`Auth/verifyToken/${token}`);
  return response.data;
};
