import { Box, Modal, styled } from '@mui/material';

export const AvailabilityDatePickerContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  [theme.breakpoints.down(600)]: {
    marginBottom: '16px',
    display: 'none',
  },
}));

export const FillAvailabilityModalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down(600)]: {
    alignItems: 'flex-end',
  },
}));

export const FillAvailabilityModalStyled = styled(Modal)(({ theme }) => ({
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '550px',
  zIndex: '2',
  [theme.breakpoints.down(600)]: {
    width: '100%',
    position: 'absolute',
    transform: 'none',
    bottom: '-5px',
    left: '0px',
  },
}));

export const ModalCloseIcon = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '16px',
  right: '16px',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.down(600)]: {
    display: 'none',
  },
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  background: 'white',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  border: 'none',
  maxHeight: '650px',
  overflowX: 'auto',
  [theme.breakpoints.down(600)]: {
    position: 'absolute',
    bottom: '0px',
    maxHeight: '80vh',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
}));

export const ModalContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '10px 25px',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  height: '80%',
  gap: '20px',
  [theme.breakpoints.down(600)]: {
    padding: '20px 40px',
    height: '350px',
    gap: '40px',
  },
}));

export const ModalFooter = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: '12px 30px',
  display: 'flex',
  justifyContent: 'center',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
  [theme.breakpoints.down(600)]: {
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: '0px',
  },
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: 'white',
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)} ${theme.spacing(3)}`,
  borderTopRightRadius: '8px',
  borderTopLeftRadius: '8px',
  [theme.breakpoints.down(600)]: {
    backgroundColor: theme.palette.background.default,
  },
}));

export const ModalTitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down(600)]: {
    flexDirection: 'column',
    marginBottom: '0px',
  },
}));
