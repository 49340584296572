import { UPDropdown } from 'components/dropdown';
import { useTranslation } from 'react-i18next';
import { GENERAL__SELECT } from 'translations/constants';

const DataGridEditInputCell = (props: any): JSX.Element => {
  const { id, value, api, field, dropdownOptions, category } = props;

  const [t] = useTranslation();

  const handleChange = async (event: { target: { value: any }; clientX: number; clientY: number }) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    if (event.clientX !== 0 && event.clientY !== 0) {
      const isValid = await api.commitCellChange({ id, field });
      if (isValid) {
        api.setCellMode(id, field, 'view');
      }
    }
  };

  const handleRef = (element: {
    querySelector: (arg0: string) => { (): any; new (): any; focus: { (): void; new (): any } };
  }) => {
    if (element) {
      element.querySelector(`input[value="${value}"]`)?.focus();
    }
  };

  return (
    <UPDropdown
      value={value}
      onChange={handleChange}
      label=""
      items={dropdownOptions}
      ref={category ? null : handleRef}
      placeholder={t(GENERAL__SELECT)}
    />
  );
};

export default DataGridEditInputCell;
