import { Box, styled } from '@mui/material';

export const AvailabilitiesCalendarDayContainer = styled(Box)<{ sixRowsCalendar?: boolean }>(
  ({ theme, sixRowsCalendar }) => ({
    width: '14.28%',
    display: 'flex',
    flexDirection: 'column',
    padding: sixRowsCalendar ? '0px 40px 5px' : '0px 30px 5px',
    [theme.breakpoints.down(1600)]: {
      padding: sixRowsCalendar ? '0px 33px 5px' : '0px 25px 5px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 5px 5px',
    },
    [theme.breakpoints.up(2200)]: {
      padding: sixRowsCalendar ? '0px 50px 5px' : '0px 40px 5px',
    },
  }),
);

export const AvailabilitiesCalendarDayGraph = styled(Box)<{
  disabled?: boolean;
  fullyAvailable?: boolean;
  fullyUnavailable?: boolean;
}>(({ theme, disabled, fullyAvailable, fullyUnavailable }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '5px',
  border: disabled ? `2px solid ${theme.palette.secondary.light}` : 'none',
  cursor: disabled ? 'not-allowed' : 'pointer',
  backgroundColor: (() => {
    if (!disabled) {
      if (fullyAvailable) {
        return theme.palette.primary.main;
      }
      if (fullyUnavailable) {
        return theme.palette.secondary.main;
      }
    }
    return 'transparent';
  })(),
  [theme.breakpoints.down('sm')]: {
    borderRadius: '2px',
  },
}));

export const AvailabilitiesCalendarDayGraphContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '90%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '5px',
  [theme.breakpoints.down('sm')]: {
    height: '80%',
    padding: '10px 0px',
  },
}));

export const AvailabilitiesCalendarDayNumber = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '10%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  padding: '5px 0px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    height: '20%',
  },
}));

export const AvailabilitiesCalendarDaySegment = styled(Box)<{ availabile: boolean }>(({ theme, availabile }) => ({
  width: '100%',
  height: '33%',
  backgroundColor: availabile ? theme.palette.primary.main : theme.palette.secondary.main,
  borderRadius: '5px',
  marginBottom: '5px',
  '&:last-child': {
    marginBottom: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: '2px',
    marginBottom: '3px',
  },
}));
