import { Alert, Box } from '@mui/material';
import UPButton from 'components/button';
import UPInput from 'components/input';
import { toNumber } from 'lodash';
import { CloseAlertIcon, SectionContainer } from 'modules/companies/styles';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  checkPasswordAsync,
  updatePasswordAsync,
  updatePasswordErrorAction,
  updatePasswordSuccessAction,
} from 'store/actions/profile-actions';
import {
  checkPasswordSelector,
  profileSelector,
  updatePasswordErrorSelector,
  updatePasswordSuccessSelector,
} from 'store/selectors/profile-selectors';
import {
  PROFILE__CURRENT_PASSWORD,
  PROFILE__NEW_PASSWORD,
  PROFILE__CONFIRM_NEW_PASSWORD,
  GENERAL__SAVE_BUTTON,
  GENERAL__CANCEL_BUTTON,
  PROFILE__UPDATE_PASSWORD_SUCCESSFUL,
  GENERAL__ERROR_MESSAGE,
  LOGIN__PASSWORD_MIN_LENGTH_ERROR,
} from 'translations/constants';
import IPasswordChange from 'types/profile/IPasswordChange';
import { getInputErrorText, validatePassword } from 'utils/helpers';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import { LeftContainer } from './styles';

const PasswordChangeInitialState: IPasswordChange = {
  currentPassword: '',
  newPassword: '',
  confirmedNewPassword: '',
};

const ChangePassword = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
    getValues,
    setError,
    clearErrors,
  } = useForm<IPasswordChange>({
    defaultValues: PasswordChangeInitialState,
    mode: 'onChange',
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

  const user = useAppSelector(profileSelector);
  const updatePasswordSuccess = useAppSelector(updatePasswordSuccessSelector);
  const updatePasswordError = useAppSelector(updatePasswordErrorSelector);
  const checkPasswordResult = useAppSelector(checkPasswordSelector);

  const onChangePassword: SubmitHandler<IPasswordChange> = useCallback(() => {
    const values = getValues();
    if (isDirty) {
      if (checkPasswordResult) {
        dispatch(updatePasswordAsync({ userId: toNumber(user.id), password: values.confirmedNewPassword })).then(e =>
          reset(PasswordChangeInitialState),
        );
      } else {
        errors.currentPassword = { type: 'pattern' };
      }
    }
  }, [getValues, isDirty, dispatch, user, checkPasswordResult, errors, reset]);

  const checkCurrentPassword = useCallback(() => {
    const values = getValues();
    dispatch(checkPasswordAsync({ userId: toNumber(user.id), password: values.currentPassword }));
  }, [getValues, dispatch, user]);

  useEffect(() => {
    if (checkPasswordResult === true) {
      clearErrors('currentPassword');
    }
    if (checkPasswordResult === false) {
      errors.currentPassword = { type: 'incorrectPassword' };
    }
  }, [checkPasswordResult, errors, clearErrors]);

  useEffect(() => {
    dispatch(updatePasswordSuccessAction(false));
    dispatch(updatePasswordErrorAction(false));
  }, [dispatch]);

  return (
    <SectionContainer>
      <LeftContainer>
        <Box mt={3} sx={{ width: '100%' }}>
          <Controller
            name="currentPassword"
            control={control}
            rules={{ required: true, maxLength: 100 }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(PROFILE__CURRENT_PASSWORD)}
                placeholder={t(PROFILE__CURRENT_PASSWORD)}
                error={!!errors.currentPassword || checkPasswordResult === false}
                required
                helperText={
                  // getInputErrorText(errors, 'currentPassword', undefined, 100)
                  getInputErrorText(errors, 'currentPassword', undefined, 100, false, 8, false, true) ||
                  (checkPasswordResult === false && 'This password is incorrect.')
                }
                onChange={e => {
                  field.onChange(e);
                  setIsButtonDisabled(false);
                }}
                type="password"
                onBlur={checkCurrentPassword}
              />
            )}
          />
        </Box>

        <Box mt={3} sx={{ width: '100%' }}>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: true,
              minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
              maxLength: 100,
              validate: {
                policy: value => validatePassword(value),
                // match: value => value === getValues('confirmedNewPassword'),
              },
            }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(PROFILE__NEW_PASSWORD)}
                placeholder={t(PROFILE__NEW_PASSWORD)}
                error={!!errors.newPassword}
                required
                helperText={getInputErrorText(errors, 'newPassword', undefined, 100, false, 8, false, true)}
                onChange={e => {
                  field.onChange(e);
                  setIsButtonDisabled(false);
                  const { confirmedNewPassword } = getValues();
                  if (confirmedNewPassword !== e.target.value) {
                    setError('confirmedNewPassword', { type: 'match' });
                  } else {
                    clearErrors('confirmedNewPassword');
                  }

                  if (!validatePassword(e.target.value)) {
                    errors.newPassword = { type: 'policy' };
                    setError('newPassword', { type: 'policy' });
                  } else {
                    errors.newPassword = undefined;
                  }
                }}
                type="password"
              />
            )}
          />
        </Box>

        <Box mt={3} mb={5} sx={{ width: '100%' }}>
          <Controller
            name="confirmedNewPassword"
            control={control}
            rules={{
              required: true,
              minLength: { value: 8, message: t(LOGIN__PASSWORD_MIN_LENGTH_ERROR) },
              maxLength: 100,
              validate: {
                policy: value => validatePassword(value),
                match: value => value === getValues('newPassword'),
              },
            }}
            render={({ field }) => (
              <UPInput
                value={field.value}
                label={t(PROFILE__CONFIRM_NEW_PASSWORD)}
                placeholder={t(PROFILE__CONFIRM_NEW_PASSWORD)}
                error={!!errors.confirmedNewPassword}
                required
                helperText={getInputErrorText(errors, 'confirmedNewPassword', undefined, 100, false, 8, false, true)}
                onChange={e => {
                  field.onChange(e);
                  setIsButtonDisabled(false);
                  const { newPassword } = getValues();
                  if (newPassword !== e.target.value) {
                    setError('confirmedNewPassword', { type: 'match' });
                  } else if (!validatePassword(e.target.value)) {
                    setError('confirmedNewPassword', { type: 'policy' });
                  } else {
                    clearErrors('confirmedNewPassword');
                  }
                }}
                type="password"
              />
            )}
          />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <UPButton
            text={t(GENERAL__SAVE_BUTTON)}
            disabled={isButtonDisabled}
            onClick={() => {
              setIsButtonDisabled(true);
              handleSubmit(onChangePassword)();
            }}
          />
          <Box sx={{ marginLeft: '16px' }}>
            <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={() => reset(PasswordChangeInitialState)} outlined />
          </Box>
        </Box>
        {updatePasswordSuccess && (
          <Alert
            severity="success"
            icon={false}
            variant="filled"
            sx={{ width: '100%', marginTop: '16px' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updatePasswordSuccessAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(PROFILE__UPDATE_PASSWORD_SUCCESSFUL)}
          </Alert>
        )}
        {updatePasswordError && (
          <Alert
            severity="error"
            icon={false}
            variant="filled"
            sx={{ width: '100%' }}
            action={
              <CloseAlertIcon
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dispatch(updatePasswordErrorAction(false));
                }}
              >
                <CloseIcon fill="white" />
              </CloseAlertIcon>
            }
          >
            {t(GENERAL__ERROR_MESSAGE)}
          </Alert>
        )}
      </LeftContainer>
    </SectionContainer>
  );
};

export default ChangePassword;
