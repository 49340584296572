import { UPDropdownItem } from 'components/dropdown';

const HOUR_OPTIONS: UPDropdownItem[] = [
  // dropdown options with hours at 15 minutes intervals
  { value: '1', label: '00:00' },
  { value: '2', label: '00:15' },
  { value: '3', label: '00:30' },
  { value: '4', label: '00:45' },
  { value: '5', label: '01:00' },
  { value: '6', label: '01:15' },
  { value: '7', label: '01:30' },
  { value: '8', label: '01:45' },
  { value: '9', label: '02:00' },
  { value: '10', label: '02:15' },
  { value: '11', label: '02:30' },
  { value: '12', label: '02:45' },
  { value: '13', label: '03:00' },
  { value: '14', label: '03:15' },
  { value: '15', label: '03:30' },
  { value: '16', label: '03:45' },
  { value: '17', label: '04:00' },
  { value: '18', label: '04:15' },
  { value: '19', label: '04:30' },
  { value: '20', label: '04:45' },
  { value: '21', label: '05:00' },
  { value: '22', label: '05:15' },
  { value: '23', label: '05:30' },
  { value: '24', label: '05:45' },
  { value: '25', label: '06:00' },
  { value: '26', label: '06:15' },
  { value: '27', label: '06:30' },
  { value: '28', label: '06:45' },
  { value: '29', label: '07:00' },
  { value: '30', label: '07:15' },
  { value: '31', label: '07:30' },
  { value: '32', label: '07:45' },
  { value: '33', label: '08:00' },
  { value: '34', label: '08:15' },
  { value: '35', label: '08:30' },
  { value: '36', label: '08:45' },
  { value: '37', label: '09:00' },
  { value: '38', label: '09:15' },
  { value: '39', label: '09:30' },
  { value: '40', label: '09:45' },
  { value: '41', label: '10:00' },
  { value: '42', label: '10:15' },
  { value: '43', label: '10:30' },
  { value: '44', label: '10:45' },
  { value: '45', label: '11:00' },
  { value: '46', label: '11:15' },
  { value: '47', label: '11:30' },
  { value: '48', label: '11:45' },
  { value: '49', label: '12:00' },
  { value: '50', label: '12:15' },
  { value: '51', label: '12:30' },
  { value: '52', label: '12:45' },
  { value: '53', label: '13:00' },
  { value: '54', label: '13:15' },
  { value: '55', label: '13:30' },
  { value: '56', label: '13:45' },
  { value: '57', label: '14:00' },
  { value: '58', label: '14:15' },
  { value: '59', label: '14:30' },
  { value: '60', label: '14:45' },
  { value: '61', label: '15:00' },
  { value: '62', label: '15:15' },
  { value: '63', label: '15:30' },
  { value: '64', label: '15:45' },
  { value: '65', label: '16:00' },
  { value: '66', label: '16:15' },
  { value: '67', label: '16:30' },
  { value: '68', label: '16:45' },
  { value: '69', label: '17:00' },
  { value: '70', label: '17:15' },
  { value: '71', label: '17:30' },
  { value: '72', label: '17:45' },
  { value: '73', label: '18:00' },
  { value: '74', label: '18:15' },
  { value: '75', label: '18:30' },
  { value: '76', label: '18:45' },
  { value: '77', label: '19:00' },
  { value: '78', label: '19:15' },
  { value: '79', label: '19:30' },
  { value: '80', label: '19:45' },
  { value: '81', label: '20:00' },
  { value: '82', label: '20:15' },
  { value: '83', label: '20:30' },
  { value: '84', label: '20:45' },
  { value: '85', label: '21:00' },
  { value: '86', label: '21:15' },
  { value: '87', label: '21:30' },
  { value: '88', label: '21:45' },
  { value: '89', label: '22:00' },
  { value: '90', label: '22:15' },
  { value: '91', label: '22:30' },
  { value: '92', label: '22:45' },
  { value: '93', label: '23:00' },
  { value: '94', label: '23:15' },
  { value: '95', label: '23:30' },
  { value: '96', label: '23:45' },
];

export default HOUR_OPTIONS;
