import { Tabs } from '@mui/material';
import { Box } from '@mui/system';
import UPSwitch from 'components/switch';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { EmptySection, SwitchContainer, SwitchLabel, TabStyled } from './styles';
import TabPanel from './TabPanel';
import { UPTabProps } from './types';
// import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
/* eslint-disable react/jsx-props-no-spreading */
const LinkTab = (props: any) => {
  return (
    <TabStyled
      component={Link}
      to={props.pathname} // eslint-disable-line
      {...props} // eslint-disable-line
    />
  );
};

const UPTab = (props: UPTabProps): JSX.Element => {
  const { sections, children, withSwitchTabs, disabled, onChangeTab } = props;
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [switchValues, setSwitchValues] = useState<boolean[]>([true, true, true]);

  useEffect(() => {
    if (typeof sections[0] !== 'string') {
      const pathnameSplitted = location.pathname.split('/');
      let commonElementIndex = sections.findIndex(
        element => typeof element !== 'string' && pathnameSplitted.includes(element.route as string),
      );
      if (commonElementIndex === -1) commonElementIndex = 0;
      setValue(commonElementIndex);
    }
  }, [location, sections]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onChangeTab !== null) {
      onChangeTab(newValue);
    }
  };

  const onChangeSwitch = (event: SyntheticEvent, index: number) => {
    setSwitchValues(prevState => prevState.map((item, idx) => (idx === index ? !item : item)));
  };

  if (!withSwitchTabs) {
    return (
      <Box sx={{ width: '100%' }}>
        <Tabs value={value} onChange={handleChange}>
          {sections.map((section, index) =>
            typeof section === 'string' ? (
              <TabStyled label={section} {...a11yProps(index)} key={section} />
            ) : (
              section.isVisible !== false && (
                <LinkTab
                  label={section.title}
                  {...a11yProps(index)}
                  key={section.title}
                  pathname={section.route}
                  disabled={disabled}
                />
              )
            ),
          )}
        </Tabs>
        {sections.map((section, index) => (
          <TabPanel value={value} index={index} key={typeof section === 'string' ? section : section.title}>
            {children[index]}
          </TabPanel>
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        {sections.map((section, index) => (
          <TabStyled
            label={
              <SwitchContainer>
                <UPSwitch checked={switchValues[index]} onChange={event => onChangeSwitch(event, index)} />
                <SwitchLabel>{section}</SwitchLabel>
              </SwitchContainer>
            }
            {...a11yProps(index)}
            key={typeof section === 'string' ? section : section.title}
          />
        ))}
      </Tabs>
      {sections.map((section, index) => (
        <TabPanel value={value} index={index} key={typeof section === 'string' ? section : section.title}>
          {switchValues[index] ? children[index] : <EmptySection>{section} is not enabled.</EmptySection>}
        </TabPanel>
      ))}
    </Box>
  );
};

UPTab.defaultProps = {
  withSwitchTabs: false,
  disabled: false,
  onChangeTab: null,
};

export default UPTab;
