import { Box } from '@mui/material';
import { TabStyled } from 'components/tab/styles';
import { toNumber } from 'lodash';
import { TabsContainerContractsListView } from 'modules/employees/add-edit-employee-modal/styles';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store';
import {
  deleteContractsAction,
  downloadContractAction,
  getContractsInListViewAction,
} from 'store/actions/contract-actions';
import { costCentersDropdownItemsSelector } from 'store/selectors/company-cost-centers-selectors';
import { functionsDropdownItemsSelector } from 'store/selectors/company-functions-selectors';
import { ContractListViewTypes } from 'constants/ContractListViewTypes';
import { CONTRACTS__ARCHIVED, CONTRACTS__FUTURE_CONTRACTS, CONTRACTS__TO_BE_CONFIRMED } from 'translations/constants';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/DownloadGreen.svg';
import { ContractsListViewProps } from '../types';
import ArchivedContractsView from './ArchivedContractsView';
import FutureContractsView from './FutureContractsView';
import ToBeConfirmedContractsView from './ToBeConfirmedContractsView';

const contractsTypes = ['future', 'toconfirm', 'archived'];

const ContractsListView = (props: ContractsListViewProps): JSX.Element => {
  const { companyId, currentWeek, currentYear, filters, setViewType } = props;
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const costCentersList = useAppSelector(costCentersDropdownItemsSelector);
  const functionsList = useAppSelector(functionsDropdownItemsSelector);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [selectedContractsIds, setSelectedContractsIds] = useState<number[]>([]);

  const onChangeCurrentTab = useCallback((event: SyntheticEvent, value: number) => {
    setCurrentTab(value);
  }, []);

  useEffect(() => {
    setSelectedContractsIds([]);
  }, [currentTab]);

  const makeDeleteRequest = (ids: number[], listViewType: ContractListViewTypes) => {
    dispatch(
      deleteContractsAction({
        companyId: toNumber(companyId),
        contractsIds: ids,
        year: currentYear,
        weekNumber: currentWeek,
      }),
    ).then(e => {
      filters.listViewType = listViewType;
      dispatch(
        getContractsInListViewAction({
          companyId: toNumber(companyId),
          filter: filters,
        }),
      );
    });
  };

  const onDownloadContracts = (currentTabNumber: number) => {
    dispatch(
      downloadContractAction({
        companyId: toNumber(companyId),
        ids: selectedContractsIds,
        type: contractsTypes[currentTabNumber],
      }),
    );
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <TabsContainerContractsListView value={currentTab} onChange={onChangeCurrentTab}>
          <TabStyled
            label={t(CONTRACTS__FUTURE_CONTRACTS)}
            onClick={() => {
              setCurrentTab(0);
              setViewType(ContractListViewTypes.Future);
            }}
          />
          <TabStyled
            label={t(CONTRACTS__TO_BE_CONFIRMED)}
            onClick={() => {
              setCurrentTab(1);
              setViewType(ContractListViewTypes.ToBeConfirmed);
            }}
          />
          <TabStyled
            label={t(CONTRACTS__ARCHIVED)}
            onClick={() => {
              setCurrentTab(2);
              setViewType(ContractListViewTypes.Archived);
            }}
          />
        </TabsContainerContractsListView>

        <DownloadIcon className="documentsIcon" onClick={() => onDownloadContracts(currentTab)} />
      </Box>
      {currentTab === 0 && (
        <FutureContractsView
          makeDeleteRequest={makeDeleteRequest}
          costCentersList={costCentersList}
          functionsList={functionsList}
          currentWeek={currentWeek}
          currentYear={currentYear}
          setSelectedContractsId={setSelectedContractsIds}
          filters={filters}
          companyId={toNumber(companyId)}
        />
      )}
      {currentTab === 1 && (
        <ToBeConfirmedContractsView
          makeDeleteRequest={makeDeleteRequest}
          setSelectedContractsId={setSelectedContractsIds}
          costCentersList={costCentersList}
          functionsList={functionsList}
          currentWeek={currentWeek}
          currentYear={currentYear}
          filters={filters}
          companyId={toNumber(companyId)}
        />
      )}
      {currentTab === 2 && (
        <ArchivedContractsView
          makeDeleteRequest={makeDeleteRequest}
          costCentersList={costCentersList}
          functionsList={functionsList}
          setSelectedContractsId={setSelectedContractsIds}
          filters={filters}
          companyId={toNumber(companyId)}
        />
      )}
    </Box>
  );
};

export default ContractsListView;
