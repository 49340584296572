import { TablePagination } from '@mui/material';
import { gridPageSelector, useGridApiContext, useGridSelector, gridRowCountSelector } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'store';
import { setItemsPerPageAction } from 'store/actions/app-action';
import { DROPDOWN_SEARCH__NO_OPTIONS } from 'translations/constants';

const CustomPagination = (): JSX.Element => {
  const [t] = useTranslation();
  const dispatch = useAppDispatch();
  const apiRef = useGridApiContext();

  const page = useGridSelector(apiRef, gridPageSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(setItemsPerPageAction({ numberOfItems: parseInt(event.target.value, 10) }));
    setRowsPerPage(parseInt(event.target.value, 10));
    apiRef.current.setPage(0);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    apiRef.current.setPage(newPage);
  };

  return (
    <TablePagination
      count={rowCount}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[25, 50, 100]}
      labelRowsPerPage={t(DROPDOWN_SEARCH__NO_OPTIONS)}
      component="div"
    />
  );
};

export default CustomPagination;
