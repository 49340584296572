import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      light: '#F1F8F4', // Green 2 - 10
      main: '#008C15', // Green 1
      dark: '#E5F3E7', // Green 1
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#CFD0D0', // Gray 2 - 01
      main: '#9EA2A2', // Gray light
      dark: '#5F6161', // Gray 2 - 05
      contrastText: '#303C42', // Gray dark
    },
    info: {
      light: '#FAFAFA', // Gray 2 - 04
      main: '#F5F6F6', // Gray 2 - 03
      dark: '#E7E8E8', // Gray 2 - 02
      contrastText: '#5F6161', // Gray 2 - 05
    },
    error: { main: '#EC4A67' },
    background: {
      default: '#FAFAFA',
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontSize: 14,
  },
  breakpoints: {
    values: {
      xs: 300,
      sm: 600,
      md: 1200,
      lg: 1500,
      xl: 1920,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "'Montserrat', sans-serif";
          font-style: normal;
          font-weight: normal;
          src: local('Montserrat'), url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap") format('truetype');
        }
      `,
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: '#FFFFFF ',
            color: '#008C15',
            fontWeight: 'bold',
            '&:hover': {
              background: '#FFFFFF',
              '&:hover': { background: '#F5F6F6' },
              '&.Mui-focusVisible': { background: '#FFFFFF' },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#FFFFFF ',
            color: '#008C15',
            fontWeight: 'bold',
            '&.Mui-focused': {
              backgroundColor: '#FFFFFF ',
            },
            '&:hover': {
              background: '#FFFFFF',
              '&:hover': { background: '#F5F6F6' },
              '&.Mui-focusVisible': { background: '#FFFFFF' },
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: '#008C15',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          ' .MuiMenuItem-root': {
            fontSize: '14px',
            color: '#5F6161',
          },
          '&.week-picker': {
            ' .MuiCalendarPicker-root': {
              '& .MuiTypography-root': {
                margin: '0px',
              },
              '.PrivatePickersFadeTransitionGroup-root > div > div:nth-of-type(1)': {
                marginLeft: '60px',
              },
              ' .MuiDayPicker-header': {
                paddingLeft: '60px',
              },
            },
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: '#008C15',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '49px',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-filledSuccess': {
            backgroundColor: '#008C15',
          },
          '&.MuiAlert-filledError': {
            backgroundColor: '#EC4A67',
          },
        },
      },
    },
  },
});

export default theme;
