import { createReducer } from '@reduxjs/toolkit';
import { setMenuStatusAction } from 'store/actions/menu-actions';
import IMenuState from 'types/common/IMenuState';

const initialState: IMenuState = {
  activeItemName: 'Dashboard',
};

const menuReducer = createReducer(initialState, builder =>
  builder.addCase(setMenuStatusAction, (state, action) => ({ ...state, activeItemName: action.payload })),
);

export default menuReducer;
