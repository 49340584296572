import UPDatepicker from 'components/datepicker';
import { useMediaQuery, useTheme } from '@mui/material';
import UPDropdownWithSearch from 'components/dropdown-with-search';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toNumber } from 'lodash';
import {
  DOCUMENTS__DATE,
  DOCUMENTS__SELECT_CATEGORY,
  DOCUMENTS__CATEGORY,
  DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR,
  DOCUMENTS__PERIOD_END,
  DOCUMENTS__PERIOD_START,
  DOCUMENTS__INVALID_PERIOD,
  EMPLOYEE__INVALID_ACADEMIC_PERIOD,
  MENU__EMPLOYEES,
  GENERAL__SELECT,
} from 'translations/constants';
import { AddNewRowProps } from './types';
import { isPeriodValid, needsEmployeedropdown, needsPeriod } from './helpers';
import {
  ColumnContainer,
  DocumentBox,
  DocumentBoxContainer,
  DocumentBoxForEmployee,
  DocumentError,
  RowContainer,
} from './styles';

const AddNewDocumentRow = (props: AddNewRowProps) => {
  const {
    name,
    documentTypes,
    index,
    date,
    onChangeValue,
    error,
    type,
    periodStart,
    periodEnd,
    isEmployee,
    companyEmployees,
  } = props;

  const [t] = useTranslation();

  const { control, watch } = useForm<any>({
    defaultValues: {
      documentDate: date,
      periodStart: null,
      periodEnd: null,
    },
  });

  const documentDate = watch('documentDate');
  const periodStartDate = watch('periodStart');
  const periodEndDate = watch('periodEnd');
  const [periodError, setPeriodError] = useState<boolean>(false);
  const [employeeId, setEmployeeId] = useState<number>();

  useEffect(() => {
    if (documentDate && documentDate !== date) {
      onChangeValue(index, documentDate, 'date');
    }

    if (type === 6 && periodStart !== periodStartDate) {
      onChangeValue(index, periodStartDate, 'periodStart');
    }

    if (type === 6 && periodEnd !== periodEndDate) {
      onChangeValue(index, periodEndDate, 'periodEnd');
    }
  }, [documentDate, index, onChangeValue, date, type, periodEndDate, periodStartDate, periodStart, periodEnd]);

  const getDocumentName = (n: string): string => {
    if (n.length > 39) {
      return `${n.substring(0, 39)}...`;
    }

    return n;
  };

  const showEmployeeDropdown = !isEmployee && needsEmployeedropdown(type);

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down(768));

  return (
    <DocumentBoxContainer>
      {isSmScreen && (
        <ColumnContainer>
          <DocumentBox>
            <div style={{ marginLeft: '10px' }}>{getDocumentName(name)}</div>
          </DocumentBox>
        </ColumnContainer>
      )}
      <ColumnContainer>
        <RowContainer sx={{ marginBottom: '5px' }}>
          <DocumentBox width={2 / 4}>
            <Controller
              name="documentDate"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <UPDatepicker
                  value={field.value}
                  onChange={field.onChange}
                  label={t(DOCUMENTS__DATE)}
                  required
                  error={error.dateError}
                  className="datepicker--white-background"
                />
              )}
            />
          </DocumentBox>
          <DocumentBox width={2 / 4}>
            <UPDropdownWithSearch
              value={type}
              onChange={selectedItem => {
                onChangeValue(index, selectedItem, 'type');
              }}
              placeholder={t(DOCUMENTS__SELECT_CATEGORY)}
              items={documentTypes}
              label={`${t(DOCUMENTS__CATEGORY)} *`}
              error={error.typeError}
            />
          </DocumentBox>
        </RowContainer>
        {showEmployeeDropdown && (
          <RowContainer sx={{ marginBottom: '5px' }}>
            <DocumentBoxForEmployee>
              <UPDropdownWithSearch
                value={employeeId}
                onChange={selectedItem => {
                  onChangeValue(index, toNumber(selectedItem), 'employeeId');
                }}
                placeholder={t(GENERAL__SELECT)}
                items={companyEmployees}
                label={t(MENU__EMPLOYEES)}
                disableClearable={false}
              />
            </DocumentBoxForEmployee>
          </RowContainer>
        )}
        {needsPeriod(type) && (
          <RowContainer sx={{ marginTop: '1px' }}>
            <DocumentBox width={2 / 4}>
              <Controller
                name="periodStart"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDatepicker
                    required
                    disableInput
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                      onChangeValue(index, value, 'periodStart');
                      setPeriodError(!isPeriodValid(value, periodEndDate, type));
                    }}
                    isAcademicPicker
                    label={t(DOCUMENTS__PERIOD_START)}
                    className="datepicker--white-background"
                  />
                )}
              />
            </DocumentBox>
            <DocumentBox sx={{ marginBottom: '5px' }} width={2 / 4}>
              <Controller
                name="periodEnd"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <UPDatepicker
                    required
                    disableInput
                    value={field.value}
                    onChange={value => {
                      field.onChange(value);
                      onChangeValue(index, value, 'periodEnd');
                      setPeriodError(!isPeriodValid(periodStartDate, value, type));
                    }}
                    isAcademicPicker
                    label={t(DOCUMENTS__PERIOD_END)}
                    className="datepicker--white-background"
                  />
                )}
              />
            </DocumentBox>
          </RowContainer>
        )}
        {periodError && type !== 6 && <DocumentError>{t(DOCUMENTS__INVALID_PERIOD)}</DocumentError>}
        {error.academicPeriodError && <DocumentError>{t(EMPLOYEE__INVALID_ACADEMIC_PERIOD)}</DocumentError>}
        {(error.typeError || error.dateError || error.periodError) && (
          <DocumentError>{t(DOCUMENTS__ALL_FIELDS_ARE_REQUIRED_ERROR)}</DocumentError>
        )}
      </ColumnContainer>
      {!isSmScreen && (
        <ColumnContainer>
          <DocumentBox>
            <div style={{ marginLeft: '10px', marginTop: needsPeriod(type) && showEmployeeDropdown ? '22%' : '6%' }}>
              {getDocumentName(name)}
            </div>
          </DocumentBox>
        </ColumnContainer>
      )}
    </DocumentBoxContainer>
  );
};

export default AddNewDocumentRow;
