import { getApi } from 'api';
import { AxiosResponse } from 'axios';
import { UPDropdownItem } from 'components/dropdown';
import { IParitairComite } from 'types/paritair-comite';

export const getParitairComiteSelectItemsRequest = async (): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(`ParitairComite`);
  return response.data;
};

export const getParitairComiteSelectItemsByCompanyRequest = async (companyId?: number): Promise<UPDropdownItem[]> => {
  const response: AxiosResponse<UPDropdownItem[]> = await getApi().get(
    `ParitairComite/GetAllParitairComitesByCompany/${companyId}`,
  );
  return response.data;
};

export const getAllParitairComiteRequest = async (): Promise<IParitairComite[]> => {
  const response: AxiosResponse<IParitairComite[]> = await getApi().get('ParitairComite/GetAllParitairComites');
  return response.data;
};

export const getParitairComiteByIdRequest = async (paritairComiteId: number): Promise<IParitairComite> => {
  const response: AxiosResponse<IParitairComite> = await getApi().get(`ParitairComite/${paritairComiteId}`);
  return response.data;
};

export const saveParitairComiteRequest = async (paritairComite: IParitairComite): Promise<number> => {
  const response: AxiosResponse<number> = await getApi().post('ParitairComite/New', paritairComite);
  return response.data;
};

export const updateParitairComiteRequest = async (paritairComite: IParitairComite): Promise<void> => {
  await getApi().put('ParitairComite', paritairComite);
};
