import { useTranslation } from 'react-i18next';
import {
  AuthFooterContainer,
  AuthFooterDivider,
  AuthFooterLink,
  AuthFooterLinksContainer,
  AuthFooterText,
} from './styles';
import { FOOTER__TERMS_AND_CONDITIONS, FOOTER__PRIVACY_POLICY } from '../../translations/constants';

const AuthFooter = (): JSX.Element => {
  const [t] = useTranslation();
  return (
    <AuthFooterContainer>
      <AuthFooterDivider />
      <AuthFooterLinksContainer>
        <AuthFooterLink
          href="https://www.update-pro.be/static/base/pdf/Arbeidsreglement%20voor%20uitzendkrachten%202021.pdf"
          target="_blank"
        >
          <AuthFooterText>{t(FOOTER__TERMS_AND_CONDITIONS)}</AuthFooterText>
        </AuthFooterLink>
        <AuthFooterLink href="https://www.update-pro.be/privacyverklaring/" target="_blank">
          <AuthFooterText centerText>{t(FOOTER__PRIVACY_POLICY)}</AuthFooterText>
        </AuthFooterLink>
        <AuthFooterLink href="https://www.update-pro.be" target="_blank">
          <AuthFooterText>
            <strong>www.update-pro.be</strong>
          </AuthFooterText>
        </AuthFooterLink>
      </AuthFooterLinksContainer>
    </AuthFooterContainer>
  );
};

export default AuthFooter;
