import { Box, Checkbox, Collapse, IconButton, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { IApiError } from 'types/errors';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ApiErrorBusinessObjectsLabels } from 'types/errors/ApiErrorBusinessObjects';
import { StyledTableCell, StyledTableRow } from './styles';

const ApiErrorRow = (props: {
  row: IApiError;
  isChecked: boolean;
  handleClick: (event: any, id: number) => void;
}): JSX.Element => {
  const { row, isChecked, handleClick } = props;
  const [open, setOpen] = useState<boolean>(false);

  const formatDate = (): string => {
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const withOffSet = new Date(row.errorDate).getTime() - tzoffset;
    const d = new Date(withOffSet).toISOString().split('T');
    // const d = new Date(row.errorDate).toISOString().split('T');
    return `${d[0]} ${d[1].split('.')[0]}`;
  };

  return (
    <>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }} aria-checked={isChecked}>
        <StyledTableCell padding="checkbox" align="left">
          {!row.archived && (
            <Checkbox
              color="primary"
              checked={isChecked}
              onChange={event => handleClick(event, row.id)}
              inputProps={{
                'aria-labelledby': `error-${row.id}`,
              }}
            />
          )}
        </StyledTableCell>
        <StyledTableCell width="5%" align="left">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell width="10%" align="center">
          {formatDate()}
        </StyledTableCell>
        <StyledTableCell align="left">{ApiErrorBusinessObjectsLabels[row.identifier.businessObject]}</StyledTableCell>
        <StyledTableCell align="left">{row.identifier.title}</StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div">
                <strong>{row.identifier.errorMessage}</strong>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TableRow>
                    <strong>Request Details:</strong>
                  </TableRow>
                  <TableRow>{row.requestData}</TableRow>
                  <TableRow>
                    <strong>Response Details:</strong>
                  </TableRow>
                  <TableRow>{row.message}</TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ApiErrorRow;
