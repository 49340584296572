import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getReportData } from 'api/requests/reporting-requests';
import {
  REPORTING__GET_DATA,
  REPORTING__GET_DATA_ERROR,
  REPORTING__GET_DATA_PAYLOAD,
  REPORTING__GENERAL_LOADING,
  REPORTING__CLEAR_REPORTS,
} from 'store/constants';
import ReportingFilter from 'types/reporting/ReportingFilter';
import ReportingResult from 'types/reporting/ReportingResult';

export const setReportsRequestsLoadingAction = createAction<boolean>(REPORTING__GENERAL_LOADING);

export const getReportsErrorAction = createAction<boolean>(REPORTING__GET_DATA_ERROR);
export const getReportsPayloadAction = createAction<ReportingResult>(REPORTING__GET_DATA_PAYLOAD);
export const clearReportsAction = createAction<void>(REPORTING__CLEAR_REPORTS);

export const getReportsAction = createAsyncThunk(REPORTING__GET_DATA, async (filters: ReportingFilter, thunkApi) => {
  thunkApi.dispatch(setReportsRequestsLoadingAction(true));
  thunkApi.dispatch(getReportsErrorAction(false));
  try {
    const reports = await getReportData(filters);
    thunkApi.dispatch(getReportsPayloadAction(reports));
  } catch (error) {
    thunkApi.dispatch(getReportsErrorAction(true));
  }
  thunkApi.dispatch(setReportsRequestsLoadingAction(false));
});
