import { Alert, Backdrop, Box, Tabs } from '@mui/material';
import UPCheckbox from 'components/app-container/checkbox-with-label';
import UPButton from 'components/button';
import { UPDropdownItem } from 'components/dropdown';
import { TabStyled } from 'components/tab/styles';
import { EmployeeStatus } from 'constants/EmployeeStatus';
import { toNumber } from 'lodash';
import { formatDateToString, generateDocumentsToSend, isDateValid } from 'modules/documents/helpers';
import { Document } from 'modules/documents/types';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import { useAppDispatch, useAppSelector } from 'store';
import { getDocumentsAction, saveDocumentsAction, updateStudentDeclaraionAction } from 'store/actions/documents-action';
import {
  checkEmployeeInvitationTokenValidity,
  clearEmployeeAction,
  getEmployeeByIdAction,
  saveEmployeeAction,
  updateEmployeeAction,
} from 'store/actions/employee-actions';
import { clearBicAction } from 'store/actions/financial-actions';
import { employeeDocumentsSelector } from 'store/selectors/documents-selectors';
import {
  employeeSelector,
  employeesErrorSelector,
  onboardingDetailsSelector,
  onboardingSelector,
  setInviteTokenValiditySelector,
} from 'store/selectors/employee-selector';
import { bicStateSelector } from 'store/selectors/financial-selectors';
import { profileSelector } from 'store/selectors/profile-selectors';
import theme from 'theme';
import {
  CONTRACTS__DEFAULT_ERROR,
  EMPLOYEES__ACCOUNT,
  EMPLOYEES__ADDED_SUCCESSFULLY,
  EMPLOYEES__ADD_NEW_EMPLOYEE,
  EMPLOYEES__CONTACT_INFO,
  EMPLOYEES__EDIT_EMPLOYEE,
  EMPLOYEES__EMAIL_ERROR,
  EMPLOYEES__IBAN_ERROR,
  EMPLOYEES__INACTIVE,
  EMPLOYEES__INSS_ERROR,
  EMPLOYEES__INVATION_TOKEN_INVALID,
  EMPLOYEES__PERMANENT_CHANGE_VALIDATION,
  EMPLOYEES__PERSONAL_INFO,
  EMPLOYEES__VIEW_EMPLOYEE,
  EMPLOYEES__WORKING_INFO,
  GENERAL__CANCEL_BUTTON,
  GENERAL__GO,
  GENERAL__SAVE_BUTTON,
} from 'translations/constants';
import Lang from 'types/Lang';
import { ICompanyAddress } from 'types/company';
import { IAddEditEmployee, IEmployeeDependants } from 'types/employee';
import MessageModal from 'components/message-modal';
import { getUpDropdownItemsWithIdAsValueFromEnum } from 'utils/helpers';
import { CompanyRole } from 'constants/CompanyRole';
import { AccountTab, ContactInfoTab, WorkingInfoTab } from '.';
import { ReactComponent as CloseIcon } from '../../../assets/icons/Close.svg';
import {
  generateEmployeeDocumentForPeriodUpdate,
  isAccountError,
  isContactInfoError,
  isINSSValid,
  isPersonalInfoError,
  isWorkingInfoError,
  treatAsUTC,
} from '../helpers';
import PersonalInfoTab from './PersonalInfoTab';
import {
  ContentContainer,
  InactiveBoxStyled,
  ModalCloseIcon,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
  TabsContainer,
} from './styles';
import { IAddEditEmployeeModalProps } from './types';

const AddressInitialState: ICompanyAddress = {
  id: 0,
  streetName: '',
  streetNumber: '',
  busNumber: '',
  city: '',
  postalCode: '',
  countryId: '',
};

const EmployeeDependantsInitialState: IEmployeeDependants = {
  id: 0,
  partner: false,
  disabledPartner: false,
  children: '',
  disabledChildren: '',
  elderly: '',
};

const EmployeeInitialState: IAddEditEmployee = {
  id: 0,
  firstName: '',
  lastName: '',
  dateOfBirth: null,
  birthplace: '',
  birthCountry: '',
  nationality: '',
  inss: '',
  languageId: '',
  gender: '',
  workerclass: null,
  reasonForEmployment: '',
  iban: '',
  bic: '',
  idCardNumber: '',
  modeOfTransport: '',
  maritalStatus: null,
  hasDependants: false,
  dependants: EmployeeDependantsInitialState,
  mobileNumber: '',
  emailAddress: '',
  iceMobileNumber: '',
  idUpload: null,
  idUploadURL: '',
  idUploadBack: null,
  idUploadBackURL: '',
  expirationDate: null,
  agreeGdpr: false,
  letterOfIntentCheck: false,
  academicPeriodStart: null,
  academicPeriodEnd: null,
  declarationOfTruth: null,
  pictureUpload: null,
  pictureUploadURL: '',
  pictureBase64: '',
  password: '',
  address: AddressInitialState,
  hasStayAddress: false,
  stayAddress: AddressInitialState,
  yearsOfExperience: '',
  status: null,
  permanent: false,
  startDate: null,
  inActive: false,
  companyLocationIds: [],
  canSkipApproval: false,
};

const workerClassObj = ['STUD', 'FLEX', 'EXT', 'ARB', 'BED'];

const AddEditEmployeeModal = (props: IAddEditEmployeeModalProps) => {
  const { isEdit, open, onClose, employeeId, companyId, pendingEmployeeCompanyId, onSavePendingEmployee } = props;

  const [t, i18next] = useTranslation();
  const dispatch = useAppDispatch();
  const { companyToken, inviteToken } = useParams();
  const { generalToken } = useParams();
  const isEmployee = useAppSelector(profileSelector)?.role === CompanyRole.EMPLOYEE;
  const onboardingDetails = useAppSelector(onboardingDetailsSelector);
  const employeeOnboarding = useAppSelector(onboardingSelector);
  const employeeBic = useAppSelector(bicStateSelector);
  const employeeDocuments = useAppSelector(employeeDocumentsSelector);
  const employee = useAppSelector(employeeSelector);
  const profile = useAppSelector(profileSelector);
  const isAdmin = useAppSelector(profileSelector)?.role === CompanyRole.ADMIN;
  const isManagerEdit =
    ['Company manager', 'Store/location manager', 'HR manager'].includes(profile?.role) && employee?.id > 0;

  const [successfullyOnboarded, setSuccessfullyOnboarded] = useState<boolean>(false);
  const [docToUpdate, setDocToUpdate] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentLanguage, setCurrentLanguage] = useState(0);
  const [inssError, setInssError] = useState<boolean>(false);
  const [invalidDateError, setInvalidDateError] = useState<boolean>(false);
  const [proofOfInscription, setProofOfInscription] = useState<Document>(null);
  const [newDocToAdd, setNewDocToAdd] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState<boolean>(false);

  const employeeError = useAppSelector(employeesErrorSelector);
  const isTokenInvalid = useAppSelector(setInviteTokenValiditySelector);
  const employeeErrorMessages = useMemo(() => {
    return {
      default: t(CONTRACTS__DEFAULT_ERROR),
      inss: t(EMPLOYEES__INSS_ERROR),
      iban: t(EMPLOYEES__IBAN_ERROR),
      email: t(EMPLOYEES__EMAIL_ERROR),
      permanent: t(EMPLOYEES__PERMANENT_CHANGE_VALIDATION),
    };
  }, [t]);

  const languageOptions: UPDropdownItem[] = useMemo(() => {
    return getUpDropdownItemsWithIdAsValueFromEnum(Lang);
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<any>({
    defaultValues: EmployeeInitialState,
  });

  const busNumberValue = watch('address.busNumber');
  const watchDate = watch('expirationDate');
  const watchAcademicPeriodStart = watch('academicPeriodStart');
  const watchAcademicPeriodEnd = watch('academicPeriodEnd');

  const scrollTo = (elementId: string) => {
    scroller.scrollTo(elementId, {
      duration: 500,
      smooth: true,
      containerId: 'tabsContainer',
    });
  };

  useEffect(() => {
    if (employeeOnboarding && inviteToken) {
      dispatch(checkEmployeeInvitationTokenValidity({ invitationToken: inviteToken, companyToken }));
    }
  }, [employeeOnboarding, companyToken, inviteToken, dispatch]);

  const changeLanguage = useCallback(
    (lang: string) => {
      setValue('languageId', `${lang}`);
      const userLang = languageOptions[toNumber(lang) - 1].label;
      i18next.changeLanguage(userLang);
    },
    [setValue, languageOptions, i18next],
  );

  const onError = (err, e) => {
    console.log(err, e);
    let errorTab = null;
    if (isPersonalInfoError(errors) || inssError) {
      errorTab = 'personalInfo';
    } else if (isContactInfoError(errors)) {
      errorTab = 'contactInfo';
    } else if (isWorkingInfoError(errors)) {
      errorTab = 'workingInfo';
    } else if (isAccountError(errors) || invalidDateError) {
      errorTab = 'account';
    }
    if (errorTab) {
      scrollTo(errorTab);
    }
  };

  const onSave: SubmitHandler<IAddEditEmployee> = async () => {
    const values = getValues();
    if (employeeBic) {
      values.bic = employeeBic;
    }

    values.inss = values.inss.split('.').join('').split('-').join('');
    if (!values.companyLocationIds) values.companyLocationIds = [];
    let validInss = true;
    if (values.dateOfBirth) {
      validInss = isINSSValid(values.inss, values.gender, values.dateOfBirth);
    }

    setInssError(!validInss);

    if (!validInss) setError('inss', { type: 'invalidINSS' });
    else {
      clearErrors('inss');
      if (
        !invalidDateError &&
        ((!values.permanent && new Date(values.expirationDate).getFullYear() >= 2000) || values.permanent)
      ) {
        setIsDisabled(true);
        if (values.id === 0) {
          await dispatch(
            saveEmployeeAction({
              employee: {
                ...values,
                address: {
                  ...values.address,
                  busNumber: values.address.busNumber === null ? '' : values.address.busNumber,
                },
                stayAddress: {
                  ...values.stayAddress,
                  busNumber: values.stayAddress.busNumber === null ? '' : values.stayAddress.busNumber,
                },
              },
              companyId,
            }),
          ).then(response => {
            if (proofOfInscription) {
              const documents: Document[] = [];
              documents.push({
                ...proofOfInscription,
                periodStart: treatAsUTC(watchAcademicPeriodStart),
                periodEnd: treatAsUTC(watchAcademicPeriodEnd),
              });
              dispatch(
                saveDocumentsAction({
                  companyId,
                  documents: generateDocumentsToSend(documents),
                  category: 6,
                  academicPeriodStart: formatDateToString(watchAcademicPeriodStart),
                  academicPeriodEnd: formatDateToString(watchAcademicPeriodEnd),
                  employeeId: toNumber(response.payload),
                }),
              );
            }
          });
        } else {
          await dispatch(
            updateEmployeeAction({
              employee: values,
              companyId: pendingEmployeeCompanyId || companyId,
            }),
          ).then(response => {
            if (newDocToAdd) {
              const documents: Document[] = [];
              documents.push({
                ...proofOfInscription,
                periodStart: treatAsUTC(watchAcademicPeriodStart),
                periodEnd: treatAsUTC(watchAcademicPeriodEnd),
              });
              dispatch(
                saveDocumentsAction({
                  companyId,
                  documents: generateDocumentsToSend(documents),
                  category: 6,
                  academicPeriodStart: formatDateToString(watchAcademicPeriodStart),
                  academicPeriodEnd: formatDateToString(watchAcademicPeriodEnd),
                  employeeId: toNumber(response.payload),
                }),
              );
            } else if (docToUpdate) {
              dispatch(
                updateStudentDeclaraionAction({
                  companyId,
                  employeeId,
                  academicPeriodStart: treatAsUTC(watchAcademicPeriodStart),
                  academicPeriodEnd: treatAsUTC(watchAcademicPeriodEnd),
                }),
              );
            }
          });
          if (pendingEmployeeCompanyId) onSavePendingEmployee();
        }
        onClose();
      }
    }
  };

  const onSubmit: SubmitHandler<any> = () => {
    const values = getValues();
    if (values.bic === '') {
      values.bic = employeeBic;
    }
    values.inss = values.inss.split('.').join('').split('-').join('');
    let auxValues = { ...values };

    delete auxValues.pictureBase64;
    delete auxValues.confirmPassword;

    auxValues = {
      ...auxValues,
      inss: toNumber(auxValues.inss),
      languageId: toNumber(auxValues.languageId),
      reasonForEmployment: toNumber(auxValues.reasonForEmployment),
      address: {
        ...auxValues.address,
        streetNumber: toNumber(auxValues.address.streetNumber),
        busNumber: values.address.busNumber === null ? '' : values.address.busNumber,
      },
      stayAddress: auxValues.hasStayAddress
        ? {
            ...auxValues.stayAddress,
            streetNumber: toNumber(auxValues.stayAddress.streetNumber),
            busNumber: values.stayAddress.busNumber === null ? '' : values.stayAddress.busNumber,
          }
        : AddressInitialState,
      modeOfTransport: toNumber(auxValues.modeOfTransport),
      yearsOfExperience: toNumber(auxValues.yearsOfExperience),
      idCardNumber: toNumber(auxValues.idCardNumber),
      dateOfBirth:
        typeof auxValues.dateOfBirth === 'string' ? auxValues.dateOfBirth : auxValues.dateOfBirth.toDateString(),
      expirationDate:
        typeof auxValues.expirationDate === 'string'
          ? auxValues.expirationDate
          : auxValues.expirationDate.toDateString(),
    };
    if (!invalidDateError && new Date(values.expirationDate).getFullYear() >= 2000) {
      setIsDisabled(true);
      if (employeeOnboarding) {
        dispatch(
          saveEmployeeAction({
            employee: values,
            companyId: companyToken ?? generalToken,
            invitationToken: inviteToken,
          }),
        ).then(response => {
          if (response.payload) {
            setSuccessfullyOnboarded(true);
            setTimeout(() => {
              window.location.href = '/auth/login';
            }, 1000);
          } else {
            setIsDisabled(false);
          }
        });
      } else {
        dispatch(updateEmployeeAction({ employee: auxValues, companyId }));
        if (newDocToAdd) {
          const documents: Document[] = [];
          documents.push({
            ...proofOfInscription,
            periodStart: treatAsUTC(watchAcademicPeriodStart),
            periodEnd: treatAsUTC(watchAcademicPeriodEnd),
          });
          dispatch(
            saveDocumentsAction({
              companyId,
              documents: generateDocumentsToSend(documents),
              category: 6,
              academicPeriodStart: formatDateToString(watchAcademicPeriodStart),
              academicPeriodEnd: formatDateToString(watchAcademicPeriodEnd),
              employeeId: isEmployee ? employee.id : employeeId,
            }),
          );
        } else if (docToUpdate) {
          dispatch(
            updateStudentDeclaraionAction({
              companyId,
              employeeId: isEmployee ? employee.id : employeeId,
              academicPeriodStart: treatAsUTC(watchAcademicPeriodStart),
              academicPeriodEnd: treatAsUTC(watchAcademicPeriodEnd),
            }),
          );
        }
        onClose();
      }
    }
  };

  const onChangeCurrentTab = useCallback((event: SyntheticEvent, value: number) => {
    setCurrentTab(value);
  }, []);

  const onChangeCurrentLanguage = useCallback((event: SyntheticEvent, value: number) => {
    setCurrentLanguage(value);
  }, []);

  const approveEmployee = useCallback(() => {
    const values = getValues();
    if (values.bic === '') {
      values.bic = employeeBic;
    }
    values.status = EmployeeStatus.Ready;
    dispatch(updateEmployeeAction({ employee: values, companyId: pendingEmployeeCompanyId || companyId }));

    onClose();
  }, [companyId, dispatch, employeeBic, getValues, onClose, pendingEmployeeCompanyId]);

  useEffect(() => {
    if (busNumberValue === null) setValue('address.busNumber', '');
  }, [busNumberValue, setValue]);

  useEffect(() => {
    if (!isDateValid(watchDate) && dirtyFields?.expirationDate) {
      setInvalidDateError(true);
    } else {
      setInvalidDateError(false);
    }
  }, [clearErrors, dirtyFields, watchDate]);

  useEffect(() => {
    if (onboardingDetails?.firstName) {
      setValue('firstName', `${onboardingDetails.firstName}`);
      setValue('lastName', `${onboardingDetails.lastName}`);
      setValue('emailAddress', `${onboardingDetails.emailAddress}`);
      setValue('workerclass', workerClassObj.indexOf(onboardingDetails.contractType) + 1);
    }

    if (onboardingDetails?.languageId) {
      setValue('languageId', `${onboardingDetails.languageId}`);
      const userLang = languageOptions[toNumber(onboardingDetails.languageId) - 1].label;
      i18next.changeLanguage(userLang);
    }

    if (employeeOnboarding && !onboardingDetails?.languageId) {
      i18next.changeLanguage('1');
    }

    if (onboardingDetails?.assignedCompanyLocations) {
      setValue('companyLocationIds', onboardingDetails.assignedCompanyLocations.split(','));
    }
  }, [getValues, i18next, languageOptions, onboardingDetails, employeeOnboarding, setValue]);

  useEffect(() => {
    if (employee && !employeeOnboarding) {
      dispatch(
        getDocumentsAction({
          companyId: toNumber(companyId),
          category: 6,
          employeeId: isEmployee ? employee.id : employeeId,
          startDate: null,
          endDate: null,
          triggerEmployeeDocuments: true,
        }),
      );
    }
  }, [companyId, dispatch, employee, employeeId, isEmployee, employeeOnboarding]);

  useEffect(() => {
    dispatch(clearBicAction());
    dispatch(clearEmployeeAction());
  }, [dispatch]);

  useEffect(() => {
    if (employeeId != null && !Number.isNaN(companyId)) {
      dispatch(getEmployeeByIdAction({ employeeId, companyId }));
    }
  }, [companyId, dispatch, employeeId]);

  const watchedLocationIds = watch('companyLocationIds');

  useEffect(() => {
    if (watchedLocationIds?.length === 0 && !employeeOnboarding) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [employeeOnboarding, watchedLocationIds]);

  useEffect(() => {
    if (employee && employeeDocuments.length > 0) {
      const uploadedDocument = generateEmployeeDocumentForPeriodUpdate(employeeDocuments[0]);
      setProofOfInscription(uploadedDocument);
      setNewDocToAdd(false);
      reset({
        ...employee,
        academicPeriodStart: employeeDocuments[0].periodStart,
        academicPeriodEnd: employeeDocuments[0].periodEnd,
      });
    } else if (employee) {
      setProofOfInscription(null);
      reset(employee);
    }
  }, [employee, employeeDocuments, reset]);

  const navigateToLogin = () => {
    window.location.href = '/auth/login';
  };

  if (isTokenInvalid) {
    return (
      <MessageModal
        message={t(EMPLOYEES__INVATION_TOKEN_INVALID)}
        buttonText={t(GENERAL__GO)}
        open
        onClose={navigateToLogin}
      />
    );
  }

  const isSaveDisabled = () => {
    return isDisabled || (!isDirty && employeeId > 0) || watchedLocationIds?.length === 0;
  };

  return (
    <Backdrop sx={{ zIndex: '1' }} id="TestMe" open={employeeOnboarding || isEmployee ? false : open}>
      <ModalWrapper open={open} onClose={onClose}>
        <ModalContainer>
          <ModalHeader>
            {!employeeOnboarding && !isEmployee && !isManagerEdit && (
              <>
                <ModalTitle>{isEdit ? t(EMPLOYEES__EDIT_EMPLOYEE) : t(EMPLOYEES__ADD_NEW_EMPLOYEE)}</ModalTitle>
                <ModalCloseIcon onClick={onClose}>
                  <CloseIcon fill={theme.palette.secondary.contrastText} />
                </ModalCloseIcon>
              </>
            )}

            {isManagerEdit && (
              <>
                <ModalTitle>{t(EMPLOYEES__VIEW_EMPLOYEE)}</ModalTitle>
                <ModalCloseIcon onClick={onClose}>
                  <CloseIcon fill={theme.palette.secondary.contrastText} />
                </ModalCloseIcon>
              </>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: employeeOnboarding && !onboardingDetails?.languageId ? 'flex-start' : 'center',
              }}
            >
              {employeeOnboarding && !onboardingDetails?.languageId && (
                <Tabs value={currentLanguage} onChange={onChangeCurrentLanguage} sx={{ marginRight: '100px' }}>
                  <TabStyled style={{ minWidth: '5%' }} label="nl" onClick={() => changeLanguage('1')} />
                  <TabStyled style={{ minWidth: '5%' }} label="fr" onClick={() => changeLanguage('2')} />
                  <TabStyled style={{ minWidth: '5%' }} label="en" onClick={() => changeLanguage('3')} />
                </Tabs>
              )}
              <TabsContainer value={currentTab} onChange={onChangeCurrentTab} sx={{ alignSelf: 'center' }}>
                <TabStyled label={t(EMPLOYEES__PERSONAL_INFO)} onClick={() => scrollTo('personalInfo')} />
                <TabStyled label={t(EMPLOYEES__CONTACT_INFO)} onClick={() => scrollTo('contactInfo')} />
                <TabStyled label={t(EMPLOYEES__WORKING_INFO)} onClick={() => scrollTo('workingInfo')} />
                {!isManagerEdit && <TabStyled label={t(EMPLOYEES__ACCOUNT)} onClick={() => scrollTo('account')} />}
              </TabsContainer>
            </Box>
          </ModalHeader>
          <ContentContainer id="tabsContainer" disableScroll={isBranchDropdownOpen}>
            <Element id="personalInfo" name="personalInfo">
              <PersonalInfoTab
                control={control}
                errors={errors}
                employeeId={employee ? employee.id : 0}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
                inssError={inssError}
              />
            </Element>
            <Element id="contactInfo" name="contactInfo">
              <ContactInfoTab
                control={control}
                errors={errors}
                employeeId={employee ? employee.id : 0}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
              />
            </Element>
            <Element id="workingInfo" name="workingInfo">
              <WorkingInfoTab
                control={control}
                errors={errors}
                employeeId={employee ? employee.id : 0}
                watch={watch}
                setValue={setValue}
                clearErrors={clearErrors}
                employee={employee}
                onBoardingLocationIds={
                  onboardingDetails ? onboardingDetails.assignedCompanyLocations.split(',').map(Number) : []
                }
                companyId={pendingEmployeeCompanyId || companyId}
                setIsBranchDropdownOpen={setIsBranchDropdownOpen}
              />
            </Element>
            {!isManagerEdit && (
              <Element id="account" name="account">
                <AccountTab
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                  setError={setError}
                  clearErrors={clearErrors}
                  employeeIdURL={employee ? employee?.idUploadURL : null}
                  employeeIdBackURL={employee ? employee?.idUploadBackURL : null}
                  companyId={companyId}
                  invalidDateError={invalidDateError}
                  watch={watch}
                  proofOfInscription={proofOfInscription}
                  setProofOfInscription={setProofOfInscription}
                  setNewDocToAdd={setNewDocToAdd}
                  setDocToUpdate={setDocToUpdate}
                />
              </Element>
            )}
            {successfullyOnboarded && (
              <Alert severity="success" icon={false} variant="filled">
                {t(EMPLOYEES__ADDED_SUCCESSFULLY)}
              </Alert>
            )}
            {employeeError && employeeOnboarding && (
              <Alert severity="error" icon={false} variant="filled">
                {employeeError === 'inss' ||
                employeeError === 'email' ||
                employeeError === 'iban' ||
                employeeError === 'default' ||
                employeeError === 'permanent'
                  ? employeeErrorMessages[employeeError]
                  : t(employeeError)}
              </Alert>
            )}
          </ContentContainer>
          {isManagerEdit && (
            <ModalFooter>
              <Box>
                <UPButton
                  text={t(GENERAL__SAVE_BUTTON)}
                  onClick={() => handleSubmit(isEmployee || employeeOnboarding ? onSubmit : onSave, onError)()}
                  disabled={isSaveDisabled()}
                />
              </Box>
              <Box sx={{ marginLeft: '12px' }}>
                <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onClose} outlined />
              </Box>
            </ModalFooter>
          )}
          {!isManagerEdit && (
            <ModalFooter>
              {isAdmin && employee != null && !employee.canSkipApproval && (
                <Box sx={{ marginRight: '12px' }}>
                  <UPButton text="Approve" onClick={approveEmployee} />
                </Box>
              )}
              <Box>
                <UPButton
                  text={t(GENERAL__SAVE_BUTTON)}
                  onClick={() => handleSubmit(isEmployee || employeeOnboarding ? onSubmit : onSave, onError)()}
                  disabled={isDisabled || (!isDirty && employeeId > 0)}
                />
              </Box>
              <Box sx={{ marginLeft: '12px' }}>
                <UPButton text={t(GENERAL__CANCEL_BUTTON)} onClick={onClose} outlined />
              </Box>
              {isAdmin && (
                <InactiveBoxStyled>
                  <Controller
                    name="inActive"
                    control={control}
                    render={({ field }) => (
                      <UPCheckbox label={t(EMPLOYEES__INACTIVE)} value={field.value} handleChange={field.onChange} />
                    )}
                  />
                </InactiveBoxStyled>
              )}
            </ModalFooter>
          )}
        </ModalContainer>
      </ModalWrapper>
    </Backdrop>
  );
};

export default AddEditEmployeeModal;
