import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export const DataGridStyled = styled(DataGrid)(() => ({
  '.MuiDataGrid-iconSeparator': { color: 'white', opacity: 0 },
  '.MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '.MuiDataGrid-custom-background-row': {
    backgroundColor: '#E3FFF1',
  },
  '.MuiDataGrid-custom-background-row:hover': {
    backgroundColor: '#C4FEDA',
  },
}));
